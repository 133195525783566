import React, { useReducer, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { rgba } from "polished";
import { useTranslation } from 'react-i18next'
import { NavLink as RouterNavLink, withRouter } from "react-router-dom";
import { darken } from "polished";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";

import { spacing } from "@material-ui/system";
import ArrowBack from '@material-ui/icons/ArrowBack'
import TPLogo from '../assets/images/branding_logo.png'
import TPLogoHighContrast from '../assets/images/branding_logo_high_contrast.png'
import OnDutyStatusHandler from '../pages/dashboards/Util/OnDutyStatus';
import { setTheme, increaseTextSize, decreaseTextSize } from '../redux/actions/themeActions';
import {
  Avatar,
  Badge,
  Box as MuiBox,
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  Grid,
  Link as MuiLink,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography as MuiTypography,
  Button,
  ButtonBase,
} from "@material-ui/core";

import { ExpandLess, ExpandMore, Accessible, Accessibility } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";

import { Layers } from "react-feather";
import ModalCreateMixBatch from "../pages/dashboards/Materials/modals/ModalCreateEditMixBatch";

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));
const ProfileLink = React.forwardRef((props, ref) => (
  <MuiLink underline="hover" color="textPrimary" ref={ref} onClick={props.navigate}>{props.children}</MuiLink>
))
const Box = styled(MuiBox)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${props => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${props => props.theme.spacing(2.5)}px;
  padding-bottom: ${props => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.sidebar.header.color};
  background-color: ${props => props.theme.sidebar.header.background};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(6)}px;
  cursor: default;

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${props => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Layers)`
  margin-right: ${props => props.theme.spacing(2)}px;
  color: ${props => props.theme.sidebar.header.brand.color};
`;

const BrandChip = styled(Chip)`
  background-color: ${green[700]};
  border-radius: 5px;
  color: ${props => props.theme.palette.common.white};
  font-size: 60%;
  height: 20px;
  margin-left: 2px;
  margin-bottom: 1px;
  padding: 4px 0;

  span {
    padding-left: ${props => props.theme.spacing(1.5)}px;
    padding-right: ${props => props.theme.spacing(1.5)}px;
  }
`;

const Category = styled(ListItem)`
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(5)}px;
  font-weight: ${props => props.theme.typography.fontWeightRegular};

  svg {
    color: ${props => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.05, props.theme.sidebar.background)};

    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${props => props.theme.sidebar.color};
    font-size: ${props => props.theme.typography.body1.fontSize}px;
    font-weight: ${props => props.theme.sidebar.category.fontWeight};
    padding: 0 ${props => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${props => props.theme.spacing(15)}px;
  padding-top: ${props => props.theme.spacing(2)}px;
  padding-bottom: ${props => props.theme.spacing(2)}px;

  span {
    color: ${props => props.theme.sidebar.color};
  }

  &:hover span {
    color: ${props => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${props => props.theme.sidebar.color};
  span {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${props => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${props => props.theme.sidebar.badge.color};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  padding: ${props => props.theme.spacing(4)}px
    ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;
const SidebarButtonSection = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  padding: ${props => props.theme.spacing(4)}px
    ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(4)}px;
  opacity: 0.9;
  display: block;
`;
const SidebarDownloadAppleSection = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  padding: ${props => props.theme.spacing(10)}px
    ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(2)}px;
  opacity: 0.9;
  display: block;
`;
const SidebarNTEPSection = styled.div`
  padding: ${props => props.theme.spacing(4)}px
    ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(2)}px;
  opacity: 0.9;
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SidebarDownloadGoogleSection = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  padding: ${props => props.theme.spacing(1)}px
    ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;
const BoldTypography = styled(Typography)`
    color: ${props => props.theme.sidebar.color};
    font-weight: ${props => props.theme.typography.fontWeightBold}
`;
const SidebarBanner = styled.div`
  color: ${props => props.theme.sidebar.color};
  padding: ${props => props.theme.spacing(4)}px
    ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;
const SidebarBannerBox = styled(Box)`
  ${spacing}

  border: 1px solid ${props => props.theme.palette.primary.main}
`;

const SidebarFooter = styled.div`
  background-color: ${props =>
    props.theme.sidebar.footer.background} !important;
  padding: ${props => props.theme.spacing(2.75)}px
    ${props => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 1px solid white;
`;

const SidebarFooterText = styled(Typography)`
  color: ${props => props.theme.sidebar.footer.color};
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${props => props.theme.sidebar.footer.color};
  font-size: .725rem;
  display: block;
  padding: 1px;
`;

const StyledBadge = styled(Badge)`
  margin-right: ${props => props.theme.spacing(1)}px;

  span {
    background-color: ${props => props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${props => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`

function SidebarCategory({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) {
  return (
    <Category {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
}

function SidebarLink({ name, to, badge, jobs }) {

  let tempBadge = badge;
  switch (to) {
    case '/deliveries/current':
    case '/jobs/current': {
      tempBadge = jobs.current.length
      break;
    }
    case '/jobs/nearby': {
      tempBadge = jobs.nearby.length
      break;
    }
    case '/jobs/pending': {
      tempBadge = jobs.pending.length
      break;
    }
    case '/deliveries/past':
    case '/jobs/past': {
      tempBadge = jobs.past.length
      break;
    }
    case '/deliveries/in-progress':
    case '/jobs/in-progress': {
      tempBadge = jobs.inProgress.length
      break;
    }
    case '/deliveries/external':
    case '/jobs/external': {
      tempBadge = jobs.external.length
      break;
    }
    default: {
      tempBadge = 0;
      break;
    }
  }
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <LinkText>{name}</LinkText>
      {tempBadge ? <LinkBadge label={tempBadge} /> : ""}
    </Link>
  );
}

function Sidebar({ classes, staticContext, location, history, routes, setCreateJobModal, setCreateSiteModal, setCreateScaleModal, setCreateVehicleModal, setCreateCompanyModal, setCreateInspectionModal, companyRestrictionDate, company, helpers, user, updateStyleSettings, theme, ...rest }) {
  const initOpenRoutes = () => {
   /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/" ? true : false;

      _routes = Object.assign({}, _routes, {[index]: isActive || isOpen || isHome})
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [openCreateBatchMaterialModal, setOpenCreateBatchMaterialModal] = useState(false)
  const { t } = useTranslation(); 


  const toggle = (index, categoryId) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      item => openRoutes[index] || setOpenRoutes(openRoutes => Object.assign({}, openRoutes, {[item]: false}))
    )
    
    const shouldOpen = !openRoutes[index]

    // Toggle selected element
    setOpenRoutes(openRoutes => Object.assign({}, openRoutes, {[index]: shouldOpen }));
    setSelectedCategoryId(shouldOpen ? categoryId : "")
  }

  let showTrial = false;
  let trialEndDate = ""
  if (company && company.trials && company.trials.length) {
    const lastTrial = company.trials[company.trials.length - 1]
    if (!lastTrial.has_expired) {
      showTrial = true;
      trialEndDate = helpers.getFormattedDateFromUnix(lastTrial.trial_ends)
    }
  }

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand>
        {theme.currentTheme == 'high_contrast' ?
        <img src={TPLogoHighContrast} alt="TruckPay Logo" className="img-fluid" style={{ height: '26px' }}/>
        :
        <img src={TPLogo} alt="TruckPay Logo" className="img-fluid" />
        }
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <Items>

            <SidebarCategory
              isOpen={!openRoutes[-1]}
              isCollapsable={true}
              name={t(`accessibilityTranslations.accessibility`)}
              icon={<Accessible />}
              button={true}
              onClick={() => toggle(-1)}
            />
            <Collapse
              in={openRoutes[-1]}
              timeout="auto"
              unmountOnExit
            >
              <Link button dense onClick={() => updateStyleSettings({ text_size: theme.textSize + 1 })}>
                <LinkText>{t(`accessibilityTranslations.increaseText`)}</LinkText>
              </Link>
              <Link button dense onClick={() => updateStyleSettings({ text_size: theme.textSize - 1 })}>
                <LinkText>{t(`accessibilityTranslations.decreaseText`)}</LinkText>
              </Link>
              {/* <Link button dense>
                <LinkText>Grayscale</LinkText>
              </Link> */}
              <Link button dense onClick={() => updateStyleSettings({ theme: 'high_contrast' })}>
                <LinkText>{t(`accessibilityTranslations.highContrast`)}</LinkText>
              </Link>
              {/* <Link button dense>
                <LinkText>Negative Contrast</LinkText>
              </Link> */}
              <Link button dense onClick={() => updateStyleSettings({ theme: 'standard' })}>
                <LinkText>{t(`accessibilityTranslations.standardTheme`)}</LinkText>
              </Link>
              <Link button dense onClick={() => updateStyleSettings({ theme: 'light' })}>
                <LinkText>{t(`accessibilityTranslations.lightTheme`)}</LinkText>
              </Link>
              {/* <Link button dense>
                <LinkText>Underline Links</LinkText>
              </Link> */}
              <Link button dense onClick={() => updateStyleSettings({ 
                text_size: 1,
                theme: 'standard',
                link_underline_behavior: 'hover'
              })}>
                <LinkText>{t(`actions.reset`)}</LinkText>
              </Link>
            </Collapse>
            
            {!location.pathname.includes('portal') && <>
              {(showTrial && helpers.can(company.perms, "pay-invoice")) ? (
                <SidebarBanner>
                  <SidebarBannerBox p={3}>
                    <Typography color="primary" variant="h6" align="center"> 
                      {t(`freeTrialMode`)}
                    </Typography>
                    <Typography  align="center">
                      {t(`expires`)}: {trialEndDate}
                    </Typography>
                  </SidebarBannerBox>
                </SidebarBanner>
              ) : null}
              {(Boolean(companyRestrictionDate) && helpers.can(company.perms, "pay-invoice")) ? (
                <SidebarBanner>
                  <SidebarBannerBox p={3}>
                    <Typography color="primary" variant="h6" align="center"> 
                      {t(`tpRestricted`)}
                    </Typography>
                    <Typography variant="h4" align="center" my={4}>
                      {helpers.getFormattedLongDate(companyRestrictionDate)}
                    </Typography>
                    <Typography align="center">
                      {t(`tpRestrictedMessage`)}
                    </Typography>
                  </SidebarBannerBox>
                </SidebarBanner>
              ) : null}
              <OnDutyStatusHandler company={company}/> 
            </>}
            {((location.pathname.includes('jobs') || selectedCategoryId === "sidebarJobs")&& helpers.can(company.perms, "create-job")) ? (
              <SidebarButtonSection>
                <Button fullWidth variant="contained" color="primary" onClick={() => setCreateJobModal(true)}>{t('jobTranslations.actions.createJob')}</Button>
              </SidebarButtonSection>
            ) : null}
            {((location.pathname.includes('sites') || selectedCategoryId === "sidebarSites")&& helpers.can(company.perms, "create-site")) ? (
              <SidebarButtonSection>
                <Button fullWidth variant="contained" color="primary" onClick={() => setCreateSiteModal(true)}>{t('siteTranslations.actions.createSite')}</Button>
              </SidebarButtonSection>
            ) : null}
            {((location.pathname.includes('scales') || selectedCategoryId === "sidebarScales") && helpers.can(company.perms, "add-equipment")) ? (
              <SidebarButtonSection>
                <Button fullWidth variant="contained" color="primary" onClick={() => setCreateScaleModal(true)}>{t('scaleTranslations.actions.createScale')}</Button>
              </SidebarButtonSection>
            ) : null}
            {((location.pathname.includes('vehicles') || selectedCategoryId === "sidebarVehicles") && helpers.can(company.perms, "add-truck")) ? (
              <SidebarButtonSection>
                <Button fullWidth variant="contained" color="primary" onClick={() => setCreateVehicleModal(true)}>{t('vehicleTranslations.actions.createVehicle')}</Button>
              </SidebarButtonSection>
            ) : null}
            {location.pathname.includes('portal') && (
              <SidebarButtonSection>
                <Button fullWidth variant="contained" color="primary" onClick={() => setCreateCompanyModal(true)}>{t('companyTranslations.actions.createCompany')}</Button>
              </SidebarButtonSection>
            )}
            {routes.map((category, index) => (
              <React.Fragment key={index}>
                {category.header ? (
                  <SidebarSection>{category.header}</SidebarSection>
                ) : null}

                {category.children ? (
                  <React.Fragment key={index}>
                    <SidebarCategory
                      isOpen={!openRoutes[index]}
                      isCollapsable={true}
                      name={t(`sidebarTranslations.${category.id}`)}
                      icon={category.icon}
                      button={true}
                      onClick={() => {
                        toggle(index, category.id)
                      }}
                    />

                    <Collapse
                      in={openRoutes[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {category.children.map((route, index) => (
                          route.name ? (
                            <SidebarLink
                              key={index}
                              name={t(`sidebarTranslations.${route.name}`)}
                              to={route.path}
                              icon={route.icon}
                              badge={route.badge}
                              jobs={rest.jobs}
                            /> )
                            : null

                      ))}
                    </Collapse>
                  </React.Fragment>
                ) : (
                  category.hidden ? null : 
                  <SidebarCategory
                    isCollapsable={false}
                    name={t(`sidebarTranslations.${category.id}`)}
                    to={category.path}
                    activeClassName="active"
                    component={NavLink}
                    icon={category.icon}
                    exact
                    badge={category.badge}
                    onClick={() => setSelectedCategoryId(category.id)}
                  />
                )}
              </React.Fragment>
            ))}

            {(user && user.id && !location.pathname.includes('portal')) && (
              <SidebarSection>
                <Button 
                  fullWidth 
                  variant="contained" 
                  color="primary" 
                  onClick={() => history.push('/portal/companies/list')}
                  startIcon={<ArrowBack />}
                >
                  {t('companyTranslations.backToEmployers')}
                </Button>
              </SidebarSection>
            )}

            <SidebarDownloadAppleSection align="center">
              <a href='https://apps.apple.com/us/app/truckpay/id1189367915?itsct=apps_box&itscg=30200'>
                <img src="/static/img/download-apple.svg" style={{ width: "150px" }} />
              </a>
            </SidebarDownloadAppleSection>

            <SidebarDownloadGoogleSection align="center">
              <a href='https://play.google.com/store/apps/details?id=com.truckpay.truckpay&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img style={{ width: "170px" }} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
              </a>
            </SidebarDownloadGoogleSection>

            <SidebarNTEPSection align="center">
              <BoldTypography variant="subtitle1" >
                NTEP CC: 19-140
              </BoldTypography>
              <img src="/static/img/ntep_logo_bw.png" style={{ width: "50%", marginTop: "15px" }}/>
              {/* https://apps.apple.com/us/app/truckpay/id1189367915?itsct=apps_box&itscg=30200 */}
            </SidebarNTEPSection>
            <SidebarNTEPSection align="center">
              <img src="/static/img/DOBE_logo.png" style={{ width: "100%", marginTop: "15px" }}/>
            </SidebarNTEPSection>

          </Items>
        </List>
      </Scrollbar>
      {Boolean(user?.id && company?.id) && <SidebarFooter>
        <Grid container spacing={2}>
          <Grid item>
            <StyledBadge
              overlap="circle"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              variant="dot"
            >
              <Avatar alt={`${user.first_name} ${user.last_name}`} src={user.profile_picture_url || "/static/img/avatars/profile-pic-placeholder.jpg"} />
            </StyledBadge>
          </Grid>
          <Grid item xs zeroMinWidth>
            <RouterNavLink to="/user" component={ProfileLink}>
              <SidebarFooterText variant="h6" noWrap>
                {user.first_name} {user.last_name}
              </SidebarFooterText>
            </RouterNavLink>
            <RouterNavLink to="/company" component={ProfileLink}>
              <SidebarFooterSubText variant="caption">
                {company.name}
              </SidebarFooterSubText>
            </RouterNavLink>
          </Grid>
        </Grid>
      </SidebarFooter>}
    </Drawer>
  );
}
const msp = state => ({
  user: state.session.user,
  company: state.session.company,
  jobs: state.entities.jobs,
  helpers: state.helpers,
  theme: state.theme,
})
const mdp = dispatch => ({

})
export default connect(msp, mdp)(withRouter(Sidebar));
