import React, { useEffect, useState, useRef } from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useDrag } from 'react-dnd'
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import {
    Avatar as MuiAvatar,
    Box as MuiBox,
    Breadcrumbs as MuiBreadcrumbs,
    Button as MuiButton,
    Checkbox,
    Chip as MuiChip,
    Divider as MuiDivider,
    Grid as MuiGrid,
    IconButton,
    Link,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell as MuiTableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow as MuiTableRow,
    TableSortLabel,
    Toolbar,
    Tooltip,
    Typography as MuiTypography,
    Menu,
    MenuItem,
    Select,
    InputLabel,
    Card as MuiCard,
    CardHeader,
    CardContent,
    FormControl as MuiFormControl,
    Collapse,
    TextField,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    Switch,
    FormControl,
    Modal,
    DialogActions,
    RadioGroup,
    Radio,
} from "@material-ui/core";
import { Close as CloseIcon, Language as WorldIcon } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TPLoader } from "../../../components/Progress";
import {
    KeyboardDatePicker,
    KeyboardTimePicker
} from "@material-ui/pickers";

import { withStyles } from '@material-ui/core/styles';
import { getSupportedCountries } from "../../../../redux/actions/adminActions";
import { updateDispatchOrder } from "../../../../redux/util/dispatchAPI";
import { popAlert } from "../../../../redux/actions/sessionActions";

import GoogleMapReact from 'google-map-react'
import { GMapAPIKey } from "../../../../env";
import NotificationSnackbar from "../../Util/Snackbar";

const Grid = styled(MuiGrid)(spacing);
const Box = styled(MuiBox)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)`
    ${spacing};

    font-weight: ${props => props.medium && props.theme.typography.fontWeightMedium};
    font-weight: ${props => props.bold && props.theme.typography.fontWeightBold};
    font-weight: ${props => props.bolder && props.theme.typography.fontWeightBolder};
    font-weight: ${props => props.boldest && props.theme.typography.fontWeightBoldest};
`;
const GoogleMapReactWrapper = styled.div`
  height: 300px;
  width: 100%;
`;
class SetJobAddressModal extends React.Component {
    /*
        props = {
            sites: [...Sites],
            site: Site,
            locationID: "",
            address: {
                country: "",
                address_line_1: "",
                city: "",
                province: "",
                postal_code: "",
                latitude: "",
                longitude: ""
            },
            updateAddressInfo: Function
            setModalOpen: Function,
            requireSite: boolean
        }

    */
    constructor(props) {
        super(props);

        const initialState = {
            displayCoordinates: false,
            addressType: (props.site || props.requireSite) ? 'site' : 'address', // 'address' || 'site'
            
            searchSites: props.site ? props.site.site_name : "",
            site: props.site || "",
            location_id: props.locationID || "",
            
            country: props.address?.country || "",
            address_line_1: props.address?.address_line_1 || "",
            city: props.address?.city || "",
            province: props.address?.province || "",
            postal_code: props.address?.postal_code || "",
            latitude: props.address?.latitude || "",
            longitude: props.address?.longitude || "",

            addContactInfo: false,
            address_contact_first_name: props.contactInfo?.address_contact_first_name || "",
            address_contact_last_name: props.contactInfo?.address_contact_last_name || "",
            address_company_name: props.contactInfo?.address_company_name || "",
            address_contact_email: props.contactInfo?.address_contact_email || "",
            address_contact_phone: props.contactInfo?.address_contact_phone || "",
        }
        if (props.supportedCountries && props.supportedCountries.length) {
            initialState.country = props.supportedCountries.find(country => country.id == props.address.country?.id) || ""
        }
        this.state = {
            ...initialState
        }
        this.addressInput = React.createRef();
        console.log("props", props, this.state)
    }
    componentDidMount = () => {
        this.mounted = true;
        if (!this.props.supportedCountries || !this.props.supportedCountries.length) {
            this.props.getSupportedCountries()
        }
        if (this.state.latitude && this.state.longitude) {
            this.updateMarkerPosition()
        }
        if (!this.state.country) {
            this.setStateIfMounted({
                country: this.props.supportedCountries.find(country => country.code == "US")
            })
        }
    }
    componentWillUnmount = () => {
        this.mounted = false;
    }
    setStateIfMounted = (state, callback) => {
        if (this.mounted) {
            this.setState(state, callback)
        }
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.displayCoordinates != this.state.displayCoordinates && !this.state.displayCoordinates) {
            this.connectGoogleAutocomplete()
        }
        if (
            (prevState.latitude != this.state.latitude || prevState.longitude != this.state.longitude)
            && (this.state.latitude && this.state.longitude)
        ) {
            this.updateMarkerPosition()
            if (this.state.displayCoordinates) {
                this.geocodeNewPosition({ lat: parseFloat(this.state.latitude), lng: parseFloat(this.state.longitude) })
            }
        }
        if (prevState.country != this.state.country && this.state.country && this.state.country.id) {
            this.updateAutocompleteRestriction()
        }
        if (prevState.site != this.state.site && this.state.site) {
            this.setStateIfMounted({
                location_id: this.state.site.location_id,
                country: this.props.supportedCountries.find(country => country.code == this.state.site.address.country.code) || "",
                address_line_1: this.state.site.address.address_line_1 || "",
                city: this.state.site.address.city || "",
                province: this.state.site.address.province || "",
                postal_code: this.state.site.address.postal_code || "",
                latitude: this.state.site.address.latitude || "",
                longitude: this.state.site.address.longitude || "",
                addContactInfo: Boolean(
                    this.state.site.address.address_contact_first_name ||
                    this.state.site.address.address_contact_last_name ||
                    this.state.site.address.address_contact_email ||
                    this.state.site.address.address_contact_phone
                ),
                address_company_name: this.state.site.address.address_company_name || null,
                address_contact_first_name: this.state.site.address.address_contact_first_name || null,
                address_contact_last_name: this.state.site.address.address_contact_last_name || null,
                address_contact_email: this.state.site.address.address_contact_email || null,
                address_contact_phone: this.state.site.address.address_contact_phone || null,
            })
        }
    }
    handleChange = this.props.helpers.setupHandleChange(this)
    getMapOptions = maps => {
        return {
            fullscreenControl: true,
            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.ROADMAP,
            scaleControl: true,
            scrollwheel: false,
            streetViewControl: true
        };
    };
    handleApiLoaded = (map, maps) => {
        this.map = map;
        this.maps = maps;
        this.connectGoogleAutocomplete()
        if (this.props.addressObject) {
            this.updateMarkerPosition()
        }
    }
    updateMarkerPosition = () => {
        if (this.map && this.maps) {
            const newPosition = new this.maps.LatLng(this.state.latitude, this.state.longitude)
            if (!this.marker) {
                this.marker = new this.maps.Marker({
                    position: newPosition,
                    map: this.map,
                    animation: this.maps.Animation.DROP
                })
            } else {
                this.marker.setPosition(newPosition);
            }
            this.map.setCenter(newPosition);
        }
    }
    connectGoogleAutocomplete = () => {
        if (this.addressInput.current && this.maps && this.maps.places) {
            if (!this.addressAutocomplete) {
                this.addressAutocomplete = new this.maps.places.Autocomplete(this.addressInput.current)
                this.autoCompleteListener = this.addressAutocomplete.addListener('place_changed', () => {
                    const selection = this.addressAutocomplete.getPlace()
                    if (selection) {
                        this.handleAddressSelect(selection.address_components, selection.geometry.location)
                    }
                })
            }
        }
    }
    updateAutocompleteRestriction = () => {
        const selectedCountry = this.props.supportedCountries.find(country => country.id == this.state.country.id)
        if (selectedCountry && this.addressAutocomplete) {
            this.addressAutocomplete.setComponentRestrictions({
                country: selectedCountry.code
            })
        }
    }
    handleAddressSelect = (addressComponents, coordinates) => {
        const parsedAddress = this.props.helpers.parseGoogleAddressComponents(addressComponents, true)
        console.log('address select parsedAddress', parsedAddress)
        this.setStateIfMounted({
            address_line_1: parsedAddress.address_line_1 || "",
            city: parsedAddress.city || "",
            province: parsedAddress.province || "",
            postal_code: parsedAddress.postal_code || "",
            latitude: coordinates.lat(),
            longitude: coordinates.lng(),
        })
    }
    handleMapClick = ({ x, y, lat, lng, event }) => {
        const newPosition = new this.maps.LatLng(lat, lng)
        if (this.map && this.maps) {
            this.geocodeNewPosition(newPosition, true)
        }
    }
    geocodeNewPosition = (latLng, resetCoords) => {
        console.log("geocoding new position")
        const geocoder = new this.maps.Geocoder();
        geocoder.geocode({ latLng }, (results, status) => {
            if (status == this.maps.GeocoderStatus.OK && results.length) {
                const result = results[0];
                const parsedAddress = this.props.helpers.parseGoogleAddressComponents(result.address_components, true)
                const newState = {
                    address_line_1: parsedAddress.address_line_1 || "",
                    city: parsedAddress.city || "",
                    province: parsedAddress.province || "",
                    postal_code: parsedAddress.postal_code || "",
                }
                if (resetCoords) {
                    newState.latitude = result.geometry.location.lat()
                    newState.longitude = result.geometry.location.lng()
                }
                this.setStateIfMounted({
                    ...newState
                })
            }
        })
    }
    verifySecondaryAddress = async () => {
        if (this.state.warnedBadAddressInput) {
            this.organizeJobPostData();
        } else if (!this.state.displayCoordinates && this.maps && !(this.state.secondaryAddress.latitude || this.state.secondaryAddress.longitude)) {
            const geocoder = new this.maps.Geocoder();
            await geocoder.geocode({ address: `${this.state.secondaryAddress.address_line_1} ${this.state.secondaryAddress.city} ${this.state.secondaryAddress.province} ${this.state.secondaryAddress.postal_code}` }, (results, status) => {
                console.log('geocoder', results, status)
                if (status == this.maps.GeocoderStatus.OK && results.length) {
                    this.setStateIfMounted({
                        secondaryAddress: {
                            ...this.state.secondaryAddress,
                            latitude: results[0].geometry.location.lat(),
                            longitude: results[0].geometry.location.lng(),
                        }
                    }, () => this.organizeJobPostData())
                } else {
                    this.setStateIfMounted({
                        warnedBadAddressInput: true,
                        tabValue: '3',
                        loading: false
                    }, () => {
                        this.props.popAlert('warning', this.props.t('badAddress'), this.props.t('jobTranslations.errors.badAddress'))
                    })
                }
            })
        } else {
            this.organizeJobPostData();
        }
    }
    handleSubmit = event => {
        event.preventDefault();
        const addressData = {
            location_id: this.state.location_id,
            site: this.state.addressType == 'site' ? this.state.site : "",
            address: {
                country: this.state.country || "",
                address_line_1: this.state.address_line_1 || "",
                city: this.state.city || "",
                province: this.state.province || "",
                postal_code: this.state.postal_code || "",
                latitude: this.state.latitude || "",
                longitude: this.state.longitude || ""
            },
            addContactInfo: this.state.addContactInfo,
            contactInfo: {
                address_contact_first_name: this.state.address_contact_first_name || "",
                address_contact_last_name: this.state.address_contact_last_name || "",
                address_company_name: this.state.address_company_name || "",
                address_contact_email: this.state.address_contact_email || "",
                address_contact_phone: this.state.address_contact_phone || "",
            }
        }
        this.props.updateAddressInfo(addressData)
        this.props.setModalOpen(false)
    }
    render = () => {
        return (
            <Dialog
                open={true}
                aria-labelledby="form-dialog-title"
                fullWidth
                onBackdropClick={() => this.props.setModalOpen(false)}
                onEscapeKeyDown={() => this.props.setModalOpen(false)}
                scroll="body"
            >
                <form autoComplete="off" onSubmit={this.handleSubmit}>
                    <DialogTitle disableTypography id="form-dialog-title">
                        <Box flexDirection="row" justifyContent="space-between" alignItems="center" >
                            <Typography variant="h6" display="inline">Update Address</Typography>
                            <IconButton aria-label="close"
                                onClick={() => this.props.setModalOpen(false)}
                                style={{
                                    position: 'absolute',
                                    top: '0px',
                                    right: '0px'
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        {/* Reschedule */}
                    </DialogTitle>
                    <DialogContent>
                        {!this.props.requireSite && <Grid container spacing={10}>
                            <Grid item xs>
                                <FormControl component="fieldset" fullWidth>
                                    <FormLabel component="legend">Address Type</FormLabel>
                                    <RadioGroup
                                        aria-label="addressType"
                                        name="addressType"
                                        value={this.state.addressType}
                                        onChange={this.handleChange('addressType')}
                                        row
                                    >
                                        <FormControlLabel
                                            value={'site'}
                                            control={<Radio color="primary" required/>}
                                            label="Site"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value={'address'}
                                            control={<Radio color="primary" required/>}
                                            label="Address"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {this.state.addressType == 'site' && <Grid container spacing={10}>
                            <Grid item xs>
                                <FormControl fullWidth mt={2}>
                                    <Autocomplete
                                        id="site_autocomplete"
                                        disableClearable
                                        autoComplete
                                        clearOnBlur={false}
                                        options={this.props.sites}
                                        getOptionLabel={(option) => option.site_name}
                                        onChange={(event, val) => this.setStateIfMounted({ site: val })}
                                        onInputChange={(event, val, reason) => {
                                            if (reason != 'reset' || val) {
                                                this.setStateIfMounted({ searchSites: val })
                                            }
                                        }}
                                        inputValue={this.state.searchSites}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="searchSites"
                                                label={`Search for Sites`}
                                                type="text"
                                                required
                                                error={!this.state.site}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>}
                        <Grid container spacing={10}>
                            <Grid item xs={12}>
                                <GoogleMapReactWrapper>
                                    <GoogleMapReact
                                        options={this.getMapOptions}
                                        bootstrapURLKeys={{
                                            key: GMapAPIKey
                                        }}
                                        defaultCenter={{ lat: 40.75027, lng: -73.8542755 }}
                                        defaultZoom={16}
                                        yesIWantToUseGoogleMapApiInternals
                                        onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                                        onClick={this.handleMapClick}
                                    />
                                </GoogleMapReactWrapper>
                            </Grid>
                        </Grid>
                        {this.state.addressType === 'site' ? <>
                            {Boolean(this.state.site) && <Grid container spacing={10}>
                                <Grid item xs={12}>
                                    {Boolean(this.state.site.location_id) && <Typography color="textSecondary">
                                        {this.state.site.location_id}
                                    </Typography>}
                                    <Typography>
                                        {this.state.site.site_name}
                                    </Typography>
                                    {Boolean(this.state.site.address.address_line_1 && this.state.site.address.city && this.state.site.address.province) ? <>
                                        <Typography>
                                            {this.state.site.address.address_line_1},<br />
                                            {this.state.site.address.city}, {this.state.site.address.province} {this.state.site.address.postal_code}
                                        </Typography>
                                    </> : <>
                                        <Typography>
                                            {this.props.t('addressTranslations.latitude')}: {this.state.site.address.latitude}<br />
                                            {this.props.t('addressTranslations.longitude')}: {this.state.site.address.ongitude}
                                        </Typography>
                                    </>}
                                </Grid>
                            </Grid>}
                        </> : <>
                            <Grid container spacing={10}>
                                <Grid item xs={12} md={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<WorldIcon />}
                                        onClick={() => this.setStateIfMounted({ displayCoordinates: !this.state.displayCoordinates })}
                                    >
                                        {this.state.displayCoordinates ? this.props.t('addressTranslations.useAddress') : this.props.t('addressTranslations.useCoordinates')}
                                    </Button>
                                </Grid>
                            </Grid>
                            {!this.state.displayCoordinates ? <>
                                <Grid container spacing={10}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="country">{this.props.t('addressTranslations.country')}</InputLabel>
                                            <Select
                                                labelId="country"
                                                label={this.props.t('addressTranslations.country')}
                                                value={this.state.country}
                                                onChange={this.handleChange('country')}
                                                fullWidth
                                                inputProps={{
                                                    name: "country",
                                                    id: "country"
                                                }}
                                                required
                                                error={!this.state.country}
                                            >
                                                {this.props.supportedCountries.map(country => (
                                                    <MenuItem key={country.id} value={country}>{country.name}</MenuItem>
                                                ))}
                                            </Select>
                                            {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={10}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="address_line_1"
                                                label={this.props.t('addressTranslations.addressLine1')}
                                                value={this.state.address_line_1}
                                                onChange={this.handleChange('address_line_1')}
                                                type="text"
                                                inputRef={this.addressInput}
                                                required
                                                variant="outlined"
                                                error={!this.state.address_line_1}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={10}>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="city"
                                                label={this.props.t('addressTranslations.city')}
                                                value={this.state.city}
                                                onChange={this.handleChange('city')}
                                                type="text"
                                                required
                                                variant="outlined"
                                                error={!this.state.city}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="province"
                                                label={this.props.t('addressTranslations.province')}
                                                value={this.state.province}
                                                onChange={this.handleChange('province')}
                                                type="text"
                                                required
                                                variant="outlined"
                                                error={!this.state.province}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="postal_code"
                                                label={this.props.t('addressTranslations.postalCode')}
                                                value={this.state.postal_code}
                                                onChange={this.handleChange('postal_code')}
                                                type="text"
                                                required
                                                variant="outlined"
                                                error={!this.state.postal_code}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </> : <>
                                <Grid container spacing={10}>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="latitude"
                                                label={this.props.t('addressTranslations.latitude')}
                                                value={this.state.latitude}
                                                onChange={this.handleChange('latitude')}
                                                type="number"
                                                required
                                                variant="outlined"
                                                error={!this.state.latitude}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="longitude"
                                                label={this.props.t('addressTranslations.longitude')}
                                                value={this.state.longitude}
                                                onChange={this.handleChange('longitude')}
                                                type="number"
                                                required
                                                variant="outlined"
                                                error={!this.state.longitude}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </>}
                            <Grid container spacing={10}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="location_id"
                                            label="Location ID (Optional)"
                                            value={this.state.location_id}
                                            onChange={this.handleChange("location_id")}
                                            type="text"
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={10}>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Add Contact Info</FormLabel>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={this.state.addContactInfo}
                                                        onChange={this.handleChange("addContactInfo", 'boolean')}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </>}
                        {this.state.addContactInfo && <>
                            <Grid container spacing={10}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="address_company_name"
                                            label={"Location Company Name"}
                                            value={this.state.address_company_name}
                                            onChange={this.handleChange('address_company_name')}
                                            type="text"
                                            variant="outlined"
                                            disabled={this.state.addressType == 'site'}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={10}>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="address_contact_first_name"
                                            label={"Location Contact First Name"}
                                            value={this.state.address_contact_first_name}
                                            onChange={this.handleChange('address_contact_first_name')}
                                            type="text"
                                            variant="outlined"
                                            disabled={this.state.addressType == 'site'}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="address_contact_last_name"
                                            label={"Location Contact Last Name"}
                                            value={this.state.address_contact_last_name}
                                            onChange={this.handleChange('address_contact_last_name')}
                                            type="text"
                                            variant="outlined"
                                            disabled={this.state.addressType == 'site'}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={10}>        
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="address_contact_email"
                                            label={"Location Contact Email"}
                                            value={this.state.address_contact_email}
                                            onChange={this.handleChange('address_contact_email')}
                                            type="email"
                                            variant="outlined"
                                            disabled={this.state.addressType == 'site'}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="address_contact_phone"
                                            label={"Location Contact Phone"}
                                            value={this.state.address_contact_phone}
                                            onChange={this.handleChange('address_contact_phone')}
                                            type="text"
                                            variant="outlined"
                                            disabled={this.state.addressType == 'site'}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </>}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" type="button" onClick={() => this.props.setModalOpen(false)}>
                            {this.props.t('actions.cancel')}
                        </Button>
                        <Button variant="contained" color="primary" type="submit">
                            {this.props.t('actions.submit')}
                        </Button>
                    </DialogActions>
                </form>
                <NotificationSnackbar />
            </Dialog>
        )
    }
}
const msp = state => ({
    token: state.session.user.token,
    company: state.session.company,
    helpers: state.helpers,
    supportedCountries: state.entities.supportedCountries,
    sites: state.entities.sites,
})
const mdp = dispatch => ({
    popAlert: (type, title, msg) => dispatch(popAlert(type, title, msg)),
    getSupportedCountries: () => dispatch(getSupportedCountries()),
})
export default connect(msp, mdp)(withTranslation()(SetJobAddressModal))