import { TPAPIBase } from '../../env';
import i18n from '../../i18n';

export const getCompany = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getAllValidAdditionalCompanyInformationKeys = (token) => (
    fetch(`${TPAPIBase}/additional_company_information_keys`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getAdditionalCompanyInformation = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/additional_information`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const setAdditionalCompanyInformation = (token, companyID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/additional_information`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const getSpecificCompany = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)

export const getUsersCompanies = (token) => (
    fetch(`${TPAPIBase}/company`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getMinorityClassifications = (token) => (
    fetch(`${TPAPIBase}/minority_classifications`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getCompanyTIN = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/tin`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const createCompany = (token, formData) => (
    fetch(`${TPAPIBase}/company`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: formData
    }).then(response => response.json())
)
export const editCompany = (token, companyID, formData) => (
    fetch(`${TPAPIBase}/company/${companyID}/update`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: formData
    }).then(response => response.json())
)
export const postLegalDoc = (token, companyID, formData) => (
    fetch(`${TPAPIBase}/company/${companyID}/legal/create`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: formData
    }).then(response => response.json())
)
export const modifyLegalDoc = (token, companyID, legalDocID, formData) => (
    fetch(`${TPAPIBase}/company/${companyID}/legal/update/${legalDocID}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: formData
    }).then(response => response.json())
)
export const getPreferredSuppliers = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/company_supplier/get`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getPreferredSuppliersList = (token, companyID, query) => (
    fetch(`${TPAPIBase}/company/${companyID}/company_supplier_ac?query=${query}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const addPreferredSupplier = (token, companyID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/company_supplier/add`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const deletePreferredSupplier = (token, companyID, supplierCompanyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/company_supplier/remove`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: {
                company_supplier_id: supplierCompanyID
            }
        })
    }).then(response => response.json())
)
export const getPreferredCustomers = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/company_customer/get`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getPreferredCustomerList = (token, companyID, query) => (
    fetch(`${TPAPIBase}/company/${companyID}/company_customer_ac?query=${query}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const addPreferredCustomer = (token, companyID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/company_customer/add`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const deletePreferredCustomer = (token, companyID, customerCompanyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/company_customer/remove`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: {
                company_customer_id: customerCompanyID
            }
        })
    }).then(response => response.json())
)
export const getPreferredDestinations = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/company_demolition_destination_company/get`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getPreferredDestinationList = (token, companyID, query) => (
    fetch(`${TPAPIBase}/company/${companyID}/company_demolition_destination_company_ac?query=${query}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const addPreferredDestination = (token, companyID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/company_demolition_destination_company/add`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const deletePreferredDestination = (token, companyID, destinationCompanyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/company_demolition_destination_company/remove`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: {
                demolition_destination_company_id: destinationCompanyID
            }
        })
    }).then(response => response.json())
)
export const getPreferredDrivers = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/preferred_driver/get`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getPreferredDriverCompaniesByEmail = (token, companyID, email) => (
    fetch(`${TPAPIBase}/company/${companyID}/preferred_drivers/find?email=${email}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const addPreferredDriver = (token, companyID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/preferred_driver/add`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const deletePreferredDriver = (token, companyID, driverCompanyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/preferred_driver/remove`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: {
                driver_company_id: driverCompanyID
            }
        })
    }).then(response => response.json())
)
export const getCompaniesWherePreferred = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/preferred_driver_as_preferred/get`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
    }).then(response => response.json())
)
export const removeCompanyWherePreferred = (token, companyID, otherCompanyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/preferred_driver_as_preferred/remove/${otherCompanyID}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getAuthorizedSignOffMembers = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/sign_off_authorized`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const DEPRECATED_getCustomMaterials = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/custom-materials`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const deleteCustomMaterial = (token, companyID, materialID) => (
    fetch(`${TPAPIBase}/company/${companyID}/custom-materials`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: [ materialID ]
        })
    }).then(response => response.json())
)
export const getCompanyEmployees = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/employees`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const inviteEmployee = (token, data) => (
    fetch(`${TPAPIBase}/invite`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const getCompanyDrivers = (token, companyID, jobID, radius) => (
    fetch(`${TPAPIBase}/company/${companyID}/drivers/job/${jobID}/radius/${radius}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getCompanySettings = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/settings`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const updateCompanySettings = (token, companyID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/profile`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const getPrivateNotificationsCount = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/notifications/private/count`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getPrivateCompanyNotifications= (token, companyID, pageNumber) => (
    fetch(`${TPAPIBase}/company/${companyID}/notifications/private?page=${pageNumber}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getCompanyNotifications = (token, companyID, pageNumber) => (
    fetch(`${TPAPIBase}/company/${companyID}/notifications?page=${pageNumber}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const readNotification = (token, companyID, notificationID) => (
    fetch(`${TPAPIBase}/company/${companyID}/notifications/${notificationID}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: {
                is_read: true
            }
        })
    }).then(response => response.json())
)
export const getRestrictionDate = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/restricted-as-of-date`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).catch(err => console.log(err))
)
export const getPendingInvitations = (token, companyID, options = { includePast: false }) => (
    fetch(`${TPAPIBase}/company/${companyID}/invitations/pending?include_past_deadline=${options.includePast ? 1 : 0}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const refreshInvitation = (token, companyID, invitationID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/invitations/${invitationID}/refresh`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const getPreferredCompanyVehicles = (token, companyID, preferredCompanyID, page = 1, typeIDArray = []) => {
    const formattedParams = typeIDArray.map(id => `type[]=${id}`).join('&')
    return fetch(`${TPAPIBase}/company/${companyID}/preferred/${preferredCompanyID}/vehicles?page=${page}&${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}
export const setPreferredViewVehiclesPermission = (token, companyID, preferredCompanyID, allowViewing) => (
    fetch(`${TPAPIBase}/company/${companyID}/preferred_by/${preferredCompanyID}/view_vehicles_permission/${allowViewing ? 1 : 0}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getVehicleIdentifiers = (token, companyID, paramsObject = {}) => {
    const params = []
    Object.keys(paramsObject).forEach(key => {
        if (typeof paramsObject[key] == 'object') {
            paramsObject[key].forEach(vehicleID => {
                params.push({ k: 'vehicle_ids[]', v: vehicleID })
            })
        } else {
            params.push({ k: key, v: paramsObject[key] })
        }
    })
    const formattedParams = params.map(param => `${param.k}=${param.v}`).join('&')
    return fetch(`${TPAPIBase}/company/${companyID}/preferred/vehicle_identifiers?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}
export const setPreferredBidOnBehalfPermission = (token, companyID, preferredCompanyID, allowBidding) => (
    fetch(`${TPAPIBase}/company/${companyID}/preferred_by/${preferredCompanyID}/bid_on_behalf_permission/${allowBidding ? 1 : 0}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const setPreferredDefaultDispatchingPermission = (token, companyID, preferredCompanyID, allowDispatching) => (
    fetch(`${TPAPIBase}/company/${companyID}/preferred_by/${preferredCompanyID}/default_can_dispatch/${allowDispatching ? 1 : 0}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getAllVehicleIdentifiers = (token, companyID, paramsObject = {}) => {
    return new Promise(async (resolve) => {
        let page = 1
        let fetchedAll = false
        const allIdentifiers = []
        while (!fetchedAll) {
            await getVehicleIdentifiers(token, companyID, { ...paramsObject, page }).then(res => {
                if (res.success) {
                    allIdentifiers.push(...res.data.data)
                    if (res.data.next_page_url) {
                        page += 1;
                    } else {
                        fetchedAll = true
                        resolve({
                            success: true,
                            data: allIdentifiers
                        })
                    }
                } else {
                    fetchedAll = true
                    resolve(res)
                }
            })
        }
    })
}
export const setVehicleIdentifiers = (token, companyID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/preferred/vehicle_identifiers`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const deleteVehicleIdentifiers = (token, companyID, vehicleIDs = []) => {
    const formattedParams = vehicleIDs.map(id => `vehicle_identifier_ids[]=${id}`).join('&')
    return fetch(`${TPAPIBase}/company/${companyID}/preferred/vehicle_identifiers?${formattedParams}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}
export const softDeleteCompany = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: { delete: true }
        })
    }).then(response => response.json())
)
export const setCompanyIdentifier = (token, companyID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/identifiers`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)