
import { Typography } from "@material-ui/core";
import { SpacedTypography } from "./SpacedComponents"
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

interface Props {
    title: string;
    value: string;
    mb?: number;
}

const DataInfo = ({
    title,
    value,
    mb = 5
}: Props) => {
    return <>
        <Typography color="textSecondary">
            {title}
        </Typography>
        <SpacedTypography mb={mb} >
            {value}
        </SpacedTypography>
    </>
}

export default DataInfo