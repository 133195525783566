import { TPAPIBase } from '../../env';
import i18n from '../../i18n';
import { CustomMaterial, LengthUnit, LengthUnits, Material, MixBatch, MixDesign, TemperatureUnit, TemperatureUnits, VolumeUnit, VolumeUnits, WeightUnit, WeightUnits } from '../reducers/entities';
import { ConfirmationResponse, Paginate, PaginatedAPIOptions, TPResponse } from './util';

const roundDesignTargets = (design: MixDesign): MixDesign => {
    if (design.target_volume) design.target_volume = parseFloat(design.target_volume.toFixed(2))
    if (design.target_weight) design.target_weight = parseFloat(design.target_weight.toFixed(2))
    if (design.target_temperature) design.target_temperature = parseFloat(design.target_temperature.toFixed(2))
    if (design.target_slump) design.target_slump = parseFloat(design.target_slump.toFixed(2))
    if (design.target_air_content_percentage) design.target_air_content_percentage = parseFloat(design.target_air_content_percentage).toFixed(2)
    if (design.constituents) {
        design.constituents = design.constituents.map(constituent => {
            if (constituent.target_volume) constituent.target_volume = parseFloat(constituent.target_volume.toFixed(2))
            if (constituent.target_weight) constituent.target_weight = parseFloat(constituent.target_weight.toFixed(2))
            return constituent
        })
    }
    return design
}

export const getCompanyMaterialMixDesigns = (token: string, companyID: number, options: PaginatedAPIOptions = {}): Promise<TPResponse<Paginate<MixDesign[]>>> => {
    const formattedParams: string = Object.keys(options).map(optionKey => `${optionKey}=${options[optionKey]}`).join("&")    
    return fetch(`${TPAPIBase}/company/${companyID}/material-mix-designs?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).catch(e => console.log(e))
}
export const getAllCompanyMaterialMixDesigns = async (token: string, companyID: number): Promise<TPResponse<MixDesign[]>> => {
    const data: MixDesign[] = []
    let page = 1
    let fetchedAll = false
    while (!fetchedAll) {
        const response = await getCompanyMaterialMixDesigns(token, companyID, { page })
        if (!response.success) {
            fetchedAll = true;
            break;
        } 
        data.push(...response.data.data)
        if (response.data.next_page_url) {
            page += 1
        } else {
            fetchedAll = true
        }
    }
    return {
        success: true,
        error: null,
        data: data
    }
}
export const getMaterialMixDesignByID = (token: string, companyID: number, mixDesignID: number): Promise<TPResponse<MixDesign>> => {   
    return fetch(`${TPAPIBase}/company/${companyID}/material-mix-designs/${mixDesignID}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then((res: TPResponse<MixDesign>) => {
        if (res.data) {
            res.data = roundDesignTargets(res.data)
        }
        return res
    })
}
export const getMaterialMixDesignHistory = (token: string, companyID: number, mixDesignID: number): Promise<TPResponse<Paginate<MixDesign[]>>> => {   
    return fetch(`${TPAPIBase}/company/${companyID}/material-mix-designs/${mixDesignID}/history`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).catch(e => console.log(e))
}
export interface MaterialMixDesignConstituentPayload {
    [key: string]: any;
    material_id: number | null;
    material?: Material;
    custom_material_id: number | null;
    customMaterial?: CustomMaterial;
    // notes: string;
    target_weight?: number;
    weight_units?: string;
    target_volume?: number;
    volume_units?: string;
}
export interface MaterialMixDesignPayload {
    delivery_id?: number;
    mix_id: string;
    name: string;
    custom_material_list_name: string;
    custom_material_description: string;
    custom_material_handling_and_additional_info: string;
    weight_units: string; 
    target_volume: number;
    volume_units: string;
    target_slump?: number;
    slump_units?: string;
    target_temperature?: number;
    temperature_units?: string;
    target_mixing_revolutions?: number;
    target_air_content_percentage?: number;
    constituents: MaterialMixDesignConstituentPayload[];
}
export const createMaterialMixDesign = (token: string, companyID: number, data: MaterialMixDesignPayload): Promise<TPResponse<Paginate<MixDesign[]>>> => {
    return fetch(`${TPAPIBase}/company/${companyID}/material-mix-designs`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({ data })
    }).then(response => response.json()).catch(e => console.log(e))
}
export const updateMaterialMixDesign = (token: string, companyID: number, mixDesignID: number, data: MaterialMixDesignPayload): Promise<TPResponse<Paginate<MixDesign[]>>> => {   
    return fetch(`${TPAPIBase}/company/${companyID}/material-mix-designs/${mixDesignID}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({ data })
    }).then(response => response.json()).catch(e => console.log(e))
}

// MIX BATCHES

export const getCompanyMaterialMixBatches = (token: string, companyID: number, options: PaginatedAPIOptions = {}): Promise<TPResponse<Paginate<MixBatch[]>>> => {
    const formattedParams: string = Object.keys(options).map(optionKey => `${optionKey}=${options[optionKey]}`).join("&")    
    return fetch(`${TPAPIBase}/company/${companyID}/material-mix-batches?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).catch(e => console.log(e))
}
export const getAllCompanyMaterialMixBatches = async (token: string, companyID: number): Promise<TPResponse<MixBatch[]>> => {
    const data: MixBatch[] = []
    let page = 1
    let fetchedAll = false
    while (!fetchedAll) {
        const response = await getCompanyMaterialMixBatches(token, companyID, { page })
        if (!response.success) {
            fetchedAll = true;
            break;
        } 
        data.push(...response.data.data)
        if (response.data.next_page_url) {
            page += 1
        } else {
            fetchedAll = true
        }
    }
    return {
        success: true,
        error: null,
        data: data
    }
}
export const getCompanyMaterialMixBatchByID = (token: string, companyID: number, mixBatchID: number): Promise<TPResponse<MixBatch>> => {   
    return fetch(`${TPAPIBase}/company/${companyID}/material-mix-batches/${mixBatchID}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).catch(e => console.log(e))
}
export const getCompanyMaterialMixBatchHistory = (token: string, companyID: number, mixBatchID: number): Promise<TPResponse<Paginate<MixBatch[]>>> => {   
    return fetch(`${TPAPIBase}/company/${companyID}/material-mix-batches/${mixBatchID}/history`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).catch(e => console.log(e))
}
export interface MaterialMixBatchConstituentPayload {
    [key: string]: any;
    material?: Material;
    customMaterial?: CustomMaterial;
    primary_measurement_type?: "weight" | "volume";
    amendment_custom_material_id?: number;
    material_mix_design_constituent_id?: number;
    weight?: number | null;
    weight_units?: string | null;
    volume?: number | null;
    volume_units?: string | null;
}
export interface MaterialMixBatchPayload {
    material_mix_design_id: number;
    site_id: number;
    batch_ticket_number: string;
    delivery_id?: number;
    volume: number;
    volume_units: string;
    slump?: number;
    slump_units?: string;
    temperature?: number;
    temperature_units?: string;
    mixing_revolutions?: number;
    air_content_percentage?: number;
    constituents: MaterialMixBatchConstituentPayload[];
    is_signature_applied: boolean;
}
export const createMaterialMixBatch = (token: string, companyID: number, data: MaterialMixBatchPayload): Promise<TPResponse<MixDesign>> => {
    return fetch(`${TPAPIBase}/company/${companyID}/material-mix-batches`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({ data })
    }).then(response => response.json()).catch(e => console.log(e))
}
export const updateMaterialMixBatch  = (token: string, companyID: number, data: MaterialMixBatchPayload): Promise<TPResponse<MixDesign>> => {   
    return fetch(`${TPAPIBase}/company/${companyID}/material-mix-batches`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({ data })
    }).then(response => response.json()).catch(e => console.log(e))
}
export const getDeliveryMaterialMixBatch  = (token: string, companyID: number, deliveryID: number): Promise<TPResponse<MixBatch>> => {   
    return fetch(`${TPAPIBase}/company/${companyID}/delivery/${deliveryID}/material-mix-batch`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).catch(e => console.log(e))
}
export const setDeliveryMaterialMixBatch  = (token: string, companyID: number, deliveryID: number, mixBatchID: number): Promise<TPResponse<MixBatch>> => {   
    return fetch(`${TPAPIBase}/company/${companyID}/delivery/${deliveryID}/material-mix-batch/${mixBatchID}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).catch(e => console.log(e))
}

