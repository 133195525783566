import React from 'react';
import GoogleMapReact from "google-map-react";
import { GMapAPIKey } from '../../env'
import styled from "styled-components";
import { useTranslation } from 'react-i18next'
import {
    Card as MuiCard,
    CardMedia as MuiCardMedia,
    CardHeader,
    CardContent,
    Grid,
    Divider as MuiDivider,
    Typography as MuiTypography,
    TableRow,
    TableCell
} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton'
import { spacing } from "@material-ui/system";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    darkCard: {
        backgroundColor: "#F0F0F0",
    },
}));

const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);
const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

function SkeletonBannerCard() {
    return (
        <Card mb={6}>
            <CardMedia image={"/static/img/site_photo.jpg"} />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    <Skeleton animation="wave" height={50} width="30%" />
                </Typography>
                <Grid container justify="space-between">
                    <Grid item xs={12} md={4}>
                        <Typography component="p">
                            <Skeleton animation="wave" width="40%" />
                        </Typography>
                        <Typography component="p">
                            <Skeleton animation="wave" width="40%" />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography component="p">
                            <Skeleton animation="wave" width="40%" />
                        </Typography>
                        <Typography component="p">
                            <Skeleton animation="wave" width="40%" />
                        </Typography>
                        <Typography component="p">
                            <Skeleton animation="wave" width="40%" />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography component="p">
                            <Skeleton animation="wave" width="40%" />
                        </Typography>
                        <Typography component="p">
                            <Skeleton animation="wave" width="40%" />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography component="p">
                            <Skeleton animation="wave" width="40%" />
                        </Typography>
                        <Typography component="p">
                            <Skeleton animation="wave" width="40%" />
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

const GoogleMapReactWrapper = styled.div`
  height: 300px;
  width: 100%;
`;


function SkeletonMapCard() {
    const { t } = useTranslation();
    const classes = useStyles();

    const options = {
        center: {
            lat: 40.7128,
            lng: -74.0060
        },
        zoom: 14
    };

    const getMapOptions = maps => {
        return {
            fullscreenControl: true,
            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.ROADMAP,
            scaleControl: true,
            scrollwheel: false,
            streetViewControl: true
        };
    };

    return (
        <Card mb={6} raised>
            <CardHeader title={t('addressTranslations.location')} />
            <GoogleMapReactWrapper>
                <GoogleMapReact
                    options={getMapOptions}
                    bootstrapURLKeys={{
                        key: GMapAPIKey
                    }}
                    defaultCenter={options.center}
                    defaultZoom={options.zoom}
                />
            </GoogleMapReactWrapper>
            <CardContent>
                <Typography component="p">
                    <Skeleton animation="wave" width="40%" />
                </Typography>
                <Typography component="p">
                    <Skeleton animation="wave" width="40%" />
                </Typography>
                <Typography component="p">
                    <Skeleton animation="wave" width="40%" />
                </Typography>
            </CardContent>
        </Card>

    )
}


function SkeletonDetailsCard({title}) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Card mb={6} raised >
            <CardHeader title={title ? title : t('details')} />
            <CardContent>
                <Typography component="p">
                    <Skeleton animation="wave" width="25%" />
                </Typography>
                <Typography component="p" mb={4}>
                    <Skeleton animation="wave" width="40%" />
                </Typography>

                <Typography component="p">
                    <Skeleton animation="wave" width="25%" />
                </Typography>
                <Typography component="p" mb={4}>
                    <Skeleton animation="wave" width="40%" />
                </Typography>
                <Typography component="p">
                    <Skeleton animation="wave" width="25%" />
                </Typography>
                <Typography component="p" mb={4}>
                    <Skeleton animation="wave" width="40%" />
                </Typography>
                <Typography component="p">
                    <Skeleton animation="wave" width="25%" />
                </Typography>
                <Typography component="p" mb={4}>
                    <Skeleton animation="wave" width="40%" />
                </Typography>

            </CardContent>
        </Card>
    );
}

function SkeletonJobListRow({ }) {
    return (
        <TableRow>
            <TableCell colSpan={200}>
                <Typography variant="h5">
                    <Skeleton animation="wave" width="23%" />
                </Typography>
                <Typography>
                    <Skeleton animation="wave" width="13%" />
                </Typography>
                <Typography>
                    <Skeleton animation="wave" width="60%" />
                </Typography>
                <Typography variant="h6">
                    <Skeleton animation="wave" width="20%" />
                </Typography>
            </TableCell>
        </TableRow>
    )
}

function SkeletonSiteListCard() {
    return (
        <Card mb={6}>
            <CardMedia
                image={"/static/img/site_photo.jpg"}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    <Skeleton animation="wave" width="40%" height={35}/>
                </Typography>
                <Grid container justify="space-between">
                    <Grid item xs={12} md={4}>
                        <Typography component="p" color="textSecondary">
                            <Skeleton animation="wave" width="30%" />
                        </Typography>
                        <Typography component="p">
                            <Skeleton animation="wave" width="50%" />
                        </Typography>
                        <Typography component="p">
                            <Skeleton animation="wave" width="50%" />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography component="p" color="textSecondary">
                            <Skeleton animation="wave" width="30%" />
                        </Typography>
                        <Typography component="p">
                            <Skeleton animation="wave" width="45%" />
                        </Typography>
                        <Typography component="p">
                            <Skeleton animation="wave" width="45%" />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography component="p" color="textSecondary">
                            <Skeleton animation="wave" width="30%" />
                        </Typography>
                        <Typography component="p">
                            <Skeleton animation="wave" width="50%" />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography component="p" color="textSecondary">
                            <Skeleton animation="wave" width="60%" />
                        </Typography>
                        <Typography component="p">
                            <Skeleton animation="wave" width="30%" />
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default {
    SkeletonBannerCard,
    SkeletonMapCard,
    SkeletonDetailsCard,
    SkeletonJobListRow,
    SkeletonSiteListCard
}