import { Button, Checkbox, Collapse, Grid, IconButton, Paper as MuiPaper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import { CloudUpload, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useRootSelector } from "../../..";
import { popAlert } from "../../../redux/actions/sessionActions";
import { DeliveryApi, DistributiveOmit, JobInspectorAssignment, JobQuery, VolumeUnits } from "../../../redux/reducers/entities";
import { convertVolume, convertWeight, formatNumber, getFormattedDateFromUnix, getFormattedLongDate, getFormattedTimeFromUnix, parseError } from "../../../redux/reducers/helpersReducerr";
import { getDeliveriesOfJobsByQuery } from "../../../redux/util/deliveryAPI";
import SkeletonCards from "../../components/SkeletonCards";
import ExportDeliveriesModal from "../Export/modals/ModalExportDeliveries";
import { QueryHandler } from "../Jobs/components/QueryHandler";
import DeliveryComponent from "./components/Delivery";
import ViewDeliveryModal from "./modals/ModalViewDelivery";
import DeliveryETA from "./subcomponents/DeliveryETA";

const Paper = styled(MuiPaper)(spacing)

interface Props {

}
const Deliveries = ({

}: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const token = useRootSelector(state => state.session.user.token!)
    const companyID = useRootSelector(state => state.session.company.id!)

    const [deliveries, setDeliveries] = useState<DeliveryApi[] | null>([])
    const [finalQuery, setFinalQuery] = useState<DistributiveOmit<JobQuery, "type">>({})

    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        requiresPagination: false,
    })

    useEffect(() => {
        if (pagination.requiresPagination) {
            getDeliveries()
        }
    }, [pagination.page])

    const getDeliveries = async (query: DistributiveOmit<JobQuery, "type"> = finalQuery) => {
        setDeliveries(null)
        const response = await getDeliveriesOfJobsByQuery(token, companyID, query, { page: pagination.page + 1 })
        if (!response.success) {
            const errorMsg = parseError(response)
            dispatch(popAlert('error', t('error'), errorMsg))
            setDeliveries([])
            return
        }
        setPagination({
            ...pagination,
            count: response.data.total,
            requiresPagination: response.data.total > 20
        })
        setDeliveries(response.data.data)
    }

    return <>
        <Helmet title="Delivery Query"/>
        <Grid container spacing={10}>
            <Grid item xs={12}>
                <Paper variant="elevation" p={3}>
                    <Grid container spacing={10} alignItems="center">
                        <Grid item xs>
                            <Typography variant="h3">Deliveries Of Jobs By Query</Typography>
                        </Grid>
                    </Grid>
                    <QueryHandler 
                        buttonOnClick={(isValid, finalQuery) => {
                            console.log("button clicked", isValid, finalQuery)
                            if (isValid) {
                                setFinalQuery(finalQuery)
                                getDeliveries(finalQuery)
                            }
                        }}
                        buttonText="Get Deliveries"
                        onQueryChanged={(query) => {
                            setPagination({
                                page: 0,
                                count: 0,
                                requiresPagination: false
                            })
                        }}
                    />
                </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={10}>
            <Grid item xs={12}>
                <Paper variant="elevation" p={3}>
                    <Grid container spacing={10}>
                        <Grid item xs={12}>
                            <DeliveriesTable 
                                deliveries={deliveries}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    </>
}

export default Deliveries

interface DeliveriesTableProps {
    deliveries: DeliveryApi[] | null;
    forInspector?: boolean;
    inspectorAssignment?: JobInspectorAssignment;
    refreshDeliveries?: () => void;
    inspectionType?: "pickup" | "dropoff"
}

export const DeliveriesTable = ({
    deliveries,
    forInspector,
    inspectorAssignment,
    refreshDeliveries,
    inspectionType
}: DeliveriesTableProps) => {
    const { t } = useTranslation()

    const [showExport, setShowExport] = useState(false)
    const [openExportDeliveriesModal, setOpenExportDeliveriesModal] = useState(false)
    const [exportDeliveryIds, setExportDeliveryIds] = useState<number[]>([])

    useEffect(() => {
        if (!showExport) {
            setExportDeliveryIds([])
        }
    }, [showExport])

    const toggleCheckedDelivery = (deliveryId: number, checked: boolean) => {
        if (checked && !exportDeliveryIds.includes(deliveryId)) {
            setExportDeliveryIds(curVal => [...curVal, deliveryId])
        } else {
            setExportDeliveryIds(curVal => curVal.filter(id => id !== deliveryId))
        }
    }

    const [selectedDeliveryID, setSelectedDeliveryID] = useState(0)
    const toggleSelectedDelivery = (deliveryID: number) => {
        setSelectedDeliveryID(selectedDeliveryID === deliveryID ? 0 : deliveryID)
    }

    return <TableContainer>
        <Table>
            <TableHead>
                {(deliveries && deliveries.length > 0) && <TableRow>
                    <TableCell colSpan={9}>
                        <Grid container spacing={5}>
                            {!showExport ? <>
                                <Grid item>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => setShowExport(true)}
                                    >
                                        Export
                                    </Button>
                                </Grid>
                            </> : <>
                                <Grid item>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled={!exportDeliveryIds.length}
                                        onClick={() => setOpenExportDeliveriesModal(true)}
                                    >
                                        Export Selected Deliveries
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        onClick={() => setShowExport(false)}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </>}
                        </Grid>
                    </TableCell>
                </TableRow>}
                <TableRow>
                    <TableCell />
                    <TableCell align="center"><Typography color="textSecondary" variant="h6" align="center">{t('siteTranslations.siteName')}</Typography></TableCell>
                    <TableCell align="center"><Typography color="textSecondary" variant="h6" align="center">{t('jobTranslations.jobName')}</Typography></TableCell>
                    <TableCell align="center"><Typography color="textSecondary" variant="h6" align="center">{t('deliveryTranslations.ticketNumber')}</Typography></TableCell>
                    <TableCell align="center"><Typography color="textSecondary" variant="h6" align="center">{t('vehicleTranslations.truckID')}</Typography></TableCell>
                    <TableCell align="center"><Typography color="textSecondary" variant="h6" align="center">{t('materialTranslations.material')}</Typography></TableCell>
                    <TableCell align="center"><Typography color="textSecondary" variant="h6" align="center">Load Time</Typography></TableCell>
                    <TableCell align="center"><Typography color="textSecondary" variant="h6" align="center">OTJ Time</Typography></TableCell>
                    <TableCell align="center"><Typography color="textSecondary" variant="h6" align="center">{t('timeTranslations.totalDuration')}</Typography></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Boolean(deliveries === null || deliveries === undefined) ? <>
                    <SkeletonCards.SkeletonJobListRow />
                </> : <>
                    {!deliveries!.length && <TableRow>
                        <TableCell colSpan={9}>
                            {t('deliveryTranslations.errors.noDeliveriesFound')}
                        </TableCell>
                    </TableRow>}
                    {deliveries!.map(delivery => <React.Fragment key={delivery.id}>
                        <DeliveryRow 
                            delivery={delivery}
                            selectedDeliveryID={selectedDeliveryID}
                            toggleSelectedDelivery={toggleSelectedDelivery}
                            showExport={showExport}
                            isSelected={exportDeliveryIds.includes(delivery.id)}
                            onCheckboxClick={toggleCheckedDelivery}
                        />
                        {
                            true ? <>
                                {selectedDeliveryID === delivery.id && <ViewDeliveryModal 
                                    setModalOpen={(bool) => setSelectedDeliveryID(bool ? selectedDeliveryID : 0)}
                                    deliveryID={delivery.id}
                                    asInspector={forInspector}
                                    inspectorAssignment={inspectorAssignment}
                                    refreshData={refreshDeliveries}
                                    inspectionType={inspectionType}
                                />}
                            </> : <>
                                <TableRow>
                                    <TableCell colSpan={8} padding={selectedDeliveryID === delivery.id ? "default" : "none"}>
                                        <Collapse in={selectedDeliveryID === delivery.id}>
                                            <DeliveryComponent 
                                                delivery={delivery} 
                                                expanded={selectedDeliveryID === delivery.id}
                                                forInspector={forInspector}
                                                inspectorAssignment={inspectorAssignment}
                                                refreshDeliveries={refreshDeliveries}
                                            />
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </>
                        }
                    </React.Fragment>)}
                </>}
                {openExportDeliveriesModal && <ExportDeliveriesModal 
                    setModalOpen={setOpenExportDeliveriesModal}
                    deliveryIds={exportDeliveryIds}
                />}
            </TableBody>
        </Table>
    </TableContainer>
}

interface DeliveryRowProps {
    selectedDeliveryID: number;
    toggleSelectedDelivery: (id: number) => void;
    delivery: DeliveryApi;
    showExport: boolean;
    isSelected: boolean;
    onCheckboxClick: (id: number, checked: boolean) => void;
}
const DeliveryRow = ({ 
    selectedDeliveryID, 
    toggleSelectedDelivery,
    delivery,
    showExport,
    isSelected,
    onCheckboxClick,
}: DeliveryRowProps) => {
    const { t } = useTranslation()
    const deliveryWeight = useMemo(() => {
        const unit = delivery.job.estimated_amount_units || delivery.weight_unit
        const value = convertWeight(delivery.net_weight, delivery.weight_unit, unit)
        return `${formatNumber(value, { forCapacities: true })} ${t(`unitTranslations.${unit}`)}`
    }, [delivery])
    const deliveryVolume = useMemo(() => {
        const unit = delivery.job.estimated_volume_units || VolumeUnits.CUBIC_METER
        const value = convertVolume(parseFloat(delivery.cubic_meters), VolumeUnits.CUBIC_METER, unit)
        return `${formatNumber(value, { forCapacities: true })} ${t(`unitTranslations.${unit}`)}`
    }, [delivery])
    return (
        <TableRow hover={true} style={{ backgroundColor: delivery.is_voided ? "rgba(255, 0, 0, 0.9)" : "unset" }}>
            {!showExport ? <TableCell align="center">
                <IconButton size="small" onClick={() => toggleSelectedDelivery(delivery.id)}>
                    {selectedDeliveryID === delivery.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
            </TableCell> : <TableCell padding="checkbox">
                <Checkbox
                    checked={isSelected}
                    onChange={(_, checked) => onCheckboxClick(delivery.id, checked)}
                    value="on"
                />
            </TableCell>}
            <TableCell align="center"><Typography align="center">{delivery.site.site_name}</Typography></TableCell>
            <TableCell align="center"><Typography align="center">{delivery.job.name}</Typography></TableCell>
            <TableCell align="center"><Typography align="center">{delivery.external_ticket_id || delivery.ticket_number}</Typography></TableCell>
            <TableCell align="center"><Typography align="center">{delivery.external_vehicle_identifier || delivery.vehicle?.name || "N/A"}</Typography></TableCell>
            <TableCell align="center">
                {delivery.materials.length > 0 && <Tooltip title={delivery.displayMaterials || delivery.materials.map(mat => mat.name ? mat.name : `${mat.custom_material_number && `${mat.custom_material_number} - `}${mat.custom_material_name}`).join(", ")} placement="top">
                    <>
                        {delivery.materials[0].custom_material_number && <Typography align="center" noWrap>
                            {delivery.materials[0].custom_material_number}
                        </Typography>}
                        <Typography noWrap>
                            {delivery.materials[0].name || delivery.materials[0].custom_material_name}
                        </Typography>
                        {delivery.materials.length > 1 && <Typography>
                            +{delivery.materials.length - 1}
                        </Typography>}
                    </>
                </Tooltip>}
                {Boolean(delivery.job.transaction_type_id == 2 && delivery.net_weight) && <Typography align="center">
                    {deliveryWeight}
                </Typography>}
                {Boolean(delivery.job.transaction_type_id == 4 && delivery.cubic_meters) && <Typography align="center">
                    {deliveryVolume}
                </Typography>}
            </TableCell>
            <TableCell align="center"><Typography align="center">{getFormattedTimeFromUnix(delivery.checkin, { keepLocal: true, noTimezone: true })}</Typography></TableCell>
            <TableCell align="center"><Typography align="center">{getFormattedTimeFromUnix(delivery.checkout, { keepLocal: true, noTimezone: true })}</Typography></TableCell>
            <TableCell align="center">
                {delivery.detailedStatus == 'completed' ? (
                    <Typography align="center">{delivery.total_duration}</Typography>
                ) : <>
                    <Typography align="center">{t(`deliveryTranslations.detailedStatuses.${delivery.detailedStatus}`)}</Typography>
                    {(!delivery.is_canceled && !delivery.is_voided) && <DeliveryETA delivery={delivery} />}
                </>}
            </TableCell>
        </TableRow>
    )
}