import React from 'react';
import {
    Modal,
    Tab,
    Tabs,
    TabContent,
    TabScrollButton,
    TabList,
    Paper as MuiPaper,
    AppBar,
    Toolbar,
    Typography as MuiTypography,
    Grid as MuiGrid,
    FormGroup,
    FormControl as MuiFormControl,
    FormControlLabel,
    FormLabel,
    FormHelperText,
    Input,
    InputLabel,
    Select,
    TextField,
    MenuItem,
    Button,
    Switch,
    Radio,
    RadioGroup,
    Divider,
    InputAdornment,
    Box
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'
import { popAlert, setDutyStatus } from '../../../redux/actions/sessionActions';

import { spacing } from "@material-ui/system";
import styled from "styled-components";

const Paper = styled(MuiPaper)(spacing);
const Typography = styled(MuiTypography)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Bold = styled.span`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: black;
`;
const SidebarBanner = styled.div`
  color: ${props => props.theme.sidebar.color};
  padding: ${props => props.theme.spacing(4)}px
    ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;
const SidebarBannerBox = styled(Box)`
  ${spacing}

  border: 1px solid ${props => props.theme.palette.primary.main}
`;

class OnDutyStatusHandler extends React.Component {

    handleChange = (event) => this.props.setDutyStatus(this.props.token, event.target.checked)
    
    render = () => {
        if (!this.props.company || !this.props.company.title || !this.props.company.entity_type) return null;
        if (this.props.company.title != 'Truck Driver' && this.props.company.entity_type.name != 'independent-owner-operator') return null;
        return (
            <SidebarBanner>
                <SidebarBannerBox borderColor="#FF6600" border={1} p={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" style={{ color: "white", fontWeight: "bold" }}>{this.props.t('userTranslations.onDuty')}</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.props.isOnDuty}
                                        onChange={this.handleChange}
                                        color="primary"
                                    />
                                }
                            />
                        </FormGroup>
                    </FormControl>
                </SidebarBannerBox>
            </SidebarBanner>
        )
    }
}
const msp = state => ({
    token: state.session.user.token,
    isOnDuty: state.session.isOnDuty
})
const mdp = dispatch => ({
    popAlert: (success, title, message) => dispatch(popAlert(success, title, message)),
    setDutyStatus: (token, isOnDuty) => dispatch(setDutyStatus(token, isOnDuty)),
})
export default connect(msp, mdp)(withTranslation()(OnDutyStatusHandler))