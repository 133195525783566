import { TPAPIBase } from '../../env';
import { functions } from '../reducers/helpersReducer';
import i18n from '../../i18n';
import { formatParams } from '../reducers/helpersReducerr';

export const assignDriverAndVehicleToJob = (token, companyID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/dispatch/assign`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({ data })
    }).then(response => response.json())
)
// export const assignDriverAndVehicleToJob = (token, companyID, data) => (
//     fetch(`${TPAPIBase}/company/${companyID}/dispatch/assign`, {
//         method: 'POST',
//         headers: {
//             Accept: 'application/json',
//             'Accept-Language': i18n.language,
//             'Content-Type': 'application/json',
//             'x-auth-token': token,
//         },
//         body: JSON.stringify({ data })
//     }).then(response => response.json())
// )
export const getCompanyDefaultDispatchOptions = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/dispatch/options/default`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const setCompanyDefaultDispatchOptions = (token, companyID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/dispatch/options/default`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({ data })
    }).then(response => response.json())
)
export const getJobDispatchOptions = (token, companyID, jobID ,) => (
    fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/dispatch/options`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const setJobDispatchOptions = (token, companyID, jobID ,data) => (
    fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/dispatch/options`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({ data })
    }).then(response => response.json())
)
export const notifyDrivers = (token, companyID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/dispatch/notify`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({ data })
    }).then(response => response.json())
)
export const getDriversForDispatch = (token, companyID, jobID, params = []) => {
    const formattedParams = params.map(param => `${param.key}=${param.value}`).join("&")
    return fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/dispatch/drivers?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}
export const getAllDriversForDispatch = (token, companyID, jobID, params = []) => {
    const formattedParams = params.map(param => `${param.key}=${param.value}`).join("&")
    return new Promise(async (res, rej) => {
        const drivers = []
        let page = 1;
        let fetchedAllDrivers = false;
        while (!fetchedAllDrivers) {
            await getDriversForDispatch(token, companyID, jobID, [{ key: 'page', value: page }]).then((res) => {
                if (res.success && res.data.data) {
                    drivers.push(...res.data.data)
                    if (res.data.next_page_url) {
                        page += 1;
                    } else {
                        fetchedAllDrivers = true
                    }
                } else {
                    fetchedAllDrivers = true
                }
            })
        }
        res(drivers)
    })
}
export const getVehiclesForDispatch = (token, companyID, jobID, params = []) => {
    const formattedParams = params.map(param => `${param.key}=${param.value}`).join("&")
    return fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/dispatch/vehicles?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}
export const getAllVehiclesForDispatch = (token, companyID, jobID, params = []) => {
    const formattedParams = params.map(param => `${param.key}=${param.value}`).join("&")
    return new Promise(async (res, rej) => {
        const vehicles = []
        let page = 1;
        let fetchedAllVehicles = false;
        while (!fetchedAllVehicles) {
            await getVehiclesForDispatch(token, companyID, jobID, [{ key: 'page', value: page }]).then((res) => {
                if (res.success && res.data.data) {
                    vehicles.push(...res.data.data)
                    if (res.data.next_page_url) {
                        page += 1;
                    } else {
                        fetchedAllVehicles = true
                    }
                } else {
                    fetchedAllVehicles = true
                }
            })
        }
        res(vehicles)
    })
}
export const getDriverDispatchOrders = (token, companyID, params = []) => {
    const formattedParams = params.map(param => `${param.key}=${param.value}`).join("&");
    return fetch(`${TPAPIBase}/company/${companyID}/dispatch/orders/driver?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}
export const getDispatchOrdersForCompany = (token, companyID, params = []) => {
    const formattedParams = params.map(param => `${param.key}=${param.value}`).join("&");
    return fetch(`${TPAPIBase}/company/${companyID}/dispatch/orders?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}
export const getDispatchOrdersForJob = ({ token, companyID, jobID, options = {} }) => {
    const formattedParams = formatParams(options);
    return fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/dispatch/orders?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}
export const getAllDispatchOrdersForJob = ({ token, companyID, jobID, options = {} }) => {
    return new Promise(async (res, rej) => {
        const orders = []
        let page = 1;
        let fetchedAllOrders = false;
        while (!fetchedAllOrders) {
            await getDispatchOrdersForJob({ token, companyID, jobID, options: { ...options, page }}).then((res) => {
                if (res.success && res.data.data) {
                    orders.push(...res.data.data)
                    if (res.data.next_page_url) {
                        page += 1;
                    } else {
                        fetchedAllOrders = true
                    }
                } else if (res.success == false) {
                    fetchedAllOrders = true
                    rej({
                        success: false
                    })
                } else {
                    fetchedAllOrders = true
                }
            })
        }
        res({
            success: true,
            data: orders
        })
    })
}
export const createDispatchOrder = (token, companyID, data) => {
    return fetch(`${TPAPIBase}/company/${companyID}/dispatch/orders`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
}
export const cancelDispatchOrder = (token, companyID, dispatchOrderID) => {
    return fetch(`${TPAPIBase}/company/${companyID}/dispatch/orders/${dispatchOrderID}/cancel`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
    }).then(response => response.json())
}
export const dismissDriver = (token, companyID, data) => {
    return fetch(`${TPAPIBase}/company/${companyID}/dispatch/drivers/dismiss`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
}
export const updateDispatchOrder = (token, companyID, dispatchOrderID, data) => {
    return fetch(`${TPAPIBase}/company/${companyID}/dispatch/orders/${dispatchOrderID}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
}
// export const getCompanySites = (token, companyID) => (
//     fetch(`${TPAPIBase}/company/${companyID}/sites`, {
//         method: 'GET',
//         headers: {
//             Accept: 'application/json',
//             'Accept-Language': i18n.language,
//             'Content-Type': 'application/json',
//             'x-auth-token': token,
//         }
//     }).then(response => response.json()).then(res => {
//         const tempRes = { ...res };
//         const formatSiteHours = (time) => {
//             const date = new Date().setHours(...time.split(":").map(str => parseInt(str)));
//             return functions.timeFormatter(date);
//         }
//         if (res.data && res.data.length) {
//             tempRes.data = res.data.map(site => {
//                 site.formattedOpenTime = formatSiteHours(site.opening_time)
//                 site.formattedCloseTime = formatSiteHours(site.closing_time)
//                 site.startDateObject = functions.createDateFromUnix(site.start_date)
//                 site.endDateObject = functions.createDateFromUnix(site.end_date)
//                 const startTimeParts = functions.getTimeParts(site.opening_time)
//                 const endTimeParts = functions.getTimeParts(site.closing_time)
//                 site.startDateObject.setHours(startTimeParts.hour)
//                 site.startDateObject.setMinutes(startTimeParts.min)
//                 site.endDateObject.setHours(endTimeParts.hour)
//                 site.endDateObject.setMinutes(endTimeParts.min)
//                 return site
//             })
//         }
//         return tempRes;
//     })
// )
// export const getSite = (token, siteID) => (
//     fetch(`${TPAPIBase}/site/${siteID}`, {
//         method: 'GET',
//         headers: {
//             Accept: 'application/json',
//             'Accept-Language': i18n.language,
//             'Content-Type': 'application/json',
//             'x-auth-token': token,
//         }
//     }).then(response => response.json())
// )
// export const deleteSite = (token, siteID) => (
//     fetch(`${TPAPIBase}/site/${siteID}`, {
//         method: 'DELETE',
//         headers: {
//             Accept: 'application/json',
//             'Accept-Language': i18n.language,
//             'Content-Type': 'application/json',
//             'x-auth-token': token,
//         },
//         body: JSON.stringify({
//             data: {
//                 delete: true
//             }
//         })
//     }).then(response => response.json())
// )
// export const getSiteTypes = (token) => (
//     fetch(`${TPAPIBase}/site_types`, {
//         method: 'GET',
//         headers: {
//             Accept: 'application/json',
//             'Accept-Language': i18n.language,
//             'Content-Type': 'application/json',
//             'x-auth-token': token,
//         }
//     }).then(response => response.json())
// )
// export const createSite = (token, formData) => (
//     fetch(`${TPAPIBase}/site`, {
//         method: 'POST',
//         headers: {
//             Accept: 'application/json',
//             'Accept-Language': i18n.language,
//             'x-auth-token': token,
//         },
//         body: formData
//     }).then(response => response.json())
// )
// export const editSite = (token, siteID, formData) => (
//     fetch(`${TPAPIBase}/site/${siteID}/update`, {
//         method: 'POST',
//         headers: {
//             Accept: 'application/json',
//             'Accept-Language': i18n.language,
//             'x-auth-token': token,
//         },
//         body: formData
//     }).then(response => response.json())
// )
// export const getUsersCompanies = (token) => (
//     fetch(`${TPAPIBase}/users/authenticate`, {
//         method: 'get',
//         headers: {
//             Accept: 'application/json',
// 'Accept-Language': i18n.language,
//             'Content-Type': 'application/json',
//             'x-auth-header': token
//         },
//         body: JSON.stringify({
//             data: user
//         })
//     }).then((response) => response.json())
// )
