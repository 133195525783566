import { number } from 'prop-types';
import { TPAPIBase } from '../../env';
import i18n from '../../i18n';
import { User, UserRating, UserSettings, UserStyleLinkUnderlineBehavior, UserStyleTextSize, UserStyleTheme } from '../reducers/entities/User';
import { ConfirmationResponse, TPResponse } from './util';
import { parseError } from '../reducers/helpersReducerr';

export interface AuthenticateUserPayload {
    email: string;
    password: string;
    login_device: string;
    login_device_type: string;
}
export const authenticateUser = (user: AuthenticateUserPayload): Promise<TPResponse<User>> => (
    fetch(`${TPAPIBase}/users/authenticate`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            data: user
        })
    }).then((response) => response.json())
)

export const createUser = (formData: FormData): Promise<TPResponse<User>> => (
    fetch(`${TPAPIBase}/users/create`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
        },
        body: formData
    }).then((response) => response.json())
)

export const getSpecificUser = (token: string, userID: number): Promise<TPResponse<User>> => (
    fetch(`${TPAPIBase}/users/${userID}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            "x-auth-token": token
        },
    }).then((response) => response.json())
)

export const acceptTOS = () => (
    fetch(`${TPAPIBase}/tos/version`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
        }
    }).then((response) => response.json()).then(response => (
        fetch(`${TPAPIBase}/tos/accept`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Accept-Language': i18n.language,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: {
                    "version": response.data
                }
            })
        }).then(response => response.json())
    ))
)

export const acceptPrivacyPolicy = () => (
    fetch(`${TPAPIBase}/privacy-policy/version`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
        }
    }).then((response) => response.json()).then(response => (
        fetch(`${TPAPIBase}/privacy-policy/accept`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: {
                    "version": response.data
                }
            })
        }).then(response => response.json())
    ))
)

export const updateCurrentUser = (token: string, userID: number, formData: FormData): Promise<TPResponse<User>> => (
    fetch(`${TPAPIBase}/users/${userID}/update`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            "x-auth-token": token
        },
        body: formData
    }).then(response => response.json())
)

export const requestPasswordReset = (email: string) => (
    fetch(`${TPAPIBase}/password-reset`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            data: {
                email: email
            }
        })
    }).then((response) => response.json())
)
// data: {
//     email: "email"
// }
export const verifyPasswordReset = (email: string, resetToken: string) => (
    fetch(`${TPAPIBase}/password-reset?email=${email}&token=${resetToken}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
        },
    }).then((response) => response.json())
)
export interface ResetPasswordPayload {
    reset_key: string;
    password: string;
    password_confirmation: string;
}
export const resetPassword = (resetToken: string, data: ResetPasswordPayload) => (
    fetch(`${TPAPIBase}/password-reset/${resetToken}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            data: data
        })
    }).then((response) => response.json())
)
export const getUserSettings = (token: string): Promise<TPResponse<UserSettings>> => (
    fetch(`${TPAPIBase}/user/settings`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const updateUserSettings = (token: string, data: UserSettings): Promise<TPResponse<UserSettings>> => (
    fetch(`${TPAPIBase}/user/settings`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const getDutyStatus = (token: string): Promise<TPResponse<boolean>> => (
    fetch(`${TPAPIBase}/on-duty`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
    }).then((response) => response.json())
)
export const setDutyStatus = (token: string, isOnDuty: boolean): Promise<TPResponse<UserSettings>> => (
    fetch(`${TPAPIBase}/on-duty/${isOnDuty ? 1 : 0}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
    }).then((response) => response.json())
)
export const getUserRating = (token: string, userID: number): Promise<TPResponse<{ 
    ratings: UserRating[], 
    summary: {
        average_stats: number;
        num_ratings: number;
    }
}>> => (
    fetch(`${TPAPIBase}/ratings/user/${userID}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
    }).then((response) => response.json())
)
export const getStyleSettings = (token: string) => (
    fetch(`${TPAPIBase}/user/settings/style`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
    }).then((response) => response.json()).catch(err => console.log(err))
)
export interface SetStyleSettingsPayload {
    theme: UserStyleTheme,
    text_size: UserStyleTextSize,
    link_underline_behavior: UserStyleLinkUnderlineBehavior,
    accessibility: boolean;
}
export const setStyleSettings = (token: string, settings: SetStyleSettingsPayload) => (
    fetch(`${TPAPIBase}/user/settings/style`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            data: settings
        })
    }).then((response) => response.json())
)
export interface AssociateUserWithVehiclePayload {
    user_id: number;
    vehicle_id: number;
}
export const associateUserWithVehicle = (token: string, companyID: number, data: AssociateUserWithVehiclePayload) => (
    fetch(`${TPAPIBase}/company/${companyID}/set_user_vehicle`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            data
        })
    }).then((response) => response.json())
)

interface UserToRoleOptions {
    token: string, 
    data: UserToRolePayload
}
export interface UserToRolePayload {
    company_id: number;
    roles: number[];
    user_id: number;
}
export const attachUserToRole = async ({ token, data } : UserToRoleOptions): Promise<TPResponse<boolean>> => {
    const response = await fetch(`${TPAPIBase}/role/attach`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            data
        })
    }).then((response) => response.json())

    if (!response.success) throw new Error(parseError(response))

    return response
}
export const detachUserFromRole = async ({ token, data }: UserToRoleOptions): Promise<TPResponse<boolean>> => {
    const response = await fetch(`${TPAPIBase}/role/detach`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            data
        })
    }).then((response) => response.json())

    if (!response.success) throw new Error(parseError(response))

    return response
}

/**
 * Wrapper for `Update Employee` API
 * 
 * @throws Parsed error when unsuccessful
 */
export const updateEmployee = async ({ 
    token,
    companyId,
    employeeUserId,
    payload,
}: {
    token: string;
    companyId: number;
    employeeUserId: number;
    payload: UpdateEmployeePayload;
}): Promise<TPResponse<User>> => {
    const response: TPResponse<User> = await fetch(`${TPAPIBase}/company/${companyId}/employees/${employeeUserId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            data: payload
        })
    }).then((response) => response.json())

    if (!response.success) throw new Error(parseError(response))

    return response
}
interface UpdateEmployeePayload {
    position_id: number;
}

/**
 * Wrapper for `Deactive User (Soft Delete User)` API
 * 
 * @throws Parsed error when unsuccessful
 */
export const deactivateUser = async ({ 
    token,
    userId,
}: {
    token: string;
    userId: number;
}): Promise<TPResponse<User>> => {
    const response: TPResponse<User> = await fetch(`${TPAPIBase}/users/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            data: {
                delete: true
            }
        })
    }).then((response) => response.json())

    if (!response.success) throw new Error(parseError(response))

    return response
}