import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import styled, { withTheme } from "styled-components";
import { useTranslation, withTranslation } from 'react-i18next'
import GoogleMapReact from "google-map-react";
import { GMapAPIKey } from '../../../../env';
import {
    CardActionArea,
    CardActions as MuiCardActions,
    CardContent as MuiCardContent,
    CardHeader,
    Card as MuiCard,
    CardMedia as MuiCardMedia,
    Grid,
    Divider as MuiDivider,
    Typography as MuiTypography,
    Link,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Tooltip as MuiTooltip,
    IconButton as MuiIconButton,
    Box as MuiBox,
    Avatar as MuiAvatar,
    Paper,
    Chip as MuiChip,
    Dialog,
    DialogTitle,
    DialogContent as MuiDialogContent,
    Button,
} from "@material-ui/core";
import NumberFormat from 'react-number-format';
import { spacing } from "@material-ui/system";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DeliveryTrackingCard from './DeliveryTrackingCard';
import AdditionalDeliveryDocsCard from '../../JobDocs/components/AdditionalJobDocsCard';
import SkeletonCards from '../../../components/SkeletonCards';
import CreateInspectionModal from '../../Inspector/modals/ModalCreateInspection'
import ViewDeliveryInspectionsModal from "../../Inspector/modals/ModalViewInspection";
import { getETicketTargetsForJob, getETicketUploadReadinessStatus } from "../../../../redux/util/eticketAPI";
import ETicketUploadStatusChip from "../subcomponents/ETicketUploadStatusChip";
// import SetDeliveryBatchMaterialModal from "../modals/ModalSetDeliveryBatchMaterial";
import CreateEditMixBatchModal from "../../Materials/modals/ModalCreateEditMixBatch";
import SetMixBatchForDeliveryModal from "../../Materials/modals/ModalSetMixBatchForDelivery";
import { getDeliveryMaterialMixBatch } from "../../../../redux/util/mixAPI";
import { useLocation } from "react-router-dom";
import { can, createDateFromUnix, formatNumber, parseError } from "../../../../redux/reducers/helpersReducerr";
import { useRootSelector } from "../../../..";
import { popAlert } from "../../../../redux/actions/sessionActions";
import { getSpecificInvoice, payInvoice, signOffOnInvoice } from "../../../../redux/util/invoiceAPI";
import { createDateFromTimestamp } from "../../Util/dateUtil";

const styles = {
    darkCard: {
        backgroundColor: "#F0F0F0",
    },
    acceptButton: {
        color: '#ffffff !important',
        backgroundColor: '#27c24c',
        borderColor: '#27c24c'
    },
    declineButton: {
        color: '#ffffff !important',
        backgroundColor: '#f05050',
        borderColor: '#f05050'
    },
    green: {
        color: '#27c24c !important'
    },
    red: {
        color: '#f05050 !important',
    },
    gray: {
        color: '#616161 !important',
    },
    tpo: {
        color: '#ff6600 !important',
    },
}

const Card = styled(MuiCard)(spacing);
const CardMedia = styled(MuiCardMedia)(spacing)
const Chip = styled(MuiChip)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Typography = styled(MuiTypography)(spacing);
const DialogContent = styled(MuiDialogContent)(spacing);
const SpacedButton = styled(Button)(spacing);
const BoldTypography = styled(Typography)`
    font-weight: ${props => props.theme.typography.fontWeightBold}
`;
const Box = styled(MuiBox)(spacing);
const Divider = styled(MuiDivider)(spacing);
const GoogleMapReactWrapper = styled.div`
  height: 300px;
  width: 100%;
`;
const DeliveryTimeDetailsPrimary = styled.span`
    color: ${props => props.theme.palette.text.secondary};
    margin-left: 6px;
    margin-bottom: 6px;
`;
const DeliveryTimeDetailsSecondary = styled.span`
    font-weight: ${props => props.theme.typography.fontWeightMedium};
    color: ${props => props.theme.palette.text.primary};
    margin-left: 6px;
    margin-bottom: 6px;
`;
const DeliveryDetailedStatusSpan = styled.span`
    display: inline;
    padding: .2em .6em .3em;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    background-color: #ff6600;
`

const Heading = styled.div`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.palette.common.black};
  background: ${props => props.theme.palette.common.white};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

function DeliveryDetailsCard({ className, delivery, helpers, state }) {
    const { t } = useTranslation();
    return (
        <Card mb={5} variant="outlined" raised elevation={10}>
            <CardHeader title={t('deliveryTranslations.deliveryDetails')} />
            <CardContent>
                <Typography component="p" color="textSecondary">
                    {t('deliveryTranslations.driverName')}
                </Typography>
                <Typography component="p" mb={5}>
                    {delivery.driver_name}
                </Typography>

                <Typography component="p" color="textSecondary">
                    {t('deliveryTranslations.company')}
                </Typography>
                <Typography component="p" mb={5}>
                    {delivery.driver_company_name}
                </Typography>


                {/* LICENSE PLATE */}

                {delivery.vehicle.license_plate && (<>
                    <Typography component="p" color="textSecondary">
                        {delivery.vehicle.license_plate_country.code} {t('vehicleTranslations.licensePlate')}
                    </Typography>
                    <Typography component="p" mb={5}>
                        {delivery.vehicle.license_plate}
                    </Typography>
                </>)}
                {delivery.vehicle.license_plate_2 && (<>
                    <Typography component="p" color="textSecondary">
                        {delivery.vehicle.license_plate_2_country.code} {t('vehicleTranslations.licensePlate')}
                    </Typography>
                    <Typography component="p" mb={5}>
                        {delivery.vehicle.license_plate_2}
                    </Typography>
                </>)}


                {/* BOL */}

                {delivery.bol && (<>
                    <Typography component="p" color="textSecondary">
                        {t('deliveryTranslations.bol')}
                    </Typography>
                    <Typography component="p" mb={5}>
                        {delivery.bol}
                    </Typography>
                </>)}


                {/* HELPER NAMES */}

                {delivery.constant_weighmaster_name && (<>
                    <Typography component="p" color="textSecondary">
                        {t('deliveryTranslations.weighmasterName')}
                    </Typography>
                    <Typography component="p" mb={5}>
                        {delivery.constant_weighmaster_name}
                    </Typography>
                </>)}
                {delivery.scanner && (<>
                    <Typography component="p" color="textSecondary">
                        {t('deliveryTranslations.scanner')}
                    </Typography>
                    <Typography component="p" mb={5}>
                        {delivery.scanner.first_name} {delivery.scanner.last_name}
                    </Typography>
                </>)}


                {/* WEIGHTS */}

                {(delivery.job && delivery.job.transaction_type_id == 2) ? (<>
                    {delivery.gross_weight ? <>
                        <Typography component="p" color="textSecondary">
                            {t('deliveryTranslations.grossWeight')}
                        </Typography>
                        <Typography component="p" mb={5}>
                            {helpers.formatNumber(delivery.gross_weight, { noDecimals: true })} {delivery.weight_unit}
                        </Typography>
                    </> : <>
                        {Boolean(delivery.job && delivery.job.uses_external_weight) && <>
                            <Typography component="p" color="textSecondary">
                                {t('deliveryTranslations.grossWeight')}
                            </Typography>
                            <Typography component="p" mb={5}>
                                Weight Processing
                            </Typography>
                        </>}
                    </>}

                    {delivery.net_weight ? <>
                        <Typography component="p" color="textSecondary">
                            {t('deliveryTranslations.netWeight')}
                        </Typography>
                        <Typography component="p" mb={5}>
                            {helpers.formatNumber(delivery.net_weight, { noDecimals: true })} {delivery.weight_unit}
                        </Typography>
                    </> : <>
                        {Boolean(delivery.job && delivery.job.uses_external_weight) && <>
                            <Typography component="p" color="textSecondary">
                                {t('deliveryTranslations.netWeight')}
                            </Typography>
                            <Typography component="p" mb={5}>
                                Weight Processing
                            </Typography>
                        </>}
                    </>}
                </>) : null}

                
                <Typography component="p" color="textSecondary">
                    {t('materialTranslations.material')}
                </Typography>
                <Typography component="p" mb={5}>
                    {delivery.displayMaterials}
                </Typography>

                {Boolean(state.deliveryBatch) && <>
                    <Typography color="textSecondary">Mix Batch Details:</Typography>
                    <Typography>Batch Ticket #: {state.deliveryBatch.batch_ticket_number}</Typography>
                    {Boolean(state.deliveryBatch.weight && state.deliveryBatch.weight_units) && 
                        <Typography>Weight: {state.deliveryBatch.weight} {t(`unitTranslations.weightUnits.${state.deliveryBatch.weight_units}`)}</Typography>
                    }
                    {Boolean(state.deliveryBatch.volume && state.deliveryBatch.volume_units) && 
                        <Typography>Volume: {state.deliveryBatch.volume} {t(`unitTranslations.volumeUnits.${state.deliveryBatch.volume_units}`)}</Typography>
                    }
                    {Boolean(state.deliveryBatch.temperature && state.deliveryBatch.temperature_units) && 
                        <Typography>Temperature: {state.deliveryBatch.temperature} {t(`unitTranslations.temperatureUnits.${state.deliveryBatch.temperature_units}`)}</Typography>
                    }
                    {Boolean(state.deliveryBatch.slump && state.deliveryBatch.slump_units) && 
                        <Typography>Slump: {state.deliveryBatch.slump} {t(`unitTranslations.distanceUnits.${state.deliveryBatch.slump_units}`)}</Typography>
                    }
                    {Boolean(state.deliveryBatch.mixing_revolutions) && 
                        <Typography>Mix Revolutions: {state.deliveryBatch.mixing_revolutions}</Typography>
                    }
                    {Boolean(state.deliveryBatch.air_content_percentage) && 
                        <Typography>{t('materialTranslations.airContentPercentage')}: {state.deliveryBatch.air_content_percentage}%</Typography>
                    }
                </>}

            </CardContent>
        </Card>
    )
}

function DeliveryTimeDetails({ delivery, classes, helpers, userSettings }) {
    const { t } = useTranslation();
    let timezone = undefined;
    if (!userSettings.view_times_in_local_timezone) {
        timezone = delivery.job.job_location_timezone
    }
    const dateOptions = {
        timezone: timezone
    }
    const formattedCheckinDate = useMemo(() => delivery.checkin ? helpers.getDateTime(delivery.checkinDate, dateOptions) : "", [delivery.checkinDate])
    const formattedCheckoutDate = useMemo(() => delivery.checkout ? helpers.getDateTime(delivery.checkoutDate, dateOptions) : "", [delivery.checkoutDate])
    const formattedCompletedAt = useMemo(() => {
        if (delivery.completed_at) return ""
        const completedAtDate = createDateFromUnix(delivery.completed_at, { keepLocal: false, isTimestampDateOnly: false })
        return helpers.getDateTime(completedAtDate, dateOptions)
    }, [delivery.completed_at])
    return (<>
        <Box mb={3}>
            <Typography display="inline">{t('timeTranslations.status')}: </Typography> <DeliveryDetailedStatusSpan>{t(`deliveryTranslations.detailedStatuses.${delivery.detailedStatus}`)}</DeliveryDetailedStatusSpan>
        </Box>



        <Typography mb={3}>
            <i className={`fas fa-circle fa-xs ${classes.green}`}></i> 
            <DeliveryTimeDetailsPrimary>
                {t('deliveryTranslations.checkin')}: 
            </DeliveryTimeDetailsPrimary>
            <DeliveryTimeDetailsSecondary>
                {formattedCheckinDate}
            </DeliveryTimeDetailsSecondary>
        </Typography>
        <Typography mb={3}>
            <i className={`fas fa-circle fa-xs ${classes.green}`}></i> 
            <DeliveryTimeDetailsPrimary>
                {t('deliveryTranslations.loadDuration')}: 
            </DeliveryTimeDetailsPrimary>
            <DeliveryTimeDetailsSecondary>
                {delivery.loadDuration}
            </DeliveryTimeDetailsSecondary>
        </Typography>



        <Typography mb={3}>
            <i className={`fas fa-circle fa-xs ${classes.red}`}></i> 
            <DeliveryTimeDetailsPrimary>
                {t('deliveryTranslations.checkout')}: 
            </DeliveryTimeDetailsPrimary>
            <DeliveryTimeDetailsSecondary>
                {delivery.checkoutDate && formattedCheckoutDate}
            </DeliveryTimeDetailsSecondary>
        </Typography>
        <Typography mb={3}>
            <i className={`fas fa-circle fa-xs ${classes.red}`}></i> 
            <DeliveryTimeDetailsPrimary>
                {t('deliveryTranslations.unloadDuration')}: 
            </DeliveryTimeDetailsPrimary>
            <DeliveryTimeDetailsSecondary>
                {delivery.unloadDuration}
            </DeliveryTimeDetailsSecondary>
        </Typography>


        <Typography mb={3}>
            <i className={`fas fa-circle fa-xs`}></i> 
            <DeliveryTimeDetailsPrimary>
                Delivery Complete: 
            </DeliveryTimeDetailsPrimary>
            <DeliveryTimeDetailsSecondary>
                {formattedCompletedAt}
            </DeliveryTimeDetailsSecondary>
        </Typography>
        <Typography mb={3}>
            <i className={`fas fa-circle fa-xs`}></i> 
            <DeliveryTimeDetailsPrimary>
                {t('deliveryTranslations.unloadDuration')}: 
            </DeliveryTimeDetailsPrimary>
            <DeliveryTimeDetailsSecondary>
                {delivery.unloadDuration}
            </DeliveryTimeDetailsSecondary>
        </Typography>



        <Typography mb={3}>
            <i className={`fas fa-circle fa-xs ${classes.gray}`}></i> 
            <DeliveryTimeDetailsPrimary>
                {t('deliveryTranslations.scanFeePayer')}: 
            </DeliveryTimeDetailsPrimary>
            <DeliveryTimeDetailsSecondary>
                {t(`deliveryTranslations.scanFeePayerOptions.${delivery.job.scan_fee_payer}`)}
            </DeliveryTimeDetailsSecondary>
        </Typography>
    </>)

}

function InvoiceDetails({ invoice, helpers }) {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const location = useLocation()

    const token = useRootSelector(state => state.session.user.token)
    const user = useRootSelector(state => state.session.user)
    const company = useRootSelector(state => state.session.company)

    const [currentInvoice, setCurrentInvoice] = useState(invoice)
    const [canPay, setCanPay] = useState(false)
    const [canSignOff, setCanSignOff] = useState(false)

    const totalAmount = useMemo(() => formatNumber(currentInvoice.payment_due / 100), [currentInvoice.payment_due])
    const disputedAmount = useMemo(() => formatNumber(currentInvoice.disputed_amount / 100), [currentInvoice.disputed_amount])
    const serviceFee = useMemo(() => formatNumber(currentInvoice.service_fee / 100), [currentInvoice.service_fee])
    const brokerFee = useMemo(() => formatNumber(currentInvoice.broker_fee / 100), [currentInvoice.broker_fee])

    // Checks if the invoice should show the pay invoice button
    useEffect(() => {
        console.log("checking invoice in delivery", invoice);
        if (!currentInvoice) return;
        const canPossiblyPay = location.pathname == '/invoices/payable' && can(company.perms, 'pay-invoice')

        let allSignedOff = true
        let userMustSignOff = false;
        if (currentInvoice.job.requires_sign_off) {
            Object.values(currentInvoice.sign_off).forEach(signOff => {
                if (!signOff.is_signed_off && allSignedOff) {
                    allSignedOff = false;
                }
                if (!signOff.is_signed_off && signOff.job_sign_off_member.sign_off_user_id === user.id && !userMustSignOff) {
                    userMustSignOff = true;
                }
            })
        }
        console.log("sign off", currentInvoice.to_be_paid_through_truckpay, userMustSignOff, !currentInvoice.is_paid, !allSignedOff);
        setCanSignOff(currentInvoice.to_be_paid_through_truckpay && userMustSignOff && !currentInvoice.is_paid && !allSignedOff)
        setCanPay(currentInvoice.to_be_paid_through_truckpay && canPossiblyPay && !currentInvoice.is_paid && allSignedOff)

    }, [currentInvoice])

    const updateInvoice = async () => {
        try {
            const response = await getSpecificInvoice(token, currentInvoice.id)
            if (!response.success) return;
            setCurrentInvoice(curVal => ({
                ...curVal,
                ...response.data
            }))
        } catch (error) {
            dispatch(popAlert('error', t('error'), error.message))
        }
    }

    const signOffCurrentInvoice = async () => {
        dispatch(popAlert('info', t('loading'), t('invoiceTranslations.loading.signOff')))
        try {
            const response = await signOffOnInvoice(token, company.id, currentInvoice.id)
            if (!response.success) {
                dispatch(popAlert('error', t('error'), parseError(response.error)))
            }

            dispatch(popAlert('success', t('success'), `${t('invoiceTranslations.invoiceNumber')}${currentInvoice.invoice_no} ${t('invoiceTranslations.success.signedOff')}!`))
            updateInvoice()
        } catch (error) {
            dispatch(popAlert('error', t('error'), error.message))
        }
    }

    const payCurrentInvoice = async () => {
        dispatch(popAlert('info', t('loading'), t('invoiceTranslations.loading.payInvoice')))

        try {
            const response = await payInvoice(token, company.id, currentInvoice.id)
            if (!response.success) {
                dispatch(popAlert('error', t('error'), parseError(response)))
                return
            }
            dispatch(popAlert('success', t('success'), "Invoice paid"))
            updateInvoice()
        } catch (error) {
            dispatch(popAlert('error', t('error'), error.message))
        }
    }

    return (<>
        {Boolean(currentInvoice.merchant_company?.profile_picture_url) && <Box mb={3}>
            <img src={currentInvoice.merchant_company.profile_picture_url} style={{ width: '100%' }}/>
        </Box>}

        <Typography mb={3} variant="h6">
            {t('invoiceTranslations.invoiceNumber')} <br/>
            <BoldTypography>
                {currentInvoice.invoice_no}
            </BoldTypography>
        </Typography>
        {currentInvoice.job.privacy_status != 'private' && <>
            <Typography mb={3} variant="subtitle2">
                {t('invoiceTranslations.totalAmount')}:&nbsp;
                <Typography color="primary" display="inline">
                    {currentInvoice.currency_object.symbol_prefix}{totalAmount}{currentInvoice.currency_object.symbol_suffix  || " " + currentInvoice.currency_object.code}
                </Typography>
            </Typography>
            <Typography mb={3} variant="subtitle2">
                {t('invoiceTranslations.amountDisputed')}:&nbsp;
                {currentInvoice.currency_object.symbol_prefix}{disputedAmount}{currentInvoice.currency_object.symbol_suffix  || " " + currentInvoice.currency_object.code}
            </Typography>
            <Typography mb={3} variant="subtitle2">
                {t('invoiceTranslations.serviceFee')}:&nbsp;
                {currentInvoice.currency_object.symbol_prefix}{serviceFee}{currentInvoice.currency_object.symbol_suffix  || " " + currentInvoice.currency_object.code}
            </Typography>
            <Typography mb={3} variant="subtitle2">
                {t('invoiceTranslations.brokerFee')}:&nbsp;
                {currentInvoice.currency_object.symbol_prefix}{brokerFee}{currentInvoice.currency_object.symbol_suffix  || " " + currentInvoice.currency_object.code}
            </Typography>

            <Typography mb={3} variant="body2">
                {t('invoiceTranslations.dueTo')}: {currentInvoice.merchant_company.name}
            </Typography>

            {canPay && <SpacedButton
                variant="contained"
                color="primary"
                onClick={payCurrentInvoice}
                type="button"
                mb={3}
            >
                {t('invoiceTranslations.actions.payInvoice')}
            </SpacedButton>}
            {canSignOff && <SpacedButton
                variant="contained"
                color="primary"
                onClick={signOffCurrentInvoice}
                type="button"
                mb={3}
            >
                {t('invoiceTranslations.actions.signOff')}
            </SpacedButton>}
        </>}
    </>)

}

function DeliveryPictures({ type, delivery, className, theme }) {
    const { t } = useTranslation();
    const data = {
        url: "",
        title: "",
    }

    let isSignature = false;
    switch(type) {
        case 'loadPicture': {
            data.url = delivery.load_picture_url
            data.title = t('deliveryTranslations.loadPicture')
            break;
        }
        case 'unloadPicture': {
            data.url = delivery.drop_picture_url
            data.title = t('deliveryTranslations.unloadPicture')
            break;
        }
        case 'checkinSignature': {
            data.url = delivery.checkin_signature_url
            data.title = t('deliveryTranslations.checkinSignature')
            isSignature = true;
            break;
        }
        case 'checkoutSignature': {
            data.url = delivery.checkout_signature_url
            data.title = t('deliveryTranslations.checkoutSignature')
            isSignature = true;
            break;
        }
        case 'driverCheckinSignature': {
            data.url = delivery.driver_checkin_signature_url
            data.title = t('deliveryTranslations.driverCheckinSignature')
            isSignature = true;
            break;
        }
        case 'driverCheckoutSignature': {
            data.url = delivery.driver_checkout_signature_url
            data.title = t('deliveryTranslations.driverCheckoutSignature')
            isSignature = true;
            break;
        }
        case 'scaleWeightImage': {
            data.url = delivery.scale_weight_image
            data.title = t('deliveryTranslations.scaleWeightImage')
            break;
        }
    }

    return (
        <Card mb={6} variant="outlined">
            <CardMedia
                component="img"
                image={data.url ? data.url : "/static/img/site_photo.jpg"}
                title={data.title}
                style={{
                    height: 220,
                    objectFit: "contain",
                    filter: (theme.currentTheme == 'high_contrast' && isSignature) ? 'invert(1)' : ''
                }}
            />
            <CardContent style={{backgroundColor: "rgba(0,0,0,0.1"}}>
                <Typography gutterBottom variant="subtitle2">
                    {data.title}
                </Typography>
            </CardContent>
        </Card>
    )
}
class DeliveryLocations extends React.Component {
    constructor(props) {
        super(props);
        const data = {}
        const { type, delivery } = props;
        switch(type) {
            case 'pickup': {
                data.url = delivery.load_picture_url
                data.title = this.props.t('addressTranslations.pickupAddress')
                data.info = []
                if (delivery.route.address_from.location_id) {
                    const div = `${this.props.t('addressTranslations.pickupLocationID')}: ${delivery.route.address_from.location_id}`
                    data.info.push(div)
                }

                const longitude = `${this.props.t('addressTranslations.longitude')}: ${delivery.checkin_longitude}`
                data.info.push(longitude)
                const latitude = `${this.props.t('addressTranslations.latitude')}: ${delivery.checkin_latitude}`
                data.info.push(latitude)

                break;
            }
            case 'dropoff': {
                data.url = delivery.load_picture_url
                data.title = this.props.t('addressTranslations.dropoffAddress')
                data.info = []
                if (delivery.route.address_to.location_id) {
                    const div = `${this.props.t('addressTranslations.dropoffLocationID')}: ${delivery.route.address_to.location_id}`
                    data.info.push(div)
                }

                const longitude = `${this.props.t('addressTranslations.longitude')}: ${delivery.checkout_longitude}`
                data.info.push(longitude)
                const latitude = `${this.props.t('addressTranslations.latitude')}: ${delivery.checkout_latitude}`
                data.info.push(latitude)

                break;
            }
        }
        this.state = {
            ...data,
            delivery: props.delivery,
            fetchedAddressData: false
        }
    }
    componentDidMount = () => {
        this.mounted = true;
        // this.fetchCheckAddress();
    }
    componentWillUnmount = () => {
        this.mounted = false;
    }
    setStateIfMounted = (state, callback) => {
        if (this.mounted) {
            this.setState(state, callback)
        }
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.expanded != this.props.expanded && this.props.expanded && !this.state.fetchedAddressData) {
            if (window.google && window.google.maps) {
                this.fetchCheckAddress()
            }
        }
    }
    fetchCheckAddress = () => {
        const geocoder = new window.google.maps.Geocoder();
        this.setStateIfMounted({ fetchedAddressData: true })
        if (this.props.type == 'pickup') {
            geocoder.geocode({
                latLng: {
                    lat: this.state.delivery.checkin_latitude, lng: this.state.delivery.checkin_longitude 
                }
            }, (results, status) => {
                if (results && results.length) {
                    const result = results[0];
                    const parsedAddress = this.props.helpers.parseGoogleAddressComponents(result.address_components, true)
                    this.updateInfo(parsedAddress)
                }
            })
        } else {
            geocoder.geocode({ 
                latLng: {
                    lat: this.state.delivery.checkout_latitude, lng: this.state.delivery.checkout_longitude 
                }
            }, (results, status) => {
                if (results && results.length) {
                    const result = results[0];
                    const parsedAddress = this.props.helpers.parseGoogleAddressComponents(result.address_components, true)
                    this.updateInfo(parsedAddress)
                }
            })
        }
    }
    updateInfo = (parsedAddress) => {
        const updatedInfo = [...this.state.info]
        updatedInfo.unshift(<br />)
        const cityProvince = `${parsedAddress.city}, ${parsedAddress.province}`
        updatedInfo.unshift(cityProvince)
        const addressLine1 = `${parsedAddress.address_line_1}`
        updatedInfo.unshift(addressLine1)
        this.setStateIfMounted({
            info: updatedInfo
        })
    }
    render = () => {
        return (
            <Card mb={6} className={this.props.className} variant="outlined">
                <CardContent style={{backgroundColor: "rgba(0,0,0,0.1"}}>
    
                    <Typography gutterBottom variant="subtitle2">
                        {this.state.title}
                    </Typography>
    
                    {this.state.info.map((info, index) => <Box key={index}>
                        <Typography>
                            {info}
                        </Typography>
                    </Box>)}
    
                </CardContent>
            </Card>
        )
    }
}
const DeliveryLocationsCard = withTranslation()(DeliveryLocations);

class Delivery extends React.Component {

    /*
        props: {
            delivery: Delivery,
            forInspector: Boolean,
            inspectorAssignment: InspectorAssignment
            refreshDeliveries: Function
        }

    */

    constructor(props) {
        super(props);
        const { delivery } = props;
        if (!props.delivery.displayMaterials) {
            delivery.displayMaterials = delivery.materials.map(mat => mat.name ? mat.name : mat.custom_material_name).join(", ");
            if (delivery.load_time_duration) {
                const loadDate = props.helpers.convertSecondsToTime(delivery.load_time_duration)
                delivery.loadDuration = `${loadDate.getHours()}h ${loadDate.getMinutes()}m ${loadDate.getSeconds()}s`;
            }
            if (delivery.unload_time_duration) {
                const unloadDate = props.helpers.convertSecondsToTime(delivery.unload_time_duration)
                delivery.unloadDuration = `${unloadDate.getHours()}h ${unloadDate.getMinutes()}m ${unloadDate.getSeconds()}s`;
            }
            if (delivery.checkin && delivery.checkout) {
                const durationDate = props.helpers.convertSecondsToTime((new Date(delivery.checkout) - new Date(delivery.checkin)) / 1000)
                delivery.total_duration = `${durationDate.getHours()}h ${durationDate.getMinutes()}m`;
            }

            delivery.detailedStatus = this.getDetailedDeliveryStatus(delivery);

            if (delivery.original_gross_weight || delivery.original_net_weight) {
                delivery.isReweighment = true;
            }
            delivery.checkinDate = props.helpers.createDateFromUnix(delivery.checkin, { keepLocal: true })
            delivery.checkoutDate = delivery.checkout ? props.helpers.createDateFromUnix(delivery.checkout, { keepLocal: true }) : null

        }
        this.delivery = delivery;
        const state = {
            showDeliveryRoute: false,
            openCreateInspectionModal: false,
            openViewInspectionsModal: false,
            openSetBatchMaterialModal: false,
            inspectionType: "",
            targetNames: [],
            targets: {},
            showAdditionalJobDocs: false,
            showETickekUploadReceipts: false,
            openCreateMixBatchMaterial: false,
            openSetMixBatchMaterial: false,
            needsBatch: false,
            deliveryBatch: null,
        }

        if (props.forInspector) {
            if (props.company.id == delivery.company_id) {
                // belongs to job owner
                // check company inspection flags
                if (delivery._requires_company_pickup_inspection && delivery._requires_company_dropoff_inspection) {
                    state.inspectionType = 'both'
                } else if (delivery._requires_company_pickup_inspection) {
                    state.inspectionType = 'pickup'
                } else if (delivery._requires_company_dropoff_inspection) {
                    state.inspectionType = 'dropoff'
                }
            } else {
                // is third party inspector
                // delivery._requires_third_party_pickup_inspection
                // delivery._requires_third_party_dropoff_inspection
                if (delivery._requires_third_party_pickup_inspection && delivery._requires_third_party_dropoff_inspection) {
                    state.inspectionType = 'both'
                } else if (delivery._requires_third_party_pickup_inspection) {
                    state.inspectionType = 'pickup'
                } else if (delivery._requires_third_party_dropoff_inspection) {
                    state.inspectionType = 'dropoff'
                }
            }
        }
        this.state = state
    }
    componentDidMount = () => {
        this.mounted = true;
        this.getETicketTargets()
        const jobNeedsBatch = !!this.props.delivery?.materials?.find(mat => mat.is_inspectable)
        if (jobNeedsBatch) {
            this.getDeliveryBatch()
        }
    }
    componentWillUnmount = () => {
        this.mounted = false;
    }
    setStateIfMounted = (state, callback) => {
        if (this.mounted) {
            this.setState(state, callback)
        }
    }
    setCreateInspectionModal = bool => this.setStateIfMounted({ openCreateInspectionModal: bool })
    setViewInspectionsModal = bool => this.setStateIfMounted({ openViewInspectionsModal: bool })
    setSetBatchMaterialsModal = bool => this.setStateIfMounted({ openSetBatchMaterialModal: bool })
    setCreateMixBatchMaterial = bool => this.setStateIfMounted({ openCreateMixBatchMaterial: bool })
    setSetMixBatchForDeliveryModal = bool => this.setStateIfMounted({ openSetMixBatchMaterial: bool })
    toggleShowAdditionalJobDocs = () => this.setStateIfMounted({ showAdditionalJobDocs: !this.state.showAdditionalJobDocs })
    toggleShowETickekUploadReceipts = () => this.setStateIfMounted({ showETickekUploadReceipts: !this.state.showETickekUploadReceipts })
    getDetailedDeliveryStatus = (delivery) => {
        if (delivery.checkout) return 'completed';
        if (delivery.route.pick_up_scale_id) {
            if (delivery.checkin && !delivery.load_time_duration) return 'loading';
            if (delivery.load_time_duration && !delivery.gross_pounds) return 'awaitingWeighment';
            if (delivery.load_time_duration && !delivery.checkout) return 'driving';
            if (delivery.checkout && !delivery.unload_time_duration) return 'unloading';
        } else if (delivery.route.drop_off_scale_id) {
            if (delivery.checkin && !delivery.load_time_duration) return 'loading';
            if (delivery.load_time_duration && !delivery.gross_pounds) return 'awaitingWeighment';
            if (delivery.load_time_duration && !delivery.checkout) return 'driving';
            if (delivery.checkout && !delivery.unload_time_duration) return 'unloading';
        } else {
            if (delivery.checkin && !delivery.load_time_duration && !delivery.checkout) return 'loading';
            if (delivery.checkin && delivery.load_time_duration && !delivery.checkout) return 'driving';
            if (delivery.checkout && !delivery.unload_time_duration) return 'unloading';
        }
        return 'completed';
    }
    getETicketTargets = async () => {
        if (this.props.helpers.can(this.props.company.perms, "create-job")) {
            const jobETicketTargets = await getETicketTargetsForJob(this.props.token, this.delivery.job_id)
            if (!jobETicketTargets.success) return;
            const targets = {}
            jobETicketTargets.data.map(targetData => {
                targets[targetData.target] = targetData
            })
            console.log("targets", targets)
            this.setStateIfMounted({ targets })
        }
    }
    getDeliveryBatch = async () => {
        const response = await getDeliveryMaterialMixBatch(this.props.token, this.props.company.id, this.props.delivery.id)
        this.setStateIfMounted({
            needsBatch: true,
            deliveryBatch: response.data || null
        })
    }
    render = () => {
        const delivery = this.delivery

        const targetKeys = Object.keys(this.state.targets)

        return (<>
            {Boolean(this.props.forInspector) && <Grid container spacing={10}>
                {Boolean(this.state.inspectionType) && <Grid item xs={12}>
                    <Button fullWidth variant="contained" color="primary" onClick={() => this.setCreateInspectionModal(true)}>
                        Create Inspection
                    </Button>
                </Grid>}
                {this.state.openCreateInspectionModal && 
                    <CreateInspectionModal 
                        setModalOpen={() => this.setCreateInspectionModal(false)} 
                        specificJobID={this.props.inspectorAssignment.job_id} 
                        specificToken={this.props.inspectorAssignment.token}
                        delivery={delivery}
                        inspectionType={this.state.inspectionType}
                        refreshDeliveries={this.props.refreshDeliveries}
                    />
                }
                {this.state.openViewInspectionsModal && 
                    <ViewDeliveryInspectionsModal 
                        setModalOpen={this.setViewInspectionsModal} 
                        specificJobID={this.props.inspectorAssignment.job_id} 
                        specificToken={this.props.inspectorAssignment.token}
                        delivery={delivery}
                    />
                }
            </Grid>}

            {Boolean(this.state.needsBatch && !this.state.deliveryBatch) && <Grid container spacing={10}>
                <Grid item>
                    <Button 
                        color="primary"
                        variant="outlined"
                        onClick={() => this.setCreateMixBatchMaterial(true)}
                    >
                        Create Mix Batch For Delivery
                    </Button>
                </Grid>
                {this.state.openCreateMixBatchMaterial && <CreateEditMixBatchModal 
                    setModalOpen={this.setCreateMixBatchMaterial}
                    onSetMixBatchForDelivery={this.getDeliveryBatch}
                    deliveryID={delivery.id}
                    delivery={delivery}
                />}
                {/* <Grid item>
                    <Button 
                        color="primary"
                        variant="outlined"
                        onClick={() => this.setSetMixBatchForDeliveryModal(true)}
                    >
                        Set Mix Batch For Delivery
                    </Button>
                </Grid>
                {this.state.openSetMixBatchMaterial && <SetMixBatchForDeliveryModal 
                    setModalOpen={this.setSetMixBatchForDeliveryModal}
                    deliveryID={delivery.id}
                    onSetMixBatch={this.getDeliveryBatch}
                />} */}
            </Grid>}

            {/* TODO update permissions to see this */}
            {targetKeys.length > 0 && <Grid container spacing={10}>
                {targetKeys.map(targetName => (<Grid item key={targetName}>
                    <ETicketUploadStatusChip 
                        targetName={targetName} 
                        targetID={this.state.targets[targetName]?.id} 
                        deliveryID={this.delivery.id} 
                    />
                </Grid>))}
            </Grid>}



            <Grid container spacing={5}>
                <Grid item xs={12} sm={3}>
                    {this.props.invoice && <InvoiceDetails invoice={this.props.invoice} helpers={this.props.helpers}/>}
                    <DeliveryDetailsCard delivery={delivery} helpers={this.props.helpers} state={this.state}/>
                    <DeliveryTimeDetails delivery={delivery} classes={this.props.classes} helpers={this.props.helpers} userSettings={this.props.userSettings}/>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Grid container spacing={5}>
                        <Grid item md={6}>
                            {Boolean(delivery.checkin_latitude && delivery.checkin_longitude) && <DeliveryLocationsCard delivery={delivery} type="pickup" expanded={this.props.expanded} helpers={this.props.helpers}/>}
                        </Grid>
                        <Grid item md={6}>
                            {Boolean(delivery.checkout_latitude && delivery.checkout_longitude) && <DeliveryLocationsCard delivery={delivery} type="dropoff" expanded={this.props.expanded} helpers={this.props.helpers}/>}
                        </Grid>
                    </Grid>
                    {(delivery.load_picture_url || delivery.drop_picture_url ) ? <>
                        <Grid container spacing={5}>
                            <Grid item md={6}>
                                <DeliveryPictures delivery={delivery} type="loadPicture" theme={this.props.theme} />
                            </Grid>
                            <Grid item md={6}>
                                <DeliveryPictures delivery={delivery} type="unloadPicture" theme={this.props.theme} />
                            </Grid>
                        </Grid>
                    </> : null }
                    {(delivery.checkin_signature_url || delivery.checkout_signature_url ) ? <>
                        <Grid container spacing={5}>
                            <Grid item md={6}>
                                <DeliveryPictures delivery={delivery} type="checkinSignature" theme={this.props.theme} />
                            </Grid>
                            <Grid item md={6}>
                                <DeliveryPictures delivery={delivery} type="checkoutSignature" theme={this.props.theme} />
                            </Grid>
                        </Grid>
                    </> : null }
                    {(delivery.driver_checkin_signature_url || delivery.driver_checkout_signature_url ) ? <>
                        <Grid container spacing={5}>
                            <Grid item md={6}>
                                <DeliveryPictures delivery={delivery} type="driverCheckinSignature" theme={this.props.theme}/>
                            </Grid>
                            <Grid item md={6}>
                                <DeliveryPictures delivery={delivery} type="driverCheckoutSignature" theme={this.props.theme}/>
                            </Grid>
                        </Grid>
                    </> : null }
                    {(delivery.scale_weight_image ) ? <>
                        <Grid container spacing={5}>
                            <Grid item md={12}>
                                <DeliveryPictures delivery={delivery} type="scaleWeightImage" theme={this.props.theme} />
                            </Grid>
                        </Grid>
                    </> : null }
                    {Boolean(delivery.job.company_id == this.props.company.id || delivery.driver_company.id == this.props.company.id) && <>
                        {!(delivery.load_picture_url || delivery.drop_picture_url || delivery.checkin_signature_url || delivery.checkout_signature_url) ? 
                            <DeliveryTrackingCard delivery={delivery} expanded={this.props.expanded} />
                        :
                            <>
                                <Button variant="contained" color="primary" fullWidth onClick={() => this.setStateIfMounted({ showDeliveryRoute: true })}>{this.props.t('deliveryTranslations.driverRoute')}</Button>
                                {this.state.showDeliveryRoute && 
                                    <Dialog
                                        open={true}
                                        aria-labelledby="form-dialog-title"
                                        fullWidth
                                        maxWidth="md"
                                        onBackdropClick={() => this.setStateIfMounted({ showDeliveryRoute: false })}
                                        onEscapeKeyDown={() => this.setStateIfMounted({ showDeliveryRoute: false })}
                                    >
                                        <DialogContent p={"0px !important"}>
                                            <DeliveryTrackingCard delivery={delivery} expanded={(this.props.expanded && this.state.showDeliveryRoute)} />
                                        </DialogContent>
                                    </Dialog>
                                }
                            </>
                        }
                    </>}
                </Grid>
            </Grid>
            {Boolean(this.props.expanded && !this.props.forInspector) && <Grid container spacing={10}>
                <Grid item xs={12}>
                    <Button onClick={this.toggleShowAdditionalJobDocs} color="primary" variant="outlined">
                        {this.state.showAdditionalJobDocs ? "Hide Job Docs" : "Show Job Docs"}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {this.state.showAdditionalJobDocs && <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <AdditionalDeliveryDocsCard delivery={this.delivery}/>
                        </Grid>
                    </Grid>}
                </Grid>
            </Grid>}
        </>)
    }
}
const msp = state => ({
    token: state.session.user.token,
    company: state.session.company,
    helpers: state.helpers,
    userSettings: state.session.user.settings,
    theme: state.theme
})
const mdp = dispatch => ({
    
})
export default withStyles(() => styles)(connect(msp, mdp)(withTranslation()(Delivery)));