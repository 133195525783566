import React from "react";

import async from "../components/Async";

import {
  BookOpen,
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  Monitor,
  ShoppingCart,
  PieChart,
  Sliders,
  User,
  Users,
  Download,
  FileText,
  Settings as SettingsIcon,
  MapPin,
  Inbox,
  Truck,
  Package,
  Folder,
  BarChart2,
  Shield,
  Clipboard,
  Upload,
  Navigation,
  Compass,
  GitPullRequest,
  Codesandbox
} from "react-feather";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Components components
const Alerts = async(() => import("../pages/components/Alerts"));
const Avatars = async(() => import("../pages/components/Avatars"));
const Badges = async(() => import("../pages/components/Badges"));
const Buttons = async(() => import("../pages/components/Buttons"));
const Cards = async(() => import("../pages/components/Cards"));
const Chips = async(() => import("../pages/components/Chips"));
const Dialogs = async(() => import("../pages/components/Dialogs"));
const ExpPanels = async(() => import("../pages/components/ExpansionPanels"));
const Lists = async(() => import("../pages/components/Lists"));
const Menus = async(() => import("../pages/components/Menus"));
const Pagination = async(() => import("../pages/components/Pagination"));
const Progress = async(() => import("../pages/components/Progress"));
const Snackbars = async(() => import("../pages/components/Snackbars"));
const Tooltips = async(() => import("../pages/components/Tooltips"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
const Analytics = async(() => import("../pages/dashboards/Analytics"));

// Forms components
const Pickers = async(() => import("../pages/forms/Pickers"));
const SelectionCtrls = async(() => import("../pages/forms/SelectionControls"));
const Selects = async(() => import("../pages/forms/Selects"));
const TextFields = async(() => import("../pages/forms/TextFields"));
const Dropzone = async(() => import("../pages/forms/Dropzone"));
const Editors = async(() => import("../pages/forms/Editors"));

// Icons components
const MaterialIcons = async(() => import("../pages/icons/MaterialIcons"));
const FeatherIcons = async(() => import("../pages/icons/FeatherIcons"));

// Pages components
const Blank = async(() => import("../pages/pages/Blank"));
const InvoiceDetails = async(() => import("../pages/pages/InvoiceDetails"));
const InvoiceList = async(() => import("../pages/pages/InvoiceList"));
const Orders = async(() => import("../pages/pages/Orders"));
const Pricing = async(() => import("../pages/pages/Pricing"));
const Profile = async(() => import("../pages/pages/Profile"));
const Settingss = async(() => import("../pages/pages/Settings"));
const Tasks = async(() => import("../pages/pages/Tasks"));
const Projects = async(() => import("../pages/pages/Projects"));
const Calendar = async(() => import("../pages/pages/Calendar"));
const CompaniesList = async(() => import("../pages/dashboards/Companies/CompaniesList"));
const PlantReports = async(() => import("../pages/dashboards/PlantOrders/PlantOrders"));
const Sites = async(() => import("../pages/dashboards/Sites"));
const SitesList = async(() => import("../pages/dashboards/Sites/SitesList"));
const SiteDetails = async(() => import("../pages/dashboards/Sites/SiteDetails"));
const Jobs = async(() => import("../pages/dashboards/Jobs"));
const JobsList = async(() => import("../pages/dashboards/Jobs/JobsList"));
const JobsList2 = async(() => import("../pages/dashboards/Jobs/JobsList2"));
const JobDocs = async(() => import("../pages/dashboards/JobDocs/JobDocs"));
const Bids = async(() => import("../pages/dashboards/Bids/Bids"));
const JobDetails = async(() => import("../pages/dashboards/Jobs/JobDetails"));
const Scales = async(() => import("../pages/dashboards/Scales"));
const Deliveries = async(() => import("../pages/dashboards/Deliveries"));
const DeliveriesList = async(() => import("../pages/dashboards/Deliveries/DeliveriesList"));
const DeliveriesList2 = async(() => import("../pages/dashboards/Deliveries/DeliveriesList2"));
const Invoices = async(() => import("../pages/dashboards/Invoices"));
const InvoicesList = async(() => import("../pages/dashboards/Invoices/InvoicesList"));
const VehiclesList = async(() => import("../pages/dashboards/Vehicles/VehiclesList"));
const VehiclesMap = async(() => import("../pages/dashboards/Vehicles/VehiclesMap"));
const Dispatch = async(() => import("../pages/dashboards/Dispatch/Dispatch"));
const Exports = async(() => import("../pages/dashboards/Export"));
const StandardExport = async(() => import("../pages/dashboards/Export/StandardExport"));
const AutoExport = async(() => import("../pages/dashboards/Export/AutoExport"));
const Employees = async(() => import("../pages/dashboards/Employees/Employees"));
const PrenegotiatedRates = async(() => import("../pages/dashboards/PrenegotiatedRates/PrenegotiatedRates"));
const Settings = async(() => import("../pages/dashboards/Settings"));
const PaymentMethods = async(() => import("../pages/dashboards/Settings/PaymentMethods"));
const GeneralSettings = async(() => import("../pages/dashboards/Settings/GeneralSettings"));
const CompanyProfile = async(() => import("../pages/dashboards/Profiles/CompanyProfile"));
const UserProfile = async(() => import("../pages/dashboards/Profiles/UserProfile"));
const Notifications = async(() => import("../pages/dashboards/Notifications/Notifications"));
const PendingRatings = async(() => import("../pages/dashboards/PendingRatings/PendingRatings"));
const InspectorLogbook = async(() => import("../pages/dashboards/Inspector/InspectorLogbook"));
const InspectorSupervisor = async(() => import("../pages/dashboards/Inspector/InspectorSupervisor"));
const InspectorDeliveries = async(() => import("../pages/dashboards/Inspector/InspectorDeliveries"));
const CustomMaterials = async(() => import("../pages/dashboards/Materials/CustomMaterials"));
const MixDesigns = async(() => import("../pages/dashboards/Materials/MixDesigns"));
const MixBatches = async(() => import("../pages/dashboards/Materials/MixBatches"));
const Reports = async(() => import("../pages/dashboards/Reports/Reports"));

const AdminGeneral = async(() => import("../pages/dashboards/Admin/General"));
const AdminUsers = async(() => import("../pages/dashboards/Admin/Users"));
const AdminCompanies = async(() => import("../pages/dashboards/Admin/Companies"));
const AdminFreeTrials = async(() => import("../pages/dashboards/Admin/FreeTrials"));
const AdminDeliveries = async(() => import("../pages/dashboards/Admin/Deliveries"));
const AdminSiteRevenue = async(() => import("../pages/dashboards/Admin/SiteRevenue"));
const AdminTPRestricted = async(() => import("../pages/dashboards/Admin/TPRestricted"));
const AdminMaterials = async(() => import("../pages/dashboards/Admin/Materials"));

// Tables components
const SimpleTable = async(() => import("../pages/tables/SimpleTable"));
const AdvancedTable = async(() => import("../pages/tables/AdvancedTable"));

// Chart components
const Chartjs = async(() => import("../pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));

// Documentation
const Welcome = async(() => import("../pages/docs/Welcome"));
const GettingStarted = async(() => import("../pages/docs/GettingStarted"));
const EnvironmentVariables = async(() => import("../pages/docs/EnvironmentVariables"));
const Deployment = async(() => import("../pages/docs/Deployment"));
const Theming = async(() => import("../pages/docs/Theming"));
const StateManagement = async(() => import("../pages/docs/StateManagement"));
const Support = async(() => import("../pages/docs/Support"));
const Changelog = async(() => import("../pages/docs/Changelog"));
const Presentation = async(() => import("../pages/docs/Presentation"));

// export const inspectorLogbookRoutes = {
//   id: "sidebarLogbook",
//   path: "/inspector-logbook",
//   // header: "Sites",
//   icon: <FileText />,
//   containsHome: true,
//   children: null,
//   component: InspectorDeliveries
// };
// export const inspectorDeliveriesRoutes = {
//   id: "sidebarInspectorDeliveries",
//   path: "/inspector-deliveries",
//   // header: "Sites",
//   icon: <FileText />,
//   containsHome: true,
//   children: null,
//   component: InspectorDeliveries
// };
// export const noAuthInspectorDeliveriesRoutes = {
//   id: "sidebarInspectorDeliveries",
//   path: "/inspector/:inspectorToken/job/:jobID/deliveries",
//   // header: "Sites",
//   icon: <FileText />,
//   containsHome: true,
//   children: null,
//   component: InspectorDeliveries
// };

const companiesListRoutes = {
  id: "sidebarEmployers",
  path: "/portal/companies/list",
  // header: "Sites",
  icon: null,
  containsHome: true,
  children: null,
  component: CompaniesList
};
export const inspectorLogbookRoutes = {
  id: "sidebarLogbook",
  path: "/inspector-logbook",
  // header: "Sites",
  icon: <FileText />,
  containsHome: true,
  children: null,
  component: InspectorLogbook
};
export const inspectorSupervisorRoute = {
  id: "sidebarInspectorSupervisor",
  path: "/inspector-supervisor",
  // header: "Sites",
  icon: <FileText />,
  containsHome: true,
  children: null,
  component: InspectorSupervisor
};
export const inspectorDeliveriesRoutes = {
  id: "sidebarInspectorDeliveries",
  path: "/inspector-deliveries",
  // header: "Sites",
  icon: <FileText />,
  containsHome: true,
  children: null,
  component: InspectorDeliveries
};
export const noAuthInspectorLogbookRoutes = {
  id: "sidebarLogbook",
  path: "/x-inspector-logbook",
  // header: "Sites",
  icon: <FileText />,
  containsHome: true,
  children: null,
  component: InspectorLogbook
};
export const noAuthInspectorDeliveriesRoutes = {
  id: "sidebarInspectorDeliveries",
  path: "/inspector/:inspectorToken/job/:jobID/deliveries",
  // header: "Sites",
  icon: <FileText />,
  containsHome: true,
  children: null,
  component: InspectorDeliveries
};
export const plantOrdersRoutes = {
  id: "sidebarPlantOrders",
  path: "/plantOrders",
  // header: "Sites",
  icon: <FileText />,
  containsHome: true,
  children: null,
  component: PlantReports
};
const sitesRoutes = {
  id: "sidebarSites",
  path: "/sites",
  // header: "Sites",
  icon: <MapPin />,
  containsHome: true,
  children: null,
  component: Sites
};
const scalesRoutes = {
  id: "sidebarScales",
  path: "/scales",
  // header: "Sites",
  icon: <Download />,
  containsHome: true,
  children: null,
  component: Scales
};
const jobsRoutes = {
  id: "sidebarJobs",
  path: "/jobs",
  // header: "Sites",
  icon: <Briefcase />,
  containsHome: true,
  children: [
    {
      path: "/jobs/current",
      name: "sidebarJobsCurrent",
      component: JobsList
    },
    {
      path: "/jobs/pending",
      name: "sidebarJobsPending",
      component: JobsList
    },
    {
      path: "/jobs/past",
      name: "sidebarJobsPast",
      component: JobsList
    },
    {
      path: "/jobs/in-progress",
      name: "sidebarJobsInProgress",
      component: JobsList
    },
    {
      path: "/jobs/external",
      name: "sidebarJobsExternal",
      component: JobsList
    },
    {
      path: "/jobs/nearby",
      name: "sidebarJobsNearby",
      component: JobsList
    },
    {
      path: "/jobs/additional-job-docs",
      name: "sidebarJobsAddJobDocs",
      component: JobDocs
    },
    {
      path: "/jobs/details/:jobID",
      component: JobDetails
    },
  ],
  component: Jobs
};
const driverJobsRoutes = {
  id: "sidebarJobs",
  path: "/jobs",
  // header: "Sites",
  icon: <Briefcase />,
  containsHome: true,
  children: [
    {
      path: "/jobs/current",
      name: "sidebarJobsCurrent",
      component: JobsList
    },
    {
      path: "/jobs/past",
      name: "sidebarJobsPast",
      component: JobsList
    },
    {
      path: "/jobs/nearby",
      name: "sidebarJobsNearby",
      component: JobsList
    },
    {
      path: "/jobs/bidded",
      name: "sidebarJobsBidded",
      component: JobsList
    },
    {
      path: "/jobs/bids",
      name: "sidebarJobsBids",
      component: Bids
    },
    {
      path: "/jobs/details/:jobID",
      component: JobDetails
    },
  ],
  component: Jobs
};
const companyDriverJobsRoutes = {
  id: "sidebarJobs",
  path: "/jobs",
  // header: "Sites",
  icon: <Briefcase />,
  containsHome: true,
  children: [
    {
      path: "/jobs/current",
      name: "sidebarJobsCurrent",
      component: JobsList
    },
    {
      path: "/jobs/past",
      name: "sidebarJobsPast",
      component: JobsList
    },
    {
      path: "/jobs/details/:jobID",
      component: JobDetails
    },
  ],
  component: Jobs
};
const subConJobsRoutes = {
  id: "sidebarJobs",
  path: "/jobs",
  // header: "Sites",
  icon: <Briefcase />,
  containsHome: true,
  children: [
    {
      path: "/jobs/current",
      name: "sidebarJobsCurrent",
      component: JobsList
    },
    {
      path: "/jobs/pending",
      name: "sidebarJobsPending",
      component: JobsList
    },
    {
      path: "/jobs/past",
      name: "sidebarJobsPast",
      component: JobsList
    },
    {
      path: "/jobs/in-progress",
      name: "sidebarJobsInProgress",
      component: JobsList
    },
    {
      path: "/jobs/external",
      name: "sidebarJobsExternal",
      component: JobsList
    },
    {
      path: "/jobs/nearby",
      name: "sidebarJobsNearby",
      component: JobsList
    },
    {
      path: "/jobs/bidded",
      name: "sidebarJobsBidded",
      component: JobsList
    },
    {
      path: "/jobs/bids",
      name: "sidebarJobsBids",
      component: Bids
    },
    {
      path: "/jobs/details/:jobID",
      component: JobDetails
    },
  ],
  component: Jobs
};
const companyJobsRoutes = {
  id: "sidebarJobs",
  path: "/jobs",
  // header: "Sites",
  icon: <Briefcase />,
  containsHome: true,
  children: [
    {
      path: "/jobs/current",
      name: "sidebarJobsCurrent",
      component: JobsList
    },
    {
      path: "/jobs/pending",
      name: "sidebarJobsPending",
      component: JobsList
    },
    {
      path: "/jobs/past",
      name: "sidebarJobsPast",
      component: JobsList
    },
    {
      path: "/jobs/in-progress",
      name: "sidebarJobsInProgress",
      component: JobsList
    },
    {
      path: "/jobs/external",
      name: "sidebarJobsExternal",
      component: JobsList
    },
    {
      path: "/jobs/query",
      name: "sidebarJobsQuery",
      component: JobsList2
    },
    {
      path: "/jobs/details/:jobID",
      component: JobDetails
    },
  ],
  component: Jobs
};

const deliveriesRoutes = {
  id: "sidebarDeliveries",
  path: "/deliveries",
  // header: "Sites",
  icon: <Package />,
  containsHome: true,
  children: [
    {
      path: "/deliveries/current",
      name: "sidebarDeliveriesCurrent",
      component: DeliveriesList
    },
    {
      path: "/deliveries/past",
      name: "sidebarDeliveriesPast",
      component: DeliveriesList
    },
    {
      path: "/deliveries/in-progress",
      name: "sidebarDeliveriesInProgress",
      component: DeliveriesList
    },
    {
      path: "/deliveries/external",
      name: "sidebarDeliveriesExternal",
      component: DeliveriesList
    },
    {
      path: "/deliveries/query",
      name: "sidebarDeliveriesQuery",
      component: DeliveriesList2
    }
  ],
  component: Deliveries
};
const driverDeliveriesRoutes = {
  id: "sidebarDeliveries",
  path: "/deliveries",
  // header: "Sites",
  icon: <Package />,
  containsHome: true,
  children: [
    {
      path: "/deliveries/current",
      name: "sidebarDeliveriesCurrent",
      component: DeliveriesList
    },
    {
      path: "/deliveries/past",
      name: "sidebarDeliveriesPast",
      component: DeliveriesList
    },
    {
      path: "/deliveries/in-progress",
      name: "sidebarDeliveriesInProgress",
      component: DeliveriesList
    },
  ],
  component: Deliveries
};
const companyExternalDeliveriesRoutes = {
  id: "sidebarDeliveries",
  path: "/deliveries/external",
  // header: "Sites",
  icon: <Package />,
  containsHome: true,
  children: null,
  component: DeliveriesList
};
const companyDeliveriesRoutes = {
  id: "sidebarDeliveries",
  path: "/deliveries",
  // header: "Sites",
  icon: <Package />,
  containsHome: true,
  children: [
    {
      path: "/deliveries/current",
      name: "sidebarDeliveriesCurrent",
      component: DeliveriesList
    },
    {
      path: "/deliveries/past",
      name: "sidebarDeliveriesPast",
      component: DeliveriesList
    },
    {
      path: "/deliveries/in-progress",
      name: "sidebarDeliveriesInProgress",
      component: DeliveriesList
    },
    {
      path: "/deliveries/external",
      name: "sidebarDeliveriesExternal",
      component: DeliveriesList
    },
    {
      path: "/deliveries/query",
      name: "sidebarDeliveriesQuery",
      component: DeliveriesList2
    }
  ],
  component: Deliveries
};

const invoicesRoutes = {
  id: "sidebarInvoices",
  path: "/invoices",
  // header: "Sites",
  icon: <Inbox />,
  containsHome: true,
  children: [
    {
      path: "/invoices/payable",
      name: "sidebarInvoicesPayable",
      component: InvoicesList
    },
    {
      path: "/invoices/receivable",
      name: "sidebarInvoicesReceivable",
      component: InvoicesList
    },
  ],
  component: Invoices
};
const driverInvoicesRoutes = {
  id: "sidebarInvoices",
  path: "/invoices",
  // header: "Sites",
  icon: <Inbox />,
  containsHome: true,
  children: null,
  component: InvoicesList
};
const companyInvoicesRoutes = {
  id: "sidebarInvoices",
  path: "/invoices",
  // header: "Sites",
  icon: <Inbox />,
  containsHome: true,
  children: [
    {
      path: "/invoices/payable",
      name: "sidebarInvoicesPayable",
      component: InvoicesList
    },
    {
      path: "/invoices/receivable",
      name: "sidebarInvoicesReceivable",
      component: InvoicesList
    },
  ],
  component: Invoices
};

const vehiclesRoutes = {
  id: "sidebarVehicles",
  path: "/vehicles",
  // header: "Sites",
  icon: <Truck />,
  containsHome: true,
  children: [
    {
      path: "/vehicles/list",
      name: "sidebarVehiclesList",
      component: VehiclesList
    },
    {
      path: "/vehicles/map",
      name: "sidebarVehiclesMap",
      component: VehiclesMap
    },
  ]
};
const dispatchRoutes = {
  id: "sidebarDispatch",
  path: "/dispatch",
  // header: "Sites",
  icon: <Clipboard />,
  containsHome: true,
  component: Dispatch
};

const exportRoutes = {
  id: "sidebarExport",
  path: "/export",
  // header: "Sites",
  icon: <Upload />,
  containsHome: true,
  children: [
    {
      path: "/export/default",
      name: "sidebarExportStandard",
      component: StandardExport
    },
    {
      path: "/export/auto",
      name: "sidebarExportAuto",
      component: AutoExport
    },
  ],
  component: Exports
};


const employeesRoutes = {
  id: "sidebarEmployees",
  path: "/employees",
  // header: "Sites",
  icon: <Users />,
  containsHome: true,
  component: Employees
};
const jobDocsRoutes = {
  id: "sidebarJobDocs",
  path: "/job-docs",
  // header: "Sites",
  icon: <Folder />,
  containsHome: true,
  children: [
    {
      path: "/job-docs/prenegotiated-rates",
      name: "sidebarRates",
      component: PrenegotiatedRates
    },
    {
      path: "/job-docs/additional-job-docs",
      name: "sidebarAddJobDocs",
      component: JobDocs
    },
  ],
  component: null
};
const pendingRatingsRoutes = {
  id: "sidebarPendingRatings",
  path: "/pendingRatings",
  // header: "Sites",
  icon: <BarChart2 />,
  containsHome: true,
  component: PendingRatings
};

const settingsRoutes = {
  id: "sidebarSettings",
  path: "/settings",
  // header: "Sites",
  icon: <SettingsIcon />,
  containsHome: true,
  children: [
    {
      path: "/settings/paymentMethods",
      name: "sidebarSettingsPM",
      component: PaymentMethods
    },
    {
      path: "/settings/general",
      name: "sidebarSettingsGeneral",
      component: GeneralSettings
    },
  ],
  component: Settings
};
const administratorRoutes = {
  id: "sidebarAdmin",
  path: "/admin",
  // header: "Sites",
  icon: <Shield />,
  containsHome: true,
  children: [
    {
      path: "/admin/general",
      name: "sidebarAdminGeneral",
      component: AdminGeneral
    },
    {
      path: "/admin/users",
      name: "sidebarAdminUsers",
      component: AdminUsers
    },
    {
      path: "/admin/companies",
      name: "sidebarAdminCompanies",
      component: AdminCompanies
    },
    {
      path: "/admin/freeTrials",
      name: "sidebarAdminFreeTrials",
      component: AdminFreeTrials
    },
    {
      path: "/admin/deliveries",
      name: "sidebarAdminDeliveries",
      component: AdminDeliveries
    },
    {
      path: "/admin/siteRevenue",
      name: "sidebarAdminSiteRevenue",
      component: AdminSiteRevenue
    },
    {
      path: "/admin/restrictionWhitelist",
      name: "sidebarAdminTPRestricted",
      component: AdminTPRestricted
    },
    {
      path: "/admin/materials",
      name: "sidebarAdminMaterials",
      component: AdminMaterials
    },
  ],
  component: null
};
const materialsRoutes = {
  id: "sidebarMaterials",
  path: "/materials",
  component: null,
  containsHome: true,
  icon: <Codesandbox />,
  children: [
    {
      path: "/materials/custom",
      name: "sidebarCustomMaterials",
      component: CustomMaterials
    },
    {
      path: "/materials/mix-designs",
      name: "sidebarMixDesigns",
      component: MixDesigns
    },
    {
      path: "/materials/mix-batches",
      name: "sidebarMixBatches",
      component: MixBatches
    }
  ],
};

export const companyProfileRoutes = {
  // id: "companyProfile",
  path: "/company",
  component: CompanyProfile,
  hidden: true,
  children: null,
};
export const userProfileRoutes = {
  // id: "companyProfile",
  path: "/user",
  component: UserProfile,
  hidden: true,
  children: null,
};
export const notificationRoutes = {
  // id: "companyProfile",
  path: "/notifications",
  component: Notifications,
  hidden: true,
  children: null,
};

export const reportsRoutes = {
  id: "sidebarReports",
  path: "/reports",
  icon: <Shield />,
  containsHome: true,
  component: Reports,
}

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  header: "Pages",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: "/dashboard/default",
      name: "Default",
      component: Default
    },
    {
      path: "/dashboard/analytics",
      name: "Analytics",
      component: Analytics
    }
  ],
  component: null
};

const pagesRoutes = {
  id: "Pages",
  path: "/pages",
  icon: <Layout />,
  children: [
    {
      path: "/pages/settings",
      name: "Settings",
      component: Settingss
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/pages/blank",
      name: "Blank Page",
      component: Blank
    }
  ],
  component: null
};

const profileRoutes = {
  id: "Profile",
  path: "/profile",
  icon: <User />,
  component: Profile,
  children: null
};

const projectsRoutes = {
  id: "Projects",
  path: "/projects",
  icon: <Briefcase />,
  badge: "8",
  component: Projects,
  children: null
};

const invoiceRoutes = {
  id: "Invoices",
  path: "/invoicess",
  icon: <CreditCard />,
  children: [
    {
      path: "/invoicess",
      name: "List",
      component: InvoiceList
    },
    {
      path: "/invoicess/detail",
      name: "Details",
      component: InvoiceDetails
    }
  ],
  component: null
};

const orderRoutes = {
  id: "Orders",
  path: "/orders",
  icon: <ShoppingCart />,
  component: Orders,
  children: null
};

const tasksRoutes = {
  id: "Tasks",
  path: "/tasks",
  icon: <CheckSquare />,
  badge: "17",
  component: Tasks,
  children: null
};

const calendarRoutes = {
  id: "Calendar",
  path: "/calendar",
  icon: <CalendarIcon />,
  component: Calendar,
  children: null
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ],
  component: null
};

const componentsRoutes = {
  id: "Components",
  path: "/components",
  header: "Elements",
  icon: <Grid />,
  children: [
    {
      path: "/components/alerts",
      name: "Alerts",
      component: Alerts
    },
    {
      path: "/components/avatars",
      name: "Avatars",
      component: Avatars
    },
    {
      path: "/components/badges",
      name: "Badges",
      component: Badges
    },
    {
      path: "/components/buttons",
      name: "Buttons",
      component: Buttons
    },
    {
      path: "/components/cards",
      name: "Cards",
      component: Cards
    },
    {
      path: "/components/chips",
      name: "Chips",
      component: Chips
    },
    {
      path: "/components/dialogs",
      name: "Dialogs",
      component: Dialogs
    },
    {
      path: "/components/expansion-panels",
      name: "Expansion Panels",
      component: ExpPanels
    },
    {
      path: "/components/lists",
      name: "Lists",
      component: Lists
    },
    {
      path: "/components/menus",
      name: "Menus",
      component: Menus
    },
    {
      path: "/components/pagination",
      name: "Pagination",
      component: Pagination
    },
    {
      path: "/components/progress",
      name: "Progress",
      component: Progress
    },
    {
      path: "/components/snackbars",
      name: "Snackbars",
      component: Snackbars
    },
    {
      path: "/components/tooltips",
      name: "Tooltips",
      component: Tooltips
    }
  ],
  component: null
};

const formsRoutes = {
  id: "Forms",
  path: "/forms",
  icon: <CheckSquare />,
  children: [
    {
      path: "/forms/pickers",
      name: "Pickers",
      component: Pickers
    },
    {
      path: "/forms/selection-controls",
      name: "Selection Controls",
      component: SelectionCtrls
    },
    {
      path: "/forms/selects",
      name: "Selects",
      component: Selects
    },
    {
      path: "/forms/text-fields",
      name: "Text Fields",
      component: TextFields
    },
    {
      path: "/forms/dropzone",
      name: "Dropzone",
      component: Dropzone
    },
    {
      path: "/forms/editors",
      name: "Editors",
      component: Editors
    }
  ],
  component: null
};

const tablesRoutes = {
  id: "Tables",
  path: "/tables",
  icon: <List />,
  children: [
    {
      path: "/tables/simple-table",
      name: "Simple Table",
      component: SimpleTable
    },
    {
      path: "/tables/advanced-table",
      name: "Advanced Table",
      component: AdvancedTable
    }
  ],
  component: null
};

const iconsRoutes = {
  id: "Icons",
  path: "/icons",
  icon: <Heart />,
  children: [
    {
      path: "/icons/material-icons",
      name: "Material Icons",
      component: MaterialIcons
    },
    {
      path: "/icons/feather-icons",
      name: "Feather Icons",
      component: FeatherIcons
    }
  ],
  component: null
};

const chartRoutes = {
  id: "Charts",
  path: "/charts",
  icon: <PieChart />,
  component: Chartjs,
  children: null
};

const mapsRoutes = {
  id: "Maps",
  path: "/maps",
  icon: <Map />,
  children: [
    {
      path: "/maps/google-maps",
      name: "Google Maps",
      component: GoogleMaps
    },
    {
      path: "/maps/vector-maps",
      name: "Vector Maps",
      component: VectorMaps
    }
  ],
  component: null
};

const presentationRoutes = {
  id: "Presentation",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Presentation,
  children: null
};

const documentationRoutes = {
  id: "Documentation",
  path: "/documentation",
  icon: <BookOpen />,
  children: [
    {
      path: "/documentation/welcome",
      name: "Welcome",
      component: Welcome
    },
    {
      path: "/documentation/getting-started",
      name: "Getting Started",
      component: GettingStarted
    },
    {
      path: "/documentation/environment-variables",
      name: "Environment Variables",
      component: EnvironmentVariables
    },
    {
      path: "/documentation/deployment",
      name: "Deployment",
      component: Deployment
    },
    {
      path: "/documentation/theming",
      name: "Theming",
      component: Theming
    },
    {
      path: "/documentation/state-management",
      name: "State Management",
      component: StateManagement
    },
    {
      path: "/documentation/support",
      name: "Support",
      component: Support
    },
  ],
  component: null
};

const changelogRoutes = {
  id: "Changelog",
  path: "/changelog",
  badge: "v1.2.0",
  icon: <List />,
  component: Changelog,
  children: null
};

// This route is not visisble in the sidebar
const privateRoutes = {
  id: "Private",
  path: "/private",
  component: Blank,
  children: null,
};

// Routes using the Dashboard layout
export const companiesListLayoutRoutes = [
  companiesListRoutes
];

// Routes using the Dashboard layout
export const inspectorLayoutRoutes = [
  inspectorDeliveriesRoutes,
  inspectorLogbookRoutes,
  userProfileRoutes,
  companyProfileRoutes,
  notificationRoutes,
  // noAuthInspectorLogbookRoutes,
];

export const inspectorSupervisorRoutes = [
  inspectorSupervisorRoute,
  userProfileRoutes,
  companyProfileRoutes,
  notificationRoutes,
]
export const governmentEntityRoutes = [
  inspectorSupervisorRoute,
  companyExternalDeliveriesRoutes,
  employeesRoutes,
  userProfileRoutes,
  companyProfileRoutes,
  notificationRoutes,
]

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [

  sitesRoutes,
  scalesRoutes,
  jobsRoutes,
  deliveriesRoutes,
  invoicesRoutes,
  vehiclesRoutes,
  exportRoutes,
  employeesRoutes,
  jobDocsRoutes,
  pendingRatingsRoutes,
  settingsRoutes,
  userProfileRoutes,
  companyProfileRoutes,
  notificationRoutes,

  dashboardsRoutes,
  pagesRoutes,
  profileRoutes,
  projectsRoutes,
  orderRoutes,
  invoiceRoutes,
  tasksRoutes,
  calendarRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
  documentationRoutes,
  changelogRoutes,
  presentationRoutes,
  privateRoutes
];

// driverJobsRoutes
// subConJobsRoutes
// companyJobsRoutes

// driverDeliveriesRoutes
// companyDeliveriesRoutes

// driverInvoicesRoutes
// companyInvoicesRoutes
export const driverRoutes = [
  sitesRoutes,
  driverJobsRoutes,
  driverDeliveriesRoutes,
  driverInvoicesRoutes,
  vehiclesRoutes,
  materialsRoutes,
  dispatchRoutes,
  exportRoutes,
  reportsRoutes,
  jobDocsRoutes,
  settingsRoutes,
  userProfileRoutes,
  companyProfileRoutes,
  notificationRoutes,
]

export const companyDriverRoutes = [
  sitesRoutes,
  companyDriverJobsRoutes,
  driverDeliveriesRoutes,
  settingsRoutes,
  userProfileRoutes,
  companyProfileRoutes,
  notificationRoutes,
]
export const subConRoutes = [
  sitesRoutes,
  subConJobsRoutes,
  companyDeliveriesRoutes,
  companyInvoicesRoutes,
  vehiclesRoutes,
  materialsRoutes,
  dispatchRoutes,
  exportRoutes,
  reportsRoutes,
  employeesRoutes,
  jobDocsRoutes,
  settingsRoutes,
  userProfileRoutes,
  companyProfileRoutes,
  notificationRoutes,
]
export const companyRoutes = [
  sitesRoutes,
  scalesRoutes,
  companyJobsRoutes,
  companyDeliveriesRoutes,
  companyInvoicesRoutes,
  vehiclesRoutes,
  materialsRoutes,
  dispatchRoutes,
  exportRoutes,
  reportsRoutes,
  employeesRoutes,
  jobDocsRoutes,
  pendingRatingsRoutes,
  settingsRoutes,
  userProfileRoutes,
  companyProfileRoutes,
  notificationRoutes,
]
export const wasteHaulerRoutes = [
  sitesRoutes,
  scalesRoutes,
  subConJobsRoutes,
  companyDeliveriesRoutes,
  companyInvoicesRoutes,
  vehiclesRoutes,
  materialsRoutes,
  dispatchRoutes,
  exportRoutes,
  reportsRoutes,
  employeesRoutes,
  jobDocsRoutes,
  pendingRatingsRoutes,
  settingsRoutes,
  userProfileRoutes,
  companyProfileRoutes,
  notificationRoutes,
]
export const adminRoutes = [
  sitesRoutes,
  scalesRoutes,
  companyJobsRoutes,
  companyDeliveriesRoutes,
  companyInvoicesRoutes,
  vehiclesRoutes,
  materialsRoutes,
  dispatchRoutes,
  exportRoutes,
  reportsRoutes,
  employeesRoutes,
  jobDocsRoutes,
  pendingRatingsRoutes,
  settingsRoutes,
  administratorRoutes,
  userProfileRoutes,
  companyProfileRoutes,
  notificationRoutes,
]

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  sitesRoutes,
  scalesRoutes,
  jobsRoutes,
  dashboardsRoutes,
  pagesRoutes,
  profileRoutes,
  projectsRoutes,
  orderRoutes,
  invoiceRoutes,
  tasksRoutes,
  calendarRoutes,
  authRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
  presentationRoutes,
  documentationRoutes,
  changelogRoutes
];
