import { Button, Checkbox, Grid, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DistributiveOmit, useDispatch } from "react-redux";
import { useRootSelector } from "../../../..";
import { popAlert } from "../../../../redux/actions/sessionActions";
import { CustomMaterial, JobQuery, JobQueryTypes, Material } from "../../../../redux/reducers/entities";
import { parseError } from "../../../../redux/reducers/helpersReducerr";
import { getCustomMaterialsByQuery } from "../../../../redux/util/materialAPI";
import { QueryHandler } from "../../Jobs/components/QueryHandler";
import TPModal from "../../Util/TPModal";

interface ParentState { 
    // material?: Material;
    // customMaterial?: CustomMaterial,
    // selectedMaterials: (Material | CustomMaterial)[];
    displayMaterials: string;
    hasBatchOrInspectableMaterial: boolean;
}
interface Props {
    jobType?: string;
    setModalOpen: (bool: boolean) => void;
    // selectedMaterials: (Material | CustomMaterial)[]
    // onMaterialsSelected: (newState: ParentState) => void;
    onCustomMaterialsToAdd: (customMaterials: CustomMaterial[]) => void;
}

const SearchMaterialsModal = ({
    jobType,
    setModalOpen,
    // selectedMaterials,
    // onMaterialsSelected,
    onCustomMaterialsToAdd
}: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const token = useRootSelector(state => state.session.user.token!)
    const companyID = useRootSelector(state => state.session.company.id!)
    const [loading, setLoading] = useState(false)
    const defaults = useRootSelector(state => state.session.user.settings!)

    // const [currentMaterials, setCurrentMaterials] = useState<(Material | CustomMaterial)[]>(selectedMaterials || [])

    // const materials = useRootSelector(state => state.entities.materials)
    // const [searchMaterials, setSearchMaterials] = useState("")

    // const customMaterialListNames = useRootSelector(state => state.entities.customMaterialListNames)
    // const customMaterials = useRootSelector(state => state.entities.customMaterials)
    // const [searchCustomMaterials, setSearchCustomMaterials] = useState("")

    // const [showCustomMaterials, setShowCustomMaterials] = useState(false)

    // const addMaterial = (material: Material | CustomMaterial) => {
    //     let newMaterial = { 
    //         ...material,
    //         is_custom_material: showCustomMaterials,
    //         estimated_weight: "",
    //         estimated_weight_units: defaults.default_weight_unit || "",
    //         estimated_volume: "",
    //         estimated_volume_units: defaults.default_volume_unit || "",
    //     };
    //     if (currentMaterials.find(mat => mat.is_custom_material === showCustomMaterials && mat.id === material.id)) return;

    //     newMaterial.is_custom_material = true
    //     setCurrentMaterials([ ...currentMaterials, newMaterial ])
    //     showCustomMaterials ? setSearchCustomMaterials("") : setSearchMaterials("")
    // }
    // const removeMaterial = (material: Material | CustomMaterial) => {
    //     setCurrentMaterials([...currentMaterials].filter(mat => mat.is_custom_material !== material.is_custom_material && mat.id !== material.id))
    // }
    const [customMaterials, setCustomMaterials] = useState<CustomMaterial[]>([])
    const [selectedCustomMaterials, setSelectedCustomMaterials] = useState<CustomMaterial[]>([])
    const [finalQuery, setFinalQuery] = useState<DistributiveOmit<JobQuery, "type">>({})
    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        requiresPagination: false,
    })

    const fetchCustomMaterials = async (query: DistributiveOmit<JobQuery, "type"> = finalQuery) => {
        const response = await getCustomMaterialsByQuery(token, companyID, query, { page: pagination.page + 1 })
        if (!response.success) {
            const errorMsg = parseError(response)
            dispatch(popAlert('error', t('error'), errorMsg))
            return
        }
        setPagination({
            ...pagination,
            count: response.data.total,
            requiresPagination: response.data.total > 20
        })
        setCustomMaterials(response.data.data)
    }

    const addCustomMaterial = (cm: CustomMaterial) => {
        if (selectedCustomMaterials.find(selected => selected.id === cm.id)) return;
        setSelectedCustomMaterials([ ...selectedCustomMaterials, cm ])

    }
    const removeCustomMaterial = (cm: CustomMaterial) => {
        setSelectedCustomMaterials([...selectedCustomMaterials].filter(selected => selected.id !== cm.id))
    }


    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        // let hasBatchOrInspectableMaterial = false;
        // const newMaterialDisplay = currentMaterials.map(mat => {
        //     if (!hasBatchOrInspectableMaterial) {
        //         hasBatchOrInspectableMaterial = mat.is_inspectable
        //     }
        //     return mat.name
        // }).join(", ")

        // const newState: ParentState = {
        //     hasBatchOrInspectableMaterial,
        //     displayMaterials: newMaterialDisplay,
        //     selectedMaterials: currentMaterials,
        // }
        // onMaterialsSelected(newState)
        onCustomMaterialsToAdd(selectedCustomMaterials)
        setModalOpen(false)
    }

    return <TPModal
        setModalOpen={setModalOpen}
        loading={loading}
        headerOptions={{
            headerText: "Select Materials"
        }}
    >
        <QueryHandler 
            buttonText="Search Materials"
            buttonOnClick={(isValid, query) => {
                if (isValid) {
                    setFinalQuery(query)
                    fetchCustomMaterials(query)
                }
            }}
            onQueryChanged={query => {
                setPagination({
                    page: 0,
                    count: 0,
                    requiresPagination: false
                })
            }}
            disallowedTypes={[
                JobQueryTypes.MATERIAL
            ]}
        />
        <form onSubmit={handleSubmit}>
            <Grid container spacing={10}>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {customMaterials.map(cm => <TableRow key={cm.id}>
                                    <TableCell padding="checkbox">
                                        <Checkbox 
                                            onChange={(_, checked) => {
                                                if (checked) {
                                                    addCustomMaterial(cm)
                                                } else {
                                                    removeCustomMaterial(cm)
                                                }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="textSecondary">{cm.material_number}</Typography>
                                        <Typography style={{ fontWeight: "bold" }}>{cm.name}</Typography>
                                        <Typography>{cm.description}</Typography>
                                    </TableCell>
                                </TableRow>)}
                            </TableBody>
                            {pagination.requiresPagination && <TableFooter>
                                <TableRow>
                                    <TablePagination 
                                        component="td"
                                        page={pagination.page}
                                        count={pagination.count}
                                        onPageChange={(_, newPage) => setPagination({ ...pagination, page: newPage })}
                                        rowsPerPage={20}
                                        rowsPerPageOptions={[20]}
                                    />
                                </TableRow>
                            </TableFooter>}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={6}>
                    <Button
                        color="primary"
                        fullWidth
                        variant="outlined"
                        type="button"
                        onClick={() => setModalOpen(false)}
                    >
                        {t('actions.cancel')}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        type="submit"
                    >
                        {t('actions.save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    </TPModal>

}

export default SearchMaterialsModal