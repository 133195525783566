import { Button, Checkbox, Collapse, Dialog, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useRootSelector } from "../../../..";
import { popAlert } from "../../../../redux/actions/sessionActions";
import { CustomMaterial, MaterialCategorization, MaterialCategory, MaterialCategorizationValue, MixDesign, MaterialCategoryDataTypes, MaterialCategorizationValueMap, Material } from "../../../../redux/reducers/entities";
import { parseError, toMysqlFormat } from "../../../../redux/reducers/helpersReducerr";
import { addCategorizationsToCustomMaterial, removeCategorizationFromCustomMaterial } from "../../../../redux/util/materialAPI";
import TPModal from "../../Util/TPModal";

interface Props {
    setModalOpen: (bool: boolean) => void;
    categories: MaterialCategory[];
    categorizations?: MaterialCategorization[];
    refreshData?: () => void;

    // for create
    setCategorizations?: (categorizations: MaterialCategorizationValue[]) => void;
    
    // for edit
    customMaterial?: Material | CustomMaterial | MixDesign
}

export const formatCategorizationValue = (categorizationValue: MaterialCategorizationValue, category?: MaterialCategory) => {
    if (!categorizationValue.units) {
        delete categorizationValue.units
    }
    switch (categorizationValue.categorization?.category.data_type || category?.data_type) {
        case MaterialCategoryDataTypes.DATE:
            categorizationValue.value = toMysqlFormat(categorizationValue.value, { dateOnly: true })
            break;
        case MaterialCategoryDataTypes.DATETIME:
            categorizationValue.value = toMysqlFormat(categorizationValue.value)
            break;
        case MaterialCategoryDataTypes.BOOLEAN:
            categorizationValue.value = !!categorizationValue.value
            break;
        default: 
            break;
    }
    delete categorizationValue.categorization
    return categorizationValue
}

const EditMaterialCategorizationsModal = ({
    setModalOpen,
    categories,
    categorizations,
    setCategorizations,
    customMaterial,
    refreshData
}: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const token = useRootSelector(state => state.session.user.token!)
    const companyID = useRootSelector(state => state.session.company.id!)
    const [loading, setLoading] = useState(false)
    const [valuesMap, setValuesMap] = useState<MaterialCategorizationValueMap>({})

    // Convert the list of categorizations into a map
    useEffect(() => {
        if (categorizations) {
            const map: MaterialCategorizationValueMap = {}
            categorizations.forEach(categorization => {
                let tempValue: any = categorization.value
                if (categorization.category.data_type === 'boolean') {
                    tempValue = categorization.value === "1"
                }

                map[categorization.category_key] = {
                    id: categorization.id,
                    category_key: categorization.category_key,
                    categorization,
                    value: tempValue,
                    units: categorization.units || ""
                }
            })
            setValuesMap(map)
        }
    }, [])

    // Handles when a checkbox is clicked
    const handleChecked = (category: MaterialCategory) => (e: React.ChangeEvent, checked: boolean) => {
        if (checked) {
            addCategorization(category)
        } else {
            removeCategorization(category)
        }
    }

    // Adds a new entry to the categorization map and sets a default value
    const addCategorization = (category: MaterialCategory) => {
        if (valuesMap[category.key]) return;
        console.log("adding cat", category.key)
        const isDateType = category.data_type === MaterialCategoryDataTypes.DATE || category.data_type === MaterialCategoryDataTypes.DATETIME
        setValuesMap({
            ...valuesMap,
            [category.key]: {
                category_key: category.key,
                value: isDateType ? new Date() : "",
                units: ""
            }
        })
    }

    // Removes an entry in the categorization map
    const removeCategorization = (category: MaterialCategory) => {
        console.log("removing", category.key)
        const newValues = { ...valuesMap }
        delete newValues[category.key]
        setValuesMap(newValues)
    }

    // Handles updating the categorization value
    const updateCategoryField = (categoryKey: string) => (field: string, value: any) => {
        const newValues = { ...valuesMap }
        newValues[categoryKey][field] = value
        setValuesMap(newValues)
    }

    // Handles form submission
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (!customMaterial) {
            setCategorizations?.(
                formatCategorizationValues(
                    Object.values(valuesMap)
                )
            )
            setModalOpen(false)
            return
        }
        handleUpdateMaterial()
    }

    // Gets used this modal is used for a pre-existing material
    // Sets the modal loading and determines which categories need to be removed, if any
    const handleUpdateMaterial = async () => {
        setLoading(true)
        const categorizationIDsForRemoval: number[] = []
        categorizations?.forEach(catVal => {
            if (!valuesMap[catVal.category_key]) {
                categorizationIDsForRemoval.push(catVal.id)
            }
        })

        await removeCategorizations(categorizationIDsForRemoval)

        // Will return falsey if theres an error
        const update = await updateCategorizations()
        if (!update) {
            setLoading(false)
            return;
        }
        refreshData?.()
        setModalOpen(false)
    }

    const formatCategorizationValues = (categorizationValues: MaterialCategorizationValue[]) => {
        return categorizationValues.map(catVal => formatCategorizationValue(catVal))
    }

    // Handles updating the material's categorizations
    const updateCategorizations = async () => {
        const categorizationValues = Object.values(valuesMap)
        if (!categorizationValues.length) return true;

        // Some info in the values should not be passed to the api. This gets rid of that.
        const formattedValues = formatCategorizationValues(categorizationValues)

        const response = await addCategorizationsToCustomMaterial(token, companyID, customMaterial!.id!, formattedValues)
        if (!response.success) {
            const errorMsg = parseError(response)
            setLoading(false)
            dispatch(popAlert('error', t('error'), errorMsg))
            return
        }
        dispatch(popAlert('success', t('success'), "Updated material categorizations"))
        setModalOpen(false)
    }

    // Handles removed material categorizations
    const removeCategorizations = async (categorizationIDs: number[]) => {
        if (!categorizationIDs.length) return true;
        const promises: Promise<any>[] = []
        categorizationIDs.forEach(id => {
            promises.push(
                removeCategorizationFromCustomMaterial(token, companyID, customMaterial!.id!, id)
            )
        })
        return Promise.all(promises)
    }

    return <TPModal
        setModalOpen={setModalOpen}
        loading={loading}
        headerOptions={{
            headerText: "Material Categories"
        }}
    >
        <form onSubmit={handleSubmit}>
            <TableContainer>
                <Table>
                    <TableBody>
                        {categories.map(category => {
                            const isChecked = !!valuesMap[category.key]
                            return <React.Fragment key={`${category.key}-${isChecked}`}>
                                <TableRow >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={isChecked}
                                            onChange={handleChecked(category)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{category.name}</Typography>
                                        <Typography>{category.description}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} padding={isChecked ? "default" : "none"}>
                                        <Collapse in={isChecked} timeout="auto">
                                            <RenderMaterialCategorizationInput
                                                category={category}
                                                categorizationValue={valuesMap[category.key]}
                                                setField={updateCategoryField(category.key)}
                                            />
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container spacing={10}>
                <Grid item xs={12} sm={6}>
                    <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        type="button"
                        onClick={() => setModalOpen(false)}
                    >
                        {t('actions.cancel')}
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        {t('actions.save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    </TPModal>
}

export default EditMaterialCategorizationsModal

interface RenderMaterialCategorizationInputProps {
    category: MaterialCategory,
    categorizationValue: MaterialCategorizationValue
    setField: (field: string, value: any) => void;
}
export const RenderMaterialCategorizationInput = ({
    category,
    categorizationValue,
    setField
}: RenderMaterialCategorizationInputProps) => {
    const { t } = useTranslation()
    if (!categorizationValue) return <></>

    // Categories with `allowed_selections` will only have one input to render
    if (category.allowed_selections) {
        return <FormControl fullWidth variant="outlined">
            <InputLabel id={`${category.name}-selection`}>{category.name}</InputLabel>
            <Select
                label={category.name}
                labelId={`${category.name}-selection`}
                value={categorizationValue.value}
                onChange={(e) => setField('value', e.target.value)}
                inputProps={{
                    name: `${category.name}-selection`,
                    id: `${category.name}-selection`
                }}
                fullWidth
                required
                error={!categorizationValue.value}
            >
                {category.allowed_selections.map(selection => (
                    <MenuItem key={selection} value={selection}>{selection}</MenuItem>
                ))}
            </Select>
        </FormControl>
    }

    // Categories that don't have `allowed_selections` have a value input, and optionally a unit selector
    let valueInput;
    let unitSelector;
    if (category.allowed_units) {
        unitSelector = <FormControl fullWidth variant="outlined">
            <InputLabel id={`${category.name}-units`}>Units</InputLabel>
            <Select
                label={category.name}
                labelId={`${category.name}-units`}
                value={categorizationValue.units}
                onChange={(e) => setField('units', e.target.value)}
                inputProps={{
                    name: `${category.name}-units`,
                    id: `${category.name}-units`
                }}
                fullWidth
                required
                error={!categorizationValue.units}
            >
                {category.allowed_units.map(unit => (
                    <MenuItem key={unit} value={unit}>{t(`unitTranslations.${unit}`)}</MenuItem>
                ))}
            </Select>
        </FormControl>
    }
    switch (category.data_type) {
        case MaterialCategoryDataTypes.DECIMAL:
        case MaterialCategoryDataTypes.INTEGER:
            valueInput = <FormControl fullWidth>
                <TextField
                    id={category.name}
                    label={category.name}
                    value={categorizationValue.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setField("value", e.target.valueAsNumber)}
                    type="number"
                    inputProps={{
                        min: category.range_min || 0,
                        max: category.range_max || 0,
                        step: "any"
                    }}
                    variant="outlined"
                    required
                    error={!categorizationValue.value}
                />
            </FormControl>
            break;
        case MaterialCategoryDataTypes.STRING:
            valueInput = <FormControl fullWidth>
                <TextField
                    id={category.name}
                    label={category.name}
                    value={categorizationValue.value}
                    onChange={e => setField("value", e.target.value)}
                    type="text"
                    required
                    error={!categorizationValue.value}
                    variant="outlined"
                />
            </FormControl>
            break;
        case MaterialCategoryDataTypes.BOOLEAN:
            valueInput = <FormControl component="fieldset">
                <FormLabel component="legend">{category.name}</FormLabel>
                <RadioGroup
                    id={category.name}
                    name={category.name}
                    aria-label={category.name}
                    value={categorizationValue.value}
                    onChange={e => setField("value", e.target.value === "true")}
                    row
                >
                    <FormControlLabel
                        value={false}
                        control={<Radio color="primary" required />}
                        label={t('no')}
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value={true}
                        control={<Radio color="primary" required />}
                        label={t('yes')}
                        labelPlacement="end"
                    />
                </RadioGroup>
            </FormControl>
            break;
        case MaterialCategoryDataTypes.DATE:
            valueInput = <FormControl fullWidth>
                <KeyboardDatePicker
                    label={category.name}
                    value={categorizationValue.value}
                    onChange={date => setField("value", date)}
                    inputVariant="outlined"
                    required
                    format="MM/dd/yyyy"
                />
            </FormControl>
            break;

        case MaterialCategoryDataTypes.DATETIME:
            valueInput = <FormControl fullWidth>
                <KeyboardDateTimePicker
                    label={category.name}
                    value={categorizationValue.value}
                    onChange={date => setField("value", date)}
                    inputVariant="outlined"
                    required
                    format="MM/dd/yyyy hh:mm a"
                />
            </FormControl>
            break;

        default:
            valueInput = <>{t("unknownErrorShort")}</>
    }
    return <Grid container spacing={5}>
        <Grid item xs>
            {valueInput}
        </Grid>
        {Boolean(unitSelector) && <Grid item>
            {unitSelector}
        </Grid>}
    </Grid>

}