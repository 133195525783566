import { TPAPIBase } from "../../env";
import i18n from "../../i18n";
import { Company } from "../reducers/entities";
import { User } from "../reducers/entities/User";
import { formatParams } from "../reducers/helpersReducerr";
import { BooleanParam, Paginate, PaginatedAPIOptions, TPResponse } from "./util";


interface GetDriversForDispatchReturn {
    company: Company;
    company_id: number;
    deleted_at?: string;
    edt?: string;
    fire_reason_id?: number
    id: number;
    is_active: boolean;
    position_id: number;
    user: User;
    user_id: number;
}
interface GetDriversForDispatchOptions extends PaginatedAPIOptions {
    token: string;
    companyId: number;
    jobId: number;
    start?: number;
    end?: number;
    allow_overlapping?: BooleanParam,
    driver_company_id?: number;
}
export const getDriversForDispatch = ({
    token,
    companyId,
    jobId,
    ...params
}: GetDriversForDispatchOptions): Promise<TPResponse<Paginate<GetDriversForDispatchReturn[]>>> => {
    const formattedParams = formatParams(params)
    return fetch(`${TPAPIBase}/company/${companyId}/job/${jobId}/dispatch/drivers?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}

export const getAllDriversForDispatch = async (options: GetDriversForDispatchOptions): Promise<TPResponse<GetDriversForDispatchReturn[]>> => {
    const data: GetDriversForDispatchReturn[] = []
    let fetchedAll = false
    let page = 1
    while (!fetchedAll) {
        const response = await getDriversForDispatch({ ...options, page })
        if (!response.success) fetchedAll = true;
        data.push(...response.data.data)
        if (!response.data.next_page_url) fetchedAll = true;
        page++ 
    }

    return { success: true, data, error: null }
}