import moment from "moment";


const FULL_TIMESTAMP_REGEX = /(?<year>[\d]+)-(?<month>[\d]+)-(?<day>[\d]+) (?<hour>[\d]+):(?<min>[\d]+):(?<sec>[\d]+)/
const DATE_TIMESTAMP_REGEX = /(?<year>[\d]+)-(?<month>[\d]+)-(?<day>[\d]+)/
const TIME_TIMESTAMP_REGEX = /(?<hour>[\d]+):(?<min>[\d]+):(?<sec>[\d]+)/

/**
 *  * Converts a timestamp to a date.
 * 
 * **If a timestamp isn't given, or the timestamp didn't match one of the supported formats, new Date() is returned**
 * 
 * __Supported formats:__
 * - "2001-11-30 00:00:00"
 * - "2001-11-30"
 *   - time will be current time
 * - "00:00:00"
 *   - date will be current date
 * 
 * __Params__
 * - timestamp timestamp matching one of the supported formats
 * - options
 *    - isLocal
 *       - true -> treat the timestamp as a local date
 *       - false -> treat the timestamp as a UTC date (account for UTC offset)
 * 
 * @param timestamp 
 * @param options 
 * @returns 
 */
export const createDateFromTimestamp = (timestamp: string, options = { isLocal: true }): Date => {
    if (!timestamp) return new Date()

    let parts: TimestampMatchGroups | null = null;

    if (FULL_TIMESTAMP_REGEX.test(timestamp)) {
        // Matches "2001-11-30 00:00:00" 
        parts = timestamp.match(FULL_TIMESTAMP_REGEX)!.groups!
    } else if (DATE_TIMESTAMP_REGEX.test(timestamp)) {
        // Matches "2001-11-30"
        parts = timestamp.match(DATE_TIMESTAMP_REGEX)!.groups!
    } else if (TIME_TIMESTAMP_REGEX.test(timestamp)) {
        // Matches "00:00:00"
        parts = timestamp.match(TIME_TIMESTAMP_REGEX)!.groups!
    } 
    
    // 
    if (!parts) return new Date()
    
    // Get parsed values (fallback on current value)
    const defaultDate = new Date()
    const year = parts.year ? Number.parseInt(parts.year, 10) : defaultDate.getFullYear()
    const month = parts.month ? Number.parseInt(parts.month, 10) - 1 : defaultDate.getMonth()
    const day = parts.day ? Number.parseInt(parts.day, 10) : defaultDate.getDay()
    const hour = parts.hour ? Number.parseInt(parts.hour, 10) : defaultDate.getHours()
    const min = parts.min ? Number.parseInt(parts.min, 10) : defaultDate.getMinutes()
    const sec = parts.sec ? Number.parseInt(parts.sec, 10) : defaultDate.getSeconds()

    // Account for UTC offset
    if (!options.isLocal) {
        return new Date(Date.UTC(year, month, day, hour, min, sec));
    } 

    // Create date without accounting for UTC offset
    return new Date(year, month, day, hour, min, sec);
}
interface TimestampMatchGroups {
    year?: string;
    month?: string;
    day?: string;
    hour?: string;
    min?: string;
    sec?: string;
}



/**
 * Formats a date using a given format
 * 
 * @param date date to be formatted
 * @param format format to use
 * @returns 
 */
export const formatDate = (date: Date, format: DateFormats): string => {
    return moment(date).format(format)
}
export enum DateFormats {
    DATE_FORMAT = 'YYYY-MM-DD',
    DATE_FORMAT_MEDIUM = 'MMM DD, YYYY',

    TIME_FORMAT = "hh:mm:ss",
    TIME_FORMAT_MEDIUM = "hh:mm A",
    
    DATETIME_FORMAT = "YYYY-MM-DD hh:mm:ss",
    DATETIME_FORMAT_MEDIUM = 'MM/DD/YY hh:mm A',
    DATETIME_FORMAT_FULL = 'MMM DD, YYYY, hh:mm A z',
}