import { Button, FormControl, Grid } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React, { FormEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useRootSelector } from "../../../..";
import { popAlert } from "../../../../redux/actions/sessionActions";
import { Vehicle } from "../../../../redux/reducers/entities";
import { makeUnixTime, parseError } from "../../../../redux/reducers/helpersReducerr";
import { createLegalDoc } from "../../../../redux/util/vehicleAPI_ts";
import Separator from "../../Util/Separator";

interface Props {
    newIds: number[];
    setIsLoading: (bool: boolean) => void;
    setModalOpen: (bool: boolean) => void;
}

const UploadMultipleVehicleLegalDocs = ({
    newIds,
    setIsLoading,
    setModalOpen
}: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const token = useRootSelector(state => state.session.user.token!!)
    const companyId = useRootSelector(state => state.session.company.id!!)
    const trucks = useRootSelector<Vehicle[]>(state => state.entities.vehicles)

    const [searchVehicles, setSearchVehicles] = useState("")
    const [files, setFiles] = useState<Record<number, File>>({})
    const [expirationDates, setExpirationDates] = useState<Record<number, Date>>({})

    const newTrucks = useMemo(() => {
        return trucks.filter(truck => newIds.includes(truck.id))
    }, [trucks])

    const filteredOptions = useMemo(() => {
        if (!searchVehicles) return newTrucks
        return newTrucks.filter(vehicle => {
            const vehicleInfo = [
                vehicle.name, 
                vehicle.license_plate, 
                vehicle.license_plate_2, 
                vehicle.make, 
                vehicle.model, 
                vehicle.year,
            ].join(" ")
            return vehicleInfo.includes(searchVehicles)
        })

    }, [searchVehicles, newTrucks])

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setIsLoading(true)
        const formData = new FormData()

        // Form validations require dates for all files so just check the entered files
        const idsOfVehiclesWithData = Object.keys(files)
        for (let i = 0; i < idsOfVehiclesWithData.length; i++) {
            const vehicleId = parseInt(idsOfVehiclesWithData[i]);

            // Form validations should prevent this from being triggered, but it's here just in case
            if (!expirationDates[vehicleId]) {
                const erroneousVehicle = newTrucks.find(vehicle => vehicle.id === vehicleId)
                const name = erroneousVehicle?.name || "Unknown vehicle"
                dispatch(popAlert('error', t('error'), `${name} does not have an expiration date`))
                setIsLoading(false)
                return
            }

            formData.append(`data[${i}][image]`, files[vehicleId]);
            formData.append(`data[${i}][vehicle_id]`, vehicleId.toString());
            formData.append(`data[${i}][legal_document_type_id]`, "1");
            formData.append(`data[${i}][expiration_date]`, makeUnixTime(expirationDates[vehicleId]).toString())
        }

        try {
            const response = await createLegalDoc({ token, companyId, formData })
            if (!response.success) {
                setIsLoading(false)
                dispatch(popAlert('error', t('error'), parseError(response)))
                return;
            }
            dispatch(popAlert('success', t('success'), "Successfully uploaded insurance"))
            setModalOpen(false)
        } catch (error) {
            setIsLoading(false)
            dispatch(popAlert('error', t('error'), error))
        }
    }

    return <form onSubmit={handleSubmit}>
        <Grid container spacing={5}>
            {filteredOptions.map(vehicle => (<>
                <Grid item xs={12} md={4}>
                    {vehicle.name}
                </Grid>
                <Grid item xs={12} md={4}>
                    <label id="upload_insurance_label">Upload Insurance</label>
                    <input 
                        type="file"
                        accept="image/*, application/pdf"
                        aria-label="upload insurance"
                        aria-labelledby="upload_insurance_label"
                        onChange={e => setFiles({ ...files, [vehicle.id]: e.target.files!![0] })}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                        <KeyboardDatePicker
                            label="Expiration Date"
                            value={expirationDates[vehicle.id] || null}
                            onChange={date => setExpirationDates({ ...expirationDates, [vehicle.id]: date as Date })}
                            format="MM/dd/yyyy"
                            disablePast
                            inputVariant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Separator />
            </>))}
        </Grid>
        <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
                <Button
                    fullWidth
                    color="primary"
                    variant="outlined"
                    type="button"
                    onClick={() => setModalOpen(false)}
                >
                    {t("actions.cancel")}
                </Button>
            </Grid>
            <Grid item xs={12} md={6}>
                <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    type="submit"
                >
                    {t("actions.save")}
                </Button>
            </Grid>
        </Grid>
    </form>
}

export default UploadMultipleVehicleLegalDocs