const typography = (textSizeValue) => {
  let fontSize = 13;
  switch (textSizeValue) {
    case 5: {
      fontSize = 21;
      break;
    }
    case 4: {
      fontSize = 19;
      break;
    }
    case 3: {
      fontSize = 17;
      break;
    }
    case 2: {
      fontSize = 15;
      break;
    }
    default: {
      fontSize = 13;
      break;
    }
  }
  return {
    useNextVariants: true,
    fontFamily: [
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    fontSize: fontSize,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    fontWeightBolder: 800,
    fontWeightBoldest: 900,
    h1: {
      fontSize: "2rem",
      fontWeight: 600,
      lineHeight: 1.2
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: 600,
      lineHeight: 1.2
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 600,
      lineHeight: 1.2
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: 1.2
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: 600,
      lineHeight: 1.2
    },
    h6: {
      fontSize: "1.0625rem",
      fontWeight: 600,
      lineHeight: 1.2
    },
    body1: {
      fontSize: fontSize
    },
    button: {
      textTransform: "none"
    }
  }
};

export default typography;
