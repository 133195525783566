import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CreateJobModal from '../pages/dashboards/Jobs/modals/ModalCreateEditDuplicateJob';
import CreateSiteModal from '../pages/dashboards/Sites/modals/ModalCreateEditSite';
import CreateScaleModal from '../pages/dashboards/Scales/modals/ModalCreateEditScale';
import CreateVehicleModal from '../pages/dashboards/Vehicles/modals/ModalCreateEditVehicle';
import CreateCompanyModal from '../pages/dashboards/Companies/modals/ModalCreateEditCompany';
import CreateInspectionModal from "../pages/dashboards/Inspector/modals/ModalCreateInspection";

// import Settings from "../components/Settings";

import { spacing } from "@material-ui/system";
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";
import { useLocation } from "react-router";

const drawerWidth = 240;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

// const Drawer = styled.div`
//   ${props => props.theme.breakpoints.up("md")} {
//     width: ${drawerWidth}px;
//     flex-shrink: 0;
//   }
// `;
const Drawer = styled.div`
  ${props => props.shouldHide ? "" : `
    ${props.theme.breakpoints.up("md")} {
      width: ${drawerWidth}px;
      flex-shrink: 0;
    }
  `}
`;
// const Drawer = styled.div`
// `;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${props => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children, routes, width, companyRestrictionDate, updateStyleSettings, extendedChildren }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [createJobModal, setCreateJobModal] = useState(false);
  const [createSiteModal, setCreateSiteModal] = useState(false);
  const [createScaleModal, setCreateScaleModal] = useState(false);
  const [createVehicleModal, setCreateVehicleModal] = useState(false);
  const [createCompanyModal, setCreateCompanyModal] = useState(false);
  const [createInspectionModal, setCreateInspectionModal] = useState(false);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const shouldClose = location.pathname === '/plantOrders' || location.pathname === '/dispatch'
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer shouldHide={shouldClose ? "true" : "" }>
        <Hidden mdUp={shouldClose ? false : true } implementation="js">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            setCreateJobModal={setCreateJobModal}
            setCreateSiteModal={setCreateSiteModal}
            setCreateScaleModal={setCreateScaleModal}
            setCreateVehicleModal={setCreateVehicleModal}
            setCreateCompanyModal={setCreateCompanyModal}
            setCreateInspectionModal={setCreateInspectionModal}
            companyRestrictionDate={companyRestrictionDate}
            updateStyleSettings={updateStyleSettings}
          />
        </Hidden>
        {!shouldClose && <Hidden smDown implementation="css">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            setCreateJobModal={setCreateJobModal}
            setCreateSiteModal={setCreateSiteModal}
            setCreateScaleModal={setCreateScaleModal}
            setCreateVehicleModal={setCreateVehicleModal}
            setCreateCompanyModal={setCreateCompanyModal}
            setCreateInspectionModal={setCreateInspectionModal}
            companyRestrictionDate={companyRestrictionDate}
            updateStyleSettings={updateStyleSettings}
          />
        </Hidden>}
      </Drawer>
      <AppContent>
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isWidthUp("lg", width) ? 10 : 8}>
          {children}
        </MainContent>
        {/* {extendedChildren} */}
        {createJobModal && <CreateJobModal type="create" setModalOpen={setCreateJobModal}/>}
        {createSiteModal && <CreateSiteModal type="create" setModalOpen={setCreateSiteModal}/>}
        {createScaleModal && <CreateScaleModal type="create" setModalOpen={setCreateScaleModal}/>}
        {createVehicleModal && <CreateVehicleModal type="create" setModalOpen={setCreateVehicleModal}/>}
        {createCompanyModal && <CreateCompanyModal type="create" setModalOpen={setCreateCompanyModal}/>}
        {createInspectionModal && <CreateInspectionModal setModalOpen={setCreateInspectionModal}/>}
        <Footer />
      </AppContent>
      {/* <Settings /> */}
    </Root>
  )
}

export default withWidth()(Dashboard);
