
import * as InspectorAPI from '../util/inspectorAPI';
import i18n from '../../i18n';
import { popAlert } from './sessionActions'
import { DeliveryApi, JobInspectorAssignment } from '../reducers/entities';
import { Action, Dispatch } from 'redux';

export const RECEIVE_INSPECTOR_TOKENS = "RECEIVE_INSPECTOR_TOKENS";
export const TRIGGER_INSPECTOR_FEED_UPDATE = "TRIGGER_INSPECTOR_FEED_UPDATE";
export const TRIGGER_INSPECTOR_JOB_DELIVERY_FEED_UPDATE = "TRIGGER_INSPECTOR_JOB_DELIVERY_FEED_UPDATE";

export const receiveInspectorTokens = (inspectorAssignments: JobInspectorAssignment[]) => ({
    type: RECEIVE_INSPECTOR_TOKENS,
    inspectorAssignments
})

export const triggerInspectorFeedUpdate = (shouldUpdate: boolean) => ({
    type: TRIGGER_INSPECTOR_FEED_UPDATE,
    shouldUpdate
})
export const triggerInspectorJobDeliveryFeedUpdate = (shouldUpdate: boolean) => ({
    type: TRIGGER_INSPECTOR_JOB_DELIVERY_FEED_UPDATE,
    shouldUpdate
})

export const getAllInspectorTokens = (token: string, companyID: number, options = {}) => (dispatch: Dispatch) => {
    console.log("ALL INSP OPTIONS", options);
    return new Promise(async (res, rej) => {
        const items: any[] = []
        let page = 1;
        let fetchedAll = false;
        while (!fetchedAll) {
            await InspectorAPI.getInspectorTokens(token, companyID, { ...options, page }).then(
                (res) => {
                    if (res?.success && res.data.data) {
                        items.push(...res.data.data)
                        if (res.data.next_page_url) {
                            page += 1;
                        } else {
                            fetchedAll = true
                        }
                    } else {
                        fetchedAll = true
                    }
                },
                (errors) => {
                    fetchedAll = true
                    dispatch(popAlert('error', i18n.t(`error`), errors.responseJSON))
                }
            ).catch(err => fetchedAll = true)
        }
        dispatch(receiveInspectorTokens(items))
    })
}
