import { TPAPIBase } from '../../env';
import i18n from '../../i18n';
import { Paginate, TPResponse } from './util';
import { 
    CustomMaterialTag,
} from '../reducers/entities'
import { ETicketDetails, ETicketMap, ETicketParameterValidationMap, ETicketParameterValueMap, ETicketUploadReadinessStatus, ETicketUploadReceipt, JobETicketTarget, RequiredIfDetails } from '../reducers/entities/ETicket';

interface GetETicketUploadReadinessStatusOptions {
    detailed?: boolean
}
export const getETicketUploadReadinessStatus = (token: string, deliveryID: number, eTicketTarget: string, options: GetETicketUploadReadinessStatusOptions = {}): Promise<TPResponse<ETicketUploadReadinessStatus>> => (
    fetch(`${TPAPIBase}/delivery/${deliveryID}/eticket/${eTicketTarget}/upload-readiness?detailed=${options.detailed ? 1 : 0}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)

export const runETicketTargetIfReady = (token: string, deliveryID: number, eTicketTargetID: number): Promise<TPResponse<CustomMaterialTag>> => (
    fetch(`${TPAPIBase}/delivery/${deliveryID}/eticket/${eTicketTargetID}/run`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
interface GetETicketUploadReceiptsOptions {
    [key: string]: number;
}
export const getETicketUploadReceipts = (token: string, deliveryID: number, eTicketTarget: string, options: GetETicketUploadReceiptsOptions = {}): Promise<TPResponse<Paginate<ETicketUploadReceipt[]>>> => {
    const formattedParams = Object.keys(options).map(key => `${key}=${options[key]}`).join("&")
    return fetch(`${TPAPIBase}/delivery/${deliveryID}/eticket/${eTicketTarget}/receipts?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}

export const getETicketTargetsWithDetails = (token: string, companyID: number): Promise<TPResponse<ETicketMap>> => (
    fetch(`${TPAPIBase}/company/${companyID}/eticket-targets`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then((response: TPResponse<ETicketMap>) => {
        if (response?.success && response.data) {
            const possibleTargetNames = Object.keys(response.data)
            possibleTargetNames.forEach(targetName => {
                const parameterNames = Object.keys(response.data[targetName].parameters)
                response.data[targetName].parameterKeys = parameterNames
                const targetParameterDependencies: RequiredIfDetails[] = []
                parameterNames.forEach(parameterName => {
                    const parameter = response.data[targetName].parameters[parameterName]
                    const validations = parameter.validation.split("|")
                    const validationMap: ETicketParameterValidationMap = {}
                    validations.forEach(validation => {
                        if (validation?.length) {
                            if (validation === "required") {
                                validationMap.required = true
                            } else if (validation === "numeric") {
                                validationMap.numeric = true
                            } else if (validation === "not_empty_string") {
                                validationMap.notEmptyString = true
                            } else if (validation.includes("digits_between:")) {
                                const restrictions = validation.split(":")[1]
                                const digits = restrictions.split(",")
                                const startNumber = parseInt(digits[0])
                                const endNumber = parseInt(digits[1])

                                validationMap.min = parseInt("1" + "0".repeat(startNumber - 1))
                                validationMap.max = parseInt("9".repeat(endNumber))
                                validationMap.minLength = startNumber
                                validationMap.maxLength = endNumber
                            } else if (validation.includes("in:")) {
                                const restrictions = validation.split(":")[1]
                                const options = restrictions.split(",")
                                validationMap.in = options
                            } else if (validation.includes("min:")) {
                                const restrictions = validation.split(":")[1]
                                validationMap.min = parseInt(restrictions)
                            } else if (validation.includes("max:")) {
                                const restrictions = validation.split(":")[1]
                                if (validations.includes("string")) {
                                    validationMap.maxLength = parseInt(restrictions)
                                } else {
                                    validationMap.max = parseInt(restrictions)
                                }
                            } else if (validation.includes("digits:") || validation.includes("size:")) {
                                const restrictions = validation.split(":")[1]
                                validationMap.minLength = parseInt(restrictions)
                                validationMap.maxLength = parseInt(restrictions)
                            } else if (validation.includes("required_if:")) {
                                const restrictions = validation.split(":")[1]
                                const [key, val] = restrictions.split(",")
                                targetParameterDependencies.push([key, val, parameterName])
                            }
                        }
                    })
                    response.data[targetName].parameters[parameterName].validationMap = validationMap
                    response.data[targetName].parameters[parameterName].requiredByOther = false
                }) 
                response.data[targetName].parameterDependencies = targetParameterDependencies
            })
        }
        return response
    })
)
export const getETicketTargetsForJob = (token: string, jobID: number): Promise<TPResponse<JobETicketTarget[]>> => (
    fetch(`${TPAPIBase}/job/${jobID}/eticket-targets`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
interface ETicketTargetForJobPayload {
    [target: string]: ETicketParameterValueMap
}
export const addETicketTargetForJob = (token: string, jobID: number, payload: ETicketTargetForJobPayload): Promise<TPResponse<any>> => (
    fetch(`${TPAPIBase}/job/${jobID}/eticket-targets`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: {
                eticket_targets: payload
            }
        })
    }).then(response => response.json())
)
export const updateETicketTargetForJob = (token: string, jobID: number, payload: ETicketTargetForJobPayload): Promise<TPResponse<any>> => (
    fetch(`${TPAPIBase}/job/${jobID}/eticket-targets`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: {
                eticket_targets: payload
            }
        })
    }).then(response => response.json())
)
export const removeETicketTargetForJob = (token: string, jobID: number, eTicketTargetID: number): Promise<TPResponse<any>> => (
    fetch(`${TPAPIBase}/job/${jobID}/eticket-targets/${eTicketTargetID}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)