import * as DispatchAPI from '../util/dispatchAPI';

import { functions } from '../reducers/helpersReducer';
export const RECEIVE_DISPATCH_ORDERS_PAGE = "RECEIVE_DISPATCH_ORDERS_PAGE";
export const RECEIVE_ALL_DISPATCH_ORDERS = "RECEIVE_ALL_DISPATCH_ORDERS";

export const receiveDispatchOrdersPage = (page, dispatchOrdersData) => ({
    type: RECEIVE_DISPATCH_ORDERS_PAGE,
    page,
    totalCount: dispatchOrdersData.total,
    dispatchOrders: dispatchOrdersData.data.map(order => {
        order.startMoment = functions.getLocalMomentFromTimeStringWithTimezone(order.start_datetime, order.job_timezone)
        order.endMoment = functions.getLocalMomentFromTimeStringWithTimezone(order.end_datetime, order.job_timezone)
        order.formattedStart = functions.getDateTime(order.startMoment.toDate(), { timezone: order.job_timezone })
        order.formattedEnd = functions.getDateTime(order.endMoment.toDate(), { timezone: order.job_timezone })
        return order
    }),
})
export const receiveAllDispatchOrders = (dispatchOrders) => ({
    type: RECEIVE_ALL_DISPATCH_ORDERS,
    dispatchOrders,
})

export const getDispatchOrdersForCompany = (token, companyID, page) => dispatch => {
    return DispatchAPI.getDispatchOrdersForCompany(token, companyID, [{ key: 'page', value: page }]).then(
        (res) => {
            if (res.success && res.data) {
                dispatch(receiveDispatchOrdersPage(page, res.data))
            }
            return res
        }
    )
}
export const getAllDispatchOrdersForCompany = (token, companyID) => dispatch => {

    return new Promise(async (res, rej) => {
        const dispatchOrders = [];
        let page = 1;
        let fetchedAllDispatches = false;
        while (!fetchedAllDispatches) {
            await DispatchAPI.getDispatchOrdersForCompany(token, companyID, [{ key: 'page', value: page }]).then(
                (res) => {
                    if (res.success && res.data.data) {
                        dispatchOrders.push(...res.data.data.map(order => {
                            order.startMoment = functions.getLocalMomentFromTimeStringWithTimezone(order.start_datetime, order.job_timezone)
                            order.endMoment = functions.getLocalMomentFromTimeStringWithTimezone(order.end_datetime, order.job_timezone)
                            order.formattedStart = functions.getDateTime(order.startMoment.toDate(), { timezone: order.job_timezone })
                            order.formattedEnd = functions.getDateTime(order.endMoment.toDate(), { timezone: order.job_timezone })
                            return order
                        }))
                        if (res.data.next_page_url) {
                            page += 1;
                        } else {
                            fetchedAllDispatches = true
                        }
                    } else {
                        fetchedAllDispatches = true
                    }
                }
            )
        }
        dispatch(receiveAllDispatchOrders(dispatchOrders))
        res()
    })
}

