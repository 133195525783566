import { Company } from "./Company";
import { Address } from "./Location";
import { JobMaterial } from "./Material";
import { Site } from "./Site";
import { LengthUnit, VolumeUnit, WeightUnit, Currency, Frequency, VolumeUnits, WeightUnits } from "./Unit";
import { EquipmentType } from "./Vehicle";

// TODO determine "any;" types
export interface Bid {
    // TODO are these strings or numbers
    amount: number;
    reduced_amount: number;
}
export enum BidAmountStatuses {
    OPEN = "open",
    FIXED = "fixed",
    PRENEGOTIATED_ONLY = "prenegotiated_only",
}
export type BidAmountStatus = BidAmountStatuses.OPEN | BidAmountStatuses.FIXED | BidAmountStatuses.PRENEGOTIATED_ONLY
export interface ExtendedAttributeValues {

}
export enum PrivacyStatuses {
    PUBLIC = "public",
    EXCLUSIVE = "exclusive",
    WHITELIST = "whitelist",
    PRIVATE = "private",
}
export type PrivacyStatus = PrivacyStatuses.PUBLIC | PrivacyStatuses.EXCLUSIVE | PrivacyStatuses.WHITELIST | PrivacyStatuses.PRIVATE;

export interface SignOffMember {

}
export enum TransactionTypes {
    PER_LOAD = "per-load",
    PER_HOUR = "per-hour",
    PER_WEIGHT = "per-weight",
    PER_VOLUME = "per-volume",
}
export type TransactionType = TransactionTypes.PER_HOUR | TransactionTypes.PER_LOAD | TransactionTypes.PER_WEIGHT | TransactionTypes.PER_VOLUME
export interface TransactionTypeObject {
    id: number;
    name: TransactionType;
    description: string;
}
export enum CheckTypes {
    QR_CODE = "qr-code",
    SELF_CHECK = "self-check",
    SIGNATURE = "signature",
    SUPERVISOR = "supervisor",
}
export type CheckType = CheckTypes.QR_CODE | CheckTypes.SELF_CHECK | CheckTypes.SIGNATURE | CheckTypes.SUPERVISOR

export enum WeighLocations {
    PICKUP = "P",
    DROPOFF = "D",
}
export type WeighLocation = WeighLocations.PICKUP | WeighLocations.DROPOFF
export interface Route {
    address_from: Address;
    address_to: Address;
    auto_checkout: boolean;
    checkin_type: CheckType;
    checkout_type: CheckType;
    distance: number;
    distance_was_user_input: boolean;
    drop_off_geo_fence_distance: number;
    drop_off_scale_id: number;
    dropoff_site_id: number;
    eta: number;
    fixed_amount: any;
    from_address_id: number;
    geo_fence_distance: number;
    geo_fence_distance_unit: LengthUnit;
    id: number;
    is_driver_checkin_signature_required: boolean;
    is_driver_checkout_signature_required: boolean;
    is_geo_fenced: boolean;
    job_id: number;
    material: JobMaterial[]
    order_by: string;
    pick_up_geo_fence_distance: number;
    pick_up_scale_id: number;
    pickup_site_id: number;
    scan_location: null
    to_address_id: number;
    track_driver_destination: boolean;
    truck_weigh_location: WeighLocation;
}
export interface Job {
    id: number;
    advanced_warning_hours?: number;
    allow_driver_record_weight?: number;
    allow_job_tare_weights?: boolean;
    amount: number;
    auto_award: boolean;
    auto_bol: boolean;
    awardedBids: Bid[];
    awarded_bids: Bid[];
    bid_amount_status: BidAmountStatus;
    bidders: [];
    bidding_ends: string;
    bidding_geo_restriction_distance: number;
    bidding_geo_restriction_distance_units: LengthUnit;
    bol_start: number;
    broker_fee_percentage: string;
    can_driver_authorize_inspector: boolean;
    closing_time: string;
    company_id: number
    company: Company;
    companyBids: [];
    company_customer: Company;
    company_customer_company_id: number;
    company_customer_invoices_to_be_paid_through_truckpay: boolean;
    company_customer_per_volume_job_units: VolumeUnit;
    company_customer_per_weight_job_units: WeightUnit;
    company_customer_price: number;
    company_customer_transaction_type_id: number;
    company_supplier: Company;
    company_supplier_company_id: number;
    company_supplier_invoices_to_be_paid_through_truckpay: boolean;
    company_supplier_per_volume_job_units: VolumeUnit;
    company_supplier_per_weight_job_units: WeightUnit;
    company_supplier_price: number;
    company_supplier_transaction_type_id: number;
    constant_weighmaster_license_number: string;
    constant_weighmaster_name: string
    convertedDistances: { user_to_pickup: number, pickup_to_destination: number };
    distances: {user_to_pickup: number, pickup_to_destination: number };
    currency: string;
    currency_object: Currency;
    customer_name: string;
    customer_phone_number: string;
    demolition_destination_company: Company;
    demolition_destination_company_id: number;
    demolition_destination_company_pays: boolean;
    demolition_destination_invoices_to_be_paid_through_truckpay: boolean;
    demolition_destination_per_volume_job_units: VolumeUnit;
    demolition_destination_per_weight_job_units: WeightUnit;
    demolition_destination_price: number;
    demolition_destination_transaction_type_id: number;
    displayBiddingEndDate: string;
    displayEndDate: string;
    displayMaterials: string;
    displayStartDate: string;
    display_job_number: number;
    driver_can_check_in_with_active_deliveries: boolean;
    drop_picture_url: string;
    dropoff_address_needs_correction: number;
    end_date: string;
    equipment: EquipmentType[];
    estimated_amount: number;
    estimated_amount_units: WeightUnit;
    estimated_loads: number;
    estimated_pounds: number;
    estimated_volume: number;
    estimated_volume_units: VolumeUnit;
    estimated_remaining_loads: number | null;
    estimated_remaining_volume: number | null;
    estimated_remaining_weight: number;
    extended_attribute_values: ExtendedAttributeValues[];
    extended_job_type: string;
    external_job_id: number;
    formattedBiddingEnds: string;
    formattedCloseTime: string;
    formattedEndDate: string;
    formattedEquipmentList: string;
    formattedOpenTime: string;
    formattedStartDate: string;
    invoice_due_frequency: Frequency.DAILY | Frequency.WEEKLY | Frequency.BI_WEEKLY | Frequency.MONTHLY | Frequency.QUARTERLY;
    invoices_to_be_paid_through_truckpay: boolean;
    is_active: boolean;
    is_company_dropoff_inspection_required: boolean;
    is_company_pickup_inspection_required: boolean;
    is_completed: boolean;
    is_demolition_job: boolean;
    is_external_import: number;
    is_four_way: boolean;
    is_full: boolean;
    is_last_checkin_route_done: number;
    is_removal_job: boolean;
    is_scan_picture_required: boolean;
    is_third_party_dropoff_inspection_required: boolean;
    is_third_party_pickup_inspection_required: boolean;
    is_unknown_pickup_job: boolean;
    is_waste_manifest_job: boolean;
    is_weighmaster_required: boolean;
    job_location_timezone: string;
    job_number: number
    job_tare_weight_change_tolerance: number
    job_tare_weight_expiration_days: number;
    last_checkin_route: null;
    last_external_update: null;
    load_picture_url: string;
    load_weight_method: string;
    name: string;
    noncompete_distance: number;
    noncompete_distance_meters: string;
    noncompete_distance_units: LengthUnit;
    noncompete_period: number;
    notes: string;
    open_bids: number;
    opening_time: string;
    owner_id: number
    parent_job_id: number;
    pay_rain_out: boolean;
    payment_due_frequency: Frequency.DAILY | Frequency.WEEKLY | Frequency.BI_WEEKLY | Frequency.MONTHLY | Frequency.QUARTERLY;
    per_volume_job_units: VolumeUnits;
    per_weight_job_units: WeightUnits;
    po_number: number;
    privacy_status: PrivacyStatus;
    quantity: number;
    rain_out_units: number;
    record_weight: number;
    reduced_amount: number
    remaining_spots: number;
    requires_sign_off: boolean;
    routes: Route[] | { [routeID: string]: Route };
    scan_fee_payer: "driver" | "company";
    select_material_at_checkin: number;
    send_realtime_etickets: boolean;
    sign_off_team: SignOffMember[]
    site: Site;
    site_id: number;
    start_date: string;
    ticket_number_starts_at: number;
    track_driver_return_delivery_trip: boolean;
    transaction_type: TransactionTypeObject;
    transaction_type_id: number;
    truck_arrives_empty: boolean;
    truck_weight_type: "T" | "G";
    unawardedBids: Bid[]
    updated_at: string;
    usd_conversion_factor: number;
    uses_external_weight: boolean;
    validate_documents: boolean;
    terms_and_conditions: string | null;
    total_weight_delivered_in_pounds: number;
    total_volume_delivered_in_cubic_meters: number;
    total_loads_delivered: number;
}

export interface JobMap {
    [key: number]: Job
}

export enum JobQueryTypes {
    UNKNOWN = "unknown",
    MATERIAL = "material",
    CUSTOM_MATERIAL = "customMaterial",
    MIX_DESIGN = "mixDesign",
    MATERIAL_CATEGORY = "materialCategory",
    MATERIAL_CATEGORIZATION = "materialCategorization",
    DUAL = "dual",
}
export interface BaseJobQuery {
    type?: string
}
interface MaterialQuery extends BaseJobQuery {
    type?: JobQueryTypes.MATERIAL;
    material_ids: number[];
}
interface CustomMaterialQuery extends BaseJobQuery {
    type?: JobQueryTypes.CUSTOM_MATERIAL;
    custom_material_ids: number[];
}
interface MixDesignQuery extends BaseJobQuery {
    type?: JobQueryTypes.MIX_DESIGN;
    mix_design_ids: number[];
}
interface MaterialCategoryQuery extends BaseJobQuery {
    type?: JobQueryTypes.MATERIAL_CATEGORY;
    material_category_key: string
}
export interface MaterialCategorizationQuery extends BaseJobQuery {
    type?: JobQueryTypes.MATERIAL_CATEGORIZATION;
    material_category_key: string;
    value: any;
    value_operator:  ">" | "<" | "=" | ">=" | "<=" | "!=";
    units?: string | null;
    canonical_units?: string | null;
    units_type?: string | null;
}
export const instanceOf = <T>(discriminator: string, object: any): object is T => {
    return discriminator in object;
}
export interface EmptyQuery extends BaseJobQuery { 
    type?: JobQueryTypes.UNKNOWN;
}
interface DualQuery {
    type?: JobQueryTypes.DUAL;
    query_a: JobQuery;
    query_operator: "AND" | "OR";
    query_b: JobQuery;
}
export type JobQuery = EmptyQuery | MaterialQuery | CustomMaterialQuery | MixDesignQuery | MaterialCategoryQuery | MaterialCategorizationQuery | DualQuery;
export type DistributiveOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;