import { Box, Button, Card, CardContent, CardHeader, FormControl, Grid, List, ListItem, TextField, Typography } from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomMaterials, getAvailableTagsForCustomMaterials } from "../../../../../redux/actions/materialActions";
import { popAlert } from "../../../../../redux/actions/sessionActions";
import { CustomMaterial } from "../../../../../redux/reducers/entities";
import { functions } from "../../../../../redux/reducers/helpersReducerr";
import { addCustomMaterial, AddCustomMaterialPayload, addTagToCustomMaterial } from "../../../../../redux/util/materialAPI";

interface Props {
    setModalOpen: (bool: boolean) => void;
    setLoading: (bool: boolean) => void;
    refreshAllMaterials?: () => void;
}
const AddCustomMaterialUpload = ({
    setModalOpen,
    setLoading,
    refreshAllMaterials
}: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const token = useSelector((state: any) => state.session.user.token)
    const companyID = useSelector((state: any) => state.session.company.id)
    const materialTags: string[] = useSelector((state: any) => state.entities.materialTags)

    const [openDropzoneDialog, setOpenDropzoneDialog] = useState(false)

    const [listName, setListName] = useState("")
    const [file, setFile] = useState<File>()

    useEffect(() => {
        dispatch(getAvailableTagsForCustomMaterials(token))
    }, [])

    const matNumberCol = 0;
    const matNameCol = 1;
    const matDescCol = 2;
    const matAddInfoCol = 3;
    const matTypeCol = 4;
    const matTagsCol = 5;
    const numCols = 6;

    const downloadTemplate = () => {
        const csvTemplate = `Material Number,Material Name,Description,Handling/Additional Info,Type,Tags
#A1,CSV Mat1,mat1 description,Handle with care!,,
#B2,CSV Sand,sand description,Handle with care!,Inspectable,
#C3,CSV Dirt,dirt description,Handle with care!,Hazardous Inspectable,
#B4,CSV Wood,wood description,Handle with care!,,`;
        const file = new File([csvTemplate], "materials.csv", { type: "text/csv" });
        const objectURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.download = "MaterialTemplate.csv";
        link.href = objectURL;
        link.click();
    }
    const reduceString = (str: string) => str.toLowerCase().replace(/\s/g, "")
    const parseTags = (allTags: string): string[] => {
        // grab each valid substring
        const tags = allTags.split("&").filter(Boolean)
        const reducedAvailableTags = materialTags.map(reduceString)
        console.log("parsing tags")
        console.log(tags)
        console.log(reducedAvailableTags)

        // validate each substring as a valid tag
        for (let i = 0; i < tags.length; i++) {
            const tag = tags[i];
            const reducedTag = reduceString(tag)
            console.log(reducedTag)
            if (!reducedAvailableTags.includes(reducedTag)) {
                throw tag
            }
        }

        // clean validated tags and return
        return tags.map(tag => tag.trim())
    }
    const readCSV = (event: React.SyntheticEvent) => {
        event.preventDefault()
        setLoading(true)

        if (!file) {
            dispatch(popAlert('error', t('error'), `Please upload a CSV file`))
            setLoading(false)
            setOpenDropzoneDialog(true)
            return;
        }
        const reader = new FileReader();

        reader.onloadend = function (event: ProgressEvent<FileReader>) {
            console.log('reader loaded and starting')
            const data = event?.target?.result?.toString();
            if (!data) {
                dispatch(popAlert('error', t('error'), `Unable to read file`))
                return;
            };
            const rows = data.replace(/\r/g, "\n").split('\n');
            const materials: AddCustomMaterialPayload[] = [];
            const tags: string[][] = [];

            console.log('before iteration', rows)

            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                if (row.includes("Material Number")) {
                    continue;
                }
                const entry = row.split(',');
                if (entry.length === numCols) {
                    console.log('adding material')
                    const material: AddCustomMaterialPayload = {
                        list_name: listName,
                        material_number: entry[matNumberCol],
                        name: entry[matNameCol],
                        description: entry[matDescCol],
                        handling_and_additional_info: entry[matAddInfoCol],
                        is_hazardous: entry[matTypeCol].includes("Hazardous"),
                        is_inspectable: entry[matTypeCol].includes("Inspectable"),
                    };
                    try {
                        materials.push(material);

                        const parsedTags = parseTags(entry[matTagsCol])
                        tags.push(parsedTags)
                    } catch (unknownTag) {
                        console.log('bad tags')
                        setLoading(false)
                        dispatch(popAlert('error', "Error", `Unknown tag "${unknownTag}" in line ${i} the uploaded file. Please revise the file or contact support for help.`));
                        return;
                    }
                    
                } else if (row === "") {
                    console.log('empty row')
                } else {
                    console.log("issue", row, entry)
                    console.log('bad formatting')
                    setLoading(false)
                    dispatch(popAlert('error', "Error", `There seems to be an issue with the format of line ${i} the uploaded file. Please revise the file or contact support for help.`));
                    break;
                }
            }

            console.log('formatted correctly')
            addCustomMaterialListItems(materials, tags);
        }
        reader.readAsBinaryString(file);
    }

    const addCustomMaterialListItems = (materials: AddCustomMaterialPayload[], tags: string[][]) => {
        console.log("SUBMITTED", materials, tags)
        console.log('starting upload')
        addCustomMaterial(token, companyID, materials).then(res => {
            console.log('upload material res')
            if (res.success) {
                refreshAllMaterials?.()
                addTags(res.data, tags)
            } else {
                setLoading(false)
                dispatch(popAlert('error', t('error'), functions.parseError(res)))
            }
        })
    }

    const addTags = async (materials: CustomMaterial[], tags: string[][]) => {
        const promises = []
        for (let i = 0; i < materials.length; i++) {
            const material = materials[i];
            const tagsForMaterial = tags[i]
            console.log("adding tag", material, tagsForMaterial)
            for (let j = 0; j < tagsForMaterial.length; j++) {
                const tag = tagsForMaterial[j];
                promises.push(addTagToCustomMaterial(token, companyID, material.id, tag))
            }
        }
        await Promise.all(promises).then(res => {
            console.log("WITHIN ALL")
        })
        dispatch(popAlert('success', t('success'), t('materialTranslations.success.createCustom')))
        setModalOpen(false)
    }

    return <form autoComplete="off" onSubmit={(event) => readCSV(event)}>
        <Grid container spacing={10}>
            <Grid item xs={12}>
                <Box style={{ backgroundColor: "#d9edf7", borderRadius: '8px' }} p={5}>
                    <Typography style={{ fontWeight: 'bold', color: "#31708f" }}>
                        How to upload a new list of custom materials from a CSV file?
                    </Typography>
                    <Typography style={{ color: "#31708f" }}>
                        It's simple! Just download the CSV template and replace entries with your own materials. You can indicate each material's number, name, and a description for the material. When you're ready, come back here to upload the CSV and we'll do the rest of the work.
                    </Typography>
                </Box>
            </Grid>
        </Grid>
        <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
                <Button color="primary" variant="outlined" fullWidth onClick={() => downloadTemplate()}>
                    Download CSV Template
                </Button>
            </Grid>
            <Grid item xs={12} md={6}>
                <Button color="primary" variant={Boolean(file) ? "outlined" : "contained"} fullWidth onClick={() => setOpenDropzoneDialog(true)}>
                    {Boolean(file) ? "Change File" : "Upload"}
                </Button>
                <DropzoneDialog
                    open={openDropzoneDialog}
                    onSave={files => {
                        setFile(files[0])
                        setOpenDropzoneDialog(false)
                    }}
                    showPreviews={true}
                    maxFileSize={2000000}
                    onClose={() => setOpenDropzoneDialog(false)}
                    // showFileNamesInPreview={true}
                    // showFileNames={true}
                    filesLimit={1}
                    acceptedFiles={['text/csv']}
                    dropzoneText={"Upload CSV"}
                />
            </Grid>
        </Grid>
        <Grid container spacing={10}>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField
                        id="listName"
                        label={t('materialTranslations.listName')}
                        onChange={event => setListName(event.target.value)}
                        variant="outlined"
                        fullWidth
                        required
                    />
                </FormControl>
            </Grid>
        </Grid>
        <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
                <Card
                    variant="outlined"
                    raised
                >
                    <CardHeader 
                        title={`Options for "Type"`}
                    />
                    <CardContent>
                        <Box p={2} border="1px solid gray">
                            <Typography>Note:</Typography>
                            <Typography>For materials that need multiple types, separate the types with "&"</Typography>
                        </Box>
                        <List dense>
                            <ListItem>
                                <Typography>• Hazardous</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography>• Inspectable</Typography>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card
                    variant="outlined"
                    raised
                >
                    <CardHeader 
                        title={`Options for "Tags"`}
                    />
                    <CardContent>
                        <Box p={2} border="1px solid gray">
                            <Typography>Note:</Typography>
                            <Typography>For materials that need multiple tags, separate the tags with "&"</Typography>
                        </Box>
                        <List dense>
                            {materialTags.map(tag => (
                                <ListItem key={tag}>
                                    <Typography>• {tag}</Typography>
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <Grid container spacing={10}>
            <Grid item xs={12}>
                <Button color="primary" variant="contained" type="submit" fullWidth>{t('actions.save')}</Button>
            </Grid>
        </Grid>
    </form>  
}

export default AddCustomMaterialUpload