import { TPAPIBase } from '../../env';
import { functions } from '../reducers/helpersReducer';
import i18n from '../../i18n';

export const getCompanyVehicles = (token, companyID, typeIDs = []) => {
    const formattedParams = typeIDs.map(id => `type[]=${id}`).join("&")
    return fetch(`${TPAPIBase}/company/${companyID}/vehicles?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        }
    }).then(response => response.json())
}
export const getAvailableVehicles = (token, companyID, typeIDs = []) => {
    const formattedParams = typeIDs.map(id => `type[]=${id}`).join("&")
    return fetch(`${TPAPIBase}/company/${companyID}/availablevehicles?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        }
    }).then(response => response.json())
}
export const createVehicle = (token, companyID, formData) => (
    fetch(`${TPAPIBase}/company/${companyID}/vehicles`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: formData
    }).then(response => response.json())
)
export const editVehicle = (token, companyID, vehicleID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/vehicle/${vehicleID}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const createLegalDoc = (token, companyID, formData) => (
    fetch(`${TPAPIBase}/company/${companyID}/legal/create`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: formData
    }).then(response => response.json())
)
export const editLegalDoc = (token, companyID, docID, formData) => (
    fetch(`${TPAPIBase}/company/${companyID}/legal/update/${docID}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: formData
    }).then(response => response.json())
)
export const getMakes = (token, query) => (
    fetch(`${TPAPIBase}/vehicle_makes?q=${query}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
    }).then(response => response.json())
)
export const getModels = (token, makeID, query) => (
    fetch(`${TPAPIBase}/vehicle_models?make_id=${makeID}&q=${query}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
    }).then(response => response.json())
)
export const getYears = (token, modelID) => (
    fetch(`${TPAPIBase}/vehicle_years?model_id=${modelID}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
    }).then(response => response.json())
)
