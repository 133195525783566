import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SigCanvas from 'react-signature-pad-wrapper';

interface Props {
    setModalOpen: (bool: boolean) => void;
    updateSignatureFile: (file: File) => void;
}

const SignatureDialog = ({
    setModalOpen,
    updateSignatureFile,
}: Props) => {
    const { t } = useTranslation()
    const sigCanvasRef = useRef()
    const [eSignature, setESignature] = useState("")
    const [openSignatureUpload, setOpenSignatureUpload] = useState(false)
    const [signatureFile, setSignatureFile] = useState<File>()
    const clearCanvas = () => {
        const sigCanvas: any = sigCanvasRef.current
        if (sigCanvas) {
            sigCanvas!.clear();
            setESignature("")
        }
    }
    const handleSignatureTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const sigCanvas: any = sigCanvasRef.current
        if ((sigCanvas && !sigCanvas.isEmpty()) || eSignature) {
            sigCanvas.clear()
        }
        setESignature(event.target.value)
        const { canvas } = sigCanvas
        const ctx = canvas.getContext('2d')
        ctx.textAlign = "center";
        ctx.font = '30px'
        ctx.fillText(event.target.value, canvas.width / 4, canvas.height / 4)
    }
    const handleSignatureFileChange = (files: File[]) => {
        setSignatureFile(files[0])
        setOpenSignatureUpload(false)
    }
    const updateFile = async () => {
        const sigCanvas: any = sigCanvasRef.current
        const imageURL = sigCanvas!.toDataURL();
        const file = await fetch(imageURL).then(res => res.blob()).then(blob => new File([blob], "signature.png", { type: "image/png" }))
        updateSignatureFile(file)
        setModalOpen(false)
    }
    return <>
        <Dialog
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">{t(`authTranslations.signature`)}</DialogTitle>
            <DialogContent>
                <SigCanvas ref={sigCanvasRef}/>
                <Button 
                    fullWidth
                    onClick={() => clearCanvas()}
                >
                    Clear Signature
                </Button>
                <FormControl fullWidth>
                    <TextField
                        id="signature"
                        label={"Digital Signature (Optional)"}
                        value={eSignature}
                        onChange={handleSignatureTextChange}
                        type="text"
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setModalOpen(false)} type="button" color="primary">
                    {t(`actions.cancel`)}
                </Button>
                <Button 
                    color="primary" 
                    type="button" 
                    onClick={() => setOpenSignatureUpload(true)}
                >
                    Upload Signature Image
                </Button>
                <DropzoneDialog
                    open={openSignatureUpload}
                    onSave={handleSignatureFileChange}
                    showPreviews={true}
                    maxFileSize={2000000}
                    onClose={() => setOpenSignatureUpload(false)}
                    // showFileNamesInPreview={true}
                    // showFileNames={true}
                    filesLimit={1}
                    acceptedFiles={['image/*']}
                    dropzoneText={"Upload signature image"}
                />
                <Button 
                    onClick={updateFile} 
                    variant="contained" 
                    color="primary"
                >
                    {t(`actions.save`)}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default SignatureDialog