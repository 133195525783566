import { TPAPIBase } from "../../env";
import i18n from '../../i18n';
import { TPResponse } from "./util";

export interface CreateVehicleData {
    name: string;
    type_id: number;
    vin?: string;
    make?: string;
    model?: string;
    year?: number;
    license_plate_country_id: number;
    license_plate: string;
    license_plate_2_country_id?: number;
    license_plate_2?: string;
    trailer_id_1: string;
    trailer_id_2: string;

    fuel_usage: number;
    tank_size: number;
    tank_units: string;
    number_of_axles: number;

    truck_tare_weight: number;
    truck_tare_weight_units: string;
    weight_capacity: number;
    weight_capacity_units: string;
    volume_capacity: number;
    volume_capacity_units: string;
}
interface CreateVehicleProps {
    token: string,
    companyId: number,
    data: CreateVehicleData[]
}
export const createVehicle = ({ token, companyId, data }: CreateVehicleProps): Promise<TPResponse<{ new_vehicle_ids: number[] }>> => (
    fetch(`${TPAPIBase}/company/${companyId}/vehicles`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: JSON.stringify({ data })
    }).then(response => response.json())
)


interface CreateLegalDocOptions {
    token: string,
    companyId: number,
    formData: FormData
}
/**
 * `Create Legal Doc` API
 * 
 * @todo allow api to accept multiple files + switch return type
 */
export const createLegalDoc = ({
    token,
    companyId,
    formData
}: CreateLegalDocOptions): Promise<TPResponse<boolean>> => (
    fetch(`${TPAPIBase}/company/${companyId}/legal/create`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: formData
    }).then(response => response.json())
)