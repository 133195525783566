// import { MaterialMixDesignPayload } from "../../util/mixAPI";

import { VolumeUnits } from "./Unit";

export interface CustomMaterialTag {
    id: number;
    custom_material_id: number;
    name: string;
}
export interface Material {
    id: number;
    created_at: string;
    updated_at: string;

    is_custom_material: boolean;
    is_hazardous: boolean;
    is_inspectable: boolean;

    material_id: number;
    name: string;
    material_number: string;
    description: string;

    custom_material_id: number;
    custom_material_name: string;
    custom_material_number: string;
    custom_material_description: string;
}
export interface CustomMaterial extends Material {
    // TODO this needs to be redone and not extend Material
    // company_id: number;
    // created_at: string;
    // description: string;
    // handling_and_additional_info: string;
    // id: number;
    // is_hazardous: false
    // is_inspectable: true
    // list_name: string;
    // material_mix_design_id: number | null;
    // material_number: string;
    // name: string;
    // updated_at: string;

    id: number;
    created_at: string;
    updated_at: string;

    company_id: number;
    list_name: string;
    handling_and_additional_info: string;
    material_mix_design_id?: number | null;
    mix_design?: MixDesign;
}
export interface CustomMaterialMap {
    [listName: string]: CustomMaterial[]
}
export interface CustomMaterialMapByID {
    [customMaterialID: number]: CustomMaterial
}
export interface JobMaterial extends Material {
    estimated_volume: number;
    estimated_volume_units: string;
    estimated_weight: number;
    estimated_weight_units: string;
    handling_and_additional_info: string;
}
export interface DeliveryMaterial extends JobMaterial {
    job_id: number;
    route_id: number;
    units: string;
    material_mix_design_id?: number;
}
export interface FullJobMaterial {
    id: number;
    job_id: number;
    route_id: number;
    material_id: number | null;
    custom_material_id: number | null;
    estimated_weight: number | null;
    estimated_weight_units: number | null;
    estimated_volume: number | null;
    estimated_volume_units: VolumeUnits;
    is_custom_material: boolean;
    estimated_amount: number | null;
    units: string;
    material: Material | null;
    custom_material: CustomMaterial | null;
}
export interface BaseMixMaterial {
    material?: Material;
    material_id: number | null;
    customMaterial?: CustomMaterial;
    custom_material_id: number | null;
    weight: number;
    weight_units: string | null;
    volume: number;
    volume_units: string | null;
    primary_measurement_type?: "weight" | "volume"
}
export interface MixDesignConstituent extends BaseMixMaterial {
    custom_material_id: number | null;
    id?: number;
    is_weight_an_automatic_volume_conversion: boolean;
    material_description?: string;
    material_id: number | null
    material_mix_design_id?: number;
    material_name?: string;
    material_number?: string;
    // notes: string;
    target_volume: number | null;
    target_volume_in_cubic_meters?: string | null;
    target_weight: number | null;
    target_weight_in_pounds?: string;
    volume_units: string | null;
    weight_units: string;

    material?: Material;
    custom_material?: CustomMaterial;
    // primary_measurement_type: "weight" | "volume"
}
export interface MixDesign {
    company_id: number;
    constituents?: MixDesignConstituent[];
    created_at: string;
    created_by: number;
    deleted_at: string | null;
    id: number;
    mix_id: string;
    name: string;
    notes: string;
    original_material_mix_design_id: number;
    target_volume: number;
    volume_units: string;
    target_weight: number | null;
    weight_units: string;
    target_slump: number | null;
    slump_units: string;
    target_temperature: number | null;
    temperature_units: string | null;
    target_mixing_revolutions: number;
    target_air_content_percentage: string;
    updated_at: string;
    _custom_material?: CustomMaterial;
}

export interface MixBatchConstituent extends Omit<MixDesignConstituent, "primary_measurement_type">  {
    amendment_custom_material_id?: number;
    material_mix_design_constituent_id?: number;
    // weight: number | null;
    // weight_units: string;
    // volume: number | null;
    // volume_units: string | null;
    // is_weight_an_automatic_volume_conversion: boolean;
    amendment_custom_material?: CustomMaterial;
    design_constituent?: MixDesignConstituent;
}
export interface MixBatch {
    id: number;
    material_mix_design_id: number;
    mix_design?: MixDesign;
    site_id: number;
    batch_ticket_number: string;
    delivery_id?: number;
    volume: number;
    volume_units: string;
    slump?: number;
    slump_units?: string;
    temperature?: number;
    temperature_units?: string;
    mixing_revolutions?: number;
    air_content_percentage?: number;
    weight?: number | null;
    weight_units?: string;
    constituents?: MixBatchConstituent[];
}

export enum MaterialCategoryDataTypes {
    STRING = "string",
    INTEGER = "integer",
    DECIMAL = "decimal",
    BOOLEAN = "boolean",
    DATE = "date",
    DATETIME = "datetime",
}
export enum MaterialCategoryUnitTypes {
    WEIGHT = "weight",
    VOLUME = "volume",
    LENGTH = "length",
    TEMPERATURE = "temperature",
    TIME = "time",
}
export interface MaterialCategory {
    key: string;
    name: string;
    description: string;
    data_type: MaterialCategoryDataTypes,
    range_min: number | null;
    range_max: number | null;
    allowed_selections: string[] | null;
    allowed_units: string[] | null;
    canonical_units: string | null;
    units_type: MaterialCategoryUnitTypes | null;
}
export interface MaterialCategoryMap {
    [key: string]: MaterialCategory
}
export interface MaterialCategorizationValueMap {
    [categoryKey: string]: MaterialCategorizationValue
}
export interface MaterialCategorizationValue {
    [key: string]: any;
    id?: number;
    categorization?: MaterialCategorization;
    category_key: string;
    value: any;
    units?: string | null;
}
export interface MaterialCategorization {
    id: number;
    custom_material_id: number;
    category_key: string;
    value: string;
    units: string | null;
    canonicalized_value: string;
    category: MaterialCategory;
    dateValue?: string;
}