import React from "react";
import styled from "styled-components";
import { NavLink as RouterNavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'
import { 
    getAllCurrentJobs, 
    getAllPendingJobs,
    getAllPastJobs,
    getAllInProgressJobs,
    getExternalJobs
} from '../../../redux/actions/jobActions';
import Helmet from 'react-helmet';
import { getRelevantDeliveries } from '../../../redux/util/deliveryAPI'
import {
    Box,
    Breadcrumbs as MuiBreadcrumbs,
    Button as MuiButton,
    Checkbox,
    Chip as MuiChip,
    Card as MuiCard,
    CardContent,
    Divider as MuiDivider,
    Grid as MuiGrid,
    IconButton,
    Link,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Tooltip,
    Typography as MuiTypography,
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    TextField as MuiTextField,
    FormControl as MuiFormControl,
    Select as MuiSelect,
    MenuItem,
    InputLabel,
    Collapse,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Switch
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import {
    KeyboardDatePicker,
    KeyboardTimePicker
} from "@material-ui/pickers";
import SkeletonCards from '../../components/SkeletonCards';
import Delivery from './components/Delivery'
import { spacing } from "@material-ui/system";
import { withStyles } from '@material-ui/core/styles';
import DeliveryETA from "./subcomponents/DeliveryETA";
import ViewDeliveryModal from "./modals/ModalViewDelivery";
import { DeliveriesTable } from "./DeliveriesList2";
import { getDetailedDeliveryStatus, toMysqlFormat } from "../../../redux/reducers/helpersReducerr";

const Paper = styled(MuiPaper)(spacing);
const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Grid = styled(MuiGrid)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ScrollArea = styled.div`
  height: 300px;
  overflow-y: scroll;
`;
const ScrollableGrid = styled(MuiGrid)({
    // height: '200px',
    maxHeight: '300px',
    overflow: 'hidden',
    overflowY: 'scroll'
});
const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);
const AccordionSummary = withStyles({
    root: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {

    },
})(MuiAccordionSummary);
const AccordionDetails = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        padding: "20px"
    },
})(MuiAccordionDetails);

const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;

const Select = styled(MuiSelect)(spacing);

/*
    PROPS
    deliveries: []
    t: i18n Translation function
    helpers: state.helpers

    refreshDeliveries: Function
    forInspector: Boolean
    inspectorAssignment: InspectorAssignment object

*/
export class DeliveriesAccordion extends React.Component {

    state = {
        expanded: null
    };

    handleChange = deliveryID => (event) => {
        this.setState({
            expanded: this.state.expanded === deliveryID ? false : deliveryID
        });
    };
    

    render() {
        const { expanded } = this.state;
        if (!this.props.deliveries) return null;

        return <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell align="center"><Typography color="textSecondary" variant="h6" align="center">{this.props.t('siteTranslations.siteName')}</Typography></TableCell>
                            <TableCell align="center"><Typography color="textSecondary" variant="h6" align="center">{this.props.t('jobTranslations.jobName')}</Typography></TableCell>
                            <TableCell align="center"><Typography color="textSecondary" variant="h6" align="center">{this.props.t('deliveryTranslations.ticketNumber')}</Typography></TableCell>
                            <TableCell align="center"><Typography color="textSecondary" variant="h6" align="center">{this.props.t('vehicleTranslations.truckID')}</Typography></TableCell>
                            <TableCell align="center"><Typography color="textSecondary" variant="h6" align="center">{this.props.t('materialTranslations.material')}</Typography></TableCell>
                            <TableCell align="center"><Typography color="textSecondary" variant="h6" align="center">{this.props.t('timeTranslations.date')}</Typography></TableCell>
                            <TableCell align="center"><Typography color="textSecondary" variant="h6" align="center">{this.props.t('timeTranslations.totalDuration')}</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {!this.props.deliveries.length ? (
                        <TableRow>
                            <TableCell colSpan={8}>
                                <Typography align="center">{this.props.t('deliveryTranslations.errors.noDeliveriesFound')}</Typography>
                            </TableCell>
                        </TableRow>
                    ) : <>
                        {this.props.deliveries.map(delivery => <React.Fragment key={delivery.id}>
                            <TableRow hover={true}>
                                <TableCell align="center">
                                    <IconButton size="small" onClick={this.handleChange(delivery.id)}>
                                        {expanded === delivery.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center"><Typography align="center">{delivery.site.site_name}</Typography></TableCell>
                                <TableCell align="center"><Typography align="center">{delivery.job.name}</Typography></TableCell>
                                <TableCell align="center"><Typography align="center">{delivery.external_ticket_id || delivery.ticket_number}</Typography></TableCell>
                                <TableCell align="center"><Typography align="center">{delivery.external_vehicle_identifier || delivery.vehicle.name}</Typography></TableCell>
                                <TableCell align="center">
                                    <Tooltip title={delivery.displayMaterials || delivery.materials.map(mat => mat.name ? mat.name : mat.custom_material_name).join(", ")} placement="top" mb={3} mr={3}>
                                        <Typography align="center">{
                                            delivery.materials.length && (delivery.materials[0].name || delivery.materials[0].custom_material_name)}{delivery.materials[1] ? ", ..." : null}
                                        </Typography>
                                    </Tooltip>
                                    {Boolean(delivery.job.transaction_type_id == 2 && delivery.net_weight) && <Typography align="center">{this.props.helpers.formatNumber(delivery.net_weight, { forCapacities: true })} {this.props.t(`unitTranslations.${delivery.weight_unit}`)}</Typography>}
                                    {Boolean(delivery.job.transaction_type_id == 4 && delivery.cubic_meters) && <Typography align="center">{this.props.helpers.formatNumber(delivery.cubic_meters, { forCapacities: true })} {this.props.t('unitTranslations.cubic-meter')}</Typography>}
                                </TableCell>
                                <TableCell align="center"><Typography align="center">{this.props.helpers.getFormattedLongDate(delivery.checkinDate)}</Typography></TableCell>
                                {delivery.detailedStatus == 'completed' && (<TableCell align="center">
                                    <Typography align="center">{delivery.total_duration}</Typography>
                                </TableCell>)}
                                {delivery.detailedStatus != 'completed' && (<TableCell align="center">
                                    <Typography align="center">{this.props.t(`deliveryTranslations.detailedStatuses.${delivery.detailedStatus}`)}</Typography>
                                    <DeliveryETA delivery={delivery} />
                                </TableCell>)}
                            </TableRow>
                            {
                                true ? <>
                                    {expanded === delivery.id && <ViewDeliveryModal 
                                        setModalOpen={(bool) => this.setState({ expanded: bool })}
                                        deliveryID={delivery.id}
                                        asInspector={this.props.forInspector}
                                        inspectorAssignment={this.props.inspectorAssignment}
                                        inspectionType={this.props.inspectionType}
                                        refreshData={this.props.refreshDeliveries}
                                    />}
                                </> : <>
                                    <TableRow>
                                        <TableCell colSpan={8} padding={expanded === delivery.id ? "default" : "none"}>
                                            <Collapse in={expanded === delivery.id}>
                                                <Delivery 
                                                    delivery={delivery} 
                                                    expanded={expanded === delivery.id}
                                                    forInspector={this.props.forInspector}
                                                    inspectorAssignment={this.props.inspectorAssignment}
                                                    refreshDeliveries={this.props.refreshDeliveries}
                                                />
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </>
                            }
                        </React.Fragment>)}
                    </>}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
        
    }
}

function getFormattedDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
}
function getFormattedMaxDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return year + '/' + month + '/' + day;
}

class DeliveriesList extends React.Component {

    state = {
        jobs: null, // turns into [job, job, ...]
        jobType: "",
        dateRange: "daily",
        filterJobsByDate: false,
        jobDate: new Date(),
        customStartDate: new Date(),
        customEndDate: new Date(),
        selectedJobIDs: [0],
    }
    componentDidMount = () => {
        this.mounted = true;
        this.getJobs()
        this.today = getFormattedDate(new Date())
        this.maxToday = getFormattedMaxDate(new Date())
    }
    componentWillUnmount = () => {
        this.mounted = false;
    }

    getRelevantState = () => {
        console.log("relevant state", this.props.match.path)
        switch (this.props.match.path) {
            case "/deliveries/current": {
                return this.props.currentJobs;
            }
            case "/deliveries/pending": {
                return this.props.pendingJobs;
            }
            case "/deliveries/past": {
                return this.props.pastJobs;
            }
            case "/deliveries/in-progress": {
                return this.props.inProgressJobs;
            }
            case "/deliveries/external": {
                return this.props.externalJobs;
            }
            default: {
                return []
            }
        }
    }
    setStateIfMounted = (state, callback) => {
        if (this.mounted) {
            this.setState(state, callback)
        }
    }

    getJobs = () => {
        const filter = {}
        if (this.state.filterJobsByDate) {
            filter.start_date_range_end = toMysqlFormat(this.state.jobDate, { dateOnly: true })
            filter.end_date_range_start = toMysqlFormat(this.state.jobDate, { dateOnly: true })
        }
        switch(this.props.match.path) {
            case "/deliveries/current": {
                this.setStateIfMounted({ jobType: "currentJobs", jobs: (this.getRelevantState().length ? this.getRelevantState() : null) }, () => {
                    this.props.getAllCurrentJobs(this.props.user.token, this.props.company, { forDispatch: true }, filter).then(jobsRes => {
                        console.log("got new jobs", this.getRelevantState());
                        this.setStateIfMounted({ jobs: this.getRelevantState(), dateRange: 'daily' }, () => this.getDeliveries())
                    })
                })
                break;
            }
            case "/deliveries/pending": {
                this.setStateIfMounted({ jobType: "pendingJobs", jobs: this.getRelevantState().length ? this.getRelevantState() : null }, () => {
                    this.props.getAllPendingJobs(this.props.user.token, this.props.company, {}, filter).then(jobsRes => {
                        this.setStateIfMounted({ jobs: this.getRelevantState(), dateRange: 'daily' }, () => this.getDeliveries())
                    })
                })
                break;
            }
            case "/deliveries/past": {
                this.setStateIfMounted({ jobType: "pastJobs", jobs: this.getRelevantState().length ? this.getRelevantState() : null }, () => {
                    this.props.getAllPastJobs(this.props.user.token, this.props.company, {}, filter).then(jobsRes => {
                        this.setStateIfMounted({ jobs: this.getRelevantState(), dateRange: 'daily' }, () => this.getDeliveries())
                    })
                })
                break;
            }
            case "/deliveries/in-progress": {
                this.setStateIfMounted({ jobType: "inProgressJobs", jobs: this.getRelevantState().length ? this.getRelevantState() : null }, () => {
                    this.props.getAllInProgressJobs(this.props.user.token, this.props.company, {}, filter).then(jobsRes => {
                        this.setStateIfMounted({ jobs: this.getRelevantState(), dateRange: 'daily' }, () => this.getDeliveries())
                    })
                })
                break;
            }
            case "/deliveries/external": {
                this.setStateIfMounted({ jobType: "externalJobs", jobs: this.getRelevantState().length ? this.getRelevantState() : null }, () => {
                    this.props.getExternalJobs(this.props.user.token, this.props.company, {}, filter).then(jobsRes => {
                        this.setStateIfMounted({ jobs: this.getRelevantState(), dateRange: 'daily' }, () => this.getDeliveries())
                    })
                })
                break;
            }
            case "/inspector-logbook" : {

            }
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        this.mounted = true;
        if (prevProps.match.path !== this.props.match.path) {
            this.getJobs();
        }
    }
    handleSelectChange = field => {
        return (event, node) => {
            this.setStateIfMounted({[field]: node.props.value})
        }
    }
    handleDateChange = field => {
        return date => {
            this.setStateIfMounted({ [field]: date })
        }
    }
    handleClick = (event, jobID) => {
        const alreadySelected = this.state.selectedJobIDs.includes(jobID);
        if (jobID == 0) {
            if (alreadySelected) {
                this.setStateIfMounted({ selectedJobIDs: [] })
            } else {
                this.setStateIfMounted({ selectedJobIDs: [0] })
            }
        } else {
            if (alreadySelected) {
                this.setStateIfMounted({ selectedJobIDs: this.state.selectedJobIDs.filter(id => id != jobID) })
            } else {
                this.setStateIfMounted({ selectedJobIDs: [...this.state.selectedJobIDs, jobID].filter(id => id != 0) })
            }
        }
    }
    // getDateFromDateString = (dateString) => {
    //     const parts = dateString.split("-");
    //     return new Date(parts[0], parts[1] - 1, parts[2]);
    // }
    getDeliveries = (event) => {

        // this.grabDeliveries();
        if (event) event.preventDefault();
        const params = [
            {
                key: 'timezone',
                value: 'America/New_York'
            }
        ]
        // TODO
        // if ($scope.deliveryData.timezone != $scope.defaultTimezone) {
        //     updateDefaultDeliveryTimezone()
        // }
        if (this.props.company.title == "Truck Driver") {
            params.push({
                key: 'driver_id',
                value: this.props.user.id
            })
        }
        params.push({
            key: 'period',
            value: this.state.dateRange || 'daily'
        })
        if (this.state.dateRange == 'custom') {
            params.push( {
                key: 'start',
                value: Math.floor(this.state.customStartDate / 1000)
            }, {
                key: 'end',
                value: Math.floor(this.state.customEndDate / 1000)
            })
        }
        if (this.state.selectedJobIDs.length && !this.state.selectedJobIDs.includes(0)) {
            this.state.selectedJobIDs.forEach(id => {
                params.push({
                    key: "job_ids[]",
                    value: id
                })
            })
        }

        getRelevantDeliveries(this.props.user.token, this.props.company, params).then(res => {
            if (res.data) {
                const allJobs = this.state.selectedJobIDs.includes(0);
                const deliveries = res.data.filter(delivery => {
                    if (allJobs) {
                        //see if the delivery belongs to any job of the current job type
                        const found = this.state.jobs.find(job => job.id == delivery.job_id);
                        return !!found;
                    } else {
                        return this.state.selectedJobIDs.includes(delivery.job_id);
                    }
                }).map(delivery => {
                    delivery.displayMaterials = delivery.materials.map(mat => mat.name ? mat.name : mat.custom_material_name).join(", ");
                    if (delivery.load_time_duration) {
                        const loadDate = this.props.helpers.convertSecondsToTime(delivery.load_time_duration)
                        delivery.loadDuration = `${loadDate.getHours()}h ${loadDate.getMinutes()}m ${loadDate.getSeconds()}s`;
                    }
                    if (delivery.unload_time_duration) {
                        const unloadDate = this.props.helpers.convertSecondsToTime(delivery.unload_time_duration)
                        delivery.unloadDuration = `${unloadDate.getHours()}h ${unloadDate.getMinutes()}m ${unloadDate.getSeconds()}s`;
                    }
                    if (delivery.checkin && delivery.checkout) {
                        const durationDate = this.props.helpers.convertSecondsToTime((new Date(delivery.checkout) - new Date(delivery.checkin)) / 1000)
                        delivery.total_duration = `${durationDate.getHours()}h ${durationDate.getMinutes()}m`;
                    }

                    delivery.detailedStatus = getDetailedDeliveryStatus(delivery);

                    if (delivery.original_gross_weight || delivery.original_net_weight) {
                        delivery.isReweighment = true;
                    }
                    delivery.checkinDate = delivery.checkin ? this.props.helpers.createDateFromUnix(delivery.checkin, { keepLocal: true }) : null
                    delivery.checkoutDate = delivery.checkout ? this.props.helpers.createDateFromUnix(delivery.checkout, { keepLocal: true }) : null
                    return delivery
                })
                
                console.log('deliveries', deliveries)
                this.setStateIfMounted({deliveries: deliveries})
            }
        })
    }

    isSelected = (id) => this.state.selectedJobIDs.includes(id)

    saveDeliveries = () => {
        window.localStorage.setItem('testDeliveryList', JSON.stringify(this.state.deliveries))
    }
    grabDeliveries = () => {
        this.setStateIfMounted({ deliveries: JSON.parse(window.localStorage.getItem('testDeliveryList')) })
    }

    render = () => {

        let chunkedJobs = [[{ id: 'noJobs', name: this.props.t('jobTranslations.noJobs')  }]]
        let hasReceivedJobs = false;
        if (this.state.jobs && this.state.jobs.length) {
            const allJobs = [{ id: 0, name: this.props.t('jobTranslations.allJobs') }, ...this.state.jobs];
            chunkedJobs = this.props.helpers.chunk(allJobs, 2);
            hasReceivedJobs = true;
        }
        return (
            <React.Fragment>
                <Helmet title={this.props.t(`jobTranslations.${this.state.jobType}`)} />
    
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h1" mb={5}>
                                    {this.props.t('deliveryTranslations.deliveries')}
                                </Typography>
                                <form noValidate={false} onSubmit={this.getDeliveries}>
                                    <Grid container spacing={10} mb={2}>
                                        <Grid item>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Filter Jobs By Date</FormLabel>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        label=""
                                                        control={
                                                            <Switch
                                                                name=""
                                                                checked={this.state.filterJobsByDate}
                                                                onChange={e => {
                                                                    this.setStateIfMounted({ filterJobsByDate: e.target.checked })
                                                                }}
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                        {this.state.filterJobsByDate && <>
                                            <Grid item>
                                                <FormControl fullWidth>
                                                    <KeyboardDatePicker
                                                        // label={t('timeTranslations.startDate')}
                                                        label={"Date"}
                                                        value={this.state.jobDate}
                                                        onChange={jobDate => this.setStateIfMounted({ jobDate })}
                                                        format="MM/dd/yyyy"
                                                        // min={this.today}
                                                        inputVariant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => this.getJobs()}
                                                >
                                                    Get Jobs
                                                </Button>
                                            </Grid>
                                        </>
                                        }
                                    </Grid>
                                    <Grid container spacing={5} mb={5}>
                                        <Grid item xs={3}>
                                            <Typography variant="h6" gutterBottom>
                                                {this.props.t('jobTranslations.actions.selectAJob')}
                                            </Typography>
                                        </Grid>
                                        <ScrollableGrid item xs={9}>

                                            <TableContainer>
                                                <Table
                                                    aria-labelledby="tableTitle"
                                                    size={'medium'}
                                                    aria-label="enhanced table"
                                                >
                                                    <TableBody>
                                                        {hasReceivedJobs && chunkedJobs.map((chunk, index) => (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                // aria-checked={isItemSelected}
                                                                tabIndex={-1}
                                                                key={index}
                                                                // selected={isItemSelected}
                                                            >
                                                                {chunk.map(job => {
                                                                    const isItemSelected = this.isSelected(job.id);
                                                                    const labelId = `enhanced-table-checkbox-${job.id}`;
                                                                    return (
                                                                        <React.Fragment key={job.id}>
                                                                            <TableCell padding="checkbox">
                                                                                <Checkbox
                                                                                    checked={isItemSelected}
                                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                                    onClick={(event) => this.handleClick(event, job.id)}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell align="left">{job.name}</TableCell>
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                            </TableRow>
                                                        ))}
                                                        {!hasReceivedJobs && (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                            >
                                                                <TableCell align="left">{this.props.t('jobTranslations.noJobs')}</TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </ScrollableGrid>
                                    </Grid>
                                    <Grid container spacing={5} mb={5}>
                                        <Grid item xs={3}>
                                            <Typography variant="h6" gutterBottom>
                                                {this.props.t('timeTranslations.actions.chooseADateRange')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="dateRange">{this.props.t('timeTranslations.range')}</InputLabel>
                                                        <Select
                                                            labelId="dateRange"
                                                            label={this.props.t('timeTranslations.range')}
                                                            value={this.state.dateRange}
                                                            onChange={this.handleSelectChange('dateRange')}
                                                            fullWidth
                                                            required
                                                            inputProps={{
                                                                name: "dateRange",
                                                                id: "dateRange"
                                                            }}
                                                            error={!this.state.dateRange}
                                                        >
                                                            <MenuItem value="daily">{this.props.t('timeTranslations.rangeOptions.daily')}</MenuItem>
                                                            <MenuItem value="weekly">{this.props.t('timeTranslations.rangeOptions.weekly')}</MenuItem>
                                                            <MenuItem value="monthly">{this.props.t('timeTranslations.rangeOptions.monthly')}</MenuItem>
                                                            <MenuItem value="quarterly">{this.props.t('timeTranslations.rangeOptions.quarterly')}</MenuItem>
                                                            <MenuItem value="yearly">{this.props.t('timeTranslations.rangeOptions.yearly')}</MenuItem>
                                                            <MenuItem value="custom">{this.props.t('timeTranslations.rangeOptions.custom')}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                {this.state.dateRange == 'custom' && <Grid item xs={12} md={4}>
                                                    <FormControl ml={2} fullWidth>
                                                        <KeyboardDatePicker
                                                            label={this.props.t('timeTranslations.startDate')}
                                                            value={this.state.customStartDate}
                                                            onChange={this.handleDateChange('customStartDate')}
                                                            format="MM/dd/yyyy"
                                                            min={this.today}
                                                            inputVariant="outlined"
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </Grid>}
                                                {this.state.dateRange == 'custom' && <Grid item xs={12} md={4}>
                                                    <FormControl ml={2} fullWidth>
                                                        <KeyboardDatePicker
                                                            label={this.props.t('timeTranslations.endDate')}
                                                            value={this.state.customEndDate}
                                                            onChange={this.handleDateChange('customEndDate')}
                                                            format="MM/dd/yyyy"
                                                            min={this.today}
                                                            inputVariant="outlined"
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </Grid>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={5} mb={5} alignItems="center">
                                        <Grid item xs={12}>
                                            <Button color="primary" variant="contained" ml="auto" type="submit">
                                                {this.props.t('deliveryTranslations.actions.getDeliveries')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                        <Card mt={2}>
                            <CardContent>
                                <DeliveriesTable 
                                    deliveries={this.state.deliveries}
                                />
                                {/* <DeliveriesAccordion 
                                    deliveries={this.state.deliveries} 
                                    company={this.props.company} 
                                    props={this.props} 
                                    jobType={this.state.jobType} 
                                    emptyList={this.state.emptyList} 
                                    helpers={this.props.helpers} 
                                    t={this.props.t}
                                /> */}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}
const msp = state => ({
    company: state.session.company,
    user: state.session.user,
    currentJobs: state.entities.jobs.current,
    pendingJobs: state.entities.jobs.pending,
    pastJobs: state.entities.jobs.past,
    inProgressJobs: state.entities.jobs.inProgress,
    externalJobs: state.entities.jobs.external,
    helpers: state.helpers
})
const mdp = dispatch => ({
    getAllCurrentJobs: (token, company, options, filterOptions) => dispatch(getAllCurrentJobs(token, company, options, filterOptions)),
    getAllPendingJobs: (token, company) => dispatch(getAllPendingJobs(token, company)),
    getAllPastJobs: (token, company) => dispatch(getAllPastJobs(token, company)),
    getAllInProgressJobs: (token, company) => dispatch(getAllInProgressJobs(token, company)),
    getExternalJobs: (token, company) => dispatch(getExternalJobs(token, company)),
})
export default connect(msp, mdp)(withTranslation()(DeliveriesList));
