import React from 'react';
import {
    Modal,
    Tab,
    Tabs,
    TabContent,
    TabScrollButton,
    TabList,
    Paper as MuiPaper,
    AppBar,
    Toolbar,
    Typography as MuiTypography,
    Grid as MuiGrid,
    FormGroup,
    FormControl as MuiFormControl,
    FormControlLabel as MuiFormControlLabel,
    FormLabel,
    FormHelperText,
    Input,
    InputLabel,
    InputAdornment,
    Select,
    TextField,
    MenuItem,
    Button,
    Switch,
    Radio,
    RadioGroup,
    Divider,
    Box,
    Slider,
    Checkbox,
    Avatar,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next'
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext'
import { connect } from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import { TPLoader } from '../../../components/Progress';
import { popAlert } from '../../../../redux/actions/sessionActions';
import { assignDrivers } from '../../../../redux/util/jobAPI';
import { assignDriverAndVehicleToJob } from '../../../../redux/util/dispatchAPI';
import { getCompanyDrivers } from '../../../../redux/util/companyAPI';
import { getAvailableVehicles, getCompanyVehicles } from '../../../../redux/util/vehicleAPI';
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { debounce } from 'lodash';
import NotificationSnackbar from '../../Util/Snackbar';
import { getAllDriversForDispatch, getDriversForDispatch } from '../../../../redux/util/dispatchAPI_ts';

const Paper = styled(MuiPaper)(spacing);
const Typography = styled(MuiTypography)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const FormControlLabel = styled(MuiFormControlLabel)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Bold = styled.span`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: black;
`;

const GoogleMapReactWrapper = styled.div`
  height: 300px;
  width: 100%;
`;

function SectionHeader({ title }) {
    return (<>
        <Typography variant="h2" mb={4}><Bold>{title}</Bold></Typography>
        <Divider />
    </>
    )
}

class AssignDriverModal extends React.Component {
    nullState = {
        searchRadius: 1000,
        companyDrivers: [],
        vehicles: [],
        loading: true,
        tabValue: '-1',
        selectedVehicle: "",
        selectedDriver: ""
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.nullState,
        }
        this.tabAvailability = { 0: true }

    }

    componentDidMount = () => {
        this.mounted = true;
  
        Promise.all([
            this.getAvailableVehicles(),
            this.getCompanyDrivers(),
        ]).then(resArray => {
            console.log("res", resArray);
            this.tabAvailability[0] = true;
            this.setStateIfMounted({
                tabValue: '0',
                loading: false
            })
        })
       
    }
    submitAssignment = (event) => {
        event.preventDefault();
        this.setStateIfMounted({
            tabValue: '-1',
            loading: true
        }, () => this.organizePostData())
    }
    organizePostData = () => {
        const postData = {
            job_id: this.props.job.id,
            allow_overlapping: true,
            vehicle_id: this.state.selectedVehicle.id,
            driver_id: this.state.selectedDriver.id,
            driver_company_id: this.props.company.id
        }
        assignDriverAndVehicleToJob(this.props.token, this.props.company.id, postData).then(res => {
            if (res.success) {
                this.props.popAlert('success', this.props.t('success'), this.props.t('jobTranslations.success.assignDriver'))
                this.props.refreshJob();
                this.props.setModalOpen(false)
            } else {
                this.setStateIfMounted({
                    tabValue: '0',
                    loading: false,
                }, () => this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res.error)))
            }
        })
    }
    getCompanyDrivers = async () => {
        const {
            popAlert,
            t,
            token,
            company,
            job
        } = this.props
        try {
            const response = await getAllDriversForDispatch({
                token,
                companyId: company.id,
                jobId: job.id,
                driver_company_id: company.id
            })

            if (!response.success) {
                popAlert("error", t('error'), "Unable to get drivers")
                return
            }

            this.setStateIfMounted({
                companyDrivers: response.data.map(employee => employee.user)
            })
        } catch (e) {
            popAlert("error", t('error'), e)
        }
    }
    getCompanyDriversDebounced = debounce(this.getCompanyDrivers, 500, { maxWait: 1000, leading: false, trailing: true })
    getAvailableVehicles = () => {
        const typeIDs = this.props.job.equipment.map(eq => eq.equipment_id);
        if (this.props.job.driver_can_check_in_with_active_deliveries) {
            return getCompanyVehicles(this.props.token, this.props.company.id, typeIDs).then(res => {
                if (res.data) {
                    this.setStateIfMounted({ vehicles: res.data })
                }
            })
        } else {
            return getAvailableVehicles(this.props.token, this.props.company.id, typeIDs).then(res => {
                if (res.data) {
                    this.setStateIfMounted({ vehicles: res.data })
                }
            })
        }
    }
    componentWillUnmount = () => {
        this.mounted = false;
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.searchRadius != this.state.searchRadius) {
            // debounce(this.getCompanyDrivers, 500)
            this.getCompanyDriversDebounced();
        }
    }
    setStateIfMounted = (state, callback) => {
        if (this.mounted) {
            this.setState(state, callback)
        }
    }
    handleChange = (field, type, singleNestedOptions) => {
        if (type) {
            if (type == 'boolean') {
                return event => this.setStateIfMounted({ [field]: event.target.checked })
            } else if (type == 'number') {
                return event => this.setStateIfMounted({ [field]: parseFloat(event.target.value) || "" })
            } else if (type == 'date') {
                return date => this.setStateIfMounted({ [field]: date })
            } else if (type == 'time') {
                return time => this.setStateIfMounted({ [field]: time })
            } else if (type == 'radio-boolean') {
                return event => this.setStateIfMounted({ [field]: event.target.value == 'true' ? true : false })
            }
        }
        return event => this.setStateIfMounted({ [field]: event.target.value });
    }

    getTabPanelDisplay = (tabValue) => {
        if (this.state.tabValue == tabValue) {
            return ""
        } else {
            return "none";
        }
    }
    getDisplayType = (isLoader) => {
        if (isLoader) {
            return this.state.loading ? "" : "none";
        } else {
            return this.state.loading ? "none" : "";
        }
    }
    render = () => {
        return (
            <Modal open={true} style={{ overflowY: 'scroll' }}>
                <Grid container spacing={10} direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} sm={8} md={7} lg={5}>
                        <Paper m={6}>
                            <TabContext value={this.state.tabValue}>
                                {/* <AppBar color="default" position="static"> */}
                                <Grid container spacing={10} style={{ width: '100%', margin: 0 }} direction="row" justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="h3">{this.props.t('jobTranslations.actions.assignDriver')}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <CloseIcon onClick={() => this.props.setModalOpen(false)} />
                                    </Grid>
                                </Grid>
                                {/* </AppBar> */}
                                <AppBar color="default" position="static" style={{display: 'none'}}>
                                    <Toolbar>
                                        <Tabs
                                            variant="scrollable"
                                            indicatorColor="primary"
                                            scrollButtons="auto"
                                            value={this.state.tabValue}
                                            onChange={(event, tabValue) => this.setStateIfMounted({ tabValue: tabValue })}
                                        >
                                            <Tab label="Loading" value="-1" id="-1" disabled style={{ display: 'none' }} />
                                            <Tab label="Assign Drivers" value="0" id="0" disabled={!this.tabAvailability[0] || this.state.loading} />
                                        </Tabs>
                                    </Toolbar>
                                </AppBar>
                                <TabPanel value={this.state.tabValue} index="-1" style={{ display: this.getTabPanelDisplay('-1') }}>
                                    <TPLoader />
                                </TabPanel>

                                {/* ASSIGN DRIVERS  */}
                                <TabPanel value={this.state.tabValue} index="0" style={{ display: this.getTabPanelDisplay('0') }}>
                                    <Grid container spacing={10}>
                                        <Grid item xs={12}>
                                            <form autoComplete="off" onSubmit={(event) => this.submitAssignment(event)}>
                                                <Grid container spacing={10}>
                                                    <Grid item xs={12} md={6}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel id="selectedDriver">{this.props.t('jobTranslations.actions.selectDriver')}</InputLabel>
                                                            <Select
                                                                labelId="selectedDriver"
                                                                label={this.props.t('jobTranslations.actions.selectDriver')}
                                                                value={this.state.selectedDriver}
                                                                onChange={this.handleChange('selectedDriver')}
                                                                fullWidth
                                                                inputProps={{
                                                                    name: "selectedDriver",
                                                                    id: "selectedDriver"
                                                                }}
                                                            >
                                                                {this.state.companyDrivers.map(driver => (
                                                                    <MenuItem key={driver.id} value={driver}>{driver.first_name} {driver.last_name}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel id="selectedVehicle">{this.props.t('jobTranslations.actions.selectVehicle')}</InputLabel>
                                                            <Select
                                                                labelId="selectedVehicle"
                                                                label={this.props.t('jobTranslations.actions.selectVehicle')}
                                                                value={this.state.selectedVehicle}
                                                                onChange={this.handleChange('selectedVehicle')}
                                                                fullWidth
                                                                inputProps={{
                                                                    name: "selectedVehicle",
                                                                    id: "selectedVehicle"
                                                                }}
                                                            >
                                                                {this.state.vehicles.map(vehicle => (
                                                                    <MenuItem key={vehicle.id} value={vehicle}>
                                                                        {vehicle.name} | {vehicle.equipment_type.name} | {vehicle.license_plate} | {vehicle.year} {vehicle.make} {vehicle.model}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button type="submit" variant="contained" color="primary" fullWidth>{this.props.t('jobTranslations.actions.assign')}</Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </TabContext>
                        </Paper>
                    </Grid>
                    <NotificationSnackbar />
                </Grid>
            </Modal>
        )
    }
}
const msp = state => ({
    helpers: state.helpers,
    token: state.session.user.token,
    user: state.session.user,
    company: state.session.company,
})
const mdp = dispatch => ({
    popAlert: (success, title, message) => dispatch(popAlert(success, title, message)),
})
export default connect(msp, mdp)(withTranslation()(AssignDriverModal))