import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { match, NavLink as RouterNavLink, RouterProps, useHistory, withRouter } from "react-router-dom";
import { connect, useSelector, useDispatch } from 'react-redux';
import { withTranslation, useTranslation } from 'react-i18next'
import { getSpecificJob } from '../../../redux/actions/jobActions';
import { popAlert, updateInspectorDetails } from "../../../redux/actions/sessionActions";
import {
    FilterList,
    Refresh,
    Edit,
    Clear,
    Close
} from '@material-ui/icons'
import Helmet from 'react-helmet';
import Autocomplete from '@material-ui/lab/Autocomplete'
import TPModal from "../Util/TPModal";
import {
    Box,
    Breadcrumbs as MuiBreadcrumbs,
    Button as MuiButton,
    Checkbox,
    Chip as MuiChip,
    Divider as MuiDivider,
    Grid as MuiGrid,
    IconButton,
    Link,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell as MuiTableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Tooltip,
    Typography as MuiTypography,
    FormControl,
    Switch,
    FormGroup,
    FormLabel,
    FormControlLabel,
    TextField,
    FormHelperText,
    Card,
    CardActions,
    CardHeader,
    CardActionArea,
    CardContent,
    Tab,
    Tabs,
    AppBar,

} from "@material-ui/core";
import SkeletonCards from '../../components/SkeletonCards';
import { TPLoader } from "../../components/Progress";
import { spacing } from "@material-ui/system";
import {
    KeyboardDatePicker,
    KeyboardTimePicker,
    KeyboardDateTimePicker
} from "@material-ui/pickers";
import { modifyRequestedBid, cancelBid } from "../../../redux/util/jobAPI";
import { getMyInspections, getInspectorTokens, getDeliveryFeed, GetDeliveryFeedOptions, GetUserInspectorsOptions } from "../../../redux/util/inspectorAPI";
import { getAllInspectorTokens, triggerInspectorFeedUpdate } from "../../../redux/actions/inspectorActions";
import { Collapse } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { DeliveriesAccordion } from "../Deliveries/DeliveriesList";
import { MainJobDetailsCell } from "../Jobs/components/JobListRow";
import { TabContext } from "@material-ui/lab";
import TabPanel from "../Util/TabPanel";
import { Company, DeliveryApi, JobInspectorAssignment, JobInspectorAssignmentMap } from "../../../redux/reducers/entities";
import { User } from "../../../redux/reducers/entities/User";
import { getJobsBeingWorkedOnByMyCompany, GetJobsBeingWorkedOnByMyCompanyOptions } from "../../../redux/util/jobAPIts";
import { DeliveriesTable } from "../Deliveries/DeliveriesList2";
import { useRootSelector } from "../../..";
import { toMysqlFormat } from "../../../redux/reducers/helpersReducerr";

const Paper = styled(MuiPaper)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TableCell = styled(MuiTableCell)(spacing);
const Button = styled(MuiButton)`
    ${spacing}

    ${(props: any) => props.accept && props.theme.buttons.accept}
    ${(props: any) => props.decline && props.theme.buttons.decline}
`;
interface InspectorJobRowProps {
    isOpen: boolean;
    onRowClick: (jobID: string) => void;
    inspectorAssignment: JobInspectorAssignment;
}
function InspectorJobRow({ isOpen, onRowClick, inspectorAssignment }: InspectorJobRowProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const helpers = useSelector((state: any) => state.helpers)
    const token = useSelector((state: any) => state.session.user.token)
    const company = useSelector((state: any) => state.session.company)


    const specificJob = useSelector((state: any) => state.entities.jobs[inspectorAssignment.job_id])
    useEffect(() => {
        if (!specificJob) {
            dispatch(getSpecificJob(token, company.id, inspectorAssignment.job_id))
        }
    }, [])


    if (!specificJob) {
        return <SkeletonCards.SkeletonJobListRow />
    }
    return (<>
        <TableRow selected={isOpen}>
            <MainJobDetailsCell job={specificJob} company={company} forExport={false} onClick={() => onRowClick(inspectorAssignment.job_id.toString())}/>

            <TableCell align="right" onClick={() => onRowClick(inspectorAssignment.job_id.toString())}>
                <IconButton aria-label='expand order row' size="small" style={{ backgroundColor: 'rgba(255,255,255, 0.15)', borderRadius: '8px' }}>
                    {isOpen ? <KeyboardArrowUp fontSize="large" /> : <KeyboardArrowDown fontSize="large" />}
                </IconButton>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell padding="none" style={{ borderBottom: isOpen ? undefined : 0 }} colSpan={12}>
                <Collapse in={isOpen} timeout="auto">
                    <Grid container spacing={10}>
                        <Grid item xs={12}>
                            <InspectorJobDeliveryFeed 
                                inspectorAssignment={inspectorAssignment}
                                isOpen={isOpen}
                            />
                        </Grid>
                    </Grid>
                </Collapse>
            </TableCell>
        </TableRow>
    </>)
}

interface InspectorJobDeliveryFeedProps {
    isOpen: boolean;
    inspectorAssignment: JobInspectorAssignment;
}
function InspectorJobDeliveryFeed({ inspectorAssignment, isOpen }: InspectorJobDeliveryFeedProps) {
    const { t } = useTranslation()
    const helpers = useSelector((state: any)=> state.helpers)
    const specificJob = useSelector((state: any)=> state.entities.jobs[inspectorAssignment.job_id])
    const currentCompanyID = useSelector((state: any)=> state.session.company.id)

    const isPickupAndDropoffInspector = inspectorAssignment.is_pickup_inspector && inspectorAssignment.is_dropoff_inspector
    const pickupTabValue = "0"
    const dropoffTabValue = "1"
    const [tabValue, setTabValue] = useState(pickupTabValue)

    const [feed, setFeed] = useState<DeliveryApi[]>([])
    const [pickupFeed, setPickupFeed] = useState<DeliveryApi[]>([])
    const [dropoffFeed, setDropoffFeed] = useState<DeliveryApi[]>([])

    const feedUpdateTrigger = useRootSelector(state => state.entities.triggers.inspectorFeed || false)

    useEffect(() => {
        if (isOpen && feedUpdateTrigger) {
            console.log('refreshing deliveries due to notification');
            
            refreshDeliveries()
        }
    }, [feedUpdateTrigger])

    useEffect(() => {
        if (isOpen) {
            refreshDeliveries()
        }
    }, [isOpen, tabValue])

    const refreshDeliveries = () => {
        console.log("InspectorJobDeliveryFeed refreshDeliveries")
        // setFeed([])
        const options: GetDeliveryFeedOptions = {
            page: 1
        }
        let updatePickupFeed = false
        let updateDropoffFeed = false
        if (specificJob) {
            if (specificJob.company_id == currentCompanyID) {
                // is company inspector
                if (tabValue === pickupTabValue) {
                    updatePickupFeed = true
                    options._requires_company_pickup_inspection = inspectorAssignment.is_pickup_inspector
                } else if (tabValue === dropoffTabValue){
                    updateDropoffFeed = true
                    options._requires_company_pickup_inspection = 0
                    options._requires_company_dropoff_inspection = inspectorAssignment.is_dropoff_inspector
                }
            } else {
                // is third party inspector
                if (tabValue === pickupTabValue) {
                    updatePickupFeed = true
                    options._requires_company_pickup_inspection = inspectorAssignment.is_pickup_inspector
                } else if (tabValue === dropoffTabValue) {
                    updateDropoffFeed = true
                    options._requires_third_party_pickup_inspection = 0
                    options._requires_third_party_dropoff_inspection = inspectorAssignment.is_dropoff_inspector
                }
            }
        }
        getDeliveryFeed(inspectorAssignment.token, inspectorAssignment.job_id, options).then(res => {
            if (res?.success) {
                const { data } = res.data
                if (updatePickupFeed) setPickupFeed(data)
                else if (updateDropoffFeed) setDropoffFeed(data)
                setFeed(data)
            }
        })
    }

    if (!isPickupAndDropoffInspector) {
        return <DeliveriesTable 
            deliveries={feed}
            forInspector
            inspectorAssignment={inspectorAssignment}
            refreshDeliveries={refreshDeliveries}
            inspectionType={tabValue === pickupTabValue ? "pickup" : "dropoff"}
        />
        // return (
        //     <DeliveriesAccordion 
        //         helpers={helpers}
        //         deliveries={feed}
        //         t={t}
        //         forInspector={true}
        //         inspectorAssignment={inspectorAssignment}
        //         refreshDeliveries={refreshDeliveries}
        //     />
        // )
    }
    return <>
        <TabContext value={tabValue}>
            <AppBar color="default" position="static">
                <Toolbar style={{ padding: '10px' }}>
                    <Tabs
                        // variant="scrollable"
                        indicatorColor="primary"
                        // scrollButtons="auto"
                        value={tabValue}
                        onChange={(event, val) => {
                            setTabValue(val)
                            refreshDeliveries()
                        }}
                    >
                        <Tab label={t('addressTranslations.pickup')} value={pickupTabValue} />
                        <Tab label={t('addressTranslations.dropoff')} value={dropoffTabValue} />
                    </Tabs>
                </Toolbar>
            </AppBar>
            <TabPanel value={tabValue} index={pickupTabValue}>
                <DeliveriesAccordion 
                    helpers={helpers}
                    deliveries={pickupFeed}
                    t={t}
                    forInspector={true}
                    inspectorAssignment={inspectorAssignment}
                    refreshDeliveries={refreshDeliveries}
                    inspectionType={tabValue === pickupTabValue ? "pickup" : "dropoff"}
                />
            </TabPanel>
            <TabPanel value={tabValue} index={dropoffTabValue}>
                <DeliveriesAccordion 
                    helpers={helpers}
                    deliveries={dropoffFeed}
                    t={t}
                    forInspector={true}
                    inspectorAssignment={inspectorAssignment}
                    refreshDeliveries={refreshDeliveries}
                    inspectionType={tabValue === pickupTabValue ? "pickup" : "dropoff"}
                />
            </TabPanel>
        </TabContext>
    </>
}
interface InspectorJobsTableProps {
    assignments: {
        [jobID: string]: JobInspectorAssignment
    }
}
function InspectorJobsTable({ assignments }: InspectorJobsTableProps) {
    const [expanded, setExpanded] = useState(0)
    const handleJobSelection = (jobID: string) => {
        const id = parseInt(jobID)
        setExpanded(expanded === id ? 0 : id)
    };

    const onRowClick = (jobID: string) => {
        handleJobSelection(jobID)
    }

    if (!assignments) return null;

    return <>
        <TableContainer>
            <Table>
                <TableBody>
                    {Object.keys(assignments).length < 1 && <TableRow><TableCell colSpan={200}><Typography variant="h5">No jobs found</Typography></TableCell></TableRow>}
                    {Object.keys(assignments).map((jobID: string) => <InspectorJobRow key={jobID} inspectorAssignment={assignments[jobID]} isOpen={expanded.toString() === jobID} onRowClick={onRowClick}/>)}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}

interface InspectorLinkParams { inspectorToken: string, jobID: string }
interface InspectorDeliveriesProps {
    match: match<InspectorLinkParams>
    inspectorAssignments: JobInspectorAssignmentMap,
    updateInspectorDetails: (params: InspectorLinkParams) => void
}

interface InspectorDeliveriesState {
    page: number;
    count: number;
    openCreateInspection: boolean;
    assignments: JobInspectorAssignmentMap | null,

    viewInProgressJobsOnly: boolean, // if false, show date 
    viewInspectionsForCompanyJobs: boolean,
    date: Date,
}
class InspectorDeliveries extends React.Component<InspectorDeliveriesProps & ReduxProps & ReduxState, InspectorDeliveriesState> {
    
    private mounted = false;

    state: InspectorDeliveriesState = {
        page: 0,
        assignments: null,
        count: -1,

        viewInProgressJobsOnly: true,
        viewInspectionsForCompanyJobs: true,
        date: new Date(),

        openCreateInspection: false,
    }
    componentWillUnmount = () => this.mounted = false
    setStateIfMounted = (state: any, callback?: () => void) => {
        if (this.mounted) {
            this.setState(state, callback)
        }
    }
    componentDidMount = () => {
        this.mounted = true;
        this.loadInitialData()
    }
    loadInitialData = () => {
        if (
            this.props.match.params.inspectorToken && 
            this.props.match.params.inspectorToken !== ':inspectorToken' && 
            this.props.match.params.jobID &&
            this.props.match.params.jobID !== ':jobID'
        ) {
            this.props.updateInspectorDetails(this.props.match.params)
            this.getInspections(this.props.match.params.inspectorToken, this.props.match.params.jobID)
        } else {
            // Make sure we have the inspector token and job id
            this.checkInspectorToken()
        }
    }
    componentDidUpdate = (prevProps: any, prevState: any) => {
        if (prevProps.inspectorAssignments != this.props.inspectorAssignments && Object.keys(this.props.inspectorAssignments).length) {
            this.setStateIfMounted({ assignments: this.props.inspectorAssignments })
        }
        if (prevProps.feedUpdateTrigger != this.props.feedUpdateTrigger && this.props.feedUpdateTrigger) {
            this.loadInitialData()
            console.log('refreshing inspector tokens due to notification');
            this.props.triggerInspectorFeedUpdate(false)
        }
        if (prevState.viewInProgressJobsOnly !== this.state.viewInProgressJobsOnly) {
            this.loadInitialData()
        }
        if (prevState.viewInspectionsForCompanyJobs !== this.state.viewInspectionsForCompanyJobs) {
            this.loadInitialData()
        }
    }
    checkInspectorToken = async () => {
        if (!this.props.match.params.inspectorToken) {
            this.getInspectorTokens()
        } else {
            this.getInspections()
        }
    }
    getInspectorTokens = () => {
        this.setStateIfMounted({
            assignments: null
        }, async () => {

            // View company inspections
            // Gets company jobs and the inspector tokens associated with them
            if (this.state.viewInspectionsForCompanyJobs) {
                const options: GetJobsBeingWorkedOnByMyCompanyOptions = { return_only_ids: 1 }
                if (this.state.viewInProgressJobsOnly) {
                    options.has_in_progress_delivery = 1
                } else {
                    options.start_date_range_end = toMysqlFormat(this.state.date, { dateOnly: true })
                    options.end_date_range_start = toMysqlFormat(this.state.date, { dateOnly: true })
                }
                const response = await getJobsBeingWorkedOnByMyCompany(this.props.token, this.props.company.id, options)
                if (response.success && Array.isArray(response.data) && response.data.length) {
                    console.log("INSP JOB IDS", response.data);
                    this.props.getAllInspectorTokens(this.props.token, this.props.company.id, { job_ids: response.data })
                } else {
                    this.setStateIfMounted({
                        assignments: []
                    })
                }
                return
            }

            // View third party inspections
            // Currently, there's no way to see which jobs your company is inspecting as a third party inspector
            // So just don't filter by jobs
            this.props.getAllInspectorTokens(this.props.token, this.props.company.id, undefined)

        })
    }
    getInspections = (inspectorToken?: string, jobID?: string) => {
        const token = inspectorToken || this.props.inspectorDetails?.inspectorToken
        const id = jobID || this.props.inspectorDetails?.jobID
        this.setState({
            assignments: {
                ...this.state.assignments!,
                [id]: {
                    token
                }
            }
        })
    }
    
    handleChangePage = (event: any, newPage: number) => {
        this.setStateIfMounted({
            page: newPage
        }, this.loadInitialData)
    }
    render = () => {
        return (
            <React.Fragment>
                <Helmet title={"Inspection Deliveries"} />

                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <Paper p={5}>
                            <Grid container spacing={10}>
                                <Grid item xs>
                                    <Typography variant='h2' ml={3}>
                                        Inspection Deliveries
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {/* <IconButton
                                        onClick={this.toggleFilter}
                                    >
                                        <FilterList />
                                    </IconButton> */}
                                    <IconButton
                                        onClick={() => this.loadInitialData()}
                                    >
                                        <Refresh />
                                    </IconButton>
                                </Grid>
                            </Grid>

                            <Divider my={5} />
                            <Grid container spacing={10}>
                                <Grid item>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">View Inspections for Company Jobs</FormLabel>
                                        <FormGroup>
                                            <FormControlLabel
                                                label=""
                                                control={
                                                    <Switch
                                                        name=""
                                                        checked={this.state.viewInspectionsForCompanyJobs}
                                                        onChange={e => {
                                                            this.setStateIfMounted({ viewInspectionsForCompanyJobs: e.target.checked })
                                                        }}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                {this.state.viewInspectionsForCompanyJobs && <Grid item>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">In Progress Jobs Only</FormLabel>
                                        <FormGroup>
                                            <FormControlLabel
                                                label=""
                                                control={
                                                    <Switch
                                                        name=""
                                                        checked={this.state.viewInProgressJobsOnly}
                                                        onChange={e => {
                                                            this.setStateIfMounted({ viewInProgressJobsOnly: e.target.checked })
                                                        }}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>}
                                {!this.state.viewInProgressJobsOnly && <>
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <KeyboardDatePicker
                                                // label={t('timeTranslations.startDate')}
                                                label={"Date"}
                                                value={this.state.date}
                                                onChange={date => this.setStateIfMounted({ date })}
                                                format="MM/dd/yyyy"
                                                // min={this.today}
                                                inputVariant="outlined"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => this.loadInitialData()}
                                        >
                                            Get Assignments
                                        </Button>
                                    </Grid>
                                </>
                                }
                            </Grid>
                            <Divider my={5} />
                            {this.state.assignments != null ? <>
                                <InspectorJobsTable 
                                    assignments={this.state.assignments}
                                />
                            </> : (
                                <Grid container spacing={10} alignItems="center" justify="center">
                                    <TPLoader />
                                </Grid>

                            )}

                            {/* <TablePagination
                                rowsPerPageOptions={[20]}
                                component="div"
                                count={this.state.count}
                                rowsPerPage={20}
                                page={this.state.page}
                                onPageChange={this.handleChangePage}
                            /> */}
                        </Paper>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}
interface ReduxState {
    company: Company,
    user: User,
    token: string,
    helpers: any,
    inspectorDetails: any,
    inspectorAssignments: JobInspectorAssignmentMap,
    feedUpdateTrigger: boolean,
}
interface ReduxProps {
    popAlert: (success: string, title: string, message: string) => void,
    getSpecificJob: (token: string, companyID: number, jobID: number) => void,
    updateInspectorDetails: (params: InspectorLinkParams) => void,
    getAllInspectorTokens: (token: string, companyID: number, options?: GetUserInspectorsOptions) => void;
    triggerInspectorFeedUpdate: (bool: boolean) => void;
}
const msp = (state: any) => ({
    company: state.session.company,
    user: state.session.user,
    token: state.session.user.token,
    helpers: state.helpers,
    inspectorDetails: state.session.inspectorDetails,
    inspectorAssignments: state.entities.inspectorAssignments,
    feedUpdateTrigger: state.entities.triggers.inspectorFeed,
})
const mdp = (dispatch: any) => ({
    popAlert: (type: string, title: string, msg: string) => dispatch(popAlert(type, title, msg)),
    getSpecificJob: (token: string, companyID: number, jobID: number) => dispatch(getSpecificJob(token, companyID, jobID)),
    updateInspectorDetails: (payload: any) => dispatch(updateInspectorDetails(payload)),
    getAllInspectorTokens: (token: string, companyID: number, options?: GetUserInspectorsOptions) => dispatch(getAllInspectorTokens(token, companyID, options)),
    triggerInspectorFeedUpdate: (bool: boolean) => dispatch(triggerInspectorFeedUpdate(bool)),
})
export default connect<ReduxState, ReduxProps>(msp, mdp)(withTranslation()(InspectorDeliveries));
