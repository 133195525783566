import { TPAPIBase } from '../../env';
import { functions } from '../reducers/helpersReducer';
import i18n from '../../i18n';

export const getJob = (token, companyID, jobID) => (
    fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then(res => {
        const tempRes = { ...res };
        if (res.success && res.data) {
            tempRes.data.displayStartDate = functions.getFormattedDateFromDateString(tempRes.data.start_date)
            tempRes.data.displayEndDate = functions.getFormattedDateFromDateString(tempRes.data.end_date)
            tempRes.data.displayBiddingEndDate = functions.getFormattedDateFromUnix(tempRes.data.bidding_ends)
        }
        return tempRes;
    }).catch(err => console.log(err))
)
export const getCompanyBids = (token, companyID, params = []) => {
    const formattedParams = params.map(param => `${param.key}=${param.value}`).join("&")
    return fetch(`${TPAPIBase}/company/${companyID}/bids?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}
export const getAllCompanyBids = async (token, companyID, params = []) => {
    let fetchedAll = false
    let page = 1
    const bids = []
    while (!fetchedAll) {
        await getCompanyBids(token, companyID, [{ key: 'page', value: page }, ...params]).then(res => {
            if (res.success && res.data.data) {
                bids.push(...res.data.data)
                if (res.data.next_page_url) {
                    page += 1
                } else {
                    fetchedAll = true
                }
            } else {
                fetchedAll = true
            }
        })
    }
    return {
        success: true,
        data: bids
    }
}
export const modifyRequestedBid = (token, jobID, bidID, data) => (
    fetch(`${TPAPIBase}/job/${jobID}/requested-bid/${bidID}/modify`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const acceptRequestedBid = (token, companyID, jobID, bidID) => (
    fetch(`${TPAPIBase}/job/${jobID}/requested-bid/${bidID}/accept`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: { bidder_company_id: companyID }
        })
    }).then(response => response.json())
)
export const declineRequestedBid = (token, companyID, jobID, bidID) => (
    fetch(`${TPAPIBase}/job/${jobID}/requested-bid/${bidID}/decline`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: { bidder_company_id: companyID }
        })
    }).then(response => response.json())
)
export const getPendingOrRequestedBidsOnJob = (token, jobID) => (
    fetch(`${TPAPIBase}/job/${jobID}/bidders`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getAssignedDrivers = (token, companyID, jobID, options = { page: 1 }) => (
    fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/assigned/drivers?page=${options.page}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getAllAssignedDrivers = async (token, companyID, jobID) => {
    let fetchedAll = false
    let page = 1
    const assignedDrivers = []
    while (!fetchedAll) {
        await getAssignedDrivers(token, companyID, jobID, { page }).then(res => {
            if (res.success && res.data.data) {
                assignedDrivers.push(...res.data.data)
                if (res.data.next_page_url) {
                    page += 1
                } else {
                    fetchedAll = true
                }
            } else {
                fetchedAll = true
            }
        })
    }
    return {
        success: true,
        data: assignedDrivers
    }
}
export const getJobCoordinates = (token, companyID, jobID) => (
    fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getBidsForJob = (token, jobID) => (
    fetch(`${TPAPIBase}/job/${jobID}/bidders`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getDriverLocations = (token, jobID, params = {}) => {
    const formattedParams = Object.keys(params).map(key => `${key}=${params[key]}`).join("&")
    return fetch(`${TPAPIBase}/tracker/job/${jobID}/drivers?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).catch(err => console.log(err))
}

export const createJob = (token, companyID, siteID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/site/${siteID}/job`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const editJob = (token, jobID, data) => (
    fetch(`${TPAPIBase}/job/${jobID}/modify`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const editActiveJob = (token, jobID, data) => (
    fetch(`${TPAPIBase}/job/${jobID}/modify-active`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const markJobCompleted = (token, jobID) => (
    fetch(`${TPAPIBase}/job/${jobID}/is_completed`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
    }).then(response => response.json())
)
export const getUsersWhoCanView = (token, jobID) => (
    fetch(`${TPAPIBase}/job/${jobID}/users-that-can-view`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const addUserWhoCanView = (token, jobID, email) => (
    fetch(`${TPAPIBase}/job/${jobID}/users-that-can-view`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: { emails: [email] }
        })
    }).then(response => response.json())
)
export const removeUserWhoCanView = (token, jobID, userID) => (
    fetch(`${TPAPIBase}/job/${jobID}/users-that-can-view/${userID}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
    }).then(response => response.json())
)
export const awardBid = (token, companyID, jobID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/award`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const fireDriver = (token, jobID, data) => (
    fetch(`${TPAPIBase}/job/${jobID}/owner_operator/fire`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const fireCompany = (token, jobID, data) => (
    fetch(`${TPAPIBase}/job/${jobID}/fire`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const dismissDriver = (token, jobID, data) => (
    fetch(`${TPAPIBase}/job/${jobID}/driver/dismiss`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const declineBid = (token, jobID, bidID) => (
    fetch(`${TPAPIBase}/job/${jobID}/bid/${bidID}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: { is_approved: false }
        })
    }).then(response => response.json())
)
export const placeBid = (token, companyID, jobID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/bid`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const createABid = (token, jobID, data) => (
    fetch(`${TPAPIBase}/job/${jobID}/bid`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const cancelBid = (token, companyID, jobID, bidID) => (
    fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/bid/${bidID}/cancel`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: {}
        })
    }).then(response => response.json())
)
export const assignDrivers = (token, jobID, data) => (
    fetch(`${TPAPIBase}/job/${jobID}/assign`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const quitJob = (token, data) => (
    fetch(`${TPAPIBase}/owner_operator/resign`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const resign = (token, companyID, jobID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/resign`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const supervisorCheckout = (token, checkinID) => (
    fetch(`${TPAPIBase}/jobs/supervisor-checkout`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: {
                checkin_id: checkinID
            }
        })
    }).then(response => response.json())
)
export const getPrenegotiatedRatesForJobAsDriverCompany = (token, companyID, jobID) => (
    fetch(`${TPAPIBase}/company/${companyID}/prenegotiated-rates-as-driver-company/job/${jobID}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getPrenegotiatedRatesForJobAsConstructionCompany = (token, companyID, jobID) => (
    fetch(`${TPAPIBase}/company/${companyID}/prenegotiated-rates-as-construction-company/job/${jobID}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getUnratedJobs = (token, companyID) => (
    fetch(`${TPAPIBase}/ratings/company/${companyID}/unrated/jobs`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getUnratedWorkers = (token, jobID) => (
    fetch(`${TPAPIBase}/ratings/job/${jobID}/unrated`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const rateWorker = (token, jobID, data) => (
    fetch(`${TPAPIBase}/ratings/job/${jobID}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const calculateBid = (token, data) => (
    fetch(`${TPAPIBase}/bidcalculator`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)

export const getJobWhitelist = (token, companyID, jobID, params = []) => {
    const formattedParams = params.map(param => `${param.key}=${param.value}`).join("&")
    return fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/whitelist/companies?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}
export const getEntireJobWhitelist = (token, companyID, jobID) => {
    return new Promise(async (res, rej) => {
        const companies = []
        let page = 1;
        let fetchedAllCompanies = false;
        while (!fetchedAllCompanies) {
            await getJobWhitelist(token, companyID, jobID, [{ key: 'page', value: page }]).then((res) => {
                if (res.success && res.data.data) {
                    companies.push(...res.data.data)
                    if (res.data.next_page_url) {
                        page += 1;
                    } else {
                        fetchedAllCompanies = true
                    }
                } else {
                    fetchedAllCompanies = true
                }
            })
        }
        res({
            success: true,
            data: companies
        })
    })
}
export const addCompaniesToJobWhitelist = (token, companyID, jobID, companyIDs) => (
    fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/whitelist/companies`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: { companies: companyIDs }
        })
    }).then(response => response.json())
)
export const removeCompanyFromJobWhitelist = (token, companyID, jobID, companyToRemoveID) => (
    fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/whitelist/companies/${companyToRemoveID}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
    }).then(response => response.json())
)
export const getJobsCreatedByCompany = (token, companyID, params) => {
    const formattedParams = params.map(param => `${param.key}=${param.value}`).join("&")
    return fetch(`${TPAPIBase}/company/${companyID}/jobs/created?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then(res => {
        const tempRes = { ...res };
        if (res.success && res.data && res.data.data.length) {
            tempRes.data.data = res.data.data.map(job => {
                job.displayStartDate = functions.getFormattedDateFromDateString(job.start_date)
                job.displayEndDate = functions.getFormattedDateFromDateString(job.end_date)
                return job;
            })
        }
        return tempRes;
    })
}
export const getJobsBeingWorkedOnByMyCompany = (token, companyID, params) => {
    const formattedParams = params.map(param => `${param.key}=${param.value}`).join("&")
    return fetch(`${TPAPIBase}/company/${companyID}/jobs/working?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then(res => {
        const tempRes = { ...res };
        if (res.success && res.data && res.data.data.length) {
            tempRes.data.data = res.data.data.map(job => {
                job.displayStartDate = functions.getFormattedDateFromDateString(job.start_date)
                job.displayEndDate = functions.getFormattedDateFromDateString(job.end_date)
                return job;
            })
        }
        return tempRes;
    })
}
export const getDriverJobs = (token, companyID, params) => {
    const formattedParams = params.map(param => `${param.key}=${param.value}`).join("&")
    return fetch(`${TPAPIBase}/company/${companyID}/jobs/driving?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then(res => {
        const tempRes = { ...res };
        if (res.success && res.data && res.data.data.length) {
            tempRes.data.data = res.data.data.map(job => {
                job.displayStartDate = functions.getFormattedDateFromDateString(job.start_date)
                job.displayEndDate = functions.getFormattedDateFromDateString(job.end_date)
                return job;
            })
        }
        return tempRes;
    })
}

////////// CURRENT JOBS //////////


const getCurrentJobs = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/jobs/current`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
const getFourwayJobs = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/jobs/fourway`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getAssignedCurrentNotInProgressJobs = (token, companyID, asCompany) => (
    fetch(`${TPAPIBase}/company/${companyID}/assigned-jobs/current_not_inprogress?for_company=${asCompany ? 1 : 0}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then(res => {
        const tempRes = { ...res };
        if (res.success && res.data.length) {
            tempRes.data = res.data.map(job => {
                job.displayStartDate = functions.getFormattedDateFromDateString(job.start_date)
                job.displayEndDate = functions.getFormattedDateFromDateString(job.end_date)
                return job;
            })
        }
        return tempRes;
    })
)
export const getAllCurrentJobs = (token, companyID, asCompany) => (
    Promise.all([
        getCurrentJobs(token, companyID),
        getFourwayJobs(token, companyID),
        getAssignedCurrentNotInProgressJobs(token, companyID, asCompany),
    ]).then(resArray => {
        const jobIDs = {}
        const filteredJobs = [];
        if (resArray[0].hasOwnProperty('data') && resArray[1].hasOwnProperty('data') && resArray[2].hasOwnProperty('data')) {
            [...resArray[0].data, ...resArray[1].data, ...resArray[2].data].forEach(job => {
                if (jobIDs[job.id]) {
                    // do nothing
                } else {
                    jobIDs[job.id] = true;
                    job.displayStartDate = functions.getFormattedDateFromDateString(job.start_date)
                    job.displayEndDate = functions.getFormattedDateFromDateString(job.end_date)
                    filteredJobs.push(job)
                }
            })
            return {success: true, data: filteredJobs};
        } else {
            return { success: false, data: []}
        }
    })
)


////////// NEARBY JOBS //////////


export const getNearbyJobs = (token, companyID, options = { relativeToCompany: false, page: 1 }) => (
    fetch(`${TPAPIBase}/company/${companyID}/jobs/nearby?relative_to_company=${options.relativeToCompany ? 1 : 0}&page=${options.page}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then(res => {
        const tempRes = { ...res };
        if (res.success && res.data && res.data.data.length) {
            tempRes.data.data = res.data.data.map(job => {
                job.displayStartDate = functions.getFormattedDateFromDateString(job.start_date)
                job.displayEndDate = functions.getFormattedDateFromDateString(job.end_date)
                return job;
            })
        }
        return tempRes;
    })
)


////////// PENDING JOBS //////////


export const getPendingJobs = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/jobs/pending`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then(res => {
        const tempRes = { ...res };
        if (res.success && res.data.length) {
            tempRes.data = res.data.map(job => {
                job.displayStartDate = functions.getFormattedDateFromDateString(job.start_date)
                job.displayEndDate = functions.getFormattedDateFromDateString(job.end_date)
                return job;
            })
        }
        return tempRes;
    })
)


////////// PAST JOBS //////////


export const getPastJobs = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/jobs/past`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then(res => {
        const tempRes = { ...res };
        if (res.success && res.data.length) {
            tempRes.data = res.data.map(job => {
                job.displayStartDate = functions.getFormattedDateFromDateString(job.start_date)
                job.displayEndDate = functions.getFormattedDateFromDateString(job.end_date)
                return job;
            })
        }
        return tempRes;
    })
)
export const getAssignedPastJobs = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/assigned-jobs/past`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then(res => {
        const tempRes = { ...res };
        if (res.success && res.data.length) {
            tempRes.data = res.data.map(job => {
                job.displayStartDate = functions.getFormattedDateFromDateString(job.start_date)
                job.displayEndDate = functions.getFormattedDateFromDateString(job.end_date)
                return job;
            })
        }
        return tempRes;
    })
)


////////// IN PROGRESS JOBS //////////


export const getInProgressJobs = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/jobs/inprogress`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
    // .then(res => {
    //     const tempRes = { ...res };
    //     if (res.success && res.data.length) {
    //         tempRes.data = res.data.map(job => {
    //             job.displayStartDate = functions.getFormattedDateFromUnix(job.start_date)
    //             job.displayEndDate = functions.getFormattedDateFromUnix(job.end_date)
    //             return job;
    //         })
    //     }
    //     return tempRes;
    // })
)
export const getCompanyInProgressJobs = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/jobs/companyInprogress`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
    // .then(res => {
    //     const tempRes = { ...res };
    //     if (res.success && res.data.length) {
    //         tempRes.data = res.data.map(job => {
    //             job.displayStartDate = functions.getFormattedDateFromUnix(job.start_date)
    //             job.displayEndDate = functions.getFormattedDateFromUnix(job.end_date)
    //             return job;
    //         })
    //     }
    //     return tempRes;
    // })
)
export const getAssignedInProgressJobs = (token, companyID, forCompany) => (
    fetch(`${TPAPIBase}/company/${companyID}/assigned-jobs/inprogress?for_company=${forCompany ? 1 : 0}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
    // .then(res => {
    //     const tempRes = { ...res };
    //     if (res.success && res.data.length) {
    //         tempRes.data = res.data.map(job => {
    //             job.displayStartDate = functions.getFormattedDateFromUnix(job.start_date)
    //             job.displayEndDate = functions.getFormattedDateFromUnix(job.end_date)
    //             return job;
    //         })
    //     }
    //     return tempRes;
    // })
)
export const getAllInProgressJobs = (token, companyID, asCompany) => (
    Promise.all([
        getInProgressJobs(token, companyID),
        getCompanyInProgressJobs(token, companyID),
        getAssignedInProgressJobs(token, companyID, asCompany),
    ]).then(resArray => {
        const jobIDs = {}
        const filteredJobs = [];
        if (resArray[0].hasOwnProperty('data') && resArray[1].hasOwnProperty('data') && resArray[2].hasOwnProperty('data')) {
            [...resArray[0].data, ...resArray[1].data, ...resArray[2].data].forEach(job => {
                if (jobIDs[job.id]) {
                    // do nothing
                } else {
                    jobIDs[job.id] = true;
                    job.displayStartDate = functions.getFormattedDateFromDateString(job.start_date)
                    job.displayEndDate = functions.getFormattedDateFromDateString(job.end_date)
                    filteredJobs.push(job)
                }
            })
            return { success: true, data: filteredJobs };
        } else {
            return { success: false, data: [] };
        }
    })
)



////////// EXTERNAL JOBS //////////


export const getExternalJobs = (token, includeCompleted) => (
    fetch(`${TPAPIBase}/jobs-i-can-view?include_completed=${includeCompleted ? 1 : 0}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then(res => {
        const tempRes = { ...res };
        if (res.success && res.data.length) {
            tempRes.data = res.data.map(externalSubscription => {
                const job = externalSubscription.job;
                job.displayStartDate = functions.getFormattedDateFromDateString(job.start_date)
                job.displayEndDate = functions.getFormattedDateFromDateString(job.end_date)
                return job;
            })
        }
        return tempRes;
    })
)



////////// BIDDED JOBS //////////


export const getBiddedJobs = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/jobs/bidded`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then(res => {
        const tempRes = { ...res };
        if (res.success && res.data.length) {
            tempRes.data = res.data.map(job => {
                job.displayStartDate = functions.getFormattedDateFromDateString(job.start_date)
                job.displayEndDate = functions.getFormattedDateFromDateString(job.end_date)
                return job;
            })
        }
        return tempRes;
    })
)
