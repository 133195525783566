import React, { useEffect, useRef, useState } from 'react';
import {
    Modal,
    Tab,
    Tabs,
    TabContent,
    TabScrollButton,
    TabList,
    Paper as MuiPaper,
    AppBar,
    Toolbar,
    Typography as MuiTypography,
    Grid as MuiGrid,
    FormGroup,
    FormControl as MuiFormControl,
    FormControlLabel,
    FormLabel,
    FormHelperText,
    Input,
    InputLabel,
    Select,
    TextField,
    MenuItem,
    Button,
    Switch,
    Radio,
    RadioGroup,
    Divider as MuiDivider,
    InputAdornment,
    Box as MuiBox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link
} from '@material-ui/core';
import TPModal from '../../Util/TPModal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import CloseIcon from "@material-ui/icons/Close";
import { getPhoneData, CustomPhoneNumberInput } from '../../Util/PhoneInput';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { popAlert } from '../../../../redux/actions/sessionActions';
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';
import SigCanvas from 'react-signature-pad-wrapper';
import PropTypes from 'prop-types'

import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { getMyInspectionsWithValues } from '../../../../redux/util/inspectorAPI';
import { useParams } from 'react-router-dom';
import { InspectionItemDataTypes } from '../../../../redux/reducers/entities';
import { getDateTimeFromUnix } from '../../../../redux/reducers/helpersReducerr';
import Separator from '../../Util/Separator';
import { getCompanyInspectionWithValues } from '../../../../redux/util/companyAPI_ts';
import ViewDeliveryModal from '../../Deliveries/modals/ModalViewDelivery';

const Paper = styled(MuiPaper)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Box = styled(MuiBox)(spacing);
const Bold = styled.span`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: black;
`;

function RenderInspectionValue({ inspectionValue }) {
    const { t } = useTranslation()
    const jobInspectionItem = inspectionValue.job_inspection_item
    const inspectionItem = jobInspectionItem.inspection_item

    let displayError = <></>
    let displayLine1 = <Typography variant="h6">{inspectionItem.name}: {inspectionValue.value} {inspectionValue.units ? t(`unitTranslations.${inspectionValue.units}`) : ""}</Typography>
    let displayLine2 = <Typography variant="body2">{inspectionItem.description}</Typography>
    let displayLine3 = <Typography variant="subtitle2" color="textSecondary">Notes: </Typography>
    let displayLine4 = <Typography variant="body1">{jobInspectionItem.notes || t('na')}</Typography>
    

    switch (inspectionValue.job_inspection_item.inspection_item.data_type) {
        case InspectionItemDataTypes.STRING:
        case InspectionItemDataTypes.INTEGER:
        case InspectionItemDataTypes.DECIMAL:
            // these all use the default display
            break;

        case InspectionItemDataTypes.DATETIME:
            displayLine1 = <Typography variant="h6">{inspectionItem.name}: {getDateTimeFromUnix(inspectionValue.value)}</Typography>
            break
        case InspectionItemDataTypes.BOOLEAN: 
            displayLine1 = <Typography variant="h6">{inspectionItem.name}: {inspectionValue.value ? t('valid') : t('invalid')}</Typography>
            break
        case InspectionItemDataTypes.IMAGE:
            displayLine1 = <Typography variant="h6">{inspectionItem.name}: <Link href={inspectionValue.value} target="_blank">View Image</Link></Typography>
            break;
        case "document":
            displayLine1 = <Typography variant="h6">{inspectionItem.name}: <Link href={inspectionValue.value} target="_blank">View Doc</Link></Typography>
            break;
        case "signature": 
            displayLine1 = <Typography variant="h6">{inspectionItem.name}: <Link href={inspectionValue.value} target="_blank">View Signature</Link></Typography>
            break;
        default: 
            displayError = <Typography color="error">Unknown Error.</Typography>
            break;
    }
    return (<Grid container spacing={5}>
        <Grid item xs={12}>
            {displayError}
            {displayLine1}
            {displayLine2}
            {displayLine3}
            {displayLine4}
        </Grid>
    </Grid>)

}

/**
 * Modal that shows inspection details
 * 
 * __API USAGES__
 * - `asSupervisor` === true
 *   - `Get Company Inspection With Values`
 * - `asSupervisor` !== true
 *   - `Get My Inspections With Values`
 * 
 * 
 */
function ViewInspectionModal({ 
    setModalOpen,
    inspection,
    specificToken,
    specificJobID,    
    asSupervisor,
}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const currentCompany = useSelector(state => state.session.company)
    const helpers = useSelector(state => state.helpers)
    const token = useSelector(state => state.session.user.token)

    const inspectorToken = useSelector(state => state.session.inspectorDetails?.inspectorToken || specificToken)
    const jobID = useSelector(state => state.session.inspectorDetails?.jobID || specificJobID)

    const [inspectionValues, setInspectionValues] = useState([])
    
    const getValuesAsSupervisor = async () => {
        const response = await getCompanyInspectionWithValues({
            token,
            companyId: currentCompany.id,
            inspectionId: inspection.id
        })
        if (!response.success) return;
        setInspectionValues(response.data.values)
    }

    const getValuesAsInspector = async () => {
        const response = await getMyInspectionsWithValues(inspectorToken, jobID, inspection.id)
        if (!response.success) return;
        setInspectionValues(response.data.values)
    }

    useEffect(() => {
        if (asSupervisor) {
            getValuesAsSupervisor()
        } else {
            getValuesAsInspector()
        }
    }, [])

    const [openDeliveryModal, setOpenDeliveryModal] = useState(false)

  
    return (
        <TPModal 
            setModalOpen={setModalOpen}
            headerOptions={{
                headerText: inspection.displayDate,
            }}
        >
            <Grid container spacing={10}>
                <Grid item>
                    <Button
                        variant="contained"
                        onClick={() => setOpenDeliveryModal(true)}
                    >
                        View Delivery
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={12}>
                    <Typography color="textSecondary">
                        Notes:
                    </Typography>
                    <Typography>
                        {inspection.notes || t('na')}
                    </Typography>
                </Grid>
            </Grid>
            <Separator my={3} />
            {inspectionValues.map(inspection => <React.Fragment key={inspection.id}>
                <RenderInspectionValue inspectionValue={inspection} />
                <Divider my={3} />
            </React.Fragment>)}
            {openDeliveryModal && <ViewDeliveryModal 
                setModalOpen={setOpenDeliveryModal}
                deliveryID={inspection.delivery_id}
            />}
        </TPModal>
    )
    
}
ViewInspectionModal.propTypes = {
    setModalOpen: PropTypes.func.isRequired,
    specificToken: PropTypes.string,
    specificJobID: PropTypes.number,
    inspection: PropTypes.object,
    asSupervisor: PropTypes.bool,
}

export default ViewInspectionModal