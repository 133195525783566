import i18n from '../../i18n';
import { useRef, useEffect } from 'react';
import moment from 'moment';
import { GMapAPIKey } from '../../env';
export const functions = {
    getDateTimeFromUnix: function (unixTimestamp, options = { timezone: undefined, noTimezone: false, keepLocal: false }) {
        if (!unixTimestamp) return '';
        const date = this.createDateFromUnix(unixTimestamp, { keepLocal: options.keepLocal });
        const dateOptions = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }
        if (!options.noTimezone) {
            options.timeZoneName =  'short'
            options.timeZone = options.timezone
        }
        return date.toLocaleString(i18n.language, dateOptions)
    },
    getDateTime: function (date, options = { timezone: undefined }) {
        if (!date) return '';
        return date.toLocaleString(i18n.language, { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short', timeZone: options.timezone })
    },
    getFormattedDateFromUnix: function (unixTimestamp, options = { keepLocal: false }) {
        if (!unixTimestamp) return '';
        const date = this.createDateFromUnix(unixTimestamp, options);
        return this.getFormattedLongDate(date)
        // return date.toLocaleDateString(i18n.language, { 
        //     month: 'long', 
        //     day: 'numeric', 
        //     year: 'numeric' 
        // })
    },
    getFormattedTimeFromUnix: function (unixTimestamp, options = { timezone: undefined }) {
        if (!unixTimestamp) return '';
        const date = this.createDateFromUnix(unixTimestamp);
        return this.timeFormatter(date, options)
        // return date.toLocaleTimeString(i18n.language, { 
        //     hour: 'numeric', 
        //     minute: 'numeric', 
        //     timeZoneName: 'short', 
        //     timeZone: options.timezone 
        // })
    },
    timeFormatter: function (dateVal, options = { timezone: undefined, noTimezone: false }) {
        if (!dateVal) return '';
        const date = new Date(dateVal)
        const timeOptions = {
            hour: 'numeric',
            minute: 'numeric',
        }
        if (!options.noTimezone) {
            timeOptions.timeZoneName = 'short'
            timeOptions.timeZone = options.timezone
        };
        return date.toLocaleTimeString(i18n.language, timeOptions)
    },
    getLongDate: function (date, options = { timezone: undefined }) {
        if (!date) return ['', '', ''];

        return [
            date.toLocaleDateString(i18n.language, { weekday: 'long' }),
            date.toLocaleDateString(i18n.language, { month: 'long', day: 'numeric', year: 'numeric' }),
            date.toLocaleTimeString(i18n.language, { hour: 'numeric', minute: 'numeric', timeZoneName: 'short', timeZone: options.timezone })
        ]
    },
    getFormattedLongDate: function(date) {
        if (!date) return '';
        return date.toLocaleDateString(i18n.language, { 
            month: 'long', 
            day: 'numeric', 
            year: 'numeric' 
        });
    },
    getTimeZoneOffset: function (timeZone) {
        const hoursHere = new Date().getHours();
        const hoursThere = new Date(new Date().toLocaleString('en-US', { timeZone: timeZone })).getHours();
        return hoursThere - hoursHere;
    },
    getLocalMomentFromTimeStringWithTimezone: function(timeString, timezone) {
        /*
            create date from timestring
            get timezone offset
            apply timezone offset to created date
            return date

            e.g.
            "2021-08-02 19:00:00" which is in "America/New_York"
        */
        const offset = this.getTimeZoneOffset(timezone);
        return moment(timeString).subtract(offset, 'hours')
    },
    getDateFromTime: function(timeString) {
        if (!timeString) return new Date()
        const date = new Date();
        date.setHours(...timeString.split(':').map(str => parseInt(str)));
        return date;
    },
    getFormattedLongDateFromUnix: function (unixTimestamp) {
        if (!unixTimestamp) return '';
        const date = this.createDateFromUnix(unixTimestamp);
        return this.getLongDate(date)
    },
    getDateDetails: function (datetimeString) {
        if (/(?<year>[\d]+)-(?<month>[\d]+)-(?<day>[\d]+) (?<hour>[\d]+):(?<min>[\d]+):(?<sec>[\d]+)/.test(datetimeString)) {
            return datetimeString.match(/(?<year>[\d]+)-(?<month>[\d]+)-(?<day>[\d]+) (?<hour>[\d]+):(?<min>[\d]+):(?<sec>[\d]+)/).groups
        } else {
            return {
                error: true
            }
        }
    },
    createDateFromUnix: function (unixTimestamp, options = { keepLocal: true }) {
        if (!unixTimestamp) return new Date();
        
        let {
            year, month, day,
            hour, min, sec, error
        } = this.getDateDetails(unixTimestamp)

        if (error) {
            return new Date()
        }

        if (options.keepLocal) {
            return new Date(parseInt(year), (parseInt(month) - 1), parseInt(day), parseInt(hour), parseInt(min), parseInt(sec));
        } 
        return new Date(Date.UTC(parseInt(year), (parseInt(month) - 1), parseInt(day), parseInt(hour), parseInt(min), parseInt(sec)));
    },
    getFormattedDateFromDateString: function(dateString) {
        const date = this.createDateFromDateString(dateString)
        return this.getFormattedLongDate(date)
    },
    createDateFromDateString: function(dateString) {
        const { year, month, day } = this.getDateParts(dateString)
        return new Date(parseInt(year), (parseInt(month) - 1), parseInt(day))
    },
    getDateParts: function (dateString) {
        const [year, month, day] = dateString.split('-');
        return {year, month, day}
    },
    getTimeParts: function (timeString) {
        const time = timeString.split(':');
        const hour = time[0];
        const min = time[1];
        const sec = time[2];
        return {hour, min, sec}
    },
    parseError: function (error) {
        if (!error) return 'Unknown error. Please contact a TruckPay representative.';
        let errString = '';
        if (error.message) {
            errString = error.message;
        } else if (typeof error == 'string') {
            errString = error;
        } else if (typeof error.error == 'string') {
            errString = error.error;
        } else if (error.data?.error && typeof error.data.error == 'string') {
            errString = error.data.error;
        } else if (error.data?.error && Array.isArray(error.data.error)) {
            errString = error.data.error.join(' ')
        } else {
            console.log(error);
            const errors = [];
            Object.values(error).forEach(errVal => {
                if (Array.isArray(errVal)) {
                    errVal.forEach(err=>errors.push(err))
                } else {
                    try {
                        Object.entries(errVal).forEach(entryArr => {
                            const key = entryArr[0];
                            const val = entryArr[1];
                            errors.push(val)
                        })
                    } catch (e) {
                        errors.push(errVal)
                    }
                }
            })
            errString = errors.join(' ')
        }
        return errString;
    },
    chunk: function (arr, size) {
        if (!arr) return [];
        let newArr = [];
        for (let i = 0; i < arr.length; i += size) {
            newArr.push(arr.slice(i, i + size));
        }
        return newArr;
    },
    can: function (arr, perm) {
        if (arr) {
            return !!arr.find(p => p.name === perm);
        } else {
            return false;
        }
    },
    capitalize: function(s) {
        if (typeof s !== 'string') return '';
        const stringParts = s.split('_')
        return stringParts.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
    },
    formatNumber: function(number, options = { noDecimals: false, forCapacities: false }) {
        if (number == null || number == undefined) return ''
        let finalNum = number;
        if (typeof number != 'number') finalNum = parseFloat(number)
        if (options.noDecimals) {
            return finalNum.toLocaleString(i18n.language)
        } else if (options.forCapacities) {
            return finalNum.toLocaleString(i18n.language, { maximumFractionDigits: 2 })
        } else {
            return finalNum.toLocaleString(i18n.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        }
    },
    convertUnits: function (value, initialUnit, newUnit) {
        switch (initialUnit) {
            case 'm': {
                if (newUnit == 'm') return value;
                else if (newUnit == 'mi') return value * 0.000621371;
                else if (newUnit == 'km') return value * 0.001;
            }
            case 'mi': {
                if (newUnit == 'm') return value * 1609.34;
                else if (newUnit == 'mi') return value;
                else if (newUnit == 'km') return value * 1.60934;
            }
            case 'km': {
                if (newUnit == 'm') return value * 1000;
                else if (newUnit == 'mi') return value * 0.621371;
                else if (newUnit == 'km') return value;
            }
        }
    },
    getInitials: function(firstName, lastName) {
        if (!firstName) return '';
        if (firstName && !lastName) return firstName[0].toUpperCase();
        return (firstName[0] + lastName[0]).toUpperCase();
    },
    convertTransactionTypeIDToName: function (transactionTypeID, job, options = { forSupplier: false, forCustomer: false, forDestination: false }) {
        if (transactionTypeID != null) {
            let transactionTypeName = '';
            switch (transactionTypeID) {
                case 1:
                    transactionTypeName = 'Load';
                    break;
                case 2:
                    transactionTypeName = 'Weight';
                    if (options.forSupplier) {
                        transactionTypeName = job.company_supplier_per_weight_job_units;
                    } else if (options.forDestination) {
                        transactionTypeName = job.demolition_destination_per_weight_job_units;
                    } else if (options.forCustomer) {
                        transactionTypeName = job.company_customer_per_weight_job_units;
                    } else {
                        transactionTypeName = job.per_weight_job_units;
                    }
                    break;
                case 3:
                    transactionTypeName = 'Hour';
                    break;
                case 4: 
                    transactionTypeName = 'Volume';
                    if (options.forSupplier) {
                        transactionTypeName = job.company_supplier_per_volume_job_units;
                    } else if (options.forDestination) {
                        transactionTypeName = job.demolition_destination_per_volume_job_units;
                    } else if (options.forCustomer) {
                        transactionTypeName = job.company_customer_per_weight_job_units;
                    } else {
                        transactionTypeName = job.per_volume_job_units;
                    }
                    break;
            }
            return transactionTypeName;
        }
    },
    formatPhoneNumber: function (number) {
        if (number && number.length == 10) {
            if (number.match(/^\d{3}\d{3}\d{4}$/)) {
                return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`
            }
        } else if (number && number.length == 11) {
            if (number.match(/^\d{1}\d{3}\d{3}\d{4}$/)) {
                return `+${number.slice(0, 1)} (${number.slice(1, 4)}) ${number.slice(4, 7)}-${number.slice(7, 11)}`
            }
        } else {
            return number
        }
    },
    makeDate: function (dateObject, timeString) {
        if (typeof (timeString) !== 'undefined') {
            return this.toMysqlFormat(dateObject) + ' ' + timeString + ':00';
        } else {
            return this.toMysqlFormat(dateObject);
        }
    },
    toMysqlFormat: function (date, options = { timeOnly: false, includeSeconds: false, dateOnly: false }) {
        let hour = date.getHours();
        let min = date.getMinutes();
        //Add the zero...
        if (min < 10) {
            min = '0' + min;
        }
        let finalTime = hour + ':' + min;
        if (options.includeSeconds) {
            finalTime += ':00'
        }

        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');

        if (options.timeOnly) {
            return finalTime;
        } else if (options.dateOnly) {
            return year + '-' + month + '-' + day;
        } else {
            return `${year}-${month}-${day} ${hour}:${min}:00`
        }
    },
    makeUnixTime: function(date) {
        if (Boolean(date.getTime)) {
            return Math.trunc(date.getTime() / 1000);
        } else {
            return Math.trunc(this.createDateFromUnix(date).getTime() / 1000);
        }
    },
    convertSecondsToTime: function (seconds) {
        const fraction = seconds / (60 * 60);
        const hour = Math.floor(fraction);
        const min = (seconds % 3600) / 60;
        const sec = (seconds % 3600) % 60;
        const finalTime = new Date(1970, 0, 1, hour, min, sec);
        return finalTime;
    },
    parseGoogleAddressComponents: function (addressComponents, useLongName) {
        let streetNumber = '';
        let unitedKingdomState = '';
        let unitedKingdomCity = '';
        let addressData = {
            address_line_1: '',
            city: '',
            province: '',
            postal_code: '',
            country: ''
        };
        if (!addressComponents) return addressData;
        addressComponents.forEach(component => {
            switch (component.types[0]) {
                case 'street_number':
                    streetNumber = useLongName ? component.long_name : component.short_name;
                    break;

                case 'route':
                    if (streetNumber) {
                        addressData.address_line_1 = streetNumber + ' ' + (useLongName ? component.long_name : component.short_name);
                    } else {
                        addressData.address_line_1 = useLongName ? component.long_name : component.short_name;
                    }
                    break;

                case 'locality': 
                case 'sublocality_level_1':
                case 'sublocality': 
                case 'neighborhood':
                    addressData.city = useLongName ? component.long_name : component.short_name;
                    break;
                
                case 'administrative_area_level_1':
                    addressData.province = useLongName ? component.long_name : component.short_name;
                    break;

                case 'postal_code':
                case 'postal_code_prefix':
                    addressData.postal_code = useLongName ? component.long_name : component.short_name;
                    break;

                case 'postal_town':
                    unitedKingdomState = useLongName ? component.long_name : component.short_name;
                    break;

                case 'country':
                    addressData.country = useLongName ? component.long_name : component.short_name;
                    break;
            }
        });
        return addressData;
    },
    getTransactionTypeDisplay: function(job) {
        switch (job.transaction_type.name) {
            case 'per-hour': {
                return i18n.t(`jobTranslations.transactionTypes.perHour`)
            }
            case 'per-load': {
                return i18n.t(`jobTranslations.transactionTypes.perLoad`);
            }
            case 'per-weight': {
                const string = job.per_weight_job_units ? `/ ${job.per_weight_job_units}` : i18n.t(`jobTranslations.transactionTypes.perWeight`)
                return string;
            }
            case 'per-volume': {
                const string = job.per_volume_job_units ? `/ ${job.per_volume_job_units}` : i18n.t(`jobTranslations.transactionTypes.perVolume`)
                return string;
            }
        }
    },
    getPricingDisplay: function(job, company, rate) {
        if (!job.transaction_type) return '';
        if (job.bid_amount_status == 'open' || job.is_bid_price_fixed == false) {
            switch (job.transaction_type.name) {
                case 'per-hour': {
                    return i18n.t(`jobTranslations.transactionTypes.per-hour`)
                }
                case 'per-load': {
                    return i18n.t(`jobTranslations.transactionTypes.per-load`);
                }
                case 'per-weight': {
                    return `${i18n.t(`jobTranslations.transactionTypes.per-weight`)} (${i18n.t(`unitTranslations.weightUnits.${job.per_weight_job_units}`)})`;
                }
                case 'per-volume': {
                    return `${i18n.t(`jobTranslations.transactionTypes.per-volume`)} (${i18n.t(`unitTranslations.volumeUnits.${job.per_volume_job_units}`)})`;
                }
                default: {
                    return i18n.t(`jobTranslations.bid.open`)
                }
            }
        } else if (job.bid_amount_status == 'prenegotiated_only' && rate) {
            return `${job.currency_object && job.currency_object.symbol_prefix}${this.formatNumber(rate.rate / 100)}${job.currency_object.symbol_suffix || ' ' + job.currency_object.code} ${this.getTransactionTypeDisplay(job)}`
        } else if (job.bid_amount_status == 'prenegotiated_only') {
            return i18n.t(`jobTranslations.bid.prenegotiated_only`)
        } else if ((job.bid_amount_status == 'fixed' || job.is_bid_price_fixed == true) && job.company_id == company.id) {
            return `${job.currency_object && job.currency_object.symbol_prefix}${this.formatNumber(job.amount / 100)}${job.currency_object.symbol_suffix || ' ' + job.currency_object.code} ${this.getTransactionTypeDisplay(job)}`
        } else if ((job.bid_amount_status == 'fixed' || job.is_bid_price_fixed == true) && job.company_id != company.id) {
            return `${job.currency_object && job.currency_object.symbol_prefix}${this.formatNumber((job.reduced_amount || job.amount) / 100)}${job.currency_object.symbol_suffix || ' ' + job.currency_object.code} ${this.getTransactionTypeDisplay(job)}`
        } else {
            return ''
        }
    },
    getBidPricingDisplay: function(job, bid, company) {

        return `${job.currency_object && job.currency_object.symbol_prefix}${this.formatNumber((bid.reduced_amount || bid.amount) / 100)}${job.currency_object.symbol_suffix || ' ' + job.currency_object.code} ${this.getTransactionTypeDisplay(job)}`

    },
    mergeDateAndTime: function (date, time) {
        const tempDate = new Date(date);
        const hour = time.getHours();
        const min = time.getMinutes();
        tempDate.setHours(hour);
        tempDate.setMinutes(min);
        return tempDate;

    },
    handleChange: function (that, field, type, singleNestedOptions) {
        if (!singleNestedOptions) {
            if (type) {
                if (type == 'boolean') {
                    return event => that.setStateIfMounted({ [field]: event.target.checked })
                } else if (type == 'number') {
                    return event => that.setStateIfMounted({ [field]: parseFloat(event.target.value) || "" })
                } else if (type == 'date') {
                    return date => that.setStateIfMounted({ [field]: date })
                } else if (type == 'time') {
                    return time => that.setStateIfMounted({ [field]: time })
                } else if (type == 'radio-boolean') {
                    return event => that.setStateIfMounted({ [field]: event.target.value == 'true' ? true : false })
                }
            }
            return event => that.setStateIfMounted({ [field]: event.target.value });
        } else if (singleNestedOptions) {
            if (type) {
                if (type == 'boolean') {
                    return event => that.setStateIfMounted({
                        ...that.state,
                        [singleNestedOptions.parent]: {
                            ...that.state[singleNestedOptions.parent],
                            [singleNestedOptions.child]: event.target.checked
                        }
                    })
                } else if (type == 'number') {
                    return event => that.setStateIfMounted({
                        ...that.state,
                        [singleNestedOptions.parent]: {
                            ...that.state[singleNestedOptions.parent],
                            [singleNestedOptions.child]: parseFloat(event.target.value) || ""
                        }
                    })
                } else if (type == 'date') {
                    return date => {
                        that.setStateIfMounted({
                            ...that.state,
                            [singleNestedOptions.parent]: {
                                ...that.state[singleNestedOptions.parent],
                                [singleNestedOptions.child]: date
                            }
                        })
                    }
                } else if (type == 'time') {
                    return time => {
                        that.setStateIfMounted({
                            ...that.state,
                            [singleNestedOptions.parent]: {
                                ...that.state[singleNestedOptions.parent],
                                [singleNestedOptions.child]: time
                            }
                        })
                    }
                }
            }
            return event => {
                that.setStateIfMounted({
                    ...that.state,
                    [singleNestedOptions.parent]: {
                        ...that.state[singleNestedOptions.parent],
                        [singleNestedOptions.child]: event.target.value
                    }
                })
            }
        }
    },
    setupHandleChange: function(context) {
        return (field, type, singleNestedOptions) => {
            if (!singleNestedOptions) {
                if (type) {
                    if (type == 'boolean') {
                        return event => context.setStateIfMounted({ [field]: event.target.checked })
                    } else if (type == 'number') {
                        return event => context.setStateIfMounted({ [field]: parseFloat(event.target.value) || "" })
                    } else if (type == 'date') {
                        return date => context.setStateIfMounted({ [field]: date })
                    } else if (type == 'time') {
                        return time => context.setStateIfMounted({ [field]: time })
                    } else if (type == 'radio-boolean') {
                        return event => context.setStateIfMounted({ [field]: event.target.value == 'true' ? true : false })
                    }
                    return event => context.setStateIfMounted({ [field]: event.target.value });
                }
                return event => context.setStateIfMounted({ [field]: event.target.value });
            } else if (singleNestedOptions) {
                if (type) {
                    if (type == 'boolean') {
                        return event => context.setStateIfMounted({
                            ...context.state,
                            [singleNestedOptions.parent]: {
                                ...context.state[singleNestedOptions.parent],
                                [singleNestedOptions.child]: event.target.checked
                            }
                        })
                    } else if (type == 'number') {
                        return event => context.setStateIfMounted({
                            ...context.state,
                            [singleNestedOptions.parent]: {
                                ...context.state[singleNestedOptions.parent],
                                [singleNestedOptions.child]: parseFloat(event.target.value) || ""
                            }
                        })
                    } else if (type == 'date') {
                        return date => {
                            context.setStateIfMounted({
                                ...context.state,
                                [singleNestedOptions.parent]: {
                                    ...context.state[singleNestedOptions.parent],
                                    [singleNestedOptions.child]: date
                                }
                            })
                        }
                    } else if (type == 'time') {
                        return time => {
                            context.setStateIfMounted({
                                ...context.state,
                                [singleNestedOptions.parent]: {
                                    ...context.state[singleNestedOptions.parent],
                                    [singleNestedOptions.child]: time
                                }
                            })
                        }
                    }
                }
                return event => context.setStateIfMounted({
                    ...context.state,
                    [singleNestedOptions.parent]: {
                        ...context.state[singleNestedOptions.parent],
                        [singleNestedOptions.child]: event.target.value
                    }
                });
            }
        }
    },
    usePrevious: function(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    },
    wait: function(sec) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve()
            }, sec * 1000);
        })
    },
    getDriverETA: function ({ origin, destination, departureTime }) {
        return new Promise((resolve, rej) => {
            if (window.google && window.google.maps) {
                const maps = window.google.maps
                const distanceMatrix = new maps.DistanceMatrixService()
                distanceMatrix.getDistanceMatrix({
                    origins: [new maps.LatLng(origin.latitude, origin.longitude)],
                    destinations: [new maps.LatLng(destination.latitude, destination.longitude)],
                    travelMode: "DRIVING"
                }, (results, status) => {
                    resolve({ status, results })
                })
            }
        })
    },
    getDetailedDeliveryStatus: function (delivery) {
        if (delivery.checkout) return 'completed';
        if (delivery.route.pick_up_scale_id) {
            if (delivery.checkin && !delivery.load_time_duration) return 'loading';
            if (delivery.load_time_duration && !delivery.gross_pounds) return 'awaitingWeighment';
            if (delivery.load_time_duration && !delivery.checkout) return 'driving';
            if (delivery.checkout && !delivery.unload_time_duration) return 'unloading';
        } else if (delivery.route.drop_off_scale_id) {
            if (delivery.checkin && !delivery.load_time_duration) return 'loading';
            if (delivery.load_time_duration && !delivery.gross_pounds) return 'awaitingWeighment';
            if (delivery.load_time_duration && !delivery.checkout) return 'driving';
            if (delivery.checkout && !delivery.unload_time_duration) return 'unloading';
        } else {
            if (delivery.checkin && !delivery.load_time_duration && !delivery.checkout) return 'loading';
            if (delivery.checkin && delivery.load_time_duration && !delivery.checkout) return 'driving';
            if (delivery.checkout && !delivery.unload_time_duration) return 'unloading';
        }
        return 'completed';
    },
    getDeliveryMetadata: function (delivery) {
        delivery.displayMaterials = delivery.materials.map(mat => mat.name ? mat.name : mat.custom_material_name).join(", ");
        if (delivery.load_time_duration) {
            const loadDate = functions.convertSecondsToTime(delivery.load_time_duration)
            delivery.loadDuration = `${loadDate.getHours()}h ${loadDate.getMinutes()}m ${loadDate.getSeconds()}s`;
        }
        if (delivery.unload_time_duration) {
            const unloadDate = functions.convertSecondsToTime(delivery.unload_time_duration)
            delivery.unloadDuration = `${unloadDate.getHours()}h ${unloadDate.getMinutes()}m ${unloadDate.getSeconds()}s`;
        }
        if (delivery.total_duration) {
            const durationDate = functions.convertSecondsToTime(delivery.total_duration)
            delivery.formattedTotalDuration = `${durationDate.getHours()}h ${durationDate.getMinutes()}m`;
        } else if (delivery.checkin && delivery.checkout) {
            const millisecondDiff = (new Date(delivery.checkout).getTime()  - new Date(delivery.checkin).getTime())
            const durationDate = functions.convertSecondsToTime(millisecondDiff / 1000)
            delivery.formattedTotalDuration = `${durationDate.getHours()}h ${durationDate.getMinutes()}m`;
        } else {
            delivery.formattedTotalDuration = null
        }

        delivery.detailedStatus = functions.getDetailedDeliveryStatus(delivery);

        if (delivery.original_gross_weight || delivery.original_net_weight) {
            delivery.isReweighment = true;
        }
        delivery.checkinDate = delivery.checkin ? functions.createDateFromUnix(delivery.checkin, { keepLocal: true }) : null
        delivery.checkoutDate = delivery.checkout ? functions.createDateFromUnix(delivery.checkout, { keepLocal: true }) : null
        return delivery
    },
    formatToReadableString: function(string) {
        return string.replace(/_/g, " ").toUpperCase()
    }
}
export default (state = functions, action) => state;

