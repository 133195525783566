import React from "react";
import styled from "styled-components";
import { NavLink as RouterNavLink } from "react-router-dom";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import TPLoaderGIF from '../../assets/videos/tp_loader.gif'
import TPLoaderGIFWhite from '../../assets/videos/tp_logo_animated_white.gif'
import TPLoaderGIFOrange from '../../assets/videos/tp_logo_animated_orange.gif'

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  LinearProgress as MuiLinearProgress,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton'

import { spacing } from "@material-ui/system";

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CircularProgress = styled(MuiCircularProgress)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 300px;

  ${props => props.theme.breakpoints.up("sm")} {

  }
  ${props => props.theme.breakpoints.up("md")} {

  }
  ${props => props.theme.breakpoints.up("lg")} {

  }
  ${props => props.theme.breakpoints.up("sm")} {

  }
`;
export function TPLoaderComponent({ theme = {} }) {
  switch (theme.currentTheme) {
    case 'high_contrast':
      return (<Root><img style={{ height: '260px' }} src={TPLoaderGIFWhite} /></Root>)
    default:
      return (<Root><img style={{ height: '260px' }} src={TPLoaderGIFOrange} /></Root>)
  }
}
const msp = state => ({
  theme: state.theme
})
export const TPLoader = connect(msp, null)(TPLoaderComponent)

function CircularIndeterminate({color}) {
  return (
    <Card mb={6}>
      <CardContent>
        <Paper mt={3}>
          <Grid container justify="center">
            <Grid item xs={12}>
              <Skeleton animation="wave" height={50}/>
            </Grid>
          </Grid>
        </Paper>
      </CardContent>
    </Card>
  );
}
export const ProgressCard = ({color}) => CircularIndeterminate(color)

class CircularDeterminate extends React.Component {
  state = {
    completed: 0
  };

  componentDidMount() {
    this.timer = setInterval(this.progress, 20);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  };

  render() {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Circular Determinate
          </Typography>
          <Paper mt={3}>
            <CircularProgress
              m={2}
              variant="determinate"
              value={this.state.completed}
            />
            <CircularProgress
              m={2}
              variant="determinate"
              value={this.state.completed}
              color="secondary"
            />
          </Paper>
        </CardContent>
      </Card>
    );
  }
}

function CircularStatic() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Circular Static
        </Typography>
        <Paper mt={3}>
          <CircularProgress m={2} variant="static" value={5} />
          <CircularProgress m={2} variant="static" value={25} />
          <CircularProgress m={2} variant="static" value={50} />
          <CircularProgress m={2} variant="static" value={75} />
          <CircularProgress m={2} variant="static" value={100} />
        </Paper>
      </CardContent>
    </Card>
  );
}

function LinearIndeterminate() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Linear Indeterminate
        </Typography>
        <Paper mt={3}>
          <LinearProgress my={2} />
          <LinearProgress my={2} color="secondary" />
        </Paper>
      </CardContent>
    </Card>
  );
}

class LinearDeterminate extends React.Component {
  state = {
    completed: 0
  };

  componentDidMount() {
    this.timer = setInterval(this.progress, 500);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  progress = () => {
    const { completed } = this.state;
    if (completed === 100) {
      this.setState({ completed: 0 });
    } else {
      const diff = Math.random() * 10;
      this.setState({ completed: Math.min(completed + diff, 100) });
    }
  };

  render() {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Linear Determinate
          </Typography>
          <Paper mt={3}>
            <LinearProgress
              my={2}
              variant="determinate"
              value={this.state.completed}
            />
            <LinearProgress
              my={2}
              variant="determinate"
              value={this.state.completed}
              color="secondary"
            />
          </Paper>
        </CardContent>
      </Card>
    );
  }
}

class LinearBuffer extends React.Component {
  state = {
    completed: 0,
    buffer: 10
  };

  componentDidMount() {
    this.timer = setInterval(this.progress, 500);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  progress = () => {
    const { completed } = this.state;
    if (completed > 100) {
      this.setState({ completed: 0, buffer: 10 });
    } else {
      const diff = Math.random() * 10;
      const diff2 = Math.random() * 10;
      this.setState({
        completed: completed + diff,
        buffer: completed + diff + diff2
      });
    }
  };

  render() {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Linear Buffer
          </Typography>
          <Paper mt={3}>
            <LinearProgress
              my={2}
              variant="buffer"
              value={this.state.completed}
              valueBuffer={this.state.buffer}
            />
            <LinearProgress
              my={2}
              variant="buffer"
              value={this.state.completed}
              valueBuffer={this.state.buffer}
              color="secondary"
            />
          </Paper>
        </CardContent>
      </Card>
    );
  }
}

function LinearQuery() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Linear Query
        </Typography>
        <Paper mt={3}>
          <LinearProgress my={2} variant="query" />
          <LinearProgress my={2} variant="query" color="secondary" />
        </Paper>
      </CardContent>
    </Card>
  );
}

function Progress() {
  return (
    <React.Fragment>
      <Helmet title="Progress" />
      <Typography variant="h3" gutterBottom display="inline">
        Progress
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/">
          Components
        </Link>
        <Typography>Progress</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <CircularIndeterminate />
          <CircularDeterminate />
          <CircularStatic />
        </Grid>
        <Grid item xs={12} md={6}>
          <LinearIndeterminate />
          <LinearDeterminate />
          <LinearBuffer />
          <LinearQuery />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Progress;
