import React, { useEffect, useState } from "react";
import { DeliveryApi, DeliveryTrackApi } from "../../../../redux/reducers/entities";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";
import { GMapAPIKey } from '../../../../env';
import { useTranslation } from "react-i18next";
import { useRootSelector } from "../../../..";
import { getAllTracksForDelivery } from "../../../../redux/util/deliveryAPI";
import TPTruckIcon from "../../Util/TPTruckIcon";

const GoogleMapReactWrapper = styled.div`
  height: 400px;
  width: 100%;
`;




interface Props {
    delivery?: DeliveryApi
}
enum PathTypes {
    MAIN = "main",
    RETURN = "return"
}
enum MarkerTypes {
    START = "start",
    END = "end",
    RETURN = "return",
    TRUCK = "truck",
}

const DeliveryTrackingMap = ({
    delivery
}: Props) => {
    const { t } = useTranslation()
    const token = useRootSelector(state => state.session.user.token!!)
    const company = useRootSelector(state => state.session.company)


    // States for Google Maps references
    const [mapRefs, setMapRefs] = useState<{ map: any, maps: any }>({
        map: null,
        maps: null
    })
    const [polylines, setPolylines] = useState<Partial<{ [key in PathTypes]: any }>>({})
    const [markers, setMarkers] = useState<Partial<{ [key in MarkerTypes]: any }>>({})
    const [truckIcon, setTruckIcon] = useState<TPTruckIcon>()



    // Start things here that depend on the Google API
    useEffect(() => {
        if (mapRefs.maps && delivery?.id) {
            console.log("GETTING PRIMARY TRACKS")
            getPrimaryTracks()
        }
        console.log(mapRefs, delivery)
    }, [mapRefs, delivery])

    useEffect(() => {
        adjustMapCenter()
    }, [markers])


    // Get all of the primary tracks of the delivery
    const getPrimaryTracks = async () => {

        const response = await getAllTracksForDelivery(token, delivery!.id) 
        if (!response.success || !response.data.length) return;

        createPolyline(response.data)
    }

    // Create a polyline that follows the tracks
    const createPolyline = (tracks: DeliveryTrackApi[], pathType: PathTypes = PathTypes.MAIN) => {

        // Polyline requires an array of Google LatLngs
        const deliveryCoords: any[] = tracks.map(track => new mapRefs.maps.LatLng(track.latitude, track.longitude))

        // Create the polyline 
        const strokeColor = pathType === PathTypes.MAIN ? "tomato" : "limegreen";
        const polylineKey = pathType
        const newPolylines = { ...polylines }
        newPolylines[polylineKey] = new mapRefs.maps.Polyline({
            path: deliveryCoords,
            geodesic: true,
            strokeColor,
            strokeOpacity: 1.0,
            strokeWeight: 4
        })

        // Put the polyline on the map
        newPolylines[polylineKey].setMap(mapRefs.map)

        setPolylines(newPolylines)
        addMarkers(deliveryCoords)
    }

    // Creates/updates markers on the map
    const addMarkers = (deliveryLatLngs: any[], pathType: PathTypes = PathTypes.MAIN) => {
        const newMarkers = { ...markers }
        if (pathType === PathTypes.MAIN) {


            // Set the start marker
            if (newMarkers[MarkerTypes.START]) {
                removeMarker(MarkerTypes.START)
            }
            newMarkers.start = new mapRefs.maps.Marker({
                map: mapRefs.map,
                position: deliveryLatLngs[0],
                icon: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
            })


            if (delivery!.in_progress) {
                // Add/update the in progress truck icon
                const latestPosition = deliveryLatLngs[deliveryLatLngs.length - 1]
                const secondToLatestPosition = deliveryLatLngs[deliveryLatLngs.length - 2] || latestPosition
                updateTruckIcon(secondToLatestPosition, latestPosition)

            } else {
                // Set the end marker
                if (newMarkers[MarkerTypes.END]) {
                    removeMarker(MarkerTypes.END)
                }
                newMarkers.end = new mapRefs.maps.Marker({
                    map: mapRefs.map,
                    position: deliveryLatLngs[0],
                    icon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
                })
                setMarkers(newMarkers)
                adjustMapCenter()
            }
        } else {

        }
    }

    const updateTruckIcon = (firstLatLng: any, secLatLng: any) => {
        if (markers.truck) {

            // Update the truck icon
            const heading = mapRefs.maps.geometry.spherical.computeHeading(firstLatLng, secLatLng)
            markers.truck.setIcon(truckIcon!.setRotation({ deg: heading }).getUrl());
            markers.truck.setPosition(secLatLng)

        } else {

            // Create the truck icon
            const icon = new TPTruckIcon({ is_returning: false }).setRotation({ deg: 0 })
            setTruckIcon(icon)

            const newMarkers = { ...markers }
            newMarkers.truck = new mapRefs.maps.Marker({
                position: secLatLng,
                map: mapRefs.map,
                anchor: new mapRefs.maps.Point(26, 26),
                icon: icon.getUrl()
            });

            adjustMapCenter()
        }

    }

    const adjustMapCenter = () => {
        const markerObjects = Object.values(markers)
        if (mapRefs.maps && markerObjects.length) {
            console.log("ADJUSTING CENTER", mapRefs, markers)
            const bounds = new mapRefs.maps.LatLngBounds();
            markerObjects.forEach(marker => {
                bounds.extend(marker.position)
            })
            mapRefs.map.fitBounds(bounds);
        }
    }

    const removeMarker = (markerType: MarkerTypes) => {
        if (!markerType) return;
        const newMarkers = { ...markers }

        if (!newMarkers[markerType]) return;
        newMarkers[markerType].setMap(null)
        setMarkers(newMarkers)
    }

    return <GoogleMapReactWrapper>
        <GoogleMapReact
            options={(maps) => ({
                fullscreenControl: true,
                mapTypeControl: true,
                mapTypeId: maps.MapTypeId.ROADMAP,
                scaleControl: true,
                scrollwheel: false,
                streetViewControl: true
            })}
            bootstrapURLKeys={{
                key: GMapAPIKey
            }}
            defaultCenter={{ lat: delivery?.checkin_latitude || company.address?.latitude || 40.75027, lng: delivery?.checkin_longitude || company.address?.longitude || -73.8542755 }}
            defaultZoom={16}
            yesIWantToUseGoogleMapApiInternals
            layerTypes={['TrafficLayer']}
            onGoogleApiLoaded={({ map, maps }) => {
                setMapRefs({ map, maps })
            }}
        />
    </GoogleMapReactWrapper>
}
export default DeliveryTrackingMap