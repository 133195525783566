import React from 'react';
import {
    Modal,
    Paper as MuiPaper,
    Grid as MuiGrid,
    GridSize,
    Box as MuiBox,
    Typography,
    IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import NotificationSnackbar from './Snackbar';
import { TPLoader } from '../../../components/Loader';

const Paper = styled(MuiPaper)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Box = styled(MuiBox)(spacing);

interface HeaderOptions {
    superHeaderText?: string;
    superHeaderColor?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error',
    headerText?: string;
    subHeaderText?: string;
    subHeaderColor?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error',
}
interface BodyOptions {
    noPadding?: boolean;
}
interface TPModalProps {
    open?: boolean;
    children?: any;
    xs?: GridSize;
    sm?: GridSize;
    md?: GridSize;
    lg?: GridSize;
    setModalOpen?: (bool: boolean) => void;
    style?: any;
    headerOptions?: HeaderOptions;
    bodyOptions?: BodyOptions;
    loading?: boolean;
}

export default function TPModal({ 
    open = true, 
    children, 
    xs = 12, sm = 9, md = 7, lg = 5, 
    setModalOpen = () => { console.log('unset setmodalopen') },
    headerOptions = {
        superHeaderText: "",
        superHeaderColor: "initial",
        headerText: "",
        subHeaderText: "",
        subHeaderColor: "initial",
    },
    bodyOptions,
    loading = false,
    ...props 
}: TPModalProps) {
    const GridContainerBackgroundID = "TPModal-Grid-Container"

    const handleGridClick = (e: any) => {
        e.stopPropagation()
        if (e.target?.id === GridContainerBackgroundID) {
            setModalOpen(false)
        }
    }
    return (
        <Modal 
            open={open}
            style={{ overflowY: 'scroll' }}
            {...props}
            onBackdropClick={() => setModalOpen(false)}
            onClose={() =>setModalOpen(false)}
        >
            <Grid container spacing={10} direction="row" justify="center" alignItems="center" id={GridContainerBackgroundID} onClick={handleGridClick}>
                <Grid item xs={xs} sm={sm} md={md} lg={lg}>
                    <Paper m={6} variant="outlined">
                        {!!headerOptions?.headerText && (
                            <Box borderBottom={1} borderColor="grey.500">
                                <Grid container spacing={10} style={{ width: '100%', margin: 0, backgroundColor: "rgba(0,0,0,0.05)" }} direction="row" justify="space-between" alignItems="center">
                                    <Grid item>
                                        {!!headerOptions.superHeaderText && <Typography variant="h5" color={headerOptions.superHeaderColor}>{headerOptions.superHeaderText}</Typography>}
                                        <Typography variant="h3">{headerOptions.headerText}</Typography>
                                        {!!headerOptions.subHeaderText && <Typography variant="h6" color={headerOptions.subHeaderColor}>{headerOptions.subHeaderText}</Typography>}
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={() => setModalOpen(false)} size="small">
                                            <CloseIcon color="action" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                        {loading ? 
                            <Grid container spacing={10} alignItems="center" justify="center">
                                <Grid item>
                                    <TPLoader />
                                </Grid>
                            </Grid>
                        : 
                            <Box p={bodyOptions?.noPadding ? 0 : 3 }>
                                {children}
                            </Box>
                        }
                    </Paper>
                </Grid>
                <NotificationSnackbar />
            </Grid>
        </Modal>
    )
}
