import { AppBar, Tab, Tabs, Toolbar } from "@material-ui/core";
import { TabContext } from "@material-ui/lab";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useRootSelector } from "../../../..";
import { getAllCustomMaterials } from "../../../../redux/actions/materialActions";
import TabPanel from "../../Util/TabPanel";
import TPModal from "../../Util/TPModal";
import AddCustomMaterialManual from "./components/CustomMaterialManual";
import AddCustomMaterialUpload from "./components/CustomMaterialUpload";
import { CustomMaterial } from "../../../../redux/reducers/entities";

interface Props {
    listName?: string;
    setModalOpen: (bool: boolean) => void;


    customMaterial?: CustomMaterial;

    // unused?
    refreshMaterials?: () => void;
    enableListNameChange: boolean;
}

/**
 * 
 * __Props__
 * - listName?: string
 * 
 * - customMaterial?: string
 */
const AddEditCustomMaterialModal = ({ 
    listName, 
    setModalOpen, 
    enableListNameChange,
    customMaterial
}: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const token = useRootSelector(state => state.session.user.token!)
    const companyID = useRootSelector(state => state.session.company.id!)

    const [tabValue, setTabValue] = useState("0")
    const [loading, setLoading] = useState(false)

    const refreshAllMaterials = () => {
        dispatch(getAllCustomMaterials(token, companyID))
    }

    return (
        <TPModal
            setModalOpen={setModalOpen}
            headerOptions={{
                headerText: listName ? t('materialTranslations.actions.createCustom') : t('materialTranslations.actions.createNewList')
            }}
            bodyOptions={{
                noPadding: true
            }}
            loading={loading}
        >
            <TabContext value={tabValue}>
                {!customMaterial && <AppBar color="default" position="static">
                    <Toolbar>
                        <Tabs
                            variant="scrollable"
                            indicatorColor="primary"
                            scrollButtons="auto"
                            value={tabValue}
                            onChange={(event, tabVal) => setTabValue(tabVal)}
                        >
                            <Tab label={"Manual"} value="0" id="0" />
                            <Tab label={"Upload"} value="1" id="1" />
                        </Tabs>
                    </Toolbar>
                </AppBar>}
                <TabPanel value={tabValue} index="0" boxProps={{ p: 3 }}>
                    <AddCustomMaterialManual 
                        setModalOpen={setModalOpen}
                        listName={listName}
                        refreshAllMaterials={refreshAllMaterials}
                        enableListNameChange={enableListNameChange || false}
                        setLoading={setLoading}
                        customMaterial={customMaterial}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index="1" boxProps={{ p: 3 }}>
                    <AddCustomMaterialUpload
                        setModalOpen={setModalOpen}
                        refreshAllMaterials={refreshAllMaterials}
                        setLoading={setLoading}
                    />
                </TabPanel>
            </TabContext>
        </TPModal>
        
                   
    )
}
export default AddEditCustomMaterialModal