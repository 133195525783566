import * as SiteAPI from '../util/siteAPI';

import { functions } from '../reducers/helpersReducer';
export const RECEIVE_SITES = "RECEIVE_SITES";
export const RECEIVE_SITE = "RECEIVE_SITE";

export const receiveSites = (sites) => ({
    type: RECEIVE_SITES,
    sites
})
export const receiveSite = (site) => ({
    type: RECEIVE_SITE,
    site
})

export const getCompanySites = (token, companyID) => dispatch => {
    return SiteAPI.getCompanySites(token, companyID).then(
        (siteResponse) => {
            if (siteResponse.success) {
                dispatch(receiveSites(siteResponse.data))
            }
            return siteResponse
        }
    )
}
export const getSpecificSite = (token, siteID) => dispatch => {
    return SiteAPI.getSite(token, siteID).then(
        (siteResponse) => {
            if (siteResponse.success) {
                dispatch(receiveSite(siteResponse.data))
            }
            return siteResponse
        }
    )
}

