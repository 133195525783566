import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { Provider, TypedUseSelectorHook, useSelector } from 'react-redux';
import configureStore from './redux/store/index';

const preloadedState = { 
  session: {
    user: {},
    company: {},
    loading: false,
    alerts: {
      alert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: 'success'
    },
    notificationCount: 0,
    isOnDuty: false,
  } 
};
if (window.localStorage.getItem("TPUser")) {
  preloadedState.session.user = JSON.parse(window.localStorage.getItem("TPUser") || "");
}
const storageKey = `${process.env.REACT_APP_ENV || process.env.NODE_ENV}-TPCompany`
if (window.localStorage.getItem(storageKey)) {
  const company = JSON.parse(window.localStorage.getItem(storageKey) || "")
  preloadedState.session.company = company;
} else if (window.localStorage.getItem('TPCompany')) {
  const company = JSON.parse(window.localStorage.getItem('TPCompany') || "")
  preloadedState.session.company = company;
}

const store = configureStore(preloadedState);
console.log("initial store", store.getState())

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export const useRootSelector: TypedUseSelectorHook<RootState> = useSelector
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, document.getElementById('root'));
