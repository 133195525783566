import { Button, FormControl, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../..";
import { popAlert } from "../../../../redux/actions/sessionActions";
import { MixBatch } from "../../../../redux/reducers/entities";
import { parseError } from "../../../../redux/reducers/helpersReducerr";
import { getAllCompanyMaterialMixBatches, setDeliveryMaterialMixBatch } from "../../../../redux/util/mixAPI";
import TPModal from "../../Util/TPModal";

interface Props {
    setModalOpen: (bool: boolean) => void;
    onSetMixBatch?: () => void;

    deliveryID: number;
}

const SetMixBatchForDeliveryModal = ({
    setModalOpen, 
    onSetMixBatch,
    deliveryID
}: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.session.user.token!)
    const companyID = useSelector((state: RootState) => state.session.company.id!)
    const [loading, setLoading] = useState(false)

    const [searchBatches, setSearchBatches] = useState("")
    const [batches, setBatches] = useState<MixBatch[]>([])
    const [selectedBatchID, setSelectedBatchID] = useState(0)

    const getAllBatches = async () => {
        const response = await getAllCompanyMaterialMixBatches(token, companyID)
        if (!response.success) return;

        setBatches(response.data)
    }

    useEffect(() => {
        getAllBatches()
    }, [])

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setLoading(true)

        if (!selectedBatchID) {
            dispatch(popAlert('error', t('error'), "Select a mix batch"))
            setLoading(false)
            return;
        }
        const response = await setDeliveryMaterialMixBatch(token, companyID, deliveryID, selectedBatchID)
        if (!response.success) {
            const errorMessage = parseError(response)
            dispatch(popAlert("error", t("error"), errorMessage))
            setLoading(false)
            return;
        }
        dispatch(popAlert("success", t("success"), "Set batch material for delivery!"))
        onSetMixBatch?.()
        setModalOpen(false)
    }

    return <TPModal
        loading={loading}
        setModalOpen={setModalOpen}
        headerOptions={{
            headerText: "Set Mix Batch"
        }}
    >
        <form onSubmit={handleSubmit}>
            <Grid container spacing={10}>
                <Grid item xs>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="design_autocomplete"
                            disableClearable
                            autoComplete
                            clearOnBlur={false}
                            options={batches}
                            getOptionLabel={(option) => `${option.batch_ticket_number}`}
                            onChange={(event, val) => setSelectedBatchID(val.id!) }
                            onInputChange={(event, val, reason) => {
                                if (reason != 'reset' || val) {
                                    setSearchBatches(val)
                                }
                            }}
                            inputValue={searchBatches}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id="searchBatches"
                                    label={`Search Batches by Ticket Number`}
                                    type="text"
                                    required
                                    error={!selectedBatchID}
                                    variant="outlined"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        {t('actions.submit')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    </TPModal>

}

export default SetMixBatchForDeliveryModal