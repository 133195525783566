import { TPAPIBase } from "../../env"
import i18n from "../../i18n"
import { Company, Inspection, } from "../reducers/entities"
import { Employee, User } from "../reducers/entities/User";
import { formatParams, getDateTimeFromUnix } from "../reducers/helpersReducerr";
import { PaginatedAPIOptions, Paginate, TPResponse } from "./util"

export interface GetCompanyInspectionsParams extends PaginatedAPIOptions {
    token: string;
    companyId: number;
    delivery_ids?: number[];
    job_ids?: number[];
    created_after?: string;
    created_before?: string;
    inspection_location?: "pickup" | "dropoff";
    inspector_company_ids?: number[];
    inspected_company_ids?: number[];
    inspector_user_ids?: number[];
    inspector_ids?: number[];
}
/**
 * `Get Company Inspections` API
 * 
 */
export const getCompanyInspections = ({
    token,
    companyId,
    ...params
}: GetCompanyInspectionsParams): Promise<TPResponse<Paginate<Inspection[]>>> => {
    const formattedParams = formatParams(params)
    return fetch(`${TPAPIBase}/company/${companyId}/inspections?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then((res: TPResponse<Paginate<Inspection[]>>) => {
        if (res.success) {
            res.data.data = res.data.data.map(inspection => {
                inspection.displayDate = getDateTimeFromUnix(inspection.created_at)
                return inspection
            })
        }
        return res
    })
}


export interface GetCompanyInspectionsWithValuesParams {
    token: string;
    companyId: number;
    inspectionId: number;
}
/**
 * `Get Company Inspection With Values` API
 * 
 */
export const getCompanyInspectionWithValues = ({
    token,
    companyId,
    inspectionId,
}: GetCompanyInspectionsWithValuesParams): Promise<TPResponse<Inspection>> => {
    return fetch(`${TPAPIBase}/company/${companyId}/inspections/${inspectionId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}


export interface GetAllCompanyEmployeesParams {
    token: string;
    companyId: number;
    role_ids?: number[];
    position_ids?: number[];
}
/**
 * `Get All Company Employees` API
 */
export const getAllCompanyEmployees = ({
    token,
    companyId,
    ...params
}: GetAllCompanyEmployeesParams): Promise<TPResponse<Employee[]>> => {
    const formattedParams = formatParams(params)
    return fetch(`${TPAPIBase}/company/${companyId}/employees?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}


export interface GetInspectedCompaniesParams extends PaginatedAPIOptions {
    token: string;
    companyId: number;
    delivery_ids?: number[];
    job_ids?: number[];
    created_after?: string;
    created_before?: string;
    inspection_location?: "pickup" | "dropoff";
    inspector_user_ids?: number[];
    inspector_ids?: number[];
    return_only_ids?: number;
}
/**
 * `Get Companies That My Company Has Inspected` API
 * 
 */
export const getInspectedCompanies = ({
    token,
    companyId,
    ...params
}: GetInspectedCompaniesParams): Promise<TPResponse<Paginate<Company[]>>> => {
    const formattedParams = formatParams(params)
    return fetch(`${TPAPIBase}/company/${companyId}/inspections/companies?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then((res: TPResponse<Paginate<Company[]>>) => {
        // if (res.success) {
        //     res.data.data = res.data.data.map(inspection => {
        //         inspection.displayDate = getDateTimeFromUnix(inspection.created_at)
        //         return inspection
        //     })
        // }
        return res
    })
}
/**
 * Wrapper for the `Get Companies That My Company Has Inspected` API.\
 * Fetches every page and combines the data.
 * 
 * @see {@link getInspectedCompanies}
 */
export const getAllInspectedCompanies = async (params: GetInspectedCompaniesParams): Promise<Company[]> => {
    let page = 1
    let fetchedAll = false
    const companies = []
    while (!fetchedAll) {
        const response = await getInspectedCompanies({ ...params, page })
        if (!response.success) break;
        companies.push(...response.data.data)
        if (!response.data.next_page_url) break;
        page++
    }
    return companies
}

interface GetRolesAndPermissionsForCompanyEmployeeOptions {
    token: string;
    companyId: number;
    employeeId: number;
}
/**
 * `Get Roles and Permissions For Company Employee` API
 */
export const getRolesAndPermissionsForCompanyEmployee = ({
    token,
    companyId,
    employeeId
}: GetRolesAndPermissionsForCompanyEmployeeOptions): Promise<TPResponse<User>> => {
    return fetch(`${TPAPIBase}/company/${companyId}/roles/${employeeId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}