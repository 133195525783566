import { TPAPIBase } from '../../env';
import i18n from '../../i18n';
import { Company, DeliveryApi, DeliveryTrackApi, DistributiveOmit, JobQuery } from '../reducers/entities';
import { formatParams, getDeliveryMetadata, parseError } from '../reducers/helpersReducerr';
import { Paginate, PaginatedAPIOptions, TPResponse } from './util';

export const getRelevantDeliveries = (token: string, company: Company, params: { key: string, value: any }[] = []) => {
    const formattedParams = params.map(param => `${param.key}=${param.value}`).join("&")
    return fetch(`${TPAPIBase}/company/${company.id}/relevant_deliveries?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then((res: TPResponse<DeliveryApi[]>) => {
        if (res.success) {
            res.data = res.data.map(getDeliveryMetadata)
        }
        return res;
    })
}
export const getTracksForDelivery = (token: string, deliveryID: number, pageNumber: number): Promise<TPResponse<Paginate<DeliveryTrackApi[]>>> => (
    fetch(`${TPAPIBase}/delivery/${deliveryID}/tracks?page=${pageNumber}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getAllTracksForDelivery = async (token: string, deliveryID: number): Promise<TPResponse<DeliveryTrackApi[]>> => {
    const tracks: DeliveryTrackApi[] = []
    let page = 1
    let fetchedAll = false
    while (!fetchedAll) {
        const response = await getTracksForDelivery(token, deliveryID, page)
        if (!response?.success) break;
        tracks.push(...response.data.data)
        if (response.data.next_page_url) {
            page += 1
        } else {
            fetchedAll = true
        }
    }
    return {
        success: true,
        error: null,
        data: tracks
    }
}
export const getReturnTracksForDelivery = (token: string, deliveryID: number, pageNumber: number) => (
    fetch(`${TPAPIBase}/delivery/${deliveryID}/return-tracks?page=${pageNumber}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const deliveryCheckIn = (token: string, formData: FormData) => (
    fetch(`${TPAPIBase}/jobs/selfcheckin`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: formData
    }).then(response => response.json())
)
export const deliveryCheckOut = (token: string, formData: FormData) => (
    fetch(`${TPAPIBase}/jobs/selfcheckout`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: formData
    }).then(response => response.json())
)
export const confirmLoad = (token: string, companyID: number, data: any) => (
    fetch(`${TPAPIBase}/company/${companyID}/confirm_load`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const getMyDeliveries = (token: string, jobID: number, pageNumber = 1) => (
    fetch(`${TPAPIBase}/my_deliveries?page=${pageNumber}&job_ids[]=${jobID}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getBatchMaterialForDelivery = (token: string, companyID: number, deliveryID: number) => (
    fetch(`${TPAPIBase}/company/${companyID}/delivery/${deliveryID}/batch_material`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const setBatchMaterialForDelivery = (token: string, companyID: number, deliveryID: number, batchMaterialID: number) => (
    fetch(`${TPAPIBase}/company/${companyID}/delivery/${deliveryID}/batch_material/${batchMaterialID}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getDeliveryDetails = (token: string, companyID: number, deliveryID: number): Promise<TPResponse<DeliveryApi>> => (
    fetch(`${TPAPIBase}/company/${companyID}/delivery/${deliveryID}/details`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then((res: TPResponse<DeliveryApi>) => {
        if (res.data) {
            res.data = getDeliveryMetadata(res.data)
        }
        return res
    })
)
export const voidOrUnvoidDelivery = (token: string, companyID: number, deliveryID: number, isVoided: 0 | 1): Promise<TPResponse<DeliveryApi>> => (
    fetch(`${TPAPIBase}/company/${companyID}/delivery/${deliveryID}/is_voided/${isVoided}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)


export const getDeliveriesOfJobsByQuery = (token: string, companyID: number, query: DistributiveOmit<JobQuery, "type">, options: PaginatedAPIOptions = {}): Promise<TPResponse<Paginate<DeliveryApi[]>>> => {
    const formattedParams = formatParams(options)
    return fetch(`${TPAPIBase}/company/${companyID}/deliveries/query?${formattedParams}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: {
                query
            }
        })
    }).then(response => response.json()).then((res: TPResponse<Paginate<DeliveryApi[]>>) => {
        const tempRes = { ...res };
        if (res.success && res.data && res.data.data.length) {
            tempRes.data.data = res.data.data.map(getDeliveryMetadata)
        }
        return tempRes;
    })
}
export interface ExportDeliveriesPayload {
    action: "email" | "download";
    job_ids?: number[];
    delivery_ids?: number[];
    include_inspections: boolean;
    start_date?: string;
    end_date?: string;
    email_addresses?: string[];
    export_format: "xlsx" | "pdf" | "csv";
}
interface ExportDeliveriesOptions {
    token: string;
    companyId: number;
    data: ExportDeliveriesPayload
}
export const exportDeliveries = ({
    token,
    companyId,
    data
}: ExportDeliveriesOptions): Promise<TPResponse<string>> => (
    fetch(`${TPAPIBase}/company/${companyId}/export/deliveries`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
            'Content-Type': "application/json"
        },
        body: JSON.stringify({ data })
    }).then(response => response.json())
)
interface GetRelevantDeliveries2Params {
    token: string;
    companyId: number;
    options?: {
        start?: number;
        end?: number;
        driver_id?: number;
        job_ids?: number[];
    }
}
export const getRelevantDeliveries2 = ({
    token,
    companyId,
    options = {}
}: GetRelevantDeliveries2Params) => {
    const formattedParams = formatParams(options)
    return fetch(`${TPAPIBase}/company/${companyId}/relevant_deliveries?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then((res: TPResponse<DeliveryApi[]>) => {
        if (res.success) {
            res.data = res.data.map(getDeliveryMetadata)
        }
        return res;
    })
}


/**
 * Wrapper for `Cancel Delivery` API
 */
export const cancelDelivery = async ({
    token,
    companyId,
    jobId,
    deliveryId,
}: {
    token: string;
    companyId: number;
    jobId: number;
    deliveryId: number;
}) => {
    const response: TPResponse<boolean> = await fetch(`${TPAPIBase}/company/${companyId}/job/${jobId}/delivery/${deliveryId}/cancel`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())

    if (!response.success) throw new Error(parseError(response))

    return response.data
}