import React, { useState, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Modal,
    Tab,
    Tabs,
    TabContent,
    TabScrollButton,
    TabList,
    Paper as MuiPaper,
    AppBar,
    Toolbar,
    Typography as MuiTypography,
    Grid as MuiGrid,
    FormGroup,
    FormControl as MuiFormControl,
    FormControlLabel,
    FormLabel,
    FormHelperText,
    Input,
    InputLabel,
    Select,
    TextField,
    MenuItem,
    Button,
    Switch,
    Radio,
    RadioGroup,
    Divider as MuiDivider,
    InputAdornment,
    Box,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Close as CloseIcon } from '@material-ui/icons';

import {
    KeyboardDatePicker,
    KeyboardTimePicker,
    KeyboardDateTimePicker
} from "@material-ui/pickers";

import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { toMysqlFormat } from '../../../../redux/reducers/helpersReducerr';


const Paper = styled(MuiPaper)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Bold = styled.span`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

/*
    "Ordered By": string
    "Sales Order Number": string
    "Day/Night": string
    "Pickup/Delivery": string
    "Load Time": time
    "On Job Time": time
    "Qty Per Hour": decimal

    "extended_attribute_values": {
        "Day/Night": "Day",
        "Ordered By": "The Orderer",
        "Sales Order Number": "7894561",
        "Pickup/Delivery": "Pickup",
        "Load Time": "01:23:00",
        "On Job Time": "02:34:00",
        "Qty Per Hour": 2
    },
*/


function ExtendedAttributesPartialRMHMA({ setExtendedAttributeValues, existingExtendedAttributeValues, existingExtendedAttributeValuesMap, preferredDrivers, setState, specificHaulers = [], type, jobState }) {
    const today = new Date()
    const { t } = useTranslation()
    const helpers = useSelector(state => state.helpers)
    const { default_weight_unit } = useSelector(state => state.session.user.settings)
    const validUnits = useSelector(state => state.entities.units)
    const [isWeightQty, setIsWeightQty] = useState(jobState.transaction_type_id === 2 || false)
    const [useCompanyDrivers, setUseCompanyDrivers] = useState(jobState.privacy_status === "private" || false)

    useEffect(() => {
        setUseCompanyDrivers(jobState.privacy_status == "private")
    }, [jobState.privacy_status])
    useEffect(() => {
        if (isWeightQty) {
            setState({
                transaction_type_id: 2
            })
        } else {
            setState({
                transaction_type_id: 4
            })
        }
    }, [isWeightQty])
    useEffect(() => {

        // bid_amount_status must be set unless privacy_status == 'private'
        // open fixed preneg
        console.log("setting company drivers");
        if (useCompanyDrivers) {
            setState({
                privacy_status: "private",
            })
        } else {
            setState({
                // privacy_status: "exclusive",
                bid_amount_status: "fixed"
            })
        }
    }, [useCompanyDrivers])
    // const specificHaulerID = existingExtendedAttributeValues ? existingExtendedAttributeValues.find(attr => attr.name === 'Specific Hauler ID')?.value || "" : ""
    // let specificHauler = {}
    // if (specificHaulerID) {
    //     specificHauler = preferredDrivers.find(driver => driver.identifier == specificHaulerID)
    // }
    // load up standard fields

    let [
        customerID,
        orderedBy, 
        salesOrderNumber, 
        dayOrNight, 
        pickupOrDelivery, 
        loadTime, 
        onJobTime, 
        qtyPerHour, 
        shippingDateTime,
    ] = useMemo(() => (
        [
            { key: 'Customer ID', default: '', type: 'string' }, 
            { key: 'Ordered By', default: '', type: 'string' }, 
            { key: 'Sales Order Number', default: '', type: 'string' }, 
            { key: 'Day/Night', default: 'Day', type: 'string' }, 
            { key: 'Pickup/Delivery', default: 'Pickup', type: 'string' }, 
            { key: 'Load Time', default: new Date(), type: 'time' }, 
            { key: 'On Job Time', default: new Date(), type: 'time' },
            { key: 'Qty Per Hour', default: 1, type: 'decimal' },
            { key: 'Shipping Date and Time', default: new Date(), type: 'datetime' }
        ].map(attr => {
            // already made changes
            if (existingExtendedAttributeValuesMap[attr.key]) {
                switch(attr.type) {
                    case 'time':
                        return helpers.getDateFromTime(existingExtendedAttributeValuesMap[attr.key])
                    case 'datetime': 
                        return helpers.createDateFromUnix(existingExtendedAttributeValuesMap[attr.key], { keepLocal: true })
                    default: 
                        return existingExtendedAttributeValuesMap[attr.key]
                }
            } 
            
            // initializing from exiting job attributes
            else if (existingExtendedAttributeValues.find(eav => eav.name === attr.key)) {
                const attrVal = existingExtendedAttributeValues.find(eav => eav.name === attr.key).value
                switch (attr.type) {
                    case 'time':
                        return helpers.getDateFromTime(attrVal)
                    case 'datetime':
                        return helpers.createDateFromUnix(attrVal, { keepLocal: true })
                    default:
                        return attrVal
                }
            } 
    
            // fresh job
            return attr.default
        })
    ), [])
    const defaultWeightUnit = default_weight_unit || "";

    const [attributes, setAttributes] = useState({
        orderedBy,
        salesOrderNumber,
        dayOrNight,
        pickupOrDelivery,
        loadTime,
        onJobTime,
        qtyPerHour,
        shippingDateTime,
        specificHaulers,
        customerID,
        searchHauler: "",
        specificHauler: "",
        customer_name: jobState.customer_name || "",
        customer_phone_number: jobState.customer_phone_number || "",
        estimated_amount: jobState.estimated_amount || "",
        estimated_amount_units: jobState.estimated_amount_units || defaultWeightUnit,
        estimated_volume: jobState.estimated_volume || "",
        estimated_volume_units: jobState.estimated_volume_units || defaultWeightUnit,
    })
    
    // const [attributes, setAttributes] = useState({
    //     orderedBy: existingExtendedAttributeValues ? existingExtendedAttributeValues.find(attr => attr.name === 'Ordered By')?.value || "" : '',
    //     salesOrderNumber: existingExtendedAttributeValues ? existingExtendedAttributeValues.find(attr => attr.name === 'Sales Order Number')?.value || "" : '',
    //     dayOrNight: existingExtendedAttributeValues ? existingExtendedAttributeValues.find(attr => attr.name === 'Day/Night')?.value || 'Day' : 'Day',
    //     pickupOrDelivery: existingExtendedAttributeValues ? existingExtendedAttributeValues.find(attr => attr.name === 'Pickup/Delivery')?.value || 'Pickup' : 'Pickup',
    //     loadTime: existingExtendedAttributeValues ? helpers.getDateFromTime(existingExtendedAttributeValues.find(attr => attr.name === 'Load Time')?.value) : new Date(),
    //     onJobTime: existingExtendedAttributeValues ? helpers.getDateFromTime(existingExtendedAttributeValues.find(attr => attr.name === 'On Job Time')?.value) : new Date(),
    //     qtyPerHour: existingExtendedAttributeValues ? existingExtendedAttributeValues.find(attr => attr.name === 'Qty Per Hour')?.value || 1 : 1,
    //     shippingDateTime: existingExtendedAttributeValues ? helpers.createDateFromUnix(existingExtendedAttributeValues.find(attr => attr.name === 'Shipping Date and Time')?.value, { keepLocal: true }) : new Date(),
    //     specificHaulerID: specificHaulerID,
    //     specificHaulers: [],
    //     searchHauler: "",
    //     specificHauler: specificHauler,
    // })

    // Updates job modal's 'extended_attribute_values' field
    useEffect(() => {
        setExtendedAttributeValues({
            "Customer ID": attributes.customerID,
            "Ordered By": attributes.orderedBy,
            "Sales Order Number": attributes.salesOrderNumber,
            "Day/Night": attributes.dayOrNight,
            "Pickup/Delivery": attributes.pickupOrDelivery,
            "Load Time": toMysqlFormat(attributes.loadTime, { timeOnly: true, includeSeconds: true }),
            "On Job Time": toMysqlFormat(attributes.onJobTime, { timeOnly: true, includeSeconds: true }),
            "Qty Per Hour": attributes.qtyPerHour,
            "Shipping Date and Time": toMysqlFormat(attributes.shippingDateTime),
            "Specific Hauler IDs": attributes.specificHaulers.map(hauler => hauler.identifier),
        })
        const conditionalUpdates = {}
        if (useCompanyDrivers) {
            conditionalUpdates.privacy_status = 'private'
        } else {
            if (!["exclusive", "whitelist"].includes(jobState.privacy_status)) {
                conditionalUpdates.privacy_status = "exclusive"
            }
            conditionalUpdates.bid_amount_status = "fixed"
        }

        setState({
            // specificHauler: attributes.specificHauler,
            // specificHaulerID: attributes.specificHaulerID,
            specificHaulers: attributes.specificHaulers, 
            customer_name: attributes.customer_name,
            customer_phone_number: attributes.customer_phone_number,
            estimated_amount: attributes.estimated_amount,
            estimated_amount_units: attributes.estimated_amount_units,
            estimated_volume: attributes.estimated_volume,
            estimated_volume_units: attributes.estimated_volume_units,
            useCompanyDrivers: useCompanyDrivers,
            ...conditionalUpdates
        })
        

    }, [attributes])

    useEffect(() => {
        if (attributes.specificHauler) {
            addHauler()
        }
    }, [attributes.specificHauler])

    const addHauler = () => {
        if (attributes.specificHauler && !attributes.specificHaulers.find(hauler => hauler.identifier == attributes.specificHauler.identifier)) {
            setAttributes({
                ...attributes,
                specificHaulers: [...attributes.specificHaulers, {
                    ...attributes.specificHauler,
                    identifier: attributes.specificHauler.identifier || '',
                    bidAsCompany: false,
                    award: false,
                    trucksRequired: 0,
                }],
                specificHauler: "",
                searchHauler: "",
            })
        }

    }
    const removeHauler = haulerID => {
        const newHaulers = [...attributes.specificHaulers].filter(hauler => hauler.identifier != haulerID)
        setAttributes({
            ...attributes, 
            specificHaulers: newHaulers
        })
    }

    // useEffect(() => {
    //     if (attributes.specificHauler && attributes.specificHauler)
    // }, [attributes.specificHauler])

    const handleChange = (field, type) => {
        if (type) {
            if (type == 'boolean') {
                return event => setAttributes({ 
                    ...attributes,
                    [field]: event.target.checked 
                })
            } else if (type == 'number') {
                return event => setAttributes({ 
                    ...attributes,
                    [field]: parseFloat(event.target.value) || "" 
                })
            } else if (type == 'date') {
                return date => setAttributes({ 
                    ...attributes,
                    [field]: date 
                })
            } else if (type == 'time') {
                return time => setAttributes({ 
                    ...attributes,
                    [field]: time 
                })
            } else if (type == 'radio-boolean') {
                return event => setAttributes({ 
                    ...attributes,
                    [field]: event.target.value == 'true' ? true : false 
                })
            }
        }
        return event => setAttributes({ 
            ...attributes,
            [field]: event.target.value 
        });
    }
    return (<>
        <Grid container spacing={10}>
            <Grid item xs={12} md>
                <FormControl fullWidth>
                    <KeyboardTimePicker
                        label={'Load Time'}
                        value={attributes.loadTime}
                        onChange={handleChange('loadTime', 'time')}
                        inputVariant="outlined"
                        required
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md>
                <FormControl fullWidth>
                    <KeyboardDateTimePicker
                        label={'Shipping Date And Time'}
                        value={attributes.shippingDateTime}
                        onChange={handleChange('shippingDateTime', 'date')}
                        inputVariant="outlined"
                        required
                        format="MM/dd/yyyy hh:mm a"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md>
                <FormControl fullWidth>
                    <KeyboardTimePicker
                        label={'On The Job Time'}
                        value={attributes.onJobTime}
                        onChange={handleChange('onJobTime', 'time')}
                        inputVariant="outlined"
                        required
                    />
                </FormControl>
            </Grid>
        </Grid>
        <Grid container spacing={10}>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                    <TextField
                        id="customerID"
                        label={`Customer #`}
                        value={attributes.customerID}
                        onChange={handleChange("customerID")}
                        type="text"
                        variant="outlined"
                        required
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                    <TextField
                        id="customer_name"
                        label={`Customer Name (Optional)`}
                        value={attributes.customer_name}
                        onChange={handleChange("customer_name")}
                        type="text"
                        variant="outlined"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                    <TextField
                        id="customer_phone_number"
                        label={`Customer Phone Number (Optional)`}
                        value={attributes.customer_phone_number}
                        onChange={handleChange("customer_phone_number")}
                        type="text"
                        variant="outlined"
                    />
                </FormControl>
            </Grid>
        </Grid>
        <Grid container spacing={10}>
            <Grid item xs={12} md>
                <FormControl fullWidth>
                    <TextField
                        id="RMHMA_orderedBy"
                        label={`Ordered By`}
                        value={attributes.orderedBy}
                        onChange={handleChange("orderedBy")}
                        type="text"
                        variant="outlined"
                        required
                        error={!attributes.orderedBy}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md>
                <FormControl fullWidth>
                    <TextField
                        id="RMHMA_salesOrderNumber"
                        label={`Sales Order Number`}
                        value={attributes.salesOrderNumber}
                        onChange={handleChange("salesOrderNumber")}
                        type="text"
                        variant="outlined"
                        required
                        error={!attributes.salesOrderNumber}
                    />
                </FormControl>
            </Grid>
        </Grid>
        <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="RMHMA_dayOrNight">Day or Night</InputLabel>
                    <Select
                        label="Day or Night"
                        labelId="RMHMA_dayOrNight"
                        value={attributes.dayOrNight}
                        onChange={handleChange('dayOrNight')}
                        fullWidth
                        required
                        inputProps={{
                            name: "RMHMA_dayOrNight_input",
                            id: "RMHMA_dayOrNight_input"
                        }}
                    >
                        <MenuItem value={'Day'}>Day</MenuItem>
                        <MenuItem value={'Night'}>Night</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="RMHMA_pickupOrDelivery">Pickup or Delivery</InputLabel>
                    <Select
                        label="Pickup or Delivery"
                        labelId="RMHMA_pickupOrDelivery"
                        value={attributes.pickupOrDelivery}
                        onChange={handleChange('pickupOrDelivery')}
                        fullWidth
                        required
                        inputProps={{
                            name: "RMHMA_pickupOrDelivery_input",
                            id: "RMHMA_pickupOrDelivery_input"
                        }}
                    >
                        <MenuItem value={'Pickup'}>Pickup</MenuItem>
                        <MenuItem value={'Delivery'}>Delivery</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
        <Grid container spacing={10}>
            <Grid item xs={12} md={4}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Order Qty Type</FormLabel>
                    <RadioGroup
                        aria-label="isWeightQty"
                        name="isWeightQty"
                        value={isWeightQty}
                        onChange={(_, value) => setIsWeightQty(value === 'true')}
                        row
                        required
                    >
                        <FormControlLabel
                            value={true}
                            control={<Radio color="primary" required />}
                            label={t('unitTranslations.weight')}
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio color="primary" required />}
                            label={t('unitTranslations.volume')}
                            labelPlacement="end"
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
            {isWeightQty ? <>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                        <TextField
                            id="RMHMA_estimated_amount"
                            label={`Order Qty`}
                            value={attributes.estimated_amount}
                            onChange={handleChange("estimated_amount")}
                            type="number"
                            required
                            inputProps={{
                                min: 1,
                                step: "any"
                            }}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="RMHMA_estimated_amount_units">Order Qty Units</InputLabel>
                        <Select
                            label="Order Qty Units"
                            labelId="RMHMA_estimated_amount_units"
                            value={attributes.estimated_amount_units}
                            onChange={handleChange('estimated_amount_units')}
                            fullWidth
                            required
                            inputProps={{
                                name: "RMHMA_estimated_amount_units_input",
                                id: "RMHMA_estimated_amount_units_input"
                            }}
                        >
                            {validUnits.weight && validUnits.weight.map(unit => (
                                <MenuItem key={unit} value={unit}>{t(`unitTranslations.${unit}`)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </> : <>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                        <TextField
                            id="RMHMA_estimated_volume"
                            label={`Order Qty`}
                            value={attributes.estimated_volume}
                            onChange={handleChange("estimated_volume")}
                            type="number"
                            required
                            inputProps={{
                                min: 1,
                                step: "any"
                            }}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="RMHMA_estimated_volume_units">Order Qty Units</InputLabel>
                        <Select
                            label="Order Qty Units"
                            labelId="RMHMA_estimated_volume_units"
                            value={attributes.estimated_volume_units}
                            onChange={handleChange('estimated_volume_units')}
                            fullWidth
                            required
                            inputProps={{
                                name: "RMHMA_estimated_volume_units_input",
                                id: "RMHMA_estimated_volume_units_input"
                            }}
                        >
                            {validUnits.volume && validUnits.volume.map(unit => (
                                <MenuItem key={unit} value={unit}>{t(`unitTranslations.${unit}`)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </>}
        </Grid>
        <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <TextField
                        id="qtyPerHour"
                        label={`Quantity Per Hour`}
                        value={attributes.qtyPerHour}
                        onChange={handleChange("qtyPerHour")}
                        type="number"
                        required
                        inputProps={{
                            min: 1,
                            step: "any"
                        }}
                        variant="outlined"
                    />
                </FormControl>
            </Grid>
        </Grid>
        <Grid container spacing={10}>
            <Grid item xs={12} md={4}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Driver Type</FormLabel>
                    <RadioGroup
                        aria-label="useCompanyDrivers"
                        name="useCompanyDrivers"
                        value={useCompanyDrivers}
                        onChange={(_, value) => setUseCompanyDrivers(value === 'true')}
                        row
                    >
                        <FormControlLabel
                            value={true}
                            control={<Radio color="primary" required />}
                            label={"Company"}
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio color="primary" required />}
                            label={"External Hauler"}
                            labelPlacement="end"
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
        {!useCompanyDrivers && <>
            <Grid container spacing={10}>
                <Grid item xs>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="RMHMA_driver_autocomplete"
                            autoComplete
                            clearOnBlur={false}
                            options={preferredDrivers}
                            getOptionLabel={({ identifier, driver_company }) => {
                                if (identifier && driver_company) {
                                    return `${identifier} - ${driver_company.name}`
                                } else if (driver_company) {
                                    return driver_company.name
                                } else {
                                    return ""
                                }
                            }}
                            value={attributes.specificHauler}
                            onChange={(event, val) => {
                                if (val) {
                                    setAttributes({ ...attributes, specificHauler: val, specificHaulerID: val.identifier })
                                }
                            }}
                            onInputChange={(event, val, reason) => {
                                if (reason != 'reset' || val) {
                                    setAttributes({ ...attributes, searchHauler: val })
                                }
                            }}
                            inputValue={attributes.searchHauler}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id="searchHauler"
                                    label={`Haulers`}
                                    type="text"
                                    variant="outlined"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                {attributes.specificHaulers.map(hauler => (
                    <Grid item xs={12} md={6} key={hauler.driver_company_id}>
                        <Grid container spacing={10} direction="row" justify="space-between" alignItems="center">
                            <Grid item xs>
                                <Typography gutterBottom>
                                    {hauler.driver_company.name}
                                </Typography>
                                {Boolean(type === 'create' || type === 'duplicate') ? <>
                                    <Grid container spacing={10}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id={`${hauler.driver_company_id}_identifier`}
                                                    label={`Identifier`}
                                                    value={hauler.identifier}
                                                    onChange={event => {
                                                        const newHaulers = [...attributes.specificHaulers].map(haul => {
                                                            if (haul.id == hauler.id) {
                                                                haul.identifier = event.target.value
                                                            }
                                                            return haul
                                                        })
                                                        setAttributes({
                                                            ...attributes,
                                                            specificHaulers: newHaulers
                                                        })
                                                    }}
                                                    type="text"
                                                    required
                                                    error={!hauler.identifier}
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={10}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id={`${hauler.driver_company_id}_qtyPerHour`}
                                                    label={`Vehicle Count`}
                                                    value={hauler.trucksRequired}
                                                    onChange={event => {
                                                        const newHaulers = [...attributes.specificHaulers].map(haul => {
                                                            if (haul.id == hauler.id) {
                                                                haul.trucksRequired = event.target.value
                                                            }
                                                            return haul
                                                        })
                                                        setAttributes({
                                                            ...attributes,
                                                            specificHaulers: newHaulers
                                                        })
                                                    }}
                                                    type="number"
                                                    required
                                                    inputProps={{
                                                        min: 1,
                                                        step: "any"
                                                    }}
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </> : <Typography>
                                    {hauler.identifier}
                                </Typography>}
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    type="button"
                                    color="default"
                                    onClick={() => removeHauler(hauler.identifier)}
                                    aria-label={`Remove hauler ID ${hauler.identifier}`}
                                >
                                    <CloseIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </>}
    </>)
    
}
export default withTranslation()(ExtendedAttributesPartialRMHMA)