import React from "react";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import { connect, useSelector } from 'react-redux';

import { CircularProgress as MuiCircularProgress } from "@material-ui/core";
// import TPLoaderGIF from '../assets/videos/tp_loader.gif'
import TPLoaderGIFWhite from '../assets/videos/tp_logo_animated_white.gif'
import TPLoaderGIFOrange from '../assets/videos/tp_logo_animated_orange.gif'
const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;
const CircularProgress = styled(MuiCircularProgress)(spacing)

function Loader() {
  return (
    <Root>
      <CircularProgress m={2} color="secondary" />
    </Root>
  );
}

export function TPLoader() {
  const theme = useSelector((state: any) => state.theme)
  switch(theme.currentTheme) {
    case 'high_contrast':
      return (<Root><img style={{ height: '260px' }} src={TPLoaderGIFWhite} /></Root>)
    default:
      return (<Root><img style={{ height: '260px' }} src={TPLoaderGIFOrange} /></Root>)
  }
}
export default Loader;
