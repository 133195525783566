
export enum InspectionItemDataTypes {
    IMAGE = "image",
    STRING = "string",
    INTEGER = "integer",
    DECIMAL = "decimal",
    DOCUMENT = "document",
    SIGNATURE = "signature",
    BOOLEAN = "boolean",
    DATETIME = "datetime",
}

// unused?
export type InspectionItemDataType = 
    InspectionItemDataTypes.IMAGE |
    InspectionItemDataTypes.STRING | 
    InspectionItemDataTypes.INTEGER | 
    InspectionItemDataTypes.DECIMAL |
    InspectionItemDataTypes.DOCUMENT | 
    InspectionItemDataTypes.SIGNATURE |
    InspectionItemDataTypes.BOOLEAN

export interface InspectionItem {
    allow_multiple_per_inspection: boolean;
    allowed_units: string[];
    canonical_units: string | null;
    data_type: InspectionItemDataTypes;
    description: string;
    id: number;
    name: string;
    requires_units: boolean;
    selections: string[] | null;
    units_type: string;
}
export enum InspectorTypes {
    COMPANY = "company",
    THIRD_PARTY = "third-party"
}
export type InspectorType = InspectorTypes.COMPANY | InspectorTypes.THIRD_PARTY

export interface JobInspectorAssignmentMap {
    [jobID: string]: JobInspectorAssignment
}
export interface JobInspectorAssignment {
    created_at: string;
    id: 23
    inspector_company_id: number;
    inspector_company_name: string;
    inspector_email: string;
    inspector_external_id: string | null;
    inspector_first_name: string;
    inspector_last_name: string;
    inspector_phone: string;
    inspector_phone_country_code: string;
    inspector_type: InspectorType;
    inspector_user_id: number;
    is_dropoff_inspector: number;
    is_pickup_inspector: number;
    job_id: number;
    token: string;
}
export interface JobInspectionItem {
    id: number;
    inspection_item: InspectionItem;
    inspection_item_id: number;
    is_dropoff_inspection: boolean;
    is_pickup_inspection: boolean;
    job_id: number;
    notes: string;
    units: string | null;
}

export interface Inspection {
    created_at: string;
    delivery_id: number;
    id: number;
    inspection_location: "pickup" | "dropoff";
    inspection_overridden: 0 | 1;
    inspection_type: InspectorTypes;
    inspector: JobInspectorAssignment;
    inspector_user_id: null;
    job_id: number;
    job_inspector_id: number;
    latitude: string;
    longitude: string;
    notes: string;
    updated_at: string;
    values: InspectionValue[],
    displayDate?: string;
}
export interface InspectionValue {
    canonicalized_value: string;
    delivery_inspection_id: number;
    id: number;
    job_inspection_item: JobInspectionItem;
    job_inspection_item_id: number;
    timezone: string | null;
    units: string;
    value: string;
}