import * as VehicleAPI from '../util/vehicleAPI';

import { functions } from '../reducers/helpersReducer';
export const RECEIVE_VEHICLES = "RECEIVE_VEHICLES";

export const receiveVehicles = (vehicles) => ({
    type: RECEIVE_VEHICLES,
    vehicles
})

export const getCompanyVehicles = (token, companyID, params) => dispatch => {
    return VehicleAPI.getCompanyVehicles(token, companyID, params).then(
        (res) => {
            if (res.success) {
                dispatch(receiveVehicles(res.data))
            }
            return res
        }
    )
}

