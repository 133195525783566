import React, { useState } from "react";
import styled, { withTheme } from "styled-components";
import { connect, useSelector } from "react-redux";
import { darken } from "polished";
import { withRouter, useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Badge,
  Grid,
  Hidden,
  InputBase,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar
} from "@material-ui/core";
import { logout } from '../redux/actions/sessionActions'
import { Menu as MenuIcon } from "@material-ui/icons";

import {
  Bell,
  MessageSquare,
  Search as SearchIcon,
  Power
} from "react-feather";

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${props => props.theme.header.indicator.background};
    color: ${props => props.theme.palette.common.white};
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${props => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${props => darken(0.05, props.theme.header.background)};
  }

  ${props => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${props => props.theme.header.search.color};
    padding-top: ${props => props.theme.spacing(2.5)}px;
    padding-right: ${props => props.theme.spacing(2.5)}px;
    padding-bottom: ${props => props.theme.spacing(2.5)}px;
    padding-left: ${props => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

function LanguageMenu() {

  const { i18n } = useTranslation()

  const initLanguageMenu = () => {
    console.log("init lang menu")
    return i18n.language;
  }
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [language, setLanguage] = useState(() => initLanguageMenu())

  const changeLanguage = languageCode => {
    i18n.changeLanguage(languageCode);
    window.localStorage.setItem("TPLang", languageCode)
    setLanguage(languageCode);
  }

  const toggleMenu = event => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        {language == 'en' && <Flag src="/static/img/flags/us.png" alt="English" />}
        {language == 'es' && <Flag src="/static/img/flags/es.png" alt="Español" />}
        {language == 'fr' && <Flag src="/static/img/flags/fr.png" alt="Français" />}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={() => {
          changeLanguage('en');
          closeMenu()
        }}>
          English
        </MenuItem>
        <MenuItem onClick={() => {
          changeLanguage('es');
          closeMenu()
        }}>
          Español
        </MenuItem>
        <MenuItem onClick={() => {
          changeLanguage('fr');
          closeMenu()
        }}>
          Français
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

function UserMenu({ logout, company }) {
  const history = useHistory()
  const user = useSelector(state => state.session.user)
  const [anchorMenu, setAnchorMenu] = useState(null);
  const { t, i18n } = useTranslation()

  const toggleMenu = event => {
    setAnchorMenu(event.currentTarget);
  };

  // const navigateToCompanyProfile = () => {
  //   history.push('/company');
  //   setAnchorMenu(null);
  // }
  // const navigateToUserProfile = () => {
  //   history.push('/user');
  //   setAnchorMenu(null);
  // }
  const closeMenu = () => {
    setAnchorMenu(null);
  };
  const canShowProfile = Boolean(company && company.id)
  return (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        aria-label="Sign out"
      >
        <Power />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {/* {canShowProfile && [
          <MenuItem onClick={navigateToUserProfile} key="user">
            {t('userTranslations.userProfile')}
          </MenuItem>,
          <MenuItem onClick={navigateToCompanyProfile} key="company">
            {t('companyTranslations.companyProfile')}
          </MenuItem>
        ]} */}
        {Boolean(user && user.id) ? 
          <MenuItem onClick={logout}>
            {t('authTranslations.signOut')}
          </MenuItem>
        : 
          <MenuItem onClick={() => history.push('/auth/sign-in')}>
            {t('authTranslations.signIn')}
          </MenuItem>
        }
      </Menu>
    </React.Fragment>
  );
}

const Header = ({ onDrawerToggle, logout, history, notificationCount, company }) => {
  const location = useLocation()
  const shouldClose = location.pathname === '/plantOrders' || location.pathname === '/dispatch'
  return <React.Fragment>
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp={shouldClose ? false : true }>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open language menu"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          {/* <Grid item>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder="Search topics" />
            </Search>
          </Grid> */}
          <Grid item xs />
          <Grid item>
            {/* <IconButton color="inherit">
              <Indicator badgeContent={3}>
                <MessageSquare />
              </Indicator>
            </IconButton> */}
            {(company && company.id) && <IconButton aria-label="Notifications" color="inherit" onClick={() => history.push('/notifications')}>
              <Indicator badgeContent={notificationCount ? notificationCount : null}>
                <Bell />
              </Indicator>
            </IconButton>}
            <LanguageMenu />
            <UserMenu logout={logout} history={history} company={company} />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
};

const msp = state => ({
  notificationCount: state.session.notificationCount,
  company: state.session.company
})
const mdp = dispatch => ({
  logout: () => dispatch(logout())
})
export default withRouter(connect(msp, mdp)(withTheme(Header)));
