import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from '../reducers/index';


const configureStore = (preloadedState = {}) => {
    switch (process.env.REACT_APP_ENV || process.env.NODE_ENV) {
        case 'test':
        case 'development':
        case 'dev':
            return createStore(rootReducer, preloadedState, applyMiddleware(thunk))
            break;
        default:
        case 'production':
            return createStore(rootReducer, preloadedState, applyMiddleware(thunk))
            break;
    }
}


export default configureStore;
