import { combineReducers } from 'redux';

import themeReducer from './themeReducers';
import sessionReducer from './sessionReducer';
import entitiesReducer from './entitiesReducer';
import helpersReducer from './helpersReducer';

export default combineReducers({
	theme: themeReducer,
	session: sessionReducer,
	entities: entitiesReducer,
	helpers: helpersReducer
});
