import React, { ReactElement, useState, useEffect, useMemo } from "react";
import { Button, Collapse, FormControl, FormHelperText, Grid, InputBaseComponentProps, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableRow, TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ETicketParameter, ETicketParameterDatatypes } from "../../../../../../redux/reducers/entities/ETicket";

interface Props {
    parameter: ETicketParameter;
    value: string | number | null | undefined;
    setValue: (value: any) => void;
    targetName: string;
    parameterName: string;
}
/*
    size sets min/max 
    digits_between sets min/max
*/
const RenderETicketParameter = ({
    targetName,
    parameterName,
    parameter,
    value,
    setValue,
}: Props): ReactElement => {
    const { t } = useTranslation()
    const [component, setComponent] = useState<ReactElement | null>()

    const inputProps = useMemo(() => {
        const props: InputBaseComponentProps = {}
        if (parameter.validationMap.min) props.min = parameter.validationMap.min
        if (parameter.validationMap.max) props.max = parameter.validationMap.max
        if (parameter.validationMap.minLength) props.minLength = parameter.validationMap.minLength
        if (parameter.validationMap.maxLength) props.maxLength = parameter.validationMap.maxLength
        return props
    }, [parameter])

    const updateComponent = () => {
        let tempComponent = <Typography>{t('unknownError')}</Typography>
        const isRequired = parameter.always_required || parameter.validationMap.required || parameter.requiredByOther
        const inputID = `${targetName}-${parameter.readable}`
        const hasError = Boolean(isRequired && !value)
        switch(parameter.datatype) {
            case "float":
                tempComponent = (
                    <FormControl fullWidth>
                        <TextField
                            id={inputID}
                            label={parameter.readable}
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                            type="text"
                            required={isRequired}
                            inputProps={inputProps}
                            error={hasError}
                            variant="outlined"
                        />
                        <FormHelperText>{parameter.description}</FormHelperText>
                    </FormControl>
                )
                break;
            case "string":
                if (parameter.validationMap.numeric) {
                    tempComponent = (
                        <FormControl fullWidth>
                            <TextField
                                id={inputID}
                                label={parameter.readable}
                                value={value}
                                onChange={event => setValue(parseInt(event.target.value))}
                                type="number"
                                required={isRequired}
                                inputProps={inputProps}
                                error={!value}
                                variant="outlined"
                            />
                            <FormHelperText>{parameter.description}</FormHelperText>
                        </FormControl>
                    )
                } else if (parameter.validationMap.in?.length) {
                    tempComponent = (
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id={`label-${inputID}`}>{parameter.readable}</InputLabel>
                            <Select
                                label={parameter.readable}
                                labelId={`label-${inputID}`}
                                id={inputID}
                                value={value}
                                onChange={event => setValue(event.target.value)}
                                fullWidth
                                required={isRequired}
                                // inputProps={{
                                //     name: inputID,
                                //     id: inputID
                                // }}
                                error={hasError}
                            >
                                {parameter.validationMap.in.map(option => (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{parameter.description}</FormHelperText>
                        </FormControl>
                    )
                } else {
                    tempComponent = (
                        <FormControl fullWidth>
                            <TextField
                                id={inputID}
                                label={parameter.readable}
                                value={value}
                                onChange={(event) => setValue(event.target.value)}
                                type="text"
                                required={isRequired}
                                inputProps={inputProps}
                                error={hasError}
                                variant="outlined"
                            />
                            <FormHelperText>{parameter.description}</FormHelperText>
                        </FormControl>
                    )
                }
                break;
            default:
                break;
        }
        setComponent(tempComponent)
    }
    useEffect(() => {
        updateComponent()
    }, [parameter, value, parameter.requiredByOther])
    return (
        <Grid item xs={12}>
            {component}
        </Grid>
    )
}
export default RenderETicketParameter