import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AuthLayoutWrapper } from "../../layouts/Auth";
import { Helmet } from "react-helmet";
import { Typography } from "../dashboards/Util/SpacedComponents";
import { Paragraph, SectionHeader } from ".";
import { List, ListItem } from "@material-ui/core";

interface Props {

}

const TPTermsOfService = ({ }: Props) => {
    const { t } = useTranslation()

    return (
        <AuthLayoutWrapper>
            <Helmet title={"Terms Of Service - MyTruckScales"} />
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
                TRUCKPAY TERMS OF SERVICE
            </Typography>
            <Typography mb={4}>
                Effective as of November 17, 2016
            </Typography>
            <Paragraph>
                These terms of service (these “Terms”) constitute a legally binding agreement between you and
                TruckPay Inc. (“TruckPay,” “we,” “us” or “our”) governing your use of the TruckPay application,
                website, and technology platform (collectively, the “TruckPay Platform”). By using the TruckPay
                Platform, you expressly acknowledge that you understand these Terms (including the dispute
                resolution and arbitration provisions set forth in these Terms) and accept all of these Terms. If you
                do not agree to be bound by these Terms, you may not use or access the TruckPay Platform.
            </Paragraph>
            {/* 
            <SectionHeader bold>
                Collection of Information
            </SectionHeader> */}

            <SectionHeader>
                The TruckPayPlatform
            </SectionHeader>
            <Paragraph>
                The TruckPay Platform provides a marketplace where persons who seek transportation of loads
                and other jobs completed (“Listers”) can be matched with persons who provide transportation
                services with respect to those loads or complete those jobs (“Providers”) and a paper ticket
                replacement scan technology and a GPS tracking technology (the “Technologies”) that facilitate
                enhanced tracking of jobs and the provision of productivity metrics (the “Technology Benefits”).
                Listers and Providers are collectively referred to herein as “Users,” and each User shall create a
                User account that enables access to the TruckPay Platform. For purposes of these Terms, the
                services provided by Providers (either directly or through subcontractors) to Listers that are
                matched through the Platform shall be referred to collectively as the “Services.” Any decision by a
                User to offer or accept Services is a decision made in such User’s sole discretion. Each Service
                provided by a Provider (either directly or through subcontractors) to a Lister shall constitute a
                separate agreement between such persons. A person providing Services on behalf of a Provider,
                whether the Provider directly, or an employee, independent contractor or other agent of Provider, is
                referred to herein as a “Driver.”
            </Paragraph>

            <SectionHeader>
                Modification of these Terms
            </SectionHeader>
            <Paragraph>
                In the event TruckPay modifies these Terms, such modifications shall be binding on you only upon
                your acceptance of the modified Terms by your subsequent use of the TruckPay Platform.
                TruckPay reserves the right to modify any information referenced in the hyperlinks from these
                Terms from time to time, and such modifications shall become effective upon posting. Continued
                use of the TruckPay Platform or Services after any such changes shall constitute your consent to
                such changes. Unless material changes are made to the arbitration provisions herein, you agree
                that modifications of these Terms do not create a renewed opportunity to opt out of arbitration (if
                applicable).
            </Paragraph>

            <SectionHeader>
                Eligibility
            </SectionHeader>
            <Paragraph>
                The TruckPay Platform may only be used by entities and by individuals who can form legally
                binding contracts under applicable law. The TruckPay Platform is not available to children
                (persons under the age of 18) or Users who have had their User account temporarily or
                permanently deactivated. By becoming a User, you represent and warrant that (a) if you are an
                entity, you have all requisite power and authority to accept these Terms, or (b) if you are an
                individual, you are at least 18 years old and that you have the right, authority and capacity to enter
                into and abide by these Terms. You may not allow other persons to use your User account, and
                you agree that you are the sole authorized user of your account.
            </Paragraph>

            {/* <Paragraph>
                <i>Other Sources.</i> We may also receive information from other sources and combine that with information 
                we collect through our Services. For example, if you choose to link, create, or log in to your TruckPay 
                account with a payment provider (e.g., Google Wallet) or if you engage with a separate app or website that 
                uses our API (or whose API we use), we may receive information about you or your connections from that site 
                or app.
            </Paragraph> */}

            <SectionHeader>
                Charges
            </SectionHeader>
            <Paragraph noBottomMargin>
                As a Lister, you agree to pay the amounts you specify will be paid to TruckPay for specific
                Services listed by you on the TruckPay Platform and Services (“Charges”). Furthermore, by using
                the TruckPay Platform and Services, you acknowledge and agree as follows:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • TruckPay is serving solely as (a) a marketplace that you will use to find Providers to move
                    loads or provide the other Services specified by you and (b) a provider of the Technologies;
                </ListItem>
                <ListItem>
                    • You are obligated to pay to TruckPay all load scan and load fees that have incurred during
                    any invoice period for any job listed by you on the TruckPay Platform (and you will hold
                    TruckPay harmless from any failure by you to timely pay such fees);
                </ListItem>
                <ListItem>
                    • Such invoices will be paid in accordance with the payment terms stipulated for each
                    particular job that you have created on the TruckPay Platform;
                </ListItem>
                <ListItem>
                    • Your authorized representatives will immediately review all invoices when the TruckPay
                    Platform has notified you and authorize payment, unless
                </ListItem>
                <ListItem>
                    <List>
                        <ListItem>
                            • You have determined reasonably and in good faith that there is a material problem
                            with any such invoice; and
                        </ListItem>
                        <ListItem>
                            • You have timely notified TruckPay and the applicable Provider of such problem;
                            and
                        </ListItem>
                    </List>
                </ListItem>
                <ListItem>
                    • If such a problem exists, your designated representative will work reasonably and in good
                    faith (and as though time is of the essence) to resolve such problem and will immediately
                    pay the invoice once such problem has been resolved in a manner consistent with the
                    resolution of the problem.
                </ListItem>
            </List>

            <Paragraph noBottomMargin>
                As a Provider, by accepting an assignment to provide Services using the TruckPay Platform, you
                acknowledge and agree that TruckPay is serving solely as a marketplace that you will use to find
                jobs relating to transport and delivery of loads or providing other Services to Listers, and thus
                TruckPay has no obligation to pay you for any services rendered by you (or your employees,
                contractors or affiliates) unless:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • You were engaged for a job that was listed on the TruckPay Platform;
                </ListItem>
                <ListItem>
                    • You accept such job using the TruckPay Platform and complete the job in a manner
                    reasonably satisfactory to the Lister who listed the job on the TruckPay Platform; and
                </ListItem>
                <ListItem>
                    • Such Lister has paid TruckPay for your services relating such job (it being understood that
                    TruckPay shall not be obligated to pay you any more than the amount it received from such
                    Lister with respect to the applicable Services, whether received through TruckPay’s third-party
                    payment processing service or otherwise).
                </ListItem>
            </List>

            <Paragraph noBottomMargin>
                Fees and Other Charges.
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • <i>Service Fee.</i> TruckPay may assess a “Service Fee” to support the TruckPay Platform and
                    related services provided to you by TruckPay. The amount of the Service Fee may vary
                    but shall be retained by TruckPay in its entirety.
                </ListItem>
                <ListItem>
                    • <i>Other Charges.</i> Other fees and surcharges may apply to your Services, including state or
                    local fees. In addition, where required by law, TruckPay will collect applicable taxes.
                    These other charges are not shared with the applicable Provider unless expressly stated
                    otherwise.
                </ListItem>
            </List>
            <Paragraph>
                All Charges for Services provided in connection with the use of the TruckPay Platform must be
                facilitated and paid through a third-party payment processing service (e.g., Stripe, Inc.) specified
                by TruckPay. No Provider or Driver may accept any payment directly from any Lister for
                Services provided in connection with the use of the TruckPay Platform. TruckPay may replace
                its third-party payment processing services without notice to you.
            </Paragraph>

            <SectionHeader>
                Payments
            </SectionHeader>
            <Paragraph>
                If you are a Provider, you will receive payment for your provision of Services solely through the
                third-party payment processing service specified by TruckPay; provided, however, that in the
                event of non-payment for Services by a Lister, TruckPay may, in its sole discretion, authorize the
                unpaid Provider to seek payment directly from such Lister of the amount specified on the
                TruckPay Platform for the applicable Services (it being understood that such action by a Provider
                shall not impair or interfere with TruckPay’s ability to collect the fees and other amounts it is
                owed by such Lister). All payments are subject to a TruckPay Service Fee. You acknowledge and
                agree that any amounts so paid to you shall not include any interest and will be net of any amounts
                that we are required to withhold by law. Drivers shall look solely to the applicable Provider for
                Payment and neither any Lister nor TruckPay shall have any liability to pay a Driver for the
                provision of Services.
            </Paragraph>

            <SectionHeader>
                TruckPay Communications
            </SectionHeader>
            <Paragraph>
                By becoming a User, you agree to receive communications from us, including via e-mail, text
                message, calls, and push notifications. You agree that texts, calls or prerecorded messages may be
                generated by automatic telephone dialing systems. Communications from TruckPay, its affiliated
                companies and/or Providers or Listers, may include but are not limited to: operational
                communications concerning your User account or use of the TruckPay Platform or Services,
                updates concerning new and existing features on the TruckPay Platform, communications
                concerning promotions run by us or our third-party partners, and news concerning TruckPay and
                industry developments. Standard text messaging charges applied by your cell phone carrier will
                apply to text messages we send.
            </Paragraph>


            <SectionHeader>
                Your Information
            </SectionHeader>
            <Paragraph>
                Your Information is any information you provide, publish or post to or through the TruckPay
                Platform (including any profile information you provide) or send to other Users (including via inapplication
                feedback, any email feature, or through any TruckPay-related social media posting)
                (your “Information”). You consent to us using your Information to create a User account that will
                allow you to use the TruckPay Platform and participate in the Services. Our collection and use of
                personal information in connection with the TruckPay Platform and Services is as provided in
                TruckPay’s Privacy Statement located at www.TruckPay.com/privacy. You are solely responsible
                for your Information and your interactions with other members of the public, and we act only as a
                passive conduit for your online posting of your Information. You agree to provide and maintain
                accurate, current and complete information and that we and other members of the public may rely
                on your Information as accurate, current and complete. To enable TruckPay to use your
                Information, you grant to us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free,
                transferable, sub-licensable (through multiple tiers) right and license to exercise the copyright,
                publicity, and database rights you have in your Information, and to use, copy, perform, display and
                distribute such Information to prepare derivative works, or incorporate into other works, such
                Information, in any media now known or not currently known. TruckPay does not assert any
                ownership over your Information; rather, as between you and TruckPay, subject to the rights
                granted to us in these Terms, you retain full ownership of all of your Information and any
                intellectual property rights or other proprietary rights associated with your Information.
            </Paragraph>
            <Paragraph>
                You may be able to create or log-in to your TruckPay User account through accounts you may
                have with third party social media platforms (each, an “SMP”). By connecting to TruckPay
                through an SMP, you understand that TruckPay may access, store, and make available any SMP
                content according to the permission settings of your SMP (e.g., friends, mutual friends, contacts or
                following/followed lists (the “SMP Content”)). You understand that SMP Content may be
                available on and through the TruckPay Platform to other Users. Unless otherwise specified in
                these Terms, all SMP Content, if any, shall be considered to be your Information.
            </Paragraph>

            <SectionHeader>
                Promotions and Referral Programs
            </SectionHeader>
            <Paragraph>
                TruckPay, at its sole discretion, may make available promotions with different features to any
                Users or prospective Users. These promotions, unless made to you, shall have no bearing
                whatsoever on your Agreement or relationship with TruckPay. TruckPay reserves the right to
                withhold or deduct credits or benefits obtained through a promotion the event that TruckPay
                determines or believes that the redemption of the promotion or receipt of the credit or benefit was
                in error, fraudulent, illegal, or in violation of the applicable promotion terms or these Terms
            </Paragraph>
            <Paragraph>
                As part of your User account, TruckPay may provide you with or allow you to create a “TruckPay
                Code,” a unique alphanumeric code for you to distribute to friends, family and other persons (each
                a “Referred User”) to become new TruckPay Listers or Providers. TruckPay Codes may only be
                distributed for promotional purposes and must be given away free of charge. You may not sell,
                trade, or barter your TruckPay Code. You are prohibited from advertising TruckPay Codes.
                TruckPay reserves the right to deactivate or invalidate any TruckPay Code at any time in
                TruckPay’s discretion.
            </Paragraph>
            <Paragraph>
                From time to time, TruckPay may offer you with incentives to refer new Users to the TruckPay
                community (the “Referral Program”). These incentives may come in the form of TruckPay Credits,
                and TruckPay may set or change the incentive types, amounts, terms, restrictions, and qualification
                requirements for any incentives in its sole discretion. Your distribution of TruckPay Codes and
                participation in the Referral Program is subject to these Terms and any rules for the Referral
                Program that we specify from time-to-time.
            </Paragraph>

            <SectionHeader>
                Restricted Activities
            </SectionHeader>
            <Paragraph noBottomMargin>
                With respect to your use of the TruckPay Platform and your participation in the Services, you
                agree that you will not:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • Violate any law, statute, rule, permit, ordinance or regulation;
                </ListItem>
                <ListItem>
                    • Interfere with or disrupt the Services or the TruckPay Platform or the servers or networks
                    connected to the TruckPay Platform;
                </ListItem>
                <ListItem>
                    • Post Information or interact on the TruckPay Platform or Services in a manner which is
                    false, inaccurate, misleading (directly or by omission or failure to update information),
                    defamatory, libelous, abusive, obscene, profane, offensive, sexually oriented, threatening,
                    harassing, or illegal;
                </ListItem>
                <ListItem>
                    • Use the TruckPay Platform in any way that infringes any third party’s rights, including
                    but not limited to: intellectual property rights, copyright, patent, trademark, trade secret
                    or other proprietary rights or rights of publicity or privacy;
                </ListItem>
                <ListItem>
                    • For a period of six months after a User’s most recent interaction (regardless of whether
                    such interaction results in any provision of Services) with a Lister, Provider or Driver
                    using the TruckPay Platform, solicit or attempt to solicit such Lister, Provider or Driver
                    with respect to providing any Services in any manner that does not use the TruckPay
                    Platform (it being understood that this provision will not prohibit Providers and Drivers
                    that have an employment or independent contractor relationship that existed prior to their
                    use of the TruckPay Platform from maintaining that pre-existing relationship);
                </ListItem>
                <ListItem>
                    • Post, email or otherwise transmit any malicious code, files or programs designed to
                    interrupt, damage, destroy or limit the functionality of any computer software or
                    hardware or telecommunications equipment or surreptitiously intercept or expropriate
                    any system, data or personal information;
                </ListItem>
                <ListItem>
                    • Forge headers or otherwise manipulate identifiers in order to disguise the origin of any
                    information transmitted through the TruckPay Platform;
                </ListItem>
                <ListItem>
                    • “Frame” or “mirror” any part of the TruckPay Platform, without our prior written
                    authorization or use meta tags or code or other devices containing any reference to us in
                    order to direct any person to any other web site for any purpose; or
                    • Modify, adapt, translate, reverse engineer, decipher, decompile or otherwise disassemble
                    any portion of the TruckPay Platform or any software used on or for the TruckPay
                    Platform;
                </ListItem>
                <ListItem>
                    • Rent, lease, lend, sell, redistribute, license or sublicense the TruckPay Platform or access
                    to any portion of the TruckPay Platform;
                </ListItem>
                <ListItem>
                    • Use any robot, spider, site search/retrieval application, or other manual or automatic
                    device or process to retrieve, index, scrape, “data mine”, or in any way reproduce or
                    circumvent the navigational structure or presentation of the TruckPay Platform or its
                    contents;
                </ListItem>
                <ListItem>
                    • Link directly or indirectly to any other web sites;
                </ListItem>
                <ListItem>
                    • Transfer or sell your User account, password and/or identification to any other party
                </ListItem>
                <ListItem>
                    • Discriminate against or harass anyone on the basis of race, national origin, religion,
                    gender, gender identity, physical or mental disability, medical condition, marital status,
                    age or sexual orientation,
                </ListItem>
                <ListItem>
                    • Impersonate any person or entity;
                </ListItem>
                <ListItem>
                    • Stalk, threaten, or otherwise harass any person, or carry any weapons; or
                </ListItem>
                <ListItem>
                    • Cause any third party to engage in the restricted activities above.
                </ListItem>
            </List>


            <SectionHeader>
                Lister Representations, Warranties and Agreements
            </SectionHeader>
            <Paragraph noBottomMargin>
                Each Lister represents, warrants, and agrees that it:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • Will not make any misrepresentation regarding TruckPay, the TruckPay Platform, the
                    Services or your status as a Lister;
                </ListItem>
                <ListItem>
                    • Will not attempt to defraud TruckPay, Listers or Drivers on the TruckPay Platform or in
                    connection with your provision of Services;
                </ListItem>
                <ListItem>
                    • Agree that we may obtain information about you, including your criminal records, and
                    you agree to provide any further necessary authorizations to facilitate our access to such
                    records during the term of the Agreement;
                </ListItem>
                <ListItem>
                    • Will only provide Services, or allow Services to be provided by Drivers on its behalf,
                    using the vehicle that has been reported to, and approved by TruckPay, and for which a
                    photograph and VIN number has been provided to TruckPay;
                </ListItem>
                <ListItem>
                    • Have a valid policy of liability insurance (in coverage amounts consistent with all
                    applicable legal requirements) that names or schedules you for the operation of the
                    vehicle you provide for your Drivers’ use to provide Services; and
                </ListItem>
                <ListItem>
                    • Will pay all applicable federal, state and local taxes based on your provision of Services
                    and any payments received by you.
                </ListItem>
            </List>


            <SectionHeader>
                Driver Representations, Warranties and Agreements
            </SectionHeader>
            <Paragraph noBottomMargin>
                Each Provider will cause its Drivers to agree in writing that, by providing Services as a Driver on
                the TruckPay Platform, such Drivers represent, warrant, and agree that such Drivers (and each
                Provider shall be responsible for breaches of the following by its Drivers):
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • Possess a valid Class A commercial driver’s license (in good standing with each of the
                    jurisdictions in which the Driver provides Services) and are authorized and medically fit
                    to operate a commercial motor vehicle and have all appropriate licenses, approvals and
                    authority to provide Services to Listers in all jurisdictions in which you provide Services;
                </ListItem>
                <ListItem>
                    • Own, or have the legal right to operate, the vehicle you use when providing Services, and
                    such vehicle is in good operating condition and meets the industry safety standards and
                    all applicable statutory and state department of motor vehicle requirements for a vehicle
                    of its kind and in accordance with all federal, state and other applicable department of
                    transportation standards;
                </ListItem>
                <ListItem>
                    • Will not engage in reckless behavior while driving, drive unsafely, operate a vehicle that
                    is unsafe to drive, be involved in a motor vehicle accident or collision of any kind, permit
                    an unauthorized third party to accompany you in the vehicle while providing Services,
                    provide Services as a Driver while under the influence of alcohol or drugs, or take action
                    that harms or threatens to harm the safety of the TruckPay community or third parties;
                </ListItem>
                <ListItem>
                    • Will only provide Services using the vehicle that has been reported to, and approved by
                    TruckPay, and for which a photograph and VIN number has been provided to TruckPay;
                </ListItem>
                <ListItem>
                    • Will not make any misrepresentation regarding TruckPay, the TruckPay Platform, the
                    Services or their status as a Driver;
                </ListItem>
                <ListItem>
                    • Will not attempt to defraud TruckPay, Listers or Providers on the TruckPay Platform or
                    in connection with your provision of Services;
                </ListItem>
                <ListItem>
                    • Agree that we may obtain information about you, including your criminal and driving
                    records, and you agree to provide any further necessary authorizations to facilitate our
                    access to such records during the term of the Agreement;
                </ListItem>
                <ListItem>
                    • Have a valid policy of liability insurance (in coverage amounts consistent with all
                    applicable legal requirements) that names or schedules you for the operation of the
                    vehicle you use to provide Services (unless such vehicle is provided by a Provider and
                    such Provider has such insurance that so covers such vehicle); and
                </ListItem>
                <ListItem>
                    • Will pay all applicable federal, state and local taxes based on your provision of Services
                    and any payments received by you.
                </ListItem>
            </List>


            <SectionHeader>
                Intellectual Property
            </SectionHeader>
            <Paragraph>
                All intellectual property rights in the TruckPay Platform shall be owned by TruckPay absolutely
                and in their entirety. These rights include and are not limited to database rights, copyright, design
                rights, trademarks and other similar rights, in each such case whether registered or unregistered,
                wherever existing in the world together with the right to apply for protection of the same. All other
                trademarks, logos, service marks, company or product names set forth in the TruckPay Platform
                are the property of their respective owners. You acknowledge and agree that any questions,
                comments, suggestions, ideas, feedback or other information submitted by you to us are nonconfidential and
                shall become the sole property of TruckPay. TruckPay shall own exclusive rights,
                including all intellectual property rights, and shall be entitled to the unrestricted use and
                dissemination of these submissions for any purpose, commercial or otherwise, without
                acknowledgment or compensation to you
            </Paragraph>
            <Paragraph>
                TRUCKPAY and other TruckPay logos, designs, graphics, icons, scripts and service names are
                registered trademarks, trademarks or trade dress of TruckPay in the United States and/or other
                countries (collectively, the “TruckPay Marks”). If you provide Services as a Provider or Driver,
                TruckPay grants to you, during the term of these Terms, and subject to your compliance with these
                Terms, a limited, revocable, non-exclusive license to display and use the TruckPay Marks solely in
                connection with providing the Services through the TruckPay Platform (“License”). The License
                is non-transferable and non-assignable, and you shall not grant to any third party any right,
                permission, license or sublicense with respect to any of the rights granted hereunder without
                TruckPay’s prior written permission, which it may withhold in its sole discretion. The TruckPay
                Marks may not be used in any manner that is likely to cause confusion in the marketplace
            </Paragraph>
            <Paragraph>
                You acknowledge that TruckPay is the owner and licensor of the TruckPay Marks, including all
                goodwill associated therewith, and that your use of the TruckPay Marks will confer no additional
                interest in or ownership of the TruckPay Marks in you but instead inures to the benefit of
                TruckPay. You agree to use the TruckPay Marks strictly in accordance with any usage guidelines
                adopted by TruckPay, as may be provided to you and revised from time to time, and to
                immediately cease any use that TruckPay determines to nonconforming or otherwise unacceptable.
            </Paragraph>
            <Paragraph noBottomMargin>
                You agree that you will not:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • Create any materials that incorporate the TruckPay Marks or any derivatives of the
                    TruckPay Marks other than as expressly approved by TruckPay in writing;
                </ListItem>
                <ListItem>
                    • Use the TruckPay Marks in any way that tends to impair their validity as proprietary
                    trademarks, service marks, trade names or trade dress, or use the TruckPay Marks other
                    than in accordance with the terms, conditions and restrictions herein;
                </ListItem>
                <ListItem>
                    • Take any other action that would jeopardize or impair TruckPay’s rights as owner of the
                    TruckPay Marks or the legality and/or enforceability of the TruckPay Marks, including,
                    without limitation, challenging or opposing TruckPay’s ownership in the TruckPay Marks;
                </ListItem>
                <ListItem>
                    • Apply for trademark registration or renewal of trademark registration of any of the
                    TruckPay Marks, any derivative of the TruckPay Marks, any combination of the TruckPay
                    Marks and any other name, or any trademark, service mark, trade name, symbol or word
                    which is similar to the TruckPay Marks; or
                </ListItem>
                <ListItem>
                    • Use the TruckPay Marks on or in connection with any product, service or activity that is in
                    violation of any law, statute, government regulation or standard.
                </ListItem>
            </List>
            <Paragraph>
                Violation of any provision of this License may result in immediate termination of the License, in
                TruckPay’s sole discretion. If you create any materials bearing the TruckPay Marks (in violation
                of these Terms or otherwise), you agree that upon their creation TruckPay exclusively owns all
                right, title and interest in and to such materials, including without limitation any modifications to
                the TruckPay Marks or derivative works based on the TruckPay Marks. You further agree to
                assign any interest or right you may have in such materials to TruckPay, and to provide
                information and execute any documents as reasonably requested by TruckPay to enable TruckPay
                to formalize such assignment.
            </Paragraph>
            <Paragraph>
                TruckPay respects the intellectual property of others, and expects Users to do the same. If you
                believe, in good faith, that any materials on the TruckPay Platform or Services infringe upon your
                copyrights, please contact TruckPay
            </Paragraph>


            <SectionHeader>
                Disclaimers
            </SectionHeader>
            <Paragraph>
                The following disclaimers are made on behalf of TruckPay, our affiliates, subsidiaries, parents,
                successors and assigns, and each of our respective officers, directors, employees, agents, and
                shareholders.
            </Paragraph>
            <Paragraph>
                TruckPay does not provide transportation services, and TruckPay is not a transportation carrier. It
                is up to the Driver to decide whether or not to offer Services to a Lister contacted through the
                TruckPay Platform, and it is up to the Lister to decide whether or not to accept Services from any
                Driver contacted through the TruckPay Platform. We cannot ensure that a Provider, Driver or
                Lister will complete an arranged transportation service. We have no control over the quality or
                safety of the transportation that occurs as a result of the Services.
            </Paragraph>
            <Paragraph>
                The TruckPay Platform is provided on an “as is” basis and without any warranty or condition,
                express, implied or statutory. We do not guarantee and do not promise any specific results from
                use of the TruckPay Platform and/or the Services, including the ability to provide or receive
                Services at any given location or time. We specifically disclaim any implied warranties of title,
                merchantability, fitness for a particular purpose and non-infringement. Some states do not allow
                the disclaimer of implied warranties, so the foregoing disclaimer may not apply to you.
            </Paragraph>
            <Paragraph>
                We do not warrant that your use of the TruckPay Platform or Services will be accurate, complete,
                reliable, current, secure, uninterrupted, always available, or error- free, or will meet your
                requirements, that any defects in the TruckPay Platform will be corrected, or that the TruckPay
                Platform is free of viruses or other harmful components. We disclaim liability for, and no warranty
                is made with respect to, connectivity and availability of the TruckPay Platform or Services.
            </Paragraph>
            <Paragraph>
                Each User acknowledges and agrees that a failure to properly use the Technologies by it or any
                other User with whom it uses the TruckPay Platform in connection with the provision of Services
                may deprive it and any such other User of the Technology Benefits.
            </Paragraph>
            <Paragraph noBottomMargin>
                TruckPay may provide a Bid Calculator and other tools to assist Users in connection with their use
                of the TruckPay Platform. Without limiting the generality of the foregoing disclaimers:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • Any such tools are provided “as is” and without any warranty as to their accuracy or
                    efficacy;
                </ListItem>
                <ListItem>
                    • A User accepts all responsibility for its use of any such tools and agrees that the use of such
                    tools requires the User to critically evaluate the information produced by such tools and use
                    its independent business judgment in connection with its use of any such tools and the
                    TruckPay Platform; and
                </ListItem>
                <ListItem>
                    • TruckPay shall not have any liability (including, without limitation, for any harm to a User
                    from losing bids or from placing unprofitably low bids) to any User in connection with
                    such User’s use of any such tools.
                </ListItem>
            </List>
            <Paragraph>
                TruckPay is not responsible for the conduct, whether online or offline, of any User of the TruckPay
                Platform or Services. You are solely responsible for your interactions with other Users. By using
                the TruckPay Platform and participating in the Services, you agree to accept such risks and agree
                that TruckPay is not responsible for the acts or omissions of Users on the TruckPay Platform or
                participating in the Services.
            </Paragraph>
            <Paragraph>
                TruckPay expressly disclaims any liability arising from the unauthorized use of your User account.
                Should you suspect that any unauthorized party may be using your User account or you suspect
                any other breach of security, you agree to notify us immediately.
            </Paragraph>
            <Paragraph>
                It is possible for others to obtain information about you that you provide, publish or post to or
                through the TruckPay Platform (including any profile information you provide), send to other
                Users, or share during the Services, and to use such information to harass or harm you. We are not
                responsible for the use of any personal information that you disclose to other Users on the
                TruckPay Platform or through the Services. Please carefully select the type of information that you
                post on the TruckPay Platform or through the Services or release to others. We disclaim all
                liability, regardless of the form of action, for the acts or omissions of other Users (including
                unauthorized users, or “hackers”).
            </Paragraph>
            <Paragraph>
                Opinions, advice, statements, offers, or other information or content concerning TruckPay or made
                available through the TruckPay Platform, but not directly by us, are those of their respective
                authors, and should not necessarily be relied upon. Such authors are solely responsible for such
                content. Under no circumstances will we be responsible for any loss or damage resulting from
                your reliance on information or other content posted by third parties, whether on the TruckPay
                Platform or otherwise. We reserve the right, but we have no obligation, to monitor the materials
                posted on the TruckPay Platform and remove any such material that in our sole opinion violates, or
                is alleged to violate, the law or these Terms or which might be offensive, illegal, or that might
                violate the rights, harm, or threaten the safety of Users or others.
            </Paragraph>
            <Paragraph>
                Location data provided by the TruckPay Platform is for basic location purposes only and is not
                intended to be relied upon in situations where precise location information is needed or where
                erroneous, inaccurate or incomplete location data may lead to death, personal injury, property or
                environmental damage. Neither TruckPay, nor any of its content providers, guarantees the
                availability, accuracy, completeness, reliability, or timeliness of location data tracked or displayed
                by the TruckPay Platform. Any of your Information, including geolocation data, you upload,
                provide, or post on the TruckPay Platform may be accessible to TruckPay and certain Users of the
                TruckPay Platform.
            </Paragraph>
            <Paragraph>
                TruckPay advises you to use the TruckPay Platform with a data plan with unlimited or very high
                data usage limits, and TruckPay shall not be responsible or liable for any fees, costs, or overage
                charges associated with any data plan you use to access the TruckPay Platform.
            </Paragraph>
            <Paragraph>
                This paragraph applies to any version of the TruckPay Platform that you acquire from any app
                store. These Terms are entered into between you and TruckPay. No app store is a party to these
                Terms and shall have no obligations with respect to the TruckPay Platform. TruckPay, not any app
                store, is solely responsible for the TruckPay Platform and the content thereof as set forth
                hereunder. However, any app store that provides the TruckPay app are third party beneficiaries of
                these Terms. Upon your acceptance of these Terms, each app store shall have the right (and will
                be deemed to have accepted the right) to enforce these Terms against you as a third party
                beneficiary thereof. In the event of a conflict in the terms of any app store agreement or terms of
                use and these Terms, these Terms shall control.
            </Paragraph>


            <SectionHeader>
                Indemnity
            </SectionHeader>
            <Paragraph noBottomMargin>
                You will defend, indemnify, and hold TruckPay including our affiliates, subsidiaries, parents,
                successors and assigns, and each of our respective officers, directors, employees, agents, or
                shareholders harmless from any claims, actions, suits, losses, costs, liabilities and expenses
                (including reasonable attorneys’ fees) relating to or arising out of your use of the TruckPay
                Platform and participation in the Services, including:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • Your breach of these Terms or the documents it incorporates by reference;
                </ListItem>
                <ListItem>
                    • Your violation of any law or the rights of a third party, including, without limitation,
                    Providers, Drivers, Listers, other motorists, and pedestrians, as a result of your own
                    interaction with such third party;
                </ListItem>
                <ListItem>
                    • Any allegation that any materials that you submit to us or transmit through the TruckPay
                    Platform infringe or otherwise violate the copyright, trademark, trade secret or other
                    intellectual property or other rights of any third party;
                </ListItem>
                <ListItem>
                    • Your ownership, use or operation of a motor vehicle or commercial or passenger vehicle,
                    including your provision of Services as a Provider or Driver; and/or
                </ListItem>
                <ListItem>
                    • Any other activities in connection with the Services.
                </ListItem>
            </List>
            <Paragraph>
                This indemnity shall be applicable without regard to the negligence of any party, including any
                indemnified person.
            </Paragraph>


            <SectionHeader>
                Limitation of Liability
            </SectionHeader>
            <Paragraph>
                IN NO EVENT WILL TRUCKPAY, INCLUDING OUR AFFILIATES, SUBSIDIARIES,
                PARENTS, SUCCESSORS AND ASSIGNS, AND EACH OF OUR RESPECTIVE OFFICERS,
                DIRECTORS, EMPLOYEES, AGENTS, OR SHAREHOLDERS (COLLECTIVELY
                “TRUCKPAY” FOR PURPOSES OF THIS SECTION), BE LIABLE TO YOU FOR ANY
                INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, CONSEQUENTIAL, OR INDIRECT
                DAMAGES (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR DELETION,
                CORRUPTION, LOSS OF DATA, LOSS OF PROGRAMS, FAILURE TO STORE ANY
                INFORMATION OR OTHER CONTENT MAINTAINED OR TRANSMITTED BY THE
                TRUCKPAY PLATFORM, SERVICE INTERRUPTIONS, OR FOR THE COST OF
                PROCUREMENT OF SUBSTITUTE SERVICES) ARISING OUT OF OR IN CONNECTION
                WITH THE TRUCKPAY PLATFORM, THE SERVICES, OR THESE TERMS, HOWEVER
                ARISING, INCLUDING NEGLIGENCE, EVEN IF WE OR OUR AGENTS OR
                REPRESENTATIVES KNOW OR HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. THE TRUCKPAY PLATFORM MAY BE USED BY YOU TO REQUEST AND
                SCHEDULE TRANSPORTATION OR OTHER SERVICES WITH THIRD PARTY
                PROVIDERS, BUT YOU AGREE THAT TRUCKPAY HAS NO RESPONSIBILITY OR
                LIABILITY TO YOU RELATED TO ANY TRANSPORTATION OR OTHER SERVICES
                PROVIDED TO YOU BY THIRD PARTY PROVIDERS OTHER THAN AS EXPRESSLY SET
                FORTH IN THESE TERMS. CERTAIN JURISDICTIONS MAY NOT ALLOW THE
                EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO
                YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS
                MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS
            </Paragraph>



            <SectionHeader>
                Term and Termination
            </SectionHeader>
            <Paragraph>
                These Terms are an agreement and are effective upon your creation of a User account. These
                Terms may be terminated: (a) by either party, without cause, upon seven (7) days’ prior written
                notice to TruckPay; or (b) by either Party immediately, without notice, upon the other Party’s
                material breach of these Terms. In addition, TruckPay may terminate these Terms or deactivate
                your User account immediately in the event: (i) you no longer qualify to provide Services or to
                operate the approved vehicle under applicable law, rule, permit, ordinance or regulation; (ii) you
                fall below TruckPay’s star rating or cancellation threshold; or (iii) TruckPay has the good faith
                belief that such action is necessary to protect the safety of the TruckPay community or third
                parties, provided that in the event of a deactivation pursuant to and of items (i)-(iii) above, you will
                be given notice of the potential or actual deactivation and an opportunity to attempt to cure the
                issue to TruckPay’s reasonable satisfaction prior to TruckPay permanently terminating these
                Terms. For all other breaches of these Terms, you will be provided notice and an opportunity to
                cure the breach. If the breach is cured in a timely manner and to TruckPay’s satisfaction, these
                Terms will not be permanently terminated. The terms and conditions set forth in these Terms and
                shall survive any termination or expiration of these Terms with respect to all Services provided or
                other matters occurring or existing prior to the date of termination.
            </Paragraph>



            <SectionHeader>
                Dispute Resolution and Arbitration Agreement
            </SectionHeader>
            <Paragraph>
                YOU AND TRUCKPAY MUTUALLY AGREE TO WAIVE OUR RESPECTIVE RIGHTS TO
                RESOLUTION OF DISPUTES IN A COURT OF LAW BY A JUDGE OR JURY AND AGREE
                TO RESOLVE ANY DISPUTE BY ARBITRATION, AS SET FORTH BELOW. This agreement
                to arbitrate (“Arbitration Agreement”) is governed by the Federal Arbitration Act and survives
                after the Agreement terminates or your relationship with TruckPay ends. Any such arbitration will
                take place on an individual basis, and joint arbitrations and class actions are not permitted. Except
                as expressly provided below, this Arbitration Agreement applies to all disputes and claims between
                you and TruckPay, including our affiliates, subsidiaries, parents, successors and assigns, and each
                of our respective officers, directors, employees, agents, or shareholders. BY AGREEING TO
                ARBITRATION, YOU UNDERSTAND THAT YOU AND TRUCKPAY ARE WAIVING THE
                RIGHT TO SUE IN COURT OR HAVE A JURY TRIAL FOR ALL CLAIMS. This Arbitration
                Agreement is intended to require arbitration of every claim or dispute that can lawfully be
                arbitrated, except for those claims and disputes which by the terms of this Arbitration Agreement
                are expressly excluded from the requirement to arbitrate.
            </Paragraph>
            <Paragraph>
                Any arbitration conducted pursuant to this Arbitration Agreement shall be administered by the
                American Arbitration Association (“AAA”) pursuant to its Commercial that are in effect at the
                time the arbitration is initiated, as modified by the terms set forth in these Terms. Copies of these
                rules can be obtained at the AAA’s website (www.adr.org) (the “AAA Rules”) or by calling the
                AAA at 1-800-778-7879. As part of the arbitration, both you and TruckPay will have the
                opportunity for reasonable discovery of non-privileged information that is relevant to the
                applicable claim. The arbitrator may award any individualized remedies that would be available in
                court. The arbitrator may award declaratory or injunctive relief only in favor of the individual
                party seeking relief and only to the extent necessary to provide relief warranted by that party's
                individual claims. The arbitrator will provide a reasoned written statement of the arbitrator’s
                decision which shall explain the award given and the findings and conclusions on which the
                decision is based. The arbitrator will decide the substance of all claims in accordance with
                applicable law, and will honor all claims of privilege recognized by law. The arbitrator shall not be
                bound by rulings in prior arbitrations involving different Listers, Providers or Drivers, but is bound
                by rulings in prior arbitrations involving the same Listers, Providers or Drivers to the extent
                required by applicable law. The arbitrator’s award shall be final and binding and judgment on the
                award rendered by the arbitrator may be entered in any court having jurisdiction thereof, provided
                that any award may be challenged in a court of competent jurisdiction.
            </Paragraph>
            <Paragraph>
                In the event that any portion of this Arbitration Agreement is deemed illegal or unenforceable, such
                provision shall be severed and the remainder of the Arbitration Agreement shall be given full force
                and effect.
            </Paragraph>


            <SectionHeader>
                Confidentiality
            </SectionHeader>
            <Paragraph noBottomMargin>
                You agree not to use any technical, financial, strategic and other proprietary and confidential
                information relating to TruckPay’s business, operations and properties, including User information
                (“Confidential Information”) disclosed to you by TruckPay for your own use or for any purpose
                other than as contemplated herein. You shall not disclose or permit disclosure of any Confidential
                Information to third parties. You agree to take all reasonable measures to protect the secrecy of
                and avoid disclosure or use of Confidential Information of TruckPay in order to prevent it from
                falling into the public domain. Notwithstanding the above, you shall not have liability to TruckPay
                with regard to any Confidential Information which you can prove it:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • Was publicly available at the time it was disclosed by TruckPay or became publicly
                    available through no fault of yours;
                </ListItem>
                <ListItem>
                    • Was known to you, without restriction, at the time of disclosure, as demonstrated by files in
                    existence at the time of disclosure;
                </ListItem>
                <ListItem>
                    • Is disclosed with the prior written approval of TruckPay; or
                </ListItem>
                <ListItem>
                    • Became known to you, without restriction, from a source other than TruckPay without
                    breach of these Terms or any other confidentiality agreement by you and otherwise not in
                    violation of TruckPay’s rights.
                </ListItem>
            </List>
            <Paragraph>
                Nothing in these Terms shall be deemed to prohibit Confidential Information from being disclosed
                pursuant to the order or requirement of a court, administrative agency, or other governmental
                body; provided, however, that you shall provide prompt notice of such court order or requirement
                to TruckPay and provide TruckPay with any other assistance reasonably requested by TruckPay to
                enable TruckPay to seek a protective order or otherwise prevent or restrict such disclosure.
            </Paragraph>


            <SectionHeader>
                Relationship with TruckPay
            </SectionHeader>
            <Paragraph>
                As a Lister, Provider or Driver on the TruckPay Platform, you acknowledge and agree that you and
                TruckPay are in a direct business relationship, and the relationship between the parties under these
                Terms is solely that of independent contracting parties. You and TruckPay expressly agree that
                these Terms are not an employment agreement and does not create an employment relationship
                between you and TruckPay and no joint venture, franchisor-franchisee, partnership, or agency
                relationship is intended or created by these Terms. You have no authority to bind TruckPay, and
                you undertake not to hold yourself out as an employee, agent or authorized representative of
                TruckPay.
            </Paragraph>
            <Paragraph>
                TruckPay does not, and shall not be deemed to, direct or control you generally or in your
                performance under these Terms specifically, including in connection with your provision of
                Services, your acts or omissions, or your operation and maintenance of your vehicle. You retain
                the sole right to determine when, where, and for how long you will utilize the TruckPay Platform.
                As a Provider, you retain the option to accept or to decline or ignore a Lister’s request for Services 
                via the TruckPay Platform, or to cancel an accepted request for Services via the TruckPay
                Platform, subject to TruckPay’s then-current cancellation policies. You acknowledge and agree
                that you have complete discretion to provide Services or otherwise engage in other business or
                employment activities.
            </Paragraph>



            <SectionHeader>
                Other Services
            </SectionHeader>
            <Paragraph>
                In addition to connecting Listers with Providers, the TruckPay Platform may enable Users to
                provide or receive other services from other third parties (collectively, the “Other Services”). You
                understand that the Other Services are subject to the terms and pricing of the third-party provider.
                If you choose to purchase Other Services through the TruckPay Platform, you authorize TruckPay
                to charge your payment method on file, if any, according to the pricing terms set by the third-party
                provider. You agree that TruckPay is not responsible and may not be held liable for the Other
                Services or the actions or omissions of the third-party provider. Such Other Services may not be
                investigated, monitored or checked for accuracy, appropriateness, or completeness by us, and we
                are not responsible for any Other Services accessed through the TruckPay Platform.
            </Paragraph>



            <SectionHeader>
                General
            </SectionHeader>
            <Paragraph>
                These Terms shall be governed by the laws of the State of New Jersey without regard to choice of
                law principles. This choice of law provision is only intended to specify the use of New Jersey law
                to interpret these Terms and is not intended to create any other substantive right to persons outside
                of New Jersey to assert claims under New Jersey law whether by statute, common law, or
                otherwise. If any provision of these Terms is or becomes invalid or non-binding, the parties shall
                remain bound by all other provisions hereof. In that event, the parties shall replace the invalid or
                non-binding provision with provisions that are valid and binding and that have, to the greatest
                extent possible, a similar effect as the invalid or non-binding provision, given the contents and
                purpose of these Terms. You agree that these Terms and all incorporated agreements may be
                automatically assigned by TruckPay, in our sole discretion by providing notice to you. Except as
                explicitly stated otherwise, any notices to TruckPay shall be given by certified mail, postage
                prepaid and return receipt requested to TruckPay, Inc., 15 Central Avenue, Tenafly, NJ 07670.
                Any notices to you shall be provided to you through the TruckPay Platform or given to you via the
                email address or physical address you provide to TruckPay during the registration process.
                Headings are for reference purposes only and in no way define, limit, construe or describe the
                scope or extent of such section. A party’s failure to act with respect to a breach by the other party
                does not constitute a waiver of the party’s right to act with respect to subsequent or similar
                breaches. These Terms set forth the entire understanding and agreement between you and
                TruckPay with respect to the subject matter hereof and supersedes all previous understandings and
                agreements between the parties, whether oral or written.
            </Paragraph>
            <Paragraph>
                If you have any questions regarding the TruckPay Platform or Services, please contact TruckPay at
                support@truckpay.com.
            </Paragraph>

        </AuthLayoutWrapper>
    )

}

export default TPTermsOfService