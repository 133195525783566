import React, { useEffect } from "react";
import { connect } from "react-redux";
import Helmet from 'react-helmet';
import DateFnsUtils from "@date-io/date-fns";
import { unpopAlert } from './redux/actions/sessionActions'
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import maTheme from "./theme";
import Routes from "./routes/Routes";
import NotificationSnackbar from "./pages/dashboards/Util/Snackbar";

/*
  todo add material numbers to material selects
*/

function App({ ...props }) {

  // Custom onClose for the snackbar component
  useEffect(() => {
    if (props.alerts.alert) {
      setTimeout(props.unpopAlert, 5000)
    }
  }, [props.alerts.alert])

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | TruckPay"
        defaultTitle="TruckPay"
      />
      <I18nextProvider i18n={i18n}>
        <StylesProvider injectFirst>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={maTheme[props.theme.currentTheme][props.theme.textSize]}>
              <ThemeProvider theme={maTheme[props.theme.currentTheme][props.theme.textSize]}>
                <Routes />
                <NotificationSnackbar />
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </I18nextProvider>
    </React.Fragment>
  );

}
const msp = state => ({
  theme: state.theme,
  alerts: state.session.alerts
})
const mdp = dispatch => ({
  unpopAlert: () => dispatch(unpopAlert()),
})
export default connect(msp, mdp)(App);
