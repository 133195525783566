export enum LengthUnits {
    INCHES = "in",
    CM = "cm",
    FEET = "ft",
    METERS = "m",
    MILES = "mi",
    KM = "km",
}
export type LengthUnit = LengthUnits.INCHES | LengthUnits.CM | LengthUnits.FEET | LengthUnits.METERS | LengthUnits.MILES | LengthUnits.KM | ""

export enum TimeUnits {
    SEC = "sec",
    MIN = "min",
    HR = "hr",
    DAY = "d",
}
export type TimeUnit = TimeUnits.SEC | TimeUnits.MIN | TimeUnits.HR | TimeUnits.DAY | ""

export enum VolumeUnits {
    GAL = "gal",
    LITER = "l",
    CUBIC_METER = "cubic-meter",
    CUBIC_YARD = "cubic-yard",
    FLUID_OUNCE = "fl-oz"
}
export type VolumeUnit = VolumeUnits.GAL | VolumeUnits.LITER | VolumeUnits.CUBIC_METER | VolumeUnits.CUBIC_YARD | ""

export enum WeightUnits {
    LB = "lb",
    KG = "kg",
    TON = "t",
    MT = "mt",
}
export type WeightUnit = WeightUnits.LB | WeightUnits.KG | WeightUnits.TON | WeightUnits.MT | ""
export const WeightUnitsAsArray = [WeightUnits.LB, WeightUnits.KG, WeightUnits.TON, WeightUnits.MT]

// type WeightUnits = "lb" | "kg"
export interface UnitMap {
    weight: WeightUnit[],
    volume: VolumeUnit[],
    length: LengthUnit[],
    time: TimeUnit[],
    temperature: TemperatureUnits[]
}

export interface Currency {
    code: string;
    is_payment_supported: boolean;
    is_supported: boolean;
    name: string;
    symbol_prefix: string;
    symbol_suffix: string;
}
export enum Frequency {
    DAILY = "daily",
    WEEKLY = "weekly",
    BI_WEEKLY = "bi-weekly",
    MONTHLY = "monthly",
    QUARTERLY = "quarterly",
    YEARLY = "yearly",
}
export enum TemperatureUnits {
    F = "F",
    C = "C"
}
export type TemperatureUnit = TemperatureUnits.F | TemperatureUnits.C | ""
