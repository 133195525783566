import React from "react";
import styled from "styled-components";
import { withTranslation } from 'react-i18next';
import {
  Grid,
  Hidden,
  List,
  ListItemText,
  ListItem as MuiListItem,
  Typography,
} from "@material-ui/core";

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(1) / 4}px
    ${props => props.theme.spacing(4)}px;
  background: ${props => props.theme.sidebar.footer.background};
  position: relative;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${props => props.theme.spacing(2)}px;
  padding-right: ${props => props.theme.spacing(2)}px;
  color: ${props => props.theme.palette.text.main};
  &,
  &:hover,
  &:active {
    color: ${props => props.theme.palette.text.main};
  }
`;
function openChatraWindow() {
  if (window.Chatra) {
    window.Chatra('show');
  }
}

function Footer({ t }) {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItem component="a" onClick={() => openChatraWindow()} >
                <ListItemText>
                  <Typography>
                    {t(`support`)}
                  </Typography>
                </ListItemText>

              </ListItem>
              {/* <ListItem component="a" href="#">
                <ListItemText primary="Help Center"/>
              </ListItem> */}
              <ListItem component="a" href="/truckpay_terms_of_service" target="_blank">
                <ListItemText>
                  <Typography color="textPrimary">
                    {t(`termsOfService`)}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem component="a" href="/truckpay_privacy_policy" target="_blank">
                <ListItemText>
                  <Typography color="textPrimary">
                    {t(`privacyPolicy`)}
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={6} justify="flex-end">
          <List>
            <ListItem>
              <ListItemText primary={`© ${new Date().getFullYear()} - TruckPay Inc.`} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default withTranslation()(Footer);
