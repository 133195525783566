import { LOGOUT_CURRENT_USER, SELECT_COMPANY } from '../actions/sessionActions';
import { 
    RECEIVE_COMPANIES,
    RECEIVE_CUSTOM_MATERIALS,
} from '../actions/companyActions';
import { 
    RECEIVE_SITES,
    RECEIVE_SITE,
} from '../actions/siteActions';
import { RECEIVE_SCALES } from '../actions/scaleActions';
import { RECEIVE_VEHICLES } from '../actions/vehicleActions';
import { 
    RECEIVE_VALID_UNITS, 
    RECEIVE_CURRENCIES,
    RECEIVE_EQUIPMENT,
    RECEIVE_SUPPORTED_COUNTRIES,
    RECEIVE_MATERIALS,
} from '../actions/adminActions';
import { 
    RECEIVE_JOB,
    RECEIVE_CURRENT_JOBS,
    RECEIVE_CURRENT_JOBS_PAGE,
    RECEIVE_NEARBY_JOBS,
    RECEIVE_NEARBY_JOBS_PAGE,
    RECEIVE_PENDING_JOBS,
    RECEIVE_PENDING_JOBS_PAGE,
    RECEIVE_PAST_JOBS,
    RECEIVE_PAST_JOBS_PAGE,
    RECEIVE_IN_PROGRESS_JOBS,
    RECEIVE_IN_PROGRESS_JOBS_PAGE,
    RECEIVE_EXTERNAL_JOBS,
    RECEIVE_BIDDED_JOBS,
    RECEIVE_BIDDED_JOBS_PAGE,
    CLEAR_PAGINATED_BIDDED_JOBS,
    CLEAR_PAGINATED_CURRENT_JOBS,
    CLEAR_PAGINATED_PENDING_JOBS,
    CLEAR_PAGINATED_IN_PROGRESS_JOBS,
    CLEAR_PAGINATED_NEARBY_JOBS,
    RECEIVE_PLANT_ORDERS_PAGE, 
    CLEAR_PAGINATED_PLANT_ORDERS
} from '../actions/jobActions';
import {
    RECEIVE_DISPATCH_ORDERS_PAGE,
    RECEIVE_ALL_DISPATCH_ORDERS,
} from '../actions/dispatchActions';
import { 
    RECEIVE_INSPECTOR_TOKENS,
    TRIGGER_INSPECTOR_FEED_UPDATE,
    TRIGGER_INSPECTOR_JOB_DELIVERY_FEED_UPDATE,
} from '../actions/inspectorActions';
import { merge } from 'lodash';
import { functions } from './helpersReducer';
import { 
    RECEIVE_MATERIAL_TAGS,
    UPSERT_CUSTOM_MATERIAL,
} from '../actions/materialActions';
import { TemperatureUnits } from './entities/Unit';

const nullState = {
    companies: [],
    sites: [],
    sitesMap: {},
    paginatedJobs: {
        current: {
            totalCount: -1
        },
        nearby: {
            totalCount: -1
        },
        pending: {
            totalCount: -1
        },
        past: {
            totalCount: -1
        },
        inProgress: {
            totalCount: -1
        },
        external: {
            totalCount: -1
        },
        bidded: {
            totalCount: -1
        },
        plantOrders: {
            totalCount: -1
        }
    },
    jobs: {
        current: [],
        nearby: [],
        pending: [],
        past: [],
        inProgress: [],
        external: [],
        bidded: [],
    },
    inspectorAssignments: {
        // [jobID]: { ...assignment }
    },
    scales: [],
    units: {
        weight: [],
        volume: [],
        length: [],
        time: [],
        temperature: []
    },
    currencies: [],
    equipment: [],
    equipmentMap: {},
    supportedCountries: [],
    materials: [],
    materialTags: [],
    customMaterials: {},
    customMaterialListNames: [],
    customMaterialsByID: {},
    vehicles: [],
    vehiclesById: {},
    timezones: [
        { name: 'Anchorage, Alaska', code: 'America/Anchorage' },
        { name: 'Juneau, Alaska', code: 'America/Juneau' },
        { name: 'Vancouver, British Columbia', code: 'America/Vancouver' },
        { name: 'Inuvik, Canada', code: 'America/Inuvik' },
        { name: 'Regina, Saskatchewan', code: 'America/Regina' },
        { name: 'Winnipeg, Manitoba', code: 'America/Winnipeg' },
        { name: 'Toronto, Ontario', code: 'America/Toronto' },
        { name: 'Montréal, Quebec', code: 'America/Montreal' },
        // NA
        { name: 'Los Angeles, California', code: 'America/Los_Angeles' },
        { name: 'Phoenix, Arizona', code: 'America/Phoenix' },
        { name: 'Denver, Colorado', code: 'America/Denver' },
        { name: 'Chicago, Illinois', code: 'America/Chicago' },
        { name: 'Indianapolis, Indiana', code: 'America/Indiana/Indianapolis' },
        { name: 'Detroit, Michigan', code: 'America/Detroit' },
        { name: 'New York, New York', code: 'America/New_York' },
        // MX
        { name: 'Hermosillo, Mexico', code: 'America/Hermosillo' },
        { name: 'Mexico City, Mexico', code: 'America/Mexico_City' },
        { name: 'Cancún, Mexico', code: 'America/Cancun' },
    ],
    publicNotifications: [],
    privateNotifications: [],
    dispatchOrders: [],
    paginatedDispatchOrders: {
        totalCount: 0
    },
    triggers: {
        inspectorFeed: false,
        // dispatchJobFeed: false,
    }
};

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

const entitiesReducer = (state = nullState, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_COMPANIES: {
            return merge({}, state, { companies: action.companies });
        }
        case RECEIVE_SITES: {
            const sitesMap = {}
            action.sites.forEach(site => sitesMap[site.id] = site)
            return merge({}, state, { sites: action.sites }, { sitesMap });
        }
        case RECEIVE_SITE: {
            return merge(
                {}, 
                state, 
                { 
                    sitesMap: {
                        ...state.sitesMap,
                        [action.site.id]: action.site
                    }
                }
            );
        }
        case RECEIVE_SCALES: {
            return merge({}, state, { scales: action.scales });
        }
        case RECEIVE_EQUIPMENT: {
            const eqMap = {}
            action.equipment.forEach(eq => {
                eqMap[eq.id] = eq
            })
            return merge({}, state, { equipment: action.equipment }, { equipmentMap: eqMap });
        }
        case RECEIVE_JOB: {
            return merge({}, state, { jobs: {
                ...state.jobs,
                [action.job.id]: action.job
            }})
        }
        case RECEIVE_CURRENT_JOBS: {
            const tempState = { ...state }
            if (tempState.jobs.current) {
                delete tempState.jobs.current
            }
            return merge({}, tempState, { jobs: {
                ...state.jobs,
                current: action.jobs
            } });
        }
        case RECEIVE_PLANT_ORDERS_PAGE: {
            // TODO why was this here?
            // const filteredJobs = action.jobs.filter(job => job.extended_job_type === 'MMHMA')

            const filteredJobs = action.jobs

            filteredJobs.map(job => {
                job.displayCustomerNumber = functions.formatPhoneNumber(job.customer_phone_number)
                job.extended_attribute_values = job.extended_attribute_values.map(eav => {
                    switch(eav.data_type) {
                        case "time": {
                            const time = functions.getDateFromTime(eav.value)
                            eav.displayValue = functions.timeFormatter(time, { noTimezone: true })
                            break;
                        }
                        case "datetime": {
                            eav.displayValue = functions.getDateTimeFromUnix(eav.value, { noTimezone: true })
                            break;
                        }
                        default: {
                            eav.displayValue = eav.value
                        }
                    }
                    return eav
                })
                return job
            })
            return merge(
                {}, 
                state, 
                {
                    paginatedJobs: {
                        ...state.paginatedJobs,
                        plantOrders: {
                            ...state.paginatedJobs.plantOrders,
                            totalCount: action.pageCount,
                            [action.page]: filteredJobs,
                        }
                    }
                }
            );
        }
        case CLEAR_PAGINATED_PLANT_ORDERS: {
            const tempState = { ...state }
            if (tempState.paginatedJobs.plantOrders) {
                delete tempState.paginatedJobs.plantOrders
            }
            return merge(
                {},
                tempState,
                {
                    paginatedJobs: {
                        ...state.paginatedJobs,
                        plantOrders: {
                            totalCount: 0
                        }
                    }
                }
            );
        }
        case RECEIVE_CURRENT_JOBS_PAGE: {
            // const unfilteredCurrentJobs = [...state.jobs.current, ...action.jobs]
            const unfilteredCurrentJobs = [...action.jobs]
            const jobIDs = {}
            const filteredCurrentJobs = []
            unfilteredCurrentJobs.forEach(val => {
                if (!jobIDs[val.id]) {
                    jobIDs[val.id] = true
                    filteredCurrentJobs.push(val)
                }
            })
            return merge(
                {}, 
                state, 
                { 
                    jobs: {
                        ...state.jobs,
                        current: filteredCurrentJobs
                    } 
                },
                {
                    paginatedJobs: {
                        ...state.paginatedJobs,
                        current: {
                            ...state.paginatedJobs.current,
                            totalCount: action.pageCount,
                            [action.page]: action.jobs,
                        }
                    }
                }
            );
        }
        case CLEAR_PAGINATED_CURRENT_JOBS: {
            const tempState = {...state}
            if (tempState.paginatedJobs.current) delete tempState.paginatedJobs.current
            return merge(
                {},
                tempState,
                {
                    paginatedJobs: {
                        ...state.paginatedJobs,
                        current: {
                            totalCount: 0,
                        }
                    }
                }
            );
        }
        case RECEIVE_NEARBY_JOBS: {
            return merge({}, state, { jobs: {
                ...state.jobs,
                nearby: action.jobs
            } });
        }
        case RECEIVE_NEARBY_JOBS_PAGE: {
            const unfilteredNearbyJobs = [...state.jobs.nearby, ...action.jobs]
            const jobIDs = {}
            const filteredNearbyJobs = []
            unfilteredNearbyJobs.forEach(val => {
                if (!jobIDs[val.id]) {
                    jobIDs[val.id] = true
                    filteredNearbyJobs.push(val)
                }
            })
            return merge(
                {},
                state,
                {
                    jobs: {
                        ...state.jobs,
                        nearby: filteredNearbyJobs
                    }
                },
                {
                    paginatedJobs: {
                        ...state.paginatedJobs,
                        nearby: {
                            ...state.paginatedJobs.nearby,
                            [action.page]: action.jobs,
                        }
                    }
                }
            );
        }
        case CLEAR_PAGINATED_NEARBY_JOBS: {
            const tempState = { ...state }
            if (tempState.paginatedJobs.nearby) delete tempState.paginatedJobs.nearby
            return merge(
                {},
                tempState,
                {
                    paginatedJobs: {
                        ...state.paginatedJobs,
                        nearby: {
                            totalCount: 0,
                        }
                    }
                }
            );
        }
        case RECEIVE_PENDING_JOBS: {
            return merge({}, state, { jobs: {
                ...state.jobs,
                pending: action.jobs
            } });
        }
        case RECEIVE_PENDING_JOBS_PAGE: {
            const unfilteredPendingJobs = [...state.jobs.pending, ...action.jobs]
            const jobIDs = {}
            const filteredPendingJobs = []
            unfilteredPendingJobs.forEach(val => {
                if (!jobIDs[val.id]) {
                    jobIDs[val.id] = true
                    filteredPendingJobs.push(val)
                }
            })
            return merge(
                {},
                state,
                {
                    jobs: {
                        ...state.jobs,
                        pending: filteredPendingJobs
                    }
                },
                {
                    paginatedJobs: {
                        ...state.paginatedJobs,
                        pending: {
                            ...state.paginatedJobs.pending,
                            totalCount: action.pageCount,
                            [action.page]: action.jobs,
                        }
                    }
                }
            );
        }
        case CLEAR_PAGINATED_PENDING_JOBS: {
            const tempState = { ...state }
            if (tempState.paginatedJobs.pending) delete tempState.paginatedJobs.pending
            return merge(
                {},
                tempState,
                {
                    paginatedJobs: {
                        ...state.paginatedJobs,
                        pending: {
                            totalCount: 0,
                        }
                    }
                }
            );
        }
        case RECEIVE_PAST_JOBS: {
            return merge({}, state, { jobs: {
                ...state.jobs,
                past: action.jobs
            } });
        }
        case RECEIVE_PAST_JOBS_PAGE: {
            const unfilteredPastJobs = [...state.jobs.past, ...action.jobs]
            const jobIDs = {}
            const filteredPastJobs = []
            unfilteredPastJobs.forEach(val => {
                if (!jobIDs[val.id]) {
                    jobIDs[val.id] = true
                    filteredPastJobs.push(val)
                }
            })
            return merge(
                {},
                state,
                {
                    jobs: {
                        ...state.jobs,
                        past: filteredPastJobs
                    }
                },
                {
                    paginatedJobs: {
                        ...state.paginatedJobs,
                        past: {
                            ...state.paginatedJobs.past,
                            totalCount: action.pageCount,
                            [action.page]: action.jobs,
                        }
                    }
                }
            );
        }
        case RECEIVE_IN_PROGRESS_JOBS: {
            return merge({}, state, { jobs: {
                ...state.jobs,
                inProgress: action.jobs
            } });
        }
        case RECEIVE_IN_PROGRESS_JOBS_PAGE: {
            const unfilteredJobs = [...state.jobs.inProgress, ...action.jobs]
            const jobIDs = {}
            const filteredJobs = []
            unfilteredJobs.forEach(val => {
                if (!jobIDs[val.id]) {
                    jobIDs[val.id] = true
                    filteredJobs.push(val)
                }
            })
            return merge(
                {},
                state,
                {
                    jobs: {
                        ...state.jobs,
                        inProgress: filteredJobs
                    }
                },
                {
                    paginatedJobs: {
                        ...state.paginatedJobs,
                        inProgress: {
                            ...state.paginatedJobs.inProgress,
                            totalCount: action.pageCount,
                            [action.page]: action.jobs,
                        }
                    }
                }
            );
        }
        case CLEAR_PAGINATED_IN_PROGRESS_JOBS: {
            const tempState = { ...state }
            if (tempState.paginatedJobs.inProgress) delete tempState.paginatedJobs.inProgress
            return merge(
                {},
                tempState,
                {
                    paginatedJobs: {
                        ...state.paginatedJobs,
                        inProgress: {
                            totalCount: 0,
                        }
                    }
                }
            );
        } 
        case RECEIVE_EXTERNAL_JOBS: {
            return merge({}, state, { jobs: {
                ...state.jobs,
                external: action.jobs
            } });
        }
        case RECEIVE_BIDDED_JOBS: {
            return merge({}, state, { jobs: {
                ...state.jobs,
                bidded: action.jobs
            } });
        }
        case RECEIVE_BIDDED_JOBS_PAGE: {
            const unfilteredJobs = [...state.jobs.bidded, ...action.jobs]
            const jobIDs = {}
            const filteredJobs = []
            unfilteredJobs.forEach(val => {
                if (!jobIDs[val.id]) {
                    jobIDs[val.id] = true
                    filteredJobs.push(val)
                }
            })
            return merge(
                {},
                state,
                {
                    jobs: {
                        ...state.jobs,
                        bidded: filteredJobs
                    }
                },
                {
                    paginatedJobs: {
                        ...state.paginatedJobs,
                        bidded: {
                            ...state.paginatedJobs.bidded,
                            totalCount: action.pageCount,
                            [action.page]: action.jobs,
                        }
                    }
                }
            );
        }
        case CLEAR_PAGINATED_BIDDED_JOBS: {
            const tempState = { ...state }
            if (tempState.paginatedJobs.bidded) delete tempState.paginatedJobs.bidded
            return merge(
                {},
                tempState,
                {
                    paginatedJobs: {
                        ...state.paginatedJobs,
                        bidded: {
                            totalCount: 0,
                        }
                    }
                }
            );
        }
        case RECEIVE_VALID_UNITS: {
            return merge({}, state, { units: {
                weight: action.units.weight,
                volume: action.units.volume,
                length: action.units.length,
                time: action.units.time,
                temperature: [TemperatureUnits.F, TemperatureUnits.C]
            } });
        }
        case RECEIVE_CURRENCIES: {
            return merge({}, state, { currencies: action.currencies });
        }
        case RECEIVE_SUPPORTED_COUNTRIES: {
            return merge({}, state, { supportedCountries: action.countries });
        }
        case RECEIVE_MATERIALS: {
            return merge({}, state, { materials: action.materials });
        }
        case RECEIVE_MATERIAL_TAGS: {
            return merge({}, state, { materialTags: action.tags });
        }
        case RECEIVE_CUSTOM_MATERIALS: {
            const tempState = merge({}, state)
            delete tempState.customMaterialListNames;
            delete tempState.customMaterials;

            const materialIDMap = {}
            Object.values(action.customMaterials).forEach(materialArray => {
                materialArray.forEach(material => {
                    materialIDMap[material.id] = material
                })
            })
            return merge(tempState, { 
                customMaterials: action.customMaterials,
                customMaterialListNames: Object.keys(action.customMaterials),
                customMaterialsByID: materialIDMap
            });
        }
        case UPSERT_CUSTOM_MATERIAL: {
            const tempState = merge({}, state)
            console.log("upserting", tempState, action)

            // if we can find the material, update it
            if (tempState.customMaterials[action.customMaterial.list_name]?.find(cm => cm.id === action.customMaterial.id)) {
                tempState.customMaterials = {
                    ...tempState.customMaterials,
                    [action.customMaterial.list_name]: [...tempState.customMaterials[action.customMaterial.list_name].map(cm => {
                        if (cm.id === action.customMaterial.id) {
                            return {
                                ...cm,
                                ...action.customMaterial
                            }
                        } 
                        return cm
                    })]
                }
            }
            if (tempState.customMaterialsByID[action.customMaterial.id]) {
                tempState.customMaterialsByID[action.customMaterial.id] = action.customMaterial
            }
            return tempState
        }
        case RECEIVE_VEHICLES: {
            const vehiclesById = {}
            action.vehicles.forEach(vehicle => {
                vehiclesById[vehicle.id] = vehicle
            })
            return merge({}, state, { vehicles: action.vehicles, vehiclesById });
        }
        case LOGOUT_CURRENT_USER: {
            return nullState;
        }
        case SELECT_COMPANY: {
            return nullState;
        }
        case RECEIVE_DISPATCH_ORDERS_PAGE: {
            return merge({}, state, {
                paginatedDispatchOrders: {
                    ...state.paginatedDispatchOrders,
                    totalCount: action.totalCount,
                    [action.page]: action.dispatchOrders,
                }
            })
        }
        case RECEIVE_ALL_DISPATCH_ORDERS: {
            return merge({}, state, {
                dispatchOrders: action.dispatchOrders
            })
        }
        case RECEIVE_INSPECTOR_TOKENS: {
            const tempState = { ...state }
            delete tempState.inspectorAssignments

            const assignments = {}
            action.inspectorAssignments.forEach(assignment => {
                assignments[assignment.job_id.toString()] = assignment
            })

            return merge({}, tempState, { inspectorAssignments: assignments })
        }
        case TRIGGER_INSPECTOR_FEED_UPDATE: {
            return merge({}, state, {
                triggers: {
                    ...state.triggers,
                    inspectorFeed: action.shouldUpdate
                }
            })
        }
        
        default: return state;
    }
}
export default entitiesReducer;