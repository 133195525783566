import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import Helmet from 'react-helmet';

import {
    Avatar,
    Checkbox,
    FormControl,
    FormControlLabel,
    Input,
    InputLabel,
    Button as MuiButton,
    Paper,
    Typography,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { login, popAlert } from '../../redux/actions/sessionActions';

const Button = styled(MuiButton)(spacing);

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${props => props.theme.spacing(5)}px;
`;

class SecurityAuth extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            password: "",
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(field) {
        return event => this.setState({ [field]: event.target.value });
    }

    handleSubmit(e) {
        e.preventDefault()
        const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;
        if (env == 'dev' || env == 'development') {
            // aupa
            if (this.state.password == 'ggVWa&z#"M}$uCw4%TFGM%5M-@x47~') {
                this.props.passedSecurityValidation(true)
                return;
            }
        }
    }
    render() {
        const t = this.props.t

        return (
            <Wrapper>
                <Helmet title={t(`authTranslations.signIn`)} />
                <form onSubmit={this.handleSubmit}>
                    <FormControl margin="normal" required fullWidth>
                        <TextField
                            id="password"
                            label={t(`userTranslations.password`)}
                            value={this.state.password}
                            onChange={this.handleChange("password")}
                            type="password"
                            required
                            autoComplete="password"
                            error={!this.state.password}
                        />
                    </FormControl>
                    <Button fullWidth color="primary" variant="contained" type="submit">
                        {t(`authTranslations.signIn`)}
                    </Button>
                </form>
            </Wrapper>
        );
    }
}

const msp = state => ({
    helpers: state.helpers
})
const mdp = dispatch => ({
    login: user => dispatch(login(user)),
    popAlert: (type, title, message) => dispatch(popAlert(type, title, message))
})
export default connect(msp, mdp)(withTranslation()(SecurityAuth));
