import { List, ListItem } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Paragraph, SectionHeader } from ".";
import { AuthLayoutWrapper } from "../../layouts/Auth";
import { Typography } from "../dashboards/Util/SpacedComponents";

interface Props {

}

const MTSPrivacyPolicy = ({}: Props) => {
    return (
        <AuthLayoutWrapper>

            <Helmet title={"Terms Of Service - MyTruckScales"} />
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
                USER PRIVACY STATEMENT
            </Typography>
            <Typography mb={4}>
                Effective as of June 16, 2023
            </Typography>
            <Paragraph>
                TruckPay Inc. (“TruckPay”, “we” or “us”) collects information about you when you use our mobile 
                applications, websites, point of sale (POS) system, and other online products and services 
                related to our MyTruckScales service (collectively, our “Services”), which may be provided through 
                an app and/or as web-based service (collectively, the “MyTruckScales App”) and through other 
                interactions and communications you have with us. This Privacy Statement (this “Statement”) applies 
                to persons anywhere in the world who use our Services (“Users”), whether that person is using our 
                Services to obtain or manage weighment services (a “Trucker User”), using our Services in providing 
                or managing weighment services (a “Scale Owner”), or is a contractor or other person using our Services 
                (“Contractors”) to hire and pay a Trucker User to pick up or drop off loads at scale locations of a 
                Scale Owner.
            </Paragraph>

            <SectionHeader bold>
                Collection of Information
            </SectionHeader>

            <SectionHeader>
                Information You Provide to Us
            </SectionHeader>
            <Paragraph>
                We collect information you provide directly to us, such as when you create or modify your
                account, request on-demand services, contact customer support, or otherwise communicate with
                us. This information may include: name, email, phone number, postal address, profile picture,
                payment method, items requested (for delivery services), delivery notes, and other information
                you choose to provide.
            </Paragraph>

            <SectionHeader>
                Information We Collect Through Your Use of Our Services
            </SectionHeader>
            <Paragraph>
                When you use our Services, we collect information about you in the following general
                categories:
            </Paragraph>
            <Paragraph> 
                <i>Transaction Information.</i> We collect transaction details related to your use of our Services,
                including the type of service requested, the location at which a Trucker User requests Services,
                weight information, date and time the service was provided, amount charged, and other related
                transaction details.
            </Paragraph>
            <Paragraph> 
                <i>Scale and Related Information.</i> We collect from Scale Owners information about their scales, 
                virtual kiosks, weighment workflows, payment methods, and similar information.
            </Paragraph>
            <Paragraph>
                <i>Financial Information.</i> TruckPay uses Plaid Technologies, Inc. (“Plaid”) and Stripe, Inc.
                (“Stripe”) to gather Users’ data from financial institutions and process payments. By using our
                Services, you grant TruckPay, Plaid and Stripe the right, power and authority to act on your
                behalf to access and transmit your personal and financial information from the relevant financial
                institution. You agree to your personal and financial information being transferred, stored and
                processed by Stripe in accordance with the Stripe Privacy Policy, which can be found at
                https://stripe.com/privacy. You agree to your personal and financial information being
                transferred, stored and processed by Plaid in accordance with the Plaid Privacy Policy, which can
                be found at https://plaid.com/legal.
            </Paragraph>
            <Paragraph>
                <i>Location Information.</i> We collect precise location data about where the Services are provided,
                and a Trucker User’s location relative to the location of scales using the Service, are provided
                from the MyTruckScales App whenever it is used by a User. If you permit the MyTruckScales
                App to access location services through the permission system used by your mobile operating
                system (your “mobile platform”), we may also collect the precise location of a Trucker User’s
                device when the MyTruckScales App is running in the foreground or background. We may also
                derive your approximate location from your IP address.
            </Paragraph>
            <Paragraph>
                <i>Usage and Preference Information.</i> We collect information about how you and site visitors
                interact with our Services, including information about your usage patterns, preferences
                expressed, and settings selected. In some cases, we collect information through the use of
                “cookies”, tracking pixels, and similar technologies to understand how you navigate through the
                MyTruckScales App and to save your preferences. Cookies are small text files that web servers
                place on your device; they are designed to store basic information and to help websites and apps
                recognize your browser. We may use both session cookies and persistent cookies. A session
                cookie disappears after you close your browser. A persistent cookie remains after you close your
                browser and may be accessed every time you use the MyTruckScales App.
            </Paragraph>
            <Paragraph>
                <i>Device Information.</i> We may collect information about your mobile device, including, for
                example, the hardware model, operating system and version, software and file names and
                versions, preferred language, unique device identifier, advertising identifiers, serial number,
                device motion information, and mobile network information.
                
            </Paragraph>
            <Paragraph>
                <i>Call and SMS Data.</i> Our Services facilitate communications between Trucker Users and Scale
                Owners. In connection with facilitating this service, we receive call data, including the date and
                time of the call or SMS message, the parties’ phone numbers, and the content of the SMS
                message.
            </Paragraph>
            <Paragraph>
                <i>Log Information.</i> When you interact with the Services, we collect server logs, which may
                include information like device IP address, access dates and times, features used or pages
                viewed, service crashes and other system activity, type of browser, and the third-party site or
                service you were using before interacting with our Services.
            </Paragraph>
            <Paragraph>
                <i>Other Sources.</i> We may also receive information from other sources and combine that with
                information we collect through our Services. For example, if you choose to link, create, or log in
                to your TruckPay account with a payment provider (e.g., Google Wallet) or if you engage with a
                separate app or website that uses our API (or whose API we use), we may receive information
                about you or your connections from that site or app.
            </Paragraph>

            <SectionHeader>
                Use of Information
            </SectionHeader>
            <Paragraph noBottomMargin>
                We may use the information we collect about you to:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • Provide, maintain, and improve our Services, including, for example, to facilitate
                    payments, send receipts, provide products and services you request (and send related
                    information), develop new features, provide customer support to Users, authenticate
                    users, and send product updates and administrative messages;
                </ListItem>
                <ListItem>
                    • Perform internal operations, including, for example, to prevent fraud and abuse of our
                    Services; to troubleshoot software bugs and operational problems; to conduct data
                    analysis, testing, and research; and to monitor and analyze usage and activity trends;
                </ListItem>
                <ListItem>
                    • Send or facilitate communications (i) between a Trucker User and a Scale Owner, or
                    (ii) between you and a contact of yours at your direction in connection with your use of
                    certain features;
                </ListItem>
                <ListItem>
                    • Send you communications we think will be of interest to you, including information
                    about products, services, promotions, news, and events of TruckPay and other companies,
                    where permissible and according to local applicable laws; and to process contest,
                    sweepstake, or other promotion entries and fulfill any related awards;
                </ListItem>
                <ListItem>
                    • Personalize and improve the Services, including to provide or recommend features,
                    content, social connections, referrals, and advertisements.
                </ListItem>
                <ListItem>
                    • We may transfer the information described in this Statement to, and process and store it
                    in, the United States and other countries, some of which may have less protective data
                    protection laws than the region in which you reside. Where this is the case, we will take
                    appropriate measures to protect your personal information in accordance with this
                    Statement.
                </ListItem>
            </List>

            <SectionHeader>
                Sharing of Information
            </SectionHeader>
            <Paragraph noBottomMargin>
                We may share the information we collect about you as described in this Statement or as
                described at the time of collection or sharing, including as follows:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • With other Users to facilitate and allow them to fulfill your arrangement with them made
                    using the Services;
                </ListItem>
                <ListItem>
                    • With third parties to provide you a service you requested through a partnership or
                    promotional offering made by a third party or us; and
                </ListItem>
                <ListItem>
                    • With third parties with whom you choose to let us share information, for example other
                    apps or websites that integrate with our API or Services, or those with an API or Service
                    with which we integrate; and
                </ListItem>
            </List>
            <Paragraph noBottomMargin>
                We also may share your information:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • With TruckPay subsidiaries and affiliated entities that provide services or conduct data
                    processing on our behalf, or for data centralization or logistical purposes;
                </ListItem>
                <ListItem>
                    • With vendors, consultants, marketing partners, and other service providers who need
                    access to such information to carry out work on our behalf;
                </ListItem>
                <ListItem>
                    • In response to a request for information by a competent authority if we believe disclosure
                    is in accordance with, or is otherwise required by, any applicable law, regulation, or legal
                    process;
                </ListItem>
                <ListItem>
                    • With law enforcement officials, government authorities, or other third parties if we
                    believe your actions are inconsistent with our User agreements, terms of service, or
                    policies, or to protect the rights, property, or safety of TruckPay or others;
                </ListItem>
                <ListItem>
                    • In connection with, or during negotiations of, any merger, sale of company assets,
                    consolidation or restructuring, financing, or acquisition of all or a portion of our business
                    by or into another company;
                </ListItem>
                <ListItem>
                    • If we otherwise notify you and you consent to the sharing; and
                </ListItem>
                <ListItem>
                    • In an aggregated and/or anonymized form which cannot reasonably be used to identify
                    you.
                </ListItem>
            </List>

            <SectionHeader>
                Analytics and Advertising Services Provided by Others
            </SectionHeader>
            <Paragraph>
                We may allow others to provide audience measurement and analytics services for us, to serve
                advertisements on our behalf across the Internet, and to track and report on the performance of
                those advertisements. These entities may use cookies, web beacons, SDKs, and other
                technologies to identify your device when you visit our site and use our Services, as well as
                when you visit other online sites and services.
            </Paragraph>

            <SectionHeader>
                Your Choices
            </SectionHeader>
            <Paragraph>
                <i>Your Account and Other Information</i> — You may correct your account information at any time by logging into
                your online or in-app account. If you wish to cancel your account, please email us at
                support@mytruckscales.com. Please note that in some cases we may retain certain information
                about you as required by law, or for legitimate business purposes to the extent permitted by law.
                For instance, if you have a standing credit or debt on your account, or if we believe you have
                committed fraud or violated our Terms, we may seek to resolve the issue before deleting your
                information.
            </Paragraph>
            <Paragraph>
                <i>Access Rights</i> - TruckPay will comply with individual’s requests regarding access, correction, and/or 
                deletion of the personal data it stores in accordance with applicable law.
            </Paragraph>
            <Paragraph>
                <i>Location Information</i> - We request permission for our app’s collection of precise location from Users’ 
                devices per the permission system used by your mobile operating system. If you initially permit the collection 
                of this information, you can later disable it by changing the location settings on your mobile device. However, 
                this will limit your ability to use certain features of our Services.
            </Paragraph>
            <Paragraph>
                <i>Contact Information</i> — We may also seek permission for our app’s collection and syncing of
                contact information from your device per the permission system used by your mobile operating
                system. If you initially permit the collection of this information, iOS users can later disable it by
                changing the contacts settings on your mobile device. The Android platform does not provide
                such a setting.
            </Paragraph>
            <Paragraph>
                <i>Promotional Communications</i> — You may opt out of receiving promotional messages from us by
                following the instructions in those messages. If you opt out, we may still send you nonpromotional communications, 
                such as those about your account, about Services you have requested, or our ongoing business relations.
            </Paragraph>
            <Paragraph>
                <i>Cookies and Advertising</i> — You can change the settings in your web browser or Mobile Platform
                to modify your cookie settings.
            </Paragraph>

            <SectionHeader>
                Changes to the Statement
            </SectionHeader>
            <Paragraph>
                We may change this Statement from time to time. If we make significant changes in the way we
                treat your personal information, or to the Statement, we will provide you notice through the
                Services or by some other means, such as email. Your continued use of the Services after such
                notice constitutes your consent to the changes. We encourage you to periodically review the
                Statement for the latest information on our privacy practices.
            </Paragraph>

            <SectionHeader>
                Contact Us
            </SectionHeader>
            <Paragraph>
                If you have any questions about this Privacy Statement, please contact us at
                privacy@mytruckscales.com.
            </Paragraph>

        </AuthLayoutWrapper>
    )

}

export default MTSPrivacyPolicy