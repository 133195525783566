import React, { useEffect, useState } from 'react';
import {
    Modal,
    Tab,
    Tabs,
    TabScrollButton,
    Paper as MuiPaper,
    AppBar,
    Toolbar,
    Typography as MuiTypography,
    Grid as MuiGrid,
    FormGroup,
    FormControl as MuiFormControl,
    FormControlLabel,
    FormLabel,
    FormHelperText,
    Input,
    InputLabel,
    Select,
    TextField,
    MenuItem,
    Button,
    Switch,
    Radio,
    RadioGroup,
    Divider as MuiDivider,
    InputAdornment,
    Box as MuiBox,
    IconButton,
} from '@material-ui/core';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import Autocomplete from '@material-ui/lab/Autocomplete'
import TPModal from '../../Util/TPModal';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next'
import {
    Language as WorldIcon,
} from '@material-ui/icons';
import { getPhoneData, CustomPhoneNumberInput } from '../../Util/PhoneInput';
import { popAlert } from '../../../../redux/actions/sessionActions';
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import NotificationSnackbar from '../../Util/Snackbar';
import { TPLoader } from '../../../components/Progress';
import { getCompanySites } from '../../../../redux/actions/siteActions';
import { getMaterials, getValidUnits } from '../../../../redux/actions/adminActions';
import { DEPRECATED_getCustomMaterials } from '../../../../redux/actions/companyActions';
import { 
    Material,
    CustomMaterialMap,
    Site,
    DeliveryApi,
    Company,
    UnitMap,
    WeightUnit,
    VolumeUnit,
    CustomMaterial,
    BaseMixMaterial,
} from '../../../../redux/reducers/entities';
import { UserSettings } from '../../../../redux/reducers/entities/User';
import AddCustomMaterialModal from './ModalAddEditCustomMaterial';

const Paper = styled(MuiPaper)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Box = styled(MuiBox)(spacing);
const Bold = styled.span`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: black;
`;

interface OwnProps {
    addConstituent: (data: BaseMixMaterial) => void;
    setModalOpen: (bool: boolean) => void;
    t: (key: string) => string;
    onlyCustomMaterials?: boolean;
    forDesign?: boolean;
}
interface OwnState {
    isCustomMaterial: boolean;
    material: Material | null;
    customMaterial: CustomMaterial | null;
    searchMaterial: string;
    searchCustomMaterial: string;
    customMaterialListName: string;
    weight: number | string;
    weight_units: WeightUnit;
    volume: number | string;
    volume_units: VolumeUnit;
    openCreateCustomMaterialModal: boolean;
    primary_measurement_type: "weight" | "volume"
}
type Props = OwnProps & ReduxState & ReduxProps

class AddConstituentModal extends React.Component<Props, OwnState> {
    public mounted: boolean = false;
    public state: OwnState = {
        isCustomMaterial: true,
        material: null,
        customMaterial: null,
        searchMaterial: "",
        searchCustomMaterial: "",
        customMaterialListName: "",
        weight: "",
        weight_units: this.props.userSettings.default_weight_unit || "",
        volume: "",
        volume_units: this.props.userSettings.default_volume_unit || "",
        primary_measurement_type: "weight",

        openCreateCustomMaterialModal: false,
    };

    componentDidMount = () => this.mounted = true
    setStateIfMounted = (state: any, callback?: any) => {
        if (this.mounted) {
            this.setState(state, callback)
        }
    }
    componentWillUnmount = () => this.mounted = false

    handleChange = this.props.helpers.setupHandleChange(this)

    setCreateCustomMaterialModal = (bool: boolean) => this.setStateIfMounted({ openCreateCustomMaterialModal: bool })


    handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        const material = !this.state.isCustomMaterial ? this.state.material! : undefined
        const customMaterial = this.state.isCustomMaterial ? this.state.customMaterial! : undefined
        const postData: BaseMixMaterial = {
            material: material,
            material_id: material?.id || null,
            customMaterial: customMaterial,
            custom_material_id: customMaterial?.id || null,
            weight: parseFloat(this.state.weight.toString()) || 0,
            weight_units: this.state.weight_units || null,
            volume: parseFloat(this.state.volume.toString()) || 0,
            volume_units: this.state.volume_units || null,
            primary_measurement_type: this.state.primary_measurement_type,

        }
        if (this.state.weight && this.state.weight_units) {
            postData.weight = Number(this.state.weight);
            postData.weight_units = this.state.weight_units;
        }
        if (this.state.volume && this.state.volume_units) {
            postData.volume = Number(this.state.volume);
            postData.volume_units = this.state.volume_units;
        }
        if (this.state.isCustomMaterial && this.state.customMaterial) {
            postData.custom_material_id = this.state.customMaterial.id
            postData.customMaterial = this.state.customMaterial || undefined
        } else if (this.state.material) {
            postData.material_id = this.state.material.id
            postData.material = this.state.material || undefined
        }
        this.props.addConstituent(postData)
    }
    
    render() {
        return (
            <TPModal setModalOpen={this.props.setModalOpen} headerOptions={{ headerText: "Add Material Constituent" }}>
                <form autoComplete="off" onSubmit={this.handleSubmit}>
                    <Grid container spacing={10}>
                        {!this.props.onlyCustomMaterials && <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<WorldIcon />}
                                onClick={() => this.setStateIfMounted({ isCustomMaterial: !this.state.isCustomMaterial })}
                            >
                                {this.state.isCustomMaterial ? this.props.t('materialTranslations.materials') : this.props.t('materialTranslations.customMaterials')}
                            </Button>
                        </Grid>}
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<WorldIcon />}
                                onClick={() => this.setCreateCustomMaterialModal(true)}
                            >
                                {this.props.t('materialTranslations.actions.addCustomMaterial')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10}>
                        {this.state.isCustomMaterial ? <>
                            <Grid item xs={12} md>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="job_custom_material_list_name">{this.props.t('materialTranslations.customListName')}</InputLabel>
                                    <Select
                                        label={this.props.t('materialTranslations.customListName')}
                                        labelId="job_custom_material_list_name"
                                        value={this.state.customMaterialListName}
                                        onChange={this.handleChange('customMaterialListName')}
                                        inputProps={{
                                            name: "job_custom_material_list_name",
                                            id: "job_custom_material_list_name"
                                        }}
                                        fullWidth
                                    >
                                        {this.props.customMaterialListNames.map(listName => (
                                            <MenuItem key={listName} value={listName}>{listName}</MenuItem>
                                        ))}
                                    </Select>
                                    {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                </FormControl>
                            </Grid>
                            {this.state.customMaterialListName && <Grid item xs={12} md>
                                <FormControl fullWidth variant="outlined">
                                    <Autocomplete
                                        id="job_custom_material"
                                        disableClearable
                                        autoComplete
                                        clearOnBlur={false}
                                        options={this.props.customMaterials[this.state.customMaterialListName]}
                                        getOptionLabel={(mat) => {
                                            if (mat.is_hazardous) {
                                                return `${mat.custom_material_number ? `(${mat.custom_material_number}) ` : ""}${mat.name} - ${this.props.t('materialTranslations.hazardous')}`
                                            } else if (mat.is_inspectable) {
                                                return `${mat.custom_material_number ? `(${mat.custom_material_number}) ` : ""}${mat.name} - ${this.props.t('materialTranslations.inspectable')}`
                                            }
                                            return mat.name
                                        }}
                                        onChange={(event, val) => this.setStateIfMounted({ customMaterial: val })}
                                        onInputChange={(event, val, reason) => {
                                            if (reason != 'reset' || val) {
                                                this.setStateIfMounted({ searchCustomMaterial: val })
                                            }
                                        }}
                                        inputValue={this.state.searchCustomMaterial}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="searchCustomMaterial"
                                                label={this.props.t('materialTranslations.customMaterials')}
                                                type="text"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>}
                        </> : <>
                            <Grid item xs={12} md>
                                <FormControl fullWidth variant="outlined">
                                    <Autocomplete
                                        id="job_custom_matejob_materialrial"
                                        disableClearable
                                        autoComplete
                                        clearOnBlur={false}
                                        options={this.props.materials}
                                        getOptionLabel={(mat: Material) => {
                                            if (mat.is_hazardous) {
                                                return `${mat.name} - ${this.props.t('materialTranslations.hazardous')}`
                                            } else if (mat.is_inspectable) {
                                                return `${mat.name} - ${this.props.t('materialTranslations.inspectable')}`
                                            }
                                            return mat.name
                                        }}
                                        onChange={(event, val) => this.setStateIfMounted({ material: val })}
                                        onInputChange={(event, val, reason) => {
                                            if (reason != 'reset' || val) {
                                                this.setStateIfMounted({ searchMaterial: val })
                                            }
                                        }}
                                        inputValue={this.state.searchMaterial}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="searchMaterial"
                                                label={this.props.t('materialTranslations.materials')}
                                                type="text"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </>}
                    </Grid>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="weight"
                                    label={`Weight`}
                                    value={this.state.weight}
                                    onChange={this.handleChange("weight")}
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                    }}
                                    variant="outlined"
                                    required={this.state.primary_measurement_type === 'weight'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="weight_units">{this.props.t('unitTranslations.weightUnit')}</InputLabel>
                                <Select
                                    label={this.props.t('unitTranslations.weightUnit')}
                                    labelId="weight_units"
                                    value={this.state.weight_units}
                                    onChange={this.handleChange('weight_units')}
                                    inputProps={{
                                        name: "weight_units",
                                        id: "weight_units"
                                    }}
                                    fullWidth
                                    required={this.state.primary_measurement_type === 'weight'}
                                    error={this.state.primary_measurement_type === 'weight' && !this.state.weight_units}
                                >
                                    {this.props.units.weight && this.props.units.weight.map(unit => (
                                        <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.weightUnits.${unit}`)}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="volume"
                                    label={`Volume`}
                                    value={this.state.volume}
                                    onChange={this.handleChange("volume")}
                                    type="number"
                                    required={this.state.primary_measurement_type === 'volume'}
                                    inputProps={{
                                        min: 0,
                                    }}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="volume_units">{this.props.t('unitTranslations.volumeUnit')}</InputLabel>
                                <Select
                                    label={this.props.t('unitTranslations.volumeUnit')}
                                    labelId="volume_units"
                                    value={this.state.volume_units}
                                    onChange={this.handleChange('volume_units')}
                                    inputProps={{
                                        name: "volume_units",
                                        id: "volume_units"
                                    }}
                                    required={this.state.primary_measurement_type === 'volume'}
                                    error={this.state.primary_measurement_type === 'volume' && !this.state.volume_units}
                                    fullWidth
                                >
                                    {this.props.units.volume && this.props.units.volume.map(unit => (
                                        <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.volumeUnits.${unit}`)}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10}>
                        <Grid item xs>
                            <FormControl>
                                <FormLabel component="legend">Primary Measurement</FormLabel>
                                <RadioGroup
                                    id="primary_measurement"
                                    name="primary_measurement"
                                    aria-label="primary_measurement"
                                    value={this.state.primary_measurement_type}
                                    onChange={this.handleChange("primary_measurement_type")}
                                    row
                                >
                                    <FormControlLabel
                                        value="weight"
                                        control={<Radio color="primary" required />}
                                        label={this.props.t('unitTranslations.weight')}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="volume"
                                        control={<Radio color="primary" required />}
                                        label={this.props.t('unitTranslations.volume')}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10}>
                        <Grid item xs>
                            <Button variant="contained" color="primary" fullWidth type="submit">
                                {this.props.t('actions.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                {this.state.openCreateCustomMaterialModal && <AddCustomMaterialModal 
                    setModalOpen={this.setCreateCustomMaterialModal}
                    listName={this.state.customMaterialListName}
                    enableListNameChange={true}
                />}
            </TPModal>
        )
    }
    
}
interface ReduxProps {
    popAlert: (success: string, title: string, message: string) => void;
}
interface ReduxState {
    helpers: any;
    userSettings: UserSettings;
    units: UnitMap;
    materials: Material[];
    customMaterialListNames: string[];
    customMaterials: CustomMaterialMap;
}
const msp = (state: any) => ({
    helpers: state.helpers,
    userSettings: state.session.user.settings,
    units: state.entities.units,
    materials: state.entities.materials,
    customMaterials: state.entities.customMaterials,
    customMaterialListNames: state.entities.customMaterialListNames,
})
const mdp = (dispatch: any) => ({
    popAlert: (success: string, title: string, message: string) => dispatch(popAlert(success, title, message)),
})
export default connect<ReduxState, ReduxProps>(msp, mdp)(withTranslation()(AddConstituentModal))