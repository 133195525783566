import React from "react";
import {
    FormControl,
    Grid, TextField, TextFieldProps
} from "@material-ui/core";
import { useDispatch } from "react-redux";

/**
 * Renders an MUI TextField wrapped in a FormControl
 * 
 * __Props__ \
 * - label: string
 * - onChange: (event) => void;
 * - value?: string | number;
 * - textfieldProps: TextFieldProps
 */
const TpTextfield = ({
    label,
    onChange,
    value,
    textfieldProps = {},
    required = false,
}: Props) => {
    return <FormControl fullWidth>
        <TextField 
            variant="outlined"
            color="primary"
            label={label}
            onChange={onChange}
            value={value}
            {...textfieldProps}
            required={required}
        />
    </FormControl>
}
interface Props {
    label: string;
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    value?: string | number;
    required?: boolean;
    textfieldProps?: TextFieldProps
}

export default TpTextfield