import { Button, FormControlLabel, FormGroup, FormLabel, IconButton, Radio, RadioGroup, Switch, TextField } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import React, { FormEvent, useState } from "react";
import { Download } from "react-feather";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useRootSelector } from "../../../..";
import { popAlert } from "../../../../redux/actions/sessionActions";
import { parseError } from "../../../../redux/reducers/helpersReducerr";
import { exportDeliveries, ExportDeliveriesPayload } from "../../../../redux/util/deliveryAPI";
import Separator from "../../Util/Separator";
import { FormControl, Grid } from "../../Util/SpacedComponents";
import TPDialog from "../../Util/TPDialog";

interface Props {
    setModalOpen: (open: boolean) => void;
    deliveryIds?: number[];
    jobIds?: number[];
}
/**
 * UI for `Export Deliveries` API
 * 
 * **__PROPS__**
 * 
 * - deliveryIds?: number[];
 * - jobIds?: number[];
 */
const ExportDeliveriesModal = ({
    setModalOpen,
    deliveryIds,
    jobIds
}: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const token = useRootSelector(state => state.session.user.token!!)
    const companyId = useRootSelector(state => state.session.company.id!!)

    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState("")

    const [payload, setPayload] = useState<{
        action: "email" | "download"
        job_ids?: number[];
        delivery_ids?: number[];
        include_inspections: boolean;
        start_date?: Date;
        end_date?: Date;
        email_addresses?: string[];
        export_format: "xlsx" | "pdf" | "csv";
    }>({
        action: "email",
        job_ids: jobIds,
        delivery_ids: deliveryIds,
        include_inspections: true,
        start_date: new Date(),
        end_date: new Date(),
        email_addresses: [],
        export_format: "xlsx"
    })

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()

        const data: ExportDeliveriesPayload = {
            action: payload.action,
            include_inspections: payload.include_inspections,
            export_format: payload.export_format,
            delivery_ids: deliveryIds
        }
        if (payload.action === "email") {
            data.email_addresses = payload.email_addresses
        }

        try {
            const response = await exportDeliveries({ token, companyId, data })
            if (!response.success) {
                dispatch(popAlert('error', t('error'), parseError(response)))
                return
            }
            if (payload.action === "download") {
                const link = document.createElement("a");
                link.download = `TruckPay_Export.${payload.export_format}`;
                link.href = response.data;
                console.log("response", response.data);
                
                link.click();
            }
            dispatch(popAlert('success', t('success'), "Exported deliveries successfully"))
            setModalOpen(false)
        } catch (e) {
            dispatch(popAlert('error', t('error'), e))
        }
    }

    const addEmail = () => {
        if (payload.email_addresses?.includes(email)) return
        setPayload(curVal => ({ ...curVal, email_addresses: [...curVal.email_addresses || [], email]}))
        setEmail("")
    }

    return <TPDialog
        setModalOpen={setModalOpen}
    >
        <form onSubmit={handleSubmit}>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Action</FormLabel>
                        <RadioGroup
                            aria-label="export_action"
                            name="export_action"
                            value={payload.action}
                            onChange={(_, value) => setPayload(curVal => ({ ...curVal, action: value as "email" | "download" }))}
                            row
                        >
                            <FormControlLabel
                                value={"email"}
                                control={<Radio color="primary" required/>}
                                label="Email"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value={"download"}
                                control={<Radio color="primary" required/>}
                                label="Download"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel component="legend">Format</FormLabel>
                        <RadioGroup
                            aria-label="export_format"
                            name="export_format"
                            value={payload.export_format}
                            onChange={(_, value) => setPayload(curVal => ({ ...curVal, export_format: value as "xlsx" | "pdf" | "csv" }))}
                            row
                        >
                            <FormControlLabel
                                value={"xlsx"}
                                control={<Radio color="primary" required/>}
                                label="XLSX"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value={"pdf"}
                                control={<Radio color="primary" required/>}
                                label="PDF"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value={"csv"}
                                control={<Radio color="primary" required/>}
                                label="CSV"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel component="legend">Include Inspection Details</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                label={<FormLabel component="legend"></FormLabel>}
                                control={
                                    <Switch
                                        checked={payload.include_inspections}
                                        onChange={(_, checked) => setPayload(curVal => ({ ...curVal, include_inspections: checked }))}
                                        color="primary"
                                    />
                                }
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
            {payload.action === "email" && <>
                <Grid container spacing={5}>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <TextField
                                id="add_email"
                                label={`Add email`}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                type="email"
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            type="button"
                            onClick={() => addEmail()}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
                {payload.email_addresses?.map(email => <Grid container spacing={5} key={email} alignItems="center">
                    <Grid item xs>
                        {email}
                    </Grid>
                    <Grid item>
                        <IconButton>
                            <Clear />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Separator />
                    </Grid>
                </Grid>)}
            </>}
            <Grid container spacing={5}>
                <Grid item xs={12} md={6}>
                    <Button
                        color="primary"
                        variant="outlined"
                        type="button"
                        onClick={() => setModalOpen(false)}
                        fullWidth
                    >
                        {t('actions.cancel')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        fullWidth
                    >
                        {t('actions.save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    </TPDialog>


}

export default ExportDeliveriesModal