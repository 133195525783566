import React from 'react';
import {
    Modal,
    Tab,
    Tabs,
    TabContent,
    TabScrollButton,
    TabList,
    Paper as MuiPaper,
    AppBar,
    Toolbar,
    Typography as MuiTypography,
    Grid as MuiGrid,
    FormGroup,
    FormControl as MuiFormControl,
    FormControlLabel,
    FormLabel,
    FormHelperText,
    Input,
    InputLabel,
    Select,
    TextField,
    MenuItem,
    Button,
    Switch,
    Radio,
    RadioGroup,
    Divider,
    InputAdornment,
    Box as MuiBox,
} from '@material-ui/core';
import StarRatings from 'react-star-ratings';
import { DropzoneDialog } from 'material-ui-dropzone'
import { connect } from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import WorldIcon from '@material-ui/icons/Language';
import { TPLoader } from '../../../components/Progress';
import {
    KeyboardDatePicker,
    KeyboardTimePicker
} from "@material-ui/pickers";
import GoogleMapReact from 'google-map-react'
import { GMapAPIKey } from '../../../../env'
import { popAlert } from '../../../../redux/actions/sessionActions';
import {
    getSupportedCountries,
} from '../../../../redux/actions/adminActions';
import { getCompanyPositions, getLegalEntityTypes, getCompanyEntityTypes } from '../../../../redux/util/adminAPI';
import { createCompany, postLegalDoc, editCompany, getMinorityClassifications, getCompanyTIN, modifyLegalDoc } from '../../../../redux/util/companyAPI';
import { getUsersCompanies } from '../../../../redux/actions/companyActions';
import { BYPASS_TIN_CHECK } from '../../../../env';
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { withTranslation } from 'react-i18next';
import { getPhoneData, CustomPhoneNumberInput } from '../../Util/PhoneInput';
import NotificationSnackbar from '../../Util/Snackbar';
import './styles.css'
import TpTextfield from '../../Util/TPTextfield';

const Paper = styled(MuiPaper)(spacing);
const Typography = styled(MuiTypography)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Box = styled(MuiBox)(spacing);
const Bold = styled.span`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: black;
`;

const GoogleMapReactWrapper = styled.div`
  height: 300px;
  width: 100%;
`;
function SectionHeader({ title }) {
    return (<>
        <Typography variant="h2" mb={4}><Bold>{title}</Bold></Typography>
        <Divider />
    </>
    )
}
class CreateEditCompanyModal extends React.Component {
    nullState = {
        name: "",
        email_address: "",
        description: "", 
        employer_identification: "",
        entity_type_id: "",
        position: "",
        federal_tax_classification: "",
        phone_number: "",
        phone_country_code: "1",
        position_id: "",
        is_minority_company: false,
        minorityClassifications: [],
        minority_classification_id: "",
        minority_certification_id: "",
        usdot: "",
        letter_head_text: "",
        letter_head_image: "",
        default_job_terms_and_conditions: "",

        address_line_1: "",
        city: "",
        province: "",
        postal_code: "",
        latitude: "",
        longitude: "",
        country_id: "",
        
        companyPositions: [],
        legalEntityTypes: [],
        entityTypes: [],
        loading: true,
        openGLPhotoUpload: false,
        openCompanyPhotoUpload: false,
        openCompanyLetterHeadPhotoUpload: false,
        generalLiabilityExpDate: new Date(),
        displayCoordinates: false,
        file: "",
        companyFile: "",
        warnedBadAddressInput: false,
        bypass_address_verification: false,

        changedGenLiabilityInfo: false,
        currentLegalDoc: {},
    }
    constructor(props) {
        super(props);
        this.today = new Date();
        this.state = { ...this.nullState }
        this.addressInput = React.createRef();
        console.log('will bypass', BYPASS_TIN_CHECK)
    }

    prefillState = newState => {
        if (this.props.company) {
            console.log("company", this.props.company, newState)
            const company = this.props.company
            const prefilledState = { ...this.nullState }
            Object.keys(company).forEach(companyKey => {
                if (prefilledState.hasOwnProperty(companyKey) && company[companyKey]) {
                    prefilledState[companyKey] = company[companyKey];
                }
            })
            Object.keys(company.address).forEach(companyAddressKey => {
                if (prefilledState.hasOwnProperty(companyAddressKey) && company.address[companyAddressKey]) {
                    prefilledState[companyAddressKey] = company.address[companyAddressKey];
                }
            })
            // Company properties that can't be set programatically
            const override = {
                // federal_tax_classification: newState.legalEntityTypes.find(type => company.federal_tax_classification == type.name).name
                phone_number: `+${this.props.company.phone_country_code}${this.props.company.phone_number}`
            }

            if (this.props.company.legal_docs) {
                const currentLegalDoc = this.props.company.legal_docs.reverse().find(doc => doc.legal_document_type == 2 && doc.valid_general_liability_insurance)
                if (currentLegalDoc) {
                    override.currentLegalDoc = currentLegalDoc
                    override.generalLiabilityExpDate = this.props.helpers.createDateFromUnix(currentLegalDoc.expiration_date)
                }
            }

            return {
                ...prefilledState,
                ...override,
            }
        } else {
            return { ...this.nullState }
        }
    }
    componentDidMount = async () => {
        this.mounted = true;
        await Promise.all([
            getCompanyPositions(this.props.token),
            getLegalEntityTypes(this.props.token),
            getCompanyEntityTypes(this.props.token),
            getMinorityClassifications(this.props.token),
            this.props.getSupportedCountries(),
        ]).then(resArray => {
            const newState = {
                companyPositions: [],
                legalEntityTypes: [],
                entityTypes: [],
            }
            if (resArray[0].data) {
                newState.companyPositions = resArray[0].data.filter(position => position.name != 'company_truck_driver')
            }
            if (resArray[1].data) {
                newState.legalEntityTypes = resArray[1].data
            }
            if (resArray[2].data) {
                newState.entityTypes = resArray[2].data
            }
            if (resArray[3].data) {
                newState.minorityClassifications = resArray[3].data
            }
            if (this.props.company && this.props.type === 'edit') {
                const prefilledState = this.prefillState(newState)
                this.setStateIfMounted({
                    ...prefilledState,
                    ...newState,
                    loading: false
                })
            } else {
                this.setStateIfMounted({ ...newState, loading: false })
            }
        })
        if (this.props.company && this.props.type === 'edit') {
            getCompanyTIN(this.props.token, this.props.company.id).then(res => {
                if (res.success && res.data) {
                    this.setStateIfMounted({
                        employer_identification: res.data
                    })
                }
            })
        }

    }
    componentWillUnmount = () => {
        this.mounted = false;
    }
    componentDidUpdate = (prevProps, prevState) => {

        if (prevState.displayCoordinates != this.state.displayCoordinates && this.state.displayCoordinates) {
            this.connectGoogleAutocomplete()
        }
        if (
            (prevState.latitude != this.state.latitude || prevState.longitude != this.state.longitude)
            && (this.state.latitude && this.state.longitude)
        ) {
            this.updateMarkerPosition()
            if (this.state.displayCoordinates) {
                this.geocodeNewPosition({ lat: parseFloat(this.state.latitude), lng: parseFloat(this.state.longitude) })
            }
        }
        if (this.props.type == 'edit' && prevState.generalLiabilityExpDate && prevState.generalLiabilityExpDate != this.state.generalLiabilityExpDate && this.state.generalLiabilityExpDate && !this.state.changedGenLiabilityInfo) {
            this.setStateIfMounted({
                changedGenLiabilityInfo: true
            })
        }
        if (prevState.country_id != this.state.country_id && this.state.country_id) {
            this.updateAutocompleteRestriction()
        }
    }
    setStateIfMounted = (state, callback) => {
        if (this.mounted) {
            this.setState(state, callback)
        }
    }
    handleChange = (field, type) => {
        if (type) {
            if (type == 'boolean') {
                return event => this.setStateIfMounted({ [field]: event.target.checked })
            } else if (type == 'number') {
                return event => this.setStateIfMounted({ [field]: parseFloat(event.target.value) || "" })
            } else if (type == 'date') {
                return date => this.setStateIfMounted({ [field]: date })
            } else if (type == 'time') {
                return time => this.setStateIfMounted({ [field]: time })
            } else if (type == 'radio-boolean') {
                return event => this.setStateIfMounted({ [field]: event.target.value == 'true' ? true : false })
            }
        }
        return event => this.setStateIfMounted({ [field]: event.target.value });
    }
    handleFileChange = field => files => {
        this.setStateIfMounted({
            [field]: files[0],
            openGLPhotoUpload: false,
            openCompanyPhotoUpload: false,
            openCompanyLetterHeadPhotoUpload: false,
        })
    }
    handleApiLoaded = (map, maps) => {
        this.map = map;
        this.maps = maps;
        this.connectGoogleAutocomplete()
    }
    getMapOptions = maps => {
        return {
            fullscreenControl: true,
            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.ROADMAP,
            scaleControl: true,
            scrollwheel: false,
            streetViewControl: true
        };
    };
    updateAutocompleteRestriction = () => {
        const selectedCountry = this.props.supportedCountries.find(country => country.id == this.state.country_id)
        if (selectedCountry && this.addressAutocomplete) {
            this.addressAutocomplete.setComponentRestrictions({
                country: selectedCountry.code
            })
        }
    }
    connectGoogleAutocomplete = () => {
        if (this.addressInput.current && this.maps && this.maps.places) {
            if (!this.addressAutocomplete) {
                this.addressAutocomplete = new this.maps.places.Autocomplete(this.addressInput.current)
                this.autoCompleteListener = this.addressAutocomplete.addListener('place_changed', () => {
                    const selection = this.addressAutocomplete.getPlace()
                    if (selection) {
                        this.handleAddressSelect(selection.address_components, selection.geometry.location);
                    }

                    const possibleContainers = document.getElementsByClassName('pac-container');
                    if (possibleContainers[0]) {
                        this.googleAutocompleteWidget = possibleContainers[0];
                        this.googleAutocompleteWidget.style.zIndex = "1301";
                        console.log("attempted to set pac zindex")
                    }
                })

                const possibleContainers = document.getElementsByClassName('pac-container');
                if (possibleContainers[0]) {
                    this.googleAutocompleteWidget = possibleContainers[0];
                    this.googleAutocompleteWidget.style.zIndex = "1301";
                    console.log("attempted to set pac zindex!!!")
                }

            }
        }
    }
    handleAddressSelect = (addressComponents, coordinates) => {
        const parsedAddress = this.props.helpers.parseGoogleAddressComponents(addressComponents, true)
        this.setStateIfMounted({
            ...parsedAddress,
            latitude: coordinates.lat(),
            longitude: coordinates.lng(),
        })
    }
    handleMapClick = ({ x, y, lat, lng, event }) => {
        const newPosition = new this.maps.LatLng(lat, lng)
        if (this.map && this.maps) {
            this.geocodeNewPosition(newPosition, true)
        }
    }
    geocodeNewPosition = (latLng, resetCoords) => {
        console.log("geocoding new position")
        const geocoder = new this.maps.Geocoder();
        geocoder.geocode({ latLng }, (results, status) => {
            if (results.length) {
                const result = results[0];
                const parsedAddress = this.props.helpers.parseGoogleAddressComponents(result.address_components, true)
                const newState = {
                    ...parsedAddress
                }
                if (resetCoords) {
                    newState.latitude = result.geometry.location.lat()
                    newState.longitude = result.geometry.location.lng()
                }
                this.setStateIfMounted({ ...newState })
            }
        })
    }
    updateMarkerPosition = () => {
        if (this.map && this.maps) {
            const newPosition = new this.maps.LatLng(this.state.latitude, this.state.longitude)
            if (!this.marker) {
                this.marker = new this.maps.Marker({
                    position: newPosition,
                    map: this.map,
                    animation: this.maps.Animation.DROP
                })
            } else {
                this.marker.setPosition(newPosition);
            }
            this.map.setCenter(newPosition);
        }

    }

    organizePostData = async () => {
        const NULLABLE_KEYS = [
            "default_job_terms_and_conditions",
        ]
        const postData = {
            name: "",
            email_address: "",
            description: "",
            entity_type_id: "",
            position: "",
            federal_tax_classification: "",
            phone_number: "",
            phone_country_code: "",
            position_id: "",
            is_minority_company: false,
            minority_classification_id: "",
            minority_certification_id: "",
            usdot: "",
            letter_head_text: "",
            default_job_terms_and_conditions: "",

            address_line_1: "",
            city: "",
            province: "",
            postal_code: "",
            latitude: "",
            longitude: "",
            country_id: "",
            bypass_address_verification: false
        }

        // Can only update the TIN if it hasn't been verified yet
        if (!this.props.company.has_tin) {
            postData.employer_identification = ""
        }

        Object.keys(postData).forEach(key => {
            if (typeof this.state[key] == 'boolean') {
                postData[key] = this.state[key]
            } else if (this.state[key]) {
                postData[key] = this.state[key]
            } else if (NULLABLE_KEYS.includes(key)) {
                // this will only get hit for falsely valued keys (given the previous condition) that are also in the nullable list
                postData[key] = null
            }
        })

        const phoneData = getPhoneData(this.state.phone_number)
        postData.phone_number = phoneData.phone
        postData.phone_country_code = phoneData.countryCode

        const formData = new FormData();
        Object.keys(postData).forEach(key => {
            if (typeof postData[key] == 'boolean') {
                formData.append(`data[${key}]`, postData[key] ? 1 : 0)
            } else if (postData[key]) {
                formData.append(`data[${key}]`, postData[key])
            }
        })
        if (this.state.companyFile) {
            formData.append(`data[image]`, this.state.companyFile)
        }
        if (this.state.letter_head_image) {
            formData.append(`data[letter_head_image]`, this.state.letter_head_image)
        }
        if (BYPASS_TIN_CHECK || this.state.country_id != 1) {
            formData.append(`data[bypass_tin_check]`, true)
        }
        const postType = this.props.type;
        if (postType == 'create') {
            console.log("submitting company data", this.state)
            createCompany(this.props.token, formData).then(res => {
                if (res.success) {
                    if (window.branch) {

                        console.log("logging a company create to branch")
                        window.branch.logEvent(
                            "WEB_CREATE_COMPANY",
                            postData,
                            function (err) {
                                console.log("logging company create log err", err)
                            }
                        )

                    }
                    this.props.popAlert('success', this.props.t('success'), this.props.t('companyTranslations.success.createCompany'))
                    if (this.state.file && this.state.generalLiabilityExpDate) {
                        this.addGeneralLiability(res.data.id)
                    } else {
                        this.props.setModalOpen(false)
                    }
                    this.props.getUsersCompanies(this.props.token)
                } else {
                    this.setStateIfMounted({
                        loading: false,
                    }, () => this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res.error)))
                }
            })
        } else if (postType == 'edit') {
            editCompany(this.props.token, this.props.company.id, formData).then(res => {
                if (res.success) {
                    // this.props.popAlert('success', this.props.t('success'), 'You have successfully edited the site!')
                    if (this.state.changedGenLiabilityInfo) {
                        this.editGeneralLiability();
                    } else {
                        this.props.setModalOpen(false)
                        this.props.refreshCompany()
                    }
                } else {
                    this.setStateIfMounted({
                        loading: false,
                    }, () => this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res.error)))
                }
            })
        }

    }
    addGeneralLiability = (companyID) => {
        const formData = new FormData()
        formData.append(`data[image]`, this.state.file)
        formData.append(`data[expiration_date]`, this.props.helpers.makeUnixTime(this.state.generalLiabilityExpDate))
        formData.append(`data[legal_document_type_id]`, 2)
        postLegalDoc(this.props.token, companyID, formData).then(res => {
            if (res.success) {
                this.props.popAlert('success', this.props.t('success'), this.props.t('companyTranslations.success.processGeneralLiability'))
            } else {
                this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res.error))
            }
            this.props.setModalOpen(false)
        })
    }
    editGeneralLiability = () => {
        const formData = new FormData()
        if (this.state.file && this.state.generalLiabilityExpDate) {
            // new gen liability
            formData.append(`data[image]`, this.state.file)
            formData.append(`data[expiration_date]`, this.props.helpers.makeUnixTime(this.state.generalLiabilityExpDate))
            formData.append(`data[legal_document_type_id]`, 2)
            postLegalDoc(this.props.token, this.props.company.id, formData).then(res => {
                if (res.success) {
                    this.props.popAlert('success', this.props.t('success'), this.props.t('companyTranslations.success.processGeneralLiability'))
                } else {
                    this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res.error))
                }
                this.props.setModalOpen(false)
                this.props.refreshCompany()
            })
        } else if (this.state.generalLiabilityExpDate) {
            // edit 
            if (this.state.currentLegalDoc && this.state.currentLegalDoc.id) {
                formData.append(`data[expiration_date]`, this.props.helpers.makeUnixTime(this.state.generalLiabilityExpDate))
                modifyLegalDoc(this.props.token, this.props.company.id, this.state.currentLegalDoc.id, formData).then(res => {
                    if (res.success) {
                        this.props.popAlert('success', this.props.t('success'), this.props.t('companyTranslations.success.processGeneralLiability'))
                    } else {
                        this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res.error))
                    }
                    this.props.setModalOpen(false)
                    this.props.refreshCompany()
                })
            }
        }
    }
    verifyAddress = async () => {
        if (this.state.warnedBadAddressInput) {
            this.organizePostData();
        }
        if (!this.state.displayCoordinates && this.maps && (this.state.address_line_1 && this.state.city && this.state.province)) {
            const geocoder = new this.maps.Geocoder();
            await geocoder.geocode({ address: `${this.state.address_line_1} ${this.state.city} ${this.state.province} ${this.state.postal_code}` }, (results, status) => {
                if (status == this.maps.GeocoderStatus.OK && results.length) {
                    this.organizePostData();
                } else {
                    this.setStateIfMounted({
                        warnedBadAddressInput: true,
                        loading: false,
                        bypass_address_verification: true,
                    }, () => {
                        this.props.popAlert('warning', this.props.t('badAddress'), this.props.t('companyTranslations.errors.badAddress'))
                    })
                    return false;
                }
            })
        } else {
            this.organizePostData();
        }
    }
    getDisplayType = (isLoader) => {
        if (isLoader) {
            return this.state.loading ? "" : "none";
        } else {
            return this.state.loading ? "none" : "";
        }
    }
    getIdentificationLabel = () => {
        if (this.state.federal_tax_classification == 'individual-sole-proprietor') {
            return this.props.t('companyTranslations.ssn')
        } else if (this.state.entity_type_id == 4) {
            return this.props.t('companyTranslations.taxIDOrSSNOptional')
        } else {
            return this.props.t('companyTranslations.einOrTaxIDOptional')
        }
    }
    submitCompany = event => {
        event.preventDefault();
        console.log("submitting!")
        if (this.props.type == 'create' && this.state.country_id == 1 && this.state.entity_type_id && this.state.entity_type_id == 4) {
            if (!this.state.file) {
                this.props.popAlert('error', this.props.t('error'), this.props.t('companyTranslations.errors.missingGenLiability'))
                return;
            }
        }
        this.setStateIfMounted({
            loading: true,
        }, () => this.verifyAddress())
    }
    render = () => {
        return (
            <Modal open={true} style={{ overflowY: 'scroll' }}>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} sm={8} md={7} lg={5}>
                        <Paper m={6}>
                            <Box borderBottom={1} borderColor="grey.500">
                                <Grid container spacing={10} style={{ width: '100%', margin: 0, backgroundColor: "rgba(0,0,0,0.05)" }} direction="row" justify="space-between" alignItems="center">
                                    <Grid item>
                                        {this.props.type == 'create' && <Typography variant="h3">{this.props.t('companyTranslations.actions.createCompany')}</Typography>}
                                        {this.props.type == 'edit' && <Typography variant="h3">{this.props.t('companyTranslations.actions.editCompany')}</Typography>}
                                    </Grid>
                                    <Grid item>
                                        <CloseIcon onClick={() => this.props.setModalOpen(false)} />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box style={{ display: this.getDisplayType(true) }}>
                                <TPLoader />
                            </Box>

                            <Box p={5} style={{ display: this.getDisplayType(false) }}>
                                <form autoComplete="off" onSubmit={(event) => this.submitCompany(event)}>
                                    <Grid container spacing={10}>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="primary" fullWidth type="button" onClick={() => this.setStateIfMounted({ openCompanyPhotoUpload: !this.state.openCompanyPhotoUpload })}>{this.props.t('companyTranslations.actions.addCompanyPhotoOptional')}</Button>
                                            <DropzoneDialog
                                                open={this.state.openCompanyPhotoUpload}
                                                onSave={this.handleFileChange('companyFile')}
                                                showPreviews={true}
                                                maxFileSize={2000000}
                                                onClose={() => this.setStateIfMounted({ openCompanyPhotoUpload: false })}
                                                showFileNamesInPreview={true}
                                                showFileNames={true}
                                                filesLimit={1}
                                                acceptedFiles={['image/*']}
                                                dropzoneText={this.props.t('companyTranslations.actions.addCompanyPhotoOptional')}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={10}>
                                        <Grid item xs={12} md={this.props.type == 'create' ? 6 : 12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="name"
                                                    label={this.props.t('companyTranslations.companyName')}
                                                    value={this.state.name}
                                                    onChange={this.handleChange("name")}
                                                    type="text"
                                                    required
                                                    error={!this.state.name}
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                        {this.props.type == 'create' && <Grid item xs={12} md={6}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="position_id">{this.props.t('companyTranslations.positionInCompany')}</InputLabel>
                                                <Select
                                                    labelId="position_id"
                                                    label={this.props.t('companyTranslations.positionInCompany')}
                                                    value={this.state.position_id}
                                                    onChange={this.handleChange('position_id')}
                                                    fullWidth
                                                    required
                                                    inputProps={{
                                                        name: "position_id",
                                                        id: "position_id"
                                                    }}
                                                    error={!this.state.position_id}
                                                >
                                                    {this.state.companyPositions.map(position => (
                                                        <MenuItem key={position.id} value={position.id}>{this.props.t(`companyTranslations.positions.${position.name}`)}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>}
                                    </Grid>
                                    <Grid container spacing={10}>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="email_address"
                                                    label={this.props.t('companyTranslations.companyEmail')}
                                                    value={this.state.email_address}
                                                    onChange={event => this.setStateIfMounted({ email_address: event.target.value.toLowerCase() })}
                                                    type="email"
                                                    required
                                                    error={!this.state.email_address}
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="phone_number"
                                                    label={this.props.t('companyTranslations.companyPhone')}
                                                    value={this.state.phone_number}
                                                    onChange={text => this.setStateIfMounted({ phone_number: text })}
                                                    placeholder="(123) 456-7890"
                                                    type="tel"
                                                    required
                                                    error={!this.state.phone_number}
                                                    variant="outlined"
                                                    InputProps={{
                                                        inputComponent: CustomPhoneNumberInput
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={10}>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="entity_type_id">{this.props.t('companyTranslations.type')}</InputLabel>
                                                <Select
                                                    labelId="entity_type_id"
                                                    label={this.props.t('companyTranslations.type')}
                                                    value={this.state.entity_type_id}
                                                    onChange={this.handleChange('entity_type_id')}
                                                    fullWidth
                                                    required
                                                    inputProps={{
                                                        name: "entity_type_id",
                                                        id: "entity_type_id"
                                                    }}
                                                    error={!this.state.entity_type_id}
                                                >
                                                    {this.state.entityTypes.map(type => (
                                                        <MenuItem key={type.id} value={type.id}>{this.props.t(`companyTranslations.types.${type.name}`)}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="federal_tax_classification">{this.props.t('companyTranslations.taxClass')}</InputLabel>
                                                <Select
                                                    labelId="federal_tax_classification"
                                                    label={this.props.t('companyTranslations.taxClass')}
                                                    value={this.state.federal_tax_classification}
                                                    onChange={this.handleChange('federal_tax_classification')}
                                                    fullWidth
                                                    required
                                                    inputProps={{
                                                        name: "federal_tax_classification",
                                                        id: "federal_tax_classification"
                                                    }}
                                                    error={!this.state.federal_tax_classification}
                                                >
                                                    {this.state.legalEntityTypes.map(type => (
                                                        <MenuItem key={type.name} value={type.name}>{this.props.t(`companyTranslations.taxClasses.${type.name}`)}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={10}>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="letter_head_text"
                                                    label={this.props.t('companyTranslations.letterHeadTextOptional')}
                                                    value={this.state.letter_head_text}
                                                    onChange={this.handleChange("letter_head_text")}
                                                    type="text"
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Button variant="contained" color="primary" fullWidth type="button" onClick={() => this.setStateIfMounted({ openCompanyLetterHeadPhotoUpload: !this.state.openCompanyLetterHeadPhotoUpload })}>{this.props.t('companyTranslations.actions.uploadLetterHeadImageOptional')}</Button>
                                            <DropzoneDialog
                                                open={this.state.openCompanyLetterHeadPhotoUpload}
                                                onSave={this.handleFileChange('letter_head_image')}
                                                showPreviews={true}
                                                maxFileSize={2000000}
                                                onClose={() => this.setStateIfMounted({ openCompanyLetterHeadPhotoUpload: false })}
                                                showFileNamesInPreview={true}
                                                showFileNames={true}
                                                filesLimit={1}
                                                acceptedFiles={['image/*']}
                                                dropzoneText={this.props.t('companyTranslations.actions.uploadLetterHeadImageOptional')}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={10}>
                                        <Grid item xs={12} >
                                            <TextField
                                                id="description"
                                                label={this.props.t('companyTranslations.description')}
                                                multiline
                                                value={this.state.description}
                                                onChange={this.handleChange('description')}
                                                rows="4"
                                                variant="outlined"
                                                fullWidth
                                                error={!this.state.description}
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={10}>
                                        <Grid item xs={12} >
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">{this.props.t('companyTranslations.isMinorityOwned')}</FormLabel>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.is_minority_company}
                                                                onChange={this.handleChange("is_minority_company", 'boolean')}
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {this.state.is_minority_company && <Grid container spacing={10}>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="minority_classification_id">{this.props.t('companyTranslations.minorityClassification')}</InputLabel>
                                                <Select
                                                    labelId="minority_classification_id"
                                                    label={this.props.t('companyTranslations.minorityClassification')}
                                                    value={this.state.minority_classification_id}
                                                    onChange={this.handleChange('minority_classification_id')}
                                                    fullWidth
                                                    required
                                                    inputProps={{
                                                        name: "minority_classification_id",
                                                        id: "minority_classification_id"
                                                    }}
                                                    error={!this.state.minority_classification_id}
                                                >
                                                    {this.state.minorityClassifications.map(classification => (
                                                        <MenuItem key={classification.id} value={classification.id}>{classification.description}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="minority_certification_id"
                                                    label={this.props.t('companyTranslations.minorityCertificationID')}
                                                    value={this.state.minority_certification_id}
                                                    onChange={this.handleChange("minority_certification_id")}
                                                    type="text"
                                                    variant="outlined"
                                                    required
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>}
                                    <Grid container spacing={10}>
                                        <Grid item xs={12}>
                                            <TpTextfield
                                                label={"Default Job Terms And Conditions"}
                                                value={this.state.default_job_terms_and_conditions || ""}
                                                onChange={this.handleChange('default_job_terms_and_conditions')}
                                                textfieldProps={{
                                                    multiline: true,
                                                    rows: 4,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={10}>
                                        <Grid item xs={12}>
                                            <GoogleMapReactWrapper>
                                                <GoogleMapReact
                                                    options={this.getMapOptions}
                                                    bootstrapURLKeys={{
                                                        key: GMapAPIKey
                                                    }}
                                                    defaultCenter={{ lat: 40.75027, lng: -73.8542755 }}
                                                    defaultZoom={16}
                                                    yesIWantToUseGoogleMapApiInternals
                                                    onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                                                    onClick={this.handleMapClick}
                                                />
                                            </GoogleMapReactWrapper>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={10}>
                                        <Grid item xs={12} md={6}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                startIcon={<WorldIcon />}
                                                onClick={() => this.setStateIfMounted({ displayCoordinates: !this.state.displayCoordinates })}
                                            >
                                                {this.state.displayCoordinates ? this.props.t('addressTranslations.useAddress') : this.props.t('addressTranslations.useCoordinates')}
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    {!this.state.displayCoordinates ? <>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="job_country">{this.props.t('addressTranslations.country')}</InputLabel>
                                                    <Select
                                                        labelId="job_country"
                                                        label={this.props.t('addressTranslations.country')}
                                                        value={this.state.country_id}
                                                        onChange={this.handleChange('country_id')}
                                                        fullWidth
                                                        inputProps={{
                                                            name: "job_country",
                                                            id: "job_country"
                                                        }}
                                                        required
                                                        error={!this.state.country_id}
                                                    >
                                                        {this.props.supportedCountries.map(country => (
                                                            <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_address_line_1"
                                                        label={this.props.t('addressTranslations.addressLine1')}
                                                        value={this.state.address_line_1}
                                                        onChange={this.handleChange('address_line_1')}
                                                        type="text"
                                                        inputRef={this.addressInput}
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.address_line_1}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_city"
                                                        label={this.props.t('addressTranslations.city')}
                                                        value={this.state.city}
                                                        onChange={this.handleChange('city')}
                                                        type="text"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.city}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_state"
                                                        label={this.props.t('addressTranslations.province')}
                                                        value={this.state.province}
                                                        onChange={this.handleChange('province')}
                                                        type="text"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.province}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_postal_code"
                                                        label={this.props.t('addressTranslations.postalCode')}
                                                        value={this.state.postal_code}
                                                        onChange={this.handleChange('postal_code')}
                                                        type="text"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.postal_code}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </> : <>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_latitude"
                                                        label={this.props.t('addressTranslations.latitude')}
                                                        value={this.state.latitude}
                                                        onChange={this.handleChange('latitude')}
                                                        type="number"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.latitude}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_longitude"
                                                        label={this.props.t('addressTranslations.longitude')}
                                                        value={this.state.longitude}
                                                        onChange={this.handleChange('longitude')}
                                                        type="number"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.longitude}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </>}
                                    {this.state.country_id == 1 && <>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="employer_identification"
                                                        label={this.getIdentificationLabel()}
                                                        value={this.state.employer_identification}
                                                        onChange={this.handleChange('employer_identification')}
                                                        type="text"
                                                        variant="outlined"
                                                        disabled={this.props.type === "edit" && this.props.company.has_tin}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="usdot"
                                                        label={this.props.t('companyTranslations.usdot')}
                                                        value={this.state.usdot}
                                                        onChange={this.handleChange('usdot')}
                                                        type="text"
                                                        required={Boolean(this.state.entity_type_id && this.state.entity_type_id == 4)}
                                                        variant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        

                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <KeyboardDatePicker
                                                        label={(this.state.entity_type_id && this.state.entity_type_id == 4) ? this.props.t('companyTranslations.genLiabilityExpDate') : this.props.t('companyTranslations.genLiabilityExpDateOptional')}
                                                        value={this.state.generalLiabilityExpDate}
                                                        onChange={this.handleChange('generalLiabilityExpDate', 'date')}
                                                        format="MM/dd/yyyy"
                                                        min={this.today}
                                                        inputVariant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Button variant="contained" color="primary" fullWidth type="button" onClick={() => this.setStateIfMounted({ openGLPhotoUpload: !this.state.openGLPhotoUpload })}>{(this.state.entity_type_id && this.state.entity_type_id == 4) ? this.props.t('companyTranslations.actions.uploadGenLiability') : this.props.t('companyTranslations.actions.uploadGenLiabilityOptional')}</Button>
                                                <DropzoneDialog
                                                    open={this.state.openGLPhotoUpload}
                                                    onSave={this.handleFileChange('file')}
                                                    showPreviews={true}
                                                    maxFileSize={2000000}
                                                    onClose={() => this.setStateIfMounted({ openGLPhotoUpload: false })}
                                                    showFileNamesInPreview={true}
                                                    showFileNames={true}
                                                    filesLimit={1}
                                                    acceptedFiles={['image/*']}
                                                    dropzoneText={this.props.t('companyTranslations.actions.uploadGenLiabilityMessage')}
                                                />
                                            </Grid>
                                        </Grid>
                                    </> }
                                    <Grid container spacing={10}>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="primary" fullWidth type="submit">
                                                {this.props.t('actions.submit')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Paper>
                    </Grid>
                    <NotificationSnackbar />
                </Grid>
            </Modal>
        )
    }
}
const msp = state => ({
    helpers: state.helpers,
    token: state.session.user.token,
    userID: state.session.user.id,
    company: state.session.company,
    supportedCountries: state.entities.supportedCountries,
})
const mdp = dispatch => ({
    getSupportedCountries: () => dispatch(getSupportedCountries()),
    getUsersCompanies: (token) => dispatch(getUsersCompanies(token)),
    popAlert: (success, title, message) => dispatch(popAlert(success, title, message)),
})
export default connect(msp, mdp)(withTranslation()(CreateEditCompanyModal))