import { Button, FormControl, Grid, IconButton, InputLabel, List as MuiList, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { DeleteForever, Edit } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useRootSelector } from "../../../..";
import { BaseJobQuery, CustomMaterial, CustomMaterialMap, JobQuery, JobQueryTypes, Material, MaterialCategory, MixDesign } from "../../../../redux/reducers/entities";
import { RenderMaterialCategorizationInput } from "../../Materials/modals/ModalEditMaterialCategorizations";
import TPModal from "../../Util/TPModal";

const List = styled(MuiList)(spacing)

interface Props {
    query: JobQuery;
    setQuery: (newQuery: JobQuery) => void;
    setModalOpen: (bool: boolean) => void;
    isRecursed?: boolean;
    entities: any;
    disallowedTypes?: JobQueryTypes[];
}

const EditJobQueryModal = ({
    query,
    setQuery,
    setModalOpen,
    isRecursed = false,
    entities,
    disallowedTypes,
}: Props) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const [currentQuery, setCurrentQuery] = useState<JobQuery>(query || { type: "unknown" })
    const [autocompleteSearch, setAutocompleteSearch] = useState("")
    const [customMaterialSearch, setCustomMaterialSearch] = useState("")
    
    
    const createQuery = (type: string): JobQuery => {
        switch (type) {
            case JobQueryTypes.MATERIAL: 
                return { type: JobQueryTypes.MATERIAL, material_ids: [] }
            case JobQueryTypes.CUSTOM_MATERIAL: 
                return { type: JobQueryTypes.CUSTOM_MATERIAL, custom_material_ids: [] }
            case JobQueryTypes.MIX_DESIGN: 
                return { type: JobQueryTypes.MIX_DESIGN, mix_design_ids: [] }
            case JobQueryTypes.MATERIAL_CATEGORY: 
                return { type: JobQueryTypes.MATERIAL_CATEGORY, material_category_key: "" }
            case JobQueryTypes.MATERIAL_CATEGORIZATION: 
                return { type: JobQueryTypes.MATERIAL_CATEGORIZATION, material_category_key: "", value: "", value_operator: "=", units: null, canonical_units: null, units_type: null }
            case JobQueryTypes.DUAL:
                return { type: JobQueryTypes.DUAL, query_a: { type: JobQueryTypes.UNKNOWN }, query_b: { type: JobQueryTypes.UNKNOWN }, query_operator: "AND" }
            default:
                return { type: JobQueryTypes.UNKNOWN }
        }
    }
            
    // const [selectedUnit, setSelectedUnit] = useState<Material | string | CustomMaterial | MixDesign | MaterialCategory | null>()

    const materials: Material[] = useRootSelector(state => state.entities.materials)
    const addMaterial = (material: Material) => {
        if (material && currentQuery.type === JobQueryTypes.MATERIAL && !currentQuery.material_ids.includes(material.id)) {
            setCurrentQuery({ ...currentQuery, material_ids: [ ...currentQuery.material_ids, material.id ]})
            setAutocompleteSearch("")
        }
    }
    const removeMaterial = (materialID: number) => {
        if (currentQuery.type === JobQueryTypes.MATERIAL) {
            setCurrentQuery({ ...currentQuery, material_ids: currentQuery.material_ids.filter(id => id !== materialID) })
        }
    }

    const customMaterialListNames: string[] = useRootSelector(state => state.entities.customMaterialListNames)
    const [customMaterialListName, setCustomMaterialListName] = useState("")
    const customMaterials: CustomMaterialMap = useRootSelector(state => state.entities.customMaterials)
    const addCustomMaterial = (customMaterial: CustomMaterial) => {
        if (customMaterial && currentQuery.type === JobQueryTypes.CUSTOM_MATERIAL && !currentQuery.custom_material_ids.includes(customMaterial.id)) {
            setCurrentQuery({ ...currentQuery, custom_material_ids: [ ...currentQuery.custom_material_ids, customMaterial.id ]})
            setCustomMaterialSearch("")
        }
    }
    const removeCustomMaterial = (customMaterialID: number) => {
        if (currentQuery.type === JobQueryTypes.CUSTOM_MATERIAL) {
            setCurrentQuery({ ...currentQuery, custom_material_ids: currentQuery.custom_material_ids.filter(id => id !== customMaterialID) })
        }
    }

    const mixDesigns: MixDesign[] = useMemo(() => Object.values(entities.mixDesigns), [])
    const addMixDesign = (design: MixDesign) => {
        if (design && currentQuery.type === JobQueryTypes.MIX_DESIGN && !currentQuery.mix_design_ids.includes(design.id)) {
            setCurrentQuery({ ...currentQuery, mix_design_ids: [ ...currentQuery.mix_design_ids, design.id] })
        }
    }
    const removeMixDesign = (mixDesignID: number) => {
        if (currentQuery.type === JobQueryTypes.MIX_DESIGN) {
            setCurrentQuery({ ...currentQuery, mix_design_ids: currentQuery.mix_design_ids.filter(id => id !== mixDesignID) })
        }
    }

    const materialCategories: MaterialCategory[] = useMemo(() => Object.values(entities.materialCategories), [])
    const selectMaterialCategory = (category: MaterialCategory) => {
        if (category && currentQuery.type === JobQueryTypes.MATERIAL_CATEGORY) {
            setCurrentQuery({ ...currentQuery, material_category_key: category.key })
        } else if (category && currentQuery.type === JobQueryTypes.MATERIAL_CATEGORIZATION) {
            setCurrentQuery({ ...currentQuery, 
                material_category_key: category.key, 
                units: category.canonical_units, 
                canonical_units: category.canonical_units,
                units_type: category.units_type
            })
        }
    }


    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        
        setQuery(currentQuery)
        setModalOpen(false)
    }

    return <TPModal 
        setModalOpen={setModalOpen}
        headerOptions={{
            headerText: "Job Query"
        }}
    >
        <form onSubmit={handleSubmit}>
            <Grid container spacing={10}>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="query_type">Query Type</InputLabel>
                        <Select
                            label="Query Type"
                            labelId="query_type"
                            value={currentQuery.type}
                            onChange={(e) => {
                                setCurrentQuery(createQuery(e.target.value as string))
                                setAutocompleteSearch("")
                            }}
                            fullWidth
                            required
                            inputProps={{
                                name: "query_type",
                                id: "query_type"
                            }}
                            error={currentQuery.type === 'unknown'}
                        >
                            {Boolean(!disallowedTypes || !disallowedTypes.includes(JobQueryTypes.MATERIAL)) && <MenuItem value={JobQueryTypes.MATERIAL}>Materials</MenuItem>}
                            {Boolean(!disallowedTypes || !disallowedTypes.includes(JobQueryTypes.CUSTOM_MATERIAL)) && <MenuItem value={JobQueryTypes.CUSTOM_MATERIAL}>Custom Materials</MenuItem>}
                            {Boolean(!disallowedTypes || !disallowedTypes.includes(JobQueryTypes.MIX_DESIGN)) && <MenuItem value={JobQueryTypes.MIX_DESIGN}>Mix Design</MenuItem>}
                            {Boolean(!disallowedTypes || !disallowedTypes.includes(JobQueryTypes.MATERIAL_CATEGORY)) && <MenuItem value={JobQueryTypes.MATERIAL_CATEGORY}>Material Category</MenuItem>}
                            {Boolean(!disallowedTypes || !disallowedTypes.includes(JobQueryTypes.MATERIAL_CATEGORIZATION)) && <MenuItem value={JobQueryTypes.MATERIAL_CATEGORIZATION}>Material Categorization</MenuItem>}
                            {Boolean(!disallowedTypes || !disallowedTypes.includes(JobQueryTypes.DUAL)) && <MenuItem value={JobQueryTypes.DUAL}>Dual</MenuItem>}
                        </Select>
                        {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                    </FormControl>
                </Grid>
            </Grid>
            {currentQuery.type === JobQueryTypes.UNKNOWN && <></>}
            {currentQuery.type === JobQueryTypes.MATERIAL && <>
                <Grid container spacing={10}>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="material_autocomplete"
                                disableClearable
                                clearOnBlur={false}
                                options={materials}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, val) => {
                                    addMaterial(val)
                                }}
                                onInputChange={(event, val, reason) => {
                                    if (reason != 'reset' || val) {
                                        setAutocompleteSearch(val)
                                    }
                                }}
                                inputValue={autocompleteSearch}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="searchMaterials"
                                        label={`Search Materials`}
                                        type="text"
                                        error={!autocompleteSearch}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    {currentQuery.material_ids.map((id, index) => {
                        const material: Material = entities?.materials?.[id]
                        if (!material) return <React.Fragment key={index}></React.Fragment>
                        return <Grid item xs={12} key={material.id}>
                            <Grid container spacing={5}>
                                <Grid item xs>
                                    <Typography>{material.name}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => removeMaterial(material.id)}>
                                        <DeleteForever />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    })}
                    <Grid item />
                </Grid>
            </>}
            {currentQuery.type === JobQueryTypes.CUSTOM_MATERIAL && <>
                <Grid container spacing={10}>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="custom_material_list_autocomplete"
                                disableClearable
                                clearOnBlur={false}
                                options={customMaterialListNames}
                                // getOptionLabel={(option) => option.}
                                onChange={(event, val) => {
                                    setCustomMaterialListName(val)
                                }}
                                onInputChange={(event, val, reason) => {
                                    if (reason != 'reset' || val) {
                                        setAutocompleteSearch(val)
                                    }
                                }}
                                inputValue={autocompleteSearch}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="searchCustomMaterialLists"
                                        label={`Custom Material List Name`}
                                        type="text"
                                        required
                                        error={!autocompleteSearch}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    {Boolean(customMaterialListName) && <Grid item xs>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="custom_material_autocomplete"
                                disableClearable
                                clearOnBlur={false}
                                options={customMaterials[customMaterialListName]}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, val) => {
                                    addCustomMaterial(val)
                                }}
                                onInputChange={(event, val, reason) => {
                                    if (reason != 'reset' || val) {
                                        setCustomMaterialSearch(val)
                                    }
                                }}
                                inputValue={customMaterialSearch}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="searchCustomMaterials"
                                        label={`Custom Material`}
                                        type="text"
                                        required
                                        error={!customMaterialSearch}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>}
                </Grid>
                <Grid container spacing={10}>
                    {currentQuery.custom_material_ids.map((id, index) => {
                        const customMaterial: CustomMaterial = entities?.customMaterials?.[id]
                        if (!customMaterial) return <React.Fragment key={index}></React.Fragment>
                        return <Grid item xs={12} key={customMaterial.id}>
                            <Grid container spacing={5}>
                                <Grid item xs>
                                    <Typography>{customMaterial.name}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => removeCustomMaterial(customMaterial.id)}>
                                        <DeleteForever />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    })}
                    <Grid item />
                </Grid>
            </>}
            {currentQuery.type === JobQueryTypes.MIX_DESIGN && <>
                <Grid container spacing={10}>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="mix_design_autocomplete"
                                disableClearable
                                clearOnBlur={false}
                                options={mixDesigns}
                                getOptionLabel={(option) => `${option.mix_id} - ${option.name}`}
                                onChange={(event, val) => {
                                    addMixDesign(val)
                                }}
                                onInputChange={(event, val, reason) => {
                                    if (reason != 'reset' || val) {
                                        setAutocompleteSearch(val)
                                    }
                                }}
                                inputValue={autocompleteSearch}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="searchMixDesigns"
                                        label={`Search Designs`}
                                        type="text"
                                        error={!autocompleteSearch}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    {currentQuery.mix_design_ids.map((id, index) => {
                        const mixDesign: MixDesign = entities?.mixDesigns?.[id]
                        if (!mixDesign) return <React.Fragment key={index}></React.Fragment>
                        return <Grid item xs={12} key={mixDesign.id}>
                            <Grid container spacing={5}>
                                <Grid item xs>
                                    <Typography>{mixDesign.mix_id} - {mixDesign.name}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => removeMixDesign(mixDesign.id)}>
                                        <DeleteForever />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    })}
                    <Grid item />
                </Grid>
            </>}
            {currentQuery.type === JobQueryTypes.MATERIAL_CATEGORY && <>
                <Grid container spacing={10}>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="material_category_autocomplete"
                                disableClearable
                                clearOnBlur={false}
                                options={materialCategories}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, val) => {
                                    selectMaterialCategory(val)
                                }}
                                onInputChange={(event, val, reason) => {
                                    if (reason != 'reset' || val) {
                                        setAutocompleteSearch(val)
                                    }
                                }}
                                inputValue={autocompleteSearch}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="searchMaterialCategories"
                                        label={`Search Categories`}
                                        type="text"
                                        error={!autocompleteSearch}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </>}
            {currentQuery.type === JobQueryTypes.MATERIAL_CATEGORIZATION && <>
                <Grid container spacing={10}>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="material_category_autocomplete"
                                disableClearable
                                clearOnBlur={false}
                                options={materialCategories}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, val) => {
                                    selectMaterialCategory(val)
                                }}
                                onInputChange={(event, val, reason) => {
                                    if (reason != 'reset' || val) {
                                        setAutocompleteSearch(val)
                                    }
                                }}
                                inputValue={autocompleteSearch}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id="searchMaterialCategories"
                                        label={`Search Categories`}
                                        type="text"
                                        error={!autocompleteSearch}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    {Boolean(currentQuery.material_category_key) && <>
                        <Grid item xs>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="value_operator">Operator</InputLabel>
                                <Select
                                    labelId="value_operator"
                                    label="Operator"
                                    value={currentQuery.value_operator}
                                    onChange={e => setCurrentQuery({ ...currentQuery, value_operator: e.target.value as any })}
                                    fullWidth
                                    required
                                    inputProps={{
                                        name: "value_operator",
                                        id: "value_operator"
                                    }}
                                >
                                    <MenuItem value={">"}>{'>'} (Greater Than)</MenuItem>
                                    <MenuItem value={"<"}>{'<'} (Less Than)</MenuItem>
                                    <MenuItem value={"="}>{'='} (Equal To)</MenuItem>
                                    <MenuItem value={">="}>{'>='} (Greater Than or Equal To)</MenuItem>
                                    <MenuItem value={"<="}>{'<='} (Less Than or Equal To)</MenuItem>
                                    <MenuItem value={"!="}>{'!='} (Not Equal To)</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </>}
                </Grid>
                {Boolean(currentQuery.material_category_key) && <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <RenderMaterialCategorizationInput 
                            category={entities.materialCategories[currentQuery.material_category_key]}
                            categorizationValue={{
                                category_key: currentQuery.material_category_key,
                                value: currentQuery.value,
                                units: currentQuery.units || ""
                            }}
                            setField={(field, value) => {
                                setCurrentQuery({
                                    ...currentQuery,
                                    [field]: value
                                })
                            }}
                        />
                    </Grid>
                </Grid>}
            </>}
            {currentQuery.type === JobQueryTypes.DUAL && <>
                <Grid container spacing={10}>
                    <Grid item xs>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="value_operator">Operator</InputLabel>
                            <Select
                                labelId="value_operator"
                                label="Operator"
                                value={currentQuery.query_operator}
                                onChange={e => setCurrentQuery({ ...currentQuery, query_operator: e.target.value as any })}
                                fullWidth
                                required
                                inputProps={{
                                    name: "value_operator",
                                    id: "value_operator"
                                }}
                            >
                                <MenuItem value={"AND"}>AND</MenuItem>
                                <MenuItem value={"OR"}>OR</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </>}
            <Grid container spacing={10}>
                <Grid item xs={12} md={6}>
                    <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        type="button"
                        onClick={() => setModalOpen(false)}
                    >
                        Cancel
                    </Button>
                    
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        Save
                    </Button>
                    
                </Grid>
            </Grid>
        </form>
    </TPModal>
}

export default EditJobQueryModal

interface RenderQueryProps {
    query: JobQuery;
    isRecursed?: boolean;
    setQuery: (newQuery: JobQuery) => void;
    entities: any;
    disallowedTypes?: JobQueryTypes[];
}
export const RenderQuery = ({
    query,
    isRecursed = false,
    setQuery,
    entities,
    disallowedTypes
}: RenderQueryProps) => {

    const [openEditQueryModal, setEditQueryModal] = useState(false)

    const renderListItem = () => {
        const defaultActionItems = <>
            <IconButton size="small" onClick={() => setEditQueryModal(true)}>
                <Edit />
            </IconButton>
            <IconButton size="small" onClick={() => setQuery({ type: JobQueryTypes.UNKNOWN })}>
                <DeleteForever />
            </IconButton>
        </>
        switch (query.type) {
            case "unknown": 
                return <List pl={3} style={{ borderLeft: "1px solid grey" }} subheader={<>
                    {/* <Typography>Material Query</Typography> */}
                    {/* <Button variant="outlined">Delete</Button> */}
                </>}>
                    <ListItem>
                        <Button variant="outlined" onClick={() => setEditQueryModal(true)} size="small">Add</Button>
                    </ListItem> 
                </List>
            case "material":
                return <List pl={3} style={{ borderLeft: "1px solid grey" }}>
                    <ListItem>
                        <Typography display="inline">Materials Used: {query.material_ids.map(id => entities.materials?.[id]?.name).join(", ")}</Typography>
                        {defaultActionItems}
                    </ListItem>
                </List>
            case "customMaterial":
                return <List pl={3} style={{ borderLeft: "1px solid grey" }}>
                    <ListItem>
                        <Typography display="inline">Custom Material: {query.custom_material_ids.map(id => entities.customMaterials?.[id]?.name).join(", ")}</Typography>
                        {defaultActionItems}
                    </ListItem>
                </List>
            case "mixDesign":
                return <List pl={3} style={{ borderLeft: "1px solid grey" }}>
                    <ListItem>
                        <Typography display="inline">
                            Mix Designs: {query.mix_design_ids.map(id => {
                                const design: MixDesign = entities.mixDesigns?.[id]
                                const parts = []
                                if (design?.mix_id) parts.push(design?.mix_id)
                                if (design?.name) parts.push(design?.name)
                                return parts.join(" - ")
                            }).join(", ")}
                        </Typography>
                        {defaultActionItems}
                    </ListItem>
                </List>
            case "materialCategory":
                return <List pl={3} style={{ borderLeft: "1px solid grey" }}>
                    <ListItem>
                        <Typography display="inline">Material Category: {entities.materialCategories?.[query.material_category_key]?.name}</Typography>
                        {defaultActionItems}
                    </ListItem>
                </List>
            case "materialCategorization":
                return <List pl={3} style={{ borderLeft: "1px solid grey" }}>
                    <ListItem>
                        <Typography display="inline">Material Category: {entities.materialCategories?.[query.material_category_key]?.name} {query.value_operator} {query.value.toString()} {query.units || ""}</Typography>
                        {defaultActionItems}
                    </ListItem>
                 </List>
            case "dual": 
                return <List 
                    style={{ borderLeft: "1px solid grey" }} 
                    subheader={<>
                        <Typography display="inline">Dual Query</Typography>
                        {defaultActionItems}
                    </>}
                    pl={3}
                >
                    <ListItem>
                        <RenderQuery
                            entities={entities}
                            query={query.query_a}
                            isRecursed
                            setQuery={(newQuery) => setQuery({ ...query, query_a: newQuery })}
                        />
                    </ListItem>
                    <ListItem>
                        <Typography>{query.query_operator}</Typography>
                    </ListItem>
                    <ListItem>
                        <RenderQuery
                            entities={entities}
                            query={query.query_b}
                            isRecursed
                            setQuery={(newQuery) => setQuery({ ...query, query_b: newQuery })}
                        />
                    </ListItem>
                </List>
        }
    }
    return <>
        {renderListItem()}
        {openEditQueryModal && <EditJobQueryModal 
            setModalOpen={setEditQueryModal}
            query={query}
            setQuery={setQuery}
            isRecursed={isRecursed}
            entities={entities}
            disallowedTypes={disallowedTypes}
        />}
    </>
}