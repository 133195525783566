import { createTheme } from "@material-ui/core/styles";

import variants from "./variants";
import typography from "./typography";
import overrides from "./overrides";
import breakpoints from "./breakpoints";
import props from "./props";
import shadows from "./shadows";

const theme = (variant, textValue) => {
  return createTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      overrides: overrides(variant.name),
      props: props,
      typography: typography(textValue),
      shadows: shadows,
      body: variant.body,
      header: variant.header,
      pseudo: variant.pseudo,
      card: variant.card,
      buttons: variant.buttons,
      palette: variant.palette,
      sidebar: variant.sidebar
    },
    variant.name
  );
};

// const themes = variants.map(variant => theme(variant));
const themes = {
  standard: {
    1: theme(variants.standardVariant, 1),
    2: theme(variants.standardVariant, 2),
    3: theme(variants.standardVariant, 3),
    4: theme(variants.standardVariant, 4),
    5: theme(variants.standardVariant, 5),
  },
  light: {
    1: theme(variants.lightVariant, 1),
    2: theme(variants.lightVariant, 2),
    3: theme(variants.lightVariant, 3),
    4: theme(variants.lightVariant, 4),
    5: theme(variants.lightVariant, 5),
  },
  dark: {
    1: theme(variants.highContrast, 1),
    2: theme(variants.highContrast, 2),
    3: theme(variants.highContrast, 3),
    4: theme(variants.highContrast, 4),
    5: theme(variants.highContrast, 5),
  },
  high_contrast: {
    1: theme(variants.highContrast, 1),
    2: theme(variants.highContrast, 2),
    3: theme(variants.highContrast, 3),
    4: theme(variants.highContrast, 4),
    5: theme(variants.highContrast, 5),
  },
}

export default themes;
