import { blue, green, grey, indigo, red, teal } from "@material-ui/core/colors";

/*

CardContent as MuiCardContent,
CardHeader as MuiCardHeader,

const Card = styled(MuiCard)`
    ${spacing}
    border-color: ${props => props.theme.card.borderColor}
`;
const CardHeader = styled(MuiCardHeader)`
    ${spacing}
    background-color: ${props => props.theme.card.header.background}
`;
const CardContent = styled(MuiCardContent)`
    ${spacing}
    background-color: ${props => props.theme.card.body.background}
`;
const CardActions = styled(MuiCardActions)`
    ${spacing}
    background-color: ${props => props.theme.card.actions.background}
`;
const Paper = styled(MuiPaper)`
    ${spacing}
    background-color: ${props => props.theme.card.body.background}
`;

const Menu = styled(MuiMenu)`
  div.MuiPaper-root {
    border: 1px solid ${props => props.theme.card.borderColor}
  }
  ul {
    background-color: ${props => props.theme.card.header.background}
  }
`;
const IconButton = styled(MuiIconButton)`
  color: ${props => props.theme.palette.text.secondary}
`;

const TableBody = styled(MuiTableBody)`
    ${spacing}
    background-color: ${props => props.theme.pseudo.hover.background}
`;
const TableRow = styled(MuiTableRow)`
    ${spacing}
    background-color: ${props => props.theme.card.body.background}
`;

const Divider = styled(MuiDivider)`
    ${spacing}
    background-color: ${props => props.theme.palette.text.secondary}
`;
*/


const lightVariant = {
  name: "light",
  palette: {
    primary: {
      main: "#FF6600",
      contrastText: "#FFF"
    },
    secondary: {
      main: grey[800],
      contrastText: "#FFF"
    },
    text: {
      primary: "#000",
      secondary: "rgba(0, 0, 0, 0.54)"
    },
    error: {
      main: "#f44336",
      secondary: "#f05050"
    },
    green: {
      main: "#27c24c",
      hover: "#27c24c"
    },
    appBar: {
      background: '#f5f5f5'
    },
    lightGray: {
      color: grey[300]
    },
    gray: {
      color: grey[700]
    },
    darkerGray: {
      color: grey[800]
    },
  },
  pseudo: {
    hover: {
      background: "rgba(0, 0, 0, 0.04)"
    },
    lightTPO: {
      background: 'rgba(255, 102, 0, 0.4)'
    }
  },
  header: {
    color: grey[200],
    background: "#FFF",
    search: {
      color: grey[100]
    },
    indicator: {
      background: red[700]
    }
  },
  card: {
    borderColor: "rgba(0, 0, 0, 0.12)",
    header: {
      background: "#F0F0F0",
    },
    body: {
      background: "#FFF",
    },
    actions: {
      background: "#FFF"
    },
  },
  buttons: {
    accept: {
      color: '#ffffff !important',
      backgroundColor: '#27c24c',
      borderColor: '#27c24c'
    },
    acceptStyle: "color: #ffffff !important; background-color: #27c24c; border-color: #27c24c",
    decline: {
      color: '#ffffff !important',
      backgroundColor: '#f05050',
      borderColor: '#f05050'
    },
    declineStyle: "color: #ffffff !important; background-color: #f05050; border-color: #f05050",
  },
  sidebar: {
    color: "#000",
    background: "#FFF",
    header: {
      color: "#FFF",
      background: "#FFF",
      brand: {
        color: "#FFFFFF"
      }
    },
    footer: {
      color: grey[900],
      background: grey[100],
      online: {
        background: green[500]
      }
    },
    category: {
      fontWeight: 600
    },
    badge: {
      color: "#FFF",
      background: "#FF6600"
    }
  },
  body: {
    background: "#F7F9FC"
  }
};

const standardVariant = {
  name: "standard",
  palette: {
    primary: {
      main: "#FF6600",
      contrastText: "#FFF"
    },
    secondary: {
      main: grey[800],
      contrastText: "#FFF"
    },
    error: {
      main: "#f44336",
      secondary: "#f05050"
    },
    text: {
      primary: "#000",
      secondary: "rgba(0, 0, 0, 0.54)"
    },
    green: {
      main: "#27c24c",
      hover: "#27c24c"
    },
    appBar: {
      background: '#f5f5f5'
    },
    lightGray: {
      color: grey[300]
    },
    gray: {
      color: grey[700]
    },
    darkerGray: {
      color: grey[800]
    },
  },
  pseudo: {
    hover: {
      background: "rgba(0, 0, 0, 0.04)"
    },
    lightTPO: {
      background: 'rgba(255, 102, 0, 0.4)'
    }
  },
  card: {
    borderColor: "rgba(0, 0, 0, 0.12)",
    header: {
      background: "#F0F0F0",
    },
    body: {
      background: "#FFF",
    },
    actions: {
      background: "#FFF"
    }
  },
  buttons: {
    accept: {
      color: '#ffffff !important',
      backgroundColor: '#27c24c',
      borderColor: '#27c24c'
    },
    acceptStyle: "color: #ffffff !important; background-color: #27c24c; border-color: #27c24c",
    decline: {
      color: '#ffffff !important',
      backgroundColor: '#f05050',
      borderColor: '#f05050'
    },
    declineStyle: "color: #ffffff !important; background-color: #f05050; border-color: #f05050",
  },
  header: {
    color: grey[500],
    background: "#FFFFFF",
    search: {
      color: grey[800]
    },
    indicator: {
      background: blue[600]
    }
  },
  sidebar: {
    color: "#FFF",
    background: "#000",
    header: {
      color: grey[200],
      background: "#FFF",
      brand: {
        color: "#FF6600"
      }
    },
    footer: {
      color: "#000",
      background: "#FFF",
      online: {
        background: green[500]
      }
    },
    category: {
      fontWeight: 400
    },
    badge: {
      color: "#FFF",
      background: "#FF6600"
    }
  },
  body: {
    background: "#F7F9FC"
  }
};

const highContrast = {
  name: "high_contrast",
  palette: {
    primary: {
      main: "#FFF",
      contrastText: "#000"
    },
    secondary: {
      main: "#FFF",
      contrastText: "#000"
    },
    text: {
      primary: "#FFF",
      secondary: "#FFF"
    },
    error: {
      main: "#FFF",
      secondary: "#FFF"
    },
    green: {
      main: "#FFF",
      hover: "#FFF"
    },
    appBar: {
      background: '#000'
    },
    lightGray: {
      color: grey[300]
    },
    gray: {
      color: grey[700]
    },
    darkerGray: {
      color: grey[800]
    },
  },
  pseudo: {
    hover: {
      background: "rgba(0, 0, 0, 0.8)"
    },
    lightTPO: {
      background: 'rgba(255, 255, 255, 0.2)'
    }
  },
  header: {
    color: grey[500],
    background: "#000",
    search: {
      color: grey[800]
    },
    indicator: {
      background: blue[600]
    }
  },
  card: {
    borderColor: "#FFF",
    header: {
      background: "#000",
    },
    body: {
      background: "#000",
    },
    actions: {
      background: "#000"
    }
  },
  buttons: {
    accept: {
      color: '#ffffff !important',
      backgroundColor: '#27c24c',
      borderColor: '#27c24c'
    },
    acceptStyle: "color: #ffffff !important; background-color: #000; border-color: #000",
    decline: {
      color: '#ffffff !important',
      backgroundColor: '#f05050',
      borderColor: '#f05050'
    },
    declineStyle: "color: #ffffff !important; background-color: #FFF; border-color: #FFF",
  },
  sidebar: {
    color: "#FFF",
    background: "#000",
    header: {
      color: "#FFF",
      background: "#000",
      brand: {
        color: "#FF6600"
      }
    },
    footer: {
      color: "#FFF",
      background: "#000",
      online: {
        background: green[500]
      }
    },
    category: {
      fontWeight: 400
    },
    badge: {
      color: "#000",
      background: "#FFF"
    }
  },
  body: {
    background: grey[800]
  }
};

const variants = {
  standardVariant,
  lightVariant,
  highContrast,
};

export default variants;
