import * as SiteAPI from '../util/scaleAPI';

import { functions } from '../reducers/helpersReducer';
import { ScaleApi } from '../reducers/entities/Scale';
export const RECEIVE_SCALES = "RECEIVE_SCALES";

export const receiveScales = (scales) => ({
    type: RECEIVE_SCALES,
    scales
})

export const getCompanyScales = (token, companyID) => dispatch => {
    return SiteAPI.getCompanyScales(token, companyID).then(
        (res) => {
            if (res.success) {
                dispatch(receiveScales(res.data.map(scaleApi => ScaleApi.toApi(scaleApi))))
            }
            return res
        }
    )
}

