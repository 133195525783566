import { List, ListItem } from "@material-ui/core";
import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Paragraph, SectionHeader } from ".";
import { AuthLayoutWrapper } from "../../layouts/Auth";
import { Grid, Typography } from "../dashboards/Util/SpacedComponents";


const MTSTermsOfService = () => {
    const { t } = useTranslation()

    return (
        <AuthLayoutWrapper>
            <Helmet title={"Terms Of Service - MyTruckScales"} />
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
                MYTRUCKSCALES TERMS OF SERVICE
            </Typography>
            <Typography mb={4}>
                Effective as of June 16, 2023
            </Typography>
            <Paragraph>
                These terms of service (these “Terms”) constitute a legally binding agreement between you and 
                TruckPay Inc. (“TruckPay,” “we,” “us” or “our”) governing your use of the MyTruckScales 
                application, website, point of sale (POS) system, and technology platform (collectively, the 
                “MyTruckScales Platform”). By using the MyTruckScales Platform, you expressly acknowledge that 
                you understand these Terms (including the dispute resolution and arbitration provisions set 
                forth in these Terms) and accept all of these Terms. If you do not agree to be bound by these 
                Terms, you may not use or access the MyTruckScales Platform.
            </Paragraph>

            <SectionHeader>
                The MyTruckScales Platform
            </SectionHeader>
            <Paragraph>
                The MyTruckScales Platform enables truck drivers and trucking companies seeking weighment 
                services (collectively, “Trucker Users”) to more easily and seamless find and access weighment 
                services from providers of those services (“Scale Owners”) and contractors and others seeking 
                services from Trucker Users (“Contractors”) to hire and pay a Trucker User to pick up or drop 
                off loads at scale locations of a Scale Owner, which services can be accessed, controlled and 
                paid for using the MyTruckScales Platform. Through the MyTruckScales website, Scale Owners 
                create their accounts, set up and manage their scales, virtual kiosks, and weighment workflows, 
                view weigh tickets and reports, set up and manage payment methods and otherwise configure and 
                manage the details of their provision of weighment services to Trucker Users through the MyTruckScales 
                Platform. Trucker Users, Scale Owners and Contractors are collectively referred to herein as “Users,” 
                and each User shall create a User account that enables access to the MyTruckScales Platform. For 
                purposes of these Terms, the services provided by Scale Owners to Trucker Users in connection with 
                the use of the MyTruckScales Platform shall be referred to collectively as the “Services.” Any 
                decision by a User to offer or accept Services is a decision made in such User’s sole discretion. 
                Each Service provided by a User (either directly or through subcontractors) to another User shall 
                constitute a separate agreement between such persons.
            </Paragraph>

            <SectionHeader>
                Modification of these Terms
            </SectionHeader>
            <Paragraph>
                In the event TruckPay modifies these Terms, such modifications shall be binding on you only upon
                your acceptance of the modified Terms by your subsequent use of the MyTruckScales Platform.
                TruckPay reserves the right to modify any information referenced in the hyperlinks from these
                Terms from time to time, and such modifications shall become effective upon posting. Continued
                use of the MyTruckScales Platform or Services after any such changes shall constitute your
                consent to such changes. Unless material changes are made to the arbitration provisions herein,
                you agree that modifications of these Terms do not create a renewed opportunity to opt out of
                arbitration (if applicable).
            </Paragraph>

            <SectionHeader>
                Eligibility
            </SectionHeader>
            <Paragraph>
                The MyTruckScales Platform may only be used by entities and by individuals who can form
                legally binding contracts under applicable law. The MyTruckScales Platform is not available to
                children (persons under the age of 18) or Users who have had their User account temporarily or
                permanently deactivated. By becoming a User, you represent and warrant that (a) if you are an
                entity, you have all requisite power and authority to accept these Terms, or (b) if you are an
                individual, you are at least 18 years old and that you have the right, authority and capacity to enter
                into and abide by these Terms. You may not allow other persons to use your User account, and you
                agree that you are the sole authorized user of your account.
            </Paragraph>

            <SectionHeader>
                Charges and Payments
            </SectionHeader>
            <Paragraph>
                If you are a Scale Owner, you agree to pay all fees for using the MyTruckScales Platform to enable
                Trucker Users to connect to your scales and virtual kiosks, as well as any payment processing fees
                for managing payments between you and Trucker Users. You also agree that TruckPay is not
                responsible for the accuracy of your scale or scale indicators, as the maintenance of your physical
                scales and related equipment maintenance is solely your responsibility.
            </Paragraph>
            <Paragraph>
                Trucker Users agree to pay all fees for Services procured using the MyTruckScales Platform
                incurred by you or your drivers to the Scale Owners that you access through the MyTruckScales
                Platform.
            </Paragraph>
            <Paragraph>
                Users further agree that TruckPay may require that all payments of moneys owed to it will be 
                processed through Plaid Technologies, Inc. (“Plaid”) and Stripe, Inc. (“Stripe”) and as such, 
                the terms of services and privacy policies of Plaid and Stripe are applicable to payments made in 
                connection with your use of the MyTruckScales Platform. Those payments maybe done through a “autopay” 
                process, in which your credit card or account is automatically billed on a prearranged periodic basis. 
                All Users agree that valid payment methods will be maintained at all times on the platform and that,
                while TruckPay does it’s best to attempt to validate such payment methods, it does not guarantee the 
                payment of any User and is absolutely not responsible for any such payments.
            </Paragraph>
            <Paragraph noBottomMargin>
                Fees and Other Charges.
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    <Typography>
                        • <i>Service Fee.</i> TruckPay may assess a “Service Fee” to support the MyTruckScales
                        Platform and related services provided to you by TruckPay. The amount of the Service
                        Fee may vary but shall be retained by TruckPay in its entirety.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography>
                        • <i>Other Charges.</i> Other fees and surcharges may apply to your Services, including state or
                        local fees. In addition, where required by law, TruckPay will collect applicable taxes.
                        These other charges are not shared with the applicable Provider unless expressly stated
                        otherwise.
                    </Typography>
                </ListItem>
            </List>
            <Paragraph>
                All Charges for Services provided in connection with the use of the MyTruckScales Platform
                must be facilitated and paid through a third-party payment processing service (e.g., Stripe or
                Plaid) specified by TruckPay. TruckPay may replace its third-party payment processing services
                without notice to you.
            </Paragraph>


            <SectionHeader>
                TruckPay Communications
            </SectionHeader>
            <Paragraph>
                By becoming a User, you agree to receive communications from us, including via e-mail, text
                message, calls, and push notifications. You agree that texts, calls or prerecorded messages may be
                generated by automatic telephone dialing systems. Communications from TruckPay, its affiliated
                companies and/or other Users, may include but are not limited to: operational communications
                concerning your User account or use of the MyTruckScales Platform or Services, updates
                concerning new and existing features on the MyTruckScales Platform, communications concerning
                promotions run by us or our third-party partners, and news concerning TruckPay and industry
                developments. Standard text messaging charges applied by your mobile phone carrier will apply to
                text messages we send.
            </Paragraph>

            <SectionHeader>
                Your Information
            </SectionHeader>
            <Paragraph>
                Your Information is any information you provide, publish or post to or through the MyTruckScales
                Platform (including any profile information you provide) or send to other Users (including via inapplication feedback, any email feature, or through any TruckPay-related social media posting)
                (your “Information”). You consent to us using your Information to create a User account that will
                allow you to use the MyTruckScales Platform and participate in the Services. Our collection and
                use of personal information in connection with the MyTruckScales Platform and Services is as
                provided in TruckPay’s Privacy Statement located at www.mytruckpay.com/privacy. You are
                solely responsible for your Information and your interactions with other members of the public,
                and we act only as a passive conduit for your online posting of your Information. You agree to
                provide and maintain accurate, current and complete information and that we and other members
                of the public may rely on your Information as accurate, current and complete. To enable TruckPay
                to use your Information, you grant to us a non-exclusive, worldwide, perpetual, irrevocable,
                royalty-free, transferable, sub-licensable (through multiple tiers) right and license to exercise the
                copyright, publicity, and database rights you have in your Information, and to use, copy, perform,
                display and distribute such Information to prepare derivative works, or incorporate into other
                works, such Information, in any media now known or not currently known. TruckPay does not
                assert any ownership over your Information; rather, as between you and TruckPay, subject to the
                rights granted to us in these Terms, you retain full ownership of all of your Information and any
                intellectual property rights or other proprietary rights associated with your Information.
            </Paragraph>
            <Paragraph>
                You may be able to create or log-in to your MyTruckScales User account through accounts you
                may have with third party social media platforms (each, an “SMP”). By connecting to TruckPay
                through an SMP, you understand that TruckPay may access, store, and make available any SMP
                content according to the permission settings of your SMP (e.g., friends, mutual friends, contacts or
                following/followed lists (the “SMP Content”)). You understand that SMP Content may be
                available on and through the MyTruckScales Platform to other Users. Unless otherwise specified
                in these Terms, all SMP Content, if any, shall be considered to be your Information.
            </Paragraph>

            <SectionHeader>
                Promotions and Referral Programs
            </SectionHeader>
            <Paragraph>
                TruckPay, at its sole discretion, may make available promotions with different features to any
                Users or prospective Users. These promotions, unless made to you, shall have no bearing
                whatsoever on your Agreement or relationship with TruckPay. TruckPay reserves the right to
                withhold or deduct credits or benefits obtained through a promotion the event that TruckPay
                determines or believes that the redemption of the promotion or receipt of the credit or benefit was
                in error, fraudulent, illegal, or in violation of the applicable promotion terms or these Terms.
            </Paragraph>
            <Paragraph>
                As part of your User account, TruckPay may provide you with or allow you to create a
                “MyTruckScales Code,” a unique alphanumeric code for you to distribute to friends, family and
                other persons (each a “Referred User”) to become new Users. MyTruckScales Codes may only be
                distributed for promotional purposes and must be given away free of charge. You may not sell,
                trade, or barter your MyTruckScales Code. You are prohibited from advertising MyTruckScales
                Codes. TruckPay reserves the right to deactivate or invalidate any MyTruckScales Code at any
                time in TruckPay’s discretion.
            </Paragraph>
            <Paragraph>
                From time to time, TruckPay may offer you with incentives to refer new Users to the
                MyTruckScales community (the “Referral Program”). These incentives may come in the form of
                MyTruckScales Credits, and TruckPay may set or change the incentive types, amounts, terms,
                restrictions, and qualification requirements for any incentives in its sole discretion. Your
                distribution of MyTruckScales Codes and participation in the Referral Program is subject to these
                Terms and any rules for the Referral Program that we specify from time-to-time.
            </Paragraph>


            <SectionHeader>
                Restricted Activities
            </SectionHeader>
            <Paragraph noBottomMargin>
                With respect to your use of the MyTruckScales Platform and your participation in the Services, you
                agree that you will not:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • Violate any law, statute, rule, permit, ordinance or regulation;
                </ListItem>
                <ListItem>
                    • Interfere with or disrupt the Services of the MyTruckScales Platform or the servers or
                    networks connected to the MyTruckScales Platform;
                </ListItem>
                <ListItem>
                    • Post Information or interact on the MyTruckScales Platform or Services in a manner
                    which is false, inaccurate, misleading (directly or by omission or failure to update
                    information), defamatory, libelous, abusive, obscene, profane, offensive, sexually
                    oriented, threatening, harassing, or illegal;
                </ListItem>
                <ListItem>
                    • Use the MyTruckScales Platform in any way that infringes any third party’s rights,
                    including but not limited to: intellectual property rights, copyright, patent, trademark,
                    trade secret or other proprietary rights or rights of publicity or privacy;
                </ListItem>
                <ListItem>
                    • Post, email or otherwise transmit any malicious code, files or programs designed to
                    interrupt, damage, destroy or limit the functionality of any computer software or
                    hardware or telecommunications equipment or surreptitiously intercept or expropriate
                    any system, data or personal information;
                </ListItem>
                <ListItem>
                    • Forge headers or otherwise manipulate identifiers in order to disguise the origin of any
                    information transmitted through the MyTruckScales Platform;
                </ListItem>
                <ListItem>
                    • “Frame” or “mirror” any part of the MyTruckScales Platform, without our prior written
                    authorization or use meta tags or code or other devices containing any reference to us in
                    order to direct any person to any other web site for any purpose; or
                </ListItem>
                <ListItem>
                    • Modify, adapt, translate, reverse engineer, decipher, decompile or otherwise disassemble
                    any portion of the MyTruckScales Platform or any software used on or for the
                    MyTruckScales Platform;
                </ListItem>
                <ListItem>
                    • Rent, lease, lend, sell, redistribute, license or sublicense the MyTruckScales Platform or
                    access to any portion of the MyTruckScales Platform;
                </ListItem>
                <ListItem>
                    • Use any robot, spider, site search/retrieval application, or other manual or automatic
                    device or process to retrieve, index, scrape, “data mine”, or in any way reproduce or
                    circumvent the navigational structure or presentation of the MyTruckScales Platform or
                    its contents;
                </ListItem>
                <ListItem>
                    • Transfer or sell your User account, password and/or identification to any other party
                </ListItem>
                <ListItem>
                    • Discriminate against or harass anyone on the basis of race, national origin, religion,
                    gender, gender identity, physical or mental disability, medical condition, marital status,
                    age or sexual orientation,
                </ListItem>
                <ListItem>
                    • Impersonate any person or entity;
                </ListItem>
                <ListItem>
                    • Stalk, threaten, or otherwise harass any person, or carry any weapons; or
                </ListItem>
                <ListItem>
                    • Cause any third party to engage in the restricted activities above.
                </ListItem>
            </List>

            <SectionHeader>
                User Representations, Warranties and Agreements
            </SectionHeader>
            <Paragraph noBottomMargin>
                Each User represents, warrants, and agrees that it:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • Will not make any misrepresentation regarding TruckPay, the MyTruckScales Platform,
                    the Services or your status as a User;
                </ListItem>
                <ListItem>
                    • Will not attempt to defraud TruckPay or any User on the MyTruckScales Platform or in
                    connection with your use of the MyTruckScales Platform;
                </ListItem>
                <ListItem>
                    • Will pay all applicable federal, state and local taxes based on your provision of Services
                    and any payments received by you.
                </ListItem>
            </List>

            <SectionHeader>
                Intellectual Property
            </SectionHeader>
            <Paragraph>
                All intellectual property rights in the MyTruckScales Platform shall be owned by TruckPay
                absolutely and in their entirety. These rights include and are not limited to database rights,
                copyright, design rights, trademarks and other similar rights, in each such case whether registered
                or unregistered, wherever existing in the world together with the right to apply for protection of the
                same. All other trademarks, logos, service marks, company or product names set forth in the
                MyTruckScales Platform are the property of their respective owners. You acknowledge and agree
                that any questions, comments, suggestions, ideas, feedback or other information submitted by you
                to us are non-confidential and shall become the sole property of TruckPay. TruckPay shall own
                exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted
                use and dissemination of these submissions for any purpose, commercial or otherwise, without
                acknowledgment or compensation to you.
            </Paragraph>
            <Paragraph>
                MYTRUCKSCALES and other TruckPay logos, designs, graphics, icons, scripts and service
                names are registered trademarks, trademarks or trade dress of TruckPay in the United States and/or
                other countries (collectively, the “TruckPay Marks”). Subject to your compliance with these Terms,
                you are granted a limited, revocable, non-exclusive license to display and use the TruckPay Marks
                solely in connection with your use of the MyTruckScales Platform (“License”). The License is
                non-transferable and non-assignable, and you shall not grant to any third party any right,
                permission, license or sublicense with respect to any of the rights granted hereunder without
                TruckPay’s prior written permission, which it may withhold in its sole discretion. The TruckPay
                Marks may not be used in any manner that is likely to cause confusion in the marketplace.
            </Paragraph>
            <Paragraph>
                You acknowledge that TruckPay is the owner and licensor of the TruckPay Marks, including all
                goodwill associated therewith, and that your use of the TruckPay Marks will confer no additional
                interest in or ownership of the TruckPay Marks in you but instead inures to the benefit of
                TruckPay. You agree to use the TruckPay Marks strictly in accordance with any usage guidelines
                adopted by TruckPay, as may be provided to you and revised from time to time, and to
                immediately cease any use that TruckPay determines to nonconforming or otherwise unacceptable.
            </Paragraph>
            <Paragraph noBottomMargin>
                You agree that you will not:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • Create any materials that incorporate the TruckPay Marks or any derivatives of the
                    TruckPay Marks other than as expressly approved by TruckPay in writing;
                </ListItem>
                <ListItem>
                    • Use the TruckPay Marks in any way that tends to impair their validity as proprietary
                    trademarks, service marks, trade names or trade dress, or use the TruckPay Marks other
                    than in accordance with the terms, conditions and restrictions herein;
                </ListItem>
                <ListItem>
                    • Take any other action that would jeopardize or impair TruckPay’s rights as owner of the
                    TruckPay Marks or the legality and/or enforceability of the TruckPay Marks, including,
                    without limitation, challenging or opposing TruckPay’s ownership in the TruckPay Marks;
                </ListItem>
                <ListItem>
                    • Apply for trademark registration or renewal of trademark registration of any of the
                    TruckPay Marks, any derivative of the TruckPay Marks, any combination of the TruckPay
                    Marks and any other name, or any trademark, service mark, trade name, symbol or word
                    which is similar to the TruckPay Marks; or
                </ListItem>
                <ListItem>
                    • Use the TruckPay Marks on or in connection with any product, service or activity that is in
                    violation of any law, statute, government regulation or standard.
                </ListItem>
            </List>
            <Paragraph>
                Violation of any provision of this License may result in immediate termination of the License, in
                TruckPay’s sole discretion. If you create any materials bearing the TruckPay Marks (in violation
                of these Terms or otherwise), you agree that upon their creation TruckPay exclusively owns all
                right, title and interest in and to such materials, including without limitation any modifications to
                the TruckPay Marks or derivative works based on the TruckPay Marks. You further agree to assign
                any interest or right you may have in such materials to TruckPay, and to provide information and
                execute any documents as reasonably requested by TruckPay to enable TruckPay to formalize such
                assignment.
            </Paragraph>
            <Paragraph>
                TruckPay respects the intellectual property of others, and expects Users to do the same. If you
                believe, in good faith, that any materials on the MyTruckScales Platform infringe upon your
                copyrights, please contact TruckPay at support@mytruckscales.com.
            </Paragraph>


            <SectionHeader>
                Disclaimers
            </SectionHeader>
            <Paragraph>
                The following disclaimers are made on behalf of TruckPay, our affiliates, subsidiaries, parents,
                successors and assigns, and each of our respective officers, directors, employees, agents, and
                shareholders.
            </Paragraph>
            <Paragraph>
                TruckPay does not own truck scales and hence, doesn’t directly provide weighment services. It is
                up to the Scale Owners to decide whether or not to offer Services to a Trucker User obtained
                through the MyTruckScales Platform, and it is up to the Trucker User to decide whether or not to
                accept Services from any Scale Owner contacted through the MyTruckScales Platform. We cannot
                ensure that a Scale Owner will complete weighment services in a manner acceptable to a Trucker
                User. We have no control over the quality or safety of the weighment services that occur as a result
                of the Services, including without limitation the accuracy of the weight information provided by a
                Scale Owner to the MyTruckScales Platform in connection with any Services.
            </Paragraph>
            <Paragraph>
                The MyTruckScales Platform is provided on an “as is” basis and without any warranty or
                condition, express, implied or statutory. We do not guarantee and do not promise any specific
                results from use of the MyTruckScales Platform and/or the Services, including the ability to
                provide or receive Services at any given location or time. We specifically disclaim any implied
                warranties of title, merchantability, fitness for a particular purpose and non-infringement. Some
                states do not allow the disclaimer of implied warranties, so the foregoing disclaimer may not apply
                to you.
            </Paragraph>
            <Paragraph>
                Each User acknowledges and agrees that a failure to properly use the MyTruckScales Platform by
                it or any other User with whom it uses the MyTruckScales Platform in connection with the
                provision of Services may deprive it and any such other User of the benefits thereof.
            </Paragraph>
            <Paragraph noBottomMargin>
                TruckPay may provide tools to assist Users in connection with their use of the MyTruckScales
                Platform. Without limiting the generality of the foregoing disclaimers:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • Any such tools are provided “as is” and without any warranty as to their accuracy or
                    efficacy;
                </ListItem>
                <ListItem>
                    • A User accepts all responsibility for its use of any such tools and agrees that the use of such
                    tools requires the User to critically evaluate the information produced by such tools and use
                    its independent business judgment in connection with its use of any such tools and the
                    MyTruckScales Platform.
                </ListItem>
            </List>
            <Paragraph>
                TruckPay is not responsible for the conduct, whether online or offline, of any User of the
                MyTruckScales Platform or Services. You are solely responsible for your interactions with other
                Users. By using the MyTruckScales Platform and participating in the Services, you agree to accept
                such risks and agree that TruckPay is not responsible for the acts or omissions of Users on the
                MyTruckScales Platform or for users who are participating in the Services.
            </Paragraph>
            <Paragraph>
                TruckPay expressly disclaims any liability arising from the unauthorized use of your User account.
                Should you suspect that any unauthorized party may be using your User account or you suspect
                any other breach of security, you agree to notify us immediately.
            </Paragraph>
            <Paragraph>
                It is possible for others to obtain information about you that you provide, publish or post to or
                through the MyTruckScales Platform (including any profile information you provide), send to
                other Users, or share during the Services, and to use such information to harass or harm you. We
                are not responsible for the use of any personal information that you disclose to other Users on the
                MyTruckScales Platform or through the Services. Please carefully select the type of information
                that you post on the MyTruckScales Platform or through the Services or release to others. We
                disclaim all liability, regardless of the form of action, for the acts or omissions of other Users
                (including unauthorized users, or “hackers”).
            </Paragraph>
            <Paragraph>
                Opinions, advice, statements, offers, or other information or content concerning TruckPay or made
                available through the MyTruckScales Platform, but not directly by us, are those of their respective
                authors, and should not necessarily be relied upon. Such authors are solely responsible for such
                content. Under no circumstances will we be responsible for any loss or damage resulting from
                your reliance on information or other content posted by third parties, whether on the
                MyTruckScales Platform or otherwise. We reserve the right, but we have no obligation, to monitor
                the materials posted on the MyTruckScales Platform and to remove any such material that in our
                sole opinion violates, or is alleged to violate, the law or these Terms or which might be offensive,
                illegal, or that might violate the rights, harm, or threaten the safety of Users or others.
            </Paragraph>
            <Paragraph>
                Any location data provided by the MyTruckScales Platform is for basic location purposes only and is
                not intended to be relied upon in situations where precise location information is needed or where
                erroneous, inaccurate or incomplete location data may lead to death, personal injury, property or
                environmental damage. Neither TruckPay, nor any of its content providers, guarantees the
                availability, accuracy, completeness, reliability, or timeliness of location data tracked or displayed
                by the MyTruckScales Platform. Any of your Information, including geolocation data, that you
                upload, provide, or post on the MyTruckScales Platform may be accessible to TruckPay and certain
                Users of the MyTruckScales Platform.
            </Paragraph>
            <Paragraph>
                TruckPay advises you to use the MyTruckScales Platform with a data plan with unlimited or very
                high data usage limits, and TruckPay shall not be responsible or liable for any fees, costs, or
                overage charges associated with any data plan you use to access the MyTruckScales Platform.
            </Paragraph>
            <Paragraph>
                This paragraph applies to any version of the MyTruckScales Platform that you acquire from any
                app store. These Terms are entered into between you and TruckPay. No app store is a party to
                these Terms and shall have no obligations with respect to the MyTruckScales Platform. TruckPay,
                not any app store, is solely responsible for the MyTruckScales Platform and the content thereof as
                set forth hereunder. However, any app store that provides the TruckPay app are third party
                beneficiaries of these Terms. Upon your acceptance of these Terms, each app store shall have the
                right (and will be deemed to have accepted the right) to enforce these Terms against you as a third
                party beneficiary thereof. In the event of a conflict in the terms of any app store agreement or
                terms of use and these Terms, these Terms shall control.
            </Paragraph>

            <SectionHeader>
                Indemnity
            </SectionHeader>
            <Paragraph noBottomMargin>
                You will defend, indemnify, and hold TruckPay including our affiliates, subsidiaries, parents,
                successors and assigns, and each of our respective officers, directors, employees, agents, or
                shareholders harmless from any claims, actions, suits, losses, costs, liabilities and expenses
                (including reasonable attorneys’ fees) relating to or arising out of your use of the MyTruckScales
                Platform and participation in the Services, including:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • Your breach of these Terms or the documents it incorporates by reference;
                </ListItem>
                <ListItem>
                    • Your violation of any law or the rights of a third party, including other Users;
                </ListItem>
                <ListItem>
                    • Any allegation that any materials that you submit to us or transmit through the
                    MyTruckScales Platform infringe or otherwise violate the copyright, trademark, trade
                    secret or other intellectual property or other rights of any third party; and/or
                </ListItem>
                <ListItem>
                    • Any other activities in connection with the Services.
                </ListItem>
            </List>
            <Paragraph>
                This indemnity shall be applicable without regard to the negligence of any party, including any
                indemnified person.
            </Paragraph>

            <SectionHeader>
                Limitation of Liability
            </SectionHeader>
            <Paragraph>
                IN NO EVENT WILL TRUCKPAY, INCLUDING OUR AFFILIATES, SUBSIDIARIES,
                PARENTS, SUCCESSORS AND ASSIGNS, AND EACH OF OUR RESPECTIVE OFFICERS,
                DIRECTORS, EMPLOYEES, AGENTS, OR SHAREHOLDERS (COLLECTIVELY
                “TRUCKPAY” FOR PURPOSES OF THIS SECTION), BE LIABLE TO YOU FOR ANY
                INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, CONSEQUENTIAL, OR INDIRECT
                DAMAGES (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR DELETION,
                CORRUPTION, LOSS OF DATA, LOSS OF PROGRAMS, FAILURE TO STORE ANY
                INFORMATION OR OTHER CONTENT MAINTAINED OR TRANSMITTED BY THE
                MYTRUCKSCALES PLATFORM, SERVICE INTERRUPTIONS, OR FOR THE COST OF
                PROCUREMENT OF SUBSTITUTE SERVICES) ARISING OUT OF OR IN CONNECTION
                WITH THE TRUCKPAY PLATFORM, THE SERVICES, OR THESE TERMS, HOWEVER
                ARISING, INCLUDING NEGLIGENCE, EVEN IF WE OR OUR AGENTS OR
                REPRESENTATIVES KNOW OR HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. YOU AGREE THAT TRUCKPAY HAS NO RESPONSIBILITY OR LIABILITY
                TO YOU RELATED TO ANY WEIGHMENT SERVICES PROVIDED TO YOU BY THIRD
                PARTY PROVIDERS OTHER THAN AS EXPRESSLY SET FORTH IN THESE TERMS.
                CERTAIN JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF
                CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
                DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU
                MAY HAVE ADDITIONAL RIGHTS.
            </Paragraph>

            <SectionHeader>
                Term and Termination
            </SectionHeader>
            <Paragraph>
                These Terms are an agreement and are effective upon your creation of a User account. These
                Terms may be terminated: (a) by either party, without cause, upon seven days’ prior written notice
                to TruckPay; or (b) by either Party immediately, without notice, upon the other Party’s material
                breach of these Terms. In addition, TruckPay may terminate these Terms or deactivate your User
                account immediately in the event: (i) you no longer qualify to provide Services or to operate the
                approved vehicle under applicable law, rule, permit, ordinance or regulation; (ii) you fall below
                TruckPay’s star rating or cancellation threshold; or (iii) TruckPay has the good faith belief that
                such action is necessary to protect the safety of the TruckPay community or third parties, provided
                that in the event of a deactivation pursuant to and of items (i)-(iii) above, you will be given notice
                of the potential or actual deactivation and an opportunity to attempt to cure the issue to TruckPay’s
                reasonable satisfaction prior to TruckPay permanently terminating these Terms. For all other
                breaches of these Terms, you will be provided notice and an opportunity to cure the breach. If the
                breach is cured in a timely manner and to TruckPay’s satisfaction, these Terms will not be
                permanently terminated. The terms and conditions set forth in these Terms and shall survive any
                termination or expiration of these Terms with respect to all Services provided or other matters
                occurring or existing prior to the date of termination.
            </Paragraph>

            <SectionHeader>
                Dispute Resolution and Arbitration Agreement
            </SectionHeader>
            <Paragraph>
                YOU AND TRUCKPAY MUTUALLY AGREE TO WAIVE OUR RESPECTIVE RIGHTS TO
                RESOLUTION OF DISPUTES IN A COURT OF LAW BY A JUDGE OR JURY AND AGREE
                TO RESOLVE ANY DISPUTE BY ARBITRATION, AS SET FORTH BELOW. This agreement
                to arbitrate (“Arbitration Agreement”) is governed by the Federal Arbitration Act and survives after
                the Agreement terminates or your relationship with TruckPay ends. Any such arbitration will take
                place on an individual basis, joint arbitrations and class actions are not permitted, and shall be held
                in the city of Newark, County of Hudson, New Jersey. Except as expressly provided below, this
                Arbitration Agreement applies to all disputes and claims between you and TruckPay, including our
                affiliates, subsidiaries, parents, successors and assigns, and each of our respective officers,
                directors, employees, agents, or shareholders. BY AGREEING TO ARBITRATION, YOU
                UNDERSTAND THAT YOU AND TRUCKPAY ARE WAIVING THE RIGHT TO SUE IN
                COURT OR HAVE A JURY TRIAL FOR ALL CLAIMS. This Arbitration Agreement is intended
                to require arbitration of every claim or dispute that can lawfully be arbitrated, except for those
                claims and disputes which by the terms of this Arbitration Agreement are expressly excluded from
                the requirement to arbitrate.
            </Paragraph>
            <Paragraph>
                Any arbitration conducted pursuant to this Arbitration Agreement shall be administered by the
                American Arbitration Association (“AAA”) pursuant to its Commercial that are in effect at the time
                the arbitration is initiated, as modified by the terms set forth in these Terms. Copies of these rules
                can be obtained at the AAA’s website (www.adr.org) (the “AAA Rules”) or by calling the AAA at
                1-800-778-7879. As part of the arbitration, both you and TruckPay will have the opportunity for
                reasonable discovery of non-privileged information that is relevant to the applicable claim. The
                arbitrator may award any individualized remedies that would be available in court. The arbitrator
                may award declaratory or injunctive relief only in favor of the individual party seeking relief and
                only to the extent necessary to provide relief warranted by that party's individual claims. The
                arbitrator will provide a reasoned written statement of the arbitrator’s decision which shall explain
                the award given and the findings and conclusions on which the decision is based. The arbitrator
                will decide the substance of all claims in accordance with applicable law, and will honor all claims
                of privilege recognized by law. The arbitrator shall not be bound by rulings in prior arbitrations
                involving different Users, but is bound by rulings in prior arbitrations involving the same Users to
                the extent required by applicable law. The arbitrator’s award shall be final and binding and
                judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction
                thereof, provided that any award may be challenged in a court of competent jurisdiction. Each
                User consents to the exclusive jurisdiction of the federal courts located in the city of Newark,
                County of Hudson, New Jersey and the New Jersey state court located in the city of Hackensack,
                County of Bergen, New Jersey with respect to any claim or dispute that cannot be lawfully
                arbitrated or with respect to enforcement of the Arbitration Agreement or any arbitrator’s decision.
            </Paragraph>
            <Paragraph>
                In the event that any portion of this Arbitration Agreement is deemed illegal or unenforceable, such
                provision shall be severed and the remainder of the Arbitration Agreement shall be given full force
                and effect.
            </Paragraph>

            <SectionHeader>
                Confidentiality
            </SectionHeader>
            <Paragraph noBottomMargin>
                You agree not to use any technical, financial, strategic and other proprietary and confidential
                information relating to TruckPay’s business, operations and properties, including User information
                (“Confidential Information”) disclosed to you by TruckPay for your own use or for any purpose
                other than as contemplated herein. You shall not disclose or permit disclosure of any Confidential
                Information to third parties. You agree to take all reasonable measures to protect the secrecy of
                and avoid disclosure or use of Confidential Information of TruckPay in order to prevent it from
                falling into the public domain. Notwithstanding the above, you shall not have liability to TruckPay
                with regard to any Confidential Information which you can prove it:
            </Paragraph>
            <List disablePadding>
                <ListItem>
                    • Was publicly available at the time it was disclosed by TruckPay or became publicly
                    available through no fault of yours;
                </ListItem>
                <ListItem>
                    • Was known to you, without restriction, at the time of disclosure, as demonstrated by files in
                    existence at the time of disclosure;
                </ListItem>
                <ListItem>
                    • Is disclosed with the prior written approval of TruckPay; or
                </ListItem>
                <ListItem>
                    • Became known to you, without restriction, from a source other than TruckPay without
                    breach of these Terms or any other confidentiality agreement by you and otherwise not in
                    violation of TruckPay’s rights.
                </ListItem>
            </List>
            <Paragraph>
                Nothing in these Terms shall be deemed to prohibit Confidential Information from being disclosed
                pursuant to the order or requirement of a court, administrative agency, or other governmental body;
                provided, however, that you shall provide prompt notice of such court order or requirement to
                TruckPay and provide TruckPay with any other assistance reasonably requested by TruckPay to
                enable TruckPay to seek a protective order or otherwise prevent or restrict such disclosure.
            </Paragraph>

            <SectionHeader>
                Relationship with TruckPay
            </SectionHeader>
            <Paragraph>
                As a User of the MyTruckScales Platform, you acknowledge and agree that you and TruckPay are
                in a direct business relationship, and the relationship between the parties under these Terms is
                solely that of independent contracting parties. You and TruckPay expressly agree that these Terms
                are not an employment agreement and does not create an employment relationship between you
                and TruckPay and no joint venture, franchisor-franchisee, partnership, or agency relationship is
                intended or created by these Terms. You have no authority to bind TruckPay, and you undertake
                not to hold yourself out as an employee, agent or authorized representative of TruckPay.
            </Paragraph>
            <Paragraph>
                TruckPay does not, and shall not be deemed to, direct or control you generally or in your
                performance under these Terms specifically, including in connection with your provision of
                Services, your acts or omissions, or your operation and maintenance of your vehicle. You retain
                the sole right to determine when, where, and for how long you will utilize the MyTruckScales
                Platform. As a Scale Owner, you retain the option to accept or to decline or ignore a Driver User’s
                request for Services via the MyTruckScales Platform, or to cancel an accepted request for Services
                via the MyTruckScales Platform, subject to TruckPay’s then-current cancellation policies. You
                acknowledge and agree that you have complete discretion to provide Services or otherwise engage
                in other business or employment activities.
            </Paragraph>

            <SectionHeader>
                Other Services
            </SectionHeader>
            <Paragraph>
                In addition to connecting scale-owners with trucking users, the MyTruckScales Platform may
                enable Users to provide or receive other services from other third parties (collectively, the “Other
                Services”). You understand that the Other Services are subject to the terms and pricing of the
                third-party provider. If you choose to purchase Other Services through the MyTruckScales
                Platform, you authorize TruckPay to charge your payment method on file, if any, according to the
                pricing terms set by the third-party provider. You agree that TruckPay is not responsible and may
                not be held liable for the Other Services or the actions or omissions of the third-party provider.
                Such Other Services may not be investigated, monitored or checked for accuracy, appropriateness,
                or completeness by us, and we are not responsible for any Other Services accessed through the
                MyTruckScales Platform.
            </Paragraph>

            <SectionHeader>
                General
            </SectionHeader>
            <Paragraph>
                These Terms shall be governed by the laws of the State of New Jersey without regard to choice of
                law principles. This choice of law provision is only intended to specify the use of New Jersey law
                to interpret these Terms and is not intended to create any other substantive right to persons outside
                of New Jersey to assert claims under New Jersey law whether by statute, common law, or
                otherwise. If any provision of these Terms is or becomes invalid or non-binding, the parties shall
                remain bound by all other provisions hereof. In that event, the parties shall replace the invalid or
                non-binding provision with provisions that are valid and binding and that have, to the greatest
                extent possible, a similar effect as the invalid or non-binding provision, given the contents and
                purpose of these Terms. You agree that these Terms and all incorporated agreements may be
                automatically assigned by TruckPay, in our sole discretion by providing notice to you. Except as
                explicitly stated otherwise, any notices to TruckPay shall be given by certified mail, postage
                prepaid and return receipt requested to TruckPay, Inc., 15 Central Avenue, Tenafly, NJ 07670. Any
                notices to you shall be provided to you through the MyTruckScales Platform or given to you via
                the email address or physical address you provide to TruckPay during the registration process.
                Headings are for reference purposes only and in no way define, limit, construe or describe the
                scope or extent of such section. A party’s failure to act with respect to a breach by the other party
                does not constitute a waiver of the party’s right to act with respect to subsequent or similar
                breaches. These Terms set forth the entire understanding and agreement between you and
                TruckPay with respect to the subject matter hereof and supersedes all previous understandings and
                agreements between the parties, whether oral or written.
            </Paragraph>
            <Paragraph>
                If you have any questions regarding the MyTruckScales Platform or Services, please contact
                TruckPay at support@mytruckscales.com.
            </Paragraph>
        </AuthLayoutWrapper>
    )

}

export default MTSTermsOfService