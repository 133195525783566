import { SET_THEME, SET_TEXT_SIZE, SET_STYLE_OPTIONS } from '../actions/themeActions'

const nullState = {
  currentTheme: 'standard',
  textSize: 1
}
const possibleStyleOptions = {
  theme: ['standard', 'light', 'dark', 'greyscale', 'high_contrast'],
  text_size: [1, 2, 3, 4, 5],
  link_underline_behavior: ['hover', 'always'],
}
export default function reducer(state = nullState, actions) {
  switch (actions.type) {

    case SET_THEME:
      return {
        ...state,
        currentTheme: actions.payload
      }

    case SET_TEXT_SIZE:
      return {
        ...state,
        textSize: actions.payload
      }

    case SET_STYLE_OPTIONS:
      return {
        ...state,
        textSize: actions.payload.textSize,
        currentTheme: actions.payload.theme,
      }

    default:
      return state
  }
}
