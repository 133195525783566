import { TPAPIBase } from '../../env';
import { functions } from '../reducers/helpersReducer';
import i18n from '../../i18n';

export const getCompanyScales = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/scales`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then((response) => response.json())
)
export const getScale = (token, scaleID) => (
    fetch(`${TPAPIBase}/scales/${scaleID}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then((response) => response.json())
)
export const getScalesAtLocation = (token, latitude, longitude) => (
    fetch(`${TPAPIBase}/scales/here?latitude=${latitude}&longitude=${longitude}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then((response) => response.json())
)
export const getCompanyScalesAtLocation = (token, companyID, latitude, longitude) => (
    fetch(`${TPAPIBase}/company/${companyID}/scales/nearby?latitude=${latitude}&longitude=${longitude}&radius_in_meters=2000`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then((response) => response.json())
)
export const getScaleHardwareSpecs = (token) => (
    fetch(`${TPAPIBase}/scale-hardware-specs`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then((response) => response.json())
)
export const getScaleCameraSpecs = (token) => (
    fetch(`${TPAPIBase}/camera-specs`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then((response) => response.json())
)
export const createScale = (token, companyID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/scales`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const editScale = (token, companyID, scaleID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/scales/${scaleID}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
