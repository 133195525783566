import { 
    LOGIN_CURRENT_USER, 
    LOGOUT_CURRENT_USER, 
    START_LOADING, 
    STOP_LOADING, 
    SHOW_ALERT, 
    HIDE_ALERT, 
    SELECT_COMPANY, 
    UPDATE_USER,
    UPDATE_USER_SETTINGS, 
    UPDATE_NOTIFICATION_COUNT,
    UPDATE_DUTY_STATUS,
    UPDATE_INSPECTOR_DETAILS
} from '../actions/sessionActions';
import { merge } from 'lodash';
import { User, UserSettings } from './entities/User';
import { Company, CompanyPermission } from './entities';
import { AlertProps, Color } from '@material-ui/lab';

interface AlertInfo {
    alert: boolean;
    alertTitle: string;
    alertMessage: string;
    alertType: Color
}
interface InspectorDetails {
    token: string;
    jobID: number;
}
interface EntitiesState {
    user: Partial<User>;
    company: Partial<Company>;
    loading: boolean;
    alerts: AlertInfo;
    notificationCount: number;
    isOnDuty: boolean;
    inspectorDetails: InspectorDetails;
}
const nullState: EntitiesState = {
    user: { },
    company: { },
    loading: false,
    alerts: {
        alert: false,
        alertTitle: "",
        alertMessage: "",
        alertType: 'success'
    },
    notificationCount: 0,
    isOnDuty: false,
    inspectorDetails: {
        token: '',
        jobID: 0
    },
};
type SessionAction = Partial<EntitiesState> & { type: string } & AlertInfo & { company: Company } & { payload: InspectorDetails } & { settings: UserSettings }

const sessionReducer = (state: EntitiesState = nullState, action: SessionAction) => {
    Object.freeze(state);
    switch (action.type) {
        case LOGIN_CURRENT_USER: {
            window.localStorage.setItem("TPUser", JSON.stringify(action.user));
            return merge({}, state, { user: action.user });
        }
        case LOGOUT_CURRENT_USER: {
            window.localStorage.clear();
            return nullState;
        }
        case START_LOADING: {
            return merge({}, state, { loading: true });
        }
        case STOP_LOADING: {
            return merge({}, state, { loading: false });
        }
        case SHOW_ALERT: {
            return merge({}, state, { alerts: { alert: true, alertTitle: action.alertTitle, alertMessage: action.alertMessage, alertType: action.alertType }})
        }
        case HIDE_ALERT: {
            return merge({}, state, { alerts: { alert: false, alertTitle: "", alertMessage: "" }})
        }
        case SELECT_COMPANY: {
            action.company.isAdmin = action.company.perms.some((perm: CompanyPermission) => perm.name === 'truckpay');
            action.company.isForeign = action.company.address.country.code != 'US';
            window.localStorage.setItem(`${process.env.REACT_APP_ENV || process.env.NODE_ENV}-TPCompany`, JSON.stringify(action.company));
            return merge({}, state, { company: action.company })
        }
        case UPDATE_USER: {
            const newUser = { ...state.user, ...action.user }
            window.localStorage.setItem("TPUser", JSON.stringify(newUser));
            return merge({}, state, { user: newUser })
        }
        case UPDATE_USER_SETTINGS: {
            return merge({}, state, { user: { ...state.user, settings: action.settings } })
        }
        case UPDATE_NOTIFICATION_COUNT: {
            return merge({}, state, { notificationCount: action.notificationCount })
        }
        case UPDATE_DUTY_STATUS: {
            return merge({}, state, { isOnDuty: action.isOnDuty })
        }
        case UPDATE_INSPECTOR_DETAILS: {
            return merge({}, state, { inspectorDetails: { ...action.payload } })
        }
        default: return state;
    }
}
export default sessionReducer;