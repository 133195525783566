import React from "react"
import { Typography } from "../dashboards/Util/SpacedComponents";



export const SectionHeader = ({ children, bold }: { children: any; bold?: boolean }) => (
    <Typography variant="subtitle2" style={{ textDecoration: "underline", fontWeight: bold ? "bold" : "unset" }} mt={4} mb={2}>
        {children}
    </Typography>
)
export const Paragraph = ({ children, noBottomMargin }: { children: any; noBottomMargin?: boolean }) => (
    <Typography mb={noBottomMargin ? 0 : 3}>
        {children}
    </Typography>
)