import { Button, Card, CardContent, CardHeader, Dialog, DialogTitle, IconButton, Tooltip, Typography as MuiTypography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Company, DeliveryApi, DeliveryMaterial, MixDesign } from "../../../../../redux/reducers/entities";
import DeliveryPictures from "./DeliveryPictures";
import DeliveryTimes from "./DeliveryTimes";
import styled from "styled-components";
import Separator from "../../../Util/Separator";
import { spacing } from "@material-ui/system";
import { formatNumber } from "../../../../../redux/reducers/helpersReducerr";
import DataInfo from "../../../Util/DataInfo";
import CreateEditMixBatchModal from "../../../Materials/modals/ModalCreateEditMixBatch";
import { DialogContent, Divider, Grid } from "../../../Util/SpacedComponents";
import { Info } from "@material-ui/icons";
import { getMaterialMixDesignByID } from "../../../../../redux/util/mixAPI";
import { useRootSelector } from "../../../../..";

const Typography = styled(MuiTypography)(spacing)

const DeliveryDetailedStatusSpan = styled.span<{ error?: boolean }>`
    display: inline;
    padding: .2em .6em .3em;
    font-weight: bold;
    line-height: 1;
    color: ${props => props.theme.palette.primary.contrastText};
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    background-color: ${props => props.error ? props.theme.palette.error.main : props.theme.palette.primary.main};
`

interface Props {
    delivery: DeliveryApi;
    jobOwnerCompany?: Company;
    shouldCreateBatch: boolean;
    setShouldCreateBatch: (bool: boolean) => void;
}
const DeliveryDetails = ({ delivery, jobOwnerCompany, shouldCreateBatch, setShouldCreateBatch }: Props) => {
    const { t } = useTranslation();
    const token = useRootSelector(state => state.session.user.token!!)
    const companyId = useRootSelector(state => state.session.company.id!!)

    const [openCreateBatchModal, setOpenCreateBatchModal] = useState(false)
    const [openBatchInfoDialog, setOpenBatchInfoDialog] = useState(false)
    const [mixDesign, setMixDesign] = useState<MixDesign | null>(delivery.mix_batch?.mix_design || null)

    const getDesign = async (material: DeliveryMaterial) => {
        const belongsToJobOwnerCompany = delivery.job.company_id === companyId
        if (!belongsToJobOwnerCompany) {
            setMixDesign(delivery.mix_batch?.mix_design || null)
            return;
        }
        try {
            const response = await getMaterialMixDesignByID(token, companyId, material.material_mix_design_id!!)
            if (!response.success) return;
            setMixDesign(response.data)
        } catch (e) {

        }
    }

    useEffect(() => {
        const inspectableMaterial = delivery.materials.find(mat => mat.material_mix_design_id)
        if (inspectableMaterial) {
            getDesign(inspectableMaterial)
        }
    }, [])

    const mixBatch = delivery.mix_batch

    return <>
        <Grid container spacing={10}>
            <Grid item xs={12}>
                <Typography display="inline">{t('timeTranslations.status')}: </Typography> 
                <DeliveryDetailedStatusSpan error={delivery.is_voided || delivery.is_canceled}>
                    {t(`deliveryTranslations.detailedStatuses.${delivery.detailedStatus}`)}
                </DeliveryDetailedStatusSpan>
            </Grid>
        </Grid>
        <Grid container spacing={5}>
            <Grid item xs={12} sm={4}>
                <DeliveryTimes delivery={delivery} type="checkin" />
            </Grid>
            <Grid item xs={12} sm={4}>
                <DeliveryTimes delivery={delivery} type="checkout" />
            </Grid>
            <Grid item xs={12} sm={4}>
                <DeliveryTimes delivery={delivery} type="complete" />
            </Grid>
        </Grid>
        <Grid container spacing={5} mt={3}>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>{t('jobTranslations.jobDetails')}</Typography>
                <Separator mt={3} />
            </Grid>
            {!!jobOwnerCompany?.name && <Grid item xs={12} sm="auto">
                <DataInfo
                    mb={0}
                    title={t('companyTranslations.company')}
                    value={jobOwnerCompany?.name || ""}
                />
            </Grid>}
            <Grid item xs={12} sm="auto">
                <DataInfo
                    mb={0}
                    title={t('jobTranslations.jobName')}
                    value={delivery.job.name}
                />
            </Grid>
        </Grid>
        
        <Grid container spacing={5} mt={3}>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>{t('deliveryTranslations.deliveryDetails')}</Typography>
                <Separator mt={3} />
            </Grid>
            {delivery.bol && <Grid item xs={12} sm="auto">
                <DataInfo
                    mb={0} 
                    title={t('deliveryTranslations.bol')}
                    value={delivery.bol}
                />
            </Grid>}
            {delivery.constant_weighmaster_name && (<Grid item xs={12} sm="auto">
                <DataInfo
                    mb={0} 
                    title={t('deliveryTranslations.weighmasterName')}
                    value={`${delivery.constant_weighmaster_name} ${delivery.constant_weighmaster_license_number || ""}`}
                />
            </Grid>)}
            {delivery.scanner && (<Grid item xs={12} sm="auto">
                <DataInfo
                    mb={0} 
                    title={t('deliveryTranslations.scanner')}
                    value={`${delivery.scanner.first_name} ${delivery.scanner.last_name}`}
                />
            </Grid>)}
            <Grid item xs={12} sm="auto">

            </Grid>
            <Grid item xs={12} sm="auto">

            </Grid>
            <Grid item xs={12} sm="auto">


                {Boolean(delivery.job && delivery.job.transaction_type_id == 2) && <>
                    
                    {delivery.gross_weight ? <Grid item xs={12} sm="auto">
                        <DataInfo
                            mb={0}
                            title={t('deliveryTranslations.grossWeight')}
                            value={`${formatNumber(delivery.gross_weight, { forCapacities: true })} ${delivery.weight_unit}`}
                        />
                    </Grid> : <>
                        {Boolean(delivery.job && delivery.job.uses_external_weight) && <Grid item xs={12} sm="auto">
                            <DataInfo
                                mb={0}
                                title={t('deliveryTranslations.grossWeight')}
                                value="Weight Processing"
                            />
                        </Grid>}
                    </>}

                    {delivery.net_weight ? <>
                        <Typography color="textSecondary">
                            {t('deliveryTranslations.netWeight')}
                        </Typography>
                        <Typography mb={5}>
                            {formatNumber(delivery.net_weight, { forCapacities: true })} {delivery.weight_unit}
                        </Typography>
                    </> : <>
                        {Boolean(delivery.job && delivery.job.uses_external_weight) && <>
                            <Typography color="textSecondary">
                                {t('deliveryTranslations.netWeight')}
                            </Typography>
                            <Typography mb={5}>
                                Weight Processing
                            </Typography>
                        </>}
                    </>}
                </>}


            </Grid>
        </Grid>
        <Grid container spacing={5} mt={3}>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>Driver Details</Typography>
                <Separator mt={3} />
            </Grid>
        </Grid>
        <Grid container spacing={5}>   
            <Grid item xs={12} sm="auto">
                <Typography color="textSecondary">
                    {t('deliveryTranslations.company')}
                </Typography>
                <Typography mb={3}>
                    {delivery.driver_company_name}
                </Typography>
            </Grid>
            <Grid item xs={12} sm="auto">
                <Typography color="textSecondary">
                    {t('deliveryTranslations.driverName')}
                </Typography>
                <Typography mb={3}>
                    {delivery.driver_name}
                </Typography>
            </Grid>
            <Grid item xs={12} sm="auto">
                <DataInfo
                    mb={0}
                    title={t('vehicleTranslations.vehicle')}
                    value={`${delivery.vehicle.year} ${delivery.vehicle.make} ${delivery.vehicle.model}`}
                />
            </Grid>
            {delivery.vehicle.license_plate && (<Grid item xs={12} sm="auto">
                <DataInfo
                    mb={0}
                    title={`${delivery.vehicle.license_plate_country.code} ${t('vehicleTranslations.licensePlate')}`}
                    value={delivery.vehicle.license_plate}
                />
            </Grid>)}
            {delivery.vehicle.license_plate_2 && (<Grid item xs={12} sm="auto">
                <DataInfo
                    mb={0}
                    title={`${delivery.vehicle.license_plate_2_country.code} ${t('vehicleTranslations.licensePlate')}`}
                    value={delivery.vehicle.license_plate_2}
                />
            </Grid>)}
        </Grid>
        <Grid container spacing={5} alignItems="center" mt={3}>
            <Grid item>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>{t('materialTranslations.materials')}</Typography>
            </Grid>
            {shouldCreateBatch && 
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setOpenCreateBatchModal(true)}
                        size="small"
                    >
                        Create Mix Batch For Delivery
                    </Button>
                    <IconButton size="small" onClick={() => setOpenBatchInfoDialog(true)}>
                        <Info />
                    </IconButton>
                    {openCreateBatchModal && <CreateEditMixBatchModal 
                        setModalOpen={setOpenCreateBatchModal}
                        onSetMixBatchForDelivery={() => setShouldCreateBatch(false)}
                        // deliveryID={delivery.id}
                        delivery={delivery}
                    />}
                    <Dialog
                        open={openBatchInfoDialog}
                        onBackdropClick={() => setOpenBatchInfoDialog(false)}
                        onEscapeKeyDown={() => setOpenBatchInfoDialog(false)}
                    >
                        <DialogTitle>
                            Creating Mix Batches
                        </DialogTitle>
                        <DialogContent pb={5}>
                            When performing jobs that have inspectable materials, the load batch may very between loads. 
                            Create a mix batch to log the material values and measurements for a specific load.
                        </DialogContent>
                    </Dialog>
            </Grid>}
        </Grid>
        <Separator mt={3} />
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Typography color="textSecondary">
                    {t('materialTranslations.material')}
                </Typography>
                <Typography mb={5}>
                    {delivery.displayMaterials}
                </Typography>
            </Grid>
            {Boolean(mixBatch) && <Grid item xs={12} md={6}>
                <Typography color="textSecondary">Mix Batch Details:</Typography>
                <Typography>Batch Ticket #: {mixBatch?.batch_ticket_number}</Typography>
                {Boolean(mixBatch?.weight && mixBatch?.weight_units) && 
                    <Typography>{t("unitTranslations.weight")}: {mixBatch?.weight} {t(`unitTranslations.weightUnits.${mixBatch?.weight_units}`)}</Typography>
                }
                {Boolean(mixBatch?.volume && mixBatch?.volume_units) && 
                    <Typography>{t("unitTranslations.volume")}: {mixBatch?.volume} {t(`unitTranslations.volumeUnits.${mixBatch?.volume_units}`)}</Typography>
                }
                {Boolean(mixBatch?.temperature && mixBatch?.temperature_units) && 
                    <Typography>{t("unitTranslations.temperature")}: {mixBatch?.temperature} {t(`unitTranslations.temperatureUnits.${mixBatch?.temperature_units}`)}</Typography>
                }
                {Boolean(mixBatch?.slump && mixBatch?.slump_units) && 
                    <Typography>{t("unitTranslations.slump")}: {mixBatch?.slump} {t(`unitTranslations.distanceUnits.${mixBatch?.slump_units}`)}</Typography>
                }
                {Boolean(mixBatch?.mixing_revolutions) && 
                    <Typography>{t("materialTranslations.mixingRevolutions")}: {mixBatch?.mixing_revolutions}</Typography>
                }
                {Boolean(mixBatch?.air_content_percentage) && 
                    <Typography>{t('materialTranslations.airContentPercentage')}: {mixBatch?.air_content_percentage}%</Typography>
                }

                <Typography color="textSecondary" mt={3}>{t("materialTranslations.constituents")}</Typography>
                {mixBatch?.constituents?.map(batchConstituent => {
                    const designConstituentMaterial = batchConstituent.design_constituent!!.custom_material || batchConstituent.design_constituent!!.material
                    const finalMaterial = batchConstituent.amendment_custom_material || designConstituentMaterial
                    if (batchConstituent.design_constituent!!.primary_measurement_type === "volume") {
                        return <Typography>{finalMaterial?.custom_material_name || finalMaterial?.name} - {batchConstituent.volume} {t(`unitTranslations.${batchConstituent.volume_units}`)}</Typography>
                    } else {
                        return <Typography>{finalMaterial?.custom_material_name || finalMaterial?.name} - {batchConstituent.weight} {t(`unitTranslations.${batchConstituent.weight_units}`)}</Typography>
                    }
                }) || ""}
            </Grid>}
            {Boolean(mixDesign) && <Grid item xs={12} md={6}>
                <Typography color="textSecondary">Mix Design Details:</Typography>
                <Typography>{t("materialTranslations.mixID")}: {mixDesign?.mix_id}</Typography>
                <Typography>{t("name")}: {mixDesign?.name}</Typography>
                <Typography>Notes: {mixDesign?.notes || t('na')}</Typography>
                {Boolean(mixDesign?.target_weight && mixDesign?.weight_units) && 
                    <Typography>Target Weight: {mixDesign?.target_weight} {t(`unitTranslations.weightUnits.${mixDesign?.weight_units}`)}</Typography>
                }
                {Boolean(mixDesign?.target_volume && mixDesign?.volume_units) && 
                    <Typography>Target Volume: {mixDesign?.target_volume} {t(`unitTranslations.volumeUnits.${mixDesign?.volume_units}`)}</Typography>
                }
                {Boolean(mixDesign?.target_temperature && mixDesign?.temperature_units) && 
                    <Typography>Target Temperature: {mixDesign?.target_temperature} {t(`unitTranslations.temperatureUnits.${mixDesign?.temperature_units}`)}</Typography>
                }
                {Boolean(mixDesign?.target_slump && mixDesign?.slump_units) && 
                    <Typography>Target Slump: {mixDesign?.target_slump} {t(`unitTranslations.distanceUnits.${mixDesign?.slump_units}`)}</Typography>
                }
                {Boolean(mixDesign?.target_mixing_revolutions) && 
                    <Typography>Target Mix Revolutions: {mixDesign?.target_mixing_revolutions}</Typography>
                }
                {Boolean(mixDesign?.target_air_content_percentage) && 
                    <Typography>Target {t('materialTranslations.airContentPercentage')}: {mixDesign?.target_air_content_percentage}%</Typography>
                }
            </Grid>}
        </Grid>
        {Boolean(delivery.hasImagesToShow) && <Grid container spacing={5} mt={3}>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>Images</Typography>
                <Separator mt={3} />
            </Grid>
            <Grid item xs={12}>

                {(
                    delivery.load_picture_url
                    || delivery.drop_picture_url
                ) && <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ fontWeight: "bold" }} align="center">Load Images</Typography>
                        <Divider />
                    </Grid>
                    {delivery.load_picture_url && <Grid item xs={12} md={6}>
                        <DeliveryPictures label={t('deliveryTranslations.loadPicture')} url={delivery.load_picture_url} />
                    </Grid>}
                    {delivery.drop_picture_url && <Grid item xs={12} md={6}>
                        <DeliveryPictures label={t('deliveryTranslations.unloadPicture')} url={delivery.drop_picture_url} />
                    </Grid>}
                </Grid>}
                {(
                    delivery.checkin_signature_url
                    || delivery.checkout_signature_url
                    || delivery.driver_checkin_signature_url
                    || delivery.driver_checkout_signature_url 
                ) && <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ fontWeight: "bold" }} align="center">Signatures</Typography>
                        <Divider />
                    </Grid>
                    {delivery.checkin_signature_url && <Grid item xs={12} md={6}>
                        <DeliveryPictures isSignature label={t('deliveryTranslations.checkinSignature')} url={delivery.checkin_signature_url} />
                    </Grid>}
                    {delivery.checkout_signature_url && <Grid item xs={12} md={6}>
                        <DeliveryPictures isSignature label={t('deliveryTranslations.checkoutSignature')} url={delivery.checkout_signature_url} />
                    </Grid>}
                    {delivery.driver_checkin_signature_url && <Grid item xs={12} md={6}>
                        <DeliveryPictures isSignature label={t('deliveryTranslations.driverCheckinSignature')} url={delivery.driver_checkin_signature_url}/>
                    </Grid>}
                    {delivery.driver_checkout_signature_url && <Grid item xs={12} md={6}>
                        <DeliveryPictures isSignature label={t('deliveryTranslations.driverCheckoutSignature')} url={delivery.driver_checkout_signature_url}/>
                    </Grid>}
                </Grid>}

                {(
                    delivery.inbound_front_image_url
                    || delivery.inbound_back_image_url 
                    || delivery.inbound_overhead_image_url 
                    || delivery.outbound_front_image_url
                    || delivery.outbound_back_image_url 
                    || delivery.outbound_overhead_image_url 
                    || delivery.scale_weight_image
                ) && <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ fontWeight: "bold" }} align="center">Scale Images</Typography>
                        <Divider />
                    </Grid>
                    
                    {delivery.inbound_front_image_url && <Grid item xs={12} md={6}>
                        <DeliveryPictures label={"Inbound Front"} url={delivery.inbound_front_image_url}/>
                    </Grid>}
                    {delivery.inbound_back_image_url && <Grid item xs={12} md={6}>
                        <DeliveryPictures label={"Inbound Back"} url={delivery.inbound_back_image_url}/>
                    </Grid>}
                    {delivery.inbound_overhead_image_url && <Grid item xs={12} md={6}>
                        <DeliveryPictures label={"Inbound Overhead"} url={delivery.inbound_overhead_image_url}/>
                    </Grid>}
                    
                    {delivery.outbound_front_image_url && <Grid item xs={12} md={6}>
                        <DeliveryPictures label={"Outbound Front"} url={delivery.outbound_front_image_url}/>
                    </Grid>}
                    {delivery.outbound_back_image_url && <Grid item xs={12} md={6}>
                        <DeliveryPictures label={"Outbound Back"} url={delivery.outbound_back_image_url}/>
                    </Grid>}
                    {delivery.outbound_overhead_image_url && <Grid item xs={12} md={6}>
                        <DeliveryPictures label={"Outbound Overhead"} url={delivery.outbound_overhead_image_url}/>
                    </Grid>}
                    {delivery.scale_weight_image && <Grid item xs={12} md={12}>
                        <DeliveryPictures label={t('deliveryTranslations.scaleWeightImage')} url={delivery.scale_weight_image} />
                    </Grid>}
                </Grid>}
            </Grid>
        </Grid>}
    </>
}

export default DeliveryDetails