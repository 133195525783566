import { Card, CardContent, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DeliveryApi } from "../../../../../redux/reducers/entities";
import { Address, SimpleAddress } from "../../../../../redux/reducers/entities/Location";
import { parseGoogleAddressComponents } from "../../../../../redux/reducers/helpersReducerr";

interface Props {
    delivery: DeliveryApi;
    type?: "pickup" | "dropoff"
}

// Functionality of this component depends on grabbing the Google API from the window
const DeliveryLocations = ({ delivery, type }: Props) => {
    const { t } = useTranslation();
    const [fetchedAddress, setFetchedAddress] = useState(false)
    const [info, setInfo] = useState<any[]>([])
    const [title, setTitle] = useState<string>(type === 'pickup' ? t('addressTranslations.pickupAddress') : t('addressTranslations.dropoffAddress'))

    useEffect(() => {
        addInitialInfo()
    }, [])

    useEffect(() => {
        if (info.length) {
            fetchAddress()
        }
    }, [info])
    const addInitialInfo = () => {
        const newInfo: any[] = []
        if (type === 'pickup') {
            if (delivery.route.address_from.location_id) {
                const locationID = `${t('addressTranslations.pickupLocationID')}: ${delivery.route.address_from.location_id}`
                newInfo.push(locationID)
            }
        } else {
            if (delivery.route.address_to.location_id) {
                const locationID = `${t('addressTranslations.dropoffLocationID')}: ${delivery.route.address_to.location_id}`
                newInfo.push(locationID)
            }
        }
        const latitude = `${t('addressTranslations.latitude')}: ${type === 'pickup' ? delivery.checkin_latitude : delivery.checkout_latitude}`
        newInfo.push(latitude)
        const longitude = `${t('addressTranslations.longitude')}: ${type === 'pickup' ? delivery.checkin_longitude : delivery.checkout_longitude}`
        newInfo.push(longitude)
        setInfo(newInfo)
    }

    const fetchAddress = async () => {
        if (!fetchedAddress && (window as any).google?.maps) {
            setFetchedAddress(true)
            const anyWindow = window as any

            const coords = type === 'pickup' ? {
                lat: delivery.checkin_latitude,
                lng: delivery.checkin_longitude
            } : {
                lat: delivery.checkout_latitude,
                lng: delivery.checkout_longitude
            }

            const geocoder = new anyWindow.google.maps.Geocoder();
            geocoder.geocode({
                latLng: coords
            }, (results: any, status: any) => {
                if (results?.length) {
                    const bestResult = results[0]
                    const parsedAddress = parseGoogleAddressComponents(bestResult.address_components, true)
                    updateAddress(parsedAddress)
                }
            })
        }
    }

    const updateAddress = (parsedAddress: SimpleAddress) => {
        const updatedInfo = [ ...info ]
        updatedInfo.unshift(<br />)
        const cityProvince = `${parsedAddress.city}, ${parsedAddress.province}`
        updatedInfo.unshift(cityProvince)
        const addressLine1 = `${parsedAddress.address_line_1}`
        updatedInfo.unshift(addressLine1)
        setInfo(updatedInfo)
    }

    return <Card variant="outlined" raised>
        <CardContent style={{ backgroundColor: "rgba(0,0,0,0.1)"}}>

            <Typography gutterBottom variant="subtitle2">{title}</Typography>

            {info.map((info, index) => <Typography key={info}>
                {info}
            </Typography>)}
               
        </CardContent>
    </Card>
}
export default DeliveryLocations