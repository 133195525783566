import { Dialog, DialogProps } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { DialogContent } from "./SpacedComponents";

interface Props extends Omit<DialogProps, "open"> {
    setModalOpen: (open: boolean) => void;
    children: any;
}

const TPDialog = ({
    setModalOpen,
    children,
    ...props
}: Props) => {
    const { t } = useTranslation()

    return <Dialog
        {...props}
        open={true}
        onBackdropClick={() => setModalOpen(false)}
        onEscapeKeyDown={() => setModalOpen(false)}
        fullWidth
    >
        <DialogContent mb={5}>
            {children}
        </DialogContent>
    </Dialog>
}

export default TPDialog