import { TPAPIBase } from '../../env';
import i18n from '../../i18n';
import { Company, DeliveryApi, Job, MaterialCategoryDataTypes, MaterialCategoryUnitTypes, RestrictionWhitelistEntry, Role, Site } from '../reducers/entities';
import { functions, formatParams, formatPhoneNumber } from '../reducers/helpersReducerr';
import { getJobMetadata } from './jobAPIts';
import { ConfirmationResponse, Paginate, TPResponse } from './util';

export const getValidUnits = (token: string) => (
    fetch(`${TPAPIBase}/units`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getCurrencies = (token: string) => (
    fetch(`${TPAPIBase}/currencies`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getEquipment = (token: string) => (
    fetch(`${TPAPIBase}/equipment`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getSupportedCountries = () => (
    fetch(`${TPAPIBase}/countries/availability`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
        }
    }).then(response => response.json())
)
export const getMaterials = (token: string) => (
    fetch(`${TPAPIBase}/material`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)

export const getCompanyPositions = (token: string) => (
    fetch(`${TPAPIBase}/construction_company/positions`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)

export const getRoles = (token: string): Promise<TPResponse<Role[]>> => (
    fetch(`${TPAPIBase}/role`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getFireReasons = (token: string) => (
    fetch(`${TPAPIBase}/fire_reasons`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getResignationReasons = (token: string) => (
    fetch(`${TPAPIBase}/resignation_reasons`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getLegalEntityTypes = (token: string) => (
    fetch(`${TPAPIBase}/legal-entity-types`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getCompanyEntityTypes = (token: string) => (
    fetch(`${TPAPIBase}/entity`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export interface RequestPasswordResetCodePayload {
    email : string;
    send_email: boolean;
    send_sms: boolean;
}
export const requestPasswordResetCode = (data: RequestPasswordResetCodePayload) => (
    fetch(`${TPAPIBase}/reset-password`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export interface ChangePasswordPayload {
    reset_key: string;
    password: string;
    password_confirmation: string;
}
export const changePassword = (data: ChangePasswordPayload) => (
    fetch(`${TPAPIBase}/password/reset`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)

// ACTUAL ADMIN APIs

export const getRealTimeReport = (token: string) => (
    fetch(`${TPAPIBase}/truckpay/realtime/reports?convert_to_currency=USD`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getActivityLog = (token: string, page = 1) => (
    fetch(`${TPAPIBase}/truckpay/activity_logs?page=${page}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getEmailBlasts = (token: string) => (
    fetch(`${TPAPIBase}/truckpay/email_blasts`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getEmailBlastGroups = (token: string) => (
    fetch(`${TPAPIBase}/truckpay/email_blasts/groups`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export interface SendEmailBlastPayload {
    subject: string;
    body: string;
    group: "companies" | "users";
}
export const sendEmailBlast = (token: string, data: SendEmailBlastPayload) => (
    fetch(`${TPAPIBase}/truckpay/email_blasts`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const getAllUsers = (token: string, query: string, page = 1) => (
    fetch(`${TPAPIBase}/truckpay/users?page=${page}&q=${query}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getAllCompanies = (token: string) => (
    fetch(`${TPAPIBase}/truckpay/clients`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const searchForCompany = (token: string, query: string): Promise<TPResponse<Paginate<Company[]>>> => (
    fetch(`${TPAPIBase}/truckpay/companies/search?name=${query}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then((res: TPResponse<Paginate<Company[]>>) => {
        if (res.data.data) {
            res.data.data = res.data.data.map(company => {
                company.formattedPhoneNumber = formatPhoneNumber(company.phone_number)
                return company
            })
        }
        return res
    })
)
export const AdminGetCompanyJobs = (token: string, companyID: number): Promise<TPResponse<Job[]>> => {
    return fetch(`${TPAPIBase}/truckpay/company/${companyID}/jobs`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then((res: TPResponse<Job[]>) => {
        if (res.data) {
            res.data = res.data.map(getJobMetadata)
        }
        return res
    })
}
export interface AdminGetJobDeliveriesOptions {
    [key: string]: any;
    utc_start_date?: number;
    utc_end_date?: number;
}
export const AdminGetJobDeliveries = (token: string, jobID: number, options: AdminGetJobDeliveriesOptions): Promise<TPResponse<DeliveryApi[]>> => {
    const formattedParams = formatParams(options)
    return fetch(`${TPAPIBase}/truckpay/job/${jobID}/deliveries?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then((res: TPResponse<DeliveryApi[]>) => {
        if (res.data) {
            res.data = res.data.map(functions.getDeliveryMetadata)
        }
        return res
    })
}
export interface CreateTrialPayload {
    company_id: number;
    trial_period_days: number;
    are_payments_allowed: boolean;
}
export const createTrial = (token: string, data: CreateTrialPayload) => (
    fetch(`${TPAPIBase}/truckpay/createtrial`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export interface EditTrialPayload {
    trial_ends: string;
}
export const editTrial = (token: string, trialID: number, data: EditTrialPayload) => (
    fetch(`${TPAPIBase}/truckpay/trials/${trialID}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export interface EndTrialPayload {
    company_id: number;
}
export const endTrial = (token: string, data: EndTrialPayload) => (
    fetch(`${TPAPIBase}/truckpay/endtrial`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
interface RevenueByConstructionSiteOptions {
    [key: string]: any;
    page?: number;
}
export interface SiteRevenue extends Site {
    broker_fees: number;
    formattedBrokerFees: string;
    scan_fees: number;
    formattedScanFees: string;
    total: number;
    formattedTotal: string;
}
export const revenueByConstructionSite = (token: string, options: RevenueByConstructionSiteOptions = {}): Promise<TPResponse<Paginate<SiteRevenue[]>>> => {
    const formattedParams = formatParams(options)
    return fetch(`${TPAPIBase}/truckpay/internal/reports/sites?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then((res: TPResponse<Paginate<SiteRevenue[]>>) => {
        if (res.data?.data) {
            res.data.data = res.data.data.map(siteRevenue => {
                siteRevenue.formattedBrokerFees = functions.formatNumber(siteRevenue.broker_fees / 100)
                siteRevenue.formattedScanFees = functions.formatNumber(siteRevenue.scan_fees / 100)
                siteRevenue.formattedTotal = functions.formatNumber(siteRevenue.total / 100)
                return siteRevenue
            })
        }
        return res
    })
}
export interface AdminGetRestrictionWhitelistOptions {
    [key: string]: any;
    page?: number;
}
export const AdminGetRestrictionWhitelist = (token: string, options: AdminGetRestrictionWhitelistOptions = {}): Promise<TPResponse<Paginate<RestrictionWhitelistEntry[]>>> => {
    const formattedParams = formatParams(options)
    return fetch(`${TPAPIBase}/truckpay/restriction-whitelist?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}

export const AdminAddRestrictionWhitelistEntry = (token: string, companyID: number): Promise<TPResponse<RestrictionWhitelistEntry>> => (
    fetch(`${TPAPIBase}/truckpay/restriction-whitelist/company/${companyID}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const AdminGetRestrictedAsOfDateForCompany = (token: string, companyID: number): Promise<TPResponse<string>> => (
    fetch(`${TPAPIBase}/truckpay/company/${companyID}/restricted-as-of-date`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export interface AdminSetRestrictedAsOfDateForCompanyPayload {
    truckpay_restricted_as_of_date: string;
}
export const AdminSetRestrictedAsOfDateForCompany = (token: string, companyID: number, data: AdminSetRestrictedAsOfDateForCompanyPayload): Promise<TPResponse<string>> => (
    fetch(`${TPAPIBase}/truckpay/company/${companyID}/restricted-as-of-date`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({ data })
    }).then(response => response.json())
)
export interface AdminCreateMaterialCategoryPayload {
    key: string;
    name: string;
    description: string;
    data_type: MaterialCategoryDataTypes;
    allowed_units?: string[];
    canonical_units?: string;
    units_type?: MaterialCategoryUnitTypes;
    range_min?: number;
    range_max?: number;
}
export const AdminCreateMaterialCategory = (token: string, data: AdminSetRestrictedAsOfDateForCompanyPayload): Promise<TPResponse<string>> => (
    fetch(`${TPAPIBase}/truckpay/material-categories`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({ data })
    }).then(response => response.json())
)
export const AdminUpdateMaterialCategory = (token: string, categoryKey: string, data: Omit<AdminSetRestrictedAsOfDateForCompanyPayload, "key" | "canonical_units" | "units_type" | "data_type">): Promise<TPResponse<string>> => (
    fetch(`${TPAPIBase}/truckpay/material-categories/${categoryKey}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({ data })
    }).then(response => response.json())
)
export const AdminDeleteMaterialCategory = (token: string, categoryKey: string): Promise<TPResponse<string>> => (
    fetch(`${TPAPIBase}/truckpay/material-categories/${categoryKey}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const AdminRestoreMaterialCategory = (token: string, categoryKey: string): Promise<TPResponse<string>> => (
    fetch(`${TPAPIBase}/truckpay/material-categories/${categoryKey}/restore`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)