import * as UserAPI from '../util/userAPI';
import { getPrivateNotificationsCount, getSpecificCompany } from '../util/companyAPI';
import { functions } from '../reducers/helpersReducer';
import i18n from '../../i18n';

export const LOGIN_CURRENT_USER = "LOGIN_CURRENT_USER";
export const LOGOUT_CURRENT_USER = "LOGOUT_CURRENT_USER";
export const SELECT_COMPANY = "SELECT_COMPANY";
export const RECEIVE_SESSION_ERRORS = "RECEIVE_SESSION_ERRORS";
export const CLEAR_SESSION_ERRORS = "CLEAR_SESSION_ERRORS";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
export const UPDATE_NOTIFICATION_COUNT = "UPDATE_NOTIFICATION_COUNT";
export const UPDATE_DUTY_STATUS = "UPDATE_DUTY_STATUS";
export const UPDATE_INSPECTOR_DETAILS = "UPDATE_INSPECTOR_DETAILS";

export const startLoading = () => ({
    type: START_LOADING
})

export const stopLoading = () => ({
    type: STOP_LOADING
})

export const loginCurrentUser = (user) => ({
    type: LOGIN_CURRENT_USER,
    user: user
})

export const logoutCurrentUser = () => ({
    type: LOGOUT_CURRENT_USER
})

export const selectCompany = (company) => ({
    type: SELECT_COMPANY,
    company
})

export const receiveSessionErrors = (errors) => ({
    type: RECEIVE_SESSION_ERRORS,
    errors
})

export const clearSessionErrors = () => ({
    type: CLEAR_SESSION_ERRORS
})

export const updateUser = (user) => ({
    type: UPDATE_USER,
    user
})

export const receiveUserSettings = (settings) => ({
    type: UPDATE_USER_SETTINGS,
    settings
})

export const updateNotificationCount = (notificationCount) => ({
    type: UPDATE_NOTIFICATION_COUNT,
    notificationCount
})

export const updateDutyStatus = (isOnDuty) => ({
    type: UPDATE_DUTY_STATUS,
    isOnDuty
})

export const updateInspectorDetails = (payload) => ({
    type: UPDATE_INSPECTOR_DETAILS,
    payload
})

export const popAlert = (alertType, alertTitle, alertMessage) => ({
    type: SHOW_ALERT,
    alertType,
    alertTitle,
    alertMessage,
})

export const unpopAlert = () => ({
    type: HIDE_ALERT,
})

export const showAlert = (type, title, message) => dispatch => {
    dispatch(popAlert(type, title, message));
}

export const hideAlert = () => dispatch => dispatch(unpopAlert())


export const signup = (user) => dispatch => (
    UserAPI.createUser(user).then(
        (newUser) => {
            if (newUser.success) {
                dispatch(loginCurrentUser(newUser.data));
            }
            return newUser;
        },
        (errors) => {
            dispatch(receiveSessionErrors(errors.responseJSON))
        }
    )
)

export const login = (user) => dispatch => {
    dispatch(startLoading());
    return UserAPI.authenticateUser(user).then(
        (user) => {
            if (user.success) {
                dispatch(loginCurrentUser(user.data));
            }
            return user;
        },
        (errors) => dispatch(receiveSessionErrors(errors.responseJSON))
    )
}
export const getNotificationsCount = (token, companyID) => dispatch => (
    getPrivateNotificationsCount(token, companyID).then(
        (res) => {
            if (res.success) {
                dispatch(updateNotificationCount(res.data));
            }
            return res;
        },
        (errors) => dispatch(receiveSessionErrors(errors.responseJSON))
    )
)
export const refreshCompany = (token, companyID) => dispatch => (
    getSpecificCompany(token, companyID).then(
        (res) => {
            if (res.data) {
                dispatch(selectCompany(res.data));
            }
            return res;
        },
        (errors) => dispatch(receiveSessionErrors(errors.responseJSON))
    )
)
export const refreshUser = (token, userID) => dispatch => (
    UserAPI.getSpecificUser(token, userID).then(
        (res) => {
            if (res.data) {
                dispatch(updateUser(res.data));
            }
            return res;
        },
        (errors) => dispatch(receiveSessionErrors(errors.responseJSON))
    )
)
export const getDutyStatus = (token) => dispatch => (
    UserAPI.getDutyStatus(token).then(
        (res) => {
            if (res.success) {
                dispatch(updateDutyStatus(res.data));
            } else {
                dispatch(popAlert('error', i18n.t(`error`), functions.parseError(res.error)))
            }
            return res;
        },
        (errors) => dispatch(receiveSessionErrors(errors.responseJSON))
    )
)
export const setDutyStatus = (token, isOnDuty) => dispatch => (
    UserAPI.setDutyStatus(token, isOnDuty).then(
        (res) => {
            if (res.success) {
                dispatch(updateDutyStatus(res.data));
                dispatch(popAlert('success', i18n.t(`success`), i18n.t(`userTranslations.success.dutyStatus`)))
            } else {
                dispatch(popAlert('error', i18n.t(`error`), functions.parseError(res.error)))
            }
            return res;
        },
        (errors) => dispatch(receiveSessionErrors(errors.responseJSON))
    )
)

export const logout = () => dispatch => dispatch(logoutCurrentUser());

