import React from "react";
import { connect } from 'react-redux';
import styled, { withTheme } from "styled-components";
import { popAlert } from '../../../../redux/actions/sessionActions';
import { withTranslation, useTranslation } from 'react-i18next';
import {
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    Card as MuiCard,
    CardMedia as MuiCardMedia,
    Grid as MuiGrid,
    Divider as MuiDivider,
    Typography as MuiTypography,
    Button as MuiButton,
    Link,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Tooltip as MuiTooltip,
    IconButton as MuiIconButton,
    Box as MuiBox,
    Avatar as MuiAvatar,
    Menu,
    MenuItem,
} from "@material-ui/core";
import { Add as AddIcon, Delete as DeleteIcon, MoreVert } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import SkeletonCards from '../../../components/SkeletonCards';
import AssignModal from '../../Jobs/modals/ModalAssignDriver';
import { TPLoader } from '../../../components/Progress';
import { 
    getAdditionalJobDocuments,
    getAdditionalDeliveryDocuments,
} from '../../../../redux/util/additionalJobDocsAPI';
import { inspectorGetAdditionalDeliveryDocuments, inspectorGetAdditionalJobDocuments } from "../../../../redux/util/inspectorAPI";
import { getFormattedDateFromUnix, getFormattedTimeFromUnix } from "../../../../redux/reducers/helpersReducerr";

const styles = {
    darkCard: {
        backgroundColor: "#F0F0F0",
    },
    acceptButton: {
        color: '#ffffff !important',
        backgroundColor: '#27c24c',
        borderColor: '#27c24c'
    },
    declineButton: {
        color: '#ffffff !important',
        backgroundColor: '#f05050',
        borderColor: '#f05050'
    }
}
const useStyles = makeStyles(() => styles);

const Card = styled(MuiCard)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Tooltip = styled(MuiTooltip)(spacing);
const IconButton = styled(MuiIconButton)(spacing);
const Box = styled(MuiBox)(spacing);
const Avatar = styled(MuiAvatar)(spacing);
const Orange = styled.span`
  color: ${props => props.theme.palette.primary.main};
`;

/*
    props: {
        job: Job,
        delivery: Delivery,
        inspectorAssignment: JobInspectorAssignment,
    }

*/

class AdditionalJobDeliveryDocsCard extends React.Component {
    state = {
        docs: {},

        page: 0,
        rowsPerPage: 25,
        count: 0,

        menuAnchor: null
    }
    setStateIfMounted = (state, callback) => {
        if (this.mounted) {
            this.setState(state, callback)
        }
    }
    
    componentDidMount = () => {
        this.mounted = true;
        this.getAdditionalJobDocuments();
    }
    componentDidUpdate = (prevProps, prevState) => {
       if (prevState.page != this.state.page) {
           this.getAdditionalJobDocuments();
       }
    }
    componentWillUnmount = () => {
        this.mounted = false
    }
    handleBadResponse = () => {
        this.setStateIfMounted({
            count: 0,
            page: 0,
            docs: { 0: [] }
        })
    }
    getAdditionalJobDocuments = () => {
        if (!this.state.docs[this.state.page]) {
            if (this.props.job) {
                if (this.props.inspectorAssignment) {
                    this.getAdditionalJobDocumentsForInspector()
                } else {
                    this.getJobDocuments()
                }
            } else if (this.props.delivery) {
                if (this.props.inspectorAssignment) {
                    this.getAdditionalDeliveryDocumentsForInspector()
                } else {
                    this.getDeliveryDocuments()
                }
            }
        }
    }
    getJobDocuments = () => {
        getAdditionalJobDocuments(this.props.token, this.props.company.id, this.props.job.id, this.state.page + 1).then(res => {
            console.log("additional job docs", res)
            if (res.error) {
                this.handleBadResponse()
                return;
            }
            if (res.success) {
                this.setStateIfMounted({
                    docs: {
                        ...this.state.docs,
                        [this.state.page]: res.data.data.map(doc => {
                            const date = getFormattedDateFromUnix(doc.created_at);
                            const time = getFormattedTimeFromUnix(doc.created_at);
                            doc.formattedDate = date;
                            doc.formattedTime = time;
                            return doc;
                        }),
                    },
                    count: res.data.total
                })
            }
        })
    }
    getDeliveryDocuments = () => {
        getAdditionalDeliveryDocuments(this.props.token, this.props.company.id, this.props.delivery.id, this.state.page + 1).then(res => {
            console.log("additional delivery docs", res)
            if (res.error) {
                this.handleBadResponse()
                return;
            }
            if (res.success) {
                this.setStateIfMounted({
                    docs: {
                        ...this.state.docs,
                        [this.state.page]: res.data.data.map(doc => {
                            const date = getFormattedDateFromUnix(doc.created_at);
                            const time = getFormattedTimeFromUnix(doc.created_at);
                            doc.formattedDate = date;
                            doc.formattedTime = time;
                            return doc;
                        }),
                    },
                    count: res.data.total
                })
            }
        })
    }
    getAdditionalJobDocumentsForInspector = async () => {
        if (!this.props.inspectorAssignment.token) return;
        const { token, job_id } = this.props.inspectorAssignment;
        const response = await inspectorGetAdditionalJobDocuments(token, job_id, { page: this.state.page + 1 })
        if (!response.success) return;
        this.setStateIfMounted({
            docs: {
                ...this.state.docs,
                [this.state.page]: response.data.data.map(doc => {
                    const date = this.props.helpers.getFormattedDateFromUnix(doc.created_at);
                    const time = this.props.helpers.getFormattedTimeFromUnix(doc.created_at);
                    doc.formattedDate = date;
                    doc.formattedTime = time;
                    return doc;
                }),
            },
            count: response.data.total
        })
    }
    getAdditionalDeliveryDocumentsForInspector = async () => {
        if (!this.props.inspectorAssignment.token || !this.props.delivery.id) return;
        const { token, job_id } = this.props.inspectorAssignment;
        const response = await inspectorGetAdditionalDeliveryDocuments(token, job_id, this.props.delivery.id, { page: this.state.page + 1 })
        if (!response.success) return;
        this.setStateIfMounted({
            docs: {
                ...this.state.docs,
                [this.state.page]: response.data.data.map(doc => {
                    const date = this.props.helpers.getFormattedDateFromUnix(doc.created_at);
                    const time = this.props.helpers.getFormattedTimeFromUnix(doc.created_at);
                    doc.formattedDate = date;
                    doc.formattedTime = time;
                    return doc;
                }),
            },
            count: response.data.total
        })
    }

    handleChangePage = (event, page) => this.setStateIfMounted({ page })

    render = () => {
        return (
            <Card mb={6} raised variant="outlined">
                {Boolean(this.props.job) && <CardHeader title={"Additional Job Docs"} />}
                {Boolean(this.props.delivery) && <CardHeader title={"Additional Delivery Docs"} />}
                <CardContent>
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="enhanced table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">{this.props.t('timeTranslations.date')}</TableCell>
                                    <TableCell align="center">{this.props.t('jobDocTranslations.name')}</TableCell>
                                    <TableCell align="center">{this.props.t('jobDocTranslations.description')}</TableCell>
                                    <TableCell align="center">{this.props.t('jobDocTranslations.trigger')}</TableCell>
                                    <TableCell align="center">{this.props.t('jobDocTranslations.links')}</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.docs[this.state.page] ?
                                    this.state.docs[this.state.page].map((doc, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={`${doc.id}-${index}`}
                                            >
                                                <TableCell align="center">
                                                    <Typography>
                                                        {doc.formattedDate}
                                                    </Typography>
                                                    <Typography>
                                                        {doc.formattedTime}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography noWrap>{doc.template.name}</Typography>
                                                </TableCell>

                                                <TableCell align="center">
                                                    <Typography noWrap>{doc.template.description || this.props.t('jobDocTranslations.none')}</Typography>
                                                </TableCell>

                                                <TableCell align="center">
                                                    <Typography noWrap>{this.props.helpers.capitalize(doc.generator.trigger)}</Typography>
                                                </TableCell>

                                                <TableCell align="center">
                                                    <Typography noWrap><Link href={doc.spreadsheet_url} target="_blank">{this.props.t('jobDocTranslations.viewSpreadsheet')}</Link></Typography>
                                                    {Boolean(doc.pdf_url) && <Typography noWrap><Link href={doc.pdf_url} target="_blank">{this.props.t('jobDocTranslations.viewPDF')}</Link></Typography>}
                                                </TableCell>
                                                <TableCell align="right" />
                                            </TableRow>
                                        );
                                    })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={7}>
                                            <TPLoader noBottomMargin />
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {this.state.count > 25 && <TablePagination
                        rowsPerPageOptions={[25]}
                        component="div"
                        count={this.state.count}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                    // onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />}
                    {/* <Menu
                        id="simple-menu"
                        anchorEl={this.state.menuAnchor}
                        keepMounted
                        open={Boolean(this.state.menuAnchor)}
                        onClose={this.handleMenuClose}
                    >
                        <MenuItem onClick={() => this.setTestModal(true)}>Test Template</MenuItem>
                        <MenuItem onClick={() => this.setCreateGeneratorModal(true)}>New Generator</MenuItem>
                        <MenuItem onClick={() => this.deleteSelectedTemplate()}>Delete</MenuItem>
                    </Menu> */}
                </CardContent>

            </Card>
        )
    }
}

const msp = state => ({
    token: state.session.user.token,
    company: state.session.company,
    helpers: state.helpers
})
const mdp = dispatch => ({

})
export default withStyles(() => styles)(connect(msp, mdp)(withTranslation()(AdditionalJobDeliveryDocsCard)));