import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { 
    Typography 
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton'
import { useTranslation } from 'react-i18next';
import { DeliveryApi } from '../../../../redux/reducers/entities';
import { functions } from '../../../../redux/reducers/helpersReducerr';

interface Props {
    delivery: DeliveryApi;
    currentLocation?: {
        latitude?: number;
        longitude?: number;
    };
    destination?: {
        latitude?: number;
        longitude?: number;
    };
}

function DeliveryETA({ delivery, currentLocation, destination }: Props) {
    const [eta, setETA] = useState("")
    const { t } = useTranslation();

    const getETA = () => {
        const etaOptions: any = {
            // origin: { ...currentLocation },
            // destination: { ...destination }
        }
        if (delivery) {
            etaOptions.origin = {
                latitude: delivery.route.address_from.latitude!,
                longitude: delivery.route.address_from.longitude!,
            }
            etaOptions.destination = {
                latitude: delivery.route.address_to.latitude!,
                longitude: delivery.route.address_to.longitude!,
            }
        }
        functions.getDriverETA(etaOptions).then(({status, results}) => {
            if (status == 'OK') {
                const { distance, duration, status } = results.rows[0].elements[0]
                setETA(duration.text)
            } else {
                setETA('')
            }
        })
    }
    useEffect(() => {
        getETA()
        const interval = setInterval(() => getETA(), 60_000)
        return () => clearInterval(interval)
    }, [])
    if (!eta) {
        return <Skeleton animation="wave" height={25} />
    } else {
        return <Typography align="center">ETA - {eta}</Typography>
    }
}

export default DeliveryETA