import { Button as MuiButton, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Switch, TextField, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getAvailableTagsForCustomMaterials, upsertCustomMaterial } from "../../../../../redux/actions/materialActions";
import { popAlert } from "../../../../../redux/actions/sessionActions";
import { CustomMaterial, MaterialCategorization, MaterialCategorizationValue, MaterialCategory, MaterialCategoryMap } from "../../../../../redux/reducers/entities";
import { functions, parseError } from "../../../../../redux/reducers/helpersReducerr";
import { addCategorizationsToCustomMaterial, addCustomMaterial, AddCustomMaterialPayload, addTagToCustomMaterial, getAllAvailableCustomMaterialCategories, getAllCategorizationsForCustomMaterial, getTagsForCustomMaterial, updateCustomMaterial } from "../../../../../redux/util/materialAPI";
import EditMaterialTagsModal from "../ModalEditCustomMaterialTags";
import EditMaterialCategorizationsModal from "../ModalEditMaterialCategorizations";

const Button = styled(MuiButton)(spacing);
interface Props {
    listName?: string;
    customMaterial?: CustomMaterial;
    setModalOpen: (bool: boolean) => void;
    setLoading: (bool: boolean) => void;
    enableListNameChange: boolean;
    refreshAllMaterials?: () => void;
}

const AddCustomMaterialManual = ({
    listName,
    setModalOpen,
    setLoading,
    enableListNameChange,
    refreshAllMaterials,
    customMaterial: currentMaterial,
}: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const token = useSelector((state: any) => state.session.user.token)
    const companyID = useSelector((state: any) => state.session.company.id)
    const materialTags: string[] = useSelector((state: any) => state.entities.materialTags)
    const [categories, setCategories] = useState<MaterialCategory[]>([])
    const [categoryMap, setCategoryMap] = useState<MaterialCategoryMap>({})

    useEffect(() => {
        Promise.allSettled([
            dispatch(getAvailableTagsForCustomMaterials(token)),
            getCategories(),
            getTags(),
            getCategorizations(),
        ])
    }, [])


    const getCategories = async () => {
        const response = await getAllAvailableCustomMaterialCategories(token)
        if (!response.success) return;
        const map: MaterialCategoryMap = {}
        response.data.forEach(category => {
            map[category.key] = category
        })
        setCategoryMap(map)
        setCategories(response.data)
    }
    const [categorizationValues, setCategorizationValues] = useState<MaterialCategorizationValue[]>([])

    const [customMaterial, setCustomMaterial] = useState<AddCustomMaterialPayload>({
        list_name: currentMaterial?.list_name || listName || "",
        material_number: currentMaterial?.material_number || "",
        name: currentMaterial?.name || "",
        description: currentMaterial?.description || "",
        is_inspectable: currentMaterial?.is_inspectable ?? false,
        is_hazardous: currentMaterial?.is_hazardous ?? false,
        handling_and_additional_info: currentMaterial?.handling_and_additional_info || "",
    })
   
    const [selectedTags, setSelectedTags] = useState<string[]>([])

    const [openEditTagModal, setOpenEditTagModal] = useState(false)
    const [openEditCategorizationsModal, setEditCategorizationsModal] = useState(false)

    useEffect(() => {
        if (customMaterial.is_hazardous) {
            setCustomMaterial((mat) => ({
                ...mat, 
                is_inspectable: mat.is_hazardous
            }))
        }
    }, [customMaterial.is_hazardous])

    const addMaterial = async () => {
        setLoading(true)
        const tempMaterial = {
            ...customMaterial,
            handling_and_additional_info: customMaterial.handling_and_additional_info || null,
        }
        const postData: AddCustomMaterialPayload[] = [tempMaterial]
        const res = await addCustomMaterial(token, companyID, postData)
        if (res.success) {
            refreshAllMaterials?.()
            const tagError = await addTags(res.data[0])
            const categorizationError = await addCategorizations(res.data[0])
            if (tagError || categorizationError) {
                dispatch(popAlert('success', t('success'), t('materialTranslations.success.createCustom')))
            }
            setModalOpen(false)
        } else {
            setLoading(false)
            dispatch(popAlert('error', t('error'), functions.parseError(res)))
        }
    }

    const getTags = () => {
        if (currentMaterial?.id) {
            return getTagsForCustomMaterial(token, companyID, currentMaterial.id).then(res => {
                if (res?.success) {
                    setSelectedTags(res.data.map(tag => tag.name))
                }
            })
        }
    }
    const getCategorizations = async () => {
        if (currentMaterial?.id) {
            const response = await getAllCategorizationsForCustomMaterial(token, companyID, currentMaterial!.id)
            if (!response.success) return;
            setCategorizationValues(response.data)
        }
    }

    const addTags = async (material: CustomMaterial) => {
        for (const tag of selectedTags) {
            const res = await addTagToCustomMaterial(token, companyID, material.id, tag)
            if (!res.success) {
                dispatch(popAlert('error', t('error'), functions.parseError(res)))
                return true
            }
        }
        return false
    }
    const addCategorizations = async (material: CustomMaterial) => {
        const response = await addCategorizationsToCustomMaterial(token, companyID, material.id, categorizationValues)
        if (!response.success) {
            const errorMsg = parseError(response)
            dispatch(popAlert('error', t('error'), errorMsg))
            return true
        }
        return false
    }

    const editMaterial = async () => {
        if (!currentMaterial) return
        setLoading(true)

        const tempMaterial = {
            ...customMaterial,
            handling_and_additional_info: customMaterial.handling_and_additional_info || null,
        }

        try {
            const updatedMaterial = await updateCustomMaterial({
                token,
                companyId: companyID,
                customMaterialId: currentMaterial.id,
                payload: tempMaterial
            })

            // Note: tags/categorizations get updated in real time within their respective edit modals

            dispatch(upsertCustomMaterial(updatedMaterial))
            dispatch(popAlert('success', t('success'), t('materialTranslations.success.createCustom')))
            setModalOpen(false)
        } catch (error: any) {
            dispatch(popAlert("error", t('error'),error.message || t('unknownError')))
            setLoading(false)
        }
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()

        if (currentMaterial) {
            editMaterial()
            return;
        }
        
        addMaterial()
    }

    return <>
        <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={10}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            id="list_name"
                            label={t('materialTranslations.listName')}
                            value={customMaterial.list_name}
                            onChange={e => setCustomMaterial(curVal => ({ ...curVal, list_name: e.target.value }))}
                            type="text"
                            required
                            disabled={!enableListNameChange}
                            error={!customMaterial.list_name}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                        <TextField
                            id="material_number"
                            label={t('materialTranslations.materialNumber')}
                            value={customMaterial.material_number}
                            onChange={e => setCustomMaterial(curVal => ({ ...curVal, material_number: e.target.value }))}
                            required
                            error={!customMaterial.material_number}
                            type="text"
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                    <FormControl fullWidth>
                        <TextField
                            id="name"
                            label={t('materialTranslations.materialName')}
                            value={customMaterial.name}
                            onChange={e => setCustomMaterial(curVal => ({ ...curVal, name: e.target.value }))}
                            type="text"
                            required
                            error={!customMaterial.name}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <TextField
                            id="description"
                            label={t('materialTranslations.materialDescription')}
                            value={customMaterial.description}
                            onChange={e => setCustomMaterial(curVal => ({ ...curVal, description: e.target.value }))}
                            required
                            multiline
                            rows="4"
                            type="text"
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <TextField
                            id="handling_and_additional_info"
                            label={t('materialTranslations.handlingAndAdditionalInfo')}
                            value={customMaterial.handling_and_additional_info}
                            onChange={e => setCustomMaterial(curVal => ({ ...curVal, handling_and_additional_info: e.target.value }))}
                            multiline
                            rows="4"
                            type="text"
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={12} md={6}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{t('materialTranslations.isHazardousMaterial')}</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={customMaterial.is_hazardous}
                                        onChange={e => setCustomMaterial(curVal => ({ ...curVal, is_hazardous: e.target.checked }))}
                                        color="primary"
                                    />
                                }
                                label=""
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{t('materialTranslations.isInspectableMaterial')}</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={customMaterial.is_inspectable}
                                        onChange={e => setCustomMaterial(curVal => ({ ...curVal, is_inspectable: e.target.checked }))}
                                        color="primary"
                                        disabled={customMaterial.is_hazardous}
                                    />
                                }
                                label=""
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={12} md={6}>
                    <Typography color="textSecondary">Tags:</Typography>
                    <Typography>
                        {selectedTags.join(", ")}
                    </Typography>
                    <Button variant="outlined" color="primary" onClick={() => setOpenEditTagModal(true)} mt={1}
                        type="button"
                    >
                        Update Tags
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography color="textSecondary">Categorizations:</Typography>
                    {categorizationValues.map(catVal => <React.Fragment key={catVal.category_key}>
                        <Typography>{categoryMap[catVal.category_key]?.name} - {catVal.value.toString()} {catVal.units && t(`unitTranslations.${catVal.units}`)}</Typography>
                    </React.Fragment>)}
                    <Button variant="outlined" color="primary" onClick={() => setEditCategorizationsModal(true)} mt={1}
                        type="button"
                    >
                        Update Categorizations
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={12}>
                    <Button color="primary" variant="contained" type="submit" fullWidth>{t('actions.save')}</Button>
                </Grid>
            </Grid>
        </form>
        {openEditTagModal && <EditMaterialTagsModal 
            setModalOpen={setOpenEditTagModal}
            tags={selectedTags}
            setTags={setSelectedTags}
            customMaterialID={currentMaterial?.id}
        />}
        {openEditCategorizationsModal && <EditMaterialCategorizationsModal
            setModalOpen={setEditCategorizationsModal}
            categories={categories}
            categorizations={[]}
            setCategorizations={(values) => setCategorizationValues(values)}
            customMaterial={currentMaterial}
        />}
    </>
}
export default AddCustomMaterialManual
