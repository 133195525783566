import { TPAPIBase } from '../../env';
import i18n from '../../i18n';

export const getAdditionalJobDocuments = (token, companyID, jobID, page = 1) => (
    fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/documents?page=${page}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getAdditionalDeliveryDocuments = (token, companyID, deliveryID, page = 1) => (
    fetch(`${TPAPIBase}/company/${companyID}/delivery/${deliveryID}/documents?page=${page}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)

///// END GENERAL

///// START TEMPLATE HANDLING

export const getAvailableFunctions = (token) => (
    fetch(`${TPAPIBase}/document-templates/functions`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getAvailableFunctionsByDataType = (token) => (
    fetch(`${TPAPIBase}/document-templates/functions/data-type-options`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getAvailableFieldsForFunction = (token, func) => (
    fetch(`${TPAPIBase}/document-templates/functions/${func}/fields`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const createDocumentTemplate = (token, companyID, formData) => (
    fetch(`${TPAPIBase}/company/${companyID}/document-templates`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: formData
    }).then(response => response.json())
)
export const testDocumentTemplate = (token, companyID, templateID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/document-templates/${templateID}/test`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const getDocumentTemplates = (token, companyID, page) => (
    fetch(`${TPAPIBase}/company/${companyID}/document-templates?page=${page}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const deleteDocumentTemplate = (token, companyID, templateID) => (
    fetch(`${TPAPIBase}/company/${companyID}/document-templates/${templateID}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
    }).then(response => response.json())
)
export const restoreDocumentTemplate = (token, companyID, templateID) => (
    fetch(`${TPAPIBase}/company/${companyID}/document-templates/${templateID}/restore`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
    }).then(response => response.json())
)

///// END TEMPLATE HANDLING

///// START GENERATOR HANDLING

export const createGeneratorForJob = (token, companyID, jobID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/document-generators`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const editGeneratorForJob = (token, companyID, genID, data) => (
    fetch(`${TPAPIBase}/company/${companyID}/document-generators/${genID}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const getGeneratorForJob = (token, companyID, jobID) => (
    fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/document-generators`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getGenerators = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/document-generators`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const deleteGenerator = (token, companyID, generatorID) => (
    fetch(`${TPAPIBase}/company/${companyID}/document-generators/${generatorID}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)


///// START GENERATOR HANDLING

///// START SAMPLE HANDLING


export const getSampleTemplates = (token, page) => (
    fetch(`${TPAPIBase}/document-templates/samples?page=${page}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)

export const createSampleTemplate = (token, formData) => (
    fetch(`${TPAPIBase}/truckpay/document-templates/samples`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: formData
    }).then(response => response.json())
)

export const updateSampleTemplate = (token, sampleTemplateID, formData) => (
    fetch(`${TPAPIBase}/truckpay/document-templates/samples/${sampleTemplateID}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: formData
    }).then(response => response.json())
)

export const deleteSampleTemplate = (token, sampleTemplateID) => (
    fetch(`${TPAPIBase}/truckpay/document-templates/samples/${sampleTemplateID}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
    
// export const getAvailableFunctions = (token) => (
//     fetch(`${TPAPIBase}/document-templates/functions`, {
//         method: 'GET',
//         headers: {
//             Accept: 'application/json',
//             'Accept-Language': i18n.language,
//             'Content-Type': 'application/json',
//             'x-auth-token': token,
//         }
//     }).then(response => response.json())
// )
// export const changePassword = (data,) => (
//     fetch(`${TPAPIBase}/password/reset`, {
//         method: 'PUT',
//         headers: {
//             Accept: 'application/json',
//             'Accept-Language': i18n.language,
//             'Content-Type': 'application/json',
//             'x-auth-token': token,
//         },
//         body: JSON.stringify({
//             data: data
//         })
//     }).then(response => response.json())
// )
