import React, { ReactElement, ReactPropTypes, useEffect, useState } from 'react';
import {
    Modal,
    Tab,
    Tabs,
    Paper as MuiPaper,
    AppBar,
    Toolbar,
    Typography as MuiTypography,
    Grid as MuiGrid,
    FormGroup,
    FormControl as MuiFormControl,
    FormControlLabel as MuiFormControlLabel,
    FormLabel,
    FormHelperText,
    Input,
    InputLabel,
    Select,
    TextField,
    MenuItem,
    Button,
    Switch,
    Radio,
    RadioGroup,
    Divider,
    InputAdornment,
    Box,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Checkbox
} from '@material-ui/core';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext'
import { connect, useDispatch, useSelector } from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import { TPLoader } from '../../../components/Progress';
import { popAlert } from '../../../../redux/actions/sessionActions';
import { useTranslation, withTranslation } from 'react-i18next';
import { 
    getPreferredDriverCompaniesByEmail,
    getPreferredCustomerList,
    getPreferredSuppliersList,
    getPreferredDestinationList,
    addPreferredDriver,
    addPreferredDestination,
    addPreferredCustomer,
    addPreferredSupplier
} from '../../../../redux/util/companyAPI';
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import NotificationSnackbar from '../../Util/Snackbar';
// import { HelperFunctions } from '../../../../redux/reducers/helpersReducer';
import TPModal from '../../Util/TPModal';
import { 
    getAllValidAdditionalCompanyInformationKeys,
    getAdditionalCompanyInformation,
    setAdditionalCompanyInformation,

} from '../../../../redux/util/companyAPI';
import { CustomMaterialTag, Material } from '../../../../redux/reducers/entities';
import { addTagToCustomMaterial, getAvailableTagsForCustomMaterials, getTagsForCustomMaterial, removeTagFromCustomMaterial } from '../../../../redux/util/materialAPI';

const Paper = styled(MuiPaper)(spacing);
const Typography = styled(MuiTypography)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const FormControlLabel = styled(MuiFormControlLabel)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Bold = styled.span`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: black;
`;

interface OwnProps {
    setModalOpen: (bool: boolean) => void;
    tags: string[];
    setTags: (tags: string[]) => void;
    customMaterialID?: number;
}

const EditMaterialTagsModal: React.FC<OwnProps> = ({
    setModalOpen,
    tags, 
    setTags,
    customMaterialID
}): ReactElement => {
    const { t } = useTranslation()
    const token = useSelector((state: any) => state.session.user.token)
    const company = useSelector((state: any) => state.session.company)
    const helpers = useSelector((state: any) => state.helpers)
    const materialTags: string[] = useSelector((state: any) => state.entities.materialTags)
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)

    const onChange = (tagName: string) => async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (!customMaterialID) {
            checked ? setTags([...tags, tagName]) : setTags(tags.filter(tag => tag !== tagName))
        } else {
            setLoading(true)
            if (checked) {
                addTagToCustomMaterial(token, company.id, customMaterialID, tagName).then(res => {
                    if (res?.success) {
                        setTags([...tags, res.data.name])
                    } else {
                        dispatch(popAlert('error', t('error'), helpers.parseError(res)))
                    }
                    setLoading(false)
                })
            } else {
                removeTagFromCustomMaterial(token, company.id, customMaterialID, tagName).then(res => {
                    if (res?.success) {
                        setTags(tags.filter(tag => tag !== tagName))
                    } else {
                        dispatch(popAlert('error', t('error'), helpers.parseError(res)))
                    }
                    setLoading(false)
                })
            }
        }
    }

    return (
        <Dialog
            open={true}
            onClose={() => setModalOpen(false)}
            onBackdropClick={() => setModalOpen(false)}
        >
            <DialogContent>
                {loading ? <>
                    <Grid container alignItems="center" justify="center">
                        <Grid item>
                            <TPLoader />
                        </Grid>
                    </Grid>
                </> : <>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {materialTags.map(tag => (
                                    <TableRow key={tag}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={tags.includes(tag)}
                                                onChange={onChange(tag)}
                                                inputProps={{
                                                    'aria-label': `select ${tag}`,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>{tag}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={() => setModalOpen(false)}>
                    {t("actions.confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditMaterialTagsModal