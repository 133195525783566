import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRootSelector } from "../../../../..";
import { DeliveryApi } from "../../../../../redux/reducers/entities";

interface Props {
    label: string;
    url: string | null;
    isSignature?: boolean;
}
const DeliveryPictures = ({ label, url, isSignature }: Props) => {
    const theme = useRootSelector(state => state.theme)

    return <Card variant="outlined" raised>
        <CardMedia
            component="img"
            image={url ? url : "/static/img/site_photo.jpg"}
            title={label}
            style={{
                height: 220,
                objectFit: url ? "contain" : "cover",
                filter: (theme.currentTheme == 'high_contrast' && isSignature) ? 'invert(1)' : ''
            }}
        />
        <CardContent style={{backgroundColor: "rgba(0,0,0,0.1"}}>
            <Typography gutterBottom variant="subtitle2">
                {label}
            </Typography>
        </CardContent>
    </Card>
}

export default DeliveryPictures