import { AppBar, Button, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Toolbar, Typography } from '@material-ui/core';
import { Autocomplete, TabContext } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import { Delete } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, useRootSelector } from '../../../..';
import { getMaterials, getValidUnits } from '../../../../redux/actions/adminActions';
import { getAllCustomMaterials } from '../../../../redux/actions/materialActions';
import { popAlert } from '../../../../redux/actions/sessionActions';
import { getCompanySites } from '../../../../redux/actions/siteActions';
import { BaseMixMaterial, CustomMaterial, CustomMaterialMap, CustomMaterialMapByID, DeliveryApi, Job, Material, MixDesign, MixDesignConstituent, Site, UnitMap, VolumeUnits, WeightUnits } from '../../../../redux/reducers/entities';
import { convertVolume, convertWeight, formatNumber, parseError } from '../../../../redux/reducers/helpersReducerr';
import { getJob } from '../../../../redux/util/jobAPI';
import { createMaterialMixBatch, getAllCompanyMaterialMixDesigns, getCompanyMaterialMixBatchByID, getMaterialMixDesignByID, MaterialMixBatchConstituentPayload, MaterialMixBatchPayload, MaterialMixDesignConstituentPayload, setDeliveryMaterialMixBatch } from '../../../../redux/util/mixAPI';
import { getSpecificUser, updateCurrentUser } from '../../../../redux/util/userAPI';
import Separator from '../../Util/Separator';
import SignatureDialog from '../../Util/SignatureDialog';
import TabPanel from '../../Util/TabPanel';
import TPModal from '../../Util/TPModal';
import AddConstituentModal from './ModalAddConstituent';

enum TabValues {
    DESIGNS, 
    DETAILS,
    CONSTITUENTS,
    SUMMARY,
}
interface Props {
    refreshData?: () => void;
    onCreateMixBatch?: () => void;
    onSetMixBatchForDelivery?: () => void;
    setModalOpen: (bool: boolean) => void;

    // for create
    deliveryID?: number;
    delivery?: DeliveryApi;

}

interface RenderTargetProps {
    mixDesignConstituent: MixDesignConstituent,
    mixBatchConstituentPayload: MaterialMixBatchConstituentPayload;
    batch: MaterialMixBatchPayload,
    delivery?: DeliveryApi,
    fullJob?: Job,
    fullDesign?: MixDesign,
}
const RenderConstituentTarget = ({ mixDesignConstituent, mixBatchConstituentPayload, batch, delivery, fullJob, fullDesign }: RenderTargetProps) => {
    const { t } = useTranslation()
    /*
        min of what truck can handle and whats remaining (get from api)
        // job.estimated_remaining_weight

                        batch volume
        multiplier = --------------
                        design volume

        theoretical = constituent value * multiplier

        value to display = min(theoretical, truck capacity, remaining job quantity)
    */

    const constituentMultiplier = useMemo(() => {
        if (!batch.volume || !batch.volume_units || !fullDesign?.target_volume || !fullDesign?.volume_units) return 1;

        // Keep values in terms of the fullDesign.volume_units
        const targetVolume = fullDesign.target_volume
        const batchVolume = convertVolume(batch.volume, batch.volume_units as VolumeUnits, fullDesign.volume_units as VolumeUnits)

        return batchVolume / targetVolume
    }, [batch, fullDesign])

    const { target, units } = useMemo(() => {
        // account for no delivery aka no truck capacity
        // account for no job aka no remaining quantity
        
        const valuesToCompare: number[] = []

        let constituentValue;
        let truckCapacity;
        let remainingJobQuantity;
        let targetUnits;

        const job = fullJob || delivery?.job;

        // if (mixBatchConstituentPayload.amendment_custom_material_id) {
        //     return {
        //         target: "",
        //         units: "",
        //     };
        // }
        const constituent = mixDesignConstituent // || mixBatchConstituentPayload
        if (!constituent.target_volume) {
            constituent.target_volume = constituent.volume
        }
        if (!constituent.target_weight) {
            constituent.target_weight = constituent.weight
        }
        console.log(`start for ${mixDesignConstituent?.material_name || "amendment"}`, constituent)
        if (constituent.primary_measurement_type === "volume") {

            // Keep all values in terms of constituent.volume_units
            targetUnits = constituent.volume_units
            constituentValue = constituent.target_volume
            truckCapacity = Boolean(delivery?.vehicle.volume_capacity && constituent.volume_units) && convertVolume(delivery!.vehicle.volume_capacity, delivery!.vehicle.volume_capacity_units, constituent.volume_units as VolumeUnits)
            console.log('checking volume', constituentValue, truckCapacity, delivery)
            if (job && job.estimated_remaining_volume && job.estimated_volume_units && constituent.volume_units) {
                remainingJobQuantity = convertVolume(job.estimated_remaining_volume, job.estimated_volume_units, constituent.volume_units as VolumeUnits)
            }

        } else {
            // Keep all values in terms of constituent.weight_units
            targetUnits = constituent.weight_units
            constituentValue = constituent.target_weight
            truckCapacity = Boolean(delivery?.vehicle.weight_capacity && constituent.weight_units) && convertWeight(delivery!.vehicle.weight_capacity, delivery!.vehicle.weight_capacity_units, constituent.weight_units as WeightUnits)
            if (job && job.estimated_remaining_weight && job.estimated_amount_units && constituent.weight_units) {
                remainingJobQuantity = convertWeight(job.estimated_remaining_weight, job.estimated_amount_units, constituent.weight_units as WeightUnits)
            }
            console.log('checking weight', constituentValue, truckCapacity, remainingJobQuantity, delivery)
        }

        // The consituent values needs to be provided at the very least
        if (!constituentValue) return {
            target: "",
            units: "",
        };

        const theoreticalValue = constituentValue * constituentMultiplier
        valuesToCompare.push(theoreticalValue)

        if (truckCapacity) {
            valuesToCompare.push(truckCapacity)
        }
        if (remainingJobQuantity) {
            valuesToCompare.push(remainingJobQuantity)
        }

        console.log(`for ${mixDesignConstituent?.material_name}`, valuesToCompare, targetUnits)
        return {
            target: formatNumber(Math.min(...valuesToCompare), { forCapacities: true }),
            units: targetUnits
        }
    }, [mixDesignConstituent, constituentMultiplier, delivery, fullJob, mixBatchConstituentPayload.primary_measurement_type])
    if (!target || !units) return <></>
    return <Typography>{target} {t(`unitTranslations.${units}`)}</Typography>
}

const CreateEditMixBatchModal = ({
    setModalOpen,
    refreshData,
    deliveryID,
    delivery,
    onCreateMixBatch,
    onSetMixBatchForDelivery
}: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state.session.user)
    const token = useSelector((state: RootState) => state.session.user.token!)
    const companyID = useSelector((state: RootState) => state.session.company.id!)
    const units: UnitMap = useSelector((state: RootState) => state.entities.units)
    const customMaterialMap: CustomMaterialMapByID = useRootSelector(state => state.entities.customMaterialsByID)
    const materials: Material[] = useRootSelector(state => state.entities.materials)

    const [tabValue, setTabValue] = useState(TabValues.DESIGNS.toString())
    const [loading, setLoading] = useState(true)
    const [openAddAmmendmentModal, setAddAmmendmentModal] = useState(false)
    
    const [searchSites, setSearchSites] = useState("")
    const sites: Site[] = useRootSelector(state => state.entities.sites)

    const [searchDesigns, setSearchDesigns] = useState("")
    const [designs, setDesigns] = useState<MixDesign[]>([])
    const [selectedDesign, setSelectedDesign] = useState<MixDesign>()
    const [fullDesign, setFullDesign] = useState<MixDesign>()
    const [fullJob, setFullJob] = useState<Job>()

    const [batch, setBatch] = useState<MaterialMixBatchPayload>({
        material_mix_design_id: 0,
        delivery_id: delivery?.id || deliveryID || undefined,
        site_id: 0,
        batch_ticket_number: "",
        volume: 0,
        volume_units: "",
        slump: 0,
        slump_units: "",
        temperature: 0,
        temperature_units: "",
        mixing_revolutions: 0,
        air_content_percentage: 0,
        constituents: [],
        is_signature_applied: false
    })
    const [constituents, setConstituents] = useState<MaterialMixBatchConstituentPayload[]>([])
    const [useNewSignature, setUseNewSignature] = useState(false)
    const [openSignatureDialog, setOpenSignatureDialog] = useState(false)


    const checkForDesignId = () => {
        setDesigns(curDesigns => {
            const mixDesignId = delivery?.materials.find(mat => mat.material_mix_design_id)?.id
            console.log("DESIGN ID", mixDesignId);
            if (mixDesignId) {
                setBatch(curVal => ({
                    ...curVal,
                    material_mix_design_id: mixDesignId
                }))
                const design = curDesigns.find(design => design.id === mixDesignId)
                if (design) {
                    const designName = `${design.mix_id} - ${design.name}`
                    setSearchDesigns(designName)
                }
            }
            return curDesigns
        })
    }

    const getAllDesigns = async () => {
        const response = await getAllCompanyMaterialMixDesigns(token, companyID)
        if (!response.success) return;
        setDesigns(response.data)
        checkForDesignId()
    }

    const getSpecificJob = async () => {
        if (!delivery) return;
        const response = await getJob(token, companyID, delivery.job_id)
        if (!response.success) return;
        setFullJob(response.data)
    }

    useEffect(() => {
        Promise.all([
            getAllDesigns(),
            getSpecificJob(),
            dispatch(getCompanySites(token, companyID)),
            dispatch(getMaterials(token)),
            dispatch(getAllCustomMaterials(token, companyID)),
            dispatch(getValidUnits(token))
        ]).then(() => {
            setLoading(false)
        })
    }, [])

    const handleSelectDesign = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!selectedDesign) {
            dispatch(popAlert('error', t('error'), "You must select a mix design"))
            return;
        };
        setLoading(true)
        const fullDesignResponse = await getMaterialMixDesignByID(token, companyID, selectedDesign!.id!)
        if (!fullDesignResponse.success) {
            dispatch(popAlert('error', t('error'), "Unable to get the full design"))
            setLoading(false)
            return;
        }
        const fullDesign = fullDesignResponse.data
        setFullDesign(fullDesign)
        setBatch({
            ...batch,
            material_mix_design_id: fullDesign.id!,
            volume: fullDesign.target_volume || 0,
            volume_units: fullDesign.volume_units || "",
            slump: fullDesign.target_slump || 0,
            slump_units: fullDesign.slump_units || "",
            temperature: fullDesign.target_temperature || 0,
            temperature_units: fullDesign.temperature_units || "",
            mixing_revolutions: fullDesign.target_mixing_revolutions || 0,
            air_content_percentage: parseFloat(fullDesign.target_air_content_percentage) || 0,
        })
        const constit = fullDesign.constituents!.map(designConstituent => ({
            ...designConstituent,
            customMaterial: designConstituent.custom_material_id ? customMaterialMap[designConstituent.custom_material_id!] : undefined,
            material: designConstituent.material_id ? materials.find(mat => mat.id === designConstituent.material_id) : undefined,
            material_mix_design_constituent_id: designConstituent.id!,
            /*
                TODO
                GET TRUCK DETAILS AND COMPARE TO BATCH DETAILS?
            */ 
            // volume: designConstituent.target_volume,
            // weight: designConstituent.target_weight,
        }))
        console.log("constistuents", constit);
        
        setConstituents(constit)
        setTabValue(TabValues.DETAILS.toString())
        setLoading(false)
    }

    const addAmmendment = (baseMixMaterial: BaseMixMaterial) => {
        setAddAmmendmentModal(false)

        // Check if the constituent has already been added
        if (constituents.find(constituent => (
            constituent.amendment_custom_material_id && constituent.amendment_custom_material_id === baseMixMaterial.custom_material_id
        ))) {
            return;
        }
        const ammendment: MaterialMixBatchConstituentPayload = {
            ...baseMixMaterial,
            amendment_custom_material_id: baseMixMaterial.custom_material_id!,
            volume: baseMixMaterial.volume || 0,
            volume_units: baseMixMaterial.volume_units || null,
            weight: baseMixMaterial.weight || 0,
            weight_units: baseMixMaterial.weight_units || null
        }
        setConstituents([...constituents, ammendment])
        // setFullDesign({
        //     ...fullDesign!,
        //     constituents: [...fullDesign!.constituents!, { 
        //         ...baseMixMaterial,
        //         material_name: baseMixMaterial.material?.name || baseMixMaterial.customMaterial?.name || "",
        //         material_number: baseMixMaterial.customMaterial?.material_number || "",
        //         target_volume: baseMixMaterial.volume,
        //         volume_units: baseMixMaterial.volume_units || "",
        //         target_weight: baseMixMaterial.weight,
        //         weight_units: baseMixMaterial.weight_units || ""
        //     }]
        // })
    }

    const updateConstituentMeasurement = (index: number, key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newConstituents = [...constituents]
        newConstituents[index][key] = e.target.valueAsNumber
        setConstituents(newConstituents)
    }

    const deleteConstituent = (index: number) => {
        const newConstituents = [...constituents]
        newConstituents.splice(index, 1)
        setConstituents(newConstituents)
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        const cleanedPostData: MaterialMixBatchPayload = {
            ...batch
        }
        if (!(batch.slump && batch.slump_units)) {
            cleanedPostData.slump = undefined
            cleanedPostData.slump_units = undefined
        }
        if (!(batch.temperature && batch.temperature_units)) {
            cleanedPostData.temperature = undefined
            cleanedPostData.temperature_units = undefined
        }
        const cleanedConstituents = [...constituents].map(constituent => {
            const newConstituent = {...constituent}
            if (!(constituent.volume && constituent.volume_units)) {
                newConstituent.volume = undefined
                newConstituent.volume_units = undefined
            }
            if (!(constituent.weight && constituent.weight_units)) {
                newConstituent.weight = undefined
                newConstituent.weight_units = undefined
            }
            return newConstituent
        })
        const postData: MaterialMixBatchPayload = {
            ...cleanedPostData,
            constituents: cleanedConstituents
        }
        const createBatchresponse = await createMaterialMixBatch(token, companyID, postData)
        if (!createBatchresponse.success) {
            const errorMessage = parseError(createBatchresponse)
            dispatch(popAlert("error", t("error"), errorMessage))
            setLoading(false)
            return;
        }
        dispatch(popAlert("success", t("success"), "Created mix batch"))
        onCreateMixBatch?.()
        if (deliveryID) {
            const deliveryBatchResponse = await setDeliveryMaterialMixBatch(token, companyID, deliveryID, createBatchresponse.data.id!)
            if (!deliveryBatchResponse.success) {
                const errorMessage = parseError(createBatchresponse)
                dispatch(popAlert("error", t("error"), errorMessage))
            } else {
                onSetMixBatchForDelivery?.()
            }
        }
        refreshData?.()
        setModalOpen(false)
    }

    const updateSignatureFile = async (file: File) => {
        const formData = new FormData()
        formData.append("data[signature]", file)
        const userUpdateResponse = await updateCurrentUser(token, user.id!, formData)
        if (!userUpdateResponse) {
            dispatch(popAlert('error', t("error"), parseError(userUpdateResponse)))
            return;
        }
        dispatch(popAlert('success', t("Success"), "Updated signature"))
        getSpecificUser(token, user.id!)
    }

    return <TPModal
        setModalOpen={setModalOpen}
        loading={loading}
        headerOptions={{
            headerText: t('materialTranslations.mixBatch')
        }}
        bodyOptions={{
            noPadding: true
        }}
        xs={12} 
        sm={10} 
        md={9} 
        lg={8} 
    >
        <TabContext value={tabValue}>
            <AppBar position="static" color="default">
                <Toolbar>
                    <Tabs
                        variant="scrollable"
                        indicatorColor="primary"
                        scrollButtons="auto"
                        value={tabValue}
                        onChange={(_, val) => setTabValue(val)}
                    >
                        <Tab label={t("materialTranslations.mixDesign")} value={TabValues.DESIGNS.toString()} />
                        <Tab label={t("details")} value={TabValues.DETAILS.toString()} disabled={!fullDesign} />
                        <Tab label={t("materialTranslations.constituents")} value={TabValues.CONSTITUENTS.toString()} disabled={!fullDesign} />
                        <Tab label={t("summary")} value={TabValues.SUMMARY.toString()} disabled={!fullDesign || !constituents.length} />
                    </Tabs>
                </Toolbar>
            </AppBar>
            <TabPanel value={tabValue} index={TabValues.DESIGNS.toString()} boxProps={{ p: 3 }}>
                <form onSubmit={handleSelectDesign}>
                    <Grid container spacing={10}>
                        <Grid item xs>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="design_autocomplete"
                                    disableClearable
                                    autoComplete
                                    clearOnBlur={false}
                                    options={designs}
                                    getOptionLabel={(option) => `${option.mix_id} - ${option.name}`}
                                    onChange={(event, val) => setSelectedDesign(val) }
                                    onInputChange={(event, val, reason) => {
                                        if (reason != 'reset' || val) {
                                            setSearchDesigns(val)
                                        }
                                    }}
                                    inputValue={searchDesigns}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="searchDesigns"
                                            label={`Search Mix Designs`}
                                            type="text"
                                            required
                                            error={!batch.site_id}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10}>
                        <Grid item xs={12}>
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                fullWidth
                            >
                                {t("actions.next")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </TabPanel>
            <TabPanel value={tabValue} index={TabValues.DETAILS.toString()} boxProps={{ p: 3 }}>
                <form onSubmit={e => {
                    e.preventDefault()
                    setTabValue(TabValues.CONSTITUENTS.toString())
                }}>
                    {Boolean(fullJob) && <Grid container spacing={10}>
                        {Boolean(fullJob!.estimated_remaining_volume && fullJob!.estimated_volume_units) && <Grid item>
                            <Typography color="textSecondary">
                                Remaining Volume:
                            </Typography>
                            <Typography gutterBottom>
                                {fullJob!.estimated_remaining_volume!.toFixed(2)} {t(`unitTranslations.${fullJob!.estimated_volume_units}`)}
                            </Typography>
                        </Grid>}
                        {Boolean(fullJob!.estimated_remaining_weight && fullJob!.estimated_amount_units) && <Grid item>
                            <Typography color="textSecondary">
                                Remaining Weight:
                            </Typography>
                            <Typography gutterBottom>
                                {fullJob!.estimated_remaining_weight!.toFixed(2)} {t(`unitTranslations.${fullJob!.estimated_amount_units}`)}
                            </Typography>
                        </Grid>}
                        {Boolean(fullJob!.estimated_remaining_loads) && <Grid item>
                            <Typography color="textSecondary">
                                Remaining Loads:
                            </Typography>
                            <Typography gutterBottom>
                                {fullJob!.estimated_remaining_loads}
                            </Typography>
                        </Grid>}
                    </Grid>}
                    <Grid container spacing={10}>
                        <Grid item xs>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="site_autocomplete"
                                    disableClearable
                                    autoComplete
                                    clearOnBlur={false}
                                    options={sites}
                                    getOptionLabel={(option) => option.site_name}
                                    onChange={(event, val) => setBatch({ ...batch, site_id: val.id })}
                                    onInputChange={(event, val, reason) => {
                                        if (reason != 'reset' || val) {
                                            setSearchSites(val)
                                        }
                                    }}
                                    inputValue={searchSites}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            id="searchSites"
                                            label={`Search for Sites`}
                                            type="text"
                                            required
                                            error={!batch.site_id}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10}>
                        <Grid item xs>
                            <FormControl fullWidth>
                                <TextField
                                    id="batch_ticket_number"
                                    label={`Batch Ticket Number`}
                                    value={batch.batch_ticket_number}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBatch({ ...batch, batch_ticket_number: e.target.value })}
                                    type="text"
                                    required
                                    error={!batch.batch_ticket_number}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="volume"
                                    label={`Volume`}
                                    value={batch.volume}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBatch({ ...batch, volume: e.target.valueAsNumber })}
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: "any",
                                    }}
                                    variant="outlined"
                                    required
                                    helperText={`Target: ${fullDesign?.target_volume || t('na')}`}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="volume_units">{t('unitTranslations.volumeUnit')}</InputLabel>
                                <Select
                                    label={t('unitTranslations.volumeUnit')}
                                    labelId="volume_units"
                                    value={batch.volume_units}
                                    onChange={e => setBatch({ ...batch, volume_units: e.target.value as string })}
                                    inputProps={{
                                        name: "volume_units",
                                        id: "volume_units"
                                    }}
                                    fullWidth
                                    required
                                >
                                    {units.volume && units.volume.map(unit => (
                                        <MenuItem key={unit} value={unit}>{t(`unitTranslations.volumeUnits.${unit}`)}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="slump"
                                    label={`Slump`}
                                    value={batch.slump}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBatch({ ...batch, slump: e.target.valueAsNumber })}
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: "any",
                                    }}
                                    variant="outlined"
                                    helperText={`Target: ${fullDesign?.target_slump || t('na')}`}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="slump_units">{t('unitTranslations.slumpUnit')}</InputLabel>
                                <Select
                                    label={t('unitTranslations.slumpUnit')}
                                    labelId="slump_units"
                                    value={batch.slump_units}
                                    onChange={e => setBatch({ ...batch, slump_units: e.target.value as string })}
                                    inputProps={{
                                        name: "slump_units",
                                        id: "slump_units"
                                    }}
                                    fullWidth
                                    required={!!batch.slump}
                                >
                                    {units.length && units.length.map(unit => (
                                        <MenuItem key={unit} value={unit}>{t(`unitTranslations.distanceUnits.${unit}`)}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="temperature"
                                    label={`Temperature`}
                                    value={batch.temperature}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBatch({ ...batch, temperature: e.target.valueAsNumber })}
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: "any",
                                    }}
                                    variant="outlined"
                                    helperText={`Target: ${fullDesign?.target_temperature || t('na')}`}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="temperature_units">{t('unitTranslations.temperatureUnit')}</InputLabel>
                                <Select
                                    label={t('unitTranslations.temperatureUnit')}
                                    labelId="temperature_units"
                                    value={batch.temperature_units}
                                    onChange={e => setBatch({ ...batch, temperature_units: e.target.value as string })}
                                    inputProps={{
                                        name: "temperature_units",
                                        id: "temperature_units"
                                    }}
                                    fullWidth
                                    required={!!batch.temperature}
                                >
                                    {units.temperature && units.temperature.map(unit => (
                                        <MenuItem key={unit} value={unit}>{t(`unitTranslations.temperatureUnits.${unit}`)}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="mixing_revolutions"
                                    label={t('materialTranslations.mixingRevolutions')}
                                    value={batch.mixing_revolutions}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBatch({ ...batch, mixing_revolutions: e.target.valueAsNumber })}
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        step: "any",
                                    }}
                                    variant="outlined"
                                    helperText={`Target: ${fullDesign?.target_mixing_revolutions || t('na')}`}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="air_content_percentage"
                                    label={t('materialTranslations.airContentPercentage')}
                                    value={batch.air_content_percentage}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBatch({ ...batch, air_content_percentage: e.target.valueAsNumber })}
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        max: 100,
                                        step: "any"
                                    }}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                    }}
                                    helperText={`Target: ${fullDesign?.target_air_content_percentage ? `${fullDesign.target_air_content_percentage}%` : t('na')}`}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10}>
                        <Grid item xs={6}>
                            <Button
                                color="primary"
                                variant="outlined"
                                type="button"
                                fullWidth
                                onClick={() => setTabValue(TabValues.DESIGNS.toString())}
                            >
                                {t("actions.previous")}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                fullWidth
                            >
                                {t("actions.next")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </TabPanel>
            <TabPanel value={tabValue} index={TabValues.CONSTITUENTS.toString()} boxProps={{ p: 3 }}>
                <form onSubmit={e => {
                    e.preventDefault()
                    setTabValue(TabValues.SUMMARY.toString())
                }}>
                    <Grid container spacing={10} alignItems="center">
                        <Grid item>
                            <Typography variant="h6">Constituents ({constituents.length})</Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                type="button"
                                color="primary"
                                onClick={() => setAddAmmendmentModal(true)}
                            >
                                {t('actions.add')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Separator my={3}/>
                    <Grid container spacing={10}>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Material Name
                                            </TableCell>
                                            <TableCell>
                                                Target
                                            </TableCell>
                                            <TableCell>
                                                Actual
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!constituents.length && <TableRow>
                                            <TableCell colSpan={4}>
                                                <Typography>{t("noItemsFound")}</Typography>
                                            </TableCell>
                                        </TableRow>}
                                        {constituents.map((constituent, index) => {
                                            return (
                                                <TableRow key={`${constituent.amendment_custom_material_id}-${constituent.material_mix_design_constituent_id}`}>
                                                    <TableCell>
                                                        {constituent.material ? <Typography>
                                                            {constituent.material.material_number} - {constituent.material.name}
                                                        </Typography> : <Typography>
                                                            {constituent.customMaterial?.material_number} - {constituent.customMaterial?.name}
                                                        </Typography>}
                                                    </TableCell>
                                                    {/* <TableCell>{fullDesign?.constituents?.[index]?.target_weight ? fullDesign?.constituents?.[index]?.target_weight : ""} {constituent.weight_units}</TableCell> */}
                                                    <TableCell>
                                                        {Boolean(fullDesign?.constituents && !constituent.amendment_custom_material_id) && <RenderConstituentTarget 
                                                            mixDesignConstituent={fullDesign!.constituents![index]} 
                                                            mixBatchConstituentPayload={constituent}
                                                            batch={batch}
                                                            delivery={delivery}
                                                            fullJob={fullJob}
                                                            fullDesign={fullDesign}
                                                        />}
                                                    </TableCell>
                                                    <TableCell>
                                                        {constituent.weight_units !== undefined && <FormControl fullWidth>
                                                            <TextField
                                                                id="weight"
                                                                label={`Weight`}
                                                                value={constituent.weight}
                                                                onChange={updateConstituentMeasurement(index, 'weight')}
                                                                type="number"
                                                                inputProps={{
                                                                    min: 0,
                                                                    step: "any"
                                                                }}
                                                                variant="outlined"
                                                                required={constituent.primary_measurement_type === "weight"}
                                                                error={constituent.primary_measurement_type === "weight"}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">
                                                                        <Typography>
                                                                            {t(`unitTranslations.${constituent.weight_units}`)}
                                                                        </Typography>
                                                                    </InputAdornment>
                                                                }}
                                                            />
                                                        </FormControl>}
                                                    </TableCell>
                                                    <TableCell>
                                                        {constituent.volume_units !== undefined && <FormControl fullWidth>
                                                            <TextField
                                                                id="volume"
                                                                label={`Volume`}
                                                                value={constituent.volume}
                                                                onChange={updateConstituentMeasurement(index, 'volume')}
                                                                type="number"
                                                                inputProps={{
                                                                    min: 0,
                                                                    step: "any"
                                                                }}
                                                                variant="outlined"
                                                                required={constituent.primary_measurement_type === "volume"}
                                                                error={constituent.primary_measurement_type === "volume"}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">
                                                                    <Typography>
                                                                        {t(`unitTranslations.${constituent.volume_units}`)}
                                                                    </Typography>
                                                                </InputAdornment>
                                                                }}
                                                            />
                                                        </FormControl>}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {Boolean(constituent.amendment_custom_material_id) && <IconButton 
                                                            size="small"
                                                            onClick={() => deleteConstituent(index)}
                                                        >
                                                            <Delete />
                                                        </IconButton>}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10}>
                        <Grid item xs={6}>
                            <Button
                                color="primary"
                                variant="outlined"
                                type="button"
                                fullWidth
                                onClick={() => setTabValue(TabValues.DETAILS.toString())}
                            >
                                {t("actions.previous")}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                fullWidth
                            >
                                {t("actions.next")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </TabPanel>
            <TabPanel value={tabValue} index={TabValues.SUMMARY.toString()} boxProps={{ p: 3 }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={10}>
                        <Grid item xs>
                            <Typography variant="h6">Details</Typography>
                        </Grid>
                    </Grid>
                    <Separator />
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={4}>
                            <Typography color="textSecondary">{t("materialTranslations.mixDesign")}</Typography>
                            <Typography>{selectedDesign?.mix_id} - {selectedDesign?.name}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography color="textSecondary">{t("siteTranslations.siteName")}</Typography>
                            <Typography>{sites.find(site => site.id === batch.site_id)?.site_name || t('na')}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography color="textSecondary">Batch Ticket #</Typography>
                            <Typography>{batch.batch_ticket_number}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10}>
                        <Grid item xs>
                            <Typography variant="h6">{t("unitTranslations.measurements")}</Typography>
                        </Grid>
                    </Grid>
                    <Separator />
                    <Grid container spacing={10}>
                        {Boolean(batch.volume && batch.volume_units) && <Grid item xs={12} md={3}>
                            <Typography color="textSecondary">{t("unitTranslations.volume")}</Typography>
                            <Typography>{batch.volume} {t(`unitTranslations.${batch.volume_units}`)}</Typography>
                        </Grid>}
                        {Boolean(batch.slump && batch.slump_units) && <Grid item xs={12} md={3}>
                            <Typography color="textSecondary">{t("unitTranslations.slump")}</Typography>
                            <Typography>{batch.slump} {t(`unitTranslations.${batch.slump_units}`)}</Typography>
                        </Grid>}
                        {Boolean(batch.temperature && batch.temperature_units) && <Grid item xs={12} md={3}>
                            <Typography color="textSecondary">{t("unitTranslations.temperature")}</Typography>
                            <Typography>{batch.temperature} {t(`unitTranslations.${batch.temperature_units}`)}</Typography>
                        </Grid>}
                        {Boolean(batch.mixing_revolutions) && <Grid item xs={12} md={3}>
                            <Typography color="textSecondary">{t("materialTranslations.mixingRevolutions")}</Typography>
                            <Typography>{batch.mixing_revolutions}</Typography>
                        </Grid>}
                        {Boolean(batch.air_content_percentage) && <Grid item xs={12} md={3}>
                            <Typography color="textSecondary">{t("materialTranslations.airContentPercentage")}</Typography>
                            <Typography>{batch.air_content_percentage}%</Typography>
                        </Grid>}
                    </Grid>

                    <Grid container spacing={10}>
                        <Grid item xs>
                            <Typography variant="h6">Constituents ({constituents.length})</Typography>
                        </Grid>
                    </Grid>
                    <Separator />
                    <Grid container spacing={10}>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Material Name
                                            </TableCell>
                                            <TableCell>
                                                Target
                                            </TableCell>
                                            <TableCell>
                                                Actual
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!constituents.length && <TableRow>
                                            <TableCell colSpan={4}>
                                                <Typography>{t("noItemsFound")}</Typography>
                                            </TableCell>
                                        </TableRow>}
                                        {constituents.map((constituent, index) => (
                                            <TableRow key={`${constituent.amendment_custom_material_id}-${constituent.material_mix_design_constituent_id}`}>
                                                <TableCell>
                                                    {constituent.material ? <Typography>
                                                        {constituent.material.material_number} - {constituent.material.name}
                                                    </Typography> : <Typography>
                                                        {constituent.customMaterial?.material_number} - {constituent.customMaterial?.name}
                                                    </Typography>}
                                                </TableCell>
                                                <TableCell>
                                                    {Boolean(fullDesign?.constituents && !constituent.amendment_custom_material_id) && <RenderConstituentTarget 
                                                        mixDesignConstituent={fullDesign!.constituents![index]!} 
                                                        mixBatchConstituentPayload={constituent}
                                                        batch={batch}
                                                        delivery={delivery}
                                                        fullJob={fullJob}
                                                        fullDesign={fullDesign}
                                                    />}
                                                </TableCell>
                                                <TableCell>
                                                    {constituent.primary_measurement_type === "volume" ? <>
                                                        <Typography>{constituent.volume} {constituent.volume_units}</Typography>
                                                    </> : <>
                                                        <Typography>{constituent.weight} {constituent.weight_units}</Typography>
                                                    </>}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10}>
                        <Grid item xs>
                            <Typography variant="h6">Other</Typography>
                        </Grid>
                    </Grid>
                    <Separator />
                    <Grid container spacing={10} alignItems="center">
                        <Grid item>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t('materialTranslations.isSignatureApplied')}</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={batch.is_signature_applied}
                                                onChange={e => setBatch({ ...batch, is_signature_applied: e.target.checked })}
                                                color="primary"
                                            />
                                        }
                                        label=""
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormHelperText>Your saved signature will be used to sign off on the batch</FormHelperText>
                        </Grid>
                        <Grid item>
                            <Button 
                                variant="outlined"
                                color="primary" 
                                onClick={() => setOpenSignatureDialog(true)}
                            >
                                {t("materialTranslations.updateSignature")}
                            </Button>
                        </Grid>
                    </Grid>
                    {batch.is_signature_applied && <Grid container spacing={10}>
                        <Grid item>
                            <Button 
                                variant={useNewSignature ? "outlined" : "contained"} 
                                color="primary" 
                                onClick={() => setUseNewSignature(false)}
                            >
                                {t("materialTranslations.useSavedSignature")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button 
                                variant="outlined"
                                color="primary" 
                                onClick={() => setOpenSignatureDialog(true)}
                            >
                                {t("materialTranslations.makeNewSignature")}
                            </Button>
                        </Grid>
                    </Grid>}
                    <Grid container spacing={10}>
                        <Grid item xs={6}>
                            <Button
                                color="primary"
                                variant="outlined"
                                type="button"
                                fullWidth
                                onClick={() => setTabValue(TabValues.CONSTITUENTS.toString())}
                            >
                                {t("actions.previous")}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                fullWidth
                            >
                                {t("actions.submit")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                {openSignatureDialog && <SignatureDialog 
                    setModalOpen={setOpenSignatureDialog}
                    updateSignatureFile={updateSignatureFile}
                />}
            </TabPanel>
        </TabContext>
        {openAddAmmendmentModal && <AddConstituentModal 
            setModalOpen={setAddAmmendmentModal}
            addConstituent={addAmmendment}
            onlyCustomMaterials={true}
            forDesign
        />}
    </TPModal>
}

export default CreateEditMixBatchModal
interface RenderContituentDetailsProps {
    constituent: {
        material?: Material;
        customMaterial?: CustomMaterial;
    }
}
const RenderContituentDetails = ({ constituent }: RenderContituentDetailsProps) => {
    return <>idk</>
}
