import React, { useEffect, useMemo, useState } from "react";
import { Button, Grid, IconButton, Paper as MuiPaper, Table, TableBody, TableContainer, TableFooter, TablePagination, TableRow, Typography } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getMaterials } from "../../../../redux/actions/adminActions";
import { getAllCustomMaterials } from "../../../../redux/actions/materialActions";
import { popAlert } from "../../../../redux/actions/sessionActions";
import { CustomMaterial, DistributiveOmit, EmptyQuery, instanceOf, Job, JobQuery, JobQueryTypes, Material, MaterialCategorizationQuery, MaterialCategory, MixDesign } from "../../../../redux/reducers/entities";
import { getAllAvailableCustomMaterialCategories } from "../../../../redux/util/materialAPI";
import { getAllCompanyMaterialMixDesigns } from "../../../../redux/util/mixAPI";
import { formatCategorizationValue } from "../../Materials/modals/ModalEditMaterialCategorizations";
import Separator from "../../Util/Separator";
import { RenderQuery } from "../modals/ModalEditJobQuery";
import { useRootSelector } from "../../../..";

interface Props {
    buttonOnClick: (isValid: boolean, query: DistributiveOmit<JobQuery, "type">) => void;
    buttonText: string;

    onQueryChanged: (query: JobQuery) => void;
    disallowedTypes?: JobQueryTypes[];
}

export const QueryHandler = ({
    buttonOnClick,
    buttonText,
    onQueryChanged,
    disallowedTypes,
}: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const token = useRootSelector(state => state.session.user.token!)
    const companyID = useRootSelector(state => state.session.company.id!)

    const [query, setQuery] = useState<JobQuery>({
        type: JobQueryTypes.UNKNOWN
    })
    useEffect(() => {
        onQueryChanged(query)
    }, [query])

    const turnToDict = (arr: any[], indexOn: string) => {
        const dict: any = {}
        arr.forEach((el: any) => {
            dict[el[indexOn]] = el
        })
        return dict
    }
    const materials = useRootSelector(state => state.entities.materials)
    const materialsByID = useMemo(() => {
        if (!materials) return {}
        return turnToDict(materials, "id")
    }, [materials])

    const customMaterialsByID = useRootSelector(state => state.entities.customMaterialsByID)

    const [materialCategoriesByKey, setMaterialCategoriesByKey] = useState({})
    const getCategories = async () => {
        const response = await getAllAvailableCustomMaterialCategories(token)
        if (!response.success) return;
        setMaterialCategoriesByKey(
            turnToDict(response.data, "key")
        )
    }

    const [mixDesignsByID, setMixDesignsByID] = useState({})
    const getDesigns = async () => {
        const response = await getAllCompanyMaterialMixDesigns(token, companyID)
        if (!response.success) return;
        setMixDesignsByID(
            turnToDict(response.data, "id")
        )
    }
    useEffect(() => {
        getCategories()
        getDesigns()
        dispatch(getMaterials(token))
        dispatch(getAllCustomMaterials(token, companyID))
    }, [])

    const entityDict: {
        materials: { [id: number]: Material }
        customMaterials: { [id: number]: CustomMaterial }
        mixDesigns: { [id: number]: MixDesign }
        materialCategories: { [key: string]: MaterialCategory }
    } = useMemo(() => {
        const newDict = {
            materials: materialsByID,
            customMaterials: customMaterialsByID,
            mixDesigns: mixDesignsByID,
            materialCategories: materialCategoriesByKey
        }
        return newDict
    }, [
        materialsByID,
        customMaterialsByID,
        mixDesignsByID,
        materialCategoriesByKey,
    ])

    const isQueryValid = (currentQuery: JobQuery): boolean => {
        if (currentQuery.type === JobQueryTypes.DUAL) {
            return isQueryValid(currentQuery.query_a) || isQueryValid(currentQuery.query_b)
        } else {
            return currentQuery.type !== JobQueryTypes.UNKNOWN
        }
    }
    const cleanQuery = (currentQuery: JobQuery): DistributiveOmit<JobQuery, "type"> => {
        const queryCopy = { ...currentQuery }

        let cleanedQuery: DistributiveOmit<JobQuery, "type"> = {}
        if (queryCopy.type === JobQueryTypes.DUAL) {
            cleanedQuery = {
                query_a: cleanQuery(queryCopy.query_a),
                query_operator: queryCopy.query_operator,
                query_b: cleanQuery(queryCopy.query_b)
            }
        } else {
            delete queryCopy.type
            if (instanceOf<MaterialCategorizationQuery>("units", queryCopy)) {
                const formattedCategorizationValue = formatCategorizationValue({
                    category_key: queryCopy.material_category_key,
                    value: queryCopy.value,
                    units: queryCopy.units
                }, entityDict.materialCategories[queryCopy.material_category_key])

                queryCopy.value = formattedCategorizationValue.value
                if (!queryCopy.units) delete queryCopy.units
                if (!queryCopy.canonical_units) delete queryCopy.canonical_units
                if (!queryCopy.units_type) delete queryCopy.units_type
            }
            cleanedQuery = {
                ...queryCopy,
            }
        }
        return cleanedQuery
    }

    return <Grid container spacing={10}>
        <Grid item xs={12}>
            <Separator my={3} color="black" />
            <Typography gutterBottom>Current Query</Typography>
            <RenderQuery
                entities={entityDict}
                query={query}
                setQuery={(newQuery) => setQuery(newQuery)}
                disallowedTypes={disallowedTypes}
            />
        </Grid>
        <Grid item xs={12}>
            <Button onClick={() => {
                let q: DistributiveOmit<JobQuery, "type"> = {}
                const queryIsValid = isQueryValid(query)
                if (queryIsValid) {
                    q = cleanQuery(query)
                } else {
                    dispatch(popAlert('error', t('error'), "You must fill out all queries"))
                }
                buttonOnClick(queryIsValid, q)
            }} variant="contained" color="primary">{buttonText}</Button>
        </Grid>
    </Grid>
}