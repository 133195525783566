import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Modal,
    Tab,
    Tabs,
    TabContent,
    TabScrollButton,
    TabList,
    Paper as MuiPaper,
    AppBar,
    Toolbar,
    Typography as MuiTypography,
    Grid as MuiGrid,
    FormGroup,
    FormControl as MuiFormControl,
    FormControlLabel,
    FormLabel,
    FormHelperText,
    Input,
    InputLabel,
    Select,
    TextField,
    MenuItem,
    Button,
    Switch,
    Radio,
    RadioGroup,
    Divider as MuiDivider,
    InputAdornment,
    Box as MuiBox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import TPModal from '../../Util/TPModal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import CloseIcon from "@material-ui/icons/Close";
import { getPhoneData, CustomPhoneNumberInput } from '../../Util/PhoneInput';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { popAlert } from '../../../../redux/actions/sessionActions';
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';
import SigCanvas from 'react-signature-pad-wrapper';

import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { addJobInspectionItems, createInspection, createJobInspector, findJobInspectorUserAndCompanyByEmail, getAllInspectionItems, getAllJobInspectionItemsAsInspector, getDeliveryDetails, getJobInspectionItemsAsInspector, updateJobInspectionNotes } from '../../../../redux/util/inspectorAPI';
import { useParams } from 'react-router-dom';
import Separator from '../../Util/Separator';
import { InspectionItemDataTypes } from '../../../../redux/reducers/entities';
import { chunk, createDateFromUnix, formatNumber, toMysqlFormat } from '../../../../redux/reducers/helpersReducerr';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { getDeliveryMaterialMixBatch } from '../../../../redux/util/mixAPI';

const Paper = styled(MuiPaper)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Box = styled(MuiBox)(spacing);
const Bold = styled.span`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: black;
`;

function SignatureDialog({
    setModalOpen,
    updateSignatureFile,
    openSignatureDialogue
}) {
    const { t } = useTranslation()
    const sigCanvasRef = useRef()
    const [eSignature, setESignature] = useState("")
    const [openSignatureUpload, setOpenSignatureUpload] = useState(false)
    const [signatureFile, setSignatureFile] = useState([])
    const clearCanvas = () => {
        const sigCanvas = sigCanvasRef.current
        if (sigCanvas) {
            sigCanvas.clear();
            setESignature("")
        }
    }
    const handleSignatureTextChange = event => {
        const sigCanvas = sigCanvasRef.current
        if ((sigCanvas && !sigCanvas.isEmpty()) || eSignature) {
            sigCanvas.clear()
        }
        setESignature(event.target.value)
        const { canvas } = sigCanvas
        const ctx = canvas.getContext('2d')
        ctx.textAlign = "center";
        ctx.font = '30px'
        ctx.fillText(event.target.value, canvas.width / 4, canvas.height / 4)
    }
    const handleSignatureFileChange = files => {
        setSignatureFile(files)
        setOpenSignatureUpload(false)
    }
    const updateFile = async () => {
        const sigCanvas = sigCanvasRef.current
        const imageURL = sigCanvas.toDataURL();
        const file = await fetch(imageURL).then(res => res.blob()).then(blob => new File([blob], "signature.png", { type: "image/png" }))
        updateSignatureFile([file])
        setModalOpen(false)
    }
    return <>
        <Dialog
            open={openSignatureDialogue}
            aria-labelledby="form-dialog-title"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">{t(`authTranslations.signature`)}</DialogTitle>
            <DialogContent>
                <SigCanvas ref={sigCanvasRef}/>
                <Button 
                    fullWidth
                    onClick={() => clearCanvas()}
                >
                    Clear Signature
                </Button>
                <FormControl fullWidth>
                <TextField
                    id="signature"
                    label={"Digital Signature (Optional)"}
                    value={eSignature}
                    onChange={handleSignatureTextChange}
                    type="text"
                />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setModalOpen(false)} type="button" color="primary">
                    {t(`actions.cancel`)}
                </Button>
                <Button 
                    color="primary" 
                    type="button" 
                    onClick={() => openSignatureUpload(true)}
                >Upload Signature Image</Button>
                <DropzoneDialog
                    open={openSignatureUpload}
                    onSave={handleSignatureFileChange}
                    showPreviews={true}
                    maxFileSize={2000000}
                    onClose={() => openSignatureUpload(false)}
                    showFileNamesInPreview={true}
                    showFileNames={true}
                    filesLimit={1}
                    acceptedFiles={['image/*']}
                    dropzoneText={"Upload signature image"}
                />
                <Button 
                    onClick={updateFile} 
                    variant="contained" 
                    color="primary"
                >
                    {t(`actions.save`)}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

function RenderInput({ 
    onChange = () => {},
    handleFileChange = () => {},
    // handleBooleanChange = () => {},
    // handleDateChange = () => {},
    jobInspectionItem,
    linkCanvasRef,
    deliveryDetails = {}
}) {
    const { t } = useTranslation()
    const [openModal, setOpenModal] = useState(false)
    
    const inspectionItem = jobInspectionItem.inspection_item

    const defaultHeader = <Grid item xs={12}>
        <Typography variant="h6">{inspectionItem.name}</Typography>
        <Typography variant="subtitle2" color="textSecondary">{t('note')}:</Typography>
        <Typography variant="body2">{jobInspectionItem.notes || 'N/A'}</Typography>
    </Grid>
    switch (inspectionItem.data_type) {
        case InspectionItemDataTypes.IMAGE:
            return (<>
                <Grid container spacing={5}>
                    {defaultHeader}
                    <Grid item xs={12}>
                        <DropzoneArea 
                            showFileNamesInPreview={true}
                            showFileNames={true}
                            filesLimit={inspectionItem.allow_multiple_per_inspection ? 50 : 1}
                            onChange={handleFileChange}
                            acceptedFiles={['image/*']}
                            dropzoneText={`Upload images`}
                        />
                    </Grid>
                </Grid>
            </>)
        case InspectionItemDataTypes.STRING:
            return (<>
                <Grid container spacing={5}>
                    {defaultHeader}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id={`${jobInspectionItem.id}_${inspectionItem.name}`}
                                label={inspectionItem.name}
                                value={jobInspectionItem.value}
                                onChange={onChange}
                                type="text"
                                variant="outlined"
                                required
                                error={!jobInspectionItem.value}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </>)
        case InspectionItemDataTypes.INTEGER:
            return (<>
                <Grid container spacing={5}>
                    {defaultHeader}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id={`${jobInspectionItem.id}_${inspectionItem.name}`}
                                label={inspectionItem.name}
                                value={jobInspectionItem.value}
                                onChange={onChange}
                                type="number"
                                variant="outlined"
                                required
                                error={!jobInspectionItem.value}
                                inputProps={{
                                    // min: 1,
                                    step: 1
                                }}
                                InputProps={{
                                    endAdornment: jobInspectionItem.units && <InputAdornment position="end">{t(`unitTranslations.${jobInspectionItem.units}`)}</InputAdornment>,
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </>)
        case InspectionItemDataTypes.DECIMAL:
            return (<>
                <Grid container spacing={5}>
                    {defaultHeader}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id={`${jobInspectionItem.id}_${inspectionItem.name}`}
                                label={inspectionItem.name}
                                value={jobInspectionItem.value}
                                onChange={onChange}
                                type="number"
                                variant="outlined"
                                required
                                error={!jobInspectionItem.value}
                                inputProps={{
                                    // min: 1,
                                    step: "any"
                                }}
                                InputProps={{
                                    endAdornment: jobInspectionItem.units && <InputAdornment position="end">{t(`unitTranslations.${jobInspectionItem.units}`)}</InputAdornment>,
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </>)
        case InspectionItemDataTypes.DATETIME:
            return (<>
                <Grid container spacing={5}>
                    {defaultHeader}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <KeyboardDateTimePicker
                                label={inspectionItem.name}
                                value={jobInspectionItem.value}
                                error={!jobInspectionItem.value}
                                onChange={onChange}
                                format="MM/dd/yyyy h:mm:ss a"
                                inputVariant="outlined"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </>)
        case InspectionItemDataTypes.DOCUMENT:
            return (<>
                <Grid container spacing={5}>
                    {defaultHeader}
                    <Grid item xs={12}>
                        <DropzoneArea 
                            showFileNamesInPreview={true}
                            showFileNames={true}
                            filesLimit={inspectionItem.allow_multiple_per_inspection ? 50 : 1}
                            onChange={handleFileChange}
                            dropzoneText={`Upload documents`}
                        />
                    </Grid>
                </Grid>
            </>)
        case InspectionItemDataTypes.SIGNATURE: 
            return (<>
                <Grid container spacing={5}>
                    {defaultHeader}
                    <Grid item xs={12}>
                        <Button
                            onClick={() => setOpenModal(true)}
                            variant={jobInspectionItem.value.length ? "outlined" : "contained"}
                            color="primary"
                        >
                            {jobInspectionItem.value.length ? "Change Signature" : "Add Signature"}
                        </Button>
                        <SignatureDialog 
                            openSignatureDialogue={openModal}
                            setModalOpen={setOpenModal}
                            updateSignatureFile={handleFileChange}
                        />
                    </Grid>
                </Grid>
            </>)
        case InspectionItemDataTypes.BOOLEAN: 
            return (<>
                <Grid container spacing={5}>
                    {defaultHeader}
                    {Boolean(jobInspectionItem.inspection_item.name === 'Constituents' && deliveryDetails?.mix_batch) && <Grid item xs={12}>
                        <Grid container spacing={10}>
                            <Grid item xs={12} md={6}>
                                {Boolean(
                                    deliveryDetails.mix_batch.mix_design.target_weight && 
                                    deliveryDetails.mix_batch.mix_design.weight_units && 
                                    deliveryDetails.mix_batch.formattedWeight &&
                                    deliveryDetails.mix_batch.weight_units
                                ) && <>
                                    <Grid item xs={12} md={6}>
                                        <Typography color="textSecondary">Weight</Typography>
                                        <Typography mb={2}>Target: {deliveryDetails.mix_batch.mix_design.formattedWeight} {t(`unitTranslations.${deliveryDetails.mix_batch.mix_design.weight_units}`)}</Typography>
                                        <Typography mb={2}>Actual: {deliveryDetails.mix_batch.formattedWeight} {t(`unitTranslations.${deliveryDetails.mix_batch.weight_units}`)}</Typography>
                                    </Grid>
                                </>}
                                    

                                {Boolean(
                                    deliveryDetails.mix_batch.mix_design.target_volume && 
                                    deliveryDetails.mix_batch.mix_design.volume_units &&
                                    deliveryDetails.mix_batch.formattedVolume &&
                                    deliveryDetails.mix_batch.volume_units
                                ) && <>
                                    <Grid item xs={12} md={6}>
                                        <Typography color="textSecondary">Volume</Typography>
                                        <Typography mb={2}>Target: {deliveryDetails.mix_batch.mix_design.formattedVolume} {t(`unitTranslations.${deliveryDetails.mix_batch.mix_design.volume_units}`)}</Typography>
                                        <Typography mb={2}>Actual: {deliveryDetails.mix_batch.formattedVolume} {t(`unitTranslations.${deliveryDetails.mix_batch.volume_units}`)}</Typography>
                                    </Grid>
                                </>}

                                {Boolean(
                                    deliveryDetails.mix_batch.mix_design.target_slump && 
                                    deliveryDetails.mix_batch.mix_design.slump_units &&
                                    deliveryDetails.mix_batch.formattedSlump &&
                                    deliveryDetails.mix_batch.slump_units
                                ) && <>
                                    <Grid item xs={12} md={6}>
                                        <Typography color="textSecondary">Slump</Typography>
                                        <Typography mb={2}>Target: {deliveryDetails.mix_batch.mix_design.formattedSlump} {t(`unitTranslations.${deliveryDetails.mix_batch.mix_design.slump_units}`)}</Typography>
                                        <Typography mb={2}>Actual: {deliveryDetails.mix_batch.formattedSlump} {t(`unitTranslations.${deliveryDetails.mix_batch.slump_units}`)}</Typography>
                                    </Grid>
                                </>}

                                {Boolean(
                                    deliveryDetails.mix_batch.mix_design.target_temperature && 
                                    deliveryDetails.mix_batch.mix_design.temperature_units &&
                                    deliveryDetails.mix_batch.formattedTemperature &&
                                    deliveryDetails.mix_batch.temperature_units
                                ) && <>
                                    <Grid item xs={12} md={6}>
                                        <Typography color="textSecondary">Temperature</Typography>
                                        <Typography mb={2}>Target: {deliveryDetails.mix_batch.mix_design.formattedTemperature} {t(`unitTranslations.${deliveryDetails.mix_batch.mix_design.temperature_units}`)}</Typography>
                                        <Typography mb={2}>Actual: {deliveryDetails.mix_batch.formattedTemperature} {t(`unitTranslations.${deliveryDetails.mix_batch.temperature_units}`)}</Typography>
                                    </Grid>
                                </>}

                                {Boolean(
                                    deliveryDetails.mix_batch.mix_design.target_mixing_revolutions &&
                                    deliveryDetails.mix_batch.formattedMixingRevolutions
                                ) && <>
                                    <Grid item xs={12} md={6}>
                                        <Typography color="textSecondary">Mixing Revolutions</Typography>
                                        <Typography mb={2}>Target: {deliveryDetails.mix_batch.mix_design.formattedMixingRevolutions}</Typography>
                                        <Typography mb={2}>Actual: {deliveryDetails.mix_batch.formattedMixingRevolutions}</Typography>
                                    </Grid>
                                </>}
                            </Grid>
                        </Grid>
                    </Grid>}
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">{t('isValid')}</FormLabel>
                            <RadioGroup
                                id={`${jobInspectionItem.id}_${inspectionItem.name}`}
                                name={inspectionItem.name}
                                aria-label={inspectionItem.name}
                                value={jobInspectionItem.value}
                                onChange={onChange}
                                row
                            >
                                <FormControlLabel
                                    value={false}
                                    control={<Radio color="primary" required />}
                                    label={t('no')}
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value={true}
                                    control={<Radio color="primary" required />}
                                    label={t('yes')}
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </>)

        default: 
            return <Typography>Error. ({jobInspectionItem.id} - {inspectionItem.name})</Typography>
    }
    return 
}

/*
    props: {
        inspectionType: 'pickup' || 'dropoff' || 'both,
        refreshDeliveries: Function
    }
*/
function CreateInspectionModal({ 
    setModalOpen,
    delivery,

    specificToken,
    specificJobID,    

    inspectionType = "pickup",
    refreshDeliveries = () => {}
}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const currentCompany = useSelector(state => state.session.company)
    const helpers = useSelector(state => state.helpers)
    const token = useSelector(state => state.session.user.token)
    const inspectorToken = useSelector(state => state.session.inspectorDetails?.inspectorToken || specificToken)
    const jobID = useSelector(state => state.session.inspectorDetails?.jobID || specificJobID)
    const [inspection, setInspection] = useState({
        inspection_location: inspectionType, // 'pickup' || 'dropoff'
        notes: "",
    })
    const [inspectionValues, setInspectionValues] = useState({})
    const [deliveryDetails, setDeliveryDetails] = useState(delivery)

    useEffect(() => {
        console.log("type", inspectionType);
        getInitialData()
    }, [])

    const getInitialData = async () => {
        const [
            inspectionItemsResponse,
            deliveryDetailsResponse,
        ] = await Promise.all([
            getAllJobInspectionItemsAsInspector(inspectorToken, jobID),
            getDeliveryDetails(inspectorToken, jobID, delivery.id),
        ])

        // Set the delivery
        if (deliveryDetailsResponse.success && deliveryDetailsResponse.data.mix_batch) {
            updateDelivery(deliveryDetailsResponse.data)
        }

        /* 
            need to attempt to prefill some inspection items
        */
        if (inspectionItemsResponse?.success === false) return;
        const inspectionValues = {}
        inspectionItemsResponse.data.forEach(iv => {
            if (iv.inspection_item.data_type === 'datetime') {
                iv.value = findDeliveryRelatedValue(iv, deliveryDetailsResponse?.data) || new Date()
            } else if (iv.inspection_item.allow_multiple_per_inspection) {
                iv.value = []
            } else {
                iv.value = findDeliveryRelatedValue(iv, deliveryDetailsResponse?.data) || ""
            }
            inspectionValues[iv.id] = iv
        })
        setInspectionValues(inspectionValues)
    }

    const updateDelivery = async (delivery) => {

        const options = { keepDecimals: true }
        // Format all mix design fields
        delivery.mix_batch.mix_design.formattedWeight = formatNumber(delivery.mix_batch.mix_design.target_weight, options)
        delivery.mix_batch.mix_design.formattedVolume = formatNumber(delivery.mix_batch.mix_design.target_volume, options)
        delivery.mix_batch.mix_design.formattedSlump = formatNumber(delivery.mix_batch.mix_design.target_slump, options)
        delivery.mix_batch.mix_design.formattedTemperature = formatNumber(delivery.mix_batch.mix_design.target_temperature, options)
        delivery.mix_batch.mix_design.formattedMixingRevolutions = formatNumber(delivery.mix_batch.mix_design.target_mixing_revolutions, options)

        // Format all mix batch fields
        delivery.mix_batch.formattedWeight = formatNumber(delivery.mix_batch.weight, options)
        delivery.mix_batch.formattedVolume = formatNumber(delivery.mix_batch.volume, options)
        delivery.mix_batch.formattedSlump = formatNumber(delivery.mix_batch.slump, options)
        delivery.mix_batch.formattedTemperature = formatNumber(delivery.mix_batch.temperature, options)
        delivery.mix_batch.formattedMixingRevolutions = formatNumber(delivery.mix_batch.mixing_revolutions, options)

        setDeliveryDetails(delivery)
    }

    /* 
        temperature - "Temperature"
        slump - "Slump"
        volume - "Total Volume"
        revolutions - "Mixing Drum Revolutions"
        air content - "Air Content"

        load time and unload time come from the ticket
        load - "Load Date and Time"
        unload - "Unload Date and Time"
    */
    // Fill in inspection items that requires delivery/mix batch info.
    // Returns null if a relevant value isn't found
    const findDeliveryRelatedValue = (jobInspectionItem, delivery) => {
        if (!delivery) return null;
        const mixBatch = delivery.mix_batch

        switch (jobInspectionItem.inspection_item.name) {

            // These values just need the top level delivery
            case "Load Date and Time": 
                return delivery.load_time ? createDateFromUnix(delivery.load_time, { keepLocal: false }) : null
            case "Unload Date and Time": 
                return delivery.unload_time ? createDateFromUnix(delivery.unload_time, { keepLocal: false }) : null

            // These values need the delivery batch
            case "Temperature": 
                if (!mixBatch) return null;
                return mixBatch.temperature;
            case "Slump": 
                if (!mixBatch) return null;
                return mixBatch.slump;
            case "Total Volume": 
                if (!mixBatch) return null;
                return mixBatch.volume;
            case "Mixing Drum Revolutions": 
                if (!mixBatch) return null;
                return mixBatch.mixing_revolutions;
            case "Air Content": 
                if (!mixBatch) return null;
                return mixBatch.air_content_percentage;
            default:
                return;
        }
        
    }

    const handleSubmit = async e => {
        e.preventDefault()
        const valuesData = []
        Object.values(inspectionValues).filter(item => {
            if (inspection.inspection_location == 'pickup') {
                return item.is_pickup_inspection
            } else {
                return item.is_dropoff_inspection
            }
        }).forEach(iv => {
            if (iv.value.forEach) {
                iv.value.forEach(value => {
                    valuesData.push({
                        job_inspection_item_id: iv.id,
                        value
                    })
                })
            } else {
                valuesData.push({
                    job_inspection_item_id: iv.id,
                    value: iv.value
                })
            }
        }) 
        const postData = {
            inspection_location: inspection.inspection_location,
            notes: inspection.notes,
            inspection_values: valuesData
        }
        if (inspection.inspection_location == 'pickup') {
            postData.latitude = delivery.route.address_from.latitude
            postData.longitude = delivery.route.address_from.longitude
        } else {
            postData.latitude = delivery.route.address_to.latitude
            postData.longitude = delivery.route.address_to.longitude
        }
        const formData = new FormData();
        Object.keys(postData).forEach(key => {
            if (typeof postData[key] !== 'object') {
                formData.append(`data[${key}]`, postData[key])
            }
        })
        addNestedDataToFormData(postData, formData)

        // console.log('form data', postData)
        // for(const pair of formData.entries()) {
        //     console.log("entries", `${pair[0]}, ${pair[1]}, ${typeof pair[1]}`);
        // }
        createInspection(inspectorToken, jobID, delivery.id, formData).then(res => {
            if (res?.success) {
                dispatch(popAlert('success', 'Success', 'Inspection saved!'))
                console.log("SHould be refreshing deliveries!")
                refreshDeliveries()
                setModalOpen(false)
            } else {
                dispatch(popAlert('error', 'Error', helpers.parseError(res)))
            }
        })
    }
    const addNestedDataToFormData = (postData, formData) => {
        /*
            postData.inspection_values = [{ itemID, value }, ...]
        */
        postData.inspection_values.forEach((inspection, index) => {
            console.log(inspection.job_inspection_item_id, inspection.value, typeof inspection.value)
            formData.append(`data[inspection_values][${index}][job_inspection_item_id]`, inspection.job_inspection_item_id)
            if (inspection.value instanceof Date) {
                formData.append(`data[inspection_values][${index}][value]`, toMysqlFormat(inspection.value))
                formData.append(`data[inspection_values][${index}][timezone]`, Intl.DateTimeFormat().resolvedOptions().timeZone)
            } else if (typeof inspection.value === 'boolean') {
                formData.append(`data[inspection_values][${index}][value]`, inspection.value ? 1 : 0)
            } else {
                formData.append(`data[inspection_values][${index}][value]`, inspection.value)
            }
        })

    }
    const handleChange = (jobInspectionItem) => {
        switch (jobInspectionItem.inspection_item.data_type) {
            case "datetime": 
                return date => {
                    setInspectionValues({
                        ...inspectionValues,
                        [jobInspectionItem.id]: {
                            ...inspectionValues[jobInspectionItem.id],
                            value: date
                        }
                    })
                }
            case "boolean":
                return e => {
                    setInspectionValues({
                        ...inspectionValues,
                        [jobInspectionItem.id]: {
                            ...inspectionValues[jobInspectionItem.id],
                            value: e.target.value === "true"
                        }
                    })
                }

            default:
                return e => {
                    setInspectionValues({
                        ...inspectionValues,
                        [jobInspectionItem.id]: {
                            ...inspectionValues[jobInspectionItem.id],
                            value: e.target.value
                        }
                    })
                }
        }
    }
    // const handleDateChange = jobInspectionItemID => date => {
    //     setInspectionValues({
    //         ...inspectionValues,
    //         [jobInspectionItemID]: {
    //             ...inspectionValues[jobInspectionItemID],
    //             value: date
    //         }
    //     })
    // }
    // const handleChange = jobInspectionItemID => e => {
    //     setInspectionValues({
    //         ...inspectionValues,
    //         [jobInspectionItemID]: {
    //             ...inspectionValues[jobInspectionItemID],
    //             value: e.target.value
    //         }
    //     })
    // }
    // const handleBooleanChange = jobInspectionItemID => e => {
    //     setInspectionValues({
    //         ...inspectionValues,
    //         [jobInspectionItemID]: {
    //             ...inspectionValues[jobInspectionItemID],
    //             value: e.target.value === "true"
    //         }
    //     })
    // }
    const handleFileChange = jobInspectionItemID => files => {
        setInspectionValues({
            ...inspectionValues,
            [jobInspectionItemID]: {
                ...inspectionValues[jobInspectionItemID],
                value: files
            }
        })
    }



  
    return (
        <TPModal 
            setModalOpen={setModalOpen}
            headerOptions={{
                headerText: "Create Inspection"
            }}
        >
            <form autoComplete="off" onSubmit={(event) => handleSubmit(event)}>
                {Boolean(inspectionType != 'pickup' && inspectionType != 'dropoff') && <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Inspection Location</FormLabel>
                            <RadioGroup
                                aria-label="inspection location"
                                name="inspection_location"
                                value={inspection.inspection_location}
                                onChange={e => setInspection({ ...inspection, inspection_location: e.target.value})}
                                row
                            >
                                <FormControlLabel
                                    value={'pickup'}
                                    control={<Radio color="primary" required />}
                                    label={'Pick Up'}
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value={'dropoff'}
                                    control={<Radio color="primary" required />}
                                    label={'Drop Off'}
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>}
                <Separator my={3} />
                <Grid container spacing={10}>
                    <Grid item xs>
                        <Typography color="textSecondary">
                            {t('jobTranslations.jobName')}
                        </Typography>
                        <Typography mb={2}>
                            {delivery.job?.name || t('na')}
                        </Typography>

                        <Typography color="textSecondary">
                            {t('jobTranslations.customerName')}
                        </Typography>
                        <Typography mb={2}>
                            {delivery.job?.customer_name || t('na')}
                        </Typography>

                        <Typography color="textSecondary">
                            {t('deliveryTranslations.ticketNumber')}
                        </Typography>
                        <Typography mb={2}>
                            {delivery.external_ticket_id || delivery.ticket_number}
                        </Typography>

                        <Typography color="textSecondary">
                            {t('vehicleTranslations.truckID')}
                        </Typography>
                        <Typography mb={2}>
                            {delivery.external_vehicle_identifier || delivery.vehicle?.name || t('na')}
                        </Typography>
                    </Grid>
                </Grid>
                <Separator my={3} />
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <TextField
                            id={`inspection_notes`}
                            label={t('jobTranslations.notes')}
                            onChange={e => {
                                setInspection({
                                    ...inspection,
                                    notes: e.target.value
                                })
                            }}
                            value={inspection.notes}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs>
                        <Divider />
                    </Grid>
                </Grid>
                {/* <Grid container spacing={10}>
                    {chunk(Object.values(inspectionValues)
                        .filter(item => (inspection.inspection_location == 'pickup' ? item.is_pickup_inspection : item.is_dropoff_inspection)))
                        .map((itemChunk, index) => <React.Fragment key={index}>
                            {itemChunk.map(item => <Grid item xs={12} md={6} key={item.id}>
                                    <RenderInput 
                                        jobInspectionItem={item}
                                        onChange={handleChange(item.id)}
                                        handleBooleanChange={handleBooleanChange(item.id)}
                                        handleFileChange={handleFileChange(item.id)}
                                        deliveryDetails={deliveryDetails}
                                    />
                            </Grid>)}
                            <Separator mt={3}/>
                        </React.Fragment>
                    )}
                </Grid> */}
                <Grid container spacing={10}>
                    {Object.values(inspectionValues)
                        .filter(item => (inspection.inspection_location == 'pickup' ? item.is_pickup_inspection : item.is_dropoff_inspection))
                        .map(item => <Grid item xs={12} md={12} key={item.id}>
                            <RenderInput 
                                jobInspectionItem={item}
                                onChange={handleChange(item)}
                                // handleDateChange={handleDateChange(item.id)}
                                // handleBooleanChange={handleBooleanChange(item.id)}
                                handleFileChange={handleFileChange(item.id)}
                                deliveryDetails={deliveryDetails}
                            />
                        </Grid>)
                    }
                </Grid>
                <Grid container spacing={10}>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" fullWidth type="submit">
                            {t('actions.save')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </TPModal>
    )
    
}

export default CreateInspectionModal