import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
    Modal,
    Tab, 
    Tabs, 
    TabContent,  
    TabScrollButton, 
    TabList,
    Paper as MuiPaper,
    AppBar,
    Toolbar,
    Typography as MuiTypography,
    Grid as MuiGrid,
    FormGroup,
    FormControl as MuiFormControl,
    FormControlLabel,
    FormLabel,
    FormHelperText,
    Input,
    InputLabel,
    Select,
    TextField,
    MenuItem, 
    Button as MuiButton,
    Switch, 
    Radio,
    RadioGroup,
    Divider as MuiDivider,
    InputAdornment,
    Box,
    OutlinedInput,
    IconButton
} from '@material-ui/core';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { connect } from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import WorldIcon from '@material-ui/icons/Language';
import { TPLoader } from '../../../components/Progress';
import { 
    KeyboardDatePicker,
    KeyboardTimePicker
} from "@material-ui/pickers";
import GoogleMapReact from 'google-map-react'
import { GMapAPIKey } from '../../../../env'
import { getCompanySites } from '../../../../redux/actions/siteActions';
import { popAlert } from '../../../../redux/actions/sessionActions';
import { getPendingJobs, clearPaginatedPendingJobs } from '../../../../redux/actions/jobActions';
import { getCompanyScalesAtLocation } from '../../../../redux/util/scaleAPI';
import { 
    createJob, 
    editJob, 
    getUsersWhoCanView, 
    addUserWhoCanView, 
    removeUserWhoCanView,
    getEntireJobWhitelist,
} from '../../../../redux/util/jobAPI';
import { 
    DEPRECATED_getCustomMaterials 
} from '../../../../redux/actions/companyActions';
import { 
    getValidUnits, 
    getCurrencies, 
    getEquipment, 
    getSupportedCountries,
    getMaterials,
} from '../../../../redux/actions/adminActions';
import { 
    getPreferredSuppliers, 
    getPreferredDestinations, 
    getPreferredCustomers,
    getPreferredDrivers,
    getAuthorizedSignOffMembers,
    setCompanyIdentifier,
} from '../../../../redux/util/companyAPI';
import { addCompaniesToJobWhitelist, removeCompanyFromJobWhitelist } from '../../../../redux/util/jobAPI';
import { addETicketTargetForJob, getETicketTargetsForJob, getETicketTargetsWithDetails, removeETicketTargetForJob, updateETicketTargetForJob } from '../../../../redux/util/eticketAPI';
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { ConfirmModalClose } from '../../../components/Dialogs';
import { WhitelistGrid } from './ModalUpdateWhitelist';
import CustomMaterialModal from '../../Materials/modals/ModalAddEditCustomMaterial';
import ExtendedAttributesPartialRMHMA from '../extendedJobTypes/RMHMA';
import NotificationSnackbar from '../../Util/Snackbar';
import SetJobAddressModal from './ModalSetJobAddress';
import './styles.css';
import AddETicketTargets from './JobPages/AddETicketTarget';
import SearchMaterialsModal from '../../Materials/modals/ModalSearchMaterials';
import TpTextfield from '../../Util/TPTextfield';

const Paper = styled(MuiPaper)(spacing);
const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const FormControl = styled(MuiFormControl)(spacing);

const StyledGrid = styled(MuiGrid)(spacing);

const Grid = React.forwardRef(({ hiddenFields, fieldName, children, fieldNames, ...props }, ref) => {
    if (hiddenFields && fieldNames?.every(fn => hiddenFields.includes(fn))) {
        // meant for containers
        return <StyledGrid ref={ref} ></StyledGrid>
    } else if (hiddenFields?.includes(fieldName)) {
        // meant for items
        return <StyledGrid ref={ref} my={4}></StyledGrid>
    } else {
        return <StyledGrid ref={ref} {...props}>{children}</StyledGrid>
    }
})

const Bold = styled.span`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

const GoogleMapReactWrapper = styled.div`
  height: 300px;
  width: 100%;
`;

function SectionHeader({ title, variant = 'h2'}) {
    return (<>
        <Typography variant={variant} gutterBottom><Bold>{title}</Bold></Typography>
        <Divider />
        </>
    )
}


class CreateEditDuplicateJobModal extends React.Component {
    nullState = {
        tabValue: '-1',
        searchSites: "", // TODO: remove
        site: "", // TODO: remove

        requiresPickupAddress: true,
        pickupSite: "",
        openPickupAddressModal: false,
        pickupAddress: {},
        addPickupContactInfo: false,
        pickupContactInfo: {},

        requiresDropoffAddress: true,
        dropoffSite: "",
        openDropoffAddressModal: false,
        dropoffAddress: {},
        addDropoffContactInfo: false,
        dropoffContactInfo: {},

        jobType: "", 
        is_four_way: false,
        is_demolition_job: false,
        is_removal_job: false,
        is_unknown_pickup_job: false,
        is_waste_manifest_job: false,
        currency: "",
        preferredSuppliers: [],
        preferredDestinations: [],
        preferredCustomers: [],
        preferredDrivers: [],
        chunkedPreferredDrivers: [],
        whitelistCompanyIDs: [],
        selectedWhitelistCompanyIDs: [],
        whitelistFilter: "",
        supplier: "",
        company_supplier_price: 1,
        company_supplier_transaction_type_id: 0,
        company_supplier_per_weight_job_units: "",
        company_supplier_per_volume_job_units: "",
        company_supplier_invoices_to_be_paid_through_truckpay: true,
        destination: "",
        demolition_destination_price: 1,
        demolition_destination_transaction_type_id: 0,
        demolition_destination_per_weight_job_units: "",
        demolition_destination_per_volume_job_units: "",
        demolition_destination_invoices_to_be_paid_through_truckpay: true,
        demolition_destination_company_pays: false,
        customer: "",
        company_customer_price: 1,
        company_customer_transaction_type_id: 0,
        company_customer_per_weight_job_units: "",
        company_customer_per_volume_job_units: "",
        company_customer_invoices_to_be_paid_through_truckpay: true,
        name: "",
        start_date: new Date(),
        end_date: new Date(),
        opening_time: new Date(),
        closing_time: new Date(),
        bidding_ends: new Date(),
        equipmentType: "",
        equipment: [],
        searchEquipment: "",
        displayEquipment: "",
        quantity: "",
        estimated_loads: "",
        estimated_amount: "",
        estimated_amount_units: "",
        estimated_volume: "",
        estimated_volume_units: "",
        transaction_type_id: "",
        bid_amount_status: "",
        amount: "",
        per_volume_job_units: "",
        per_weight_job_units: "",
        invoice_due_frequency: "",
        payment_due_frequency: "",
        frequencies: [
            { value: 'daily', name: 'Daily' },
            { value: 'weekly', name: 'Weekly' },
            { value: 'bi-weekly', name: 'Bi-weekly' },
            { value: 'monthly', name: 'Monthly' },
            { value: 'every 90 days', name: 'Quarterly' },
        ],
        invoices_to_be_paid_through_truckpay: true,
        notes: "",
        is_scan_picture_required: false,
        scan_fee_payer: "",
        auto_award: false,
        checkinTypes: [
            { value: 'qr-code', name: 'QR Code' },
            { value: 'self-check', name: 'Self Check' },
            { value: 'signature', name: 'Signature' },
        ],
        checkinType: "",
        checkoutType: "",
        checkoutTypes: [
            { value: 'qr-code', name: 'QR Code' },
            { value: 'self-check', name: 'Self Check' },
            { value: 'signature', name: 'Signature' },
            { value: 'supervisor', name: 'Supervisor Check-Out' }
        ],
        is_driver_checkin_signature_required: false,
        is_driver_checkout_signature_required: false,
        auto_checkout: false,
        is_geo_fenced: false,
        pick_up_geo_fence_distance: "",
        drop_off_geo_fence_distance: "",
        geo_fence_distance_unit: "",
        bidding_geo_restriction: false,
        bidding_geo_restriction_distance: "",
        bidding_geo_restriction_distance_units: "",
        track_driver_return_delivery_trip: false,
        requires_sign_off: false,
        addRainOut: false,
        signOffEmployees: [],
        signOffMemberFirst: "",
        signOffMemberSecond: "",
        signOffMemberThird: "",
        rain_out_units: "",
        advanced_warning_hours: "",
        emails: [],
        email: "",
        privacy_status: "",
        specifiedRouteLocation: false,
        isSitePickupLocation: false,
        displayCoordinates: false,
        addPrimaryContactInfo: false,
        secondaryAddress: {
            country: "",
            address_line_1: "",
            city: "",
            province: "",
            postal_code: "",
            latitude: "",
            longitude: "",
            address_contact_first_name: "",
            address_contact_last_name: "",
            address_company_name: "",
            address_contact_email: "",
            address_contact_phone: "",
        },
        pick_up_location_id: "",
        drop_off_location_id: "",
        country: "",
        showCustomMaterials: false,
        selectedMaterials: [],
        displayMaterials: "",
        searchCustomMaterial: "",
        customMaterial: "",
        customMaterialListName: "",
        searchMaterial: "",
        material: "",
        openCustomMaterialModal: false,
        select_material_at_checkin: false,
        record_weight: true,
        truck_arrives_empty: false,
        driver_can_check_in_with_active_deliveries: false,
        automated_scale: true,
        allow_driver_record_weight: "",
        load_weight_method: "",
        allow_job_tare_weights: false,
        job_tare_weight_expiration_days: 5,
        job_tare_weight_change_tolerance: 10,
        truck_weigh_location: "",
        scale_id: "",
        pick_up_scale_id: "",
        drop_off_scale_id: "",
        weighmaster_doesnt_change: false,
        constant_weighmaster_name: "",
        constant_weighmaster_license_number: "",
        track_driver_destination: "",
        auto_bol: false,
        bol_start: "",
        po_number: "",
        ticket_number_starts_at: "",
        scalesAtLocation: [],
        loading: true,
        warnedBadAddressInput: false,
        estimated_meters: "",
        estimated_meters_units: "",
        openConfirmClose: false,
        addNoncompete: false,
        noncompete_period: "",
        noncompete_distance: "",
        noncompete_distance_units: "",
        allow_vehicle_weight_over_capacity: false,
        driver_company_pickup_inspection_wait_minutes: "",
        driver_company_dropoff_inspection_wait_minutes: "",
        driver_third_party_pickup_inspection_wait_minutes: "",
        driver_third_party_dropoff_inspection_wait_minutes: "",


        //haulback
        addHaulback: false,
        haulbackCountry: "",
        addHaulbackContactInfo: "",
        haulbackAddress: {
            country: "",
            address_line_1: "",
            city: "",
            province: "",
            postal_code: "",
            latitude: "",
            longitude: "",
            address_contact_first_name: "",
            address_contact_last_name: "",
            address_company_name: "",
            address_contact_email: "",
            address_contact_phone: "",
        },
        displayHaulbackCoordinates: false,
        haulbackMaterial: "",
        haulbackCustomMaterialListName: "",
        haulbackCustomMaterial: "",
        haulbackShowCustomMaterials: "",
        selectedHaulbackMaterials: [],
        displayHaulbackMaterials: "",
        haulback_select_material_at_checkin: false,
        haulback_truck_weigh_location: "",
        haulback_allow_driver_record_weight: "",
        haulback_load_weight_method: false,
        haulback_automated_scale: false,
        haulbackScales: [],
        haulback_pick_up_scale_id: "",
        haulback_drop_off_scale_id: "",
        haulback_weighmaster_doesnt_change: false,
        haulback_constant_weighmaster_name: "",
        haulback_constant_weighmaster_license_number: "",
        haulbackCheckinType: "",
        haulbackCheckoutType: "",
        haulback_auto_checkout: false,
        haulback_is_driver_checkin_signature_required: false,
        haulback_is_driver_checkout_signature_required: false,
        haulback_is_geo_fenced: false,
        haulback_pick_up_geo_fence_distance: "",
        haulback_drop_off_geo_fence_distance: "",
        haulback_geo_fence_distance_unit: "",
        /* 
            haulback materials
            haulback dropoff scale
            haulback checkin/out
            haulback geofence
            haulback weigh loc
        */

        customer_name: '',
        customer_phone_number: '',

        extended_job_types: [
            "RMHMA"
        ],
        extended_job_type: "", // 'RMHMA is only option for now
        extended_attribute_values_map: {},
        extended_attribute_values: [],
        useCompanyDrivers: false,

        hiddenFields: [],

        specificHauler: {},
        specificHaulerID: "",
        specificHaulers: [],
        bidAsSpecificHauler: false,
        awardSpecificHauler: false,

        hasAdvancedSettings: {
            // [tabValue]: true | false
        },
        showAdvancedSettings: {
            // [tabValue]: true | false
        },

        estimated_amount: 0,
        estimated_amount_units: "",

        hasBatchOrInspectableMaterial: false,

        is_company_pickup_inspection_required: false,
        is_company_dropoff_inspection_required: false,
        is_third_party_pickup_inspection_required: false,
        is_third_party_dropoff_inspection_required: false,

        can_driver_authorize_inspector: false,
        has_automated_batching: false,
        send_realtime_etickets: false, // TODO - add UI

        possibleTargetNames: [],
        possibleTargets: {},
        send_realtime_etickets: false,
        initialETicketTargets: [],
        eticket_targets: {
            /*
                "PennDOT": {
                    "jmf_year": "2022",
                    "ecms_project_number": "20231",
                    "total_cubic_yards_ordered": 50,
                    "plant_supplier_code": "STD28A41",
                    "material_type": "Concrete"
                }
            */
        },

        openQueryForMaterialsModal: false,

        terms_and_conditions: null,
    }

    jobTypePage = 0;
    eTicketTargetsPage = 1;
    jobDetailsPage = 2;
    routeDetailsPage = 3;
    haulbackDetailsPage = 4;
    summaryPage = 5;

    perLoadTransactionType = 1;
    perWeightTransactionTypeID = 2;
    perHourTransactionTypeID = 3;
    perVolumeTransactionTypeID = 4;

    constructor(props){
        super(props);
        this.today = new Date();

        const canMakePublicJob = Boolean(
            !props.company.trials.length 
            || (props.company.trials.length && props.company.trials[props.company.trials.length - 1].has_expired) 
            || (props.company.trials.length && !props.company.trials[props.company.trials.length - 1].are_payments_allowed)
        )
        this.state = {
            ...this.nullState,
            canMakePublic: canMakePublicJob,
            end_date: new Date(new Date().setMonth(new Date().getMonth()+4)),
            bidding_ends: new Date(new Date().setMonth(new Date().getMonth()+3)),
            terms_and_conditions: props.company.default_job_terms_and_conditions,
        }
        this.tabAvailability = { 
            [this.jobTypePage]: true, 
            [this.eTicketTargetspage]: false, 
            [this.jobDetailsPage]: false, 
            [this.routeDetailsPage]: false, 
            [this.haulbackDetailsPage]: false, 
            [this.summaryPage]: false 
        }

        this.addressInput = React.createRef();
        this.equipmentInput = React.createRef();
        this.materialInput = React.createRef();
        this.haulbackAddressInput = React.createRef();
        this.haulbackMaterialInput = React.createRef();

        window.getJobState = () => this.state
        // window.setJobState = this.setJobState
    }
    getJobState = () => {
        return this.state
    }
    setJobState = (key, val) => {
        this.setStateIfMounted({
            [key]: val
        })
    }
    toggleLoading = () => {
        this.setStateIfMounted({
            tabValue: '-1',
            loading: true
        })
    }
    trimNumber = (float) => {
        return parseFloat((float / 100).toFixed(2))
    }
    prefillState = ({ preferredSuppliers, preferredDestinations, preferredCustomers, signOffEmployees, preferredDrivers }) => {
        if (this.props.job) {
            const job = this.props.job
            console.log('edit job', job)
            const isHaulback = this.props.job.routes.length > 1;
            const prefilledState = {...this.nullState}
            const unusedKeys = []
            Object.keys(job).forEach(jobKey => {
                if (prefilledState.hasOwnProperty(jobKey) && job[jobKey]) {
                    prefilledState[jobKey] = job[jobKey];
                } else {
                    unusedKeys.push(jobKey)
                }
            })
            Object.keys(job.routes[0]).forEach(jobRouteKey => {
                if (prefilledState.hasOwnProperty(jobRouteKey) && job.routes[0][jobRouteKey]) {
                    prefilledState[jobRouteKey] = job.routes[0][jobRouteKey];
                } else {
                    unusedKeys.push({ jobRouteKey })
                }
            })

            // Job properties that can't be set programatically
            const jobStartDate = new Date(job.start_date) || new Date();
            const jobEndDate = new Date(job.end_date) || new Date();
            const biddingEnds = new Date(job.bidding_ends) || new Date();
            const site = this.props.sites.find(site => site.id == job.site.id);
            let hasBatchOrInspectableMaterial = false
            const override = {
                material: "",
                site: site,
                searchSites: site.site_name,
                currency: this.props.currencies.find(currency => currency.code == job.currency_object.code),
                jobType: "standard",
                supplier: preferredSuppliers.find(comp => comp.company_supplier_id == job.company_supplier_company_id) || "",
                destination: preferredDestinations.find(comp => comp.demolition_destination_company_id == job.demolition_destination_company_id) || "",
                customer: preferredCustomers.find(comp => comp.company_customer_id == job.company_customer_company_id) || "",
                start_date: jobStartDate,
                end_date: jobEndDate,
                bidding_ends: biddingEnds,
                opening_time: this.props.helpers.getDateFromTime(job.opening_time),
                closing_time: this.props.helpers.getDateFromTime(job.closing_time),
                record_weight: Boolean(job.transaction_type_id == this.perWeightTransactionTypeID || job.record_weight),
                allow_driver_record_weight: Boolean(job.allow_driver_record_weight),
                amount: this.trimNumber(job.amount),
                checkinType: prefilledState.checkinTypes.find(type => type.value == job.routes[0].checkin_type),
                checkoutType: prefilledState.checkoutTypes.find(type => type.value == job.routes[0].checkout_type),
                displayEquipment: job.equipment.map(equip => equip.name).join(", "),
                specifiedRouteLocation: true,
                selectedMaterials: job.routes[0].material.map(mat => {
                    if (mat.material_id) {
                        // native material
                        if (mat.is_inspectable) {
                            hasBatchOrInspectableMaterial = true
                        }
                        return {
                            ...mat,
                            estimated_weight: mat.estimated_weight || "",
                            estimated_weight_units: mat.estimated_weight_units || "",
                            estimated_volume: mat.estimated_volume || "",
                            estimated_volume_units: mat.estimated_volume_units || "",
                            id: mat.material_id,
                        }
                    } else {
                        // custom material
                        if (mat.is_inspectable) {
                            hasBatchOrInspectableMaterial = true
                        }
                        return {
                            ...mat,
                            estimated_weight: mat.estimated_weight || "",
                            estimated_weight_units: mat.estimated_weight_units || "",
                            estimated_volume: mat.estimated_volume || "",
                            estimated_volume_units: mat.estimated_volume_units || "",
                            id: mat.custom_material_id,
                            name: mat.custom_material_name,
                            description: mat.custom_material_description,
                            number: mat.custom_material_number,
                        }
                    }
                }),
                displayMaterials: job.routes[0].material.map(mat => mat.name || mat.custom_material_name).join(", "),
                hasBatchOrInspectableMaterial: hasBatchOrInspectableMaterial,
                job_tare_weight_change_tolerance: job.job_tare_weight_change_tolerance * 100,

                // These fields can be 0, but the state prefiller prohibits falsey values so override here
                driver_company_pickup_inspection_wait_minutes: job.driver_company_pickup_inspection_wait_minutes,
                driver_company_dropoff_inspection_wait_minutes: job.driver_company_dropoff_inspection_wait_minutes,
                driver_third_party_pickup_inspection_wait_minutes: job.driver_third_party_pickup_inspection_wait_minutes,
                driver_third_party_dropoff_inspection_wait_minutes: job.driver_third_party_dropoff_inspection_wait_minutes,
            }
            if (job.is_four_way) { 
                override.jobType = 'fourway'
                prefilledState.company_supplier_price = this.trimNumber(job.company_supplier_price);
                prefilledState.company_customer_price = this.trimNumber(job.company_customer_price);
            }
            if (job.is_removal_job) { 
                override.jobType = 'unknownDropoff'
            }
            if (job.is_unknown_pickup_job) { 
                override.jobType = 'unknownPickup'
            }
            if (job.is_demolition_job) {
                override.jobType = 'demolition'
                prefilledState.demolition_destination_price = this.trimNumber(job.demolition_destination_price);
                prefilledState.company_customer_price = this.trimNumber(job.company_customer_price);
            }
            if (job.is_waste_manifest_job) { 
                override.jobType = 'waste'
            }
            if (job.advanced_warning_hours && job.rain_out_units) {
                prefilledState.addRainOut = true;
            }
            if (job.requires_sign_off && job.sign_off_team.length) {
                job.sign_off_team.forEach(member => {
                    if (member.sign_off_order == 1) {
                        prefilledState.signOffMemberFirst = signOffEmployees.find(employee => member.sign_off_user_id == employee.user_id)
                    } else if (member.sign_off_order == 2) {
                        prefilledState.signOffMemberSecond = signOffEmployees.find(employee => member.sign_off_user_id == employee.user_id)
                    } else if (member.sign_off_order == 3) {
                        prefilledState.signOffMemberThird = signOffEmployees.find(employee => member.sign_off_user_id == employee.user_id)
                    }
                })
            }
            if (job.noncompete_period && job.noncompete_distance && job.noncompete_distance_units) {
                override.addNoncompete = true;
            }

            if (job.routes[0].address_from) {
                override.pickupAddress = {
                    country: this.props.supportedCountries.find(country => country.id == job.routes[0].address_from.country_id),
                    country_id: job.routes[0].address_from.country_id,
                    address_line_1: job.routes[0].address_from.address_line_1 || "",
                    city: job.routes[0].address_from.city || "",
                    province: job.routes[0].address_from.province || "",
                    postal_code: job.routes[0].address_from.postal_code || "",
                    latitude: job.routes[0].address_from.latitude || "",
                    longitude: job.routes[0].address_from.longitude || "",
                    address_company_name: job.routes[0].address_from.address_company_name || "",
                    address_contact_name: job.routes[0].address_from.address_contact_name || "",
                    address_contact_email: job.routes[0].address_from.address_contact_email || "",
                    address_contact_phone: job.routes[0].address_from.address_contact_phone || "",
                }
            } 
            if (job.routes[0].address_to) {
                override.dropoffAddress = {
                    country: this.props.supportedCountries.find(country => country.id == job.routes[0].address_to.country_id),
                    country_id: job.routes[0].address_to.country_id,
                    address_line_1: job.routes[0].address_to.address_line_1 || "",
                    city: job.routes[0].address_to.city || "",
                    province: job.routes[0].address_to.province || "",
                    postal_code: job.routes[0].address_to.postal_code || "",
                    latitude: job.routes[0].address_to.latitude || "",
                    longitude: job.routes[0].address_to.longitude || "",
                    address_company_name: job.routes[0].address_to.address_company_name || "",
                    address_contact_name: job.routes[0].address_to.address_contact_name || "",
                    address_contact_email: job.routes[0].address_to.address_contact_email || "",
                    address_contact_phone: job.routes[0].address_to.address_contact_phone || "",
                }
            }

            override.pickupSite = job.routes[0]?.pickup_site || null;
            override.dropoffSite = job.routes[0]?.dropoff_site || null;

            if (job.routes[0].pick_up_scale_id || job.routes[0].drop_off_scale_id) {
                override.automated_scale = true;
            }
            if (job.constant_weighmaster_name) {
                override.weighmaster_doesnt_change = true
            }
            if (job.bidding_geo_restriction_distance && job.bidding_geo_restriction_distance_units) {
                override.bidding_geo_restriction = true;
            }
            if (job.routes[0].address_from.location_id) {
                override.pick_up_location_id = job.routes[0].address_from.location_id
            }
            if (job.routes[0].address_to.location_id) {
                override.drop_off_location_id = job.routes[0].address_to.location_id
            }

            // haulback overrides
            if (isHaulback) {
                override.addHaulback = true;
                const haulbackRoute = job.routes[1];

                Object.keys(haulbackRoute).forEach(haulbackRouteKey => {
                    if (prefilledState.hasOwnProperty(`haulback_${haulbackRouteKey}`) && haulbackRoute[haulbackRouteKey]) {
                        prefilledState[`haulback_${haulbackRouteKey}`] = haulbackRoute[haulbackRouteKey];
                    }
                })
                override.haulbackCountry = this.props.supportedCountries.find(country => country.id == haulbackRoute.address_to.country_id);
                override.haulbackAddress = {
                    address_line_1: haulbackRoute.address_to.address_line_1 || "",
                    city: haulbackRoute.address_to.city || "",
                    province: haulbackRoute.address_to.province || "",
                    postal_code: haulbackRoute.address_to.postal_code || "",
                    latitude: haulbackRoute.address_to.latitude || "",
                    longitude: haulbackRoute.address_to.longitude || "",
                }
                override.selectedHaulbackMaterials = haulbackRoute.material.map(mat => {
                    if (mat.material_id) {
                        // native material
                        return {
                            ...mat,
                            estimated_weight: mat.estimated_weight || "",
                            estimated_weight_units: mat.estimated_weight_units || "",
                            estimated_volume: mat.estimated_volume || "",
                            estimated_volume_units: mat.estimated_volume_units || "",
                            id: mat.material_id,
                        }
                    } else {
                        // custom material
                        return {
                            ...mat,
                            estimated_weight: mat.estimated_weight || "",
                            estimated_weight_units: mat.estimated_weight_units || "",
                            estimated_volume: mat.estimated_volume || "",
                            estimated_volume_units: mat.estimated_volume_units || "",
                            id: mat.custom_material_id,
                            name: mat.custom_material_name,
                            description: mat.custom_material_description,
                            number: mat.custom_material_number,
                        }
                    }
                })
                override.haulbackCheckinType = prefilledState.checkinTypes.find(type => type.value == haulbackRoute.checkin_type)
                override.haulbackCheckoutType = prefilledState.checkoutTypes.find(type => type.value == haulbackRoute.checkout_type)
                if (haulbackRoute.pick_up_scale_id || haulbackRoute.drop_off_scale_id) {
                    override.haulback_automated_scale = true;
                }
                // if (job.constant_weighmaster_name) {
                //     override.weighmaster_doesnt_change = true
                // }
            }

            if (job.extended_job_type) {
                override.extended_job_type = this.state.extended_job_types.find(type => type === job.extended_job_type)
                if (job.extended_job_type === 'RMHMA') {
                    override.jobType = 'RMHMA'
                    override.extended_job_type = 'RMHMA'
                    if (job.extended_attribute_values.find(eav => eav.name === 'Specific Hauler IDs')) {
                        override.specificHaulers = job.extended_attribute_values.find(eav => eav.name === 'Specific Hauler IDs').value.map(id => {
                            const preference = preferredDrivers.find(pref => pref.identifier === id)
                            if (preference) {
                                return {
                                    ...preference,
                                    trucksRequired: 0,
                                    award: false,
                                    bidAsCompany: false,
                                }
                            } else {
                                return {
                                    identifier: id,
                                    driver_company_id: id,
                                    driver_company: {
                                        name: ""
                                    },
                                    trucksRequired: 0,
                                    award: false,
                                    bidAsCompany: false,
                                }
                            }
                        })
                    }
                }
            }

            console.log("prefillign", prefilledState, "override", override)
            return {
                ...prefilledState,
                ...override,
                tabValue: '0',
                loading: false
            }
        } else {
            return {...this.nullState}
        }
    }
    componentDidMount = () => {
        this.mounted = true;
        this.getInitialData();
    }
    getInitialData = async () => {
        const token = this.props.token;
        const companyID = this.props.company.id

        await Promise.all([
            this.props.getValidUnits(token),
            this.props.getCurrencies(token),
            this.props.getEquipment(token),
            this.props.getSupportedCountries(),
            this.props.getMaterials(token),
            this.props.getCompanySites(token, companyID),
            this.props.DEPRECATED_getCustomMaterials(token, companyID),
        ])

        const { default_distance_unit, default_volume_unit, default_weight_unit } = this.props.userDefaults;
        const defaultUnits = {
            company_supplier_per_weight_job_units: default_weight_unit || '',
            company_supplier_per_volume_job_units: default_volume_unit || '',
            demolition_destination_per_weight_job_units: default_weight_unit || '',
            demolition_destination_per_volume_job_units: default_volume_unit || '',
            company_customer_per_weight_job_units: default_weight_unit || '',
            company_customer_per_volume_job_units: default_volume_unit || '',
            per_volume_job_units: default_volume_unit || '',
            per_weight_job_units: default_weight_unit || '',
            geo_fence_distance_unit: default_distance_unit || '',
            noncompete_distance_units: default_distance_unit || '',
            haulback_geo_fence_distance_unit: default_distance_unit || '',
        }  

        const newState = {
            preferredSuppliers: [],
            preferredDestinations: [],
            preferredCustomers: [],
            preferredDrivers: [],
            chunkedPreferredDrivers: [],
            signOffEmployees: [],
            tabValue: '0',
            loading: false,
        } 
        
        const preferredSuppliersResponse = await getPreferredSuppliers(token, companyID)
        if (preferredSuppliersResponse?.success && preferredSuppliersResponse.data) {
            newState.preferredSuppliers = preferredSuppliersResponse.data 
        } 
        const preferredDestinationsResponse = await getPreferredDestinations(token, companyID)
        if (preferredDestinationsResponse?.success && preferredDestinationsResponse.data) {
            newState.preferredDestinations = preferredDestinationsResponse.data 
        } 
        const preferredCustomersResponse = await getPreferredCustomers(token, companyID)
        if (preferredCustomersResponse?.success && preferredCustomersResponse.data) {
            newState.preferredCustomers = preferredCustomersResponse.data 
        } 
        const preferredDriversResponse = await getPreferredDrivers(token, companyID)
        if (preferredDriversResponse?.data) {
            newState.preferredDrivers = preferredDriversResponse.data
            newState.chunkedPreferredDrivers = this.props.helpers.chunk(preferredDriversResponse.data, 2)
        } 
        const authorizedSignOffMembersResponse = await getAuthorizedSignOffMembers(token, companyID)
        if (authorizedSignOffMembersResponse?.data) {
            newState.signOffEmployees = authorizedSignOffMembersResponse.data 
        } 

        const eTicketTargetsWithDetailsResponse = await getETicketTargetsWithDetails(token, companyID).catch(err => console.log(err))
        if (eTicketTargetsWithDetailsResponse?.success && eTicketTargetsWithDetailsResponse.data) {
            newState.possibleTargets = eTicketTargetsWithDetailsResponse.data
            newState.possibleTargetNames = Object.keys(newState.possibleTargets)
        } 
        if (this.props.job) {
            const usersWhoCanViewResponse = await getUsersWhoCanView(this.props.token, this.props.job.id)
            if (usersWhoCanViewResponse?.success) {
                newState.emails = usersWhoCanViewResponse.data.map(external => {
                    external.email = external.user.email
                    return external
                })
            }

            newState.eticket_targets = {}
            const jobETicketTargetsResponse = await getETicketTargetsForJob(this.props.token, this.props.job.id)
            if (jobETicketTargetsResponse?.success) {
                newState.initialETicketTargets = jobETicketTargetsResponse.data
                jobETicketTargetsResponse.data.forEach(jobETicketTarget => {
                    newState.eticket_targets[jobETicketTarget.target] = jobETicketTarget.parameters
                })
            }
            if (this.props.job.privacy_status == 'whitelist') {
                await getEntireJobWhitelist(this.props.token, this.props.company.id, this.props.job.id).then(res => {
                    if (res.success) {
                        const companyIDs = res.data.map(whitelisting => whitelisting.whitelisted_company_id)
                        newState.whitelistCompanyIDs = companyIDs
                        newState.selectedWhitelistCompanyIDs = companyIDs
                    }
                })
            }

        }

        let finalState = {}
        console.log("new state prefills", newState)
        if (this.props.job) {
            finalState = {
                ...this.prefillState(newState),
                ...newState,
            }
        } else {
            finalState = {
                ...newState
            }
        }
        Object.keys(defaultUnits).forEach(defaultUnitsKey => {
            if (!finalState[defaultUnitsKey]) {
                finalState[defaultUnitsKey] = defaultUnits[defaultUnitsKey];
            }
        })
        console.log("final state", finalState)
        this.setStateIfMounted(finalState)
        

    }
    componentWillUnmount = () => {
        this.mounted = false;
    }
    componentDidUpdate = (prevProps, prevState) => {
        // if (prevState.site != this.state.site && this.state.site && this.state.site.has_automated_batching) {
        //     this.setStateIfMounted({
        //         // uses_external_weight: false?
        //         has_automated_batching: this.state.site.has_automated_batching,
        //         transaction_type_id: 2,
        //     })
        // }

        if (prevState.jobType != this.state.jobType) {
            this.handleJobTypeChange()
            // this.setStateIfMounted({ extended_job_type: '' })
        }

        // todo - prefill extended job type if it's not getting set
        
        if (prevState.extended_job_type != this.state.extended_job_type) {
            if (!this.state.extended_job_type) {
                this.resetDefaults()
            }
            if (this.state.extended_job_type == 'RMHMA') {
                console.log("SETTING RMHMA DEFAULTS")
                this.setRMHMADefaults()
            }
        }
        if (prevState.displayCoordinates != this.state.displayCoordinates && !this.state.displayCoordinates) {
            this.connectGoogleAutocomplete()
        }
        if (prevState.displayHaulbackCoordinates != this.state.displayHaulbackCoordinates && !this.state.displayHaulbackCoordinates) {
            this.connectGoogleAutocomplete(true)
        }
        if (
            (prevState.secondaryAddress.latitude != this.state.secondaryAddress.latitude || prevState.secondaryAddress.longitude != this.state.secondaryAddress.longitude)
            && (this.state.secondaryAddress.latitude && this.state.secondaryAddress.longitude)
        ) {
            this.updateMarkerPosition()
            if (this.state.displayCoordinates) {
                this.geocodeNewPosition({ lat: parseFloat(this.state.secondaryAddress.latitude), lng: parseFloat(this.state.secondaryAddress.longitude) })
            }
        }
        if (
            (prevState.haulbackAddress.latitude != this.state.haulbackAddress.latitude || prevState.haulbackAddress.longitude != this.state.haulbackAddress.longitude)
            && (this.state.haulbackAddress.latitude && this.state.haulbackAddress.longitude)
        ) {
            this.updateMarkerPosition(true)
            if (this.state.displayHaulbackCoordinates) {
                this.geocodeNewPosition({ lat: parseFloat(this.state.haulbackAddress.latitude), lng: parseFloat(this.state.haulbackAddress.longitude) }, true)
            }
        }

        if (prevState.truck_weigh_location != this.state.truck_weigh_location && this.state.truck_weigh_location && this.state.automated_scale) {
            if (this.state.truck_weigh_location == 'P') {
                this.getScalesAtLocation(this.state.pickupAddress.latitude, this.state.pickupAddress.longitude)
            } else {
                this.getScalesAtLocation(this.state.dropoffAddress.latitude, this.state.dropoffAddress.longitude)
            }
        }
        if (prevState.haulback_truck_weigh_location != this.state.haulback_truck_weigh_location && this.state.haulback_truck_weigh_location) {
            if (this.state.haulback_truck_weigh_location == 'P') {
                this.getScalesAtLocation(this.state.dropoffAddress.latitude, this.state.dropoffAddress.longitude, true)
            } else {
                this.getScalesAtLocation(this.state.haulbackAddress.latitude, this.state.haulbackAddress.longitude, true)
            }
        }
        
        if (prevState.country != this.state.country && this.state.country && this.state.country.id) {
            this.updateAutocompleteRestriction()
        }
        if (prevState.haulbackCountry != this.state.haulbackCountry && this.state.haulbackCountry && this.state.haulbackCountry.id) {
            this.updateAutocompleteRestriction(true)
        }
        if (prevState.whitelistFilter != this.state.whitelistFilter) {
            this.filterWhitelist()
        }
        if (prevState.equipmentType != this.state.equipmentType && this.state.equipmentType) {
            this.addEquipment()
        }
        if (prevState.material != this.state.material && Boolean(this.state.material)) {
            this.addMaterial()
        }
        if (prevState.customMaterial != this.state.customMaterial && Boolean(this.state.customMaterial)) {
            this.addMaterial()
        }

        // Per weight jobs should always have record_weight = true and hide the field
        if (prevState.transaction_type_id != this.state.transaction_type_id && this.state.transaction_type_id == this.perWeightTransactionTypeID) {
            this.setStateIfMounted({
                record_weight: true
            })
        }
    }
    setStateIfMounted = (state, callback) => {
        if (this.mounted) {
            this.setState(state, callback)
        }
    }
    handleChange = (field, type, singleNestedOptions) => {
        if (!singleNestedOptions) {
            if (type) {
                if (type == 'boolean') {
                    return event => this.setStateIfMounted({ [field]: event.target.checked })
                } else if (type == 'number') {
                    return event => this.setStateIfMounted({ [field]: parseFloat(event.target.value) || "" })
                } else if (type == 'date') {
                    return date => this.setStateIfMounted({ [field]: date })
                } else if (type == 'time') {
                    return time => this.setStateIfMounted({ [field]: time })
                } else if (type == 'radio-boolean') {
                    return event => this.setStateIfMounted({ [field]: event.target.value == 'true' ? true : false })
                }
            }
            return event => this.setStateIfMounted({ [field]: event.target.value });
        } else if (singleNestedOptions) {
            if (type) {
                if (type == 'boolean') {
                    return event => this.setStateIfMounted({
                        ...this.state,
                        [singleNestedOptions.parent]: {
                            ...this.state[singleNestedOptions.parent],
                            [singleNestedOptions.child]: event.target.checked 
                        }
                    })
                } else if (type == 'number') {
                    return event => this.setStateIfMounted({ 
                        ...this.state,
                        [singleNestedOptions.parent]: {
                            ...this.state[singleNestedOptions.parent],
                            [singleNestedOptions.child]:  parseFloat(event.target.value) || ""
                        }
                    })
                } else if (type == 'date') {
                    return date => {
                        this.setStateIfMounted({ 
                            ...this.state,
                            [singleNestedOptions.parent]: {
                                ...this.state[singleNestedOptions.parent],
                                [singleNestedOptions.child]: date
                            }
                        })
                    }
                } else if (type == 'time') {
                    return time => {
                        this.setStateIfMounted({ 
                            ...this.state,
                            [singleNestedOptions.parent]: {
                                ...this.state[singleNestedOptions.parent],
                                [singleNestedOptions.child]: time
                            }
                        })
                    }
                }
            }
            return event => this.setStateIfMounted({ 
                ...this.state,
                [singleNestedOptions.parent]: {
                    ...this.state[singleNestedOptions.parent],
                    [singleNestedOptions.child]: event.target.value 
                }
            });
        }
    }
    handleJobTypeChange = () => {
        switch (this.state.jobType) {
            case "standard": {
                this.setStateIfMounted({
                    is_four_way: false,
                    is_demolition_job: false,
                    is_removal_job: false,
                    is_unknown_pickup_job: false,
                    is_waste_manifest_job: false,
                    requiresPickupAddress: true,
                    requiresDropoffAddress: true,
                    extended_job_type: '',
                })
                break;
            }
            case "RMHMA": {
                this.setStateIfMounted({
                    is_four_way: false,
                    is_demolition_job: false,
                    is_removal_job: false,
                    is_unknown_pickup_job: false,
                    is_waste_manifest_job: false,
                    requiresPickupAddress: true,
                    requiresDropoffAddress: true,
                    extended_job_type: 'RMHMA',
                })
                break;
            }
            case "fourWay": {
                this.setStateIfMounted({
                    is_four_way: true,
                    is_demolition_job: false,
                    is_removal_job: false,
                    is_unknown_pickup_job: false,
                    is_waste_manifest_job: false,
                    requiresPickupAddress: true,
                    requiresDropoffAddress: true,
                    extended_job_type: '',
                })
                break;
            }
            case "unknownDropoff": {
                this.setStateIfMounted({
                    is_four_way: false,
                    is_demolition_job: false,
                    is_removal_job: true,
                    is_unknown_pickup_job: false,
                    is_waste_manifest_job: false,
                    truck_weigh_location: "P",
                    specifiedRouteLocation: true,
                    isSitePickupLocation: true,
                    requiresPickupAddress: true,
                    requiresDropoffAddress: false,
                    extended_job_type: '',
                })
                break;
            }
            case "unknownPickup": {
                this.setStateIfMounted({
                    is_four_way: false,
                    is_demolition_job: false,
                    is_removal_job: false,
                    is_unknown_pickup_job: true,
                    is_waste_manifest_job: false,
                    truck_weigh_location: "P",
                    specifiedRouteLocation: true,
                    isSitePickupLocation: true,
                    requiresPickupAddress: false,
                    requiresDropoffAddress: true,
                    extended_job_type: '',
                })
                break;
            }
            case "demolition": {
                this.setStateIfMounted({
                    is_four_way: false,
                    is_demolition_job: true,
                    is_removal_job: false,
                    is_unknown_pickup_job: false,
                    is_waste_manifest_job: false,
                    requiresPickupAddress: true,
                    requiresDropoffAddress: true,
                    extended_job_type: '',
                })
                break;
            }
            case "waste": {
                this.setStateIfMounted({
                    is_four_way: false,
                    is_demolition_job: false,
                    is_removal_job: false,
                    is_unknown_pickup_job: false,
                    is_waste_manifest_job: true,
                    requiresPickupAddress: true,
                    requiresDropoffAddress: true,
                    checkoutType: this.state.checkoutTypes.find(type => type.value == 'signature'),
                    is_driver_checkout_signature_required: true,
                    extended_job_type: '',
                })
                break;
            }
        }
    }
    resetDefaults = () => {
        this.setStateIfMounted({
            hiddenFields: [],
            hasAdvancedSettings: {},
            showAdvancedSettings: {},
            canMakePublic: true
        })
    }
    setRMHMADefaults = () => {
        console.log("setting rmhma defaults");
        this.setStateIfMounted({
            currency: this.props.currencies.find(currency => currency.code == 'USD'),
            transaction_type_id: 4,
            checkinType: this.state.checkinTypes.find(type => type.value == 'self-check'),
            checkoutType: this.state.checkoutTypes.find(type => type.value == 'self-check'),
            invoices_to_be_paid_through_truckpay: false,
            track_driver_return_delivery_trip: true,
            country: this.props.supportedCountries.find(country => country.code == 'US'),
            privacy_status: 'exclusive',
            bid_amount_status: 'fixed',
            equipment: this.state.equipment.length ? [this.state.equipment[0]] : [],
            addRainOut: false,
            addHaulback: false,
            is_scan_picture_required: false,
            scan_fee_payer: 'company',
            invoice_due_frequency: 'monthly',
            payment_due_frequency: 'monthly',
            record_weight: true,
            allow_driver_record_weight: false,
            auto_award: false,
            requires_sign_off: false,
            weighmaster_doesnt_change: false,
            constant_weighmaster_name: '',
            constant_weighmaster_license_number: "",
            bol_start: 1,
            auto_bol: true,
            select_material_at_checkin: false,
            driver_can_check_in_with_active_deliveries: true,
            allow_job_tare_weights: true,
            job_tare_weight_change_tolerance: 10,
            job_tare_weight_expiration_days: 30,
            ticket_number_starts_at: 1,
            bidding_geo_restriction: false,
            bidding_geo_restriction_distance: 100,
            bidding_geo_restriction_distance_units: 'mi',
            addNoncompete: true,
            noncompete_period: 30,
            noncompete_distance_units: 'mi',
            noncompete_distance: 300,
            is_geo_fenced: false,
            pick_up_geo_fence_distance: 0.25,
            drop_off_geo_fence_distance: 0.25,
            geo_fence_distance_unit: 'mi',
            auto_checkout: true,
            truck_weigh_location: 'P',
            automated_scale: false,
            track_driver_destination: true,
            hasAdvancedSettings: {
                1: true, // job basics
                2: true, // job details
                3: true, // route details
                4: false, // haulback
            },
            hiddenFields: [
                'currency', 
                'transaction_type_id',
                'invoices_to_be_paid_through_truckpay',
                'country',
                'bid_amount_status',
                'addRainOut',
                'scan_fee_payer',
                'invoice_due_frequency',
                'payment_due_frequency',
                'record_weight',
                'auto_award',
                'requires_sign_off',
                'weighmaster_doesnt_change',
                'bol_start',
                'auto_bol',
                'allow_job_tare_weights',
                'job_tare_weight_change_tolerance',
                'job_tare_weight_expiration_days',
                'truck_weigh_location',

                // resettable fields
                'checkin_type',
                'checkout_type',
                'auto_checkout',
                'track_driver_return_delivery_trip',
                'privacy_status',
                'allow_driver_record_weight',
                'select_material_at_checkin',
                'driver_can_check_in_with_active_deliveries',
                'ticket_number_starts_at',
                'bidding_geo_restriction',
                'bidding_geo_restriction_distance',
                'bidding_geo_restriction_distance_units',
                'addNoncompete', 
                'noncompete_period',
                'noncompete_distance_units', 
                'noncompete_distance', 
                'is_geo_fenced',
                'pick_up_geo_fence_distance',
                'drop_off_geo_fence_distance',
                'geo_fence_distance_unit',
                'is_scan_picture_required',
                'track_driver_destination',
                'estimated_volume',
                'estimated_volume_units',
            ],
            canMakePublic: false
        })
    }
    nextPage = (event) => {
        event.preventDefault();
        let page = parseInt(this.state.tabValue)

        if (page == this.jobTypePage) {
            if (Boolean(this.props.type == 'create' || this.props.type == 'duplicate') && this.state.jobType === 'RMHMA' && !this.state.specificHaulers.length) {
                // this.props.popAlert('error', 'Select Haulers', 'You must select at least one hauler before you continue')
                // return;
            }
        } else if (page == this.eTicketTargetsPage) {

        } else if (page == this.jobDetailsPage) {
            if (!this.state.equipment.length) {
                this.props.popAlert('error', this.props.t('vehicleTranslations.errors.noEquipment'), this.props.t('vehicleTranslations.errors.noEquipmentMessage'))
                if (this.equipmentInput.current) {
                    this.equipmentInput.current.focus();
                }
                return;
            }
        } else if (page == this.routeDetailsPage) {
            if (!this.state.selectedMaterials.length) {
                this.props.popAlert('error', this.props.t('materialTranslations.errors.noMaterial'), this.props.t('materialTranslations.errors.noMaterialMessage'))
                if (this.materialInput.current) {
                    this.materialInput.current.focus();
                }
                return;
            }
            if (!this.state.selectedMaterials.every(mat => (mat.estimated_weight && mat.estimated_weight_units) || (mat.estimated_volume && mat.estimated_volume_units))) {
                this.props.popAlert('error', this.props.t('error'), this.props.t('materialTranslations.errors.needMaterialEstimation'))
                return
            }
            if (this.state.requiresPickupAddress && !(
                this.state.pickupAddress.address_line_1 
                || 
                (this.state.pickupAddress.latitude && this.state.pickupAddress.longitude)
            )) {
                this.props.popAlert('error', this.props.t('error'), "Need to specify the pickup location")
                return
            }
            if (this.state.requiresDropoffAddress && !(
                this.state.dropoffAddress.address_line_1 
                || 
                (this.state.dropoffAddress.latitude && this.state.dropoffAddress.longitude)
            )) {
                this.props.popAlert('error', this.props.t('error'), "Need to specify the dropoff location")
                return
            }
            if (!this.state.pickupSite?.id && !this.state.dropoffSite?.id) {
                this.props.popAlert('error', this.props.t('error'), "Pick up or Drop off must be a registered site")
                return
            }
        } else if (page == this.haulbackDetailsPage) {
            if (this.state.addHaulback) {
                if (!this.state.selectedHaulbackMaterials.length) {
                    this.props.popAlert('error', this.props.t('materialTranslations.errors.noMaterial'), this.props.t('materialTranslations.errors.noMaterialMessage'))
                    if (this.haulbackMaterialInput.current) {
                        this.haulbackMaterialInput.current.focus();
                    }
                    return;
                }
                if (!this.state.selectedHaulbackMaterials.every(mat => (mat.estimated_weight && mat.estimated_weight_units) || (mat.estimated_volume && mat.estimated_volume_units))) {
                    this.props.popAlert('error', this.props.t('error'), this.props.t('materialTranslations.errors.needMaterialEstimation'))
                    return
                }
            }
        }
        if (!this.tabAvailability[page + 1]) {

            // If all is good
            this.tabAvailability[page + 1] = true;
            this.setStateIfMounted({ tabValue: `${page + 1}` })
        } else {
            this.setStateIfMounted({ tabValue: `${page + 1}` })
        }
        if (!this.googleAutocompleteWidget) {
            const possibleContainers = document.getElementsByClassName('pac-container');
            if (possibleContainers[0]) {
                this.googleAutocompleteWidget = possibleContainers[0];
                this.googleAutocompleteWidget.style.zIndex = "1301";
            }
        } else {
            this.googleAutocompleteWidget.style.zIndex = "1301";
        }
    }
    prevPage = () => {
        const page = parseInt(this.state.tabValue)
        if (page >= 1) {
            this.setStateIfMounted({ tabValue: `${page - 1}` })
        }
        if (!this.googleAutocompleteWidget) {
            const possibleContainers = document.getElementsByClassName('pac-container');
            if (possibleContainers[0]) {
                this.googleAutocompleteWidget = possibleContainers[0];
                this.googleAutocompleteWidget.style.zIndex = "1301";
            }
        } else {
            this.googleAutocompleteWidget.style.zIndex = "1301";
        }
    }
    refreshMaterials = () => {
        this.props.DEPRECATED_getCustomMaterials(this.props.token, this.props.company.id)
    }
    addEquipment = () => {
        if (this.state.equipmentType) {
            if (
                this.state.equipment.find(equipment => equipment.id == this.state.equipmentType.id)
                || (this.state.jobType === 'RMHMA' && this.state.equipment.length >= 1)
            ) {
                this.setStateIfMounted({
                    equipmentType: "",
                    searchEquipment: "",
                })
                return;
            }
            const newEquipmentList = [...this.state.equipment, this.state.equipmentType]
            const newEquipmentListDisplay = newEquipmentList.map(equipment => equipment.name).join(", ")
            this.setStateIfMounted({ 
                equipment: newEquipmentList,
                displayEquipment: newEquipmentListDisplay,
                equipmentType: "",
                searchEquipment: "",
            })
        }
    }
    removeEquipment = (equipment) => {
        if (this.state.equipment.find(equip => equip.id == equipment.id)) {
            const newEquipmentList = [...this.state.equipment].filter(equip => equip.id != equipment.id)
            const newEquipmentListDisplay = newEquipmentList.map(equipment => equipment.name).join(", ")
            this.setStateIfMounted({
                equipment: newEquipmentList,
                displayEquipment: newEquipmentListDisplay
            })
        }
    }
    getBidSpacing = () => {
        if ((this.state.transaction_type_id == 2 || this.state.transaction_type_id == 4) && this.state.bid_amount_status == 'fixed' && this.state.privacy_status != 'private') {
            return 4;
        } else if ((this.state.transaction_type_id == 2 || this.state.transaction_type_id == 4) || this.state.bid_amount_status == 'fixed' && this.state.privacy_status != 'private') {
            return 6
        }
        return 12
    }
    getAvailableSignOffEmployees = (order) => {
        let availableEmployees = [...this.state.signOffEmployees];
        if (this.state.signOffMemberFirst && order != 1) {
            availableEmployees = availableEmployees.filter(employee => employee.id != this.state.signOffMemberFirst.id)
        }
        if (this.state.signOffMemberSecond && order != 2) {
            availableEmployees = availableEmployees.filter(employee => employee.id != this.state.signOffMemberSecond.id)
        }
        if (this.state.signOffMemberThird && order != 3) {
            availableEmployees = availableEmployees.filter(employee => employee.id != this.state.signOffMemberThird.id)
        }
        return availableEmployees;
    }
    addEmail = () => {
        if (this.props.job && this.props.type === "edit") {
            this.props.popAlert('info', this.props.t('loading'), this.props.t('jobTranslations.loading.addExternal'))
            addUserWhoCanView(this.props.token, this.props.job.id, this.state.email).then(res => {
                if (res.success) {
                    this.props.popAlert('success', this.props.t('success'), this.props.t('jobTranslations.success.addExternal'))
                    const emails = [...this.state.emails, { ...res.data[0], email: this.state.email }];
                    this.setStateIfMounted({ emails: emails, email: "" })
                } else {
                    this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res.error))
                }
            })
        } else {
            if (this.state.email && !this.state.emails.find(email => email.email == this.state.email)) {
                const emails = [...this.state.emails, {email: this.state.email}];
                this.setStateIfMounted({ emails: emails, email: "" })
            }
        }
    }
    removeEmail = email => {
        if (this.props.job && this.props.type === "edit") {
            this.props.popAlert('info', this.props.t('loading'), this.props.t('jobTranslations.loading.removeExternal'))
            removeUserWhoCanView(this.props.token, this.props.job.id, email.user_id).then(res => {
                if (res.success) {
                    this.props.popAlert('success', this.props.t('success'), this.props.t('jobTranslations.success.removeExternal'))
                    const emails = this.state.emails.filter(em => em.email != email.email);
                    this.setStateIfMounted({ emails: emails, email: "" })
                } else {
                    this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res.error))
                }
            })
        } else {
            const emails = [...this.state.emails].filter(em => em.email != email.email);
            this.setStateIfMounted({ emails: emails })
        }
    }
    handleApiLoaded = (map, maps) => {
        this.map = map;
        this.maps = maps;
        this.connectGoogleAutocomplete()
        if (this.props.job) {
            this.updateMarkerPosition()
        }
    }
    handleHaulbackApiLoaded = (map, maps) => {
        console.log("haulback map loaded")
        this.haulbackMap = map;
        this.haulbackMaps = maps;
        this.connectGoogleAutocomplete(true)
        if (this.props.job) {
            this.updateMarkerPosition(true)
        }
    }
    getMapOptions = maps => {
        return {
            fullscreenControl: true,
            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.ROADMAP,
            scaleControl: true,
            scrollwheel: false,
            streetViewControl: true
        };
    };
    updateAutocompleteRestriction = (forHaulback) => {
        if (!forHaulback) {
            const selectedCountry = this.props.supportedCountries.find(country => country.id == this.state.country.id)
            if (selectedCountry && this.addressAutocomplete) {
                this.addressAutocomplete.setComponentRestrictions({
                    country: selectedCountry.code
                })
            }
        } else {
            const selectedCountry = this.props.supportedCountries.find(country => country.id == this.state.haulbackCountry.id)
            if (selectedCountry && this.haulbackAddressAutocomplete) {
                this.haulbackAddressAutocomplete.setComponentRestrictions({
                    country: selectedCountry.code
                })
            }
        }
    }
    connectGoogleAutocomplete = (forHaulback) => {
        console.log('connecting google autocomplete', forHaulback)
        if (!forHaulback) {
            if (this.addressInput.current && this.maps && this.maps.places) {
                // if (!this.addressAutocomplete) {
                    this.addressAutocomplete = new this.maps.places.Autocomplete(this.addressInput.current)
                    this.autoCompleteListener = this.addressAutocomplete.addListener('place_changed', () => {
                        const selection = this.addressAutocomplete.getPlace()
                        if (selection) {
                            this.handleAddressSelect(selection.address_components, selection.geometry.location)
                        }
                    })
                // }
            }
        } else {
            if (this.haulbackAddressInput.current && this.haulbackMaps && this.haulbackMaps.places) {
                // if (!this.haulbackAddressAutocomplete) {
                    this.haulbackAddressAutocomplete = new this.haulbackMaps.places.Autocomplete(this.haulbackAddressInput.current)
                    this.haulbackAutoCompleteListener = this.haulbackAddressAutocomplete.addListener('place_changed', () => {
                        const selection = this.haulbackAddressAutocomplete.getPlace()
                        if (selection) {
                            this.handleAddressSelect(selection.address_components, selection.geometry.location, true)
                        }
                    })
                // }
            }
        }
    }
    handleAddressSelect = (addressComponents, coordinates, forHaulback) => {
        if (!forHaulback) {
            const parsedAddress = this.props.helpers.parseGoogleAddressComponents(addressComponents, true)
            this.setStateIfMounted({
                secondaryAddress: {
                    ...this.state.secondaryAddress,
                    ...parsedAddress,
                    latitude: coordinates.lat(),
                    longitude: coordinates.lng(),
                }
            })
        } else {
            const parsedAddress = this.props.helpers.parseGoogleAddressComponents(addressComponents, true)
            this.setStateIfMounted({
                haulbackAddress: {
                    ...this.state.haulbackAddress,
                    ...parsedAddress,
                    latitude: coordinates.lat(),
                    longitude: coordinates.lng(),
                }
            })
        }
    }
    handleMapClick = ({ x, y, lat, lng, event }) => {
        const newPosition = new this.maps.LatLng(lat, lng)
        if (this.map && this.maps) {
            this.geocodeNewPosition(newPosition, true)
        }
    }
    handleHaulbackMapClick = ({ x, y, lat, lng, event }) => {
        const newPosition = new this.haulbackMaps.LatLng(lat, lng)
        if (this.haulbackMap && this.haulbackMaps) {
            this.geocodeNewPosition(newPosition, true, true)
        }
    }
    geocodeNewPosition = (latLng, resetCoords, forHaulback) => {
        if (!forHaulback) {
            console.log("geocoding new position")
            const geocoder = new this.maps.Geocoder();
            geocoder.geocode({ latLng }, (results, status) => {
                if (status == this.maps.GeocoderStatus.OK && results.length) {
                    const result = results[0];
                    const parsedAddress = this.props.helpers.parseGoogleAddressComponents(result.address_components, true)
                    const newState = {
                        ...this.state.secondaryAddress,
                        ...parsedAddress,
                    }
                    if (resetCoords) {
                        newState.latitude = result.geometry.location.lat()
                        newState.longitude = result.geometry.location.lng()
                    }
                    this.setStateIfMounted({
                        secondaryAddress: {
                            ...newState
                        }
                    })
                }
            })
        } else {
            console.log("geocoding new position")
            const geocoder = new this.haulbackMaps.Geocoder();
            geocoder.geocode({ latLng }, (results, status) => {
                if (status == this.maps.GeocoderStatus.OK && results.length) {
                    const result = results[0];
                    const parsedAddress = this.props.helpers.parseGoogleAddressComponents(result.address_components, true)
                    const newState = {
                        ...this.state.haulbackAddress,
                        ...parsedAddress,
                    }
                    if (resetCoords) {
                        newState.latitude = result.geometry.location.lat()
                        newState.longitude = result.geometry.location.lng()
                    }
                    this.setStateIfMounted({
                        haulbackAddress: {
                            ...newState
                        }
                    })
                } else {

                }
            })
        }
    }
    updateMarkerPosition = (forHaulback) => {
        if (!forHaulback) {
            if (this.map && this.maps) {
                const newPosition = new this.maps.LatLng(this.state.secondaryAddress.latitude, this.state.secondaryAddress.longitude)
                if (!this.marker) {
                    this.marker = new this.maps.Marker({
                        position: newPosition,
                        map: this.map,
                        animation: this.maps.Animation.DROP
                    })
                } else {
                    this.marker.setPosition(newPosition);
                }
                this.map.setCenter(newPosition);
            }
        } else {
            if (this.haulbackMap && this.haulbackMaps) {
                const newPosition = new this.haulbackMaps.LatLng(this.state.haulbackAddress.latitude, this.state.haulbackAddress.longitude)
                if (!this.haulbackMarker) {
                    this.haulbackMarker = new this.haulbackMaps.Marker({
                        position: newPosition,
                        map: this.haulbackMap,
                        animation: this.haulbackMaps.Animation.DROP
                    })
                } else {
                    this.haulbackMarker.setPosition(newPosition);
                }
                this.haulbackMap.setCenter(newPosition);
            }

        }

    }
    addMaterial = (forHaulback, specificCustomMaterials) => {
        let defaults = {}
        if (this.props.userDefaults) {
            defaults = this.props.userDefaults
        }

        if (!forHaulback) {
            if (this.state.jobType == 'waste' && this.state.selectedMaterials.length) {
                this.props.popAlert('error', this.props.t('error'), "Only one material may be added to a hazardous waste job")
                return;
            }
            if (specificCustomMaterials) {
                console.log('adding custom materials', specificCustomMaterials)
                const uniqueCustomMaterials = specificCustomMaterials.filter(cm => !this.state.selectedMaterials.find(selectedMaterial => selectedMaterial.is_custom_material === true && selectedMaterial.id === cm.id))
                console.log('unique custom materials', uniqueCustomMaterials)
                const newMaterials = [...this.state.selectedMaterials, ...uniqueCustomMaterials.map(cm => ({
                    ...cm,
                    is_custom_material: true,
                    estimated_weight: "",
                    estimated_weight_units: cm.mix_design?.weight_units || defaults.default_weight_unit || "",
                    estimated_volume: "",
                    estimated_volume_units: cm.mix_design?.volume_units || defaults.default_volume_unit || "",
                }))]
                console.log("new selected", newMaterials);
                let hasBatchOrInspectableMaterial = false;
                const newMaterialDisplay = newMaterials.map(mat => {
                    if (!hasBatchOrInspectableMaterial) {
                        hasBatchOrInspectableMaterial = mat.is_inspectable
                    }
                    return mat.name
                }).join(", ")
                this.setStateIfMounted({
                    searchCustomMaterial: "",
                    customMaterial: "",
                    selectedMaterials: newMaterials,
                    displayMaterials: newMaterialDisplay,
                    hasBatchOrInspectableMaterial,
                })
            } else if (this.state.material && !this.state.showCustomMaterials) {
                if (this.state.selectedMaterials.find(mat => !mat.is_custom_material && mat.id == this.state.material.id)) {
                    this.props.popAlert('info', "Info", "Material already added")
                    this.setStateIfMounted({
                        material: "",
                        searchMaterial: "",
                    })
                    return
                }
                const newMaterials = [...this.state.selectedMaterials, { 
                    ...this.state.material, 
                    is_custom_material: false,
                    estimated_weight: "",
                    estimated_weight_units: defaults.default_weight_unit || "",
                    estimated_volume: "",
                    estimated_volume_units: defaults.default_volume_unit || "",
                }]
                let hasBatchOrInspectableMaterial = false;
                const newMaterialDisplay = newMaterials.map(mat => {
                    if (!hasBatchOrInspectableMaterial) {
                        hasBatchOrInspectableMaterial = mat.is_inspectable
                    }
                    return mat.name
                }).join(", ")
                this.setStateIfMounted({
                    material: "",
                    searchMaterial: "",
                    selectedMaterials: newMaterials,
                    displayMaterials: newMaterialDisplay,
                    hasBatchOrInspectableMaterial,
                })
            } else if (this.state.customMaterial && this.state.showCustomMaterials) {
                console.log('adding custom material', this.state.customMaterial)
                if (this.state.selectedMaterials.find(mat => mat.is_custom_material && mat.id == this.state.customMaterial.id)) {
                    this.props.popAlert('info', "Info", "Material already added")
                    this.setStateIfMounted({
                        customMaterial: "",
                        searchCustomMaterial: "",
                    })
                    return
                }
                const newMaterials = [...this.state.selectedMaterials, { 
                    ...this.state.customMaterial, 
                    is_custom_material: true,
                    estimated_weight: "",
                    estimated_weight_units: defaults.default_weight_unit || "",
                    estimated_volume: "",
                    estimated_volume_units: defaults.default_volume_unit || "",
                }]
                let hasBatchOrInspectableMaterial = false;
                const newMaterialDisplay = newMaterials.map(mat => {
                    if (!hasBatchOrInspectableMaterial) {
                        hasBatchOrInspectableMaterial = mat.is_inspectable
                    }
                    return mat.name
                }).join(", ")
                this.setStateIfMounted({
                    customMaterial: "",
                    searchCustomMaterial: "",
                    selectedMaterials: newMaterials,
                    displayMaterials: newMaterialDisplay,
                    hasBatchOrInspectableMaterial,
                })
            }
        } else {
            if (this.state.haulbackMaterial && !this.state.haulbackShowCustomMaterials && !this.state.selectedHaulbackMaterials.find(mat => !mat.is_custom_material && mat.id == this.state.haulbackMaterial.id)) {
                const newMaterials = [...this.state.selectedHaulbackMaterials, {
                    ...this.state.haulbackMaterial,
                    is_custom_material: false,
                    estimated_weight: "",
                    estimated_weight_units: defaults.default_weight_unit || "",
                    estimated_volume: "",
                    estimated_volume_units: defaults.default_volume_unit || "",
                }]
                const newMaterialDisplay = newMaterials.map(mat => mat.name).join(", ")
                this.setStateIfMounted({
                    haulbackMaterial: "",
                    selectedHaulbackMaterials: newMaterials,
                    displayHaulbackMaterials: newMaterialDisplay,
                })
            } else if (this.state.haulbackCustomMaterial && this.state.haulbackShowCustomMaterials && !this.state.selectedHaulbackMaterials.find(mat => mat.is_custom_material && mat.id == this.state.haulbackCustomMaterial.id)) {
                const newMaterials = [...this.state.selectedHaulbackMaterials, {
                    ...this.state.haulbackCustomMaterial,
                    is_custom_material: true,
                    estimated_weight: "",
                    estimated_weight_units: defaults.default_weight_unit || "",
                    estimated_volume: "",
                    estimated_volume_units: defaults.default_volume_unit || "",
                }]
                const newMaterialDisplay = newMaterials.map(mat => mat.name).join(", ")
                this.setStateIfMounted({
                    haulbackCustomMaterial: "",
                    selectedHaulbackMaterials: newMaterials,
                    displayHaulbackMaterials: newMaterialDisplay
                })
            }
        }
    } 
    removeMaterial = (material, forHaulback) => {
        if (!forHaulback) {
            const newMaterials = [...this.state.selectedMaterials].filter(mat => {
                if (material.is_custom_material) {
                    if (mat.is_custom_material) {
                        return mat.id != material.id
                    } else {
                        return true;
                    }
                } else {
                    if (!mat.is_custom_material) {
                        return mat.id != material.id
                    } else {
                        return true;
                    }
                }
            })
            const newMaterialDisplay = newMaterials.map(mat => mat.name).join(", ")
            this.setStateIfMounted({
                selectedMaterials: newMaterials,
                displayMaterials: newMaterialDisplay
            })
        } else {
            const newMaterials = [...this.state.selectedHaulbackMaterials].filter(mat => {
                if (material.is_custom_material) {
                    if (mat.is_custom_material) {
                        return mat.id != material.id
                    } else {
                        return true;
                    }
                } else {
                    if (!mat.is_custom_material) {
                        return mat.id != material.id
                    } else {
                        return true;
                    }
                }
            })
            const newMaterialDisplay = newMaterials.map(mat => mat.name).join(", ")
            this.setStateIfMounted({
                selectedHaulbackMaterials: newMaterials,
                displayHaulbackMaterials: newMaterialDisplay
            })
        }
    }
    handleMaterialWeightUpdate = (material, isAmountField, forHaulback) => {
        if (!forHaulback) {
            if (isAmountField) {
                return event => {
                    const newMaterials = [...this.state.selectedMaterials].map(mat => {
                        if (mat.is_custom_material == material.is_custom_material && mat.id == material.id) {
                            return {
                                ...mat, 
                                estimated_weight: parseFloat(event.target.value) || ""
                            }
                        }
                        return mat;
                    })
                    this.setStateIfMounted({
                        selectedMaterials: newMaterials
                    })
                }
            } else {
                return event => {
                    const newMaterials = [...this.state.selectedMaterials].map(mat => {
                        if (mat.is_custom_material == material.is_custom_material && mat.id == material.id) {
                            return {
                                ...mat,
                                estimated_weight_units: event.target.value
                            }
                        }
                        return mat;
                    })
                    this.setStateIfMounted({
                        selectedMaterials: newMaterials
                    })
                }
            }
        } else {
            if (isAmountField) {
                return event => {
                    const newMaterials = [...this.state.selectedHaulbackMaterials].map(mat => {
                        if (mat.is_custom_material == material.is_custom_material && mat.id == material.id) {
                            return {
                                ...mat, 
                                estimated_weight: parseFloat(event.target.value) || ""
                            }
                        }
                        return mat;
                    })
                    this.setStateIfMounted({
                        selectedHaulbackMaterials: newMaterials
                    })
                }
            } else {
                return event => {
                    const newMaterials = [...this.state.selectedHaulbackMaterials].map(mat => {
                        if (mat.is_custom_material == material.is_custom_material && mat.id == material.id) {
                            return {
                                ...mat,
                                estimated_weight_units: event.target.value
                            }
                        }
                        return mat;
                    })
                    this.setStateIfMounted({
                        selectedHaulbackMaterials: newMaterials
                    })
                }
            }
        }
    }
    handleMaterialVolumeUpdate = (material, isAmountField, forHaulback) => {
        if (!forHaulback) {
            if (isAmountField) {
                return event => {
                    const newMaterials = [...this.state.selectedMaterials].map(mat => {
                        if (mat.is_custom_material == material.is_custom_material && mat.id == material.id) {
                            return {
                                ...mat, 
                                estimated_volume: parseFloat(event.target.value) || ""
                            }
                        }
                        return mat;
                    })
                    this.setStateIfMounted({
                        selectedMaterials: newMaterials
                    })
                }
            } else {
                return event => {
                    const newMaterials = [...this.state.selectedMaterials].map(mat => {
                        if (mat.is_custom_material == material.is_custom_material && mat.id == material.id) {
                            return {
                                ...mat,
                                estimated_volume_units: event.target.value
                            }
                        }
                        return mat;
                    })
                    this.setStateIfMounted({
                        selectedMaterials: newMaterials
                    })
                }
            }
        } else {
            if (isAmountField) {
                return event => {
                    const newMaterials = [...this.state.selectedHaulbackMaterials].map(mat => {
                        if (mat.is_custom_material == material.is_custom_material && mat.id == material.id) {
                            return {
                                ...mat, 
                                estimated_volume: parseFloat(event.target.value) || ""
                            }
                        }
                        return mat;
                    })
                    this.setStateIfMounted({
                        selectedHaulbackMaterials: newMaterials
                    })
                }
            } else {
                return event => {
                    const newMaterials = [...this.state.selectedHaulbackMaterials].map(mat => {
                        if (mat.is_custom_material == material.is_custom_material && mat.id == material.id) {
                            return {
                                ...mat,
                                estimated_volume_units: event.target.value
                            }
                        }
                        return mat;
                    })
                    this.setStateIfMounted({
                        selectedHaulbackMaterials: newMaterials
                    })
                }
            }
        }
    }
    getScalesAtLocation = (latitude, longitude, forHaulback) => {
        getCompanyScalesAtLocation(this.props.token, this.props.company.id, latitude, longitude).then(res => {
            if (res.data) {
                if (!forHaulback) {
                    this.setStateIfMounted({ scalesAtLocation: res.data})
                } else {
                    this.setStateIfMounted({ haulbackScales: res.data})
                }
            }
        })
    }
    updateCompanyIdentifiers = () => {
        if ((this.props.type === 'create' || this.props.type === 'duplicate') && this.state.specificHaulers) {
            this.state.specificHaulers.forEach(hauler => {
                setCompanyIdentifier(this.props.token, this.props.company.id, {
                    company_id: hauler.driver_company_id,
                    identifier: hauler.identifier
                })
            })
        }
    }
    submitJob = (event) => {
        event.preventDefault();
        this.setStateIfMounted({
            loading: true,
            tabValue: '-1'
        }, () => this.organizeJobPostData())
    }
    organizeJobPostData = async () => {

        const site = this.state.pickupSite?.id ? this.state.pickupSite : this.state.dropoffSite

        const postData = {
            company_id: this.props.company.id,
            site_id: site.id,
            name: this.state.name,

            start_date: this.props.helpers.toMysqlFormat(this.state.start_date, { dateOnly: true }),
            opening_time: this.props.helpers.toMysqlFormat(this.state.opening_time, { timeOnly: true, includeSeconds: true }),
            end_date:this.props.helpers.toMysqlFormat(this.state.end_date, { dateOnly: true }),
            closing_time: this.props.helpers.toMysqlFormat(this.state.closing_time, { timeOnly: true, includeSeconds: true }),

            elevation: 270,
            job_location_timezone: site.address.timezone,
            
            is_four_way: this.state.is_four_way,
            is_demolition_job: this.state.is_demolition_job,
            is_removal_job: this.state.is_removal_job,
            is_unknown_pickup_job: this.state.is_unknown_pickup_job,
            is_waste_manifest_job: this.state.is_waste_manifest_job,

            equipment: this.state.equipment.map(equip => equip.id),
            quantity: this.state.quantity,
            estimated_loads: this.state.estimated_loads,
            
            select_material_at_checkin: this.state.select_material_at_checkin,

            transaction_type_id: this.state.transaction_type_id,
            currency: this.state.currency.code,

            is_scan_picture_required: this.state.is_scan_picture_required,
            privacy_status: this.state.privacy_status,
            bid_amount_status: this.state.bid_amount_status,
            users_that_can_view: this.state.emails.map(emailObj => emailObj.email),
            track_driver_return_delivery_trip: this.state.track_driver_return_delivery_trip,
            notes: this.state.notes,
            terms_and_conditions: this.state.terms_and_conditions || null,
            routes: [{}],
            record_weight: false,
            send_realtime_etickets: this.state.send_realtime_etickets
        }
        if (this.state.privacy_status == 'private') {
            postData.scan_fee_payer = 'company'
        } else {
            postData.scan_fee_payer = this.state.scan_fee_payer;
            postData.bidding_ends = this.props.helpers.makeUnixTime(this.state.bidding_ends);
            postData.auto_award = this.state.auto_award;

            postData.invoices_to_be_paid_through_truckpay = this.state.invoices_to_be_paid_through_truckpay;
            if (!this.state.invoices_to_be_paid_through_truckpay) {
                postData.invoice_due_frequency = 'monthly';
                postData.payment_due_frequency = 'monthly';
            } else {
                postData.invoice_due_frequency = this.state.invoice_due_frequency;
                postData.payment_due_frequency = this.state.payment_due_frequency;
            }
        }
        if (this.state.weighmaster_doesnt_change) {
            postData.constant_weighmaster_name = this.state.constant_weighmaster_name
            postData.constant_weighmaster_license_number = this.state.constant_weighmaster_license_number
        }
        if (this.state.ticket_number_starts_at) {
            postData.ticket_number_starts_at = this.state.ticket_number_starts_at
        }
        if (this.state.privacy_status != 'private' && this.state.bidding_geo_restriction) {
            postData.bidding_geo_restriction_distance = this.state.bidding_geo_restriction_distance
            postData.bidding_geo_restriction_distance_units = this.state.bidding_geo_restriction_distance_units
        }
        if (this.state.privacy_status != 'private' && this.state.addNoncompete) {
            postData.noncompete_period = this.state.noncompete_period;
            postData.noncompete_distance = this.state.noncompete_distance;
            postData.noncompete_distance_units = this.state.noncompete_distance_units;
        }
        if (this.state.po_number != "") {
            postData.po_number = this.state.po_number
        }


        // PER WEIGHT
        postData.auto_bol = true;
        if (this.state.transaction_type_id == 2) {
            if (this.state.bol_start != "") {
                postData.bol_start = this.state.bol_start
            }
            postData.record_weight = this.state.record_weight
            postData.per_weight_job_units = this.state.per_weight_job_units;
            postData.truck_arrives_empty = this.state.truck_arrives_empty;
            postData.driver_can_check_in_with_active_deliveries = this.state.driver_can_check_in_with_active_deliveries;
            postData.allow_driver_record_weight = this.state.allow_driver_record_weight;
            postData.load_weight_method = this.state.load_weight_method;
            postData.record_weight = true;
            postData.truck_weight_type = 'T';
            postData.allow_job_tare_weights = this.state.allow_job_tare_weights
            if (this.state.allow_job_tare_weights) {
                postData.job_tare_weight_change_tolerance = this.state.job_tare_weight_change_tolerance / 100
                postData.job_tare_weight_expiration_days = this.state.job_tare_weight_expiration_days
            }
            postData.auto_bol = this.state.auto_bol;
        // PER VOLUME
        } else if (this.state.transaction_type_id == 4) {
            postData.per_volume_job_units = this.state.per_volume_job_units
        } else {
            postData.record_weight = true
        }

        // RAIN OUT
        if (this.state.addRainOut && this.state.privacy_status != 'private') {
            postData.rain_out_units = this.state.rain_out_units
            postData.advanced_warning_hours = this.state.advanced_warning_hours
        }

        // SIGN OFFS
        if (this.state.requires_sign_off && this.state.privacy_status != 'private') {
            postData.requires_sign_off = this.state.requires_sign_off
            postData.invoice_sign_off_team = [];
            if (this.state.signOffMemberFirst) {
                postData.invoice_sign_off_team.push({
                    sign_off_user_id: this.state.signOffMemberFirst.user_id,
                    order: 1
                })
            }
            if (this.state.signOffMemberSecond) {
                postData.invoice_sign_off_team.push({
                    sign_off_user_id: this.state.signOffMemberSecond.user_id,
                    order: 2
                })
            }
            if (this.state.signOffMemberThird) {
                postData.invoice_sign_off_team.push({
                    sign_off_user_id: this.state.signOffMemberThird.user_id,
                    order: 3
                })
            }
        }

        if (this.state.is_four_way || this.state.is_demolition_job) {
            if (this.state.is_four_way) {
                // SET SUPPLIER OPTIONS
                postData.company_supplier_company_id = this.state.supplier.company_supplier_id
                postData.company_supplier_transaction_type_id = this.state.company_supplier_transaction_type_id
                postData.company_supplier_price = this.state.company_supplier_price * 100
                postData.company_supplier_invoices_to_be_paid_through_truckpay = this.state.company_supplier_invoices_to_be_paid_through_truckpay
                if (this.state.company_supplier_transaction_type_id == 2) {
                    postData.company_supplier_per_weight_job_units = this.state.company_supplier_per_weight_job_units
                } else if (this.state.company_supplier_transaction_type_id == 4) {
                    postData.company_supplier_per_volume_job_units = this.state.company_supplier_per_volume_job_units
                }
            } else if (this.state.is_demolition_job) {
                // SET DESTINATION OPTIONS
                postData.demolition_destination_company_id = this.state.destination.demolition_destination_company_id
                postData.demolition_destination_transaction_type_id = this.state.demolition_destination_transaction_type_id
                postData.demolition_destination_price = this.state.demolition_destination_price * 100
                postData.demolition_destination_invoices_to_be_paid_through_truckpay = this.state.demolition_destination_invoices_to_be_paid_through_truckpay
                postData.demolition_destination_company_pays = this.state.demolition_destination_company_pays
                if (this.state.demolition_destination_transaction_type_id == 2) {
                    postData.demolition_destination_per_weight_job_units = this.state.demolition_destination_per_weight_job_units
                } else if (this.state.demolition_destination_transaction_type_id == 4) {
                    postData.demolition_destination_per_volume_job_units = this.state.demolition_destination_per_volume_job_units
                }
            }
            // SET CUSTOMER OPTIONS
            postData.company_customer_company_id = this.state.customer.company_customer_id
            postData.company_customer_transaction_type_id = this.state.company_customer_transaction_type_id
            postData.company_customer_price = this.state.company_customer_price * 100
            postData.company_customer_invoices_to_be_paid_through_truckpay = this.state.company_customer_invoices_to_be_paid_through_truckpay
            if (this.state.company_customer_transaction_type_id == 2) {
                postData.company_customer_per_weight_job_units = this.state.company_customer_per_weight_job_units
            } else if (this.state.company_customer_transaction_type_id == 4) {
                postData.company_customer_per_volume_job_units = this.state.company_customer_per_volume_job_units
            }
        } else {
            if (this.state.customer_name) {
                postData.customer_name = this.state.customer_name
            }
            if (this.state.customer_phone_number) {
                postData.customer_phone_number = this.state.customer_phone_number
            }
        }
        if (this.state.hasBatchOrInspectableMaterial || this.state.jobType == 'waste') {
            postData.is_company_pickup_inspection_required = this.state.is_company_pickup_inspection_required
            if (postData.is_company_pickup_inspection_required) {
                postData.driver_company_pickup_inspection_wait_minutes = this.state.driver_company_pickup_inspection_wait_minutes || 0
            } else {
                postData.driver_company_pickup_inspection_wait_minutes = 0
            }

            postData.is_company_dropoff_inspection_required = this.state.is_company_dropoff_inspection_required
            if (postData.is_company_dropoff_inspection_required) {
                postData.driver_company_dropoff_inspection_wait_minutes = this.state.driver_company_dropoff_inspection_wait_minutes || 0
            } else {
                postData.driver_company_dropoff_inspection_wait_minutes = 0
            }

            postData.is_third_party_pickup_inspection_required = this.state.is_third_party_pickup_inspection_required
            if (postData.is_third_party_pickup_inspection_required) {
                postData.driver_third_party_pickup_inspection_wait_minutes = this.state.driver_third_party_pickup_inspection_wait_minutes || 0
            } else {
                postData.driver_third_party_pickup_inspection_wait_minutes = 0
            }

            postData.is_third_party_dropoff_inspection_required = this.state.is_third_party_dropoff_inspection_required
            if (postData.is_third_party_dropoff_inspection_required) {
                postData.driver_third_party_dropoff_inspection_wait_minutes = this.state.driver_third_party_dropoff_inspection_wait_minutes || 0
            } else {
                postData.driver_third_party_dropoff_inspection_wait_minutes = 0
            }

            postData.can_driver_authorize_inspector = this.state.can_driver_authorize_inspector
            postData.has_automated_batching = this.state.has_automated_batching
        }
        if (this.state.bid_amount_status == 'fixed') {
            postData.amount = this.state.amount * 100
        }

        // Extended Job Options
        if (this.state.extended_job_type) {
            postData.extended_job_type = this.state.extended_job_type
            // const extendedAttributeValues = {}
            // Object.keys(this.state.extended_attribute_values_map).forEach(key => {
            //     extendedAttributeValues[key] = this.state.extended_attribute_values_map[key]
            // })
            postData.extended_attribute_values = this.state.extended_attribute_values_map
        }
        if (this.state.estimated_amount && this.state.estimated_amount_units) {
            postData.estimated_amount = parseFloat(this.state.estimated_amount)
            postData.estimated_amount_units = this.state.estimated_amount_units
        }
        if (this.state.estimated_volume && this.state.estimated_volume_units) {
            postData.estimated_volume = parseFloat(this.state.estimated_volume)
            postData.estimated_volume_units = this.state.estimated_volume_units
        }
        // console.log(this.state.eticket_targets, postData.eticket_targets)
        // return;

        const primaryRoute = { };
        primaryRoute.order_by = 1;
        primaryRoute.checkin_type = this.state.checkinType.value;
        if (this.state.pickupSite?.id) {
            primaryRoute.pickup_site_id = this.state.pickupSite.id
        } 
        if (this.state.dropoffSite?.id) {
            primaryRoute.dropoff_site_id = this.state.dropoffSite.id
        } 
        if (this.state.checkinType.value == 'signature') {
            primaryRoute.is_driver_checkin_signature_required = this.state.is_driver_checkin_signature_required;
        }
        primaryRoute.checkout_type = this.state.checkoutType.value;
        if (this.state.checkoutType.value == 'signature') {
            primaryRoute.is_driver_checkout_signature_required = this.state.is_driver_checkout_signature_required;
        } else if (this.state.checkoutType.value == 'self-check') {
            primaryRoute.auto_checkout = this.state.auto_checkout
        }
        primaryRoute.custom_material = this.state.selectedMaterials.filter(mat => mat.is_custom_material).map(mat => {
            const tempMat = { 
                id: mat.id, 
            }
            if (mat.estimated_weight && mat.estimated_weight_units) {
                tempMat.estimated_weight = mat.estimated_weight;
                tempMat.estimated_weight_units = mat.estimated_weight_units
            }
            if (mat.estimated_volume && mat.estimated_volume_units) {
                tempMat.estimated_volume = mat.estimated_volume;
                tempMat.estimated_volume_units = mat.estimated_volume_units
            }
            return tempMat
        });
        primaryRoute.material = this.state.selectedMaterials.filter(mat => !mat.is_custom_material).map(mat => {
            const tempMat = {
                id: mat.id,
            }
            if (mat.estimated_weight && mat.estimated_weight_units) {
                tempMat.estimated_weight = mat.estimated_weight;
                tempMat.estimated_weight_units = mat.estimated_weight_units
            }
            if (mat.estimated_volume && mat.estimated_volume_units) {
                tempMat.estimated_volume = mat.estimated_volume;
                tempMat.estimated_volume_units = mat.estimated_volume_units
            }
            return tempMat
        });
        primaryRoute.truck_weigh_location = this.state.truck_weigh_location;
        if (this.state.transaction_type_id == 2) {
            if (this.state.automated_scale) {
                if (this.state.truck_weigh_location == 'P') {
                    primaryRoute.pick_up_scale_id = this.state.pick_up_scale_id
                } else {
                    primaryRoute.drop_off_scale_id = this.state.drop_off_scale_id
                }
            }
        }

        primaryRoute.is_geo_fenced = this.state.is_geo_fenced;
        if (this.state.is_geo_fenced) {
            primaryRoute.pick_up_geo_fence_distance = this.state.pick_up_geo_fence_distance;
            primaryRoute.drop_off_geo_fence_distance = this.state.drop_off_geo_fence_distance;
            primaryRoute.geo_fence_distance_unit = this.state.geo_fence_distance_unit;
        }
        
        if (this.state.is_removal_job) {
            postData.routes[0].track_driver_destination = this.state.track_driver_destination
        }
        primaryRoute.from = {}
        primaryRoute.to = {}

        if (this.state.jobType !== 'unknownPickup') {
            primaryRoute.from = {
                ...primaryRoute.from,
                country_id: this.state.pickupAddress.country.id || null,
                address_line_1: this.state.pickupAddress.address_line_1 || null,
                city: this.state.pickupAddress.city || null,
                province: this.state.pickupAddress.province || null,
                postal_code: this.state.pickupAddress.postal_code || null,
                latitude: this.state.pickupAddress.latitude || null,
                longitude: this.state.pickupAddress.longitude || null,
                location_id: this.state.pick_up_location_id || null,
            };
            if (this.state.addPickupContactInfo) {
                primaryRoute.from = {
                    ...primaryRoute.from,
                    address_company_name: this.state.pickupContactInfo.address_company_name || null,
                    address_contact_first_name: this.state.pickupContactInfo.address_contact_first_name || null,
                    address_contact_last_name: this.state.pickupContactInfo.address_contact_last_name || null,
                    address_contact_email: this.state.pickupContactInfo.address_contact_email || null,
                    address_contact_phone: this.state.pickupContactInfo.address_contact_phone || null,
                }
            } else if (this.state.pickupSite?.id) {
                primaryRoute.from = {
                    ...primaryRoute.from,
                    address_company_name: this.state.pickupSite.site_company_name || null,
                    address_contact_first_name: this.state.pickupSite.site_contact_first_name || null,
                    address_contact_last_name: this.state.pickupSite.site_contact_last_name || null,
                    address_contact_email: this.state.pickupSite.site_contact_email || null,
                    address_contact_phone: this.state.pickupSite.site_contact_phone || null,
                }
            }
        } else {
            // TODO TEMP FIX TO UNFINISHED UNKNOWN_ JOBS
            primaryRoute.from = {
                country_id: 1,
                latitude: this.props.company.address.latitude,
                longitude: this.props.company.address.longitude,
            }
        }
        if (this.state.jobType !== 'unknownDropoff') {
            primaryRoute.to = {
                ...primaryRoute.to,
                country_id: this.state.dropoffAddress.country.id || null,
                address_line_1: this.state.dropoffAddress.address_line_1 || null,
                city: this.state.dropoffAddress.city || null,
                province: this.state.dropoffAddress.province || null,
                postal_code: this.state.dropoffAddress.postal_code || null,
                latitude: this.state.dropoffAddress.latitude || null,
                longitude: this.state.dropoffAddress.longitude || null,
                location_id: this.state.drop_off_location_id || null,
            };
            if (this.state.addDropoffContactInfo) {
                primaryRoute.to = {
                    ...primaryRoute.to,
                    address_company_name: this.state.dropoffContactInfo.address_company_name || null,
                    address_contact_first_name: this.state.dropoffContactInfo.address_contact_first_name || null,
                    address_contact_last_name: this.state.dropoffContactInfo.address_contact_last_name || null,
                    address_contact_email: this.state.dropoffContactInfo.address_contact_email || null,
                    address_contact_phone: this.state.dropoffContactInfo.address_contact_phone || null,
                }
            } else if (this.state.dropoffSite?.id) {
                primaryRoute.to = {
                    ...primaryRoute.to,
                    address_company_name: this.state.dropoffSite.site_company_name || null,
                    address_contact_first_name: this.state.dropoffSite.site_contact_first_name || null,
                    address_contact_last_name: this.state.dropoffSite.site_contact_last_name || null,
                    address_contact_email: this.state.dropoffSite.site_contact_email || null,
                    address_contact_phone: this.state.dropoffSite.site_contact_phone || null,
                }
            }
        } else {
            // TODO TEMP FIX TO UNFINISHED UNKNOWN_ JOBS
            primaryRoute.to = {
                country_id: 1,
                latitude: this.props.company.address.latitude,
                longitude: this.props.company.address.longitude,
            }
        }

        if (this.state.warnedBadAddressInput && this.state.estimated_meters && this.state.estimated_meters_units) {
            primaryRoute.estimated_meters = this.props.helpers.convertUnits(this.state.estimated_meters, this.state.estimated_meters_units, 'm')
        }
        postData.routes = [primaryRoute]

        if (this.state.addHaulback && (this.state.jobType != 'unknownPickup' && this.state.jobType != 'unknownDropoff')) {
            const haulbackRoute = {}
            haulbackRoute.order_by = 2;
            haulbackRoute.checkin_type = this.state.haulbackCheckinType.value;
            haulbackRoute.checkout_type = this.state.haulbackCheckoutType.value;
            haulbackRoute.custom_material = this.state.selectedHaulbackMaterials.filter(mat => mat.is_custom_material).map(mat => {
                const tempMat = {
                    id: mat.id,
                }
                if (mat.estimated_weight && mat.estimated_weight_units) {
                    tempMat.estimated_weight = mat.estimated_weight;
                    tempMat.estimated_weight_units = mat.estimated_weight_units
                }
                if (mat.estimated_volume && mat.estimated_volume_units) {
                    tempMat.estimated_volume = mat.estimated_volume;
                    tempMat.estimated_volume_units = mat.estimated_volume_units
                }
                return tempMat
            });
            haulbackRoute.material = this.state.selectedHaulbackMaterials.filter(mat => !mat.is_custom_material).map(mat => {
                const tempMat = {
                    id: mat.id,
                }
                if (mat.estimated_weight && mat.estimated_weight_units) {
                    tempMat.estimated_weight = mat.estimated_weight;
                    tempMat.estimated_weight_units = mat.estimated_weight_units
                }
                if (mat.estimated_volume && mat.estimated_volume_units) {
                    tempMat.estimated_volume = mat.estimated_volume;
                    tempMat.estimated_volume_units = mat.estimated_volume_units
                }
                return tempMat
            });
            haulbackRoute.truck_weigh_location = this.state.haulback_truck_weigh_location;
            if (this.state.haulbackCheckoutType && this.state.haulbackCheckoutType.value == 'self-check') {
                haulbackRoute.auto_checkout = this.state.haulback_auto_checkout
            }
            if (this.state.haulbackCheckinType && this.state.haulbackCheckinType.value == 'signature' && this.state.haulback_is_driver_checkin_signature_required) {
                haulbackRoute.is_driver_checkin_signature_required = true;
            }
            if (this.state.haulbackCheckoutType && this.state.haulbackCheckoutType.value == 'signature' && this.state.haulback_is_driver_checkout_signature_required) {
                haulbackRoute.is_driver_checkout_signature_required = true;
            }

            haulbackRoute.is_geo_fenced = this.state.haulback_is_geo_fenced;
            if (this.state.haulback_is_geo_fenced) {
                haulbackRoute.pick_up_geo_fence_distance = this.state.haulback_pick_up_geo_fence_distance;
                haulbackRoute.drop_off_geo_fence_distance = this.state.haulback_drop_off_geo_fence_distance;
                haulbackRoute.geo_fence_distance_unit = this.state.haulback_geo_fence_distance_unit;
            }
            if (this.state.transaction_type_id == 2) {
                if (this.state.haulback_automated_scale) {
                    if (this.state.haulback_truck_weigh_location == 'P') {
                        haulbackRoute.pick_up_scale_id = this.state.haulback_pick_up_scale_id;
                    } else {
                        haulbackRoute.drop_off_scale_id = this.state.haulback_drop_off_scale_id;
                    }
                }
            }


            haulbackRoute.from = { ...postData.routes[0].to }
            haulbackRoute.to = {
                address_line_1: this.state.haulbackAddress.address_line_1 || null,
                city: this.state.haulbackAddress.city || null,
                province: this.state.haulbackAddress.province || null,
                postal_code: this.state.haulbackAddress.postal_code || null,
                latitude: this.state.haulbackAddress.latitude || null,
                longitude: this.state.haulbackAddress.longitude || null,
                country_id: this.state.haulbackCountry.id,
            }
            if (this.state.addHaulbackContactInfo) {
                haulbackRoute.to.address_company_name = this.state.haulbackAddress.addres_company_name || null;
                haulbackRoute.to.address_contact_first_name = this.state.haulbackAddress.addres_contact_first_name || null;
                haulbackRoute.to.address_contact_last_name = this.state.haulbackAddress.addres_contact_last_name || null;
                haulbackRoute.to.address_contact_email = this.state.haulbackAddress.addres_contact_email || null;
                haulbackRoute.to.address_contact_phone = this.state.haulbackAddress.addres_contact_phone || null;
            }

            postData.routes.push(haulbackRoute)
        }

        const targetNames = Object.keys(this.state.eticket_targets)
        if (targetNames.length) {
            const cleanedTargets = {}
            targetNames.forEach(targetName => {
                const cleanedTarget = {}
                Object.keys(this.state.eticket_targets[targetName]).forEach(parameterName => {
                    // TODO convert to switch case if more datatypes are used
                    if (this.state.possibleTargets[targetName].parameters[parameterName]?.datatype === "string") {
                        const parameterValue = this.state.eticket_targets[targetName][parameterName].toString()
                        if (parameterValue.length) {
                            cleanedTarget[parameterName] = parameterValue
                        }
                    } else if (this.state.possibleTargets[targetName].parameters[parameterName]?.datatype === "float") {
                        const parameterValue = parseFloat(this.state.eticket_targets[targetName][parameterName])
                        cleanedTarget[parameterName] = parameterValue
                    }
                })
                cleanedTargets[targetName] = cleanedTarget
            })
            postData.eticket_targets = cleanedTargets
        }
        if ((this.props.type === 'create' || this.props.type === 'duplicate') && this.state.specificHaulers && this.state.bid_amount_status === 'fixed' && this.state.amount) {
            console.log('adding bid data')

            const bids = this.state.specificHaulers.filter(hauler => hauler.bidAsCompany).map(hauler => {
                const bidData = {
                    bidder_company_id: hauler.driver_company.id,
                    allow_job_creator_to_dispatch: hauler.default_can_preferring_company_dispatch_on_bids || false,
                    num_vehicles_offered: parseInt(hauler.trucksRequired),
                    vehicle_type_amounts: [
                        {
                            equipment_id: this.state.equipment[0].id,
                            amount_offered: parseInt(hauler.trucksRequired)
                        }
                    ],
                    amount: this.state.amount * 100,
                }
                if (hauler.allow_preferring_company_to_place_bid_on_behalf) {
                    bidData.on_behalf_bidding_company_id = this.props.company.id
                    bidData.award = hauler.award || false
                } else {
                    bidData.is_requested_bid = true
                    bidData.requesting_company_id = this.props.company.id
                }
                return bidData
            })
            postData.create_bids = bids
        }
        console.log("job state", this.state)
        console.log("job post data", postData)

        const postType = this.props.type;
        if (postType == 'create') {
            createJob(this.props.token, this.props.company.id, site.id, postData).then(res => {
                if (res.success) {
                    this.props.popAlert('success', this.props.t('success'), this.props.t('jobTranslations.success.createJob'))
                    this.props.setModalOpen(false)
                    this.updateCompanyIdentifiers()
                    if (this.state.privacy_status == 'whitelist') {
                        addCompaniesToJobWhitelist(this.props.token, this.props.company.id, res.data.id, this.state.selectedWhitelistCompanyIDs).then(res => {
                            console.log('added companies to whitelist res', res)
                        })
                    }
                    this.props.clearPaginatedPendingJobs()
                    this.navigateToPending()
                } else {
                    this.setStateIfMounted({
                        loading: false,
                        tabValue: this.summaryPage.toString()
                    }, () => this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res.error)))
                }
            })

        } else if (postType == 'edit') {
            const tempRoutes = {}
            postData.routes.forEach((route, index) => {
                if (this.props.job.routes[index]) {
                    tempRoutes[this.props.job.routes[index].id] = route;
                }
            })
            postData.routes = tempRoutes
            editJob(this.props.token, this.props.job.id, postData).then(async res => {
                if (res.success) {
                    await this.handleWhitelist()
                    const targetsUpdatedSuccessfully = await this.handleETicketTargets(postData.eticket_targets)
                    if (!targetsUpdatedSuccessfully) {
                        this.setStateIfMounted({ loading: false, tabValue: this.eTicketTargetsPage.toString() })
                        return 
                    }

                    this.props.popAlert('success', this.props.t('success'), this.props.t('jobTranslations.success.editJob'))
                    this.props.refreshJob();
                    this.props.setModalOpen(false)
                    this.props.clearPaginatedPendingJobs()
                    this.navigateToPending()
                   
                } else {
                    this.setStateIfMounted({
                        loading: false,
                        tabValue: this.summaryPage.toString()
                    }, () => this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res.error)))
                }
            })
        } else if (postType == 'duplicate') {
            createJob(this.props.token, this.props.company.id, site.id, postData).then(res => {
                if (res.success) {
                    this.props.popAlert('success', this.props.t('success'), this.props.t('jobTranslations.success.duplicateJob'))
                    this.props.setModalOpen(false)
                    this.updateCompanyIdentifiers()
                    this.props.clearPaginatedPendingJobs()
                    this.navigateToPending()
                    if (this.state.privacy_status == 'whitelist') {
                        addCompaniesToJobWhitelist(this.props.token, this.props.company.id, res.data.id, this.state.selectedWhitelistCompanyIDs).then(res => {
                            console.log('added companies to whitelist res', res)
                        })
                    }
                } else {
                    this.setStateIfMounted({
                        loading: false,
                        tabValue: this.summaryPage.toString()
                    }, () => this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res.error)))
                }
            })
        }

    }
    navigateToPending = () => {
        if (this.props.location.pathname != '/jobs/pending') {
            this.props.history.push('/jobs/pending')
        }
    }
    removeCompanies = () => {
        return new Promise(async (res, rej) => {
            for (let i = 0; i < this.state.whitelistCompanyIDs.length; i++) {
                const whitelistedCompanyID = this.state.whitelistCompanyIDs[i];
                if (!this.state.selectedWhitelistCompanyIDs.includes(whitelistedCompanyID)) {
                    await removeCompanyFromJobWhitelist(this.props.token, this.props.company.id, this.props.job.id, whitelistedCompanyID).then(res => {
                        console.log('remove from whitelist res', res)
                    })
                }
            }
            res()
        })
    }
    handleWhitelist = async () => {
        if (this.state.selectedWhitelistCompanyIDs.length) {
            await this.removeCompanies()
            return addCompaniesToJobWhitelist(this.props.token, this.props.company.id, this.props.job.id, this.state.selectedWhitelistCompanyIDs)
        } else {
            return this.removeCompanies()
        }
    }
    /*
        Add new eticket targets
        Remove old eticket targets
    */
    handleETicketTargets = async (eTicketTargets) => {
        console.log("handling targets", eTicketTargets);
        
        // Targets found in final list that aren't in initial list
        const targetsToAdd = {}

        // Targets found in initial list and final list
        const targetsToUpdate = {}
        
        if (!eTicketTargets || !Object.keys(eTicketTargets).length) return true;
        Object.keys(eTicketTargets).forEach(targetName => {
            if (this.state.initialETicketTargets.find(targetData => targetData.target === targetName)) {
                targetsToUpdate[targetName] = eTicketTargets[targetName]
            } else {
                targetsToAdd[targetName] = eTicketTargets[targetName]
            }
        })
        const addedSuccessfully = await this.addETicketTargets(targetsToAdd)
        if (!addedSuccessfully) return false;

        const updatedSuccessfully = await this.updateETicketTargets(targetsToUpdate)
        if (!updatedSuccessfully) return false;

        // Targets found in initial list that are no longer in final list
        const targetsToDelete = this.state.initialETicketTargets.filter(targetData => !this.state.eticket_targets.hasOwnProperty(targetData.target))
        const removedSuccessfully = await this.removeETicketTargets(targetsToDelete)
        return removedSuccessfully
    }
    addETicketTargets = (targets) => {
        if (Object.keys(targets).length) {
            console.log('gonna add targets', targets)
            return addETicketTargetForJob(this.props.token, this.props.job.id, targets).then(res => {
                if (!res.success) {
                    this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res))
                }
                return res.success
            })
        } 
        console.log('skipping add targets')
        return new Promise((res, rej) => res(true))
    }
    updateETicketTargets = (targets) => {
        if (Object.keys(targets).length) {
            console.log('gonna update targets', targets)
            return updateETicketTargetForJob(this.props.token, this.props.job.id, targets).then(res => {
                if (!res.success) {
                    this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res))
                }
                return res.success
            })
        }
        console.log('skipping add targets')
        return new Promise((res, rej) => res(true))
    }
    removeETicketTargets = async (targets) => {
        let success = true
        for (let i = 0; i < targets.length; i++) {
            const target = targets[i];
            console.log('removing a target')
            await removeETicketTargetForJob(this.props.token, target.job_id, target.id).then(res => {
                if (!res.success && success) {
                    this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res))
                    success = false
                }
            })
        }
        return success
    }
    getTabPanelDisplay = (tabValue) => {
        if (this.state.tabValue == tabValue) {
            return ""
        } else {
            return "none";
        }
    }
    handleConfirmClose = bool => {
        if (bool) {
            this.props.setModalOpen(false)
        } else {
            this.setStateIfMounted({ openConfirmClose: false})
        }
    }
    filterWhitelist = () => {
        if (this.state.whitelistFilter) {
            const newFilteredDrivers = this.state.preferredDrivers.filter(preference => {
                const filterQuery = this.state.whitelistFilter.toLowerCase()
                const driverCompanyName = preference.driver_company.name.toLowerCase()
                const identifier = preference.identifier ? preference.identifier.toLowerCase() : ''
                return (
                    driverCompanyName.includes(filterQuery)
                    ||
                    identifier.toLowerCase().includes(filterQuery)
                )
            })
            this.setStateIfMounted({
                chunkedPreferredDrivers: this.props.helpers.chunk(newFilteredDrivers, 2)
            })
        } else {
            this.setStateIfMounted({
                chunkedPreferredDrivers: this.props.helpers.chunk(this.state.preferredDrivers, 2)
            })
        }
    }
    isChecked = (companyID) => {
        return Boolean(this.state.selectedWhitelistCompanyIDs.find(id => id == companyID))
    }

    handleCheckChange = (companyID) => {
        return (event) => {
            if (!this.isChecked(companyID) && event.target.checked) {
                this.setStateIfMounted({
                    selectedWhitelistCompanyIDs: [...this.state.selectedWhitelistCompanyIDs, companyID]
                })
            } else {
                this.setStateIfMounted({
                    selectedWhitelistCompanyIDs: this.state.selectedWhitelistCompanyIDs.filter(id => id != companyID)
                })
            }
        }
    }
    setCustomMaterialModal = bool => this.setStateIfMounted({ openCustomMaterialModal: bool })
    setQueryForMaterialsModal = bool => this.setStateIfMounted({ openQueryForMaterialsModal: bool })
    setExtendedAttributeValues = data => this.setStateIfMounted({ extended_attribute_values_map: data })
    renderLocation = (location) => {
        const { address_line_1, city, province, postal_code, latitude, longitude } = location == 'pickup' ? this.state.pickupAddress : this.state.dropoffAddress
        const locationID = location == 'pickup' ? this.state.pick_up_location_id : this.state.drop_off_location_id
        const site = location == 'pickup' ? this.state.pickupSite : this.state.dropoffSite
        if (!Boolean(address_line_1 && city && province) && !Boolean(latitude && longitude)) {
            return <>
                <Typography>No address specified</Typography>
            </>
        }
        return (<>
            {Boolean(locationID) && <Typography color="textSecondary">
                {locationID}
            </Typography>}
            {Boolean(site?.id) && <Typography>
                {site.site_name}
            </Typography>}
            {(address_line_1 && city && province) ? <>
                <Typography>
                    {address_line_1},<br />
                    {city}, {province} {postal_code}
                </Typography>
            </> : <> 
                <Typography>
                    {this.props.t('addressTranslations.latitude')}: {latitude}<br />
                    {this.props.t('addressTranslations.longitude')}: {longitude}
                </Typography>
            </>}
        </>)
    }
    updateAddressInfo = (forPickup) => (data) => {
        if (forPickup) {
            const addressData = {
                specifiedRouteLocation: true,
                pickupAddress: {
                    ...data.address
                },
                pick_up_location_id: data.location_id,
                pickupSite: data.site,
                addPickupContactInfo: data.addContactInfo,
                pickupContactInfo: {
                    ...data.contactInfo
                }
            }
            console.log("job pick address", addressData)
            this.setStateIfMounted(addressData)
        } else {
            const addressData = {
                specifiedRouteLocation: true,
                dropoffAddress: {
                    ...data.address
                },
                drop_off_location_id: data.location_id,
                dropoffSite: data.site,
                addDropoffContactInfo: data.addContactInfo,
                dropoffContactInfo: {
                    ...data.contactInfo
                }
            }
            console.log("job drop address", addressData)
            this.setStateIfMounted(addressData)
        }
    }
    /**
     * In the job type selector page, additional fields vary type to type
     * - RMHMA shows RMHMA additional fields
     * - Fourway/Material Disposal need the supplier/demolition company and customer company
     * - All other job types show optional customer name/phone number inputs
     * 
     * This returns the relevant label for that section
     */
    getJobTypeSubheader = () => {
        switch (this.state.jobType) {
            case "fourway":
            case "demolition":
                return "Involved Companies"
            default: 
                return "Other"
        }
    }
    render = () => {
        return (
            <Modal open={true} style={{overflowY: 'scroll'}} keepMounted>
                <Grid container spacing={10} direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} sm={9} md={7} lg={6}>
                        <Paper m={6} variant="outlined">
                            <TabContext value={this.state.tabValue}>
                                {/* <AppBar color="default" position="static"> */}
                                    <Grid container spacing={10} style={{ width: '100%', margin: 0 }} direction="row" justify="space-between" alignItems="center">
                                        <Grid item>
                                            {this.props.type == 'create' && <Typography variant="h3">{this.props.t('jobTranslations.actions.createJob')}</Typography>}
                                            {this.props.type == 'edit' && <Typography variant="h3">{this.props.t('jobTranslations.actions.editJob')}</Typography>}
                                            {this.props.type == 'duplicate' && <Typography variant="h3">{this.props.t('jobTranslations.actions.duplicateJob')}</Typography>}
                                        </Grid>
                                        <Grid item>
                                            <IconButton
                                                aria-label="Close job modal"
                                                size="small"
                                                onClick={() => this.setStateIfMounted({ openConfirmClose: true})}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                {this.state.openConfirmClose && <ConfirmModalClose t={this.props.t} messageKey="confirmCloseMessage" handleConfirm={this.handleConfirmClose}/>}
                                {/* </AppBar> */}
                                <AppBar color="default" position="static">
                                    <Toolbar>
                                        <Tabs
                                            variant="scrollable"
                                            indicatorColor="primary"
                                            scrollButtons="auto"
                                            value={this.state.tabValue}
                                            onChange={(event, tabValue) => this.setStateIfMounted({tabValue: tabValue})}
                                        >
                                            <Tab label="Loading" value="-1" id="-1" disabled style={{display: 'none'}}/>
                                            <Tab label={this.props.t('jobTranslations.actions.selectJobType')} value={this.jobTypePage.toString()} id={this.jobTypePage} disabled={!this.tabAvailability[this.jobTypePage] || this.state.loading}/>
                                            <Tab label={"External E-Tickets"} value={this.eTicketTargetsPage.toString()} id={this.eTicketTargetsPage} disabled={!this.tabAvailability[this.eTicketTargetsPage] || this.state.loading }/>
                                            <Tab label={this.props.t('jobTranslations.jobDetails')} value={this.jobDetailsPage.toString()} id={this.jobDetailsPage} disabled={!this.tabAvailability[this.jobDetailsPage] || this.state.loading }/>
                                            <Tab label={this.props.t('jobTranslations.routeDetails')} value={this.routeDetailsPage.toString()} id={this.routeDetailsPage} disabled={!this.tabAvailability[this.routeDetailsPage] || this.state.loading || !this.state.equipment.length }/>
                                            <Tab label={this.props.t('jobTranslations.haulbackDetails')} value={this.haulbackDetailsPage.toString()} id={this.haulbackDetailsPage} disabled={!this.tabAvailability[this.haulbackDetailsPage] || this.state.loading || !this.state.equipment.length || !this.state.selectedMaterials.length }/>
                                            <Tab label={this.props.t('jobTranslations.summary')} value={this.summaryPage.toString()} id={this.summaryPage} disabled={!this.tabAvailability[this.summaryPage] || this.state.loading || !this.state.equipment.length || !this.state.selectedMaterials.length || (this.state.addHaulback && !this.state.selectedHaulbackMaterials.length)}/>
                                        </Tabs>
                                    </Toolbar>
                                </AppBar>
                                <TabPanel value={this.state.tabValue} index="-1" style={{ display: this.getTabPanelDisplay('-1')}}>
                                    <TPLoader />
                                </TabPanel>

                                {/* <TabPanel value={this.state.tabValue} index={this.siteSelectPage} style={{ display: (this.state.tabValue == this.siteSelectPage && !this.state.loading) ? "" : "none" }}>
                                    <form autoComplete="off" onSubmit={(event) => this.nextPage(event, this.siteSelectPage)}>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth mt={2}>
                                                    <Autocomplete
                                                        id="site_autocomplete"
                                                        disableClearable
                                                        autoComplete
                                                        clearOnBlur={false}
                                                        options={this.props.sites}
                                                        getOptionLabel={(option) => option.site_name}
                                                        onChange={(event, val) => this.setStateIfMounted({ site: val })}
                                                        onInputChange={(event, val, reason) => {
                                                            if (reason != 'reset' || val) {
                                                                this.setStateIfMounted({ searchSites: val })
                                                            }
                                                        }}
                                                        inputValue={this.state.searchSites}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                id="searchSites"
                                                                label={`Search for Sites`}
                                                                type="text"
                                                                required
                                                                error={!this.state.site}
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText>{this.props.t('siteTranslations.actions.selectSiteMessage')}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            {this.state.site && <Grid item xs={12} md={6}>
                                                <Typography variant="h6" style={{ textDecoration: 'underline' }} mt={2}>
                                                    Selected Site: 
                                                </Typography>
                                                <Typography variant="body1">
                                                    {this.state.site.site_name}
                                                </Typography>
                                                {(this.state.site.address.address_line_1 && this.state.site.address.city && this.state.site.address.province) ? <>
                                                    <Typography>
                                                        {this.state.site.address.address_line_1},
                                                    </Typography>
                                                    <Typography mb={2}>
                                                        {this.state.site.address.city}, {this.state.site.address.province} {this.state.site.address.postal_code}
                                                    </Typography>
                                                </> : <>
                                                    <Typography mb={2}>
                                                        {this.state.site.address.latitude}, {this.state.site.address.longitude}
                                                    </Typography>
                                                </>}
                                            </Grid>}
                                        </Grid>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12}>
                                                <Button variant="contained" color="primary" fullWidth type="submit">
                                                    {this.props.t('actions.next')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </TabPanel> */}
                                <TabPanel value={this.state.tabValue} index={this.jobTypePage} style={{ display: (this.state.tabValue == this.jobTypePage && !this.state.loading) ? "" : "none" }} keepMounted>
                                    {this.state.tabValue == this.jobTypePage && <form autoComplete="off" onSubmit={(event) => this.nextPage(event, this.jobTypePage)}>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="job_type">{this.props.t('jobTranslations.actions.selectJobType')}</InputLabel>
                                                    <Select
                                                        labelId="job_type"
                                                        label={this.props.t('jobTranslations.actions.selectJobType')}
                                                        value={this.state.jobType}
                                                        onChange={this.handleChange('jobType')}
                                                        inputProps={{
                                                            name: "job_type",
                                                            id: "job_type"
                                                        }}
                                                        fullWidth
                                                        required
                                                        error={!this.state.jobType}
                                                    >
                                                        <MenuItem value="standard">
                                                            <em>{this.props.t('jobTranslations.jobTypes.standard')}</em>
                                                        </MenuItem>
                                                        <MenuItem value="RMHMA">
                                                            <em>RMHMA</em>
                                                        </MenuItem>
                                                        <MenuItem value="fourway">
                                                            <em>{this.props.t('jobTranslations.jobTypes.fourway')}</em>
                                                        </MenuItem>
                                                        <MenuItem value="unknownDropoff">
                                                            <em>{this.props.t('jobTranslations.jobTypes.unknownDropoff')}</em>
                                                        </MenuItem>
                                                        <MenuItem value="unknownPickup">
                                                            <em>{this.props.t('jobTranslations.jobTypes.unknownPickup')}</em>
                                                        </MenuItem>
                                                        <MenuItem value="demolition">
                                                            <em>{this.props.t('jobTranslations.jobTypes.demolition')}</em>
                                                        </MenuItem>
                                                        <MenuItem value="waste">
                                                            <em>{this.props.t('jobTranslations.jobTypes.waste')}</em>
                                                        </MenuItem>
                                                    </Select>
                                                    {/* <FormHelperText>Select your site that will serve as the job's pickup or dropoff location</FormHelperText> */}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md hiddenFields={this.state.hiddenFields} fieldName="privacy_status">
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="job_privacy">{this.props.t('jobTranslations.jobPrivacy')}</InputLabel>
                                                    <Select
                                                        label={this.props.t('jobTranslations.jobPrivacy')}
                                                        labelId="job_privacy"
                                                        value={this.state.privacy_status}
                                                        onChange={this.handleChange('privacy_status')}
                                                        inputProps={{
                                                            name: "job_privacy",
                                                            id: "job_privacy"
                                                        }}
                                                        fullWidth
                                                        required
                                                        error={!this.state.privacy_status}
                                                    >
                                                        {this.state.canMakePublic && <MenuItem value={'public'}>{this.props.t('jobTranslations.jobPrivacies.public')}</MenuItem>}
                                                        <MenuItem value={'exclusive'}>{this.props.t('jobTranslations.jobPrivacies.exclusive')}</MenuItem>
                                                        <MenuItem value={'whitelist'}>Whitelist</MenuItem>
                                                        <MenuItem value={'private'}>{this.props.t('jobTranslations.jobPrivacies.private')}</MenuItem>
                                                    </Select>
                                                    {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} md hiddenFields={this.state.hiddenFields} fieldName="currency">
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="job_currency">{this.props.t('jobTranslations.actions.selectCurrency')}</InputLabel>
                                                    <Select
                                                        labelId="job_currency"
                                                        label={this.props.t('jobTranslations.actions.selectCurrency')}
                                                        value={this.state.currency}
                                                        onChange={this.handleChange('currency')}
                                                        inputProps={{
                                                            name: "job_currency",
                                                            id: "job_currency"
                                                        }}
                                                        fullWidth
                                                        required
                                                        error={!this.state.currency}
                                                    >
                                                        {this.props.currencies.map(currency => (
                                                            <MenuItem key={currency.code} value={currency}>{currency.symbol_prefix} - {currency.code}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {/* <FormHelperText>Select your site that will serve as the job's pickup or dropoff location</FormHelperText> */}
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>
                                            <Grid item xs={12} m={3}>
                                                <Typography mb={5} color={this.state.jobType == 'standard' ? 'textPrimary' : 'textSecondary'}><Bold>{this.props.t('jobTranslations.jobTypes.standard')}: </Bold>{this.props.t('jobTranslations.jobTypes.standardDescription')}</Typography>
                                                <Typography mb={5} color={this.state.jobType == 'fourway' ? 'textPrimary' : 'textSecondary'}><Bold>{this.props.t('jobTranslations.jobTypes.fourway')}: </Bold>{this.props.t('jobTranslations.jobTypes.fourwayDescription')}</Typography>
                                                <Typography mb={5} color={this.state.jobType == 'unknownDropoff' ? 'textPrimary' : 'textSecondary'}><Bold>{this.props.t('jobTranslations.jobTypes.unknownDropoff')}: </Bold>{this.props.t('jobTranslations.jobTypes.unknownDropoffDescription')}</Typography>
                                                <Typography mb={5} color={this.state.jobType == 'unknownPickup' ? 'textPrimary' : 'textSecondary'}><Bold>{this.props.t('jobTranslations.jobTypes.unknownPickup')}: </Bold>{this.props.t('jobTranslations.jobTypes.unknownPickupDescription')}</Typography>
                                                <Typography mb={5} color={this.state.jobType == 'demolition' ? 'textPrimary' : 'textSecondary'}><Bold>{this.props.t('jobTranslations.jobTypes.demolition')}: </Bold>{this.props.t('jobTranslations.jobTypes.demolitionDescription')}</Typography>
                                                <Typography mb={5} color={this.state.jobType == 'waste' ? 'textPrimary' : 'textSecondary'}><Bold>{this.props.t('jobTranslations.jobTypes.waste')}: </Bold>{this.props.t('jobTranslations.jobTypes.wasteDescription')}</Typography>
                                            </Grid>
                                        </Grid>
                                        {this.state.privacy_status == 'whitelist' && <>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12}>
                                                    <SectionHeader title={"Whitelisted Companies"} variant="h3"/>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="companyFilter"
                                                        label={`Filter Companies`}
                                                        value={this.state.whitelistFilter}
                                                        onChange={this.handleChange('whitelistFilter')}
                                                        type="text"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                            <WhitelistGrid
                                                selectedCompanyIDs={this.state.selectedWhitelistCompanyIDs}
                                                chunkedPreferredDrivers={this.state.chunkedPreferredDrivers}
                                                isChecked={this.isChecked}
                                                handleCheckChange={this.handleCheckChange}
                                                maxHeight={250}
                                            />
                                        </>}
                                        {Boolean(this.state.jobType) && <Grid container spacing={10}>
                                            <Grid item xs={12}>
                                                <SectionHeader title={this.getJobTypeSubheader()} variant="h3" />
                                            </Grid>
                                        </Grid>}
                                        <Grid container spacing={10}>

                                            {this.state.jobType === 'RMHMA' && <Grid item xs>
                                                <ExtendedAttributesPartialRMHMA
                                                    setExtendedAttributeValues={this.setExtendedAttributeValues}
                                                    existingExtendedAttributeValues={this.state.extended_attribute_values}
                                                    existingExtendedAttributeValuesMap={this.state.extended_attribute_values_map}
                                                    preferredDrivers={this.state.preferredDrivers}
                                                    specificHaulers={this.state.specificHaulers}
                                                    setState={this.setStateIfMounted}
                                                    type={this.props.type}
                                                    jobState={this.state}
                                                />
                                            </Grid>}
                                            {(
                                                this.state.jobType === 'standard' ||
                                                this.state.jobType === 'unknownDropoff' ||
                                                this.state.jobType === 'unknownPickup' || 
                                                this.state.jobType === 'waste'
                                            ) ? <>
                                                <Grid item xs>
                                                    <Grid container spacing={10}>
                                                        <Grid item xs={12} md={6}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="customer_name"
                                                                    label={`Customer Name (Optional)`}
                                                                    value={this.state.customer_name}
                                                                    onChange={this.handleChange("customer_name")}
                                                                    type="text"
                                                                    variant="outlined"
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="customer_phone_number"
                                                                    label={`Customer Phone Number (Optional)`}
                                                                    value={this.state.customer_phone_number}
                                                                    onChange={this.handleChange("customer_phone_number")}
                                                                    type="text"
                                                                    variant="outlined"
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </> : null}
                                            {(this.state.jobType == 'fourway' || this.state.jobType == 'demolition') && (<> 
                                                {this.state.jobType == 'fourway' && (
                                                    <Grid item xs={12} md={6}>
                                                        <Grid container spacing={10}>
                                                            <Grid item xs={12}>
                                                                <FormControl fullWidth variant="outlined">
                                                                    <InputLabel id="job_supplier">{this.props.t('jobTranslations.materialSupplier')}</InputLabel>
                                                                    <Select
                                                                        label={this.props.t('jobTranslations.materialSupplier')}
                                                                        labelId="Select Material Supplier"
                                                                        value={this.state.supplier}
                                                                        onChange={this.handleChange('supplier')}
                                                                        inputProps={{
                                                                            name: "job_supplier",
                                                                            id: "job_supplier"
                                                                        }}
                                                                        fullWidth
                                                                        required
                                                                        error={!this.state.supplier}
                                                                    >
                                                                        {this.state.preferredSuppliers.map(supplier => (
                                                                            <MenuItem key={supplier.id} value={supplier}>{supplier.company_supplier.name}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={10}>
                                                            <Grid item xs={12}>
                                                                <FormControl component="fieldset">
                                                                    <FormLabel component="legend">{this.props.t('jobTranslations.supplierTransactionType')}</FormLabel>
                                                                    <RadioGroup
                                                                        aria-label="company_supplier_transaction_type_id"
                                                                        name="company_supplier_transaction_type_id"
                                                                        value={this.state.company_supplier_transaction_type_id}
                                                                        onChange={this.handleChange('company_supplier_transaction_type_id', 'number')}
                                                                        row={false}
                                                                    >
                                                                        <FormControlLabel
                                                                            value={1}
                                                                            control={<Radio color="primary" required/>}
                                                                            label={this.props.t('jobTranslations.transactionTypes.per-load')}
                                                                            labelPlacement="end"
                                                                        />
                                                                        <FormControlLabel
                                                                            value={2}
                                                                            control={<Radio color="primary" required/>}
                                                                            label={this.props.t('jobTranslations.transactionTypes.per-weight')}
                                                                            labelPlacement="end"
                                                                        />
                                                                        <FormControlLabel
                                                                            value={4}
                                                                            control={<Radio color="primary" required/>}
                                                                            label={this.props.t('jobTranslations.transactionTypes.per-volume')}
                                                                            labelPlacement="end"
                                                                        />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={10}>
                                                            <Grid item xs={12} md={(this.state.company_supplier_transaction_type_id == 2 || this.state.company_supplier_transaction_type_id == 4) ? 6 : 12}>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        id="company_supplier_price"
                                                                        label={`${this.props.t('jobTranslations.materialSupplierPrice')} ${this.state.currency && `(${this.state.currency.symbol_prefix} - ${this.state.currency.code})`}`}
                                                                        value={this.state.company_supplier_price}
                                                                        onChange={this.handleChange("company_supplier_price")}
                                                                        type="number"
                                                                        required
                                                                        inputProps={{
                                                                            min: 1,
                                                                            step: 0.01
                                                                        }}
                                                                        m={2}
                                                                        variant="outlined"
                                                                        InputProps={{
                                                                            startAdornment: <InputAdornment position="start">{this.state.currency && this.state.currency.symbol_prefix}</InputAdornment>,
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            {this.state.company_supplier_transaction_type_id == 2 && <Grid item xs={12} md={6}>
                                                                <FormControl fullWidth variant="outlined"> 
                                                                    <InputLabel id="job_supplier_weight_unit">{this.props.t('unitTranslations.weightUnit')}</InputLabel>
                                                                    <Select
                                                                        label={this.props.t('unitTranslations.weightUnit')}
                                                                        labelId="job_supplier_weight_unit"
                                                                        value={this.state.company_supplier_per_weight_job_units}
                                                                        onChange={this.handleChange('company_supplier_per_weight_job_units')}
                                                                        inputProps={{
                                                                            name: "job_supplier_weight_unit",
                                                                            id: "job_supplier_weight_unit"
                                                                        }}
                                                                        fullWidth
                                                                        required
                                                                        error={!this.state.company_supplier_per_weight_job_units}
                                                                    >
                                                                        {this.props.units.weight && this.props.units.weight.map(unit => (
                                                                            <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.weightUnits.${unit}`)}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                                </FormControl>
                                                            </Grid>}
                                                            {this.state.company_supplier_transaction_type_id == 4 && <Grid item xs={12} md={6}>
                                                                <FormControl fullWidth variant="outlined">
                                                                    <InputLabel id="job_supplier_volume_unit">{this.props.t('unitTranslations.volumeUnit')}</InputLabel>
                                                                    <Select
                                                                        label={this.props.t('unitTranslations.volumeUnit')}
                                                                        labelId="job_supplier_volume_unit"
                                                                        value={this.state.company_supplier_per_volume_job_units}
                                                                        onChange={this.handleChange('company_supplier_per_volume_job_units')}
                                                                        inputProps={{
                                                                            name: "job_supplier_volume_unit",
                                                                            id: "job_supplier_volume_unit"
                                                                        }}
                                                                        fullWidth
                                                                        required
                                                                        error={!this.state.company_supplier_per_volume_job_units}
                                                                    >
                                                                        {this.props.units.volume && this.props.units.volume.map(unit => (
                                                                            <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.volumeUnits.${unit}`)}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>}
                                                        </Grid>
                                                        <Grid container spacing={10}>
                                                            <Grid item xs={12}>
                                                                <FormControl component="fieldset">
                                                                    <FormLabel component="legend">{this.props.t('jobTranslations.payOnTruckpay')}</FormLabel>
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={this.state.company_supplier_invoices_to_be_paid_through_truckpay}
                                                                                    onChange={this.handleChange("company_supplier_invoices_to_be_paid_through_truckpay", 'boolean')}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {this.state.jobType == 'demolition' && (
                                                    <Grid item xs={12} md={6}>
                                                        <Grid container spacing={10}>
                                                            <Grid item xs={12}>
                                                                <FormControl fullWidth variant="outlined">
                                                                    <InputLabel id="job_destination">{this.props.t('jobTranslations.destination')}</InputLabel>
                                                                    <Select
                                                                        label={this.props.t('jobTranslations.destination')}
                                                                        labelId="job_destination"
                                                                        value={this.state.destination}
                                                                        onChange={this.handleChange('destination')}
                                                                        inputProps={{
                                                                            name: "job_destination",
                                                                            id: "job_destination"
                                                                        }}
                                                                        fullWidth
                                                                        required
                                                                        error={!this.state.destination}
                                                                    >
                                                                        {this.state.preferredDestinations.map(destination => (
                                                                            <MenuItem key={destination.id} value={destination}>{destination.demolition_destination_company.name}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={10}>
                                                            <Grid item xs={12}>
                                                                <FormControl component="fieldset">
                                                                    <FormLabel component="legend">{this.props.t('jobTranslations.destinationTransactionType')}</FormLabel>
                                                                    <RadioGroup
                                                                        aria-label="demolition_destination_transaction_type_id"
                                                                        name="demolition_destination_transaction_type_id"
                                                                        value={this.state.demolition_destination_transaction_type_id}
                                                                        onChange={this.handleChange('demolition_destination_transaction_type_id', 'number')}
                                                                        row={false}
                                                                    >
                                                                        <FormControlLabel
                                                                            value={1}
                                                                            control={<Radio color="primary" required />}
                                                                            label={this.props.t('jobTranslations.transactionTypes.per-load')}
                                                                            labelPlacement="end"
                                                                        />
                                                                        <FormControlLabel
                                                                            value={2}
                                                                            control={<Radio color="primary" required />}
                                                                            label={this.props.t('jobTranslations.transactionTypes.per-weight')}
                                                                            labelPlacement="end"
                                                                        />
                                                                        <FormControlLabel
                                                                            value={4}
                                                                            control={<Radio color="primary" required />}
                                                                            label={this.props.t('jobTranslations.transactionTypes.per-volume')}
                                                                            labelPlacement="end"
                                                                        />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={10}>
                                                            <Grid item xs={12} md={(this.state.demolition_destination_transaction_type_id == 2 || this.state.demolition_destination_transaction_type_id == 4) ? 6 : 12}>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        id="demolition_destination_price"
                                                                        label={`${this.props.t('jobTranslations.destinationPrice')} ${this.state.currency && `(${this.state.currency.symbol_prefix} - ${this.state.currency.code})`}`}
                                                                        value={this.state.demolition_destination_price}
                                                                        onChange={this.handleChange("demolition_destination_price")}
                                                                        type="number"
                                                                        variant="outlined"
                                                                        required
                                                                        inputProps={{
                                                                            min: 1,
                                                                            step: 0.01
                                                                        }}
                                                                        InputProps={{
                                                                            startAdornment: <InputAdornment position="start">{this.state.currency && this.state.currency.symbol_prefix}</InputAdornment>,
                                                                        }}
                                                                        m={2}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            {this.state.demolition_destination_transaction_type_id == 2 && <Grid item xs={12} md={6}>
                                                                <FormControl fullWidth variant="outlined">
                                                                    <InputLabel id="job_demolition_weight_unit">{this.props.t('unitTranslations.weightUnit')}</InputLabel>
                                                                    <Select
                                                                        label={this.props.t('unitTranslations.weightUnit')}
                                                                        labelId="job_demolition_weight_unit"
                                                                        value={this.state.demolition_destination_per_weight_job_units}
                                                                        onChange={this.handleChange('demolition_destination_per_weight_job_units')}
                                                                        inputProps={{
                                                                            name: "job_demolition_weight_unit",
                                                                            id: "job_demolition_weight_unit"
                                                                        }}
                                                                        fullWidth
                                                                        required
                                                                        error={!this.state.demolition_destination_per_weight_job_units}
                                                                    >
                                                                        {this.props.units.weight && this.props.units.weight.map(unit => (
                                                                            <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.weightUnits.${unit}`)}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                                </FormControl>
                                                            </Grid>}
                                                            {this.state.demolition_destination_transaction_type_id == 4 && <Grid item xs={12} md={6}>
                                                                <FormControl fullWidth variant="outlined">
                                                                    <InputLabel id="job_demolition_volume_unit">{this.props.t('unitTranslations.volumeUnit')}</InputLabel>
                                                                    <Select
                                                                        label={this.props.t('unitTranslations.volumeUnit')}
                                                                        labelId="job_demolition_volume_unit"
                                                                        value={this.state.demolition_destination_per_volume_job_units}
                                                                        onChange={this.handleChange('demolition_destination_per_volume_job_units')}
                                                                        inputProps={{
                                                                            name: "job_demolition_volume_unit",
                                                                            id: "job_demolition_volume_unit"
                                                                        }}
                                                                        fullWidth
                                                                        required
                                                                        error={!this.state.demolition_destination_per_volume_job_units}
                                                                    >
                                                                        {this.props.units.volume && this.props.units.volume.map(unit => (
                                                                            <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.volumeUnits.${unit}`)}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>}
                                                        </Grid>
                                                        <Grid container spacing={10}>
                                                            <Grid item xs={12}>
                                                                <FormControl component="fieldset">
                                                                    <FormLabel component="legend">{this.props.t('jobTranslations.payOnTruckpay')}</FormLabel>
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={this.state.demolition_destination_invoices_to_be_paid_through_truckpay}
                                                                                    onChange={this.handleChange("demolition_destination_invoices_to_be_paid_through_truckpay", 'boolean')}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={10}>
                                                            <Grid item xs={12}>
                                                                <Grid item xs={12}>
                                                                    <FormControl component="fieldset">
                                                                        <FormLabel component="legend">Destination Company Pays</FormLabel>
                                                                        <FormGroup>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Switch
                                                                                        checked={this.state.demolition_destination_company_pays}
                                                                                        onChange={this.handleChange("demolition_destination_company_pays", 'boolean')}
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                            />
                                                                        </FormGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                <Grid item xs={12} md={6}>
                                                    <Grid container spacing={10}>
                                                        <Grid item xs={12}>
                                                            <FormControl fullWidth variant="outlined">
                                                                <InputLabel id="job_customer">{this.props.t('jobTranslations.customer')}</InputLabel>
                                                                <Select
                                                                    labelId="job_customer"
                                                                    label={this.props.t('jobTranslations.customer')}
                                                                    value={this.state.customer}
                                                                    onChange={this.handleChange('customer')}
                                                                    inputProps={{
                                                                        name: "job_customer",
                                                                        id: "job_customer"
                                                                    }}
                                                                    fullWidth
                                                                    required
                                                                    error={!this.state.customer}
                                                                >
                                                                    {this.state.preferredCustomers.map(customer => (
                                                                        <MenuItem key={customer.id} value={customer}>{customer.company_customer.name}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {/* <FormHelperText>Select your which of your preferred Customers will be involved with the job.</FormHelperText> */}
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={10}>
                                                        <Grid item xs={12}>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">{this.props.t('jobTranslations.customerTransactionType')}</FormLabel>
                                                                <RadioGroup
                                                                    aria-label="company_customer_transaction_type_id"
                                                                    name="company_customer_transaction_type_id"
                                                                    value={this.state.company_customer_transaction_type_id}
                                                                    onChange={this.handleChange('company_customer_transaction_type_id', 'number')}
                                                                    row={false}
                                                                >
                                                                    <FormControlLabel
                                                                        value={1}
                                                                        control={<Radio color="primary" required />}
                                                                        label={this.props.t('jobTranslations.transactionTypes.per-load')}
                                                                        labelPlacement="end"
                                                                    />
                                                                    <FormControlLabel
                                                                        value={2}
                                                                        control={<Radio color="primary" required />}
                                                                        label={this.props.t('jobTranslations.transactionTypes.per-weight')}
                                                                        labelPlacement="end"
                                                                    />
                                                                    <FormControlLabel
                                                                        value={4}
                                                                        control={<Radio color="primary" required />}
                                                                        label={this.props.t('jobTranslations.transactionTypes.per-volume')}
                                                                        labelPlacement="end"
                                                                    />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={10}>
                                                        <Grid item xs={12} md={(this.state.company_customer_transaction_type_id == 2 || this.state.company_customer_transaction_type_id == 4) ? 6 : 12}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="company_customer_price"
                                                                    label={`${this.props.t('jobTranslations.destinationPrice')} ${this.state.currency && `(${this.state.currency.symbol_prefix} - ${this.state.currency.code})`}`}
                                                                    value={this.state.company_customer_price}
                                                                    onChange={this.handleChange("company_customer_price")}
                                                                    type="number"
                                                                    variant="outlined"
                                                                    required
                                                                    inputProps={{
                                                                        min: 1,
                                                                        step: 0.01
                                                                    }}
                                                                    m={2}
                                                                    InputProps={{
                                                                        startAdornment: <InputAdornment position="start">{this.state.currency && this.state.currency.symbol_prefix}</InputAdornment>,
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        {this.state.company_customer_transaction_type_id == 2 && <Grid item xs={12} md={6}>
                                                            <FormControl fullWidth variant="outlined">
                                                                <InputLabel id="job_customer_weight_unit">{this.props.t('unitTranslations.weightUnit')}</InputLabel>
                                                                <Select
                                                                    label={this.props.t('unitTranslations.weightUnit')}
                                                                    labelId="job_customer_weight_unit"
                                                                    value={this.state.company_customer_per_weight_job_units}
                                                                    onChange={this.handleChange('company_customer_per_weight_job_units')}
                                                                    inputProps={{
                                                                        name: "job_customer_weight_unit",
                                                                        id: "job_customer_weight_unit"
                                                                    }}
                                                                    fullWidth
                                                                    required
                                                                    error={!this.state.company_customer_per_weight_job_units}
                                                                >
                                                                    {this.props.units.weight && this.props.units.weight.map(unit => (
                                                                        <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.weightUnits.${unit}`)}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {/* <FormHelperText>Select your which of your preferred Material customers will be involved with the job.</FormHelperText> */}
                                                            </FormControl>
                                                        </Grid>}
                                                        {this.state.company_customer_transaction_type_id == 4 && <Grid item xs={12} md={6}>
                                                            <FormControl fullWidth variant="outlined">
                                                                <InputLabel id="job_customer_volume_unit">{this.props.t('unitTranslations.volumeUnit')}</InputLabel>
                                                                <Select
                                                                    label={this.props.t('unitTranslations.volumeUnit')}
                                                                    labelId="job_customer_volume_unit"
                                                                    value={this.state.company_customer_per_volume_job_units}
                                                                    onChange={this.handleChange('company_customer_per_volume_job_units')}
                                                                    inputProps={{
                                                                        name: "job_customer_volume_unit",
                                                                        id: "job_customer_volume_unit"
                                                                    }}
                                                                    fullWidth
                                                                    required
                                                                    error={!this.state.company_customer_per_volume_job_units}
                                                                >
                                                                    {this.props.units.volume && this.props.units.volume.map(unit => (
                                                                        <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.volumeUnits.${unit}`)}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>}
                                                    </Grid>
                                                    <Grid container spacing={10}>
                                                        <Grid item xs={12}>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">{this.props.t('jobTranslations.payOnTruckpay')}</FormLabel>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={this.state.company_customer_invoices_to_be_paid_through_truckpay}
                                                                                onChange={this.handleChange("company_customer_invoices_to_be_paid_through_truckpay", 'boolean')}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>)}
                                        </Grid>
                                        {Boolean(this.state.hasAdvancedSettings[1]) && <>
                                            <Grid container spacing={10}>
                                                {this.state.showAdvancedSettings[1] ? <Grid item xs>
                                                    <Button
                                                        fullWidth
                                                        onClick={() => this.setStateIfMounted({ 
                                                            showAdvancedSettings: {
                                                                ...this.state.showAdvancedSettings,
                                                                1: false
                                                            }
                                                        })}
                                                        type="button"
                                                    >
                                                        Hide Advanced Settings
                                                    </Button>
                                                </Grid> : <Grid item xs>
                                                    <Button
                                                        fullWidth
                                                        onClick={() => this.setStateIfMounted({
                                                            showAdvancedSettings: {
                                                                ...this.state.showAdvancedSettings,
                                                                1: true
                                                            }
                                                        })}
                                                        type="button"
                                                    >
                                                        Show Advanced Settings
                                                    </Button>
                                                </Grid>}
                                            </Grid>
                                        </>}
                                        {Boolean(this.state.showAdvancedSettings[1]) && <>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="job_privacy">{this.props.t('jobTranslations.jobPrivacy')}</InputLabel>
                                                        <Select
                                                            label={this.props.t('jobTranslations.jobPrivacy')}
                                                            labelId="job_privacy"
                                                            value={this.state.privacy_status}
                                                            onChange={this.handleChange('privacy_status')}
                                                            inputProps={{
                                                                name: "job_privacy",
                                                                id: "job_privacy"
                                                            }}
                                                            fullWidth
                                                            required
                                                            error={!this.state.privacy_status}
                                                        >
                                                            {this.state.canMakePublic && <MenuItem value={'public'}>{this.props.t('jobTranslations.jobPrivacies.public')}</MenuItem>}
                                                            <MenuItem value={'exclusive'}>{this.props.t('jobTranslations.jobPrivacies.exclusive')}</MenuItem>
                                                            <MenuItem value={'whitelist'}>Whitelist</MenuItem>
                                                            <MenuItem value={'private'}>{this.props.t('jobTranslations.jobPrivacies.private')}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </>}
                                        <Grid container spacing={10}>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" fullWidth type="button" onClick={() => this.prevPage()}>
                                                    {this.props.t('actions.previous')}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" fullWidth type="submit">
                                                    {this.props.t('actions.next')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>}

                                </TabPanel>
                                <TabPanel value={this.state.tabValue} index={this.eTicketTargetsPage} style={{ display: (this.state.tabValue == this.eTicketTargetsPage && !this.state.loading) ? "" : "none" }}>
                                    {this.state.tabValue == this.eTicketTargetsPage && <form autoComplete="off" onSubmit={(event) => this.nextPage(event, this.eTicketTargetsPage)}>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">{this.props.t('jobTranslations.sendRealtimeETickets')}</FormLabel>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={this.state.send_realtime_etickets}
                                                                    onChange={this.handleChange("send_realtime_etickets", 'boolean')}
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <AddETicketTargets
                                            setState={this.setStateIfMounted}
                                            state={this.state}
                                        />
                                        <Grid container spacing={10}>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" fullWidth type="button" onClick={() => this.prevPage()}>
                                                    {this.props.t('actions.previous')}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" fullWidth type="submit">
                                                    {this.props.t('actions.next')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>}

                                </TabPanel>
                                <TabPanel value={this.state.tabValue} index={this.jobDetailsPage} style={{ display: (this.state.tabValue == this.jobDetailsPage && !this.state.loading) ? "" : "none" }}>
                                    {this.state.tabValue == this.jobDetailsPage && <form autoComplete="off" onSubmit={(event) => this.nextPage(event, this.jobDetailsPage)}>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={12}>
                                                <SectionHeader title={this.props.t('jobTranslations.jobName')}/>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={12}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="name"
                                                        label={this.props.t('jobTranslations.jobName')}
                                                        value={this.state.name}
                                                        onChange={this.handleChange("name")}
                                                        type="text"
                                                        error={!this.state.name}
                                                        variant="outlined"
                                                        required
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={12}>
                                                <SectionHeader title={this.props.t('timeTranslations.datesAndTimes')} />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={this.state.privacy_status != 'private' ? 4 : 6}>
                                                <FormControl fullWidth>
                                                    <KeyboardDatePicker
                                                        label={this.props.t('timeTranslations.startDate')}
                                                        value={this.state.start_date}
                                                        onChange={this.handleChange('start_date', 'date')}
                                                        format="MM/dd/yyyy"
                                                        min={this.today}
                                                        inputVariant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={this.state.privacy_status != 'private' ? 4 : 6}>
                                                <FormControl fullWidth>
                                                    <KeyboardDatePicker
                                                        label={this.props.t('timeTranslations.endDate')}
                                                        value={this.state.end_date}
                                                        onChange={this.handleChange('end_date', 'date')}
                                                        format="MM/dd/yyyy"
                                                        min={this.today}
                                                        inputVariant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            {this.state.privacy_status != 'private' && <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <KeyboardDatePicker
                                                        label={this.props.t('jobTranslations.biddingEnds')}
                                                        value={this.state.bidding_ends}
                                                        onChange={this.handleChange('bidding_ends', 'date')}
                                                        format="MM/dd/yyyy"
                                                        min={this.today}
                                                        inputVariant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>}
                                        </Grid>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <KeyboardTimePicker
                                                        label={this.props.t('timeTranslations.openingTime')}
                                                        value={this.state.opening_time}
                                                        onChange={this.handleChange('opening_time', 'time')}
                                                        min={this.today}
                                                        inputVariant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <KeyboardTimePicker
                                                        label={this.props.t('timeTranslations.closingTime')}
                                                        value={this.state.closing_time}
                                                        onChange={this.handleChange('closing_time', 'time')}
                                                        min={this.today}
                                                        inputVariant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={12}>
                                                <SectionHeader title={this.props.t('vehicleTranslations.equipment')} />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth variant="outlined">
                                                    <Autocomplete
                                                        id="job_equipment"
                                                        disableClearable
                                                        autoComplete
                                                        clearOnBlur={false}
                                                        options={this.props.equipmentTypes}
                                                        getOptionLabel={(eq) => eq.name}
                                                        onChange={(event, val) => this.setStateIfMounted({ equipmentType: val })}
                                                        onInputChange={(event, val, reason) => {
                                                            if (reason != 'reset' || val) {
                                                                this.setStateIfMounted({ searchEquipment: val })
                                                            }
                                                        }}
                                                        value={this.state.equipmentType}
                                                        inputValue={this.state.searchEquipment}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                id="searchEquipment"
                                                                label={this.props.t('vehicleTranslations.equipmentType')}
                                                                type="text"
                                                                variant="outlined"
                                                                error={!this.state.equipment.length}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            {/* <Grid item xs={12} md={2}>
                                                <Button
                                                    variant="contained"
                                                    type="button"
                                                    color="primary"
                                                    onClick={() => this.addEquipment()}
                                                    fullWidth
                                                    disabled={Boolean(this.state.jobType === 'RMHMA' && this.state.equipment.length >= 1)}
                                                >
                                                    {this.props.t('actions.add')}
                                                </Button>
                                            </Grid> */}
                                        </Grid>
                                        <Grid container spacing={10}>
                                            {this.state.equipment.map(equipment => (
                                                <Grid item xs={12} md={6} key={equipment.id}>
                                                    <Grid container spacing={10} direction="row" justify="space-between" alignItems="center">
                                                        <Grid item>
                                                            {equipment.name}
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                type="button"
                                                                color="default"
                                                                onClick={() => this.removeEquipment(equipment)}
                                                                aria-label={`Remove ${equipment.name}`}
                                                            >
                                                                <CloseIcon />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth my={2}>
                                                    <TextField
                                                        id="quantity"
                                                        label={this.props.t('vehicleTranslations.equipmentTypeQuantity')}
                                                        value={this.state.quantity}
                                                        onChange={this.handleChange("quantity")}
                                                        type="number"
                                                        variant="outlined"
                                                        inputProps={{
                                                            min: 1,
                                                            step: 1
                                                        }}
                                                        required
                                                        error={!this.state.quantity}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth my={2}>
                                                    <TextField
                                                        id="estimated_loads"
                                                        label={this.props.t('jobTranslations.estimatedNumLoads')}
                                                        value={this.state.estimated_loads}
                                                        onChange={this.handleChange("estimated_loads")}
                                                        type="number"
                                                        variant="outlined"
                                                        required
                                                        error={!this.state.estimated_loads}
                                                        inputProps={{
                                                            min: 1,
                                                            step: 1
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        {this.state.extended_job_type !== 'RMHMA' && <>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="estimated_amount"
                                                            label={`Estimated Weight`}
                                                            value={this.state.estimated_amount}
                                                            onChange={this.handleChange("estimated_amount")}
                                                            type="number"
                                                            variant="outlined"
                                                            inputProps={{
                                                                min: 0,
                                                                step: "any"
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="estimated_amount_units">{this.props.t('unitTranslations.weightUnit')}</InputLabel>
                                                        <Select
                                                            label={this.props.t('unitTranslations.weightUnit')}
                                                            labelId="estimated_amount_units"
                                                            value={this.state.estimated_amount_units}
                                                            onChange={this.handleChange('estimated_amount_units')}
                                                            inputProps={{
                                                                name: "estimated_amount_units",
                                                                id: "estimated_amount_units"
                                                            }}
                                                            fullWidth
                                                            required
                                                            error={!this.state.estimated_amount_units}
                                                        >
                                                            {this.props.units.weight && this.props.units.weight.map(unit => (
                                                                <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.weightUnits.${unit}`)}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="estimated_volume"
                                                            label={`Estimated Volume`}
                                                            value={this.state.estimated_volume}
                                                            onChange={this.handleChange("estimated_volume")}
                                                            type="number"
                                                            variant="outlined"
                                                            inputProps={{
                                                                min: 0,
                                                                step: "any"
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="estimated_volume_units">{this.props.t('unitTranslations.volumeUnit')}</InputLabel>
                                                        <Select
                                                            label={this.props.t('unitTranslations.volumeUnit')}
                                                            labelId="estimated_volume_units"
                                                            value={this.state.estimated_volume_units}
                                                            onChange={this.handleChange('estimated_volume_units')}
                                                            inputProps={{
                                                                name: "estimated_volume_units",
                                                                id: "estimated_volume_units"
                                                            }}
                                                            fullWidth
                                                        >
                                                            {this.props.units.volume && this.props.units.volume.map(unit => (
                                                                <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.${unit}`)}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </>}
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={12}>
                                                <SectionHeader title={this.props.t('settingsTranslations.payment')} />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={6} hiddenFields={this.state.hiddenFields} fieldName="transaction_type_id">
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">{this.props.t('jobTranslations.transactionType')}</FormLabel>
                                                    <RadioGroup
                                                        aria-label="transaction_type_id"
                                                        name="transaction_type_id"
                                                        value={this.state.transaction_type_id}
                                                        onChange={this.handleChange('transaction_type_id', 'number')}
                                                        row
                                                    >
                                                        <FormControlLabel
                                                            value={3}
                                                            control={<Radio color="primary" required />}
                                                            label={this.props.t('jobTranslations.transactionTypes.per-hour')}
                                                            labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                            value={1}
                                                            control={<Radio color="primary" required />}
                                                            label={this.props.t('jobTranslations.transactionTypes.per-load')}
                                                            labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                            value={2}
                                                            control={<Radio color="primary" required />}
                                                            label={this.props.t('jobTranslations.transactionTypes.per-weight')}
                                                            labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                            value={4}
                                                            control={<Radio color="primary" required />}
                                                            label={this.props.t('jobTranslations.transactionTypes.per-volume')}
                                                            labelPlacement="end"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            {this.state.transaction_type_id == 2 && <Grid item xs={12} md={6}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="job_weight_unit">{this.props.t('unitTranslations.weightUnit')}</InputLabel>
                                                    <Select
                                                        label={this.props.t('unitTranslations.weightUnit')}
                                                        labelId="job_weight_unit"
                                                        value={this.state.per_weight_job_units}
                                                        onChange={this.handleChange('per_weight_job_units')}
                                                        inputProps={{
                                                            name: "job_weight_unit",
                                                            id: "job_weight_unit"
                                                        }}
                                                        fullWidth
                                                        required
                                                        error={!this.state.per_weight_job_units}
                                                    >
                                                        {this.props.units.weight && this.props.units.weight.map(unit => (
                                                            <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.weightUnits.${unit}`)}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>}
                                            {this.state.transaction_type_id == 4 && <Grid item xs={12} md={6}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="job_volume_unit">{this.props.t('unitTranslations.volumeUnit')}</InputLabel>
                                                    <Select
                                                        label={this.props.t('unitTranslations.volumeUnit')}
                                                        labelId="job_volume_unit"
                                                        value={this.state.per_volume_job_units}
                                                        onChange={this.handleChange('per_volume_job_units')}
                                                        inputProps={{
                                                            name: "job_volume_unit",
                                                            id: "job_volume_unit"
                                                        }}
                                                        fullWidth
                                                        required
                                                        error={!this.state.per_volume_job_units}
                                                    >
                                                        {this.props.units.volume && this.props.units.volume.map(unit => (
                                                            <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.volumeUnits.${unit}`)}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>}
                                        </Grid>
                                        {this.state.privacy_status != 'private' && <>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md hiddenFields={this.state.hiddenFields} fieldName="bid_amount_status">
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="bid_amount_status">{this.props.t('jobTranslations.bidType')}</InputLabel>
                                                        <Select
                                                            label={this.props.t('jobTranslations.bidType')}
                                                            labelId="bid_amount_status"
                                                            value={this.state.bid_amount_status}
                                                            onChange={this.handleChange('bid_amount_status')}
                                                            inputProps={{
                                                                name: "bid_amount_status",
                                                                id: "bid_amount_status"
                                                            }}
                                                            fullWidth
                                                            required
                                                            error={!this.state.bid_amount_status}
                                                        >
                                                            <MenuItem value={'open'}>{this.props.t('jobTranslations.bid.options.open')}</MenuItem>
                                                            <MenuItem value={'fixed'}>{this.props.t('jobTranslations.bid.options.fixed')}</MenuItem>
                                                            <MenuItem value={'prenegotiated_only'}>{this.props.t('jobTranslations.bid.options.prenegotiated_only')}</MenuItem>
                                                        </Select>
                                                        {/* <FormHelperText>Select your which of youfr preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                    </FormControl>
                                                </Grid>
                                                {this.state.bid_amount_status == 'fixed' && <Grid item xs={12} md>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="fixed_price"
                                                            label={`Hauler Rate ${this.state.currency && `(${this.state.currency.symbol_prefix} - ${this.state.currency.code})`}`}
                                                            value={this.state.amount}
                                                            onChange={this.handleChange("amount")}
                                                            type="number"
                                                            variant="outlined"
                                                            required
                                                            error={!this.state.amount}
                                                            inputProps={{
                                                                min: 0.01,
                                                                step: 0.01
                                                            }}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">{this.state.currency && this.state.currency.symbol_prefix}</InputAdornment>,
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>}
                                            </Grid>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="invoices_to_be_paid_through_truckpay">
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.payOnTruckpay')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.invoices_to_be_paid_through_truckpay}
                                                                        onChange={this.handleChange("invoices_to_be_paid_through_truckpay", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                                {this.state.invoices_to_be_paid_through_truckpay && <>
                                                    <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="invoice_due_frequency">
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel id="invoice_due_frequency">{this.props.t('jobTranslations.invoiceDueFrequency')}</InputLabel>
                                                            <Select
                                                                label={this.props.t('jobTranslations.invoiceDueFrequency')}
                                                                labelId="invoice_due_frequency"
                                                                value={this.state.invoice_due_frequency}
                                                                onChange={this.handleChange('invoice_due_frequency')}
                                                                inputProps={{
                                                                    name: "invoice_due_frequency",
                                                                    id: "invoice_due_frequency"
                                                                }}
                                                                fullWidth
                                                                required
                                                                error={!this.state.invoice_due_frequency}
                                                            >
                                                                {this.state.frequencies.map(frequency => (
                                                                    <MenuItem key={frequency.value} value={frequency.value}>{this.props.t(`timeTranslations.rangeOptions.${frequency.value}`)}</MenuItem>
                                                                ))}
                                                            </Select>
                                                            {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="payment_due_frequency">
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel id="payment_due_frequency">{this.props.t('jobTranslations.paymentDueFrequency')}</InputLabel>
                                                            <Select
                                                                label={this.props.t('jobTranslations.paymentDueFrequency')}
                                                                labelId="payment_due_frequency"
                                                                value={this.state.payment_due_frequency}
                                                                onChange={this.handleChange('payment_due_frequency')}
                                                                inputProps={{
                                                                    name: "payment_due_frequency",
                                                                    id: "payment_due_frequency"
                                                                }}
                                                                fullWidth
                                                                required
                                                                error={!this.state.payment_due_frequency}
                                                            >
                                                                {this.state.frequencies.map(frequency => (
                                                                    <MenuItem key={frequency.value} value={frequency.value}>{this.props.t(`timeTranslations.rangeOptions.${frequency.value}`)}</MenuItem>
                                                                ))}
                                                            </Select>
                                                            {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                        </FormControl>
                                                    </Grid>
                                                </>}
                                            </Grid>
                                        </>}
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={12}>
                                                <SectionHeader title={this.props.t('other')} />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={10} hiddenFields={this.state.hiddenFields} fieldNames={['is_scan_picture_required', 'scan_fee_payer', 'auto_award']}>
                                            <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="is_scan_picture_required">
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">{this.props.t('deliveryTranslations.checkinPhoto')}</FormLabel>
                                                    <RadioGroup
                                                        aria-label="is_scan_picture_required"
                                                        name="is_scan_picture_required"
                                                        value={this.state.is_scan_picture_required}
                                                        onChange={this.handleChange('is_scan_picture_required', 'radio-boolean')}
                                                        row
                                                    >
                                                        <FormControlLabel
                                                            value={true}
                                                            control={<Radio color="primary" required />}
                                                            label={this.props.t('yes')}
                                                            labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                            value={false}
                                                            control={<Radio color="primary" required />}
                                                            label={this.props.t('no')}
                                                            labelPlacement="end"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            {this.state.privacy_status != 'private' && <>
                                                <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="scan_fee_payer">
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('deliveryTranslations.scanFeePayer')}</FormLabel>
                                                        <RadioGroup
                                                            aria-label="scan_fee_payer"
                                                            name="scan_fee_payer"
                                                            value={this.state.scan_fee_payer}
                                                            onChange={this.handleChange('scan_fee_payer')}
                                                            row
                                                        >
                                                            <FormControlLabel
                                                                value={'driver'}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('deliveryTranslations.scanFeePayerOptions.driver')}
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value={'company'}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('deliveryTranslations.scanFeePayerOptions.company')}
                                                                labelPlacement="end"
                                                            />
                                                        
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="auto_award">
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.autoAward')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.auto_award}
                                                                        onChange={this.handleChange("auto_award", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                            </>}
                                        </Grid>
                                        <Grid container spacing={10} hiddenFields={this.state.hiddenFields} fieldNames={['checkin_type', 'checkout_type', 'auto_checkout']}>
                                            <Grid item xs={12} md={(this.state.checkoutType && this.state.checkoutType.value == 'self-check') ? 4 : 6} hiddenFields={this.state.hiddenFields} fieldName="checkin_type">
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="checkin_type">{this.props.t('jobTranslations.checkinType')}</InputLabel>
                                                    <Select
                                                        label={this.props.t('jobTranslations.checkinType')}
                                                        labelId="checkin_type"
                                                        value={this.state.checkinType}
                                                        onChange={this.handleChange("checkinType")}
                                                        inputProps={{
                                                            name: "checkin_type",
                                                            id: "checkin_type"
                                                        }}
                                                        fullWidth
                                                        required
                                                        error={!this.state.checkinType}
                                                    >
                                                        {this.state.checkinTypes.map(type => (
                                                            <MenuItem key={type.value} value={type}>{this.props.t(`jobTranslations.checkTypes.${type.value}`)}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={(this.state.checkoutType && this.state.checkoutType.value == 'self-check') ? 4 : 6} hiddenFields={this.state.hiddenFields} fieldName="checkout_type">
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="checkout_type">{this.props.t('jobTranslations.checkoutType')}</InputLabel>
                                                    <Select
                                                        label={this.props.t('jobTranslations.checkoutType')}
                                                        labelId="checkout_type"
                                                        value={this.state.checkoutType}
                                                        onChange={this.handleChange('checkoutType')}
                                                        inputProps={{
                                                            name: "checkout_type",
                                                            id: "checkout_type"
                                                        }}
                                                        fullWidth
                                                        required
                                                        error={!this.state.checkoutType}
                                                        disabled={this.state.jobType == 'waste'}
                                                    >
                                                        {this.state.checkoutTypes.map(type => (
                                                            <MenuItem key={type.value} value={type}>{this.props.t(`jobTranslations.checkTypes.${type.value}`)}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                </FormControl>
                                            </Grid>
                                            {(this.state.checkoutType && this.state.checkoutType.value == 'self-check') && <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="auto_checkout">
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">{this.props.t('jobTranslations.autoCheckout')}</FormLabel>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={this.state.auto_checkout}
                                                                    onChange={this.handleChange("auto_checkout", 'boolean')}
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>}
                                        </Grid>
                                        {((this.state.checkinType && this.state.checkinType.value == 'signature') || (this.state.checkoutType && this.state.checkoutType.value == 'signature')) ? <Grid container spacing={10}>
                                            <Grid item xs={12} md={6}>
                                                {(this.state.checkinType && this.state.checkinType.value == 'signature') ?  <FormControl component="fieldset">
                                                    <FormLabel component="legend">{this.props.t('jobTranslations.requireDriverPickupSignature')}</FormLabel>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={this.state.is_driver_checkin_signature_required}
                                                                    onChange={this.handleChange("is_driver_checkin_signature_required", 'boolean')}
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    </FormGroup>
                                                </FormControl> : null}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                {(this.state.checkoutType && this.state.checkoutType.value == 'signature') ? <FormControl component="fieldset">
                                                    <FormLabel component="legend">{this.props.t('jobTranslations.requireDriverDropoffSignature')}</FormLabel>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={this.state.is_driver_checkout_signature_required}
                                                                    onChange={this.handleChange("is_driver_checkout_signature_required", 'boolean')}
                                                                    disabled={this.state.jobType == 'waste'}
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    </FormGroup>
                                                </FormControl> : null}
                                            </Grid>
                                        </Grid>: null}
                                        <Grid container spacing={10} hiddenFields={this.state.hiddenFields} fieldNames={['is_geo_fenced', 'geo_fence_distance_unit']}>
                                            <Grid item xs={12} md={6} hiddenFields={this.state.hiddenFields} fieldName="is_geo_fenced">
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">{this.props.t('jobTranslations.geofence')}</FormLabel>
                                                    <RadioGroup
                                                        aria-label="destination_is_geo_fenced"
                                                        name="destination_is_geo_fenced"
                                                        value={this.state.is_geo_fenced}
                                                        onChange={this.handleChange('is_geo_fenced', 'radio-boolean')}
                                                        row
                                                    >
                                                        <FormControlLabel
                                                            value={true}
                                                            control={<Radio color="primary" required />}
                                                            label={this.props.t('yes')}
                                                            labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                            value={false}
                                                            control={<Radio color="primary" required />}
                                                            label={this.props.t('no')}
                                                            labelPlacement="end"
                                                        />

                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            {this.state.is_geo_fenced && (
                                                <Grid item xs={12} md={6} hiddenFields={this.state.hiddenFields} fieldName="geo_fence_distance_unit">
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="destination_geo_fence_distance_unit">{this.props.t('unitTranslations.distanceUnit')}</InputLabel>
                                                        <Select
                                                            label={this.props.t('unitTranslations.distanceUnit')}
                                                            labelId="destination_geo_fence_distance_unit"
                                                            value={this.state.geo_fence_distance_unit}
                                                            onChange={this.handleChange('geo_fence_distance_unit')}
                                                            inputProps={{
                                                                name: "destination_geo_fence_distance_unit",
                                                                id: "destination_geo_fence_distance_unit"
                                                            }}
                                                            fullWidth
                                                            required
                                                            error={!this.state.geo_fence_distance_unit}
                                                        >
                                                            {this.props.units.length && this.props.units.length.map(unit => (
                                                                <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.distanceUnits.${unit}`)}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            )}
                                        </Grid>
                                        {this.state.is_geo_fenced && (
                                            <Grid container spacing={10} hiddenFields={this.state.hiddenFields} fieldNames={['pick_up_geo_fence_distance', 'drop_off_geo_fence_distance']}>
                                                <Grid item xs={12} md={6}  hiddenFields={this.state.hiddenFields} fieldName="pick_up_geo_fence_distance">
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="pickup_geofence_distance"
                                                            label={this.props.t('jobTranslations.pickGeofenceDistance')}
                                                            value={this.state.pick_up_geo_fence_distance}
                                                            onChange={this.handleChange('pick_up_geo_fence_distance')}
                                                            type="number"
                                                            variant="outlined"
                                                            inputProps={{
                                                                min: 0.01,
                                                                step: 0.01
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6}  hiddenFields={this.state.hiddenFields} fieldName="drop_off_geo_fence_distance">
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="dropoff_geofence_distance"
                                                            label={this.props.t('jobTranslations.dropGeofenceDistance')}
                                                            value={this.state.drop_off_geo_fence_distance}
                                                            onChange={this.handleChange('drop_off_geo_fence_distance')}
                                                            type="number"
                                                            variant="outlined"
                                                            inputProps={{
                                                                min: 0.01,
                                                                step: 0.01
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid container spacing={10} hiddenFields={this.state.hiddenFields} fieldNames={['bidding_geo_restriction', 'bidding_geo_restriction_distance', 'bidding_geo_restriction_distance_units']}>
                                            {this.state.privacy_status != 'private' && <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="bidding_geo_restriction">
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">{this.props.t('jobTranslations.biddingGeorestriction')}</FormLabel>
                                                    <RadioGroup
                                                        aria-label="bidding_geo_resriction"
                                                        name="bidding_geo_resriction"
                                                        value={this.state.bidding_geo_restriction}
                                                        onChange={this.handleChange('bidding_geo_restriction', 'radio-boolean')}
                                                        row
                                                    >
                                                        <FormControlLabel
                                                            value={true}
                                                            control={<Radio color="primary" required />}
                                                            label={this.props.t('yes')}
                                                            labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                            value={false}
                                                            control={<Radio color="primary" required />}
                                                            label={this.props.t('no')}
                                                            labelPlacement="end"
                                                        />

                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>}
                                            {this.state.bidding_geo_restriction && (<>
                                                <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="bidding_geo_restriction_distance">
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="bidding_geo_restriction_distance"
                                                            label={this.props.t('jobTranslations.biddingGeorestrictionDistance')}
                                                            value={this.state.bidding_geo_restriction_distance}
                                                            onChange={this.handleChange('bidding_geo_restriction_distance')}
                                                            type="number"
                                                            variant="outlined"
                                                            required
                                                            inputProps={{
                                                                min: 1,
                                                                step: 0.01
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="bidding_geo_restriction_distance_units">
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="bidding_geo_restriction_distance_units">{this.props.t('unitTranslations.distanceUnit')}</InputLabel>
                                                        <Select
                                                            label={this.props.t('unitTranslations.distanceUnit')}
                                                            labelId="bidding_geo_restriction_distance_units"
                                                            value={this.state.bidding_geo_restriction_distance_units}
                                                            onChange={this.handleChange('bidding_geo_restriction_distance_units')}
                                                            inputProps={{
                                                                name: "bidding_geo_restriction_distance_units",
                                                                id: "bidding_geo_restriction_distance_units"
                                                            }}
                                                            fullWidth
                                                            required
                                                            error={!this.state.bidding_geo_restriction_distance_units}
                                                        >
                                                            {this.props.units.length && this.props.units.length.map(unit => (
                                                                <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.distanceUnits.${unit}`)}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </>)}
                                        </Grid>
                                        <Grid container spacing={10} hiddenFields={this.state.hiddenFields} fieldNames={['track_driver_return_delivery_trip']}>
                                            <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName={'track_driver_return_delivery_trip'}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">{this.props.t('jobTranslations.trackDriverReturnRoute')}</FormLabel>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={this.state.track_driver_return_delivery_trip}
                                                                    onChange={this.handleChange("track_driver_return_delivery_trip", 'boolean')}
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                            {(!this.state.requires_sign_off && this.state.privacy_status != 'private') ? <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="requires_sign_off">
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">{this.props.t('jobTranslations.addSignOffMembers')}</FormLabel>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={this.state.requires_sign_off}
                                                                    onChange={this.handleChange("requires_sign_off", 'boolean')}
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid> : null}
                                            {(!this.state.addRainOut && this.state.privacy_status != 'private') ? <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="addRainOut">
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">{this.props.t('jobTranslations.addRainOut')}</FormLabel>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={this.state.addRainOut}
                                                                    onChange={this.handleChange("addRainOut", 'boolean')}
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid> : null}
                                            {(this.state.privacy_status != 'private' && !this.state.addNoncompete) ? <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName='addNoncompete'>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">{this.props.t('jobTranslations.noncompeteAgreement')}</FormLabel>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={this.state.addNoncompete}
                                                                    onChange={this.handleChange("addNoncompete", 'boolean')}
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid> : null}
                                        </Grid>
                                        {(this.state.requires_sign_off && this.state.privacy_status != 'private') ? (<>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.addSignOffMembers')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.requires_sign_off}
                                                                        onChange={this.handleChange("requires_sign_off", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="job_signOffMemberFirst">{this.props.t('jobTranslations.firstMember')}</InputLabel>
                                                        <Select
                                                            label={this.props.t('jobTranslations.firstMember')}
                                                            labelId="job_signOffMemberFirst"
                                                            value={this.state.signOffMemberFirst}
                                                            onChange={this.handleChange('signOffMemberFirst')}
                                                            inputProps={{
                                                                name: "job_signOffMemberFirst",
                                                                id: "job_signOffMemberFirst"
                                                            }}
                                                            fullWidth
                                                            required
                                                            error={!this.state.signOffMemberFirst}
                                                        >
                                                            {this.getAvailableSignOffEmployees(1).map(employee => (
                                                                <MenuItem key={employee.id} value={employee}>{employee.user.first_name} {employee.user.last_name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="job_signOffMemberSecond">{this.props.t('jobTranslations.secondMember')}</InputLabel>
                                                        <Select
                                                            label={this.props.t('jobTranslations.secondMember')}
                                                            labelId="job_signOffMemberSecond"
                                                            value={this.state.signOffMemberSecond}
                                                            onChange={this.handleChange('signOffMemberSecond')}
                                                            inputProps={{
                                                                name: "job_signOffMemberSecond",
                                                                id: "job_signOffMemberSecond"
                                                            }}
                                                            fullWidth
                                                        >
                                                            {this.getAvailableSignOffEmployees(2).map(employee => (
                                                                <MenuItem key={employee.id} value={employee}>{employee.user.first_name} {employee.user.last_name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="job_signOffMemberThird">{this.props.t('jobTranslations.thirdMember')}</InputLabel>
                                                        <Select
                                                            label={this.props.t('jobTranslations.thirdMember')}
                                                            labelId="job_signOffMemberThird"
                                                            value={this.state.signOffMemberThird}
                                                            onChange={this.handleChange('signOffMemberThird')}
                                                            inputProps={{
                                                                name: "job_signOffMemberThird",
                                                                id: "job_signOffMemberThird"
                                                            }}
                                                            fullWidth
                                                        >
                                                            {this.getAvailableSignOffEmployees(3).map(employee => (
                                                                <MenuItem key={employee.id} value={employee}>{employee.user.first_name} {employee.user.last_name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </>) : null}
                                        {(this.state.addRainOut && this.state.privacy_status != 'private') ? (<>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.addRainOut')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.addRainOut}
                                                                        onChange={this.handleChange("addRainOut", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="rain_out_units"
                                                            label={this.props.t('jobTranslations.rainOutUnits')}
                                                            value={this.state.rain_out_units}
                                                            onChange={this.handleChange("rain_out_units")}
                                                            type="number"
                                                            variant="outlined"
                                                            required
                                                            inputProps={{
                                                                min: 1,
                                                                step: 0.01
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="advanced_warning_hours"
                                                            label={this.props.t('jobTranslations.advancedWarningHours')}
                                                            value={this.state.advanced_warning_hours}
                                                            onChange={this.handleChange("advanced_warning_hours")}
                                                            type="number"
                                                            variant="outlined"
                                                            required
                                                            inputProps={{
                                                                min: 1,
                                                                step: 0.5
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </>) : null}
                                        {(this.state.addNoncompete && this.state.privacy_status != 'private') ? <>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} hiddenFields={this.state.hiddenFields} fieldName='addNoncompete'>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.noncompeteAgreement')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.addNoncompete}
                                                                        onChange={this.handleChange("addNoncompete", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={10} hiddenFields={this.state.hiddenFields} fieldNames={['noncompete_period', 'noncompete_distance', 'noncompete_distance_units']}>
                                                <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName='noncompete_period'>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="noncompete_period"
                                                            label={this.props.t('jobTranslations.noncompetePeriod')}
                                                            value={this.state.noncompete_period}
                                                            onChange={this.handleChange("noncompete_period", 'number')}
                                                            type="number"
                                                            required
                                                            inputProps={{
                                                                min: 1,
                                                                step: 1
                                                            }}
                                                            error={!this.state.noncompete_period}
                                                            variant="outlined"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName='noncompete_distance'>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="noncompete_distance"
                                                            label={this.props.t('jobTranslations.noncompeteDistance')}
                                                            value={this.state.noncompete_distance}
                                                            onChange={this.handleChange("noncompete_distance", 'number')}
                                                            type="number"
                                                            required
                                                            inputProps={{
                                                                min: 0,
                                                                step: 0.01
                                                            }}
                                                            error={!this.state.noncompete_distance}
                                                            variant="outlined"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName='noncompete_distance_units'>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="noncompete_distance_units">{this.props.t('unitTranslations.distanceUnit')}</InputLabel>
                                                        <Select
                                                            labelId="noncompete_distance_units"
                                                            label={this.props.t('unitTranslations.distanceUnit')}
                                                            value={this.state.noncompete_distance_units}
                                                            onChange={this.handleChange('noncompete_distance_units')}
                                                            fullWidth
                                                            required
                                                            inputProps={{
                                                                name: "noncompete_distance_units",
                                                                id: "noncompete_distance_units"
                                                            }}
                                                            error={!this.state.noncompete_distance_units}
                                                        >
                                                            {this.props.units.length.map(unit => (
                                                                <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.distanceUnits.${unit}`)}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </> : null}
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={12}>
                                                <Typography variant="subtitle2"><Bold>{this.props.t('userTranslations.emails')}</Bold></Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={10}>
                                            {!this.state.emails.length && <Grid item xs={12} md={12}>
                                                <Typography>{this.props.t('jobTranslations.errors.noExternalEmails')}</Typography>
                                            </Grid>}
                                            {this.state.emails.map((email, index) => (
                                                <Grid item xs={12} md={6} key={index}>
                                                    <Grid container spacing={10} direction="row" justify="space-between" alignItems="center">
                                                        <Grid item>
                                                            {email.email}
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                type="button"
                                                                color="default"
                                                                onClick={() => this.removeEmail(email)}
                                                            >
                                                                <CloseIcon />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={9}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_email"
                                                        label={this.props.t('exportTranslations.actions.addEmail')}
                                                        value={this.state.email}
                                                        onChange={this.handleChange("email")}
                                                        type="text"
                                                        variant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Button
                                                    variant="contained"
                                                    type="button"
                                                    color="primary"
                                                    onClick={() => this.addEmail()}
                                                >
                                                    {this.props.t('actions.add')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="notes"
                                                    label={this.props.t('jobTranslations.notes')}
                                                    multiline
                                                    onChange={this.handleChange('notes')}
                                                    value={this.state.notes}
                                                    rows="4"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TpTextfield
                                                    label={"Terms And Conditions"}
                                                    value={this.state.terms_and_conditions || ""}
                                                    onChange={this.handleChange('terms_and_conditions')}
                                                    textfieldProps={{
                                                        multiline: true,
                                                        rows: 4,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        {Boolean(this.state.hasAdvancedSettings[2]) && <>
                                            <Grid container spacing={10}>
                                                {this.state.showAdvancedSettings[2] ? <Grid item xs>
                                                    <Button
                                                        fullWidth
                                                        onClick={() => this.setStateIfMounted({
                                                            showAdvancedSettings: {
                                                                ...this.state.showAdvancedSettings,
                                                                2: false
                                                            }
                                                        })}
                                                        type="button"
                                                    >
                                                        Hide Advanced Settings
                                                    </Button>
                                                </Grid> : <Grid item xs>
                                                    <Button
                                                        fullWidth
                                                        onClick={() => this.setStateIfMounted({
                                                            showAdvancedSettings: {
                                                                ...this.state.showAdvancedSettings,
                                                                2: true
                                                            }
                                                        })}
                                                        type="button"
                                                    >
                                                        Show Advanced Settings
                                                    </Button>
                                                </Grid>}
                                            </Grid>
                                        </>}
                                        {Boolean(this.state.showAdvancedSettings[2]) && <>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={(this.state.checkoutType && this.state.checkoutType.value == 'self-check') ? 4 : 6}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="checkin_type">{this.props.t('jobTranslations.checkinType')}</InputLabel>
                                                        <Select
                                                            label={this.props.t('jobTranslations.checkinType')}
                                                            labelId="checkin_type"
                                                            value={this.state.checkinType}
                                                            onChange={this.handleChange("checkinType")}
                                                            inputProps={{
                                                                name: "checkin_type",
                                                                id: "checkin_type"
                                                            }}
                                                            fullWidth
                                                            required
                                                            error={!this.state.checkinType}
                                                        >
                                                            {this.state.checkinTypes.map(type => (
                                                                <MenuItem key={type.value} value={type}>{this.props.t(`jobTranslations.checkTypes.${type.value}`)}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={(this.state.checkoutType && this.state.checkoutType.value == 'self-check') ? 4 : 6}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="checkout_type">{this.props.t('jobTranslations.checkoutType')}</InputLabel>
                                                        <Select
                                                            label={this.props.t('jobTranslations.checkoutType')}
                                                            labelId="checkout_type"
                                                            value={this.state.checkoutType}
                                                            onChange={this.handleChange('checkoutType')}
                                                            inputProps={{
                                                                name: "checkout_type",
                                                                id: "checkout_type"
                                                            }}
                                                            fullWidth
                                                            required
                                                            error={!this.state.checkoutType}
                                                        >
                                                            {this.state.checkoutTypes.map(type => (
                                                                <MenuItem key={type.value} value={type}>{this.props.t(`jobTranslations.checkTypes.${type.value}`)}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                    </FormControl>
                                                </Grid>
                                                {(this.state.checkoutType && this.state.checkoutType.value == 'self-check') && <Grid item xs={12} md={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.autoCheckout')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.auto_checkout}
                                                                        onChange={this.handleChange("auto_checkout", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>}
                                            </Grid>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('deliveryTranslations.checkinPhoto')}</FormLabel>
                                                        <RadioGroup
                                                            aria-label="is_scan_picture_required"
                                                            name="is_scan_picture_required"
                                                            value={this.state.is_scan_picture_required}
                                                            onChange={this.handleChange('is_scan_picture_required', 'radio-boolean')}
                                                            row
                                                        >
                                                            <FormControlLabel
                                                                value={true}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('yes')}
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('no')}
                                                                labelPlacement="end"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.trackDriverReturnRoute')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.track_driver_return_delivery_trip}
                                                                        onChange={this.handleChange("track_driver_return_delivery_trip", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={10}>
                                                {this.state.privacy_status != 'private' && <Grid item xs={12} md={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.biddingGeorestriction')}</FormLabel>
                                                        <RadioGroup
                                                            aria-label="bidding_geo_resriction"
                                                            name="bidding_geo_resriction"
                                                            value={this.state.bidding_geo_restriction}
                                                            onChange={this.handleChange('bidding_geo_restriction', 'radio-boolean')}
                                                            row
                                                        >
                                                            <FormControlLabel
                                                                value={true}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('yes')}
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('no')}
                                                                labelPlacement="end"
                                                            />

                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>}
                                                {this.state.bidding_geo_restriction && (<>
                                                    <Grid item xs={12} md={4}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="bidding_geo_restriction_distance"
                                                                label={this.props.t('jobTranslations.biddingGeorestrictionDistance')}
                                                                value={this.state.bidding_geo_restriction_distance}
                                                                onChange={this.handleChange('bidding_geo_restriction_distance')}
                                                                type="number"
                                                                variant="outlined"
                                                                required
                                                                inputProps={{
                                                                    min: 1,
                                                                    step: 0.01
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel id="bidding_geo_restriction_distance_units">{this.props.t('unitTranslations.distanceUnit')}</InputLabel>
                                                            <Select
                                                                label={this.props.t('unitTranslations.distanceUnit')}
                                                                labelId="bidding_geo_restriction_distance_units"
                                                                value={this.state.bidding_geo_restriction_distance_units}
                                                                onChange={this.handleChange('bidding_geo_restriction_distance_units')}
                                                                inputProps={{
                                                                    name: "bidding_geo_restriction_distance_units",
                                                                    id: "bidding_geo_restriction_distance_units"
                                                                }}
                                                                fullWidth
                                                                required
                                                                error={!this.state.bidding_geo_restriction_distance_units}
                                                            >
                                                                {this.props.units.length && this.props.units.length.map(unit => (
                                                                    <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.distanceUnits.${unit}`)}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </>)}
                                            </Grid>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.noncompeteAgreement')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.addNoncompete}
                                                                        onChange={this.handleChange("addNoncompete", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="noncompete_period"
                                                            label={this.props.t('jobTranslations.noncompetePeriod')}
                                                            value={this.state.noncompete_period}
                                                            onChange={this.handleChange("noncompete_period", 'number')}
                                                            type="number"
                                                            required
                                                            inputProps={{
                                                                min: 1,
                                                                step: 1
                                                            }}
                                                            error={!this.state.noncompete_period}
                                                            variant="outlined"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="noncompete_distance"
                                                            label={this.props.t('jobTranslations.noncompeteDistance')}
                                                            value={this.state.noncompete_distance}
                                                            onChange={this.handleChange("noncompete_distance", 'number')}
                                                            type="number"
                                                            required
                                                            inputProps={{
                                                                min: 0,
                                                                step: 0.01
                                                            }}
                                                            error={!this.state.noncompete_distance}
                                                            variant="outlined"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="noncompete_distance_units">{this.props.t('unitTranslations.distanceUnit')}</InputLabel>
                                                        <Select
                                                            labelId="noncompete_distance_units"
                                                            label={this.props.t('unitTranslations.distanceUnit')}
                                                            value={this.state.noncompete_distance_units}
                                                            onChange={this.handleChange('noncompete_distance_units')}
                                                            fullWidth
                                                            required
                                                            inputProps={{
                                                                name: "noncompete_distance_units",
                                                                id: "noncompete_distance_units"
                                                            }}
                                                            error={!this.state.noncompete_distance_units}
                                                        >
                                                            {this.props.units.length.map(unit => (
                                                                <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.distanceUnits.${unit}`)}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.geofence')}</FormLabel>
                                                        <RadioGroup
                                                            aria-label="destination_is_geo_fenced"
                                                            name="destination_is_geo_fenced"
                                                            value={this.state.is_geo_fenced}
                                                            onChange={this.handleChange('is_geo_fenced', 'radio-boolean')}
                                                            row
                                                        >
                                                            <FormControlLabel
                                                                value={true}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('yes')}
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('no')}
                                                                labelPlacement="end"
                                                            />

                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                {this.state.is_geo_fenced && (
                                                    <Grid item xs={12} md={6}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel id="destination_geo_fence_distance_unit">{this.props.t('unitTranslations.distanceUnit')}</InputLabel>
                                                            <Select
                                                                label={this.props.t('unitTranslations.distanceUnit')}
                                                                labelId="destination_geo_fence_distance_unit"
                                                                value={this.state.geo_fence_distance_unit}
                                                                onChange={this.handleChange('geo_fence_distance_unit')}
                                                                inputProps={{
                                                                    name: "destination_geo_fence_distance_unit",
                                                                    id: "destination_geo_fence_distance_unit"
                                                                }}
                                                                fullWidth
                                                                required
                                                                error={!this.state.geo_fence_distance_unit}
                                                            >
                                                                {this.props.units.length && this.props.units.length.map(unit => (
                                                                    <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.distanceUnits.${unit}`)}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                )}
                                            </Grid>
                                            {this.state.is_geo_fenced && (
                                                <Grid container spacing={10}>
                                                    <Grid item xs={12} md={6}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="pickup_geofence_distance"
                                                                label={this.props.t('jobTranslations.pickGeofenceDistance')}
                                                                value={this.state.pick_up_geo_fence_distance}
                                                                onChange={this.handleChange('pick_up_geo_fence_distance')}
                                                                type="number"
                                                                variant="outlined"
                                                                inputProps={{
                                                                    min: 0.01,
                                                                    step: 0.01
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="dropoff_geofence_distance"
                                                                label={this.props.t('jobTranslations.dropGeofenceDistance')}
                                                                value={this.state.drop_off_geo_fence_distance}
                                                                onChange={this.handleChange('drop_off_geo_fence_distance')}
                                                                type="number"
                                                                variant="outlined"
                                                                inputProps={{
                                                                    min: 0.01,
                                                                    step: 0.01
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </>}
                                        <Grid container spacing={10}>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" fullWidth type="button" onClick={() => this.prevPage()}>
                                                    {this.props.t('actions.previous')}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" fullWidth type="submit">
                                                    {this.props.t('actions.next')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>}
                                </TabPanel>
                                <TabPanel value={this.state.tabValue} index={this.routeDetailsPage} style={{ display: (this.state.tabValue == this.routeDetailsPage && !this.state.loading) ? "" : "none" }}>
                                    {this.state.tabValue == this.routeDetailsPage && <form autoComplete="off" onSubmit={(event) => this.nextPage(event, this.routeDetailsPage)}>
                                        <Grid container spacing={10}>
                                            {this.state.jobType != 'unknownPickup' && <Grid item xs={12} md={6}>
                                                <Grid container spacing={10}>
                                                    <Grid item xs={12} md={12}>
                                                        <SectionHeader title={this.props.t('addressTranslations.materialPickupLocation')} />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={10} direction="column" justify="space-between">
                                                    <Grid item xs={12}>
                                                        {this.renderLocation('pickup')}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button variant="contained" color="default" fullWidth type="button" onClick={() => this.setStateIfMounted({ openPickupAddressModal: true })}>
                                                            Update
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>}
                                            {this.state.jobType != 'unknownDropoff' && <Grid item xs={12} md={6}>
                                                <Grid container spacing={10}>
                                                    <Grid item xs={12} md={12}>
                                                        <SectionHeader title={this.props.t('addressTranslations.materialDropoffLocation')} />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={10} direction="column" justify="space-between">
                                                    <Grid item xs={12}>
                                                        {this.renderLocation('dropoff')}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button variant="contained" color="default" fullWidth type="button" onClick={() => this.setStateIfMounted({ openDropoffAddressModal: true })}>
                                                            Update
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>}
                                        </Grid>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={12}>
                                                <SectionHeader title={this.props.t('materialTranslations.material')} />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5}>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    startIcon={<WorldIcon />}
                                                    onClick={() => this.setStateIfMounted({ showCustomMaterials: !this.state.showCustomMaterials })}
                                                >
                                                    {this.state.showCustomMaterials ? this.props.t('materialTranslations.materials') : this.props.t('materialTranslations.customMaterials')}
                                                </Button>
                                            </Grid>
                                            {this.state.showCustomMaterials && <>
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        startIcon={<WorldIcon />}
                                                        onClick={() => this.setCustomMaterialModal(true)}
                                                    >
                                                        {this.props.t('materialTranslations.actions.addCustomMaterial')}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        startIcon={<WorldIcon />}
                                                        onClick={() => this.setQueryForMaterialsModal(true)}
                                                    >
                                                        Query For Materials
                                                    </Button>
                                                </Grid>
                                            </>}
                                        </Grid>
                                        <Grid container spacing={10}>
                                            {this.state.showCustomMaterials ? <>
                                                <Grid item xs={12} md>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="job_custom_material_list_name">{this.props.t('materialTranslations.customListName')}</InputLabel>
                                                        <Select
                                                            label={this.props.t('materialTranslations.customListName')}
                                                            labelId="job_custom_material_list_name"
                                                            value={this.state.customMaterialListName}
                                                            onChange={this.handleChange('customMaterialListName')}
                                                            inputProps={{
                                                                name: "job_custom_material_list_name",
                                                                id: "job_custom_material_list_name"
                                                            }}
                                                            fullWidth
                                                        >
                                                            {this.props.customMaterialListNames.map(listName => (
                                                                <MenuItem key={listName} value={listName}>{listName}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                    </FormControl>
                                                </Grid>
                                                {this.state.customMaterialListName && <Grid item xs={12} md>
                                                    <FormControl fullWidth variant="outlined">
                                                        <Autocomplete
                                                            id="job_custom_material"
                                                            disableClearable
                                                            autoComplete
                                                            clearOnBlur={false}
                                                            options={this.props.customMaterials[this.state.customMaterialListName]}
                                                            getOptionLabel={(mat) => mat ? `${mat.material_number} - ${mat.name}` : ""}
                                                            onChange={(event, val) => this.setStateIfMounted({ customMaterial: val })}
                                                            onInputChange={(event, val, reason) => {
                                                                if (reason != 'reset' || val) {
                                                                    this.setStateIfMounted({ searchCustomMaterial: val })
                                                                }
                                                            }}
                                                            value={this.state.customMaterial}
                                                            inputValue={this.state.searchCustomMaterial}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    id="searchCustomMaterial"
                                                                    label={this.props.t('materialTranslations.customMaterials')}
                                                                    type="text"
                                                                    variant="outlined"
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>}
                                            </> : <>
                                                <Grid item xs={12} md>
                                                    <FormControl fullWidth variant="outlined">
                                                        <Autocomplete
                                                            id="job_custom_material"
                                                            disableClearable
                                                            autoComplete
                                                            clearOnBlur={false}
                                                            options={this.props.materials}
                                                            getOptionLabel={(mat) => mat.name}
                                                            onChange={(event, val) => this.setStateIfMounted({ material: val })}
                                                            onInputChange={(event, val, reason) => {
                                                                if (reason != 'reset' || val) {
                                                                    this.setStateIfMounted({ searchMaterial: val })
                                                                }
                                                            }}
                                                            value={this.state.material}
                                                            inputValue={this.state.searchMaterial}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    id="searchMaterial"
                                                                    label={this.props.t('materialTranslations.materials')}
                                                                    type="text"
                                                                    variant="outlined"
                                                                    error={!this.state.selectedMaterials.length}
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </>}
                                            {/* <Grid item xs={12} md={2}>
                                                <Button
                                                    variant="contained"
                                                    type="button"
                                                    color="primary"
                                                    onClick={() => this.addMaterial()}
                                                    fullWidth
                                                >
                                                    {this.props.t('actions.add')}
                                                </Button>
                                            </Grid> */}
                                        </Grid>
                                        <Grid container spacing={10}>
                                            {this.state.selectedMaterials.map(material => (
                                                <Grid item xs={12} md={6} key={material.id}>
                                                    <Grid container spacing={10}>
                                                        <Grid item xs>
                                                            <Typography variant="subtitle2"><Bold>{material.material_number}</Bold></Typography>
                                                            <Typography>{material.name}</Typography>
                                                            <Typography color="textSecondary" noWrap>{material.name !== material.description && material.description}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => this.removeMaterial(material)}
                                                                aria-label={`Remove ${material.name}`}
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={10}>
                                                        <Grid item xs={12} md={7}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id={`${material.id}_${material.name}_estimated_weight`}
                                                                    label={this.props.t('jobTranslations.estimatedWeight')}
                                                                    value={material.estimated_weight}
                                                                    onChange={this.handleMaterialWeightUpdate(material, true)}
                                                                    type="number"
                                                                    variant="outlined"
                                                                    error={(!material.estimated_weight && !material.estimated_volume)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={5}>
                                                            <FormControl fullWidth variant="outlined">
                                                                <InputLabel id={`${material.id}_${material.name}_weight_unit`}>{this.props.t('unitTranslations.weightUnit')}</InputLabel>
                                                                <Select
                                                                    labelId={`${material.id}_${material.name}_weight_unit`}
                                                                    label={this.props.t('unitTranslations.weightUnit')}
                                                                    value={material.estimated_weight_units}
                                                                    onChange={this.handleMaterialWeightUpdate(material, false)}
                                                                    fullWidth
                                                                    inputProps={{
                                                                        name: `${material.id}_${material.name}_weight_unit`,
                                                                        id: `${material.id}_${material.name}_weight_unit`
                                                                    }}
                                                                >
                                                                    {this.props.units.weight.map(unit => (
                                                                        <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.weightUnits.${unit}`)}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={10}>
                                                        <Grid item xs={12} md={7}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id={`${material.id}_${material.name}_estimated_volume`}
                                                                    label={this.props.t('jobTranslations.estimatedVolume')}
                                                                    value={material.estimated_volume}
                                                                    onChange={this.handleMaterialVolumeUpdate(material, true)}
                                                                    type="number"
                                                                    variant="outlined"
                                                                    error={(!material.estimated_weight && !material.estimated_volume)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={5}>
                                                            <FormControl fullWidth variant="outlined">
                                                                <InputLabel id={`${material.id}_${material.name}_volume_unit`}>{this.props.t('unitTranslations.volumeUnit')}</InputLabel>
                                                                <Select
                                                                    labelId={`${material.id}_${material.name}_volume_unit`}
                                                                    label={this.props.t('unitTranslations.volumeUnit')}
                                                                    value={material.estimated_volume_units}
                                                                    onChange={this.handleMaterialVolumeUpdate(material, false)}
                                                                    fullWidth
                                                                    inputProps={{
                                                                        name: `${material.id}_${material.name}_volume_unit`,
                                                                        id: `${material.id}_${material.name}_volume_unit`
                                                                    }}
                                                                >
                                                                    {this.props.units.volume.map(unit => (
                                                                        <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.volumeUnits.${unit}`)}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        {this.state.selectedMaterials.length > 1 && <Grid container spacing={10} hiddenFields={this.state.hiddenFields} fieldNames={["select_material_at_checkin"]}>
                                            <Grid item xs={12}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">{this.props.t('jobTranslations.selectMaterialAtCheckin')}</FormLabel>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={Boolean(this.state.select_material_at_checkin)}
                                                                    onChange={this.handleChange("select_material_at_checkin", 'boolean')}
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>}
                                        <Box mt={3}/>
                                        {Boolean(this.state.specifiedRouteLocation) && <>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={12}>
                                                    <SectionHeader title={this.props.t('deliveryTranslations.delivery')} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={10} hiddenFields={this.state.hiddenFields} fieldNames={['driver_can_check_in_with_active_deliveries', 'ticket_number_starts_at']}>
                                                <Grid item xs={12} md={6} hiddenFields={this.state.hiddenFields} fieldName="driver_can_check_in_with_active_deliveries">
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.driverCanCheckinWithActiveDeliveries')}</FormLabel>
                                                        <RadioGroup
                                                            aria-label="driver_can_check_in_with_active_deliveries"
                                                            name="driver_can_check_in_with_active_deliveries"
                                                            value={this.state.driver_can_check_in_with_active_deliveries}
                                                            onChange={this.handleChange('driver_can_check_in_with_active_deliveries', 'radio-boolean')}
                                                            row
                                                        >
                                                            <FormControlLabel
                                                                value={true}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('yes')}
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('no')}
                                                                labelPlacement="end"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6} hiddenFields={this.state.hiddenFields} fieldName="ticket_number_starts_at">
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="ticket_number_starts_at"
                                                            label={this.props.t('jobTranslations.ticketNumberStartsAt')}
                                                            value={this.state.ticket_number_starts_at}
                                                            onChange={this.handleChange("ticket_number_starts_at")}
                                                            type="number"
                                                            inputProps={{
                                                                min: 1,
                                                                step: 1
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={10}>
                                                {(this.state.transaction_type_id === this.perWeightTransactionTypeID) && (<>
                                                    <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="auto_bol">
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">{this.props.t('jobTranslations.autoBOL')}</FormLabel>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={this.state.auto_bol}
                                                                            onChange={this.handleChange("auto_bol", 'boolean')}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    {this.state.auto_bol && <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="bol_start">
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="bol_start"
                                                                label={this.props.t('jobTranslations.bolStart')}
                                                                value={this.state.bol_start}
                                                                onChange={this.handleChange("bol_start")}
                                                                type="number"
                                                                required
                                                                inputProps={{
                                                                    min: 1,
                                                                    step: 1
                                                                }}
                                                                // error={!this.state.bol_start}
                                                                variant="outlined"
                                                            />
                                                        </FormControl>
                                                    </Grid>}
                                                </>)}
                                                <Grid item xs={12} md={4}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="po_number"
                                                            label={this.props.t('jobTranslations.poNumber')}
                                                            value={this.state.po_number}
                                                            onChange={this.handleChange("po_number")}
                                                            type="text"
                                                            // error={!this.state.po_number}
                                                            variant="outlined"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            {this.state.transaction_type_id != this.perWeightTransactionTypeID && <Grid container spacing={10}>
                                                <Grid item xs={12} hiddenFields={this.state.hiddenFields} fieldName="record_weight">
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.recordWeight')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.record_weight}
                                                                        onChange={this.handleChange("record_weight", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>}
                                            {(this.state.transaction_type_id != this.perVolumeTransactionTypeID && this.state.record_weight) ? <> 
                                                <Grid container spacing={10}>
                                                    <Grid item xs={12} md={12}>
                                                        <SectionHeader title={"Weighment Details"} />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={10}>
                                                    <Grid item xs={12} md={4}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel id="load_weight_method">{this.props.t('jobTranslations.loadWeightMethod')}</InputLabel>
                                                            <Select
                                                                labelId="load_weight_method"
                                                                label={this.props.t('jobTranslations.loadWeightMethod')}
                                                                value={this.state.load_weight_method}
                                                                onChange={this.handleChange('load_weight_method')}
                                                                fullWidth
                                                                required
                                                                inputProps={{
                                                                    name: "load_weight_method",
                                                                    id: "load_weight_method"
                                                                }}
                                                                error={!this.state.load_weight_method}
                                                            >
                                                                <MenuItem value={'TG'}>{this.props.t('jobTranslations.loadWeightMethods.tareThenGross')}</MenuItem>
                                                                <MenuItem value={'GT'}>{this.props.t('jobTranslations.loadWeightMethods.grossThenTare')}</MenuItem>
                                                                <MenuItem value={'GO'}>{this.props.t('jobTranslations.loadWeightMethods.grossOnly')}</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="allow_job_tare_weights">
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">{this.props.t('jobTranslations.allowJobTareWeights')}</FormLabel>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={this.state.allow_job_tare_weights}
                                                                            onChange={this.handleChange("allow_job_tare_weights", 'boolean')}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">Allow Overweight Vehicles</FormLabel>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={this.state.allow_vehicle_weight_over_capacity}
                                                                            onChange={this.handleChange("allow_vehicle_weight_over_capacity", 'boolean')}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={10} hiddenFields={this.state.hiddenFields}>
                                                    <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="allow_driver_record_weight">
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">Weight Recorder</FormLabel>
                                                            <RadioGroup
                                                                aria-label="supervisor_records_weight"
                                                                name="supervisor_records_weight"
                                                                value={this.state.allow_driver_record_weight}
                                                                onChange={this.handleChange('allow_driver_record_weight', 'radio-boolean')}
                                                                row
                                                            >
                                                                <FormControlLabel
                                                                    value={false}
                                                                    control={<Radio color="primary" required />}
                                                                    label={"Supervisor"}
                                                                    labelPlacement="end"
                                                                />
                                                                <FormControlLabel
                                                                    value={true}
                                                                    control={<Radio color="primary" required />}
                                                                    label={"Driver"}
                                                                    labelPlacement="end"
                                                                />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    {(this.state.jobType != 'unknownDropoff' && this.state.jobType != 'unknownPickup') ? <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="truck_weigh_location">
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">{this.props.t('jobTranslations.truckWeighLocation')}</FormLabel>
                                                            <RadioGroup
                                                                aria-label="truck_weigh_location"
                                                                name="truck_weigh_location"
                                                                value={this.state.truck_weigh_location}
                                                                onChange={this.handleChange('truck_weigh_location')}
                                                                row
                                                            >
                                                                <FormControlLabel
                                                                    value={'P'}
                                                                    control={<Radio color="primary" required />}
                                                                    label="Pickup"
                                                                    labelPlacement="end"
                                                                />
                                                                <FormControlLabel
                                                                    value={'D'}
                                                                    control={<Radio color="primary" required />}
                                                                    label="Dropoff"
                                                                    labelPlacement="end"
                                                                />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid> : null}
                                                    <Grid item xs={12} md={4}>
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">{this.props.t('jobTranslations.tpScaleOnSite')}</FormLabel>
                                                            <RadioGroup
                                                                aria-label="automated_scale"
                                                                name="automated_scale"
                                                                value={this.state.automated_scale}
                                                                onChange={this.handleChange('automated_scale', 'radio-boolean')}
                                                                row
                                                            >
                                                                <FormControlLabel
                                                                    value={true}
                                                                    control={<Radio color="primary" required />}
                                                                    label={this.props.t('yes')}
                                                                    labelPlacement="end"
                                                                />
                                                                <FormControlLabel
                                                                    value={false}
                                                                    control={<Radio color="primary" required />}
                                                                    label={this.props.t('no')}
                                                                    labelPlacement="end"
                                                                />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                {this.state.allow_job_tare_weights ? (<Grid container spacing={10} hiddenFields={this.state.hiddenFields} fieldNames={['job_tare_weight_change_tolerance', 'job_tare_weight_expiration_days']}>
                                                    <Grid item xs={12} md={6} hiddenFields={this.state.hiddenFields} fieldName="job_tare_weight_change_tolerance">
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="job_tare_weight_change_tolerance"
                                                                label={this.props.t('jobTranslations.jobTareWeightChangeTolerance')}
                                                                value={this.state.job_tare_weight_change_tolerance}
                                                                onChange={this.handleChange("job_tare_weight_change_tolerance")}
                                                                type="number"
                                                                inputProps={{
                                                                    min: 1,
                                                                    step: 1
                                                                }}
                                                                error={!this.state.job_tare_weight_change_tolerance}
                                                                variant="outlined"
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} hiddenFields={this.state.hiddenFields} fieldName="job_tare_weight_expiration_days">
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="job_tare_weight_expiration_days"
                                                                label={this.props.t('jobTranslations.jobTareWeightExpirationDays')}
                                                                value={this.state.job_tare_weight_expiration_days}
                                                                onChange={this.handleChange("job_tare_weight_expiration_days")}
                                                                type="number"
                                                                inputProps={{
                                                                    min: 1,
                                                                    step: 1
                                                                }}
                                                                error={!this.state.job_tare_weight_expiration_days}
                                                                variant="outlined"

                                                            />                                                    
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>) : null}
                                                {this.state.truck_weigh_location && <Grid container spacing={10}>
                                                    {this.state.automated_scale && <Grid item xs={12}>
                                                        <FormControl fullWidth variant="outlined">
                                                            {this.state.truck_weigh_location == 'P' ? (<>
                                                                <InputLabel id="job_pick_up_scale_id">{this.props.t('jobTranslations.pickupScale')}</InputLabel>
                                                                <Select
                                                                    labelId="job_pick_up_scale_id"
                                                                    label={this.props.t('jobTranslations.pickupScale')}
                                                                    value={this.state.pick_up_scale_id}
                                                                    onChange={this.handleChange('pick_up_scale_id')}
                                                                    fullWidth
                                                                    required
                                                                    inputProps={{
                                                                        name: "job_pick_up_scale_id",
                                                                        id: "job_pick_up_scale_id"
                                                                    }}
                                                                    error={!this.state.pick_up_scale_id}
                                                                >
                                                                    {this.state.scalesAtLocation.map(scale => (
                                                                        <MenuItem key={scale.id} value={scale.id}>{`${scale.name} - ${scale.hardware_specs.make} ${scale.hardware_specs.model}`}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                            </>) : (<>
                                                                <InputLabel id="job_drop_off_scale_id">{this.props.t('jobTranslations.dropoffScale')}</InputLabel>
                                                                <Select
                                                                    labelId="job_drop_off_scale_id"
                                                                    label={this.props.t('jobTranslations.dropoffScale')}
                                                                    value={this.state.drop_off_scale_id}
                                                                    onChange={this.handleChange('drop_off_scale_id')}
                                                                    fullWidth
                                                                    required
                                                                    inputProps={{
                                                                        name: "job_drop_off_scale_id",
                                                                        id: "job_drop_off_scale_id"
                                                                    }}
                                                                    error={!this.state.drop_off_scale_id}
                                                                >
                                                                    {this.state.scalesAtLocation.map(scale => (
                                                                        <MenuItem key={scale.id} value={scale.id}>{`${scale.name} - ${scale.hardware_specs.make} ${scale.hardware_specs.model}`}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                            </>)}
                                                        </FormControl>
                                                    </Grid>}
                                                </Grid>}
                                                <Grid container spacing={10} hiddenFields={this.state.hiddenFields} fieldNames={['weighmaster_doesnt_change']}>
                                                    <Grid item xs={12} md={4} hiddenFields={this.state.hiddenFields} fieldName="weighmaster_doesnt_change">
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">{this.props.t('jobTranslations.weighmasterDoesntChange')}</FormLabel>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={this.state.weighmaster_doesnt_change}
                                                                            onChange={this.handleChange("weighmaster_doesnt_change", 'boolean')}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    {this.state.weighmaster_doesnt_change && <>
                                                        <Grid item xs={12} md={4}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="constant_weighmaster_name"
                                                                    label={this.props.t('deliveryTranslations.weighmasterName')}
                                                                    value={this.state.constant_weighmaster_name}
                                                                    onChange={this.handleChange("constant_weighmaster_name")}
                                                                    type="text"
                                                                    required
                                                                    error={!this.state.constant_weighmaster_name}
                                                                    variant="outlined"
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="constant_weighmaster_license_number"
                                                                    label={this.props.t('deliveryTranslations.weighmasterLicense')}
                                                                    value={this.state.constant_weighmaster_license_number}
                                                                    onChange={this.handleChange("constant_weighmaster_license_number")}
                                                                    type="text"
                                                                    required
                                                                    error={!this.state.constant_weighmaster_license_number}
                                                                    variant="outlined"
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </>}
                                                </Grid>
                                            </> : null}
                                            {this.state.is_removal_job && <Grid container spacing={10} hiddenFields={this.state.hiddenFields} fieldNames={["track_driver_destination"]}>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.trackDriverDestination')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.track_driver_destination}
                                                                        onChange={this.handleChange("track_driver_destination", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>}
                                        </>}
                                        {Boolean(this.state.hasBatchOrInspectableMaterial || this.state.jobType === 'waste') && <>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={12}>
                                                    <SectionHeader title={"Inspection Details"} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={10}>
                                                <Grid item xs={6}>
                                                    <Grid container spacing={5}>
                                                        <Grid item xs={12}>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">Require Company Pickup Inspection</FormLabel>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={this.state.is_company_pickup_inspection_required}
                                                                                onChange={this.handleChange("is_company_pickup_inspection_required", 'boolean')}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        {this.state.is_company_pickup_inspection_required && <Grid item xs={12}>
                                                            <TpTextfield 
                                                                label="Company Pickup Inspection Time"
                                                                value={this.state.driver_company_pickup_inspection_wait_minutes}
                                                                onChange={e => this.setState({ driver_company_pickup_inspection_wait_minutes: e.target.valueAsNumber ?? "" })}
                                                                required
                                                                textfieldProps={{
                                                                    type: "number",
                                                                    inputProps: {
                                                                        min: 0
                                                                    },
                                                                    InputProps: {
                                                                        endAdornment: <InputAdornment position="end">{this.props.t("timeTranslations.min")}</InputAdornment>,
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container spacing={5}>
                                                        <Grid item xs={12}>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">Require Company Drop Off Inspection</FormLabel>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={this.state.is_company_dropoff_inspection_required}
                                                                                onChange={this.handleChange("is_company_dropoff_inspection_required", 'boolean')}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        {this.state.is_company_dropoff_inspection_required && <Grid item xs={12}>
                                                            <TpTextfield 
                                                                label="Company Drop Off Inspection Time"
                                                                value={this.state.driver_company_dropoff_inspection_wait_minutes}
                                                                onChange={e => this.setState({ driver_company_dropoff_inspection_wait_minutes: e.target.valueAsNumber ?? "" })}
                                                                required
                                                                textfieldProps={{
                                                                    type: "number",
                                                                    inputProps: {
                                                                        min: 0
                                                                    },
                                                                    InputProps: {
                                                                        endAdornment: <InputAdornment position="end">{this.props.t("timeTranslations.min")}</InputAdornment>,
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={10}>
                                                <Grid item xs={6}>
                                                    <Grid container spacing={5}>
                                                        <Grid item xs={12}>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">Require Third Party Pick Up Inspection</FormLabel>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={this.state.is_third_party_pickup_inspection_required}
                                                                                onChange={this.handleChange("is_third_party_pickup_inspection_required", 'boolean')}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        {this.state.is_third_party_pickup_inspection_required && <Grid item xs={12}>
                                                            <TpTextfield 
                                                                label="Third Party Pick Up Inspection Time"
                                                                value={this.state.driver_third_party_pickup_inspection_wait_minutes}
                                                                onChange={e => this.setState({ driver_third_party_pickup_inspection_wait_minutes: e.target.valueAsNumber ?? "" })}
                                                                required
                                                                textfieldProps={{
                                                                    type: "number",
                                                                    inputProps: {
                                                                        min: 0
                                                                    },
                                                                    InputProps: {
                                                                        endAdornment: <InputAdornment position="end">{this.props.t("timeTranslations.min")}</InputAdornment>,
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container spacing={5}>
                                                        <Grid item xs={12}>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">Require Third Party Drop Off Inspection</FormLabel>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={this.state.is_third_party_dropoff_inspection_required}
                                                                                onChange={this.handleChange("is_third_party_dropoff_inspection_required", 'boolean')}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        {this.state.is_third_party_dropoff_inspection_required && <Grid item xs={12}>
                                                            <TpTextfield 
                                                                label="Third Party Drop Off Inspection Time"
                                                                value={this.state.driver_third_party_dropoff_inspection_wait_minutes}
                                                                onChange={e => this.setState({ driver_third_party_dropoff_inspection_wait_minutes: e.target.valueAsNumber ?? "" })}
                                                                required
                                                                textfieldProps={{
                                                                    type: "number",
                                                                    inputProps: {
                                                                        min: 0
                                                                    },
                                                                    InputProps: {
                                                                        endAdornment: <InputAdornment position="end">{this.props.t("timeTranslations.min")}</InputAdornment>,
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={10}>
                                                <Grid item xs={6}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Driver Can Authorize Inspector</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.can_driver_authorize_inspector}
                                                                        onChange={this.handleChange("can_driver_authorize_inspector", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Automated Batching</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.has_automated_batching}
                                                                        onChange={this.handleChange("has_automated_batching", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </>}
                                        {Boolean(this.state.hasAdvancedSettings[3]) && <>
                                            <Grid container spacing={10}>
                                                {this.state.showAdvancedSettings[3] ? <Grid item xs>
                                                    <Button
                                                        fullWidth
                                                        onClick={() => this.setStateIfMounted({
                                                            showAdvancedSettings: {
                                                                ...this.state.showAdvancedSettings,
                                                                3: false
                                                            }
                                                        })}
                                                        type="button"
                                                    >
                                                        Hide Advanced Settings
                                                    </Button>
                                                </Grid> : <Grid item xs>
                                                    <Button
                                                        fullWidth
                                                        onClick={() => this.setStateIfMounted({
                                                            showAdvancedSettings: {
                                                                ...this.state.showAdvancedSettings,
                                                                3: true
                                                            }
                                                        })}
                                                        type="button"
                                                    >
                                                        Show Advanced Settings
                                                    </Button>
                                                </Grid>}
                                            </Grid>
                                        </>}
                                        {Boolean(this.state.showAdvancedSettings[3]) && <>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.supervisorRecordsWeight')}</FormLabel>
                                                        <RadioGroup
                                                            aria-label="supervisor_records_weight"
                                                            name="supervisor_records_weight"
                                                            value={this.state.allow_driver_record_weight}
                                                            onChange={this.handleChange('allow_driver_record_weight', 'radio-boolean')}
                                                            row
                                                        >
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('yes')}
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value={true}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('no')}
                                                                labelPlacement="end"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.driverRecordsWeight')}</FormLabel>
                                                        <RadioGroup
                                                            aria-label="allow_driver_record_weight"
                                                            name="allow_driver_record_weight"
                                                            value={this.state.allow_driver_record_weight}
                                                            onChange={this.handleChange('allow_driver_record_weight', 'radio-boolean')}
                                                            row
                                                        >
                                                            <FormControlLabel
                                                                value={true}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('yes')}
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('no')}
                                                                labelPlacement="end"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.truckWeighLocation')}</FormLabel>
                                                        <RadioGroup
                                                            aria-label="truck_weigh_location"
                                                            name="truck_weigh_location"
                                                            value={this.state.truck_weigh_location}
                                                            onChange={this.handleChange('truck_weigh_location')}
                                                            row
                                                        >
                                                            <FormControlLabel
                                                                value={'P'}
                                                                control={<Radio color="primary" required />}
                                                                label="Pickup"
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value={'D'}
                                                                control={<Radio color="primary" required />}
                                                                label="Dropoff"
                                                                labelPlacement="end"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            {this.state.selectedMaterials.length > 1 && <Grid container spacing={10}>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.selectMaterialAtCheckin')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={Boolean(this.state.select_material_at_checkin)}
                                                                        onChange={this.handleChange("select_material_at_checkin", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>}
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.driverCanCheckinWithActiveDeliveries')}</FormLabel>
                                                        <RadioGroup
                                                            aria-label="driver_can_check_in_with_active_deliveries"
                                                            name="driver_can_check_in_with_active_deliveries"
                                                            value={this.state.driver_can_check_in_with_active_deliveries}
                                                            onChange={this.handleChange('driver_can_check_in_with_active_deliveries', 'radio-boolean')}
                                                            row
                                                        >
                                                            <FormControlLabel
                                                                value={true}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('yes')}
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('no')}
                                                                labelPlacement="end"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="ticket_number_starts_at"
                                                            label={this.props.t('jobTranslations.ticketNumberStartsAt')}
                                                            value={this.state.ticket_number_starts_at}
                                                            onChange={this.handleChange("ticket_number_starts_at")}
                                                            type="number"
                                                            inputProps={{
                                                                min: 1,
                                                                step: 1
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            {this.state.is_removal_job && <Grid container spacing={10}>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.trackDriverDestination')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.track_driver_destination}
                                                                        onChange={this.handleChange("track_driver_destination", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>}
                                        </>}
                                        <Grid container spacing={10}>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" fullWidth type="button" onClick={() => this.prevPage()}>
                                                    {this.props.t('actions.previous')}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" fullWidth type="submit">
                                                    {this.props.t('actions.next')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>}
                                    {this.state.openPickupAddressModal && (
                                        <SetJobAddressModal 
                                            site={this.state.pickupSite}
                                            address={this.state.pickupAddress}
                                            locationID={this.state.pick_up_location_id}
                                            updateAddressInfo={this.updateAddressInfo(true)}
                                            setModalOpen={bool => this.setStateIfMounted({ openPickupAddressModal: bool })}
                                            contactInfo={this.state.pickupContactInfo}
                                            requireSite={this.state.jobType == 'waste' || this.state.jobType === 'unknownDropoff'}
                                        />
                                    )}
                                    {this.state.openDropoffAddressModal && (
                                        <SetJobAddressModal 
                                            site={this.state.dropoffSite}
                                            address={this.state.dropoffAddress}
                                            locationID={this.state.drop_off_location_id}
                                            updateAddressInfo={this.updateAddressInfo(false)}
                                            setModalOpen={bool => this.setStateIfMounted({ openDropoffAddressModal: bool })}
                                            contactInfo={this.state.dropoffContactInfo}
                                            requireSite={this.state.jobType == 'waste' || this.state.jobType === 'unknownPickup'}
                                        />
                                    )}
                                </TabPanel>
                                <TabPanel value={this.state.tabValue} index={this.haulbackDetailsPage} style={{ display: (this.state.tabValue == this.haulbackDetailsPage && !this.state.loading) ? "" : "none" }}>
                                    {this.state.tabValue == this.haulbackDetailsPage && <form autoComplete="off" onSubmit={(event) => this.nextPage(event, this.haulbackDetailsPage)}>
                                        {!this.state.addHaulback ? <> 
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} hiddenFields={this.state.hiddenFields} fieldName="addHaulback">
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary" 
                                                        fullWidth 
                                                        onClick={() => this.setStateIfMounted({ addHaulback: true })} 
                                                        disabled={this.state.jobType == 'unknownDropoff' || this.state.jobType == 'unknownPickup' || this.state.jobType == 'waste'}
                                                    >{this.props.t('jobTranslations.actions.addHaulback')}</Button>
                                                </Grid>
                                            </Grid>
                                        </> : <>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12}>
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary" 
                                                        fullWidth 
                                                        onClick={() => this.setStateIfMounted({ addHaulback: false })}>{this.props.t('jobTranslations.actions.removeHaulback')}</Button>
                                                </Grid>
                                            </Grid>
                                        
                                            {/* {(this.state.jobType != 'unknownDropoff' && this.state.jobType != 'unknownPickup')? (<>  */}
                                                <Grid container spacing={10}>
                                                    <Grid item xs={12} md={12}>
                                                        <SectionHeader title={this.props.t('addressTranslations.haulbackLocation')} />
                                                    </Grid>
                                                </Grid> 

                                                <Grid container spacing={10}>
                                                    <Grid item xs={12}>
                                                        <GoogleMapReactWrapper>
                                                            <GoogleMapReact
                                                                options={this.getMapOptions}
                                                                bootstrapURLKeys={{
                                                                    key: GMapAPIKey
                                                                }}
                                                                defaultCenter={{ lat: 40.75027, lng: -73.8542755 }}
                                                                defaultZoom={16}
                                                                yesIWantToUseGoogleMapApiInternals
                                                                onGoogleApiLoaded={({ map, maps }) => this.handleHaulbackApiLoaded(map, maps)}
                                                                onClick={this.handleHaulbackMapClick}
                                                            />
                                                        </GoogleMapReactWrapper>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={10}>
                                                    <Grid item xs={12} md={6}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            startIcon={<WorldIcon />}
                                                            onClick={() => this.setStateIfMounted({ displayHaulbackCoordinates: !this.state.displayHaulbackCoordinates })}
                                                        >
                                                            {this.state.displayHaulbackCoordinates ? this.props.t('addressTranslations.useAddress') : this.props.t('addressTranslations.useCoordinates')}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                {!this.state.displayHaulbackCoordinates ? <>
                                                    <Grid container spacing={10}>
                                                        <Grid item xs={12}>
                                                            <FormControl fullWidth variant="outlined">
                                                                <InputLabel id="job_haulback_country">{this.props.t('addressTranslations.country')}</InputLabel>
                                                                <Select
                                                                    labelId="job_haulback_country"
                                                                    label={this.props.t('addressTranslations.country')}
                                                                    value={this.state.haulbackCountry}
                                                                    onChange={this.handleChange('haulbackCountry')}
                                                                    fullWidth
                                                                    inputProps={{
                                                                        name: "job_haulback_country",
                                                                        id: "job_haulback_country"
                                                                    }}
                                                                    required
                                                                    error={!this.state.haulbackCountry}
                                                                >
                                                                    {this.props.supportedCountries.map(country => (
                                                                        <MenuItem key={country.id} value={country}>{country.name}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={10}>
                                                        <Grid item xs={12}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="job_haulback_address_line_1"
                                                                    label={this.props.t('addressTranslations.addressLine1')}
                                                                    value={this.state.haulbackAddress.address_line_1}
                                                                    onChange={this.handleChange(null, null, { parent: 'haulbackAddress', child: 'address_line_1' })}
                                                                    type="text"
                                                                    inputRef={this.haulbackAddressInput}
                                                                    required
                                                                    variant="outlined"
                                                                    error={!this.state.haulbackAddress.address_line_1}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={10}>
                                                        <Grid item xs={12} md={4}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="job_haulback_city"
                                                                    label={this.props.t('addressTranslations.city')}
                                                                    value={this.state.haulbackAddress.city}
                                                                    onChange={this.handleChange(null, null, { parent: 'haulbackAddress', child: 'city' })}
                                                                    type="text"
                                                                    required
                                                                    variant="outlined"
                                                                    error={!this.state.haulbackAddress.city}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="job_haulback_state"
                                                                    label={this.props.t('addressTranslations.province')}
                                                                    value={this.state.haulbackAddress.province}
                                                                    onChange={this.handleChange(null, null, { parent: 'haulbackAddress', child: 'province' })}
                                                                    type="text"
                                                                    required
                                                                    variant="outlined"
                                                                    error={!this.state.haulbackAddress.province}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="job_haulback_postal_code"
                                                                    label={this.props.t('addressTranslations.postalCode')}
                                                                    value={this.state.haulbackAddress.postal_code}
                                                                    onChange={this.handleChange(null, null, { parent: 'haulbackAddress', child: 'postal_code' })}
                                                                    type="text"
                                                                    required
                                                                    variant="outlined"
                                                                    error={!this.state.haulbackAddress.postal_code}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </> : <>
                                                    <Grid container spacing={10}>
                                                        <Grid item xs={12} md={6}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="job_haulback_latitude"
                                                                    label={this.props.t('addressTranslations.latitude')}
                                                                    value={this.state.haulbackAddress.latitude}
                                                                    onChange={this.handleChange(null, null, { parent: 'haulbackAddress', child: 'latitude' })}
                                                                    type="number"
                                                                    required
                                                                    variant="outlined"
                                                                    error={!this.state.haulbackAddress.latitude}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="job_haulback_longitude"
                                                                    label={this.props.t('addressTranslations.longitude')}
                                                                    value={this.state.haulbackAddress.longitude}
                                                                    onChange={this.handleChange(null, null, { parent: 'haulbackAddress', child: 'longitude' })}
                                                                    type="number"
                                                                    required
                                                                    variant="outlined"
                                                                    error={!this.state.haulbackAddress.longitude}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </>}
                                                <Grid container spacing={10}>
                                                    <Grid item xs={12}>
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">Add Contact Info</FormLabel>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={this.state.addHaulbackContactInfo}
                                                                            onChange={this.handleChange("addHaulbackContactInfo", 'boolean')}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                {this.state.addHaulbackContactInfo && <>
                                                    <Grid container spacing={10}>
                                                        <Grid item xs={12} md={4}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="address_contact_first_name"
                                                                    label={"Site Contact First Name"}
                                                                    value={this.state.haulbackAddress.address_contact_first_name}
                                                                    onChange={this.handleChange(null, null, { parent: 'haulbackAddress', child: 'address_contact_first_name' })}
                                                                    type="text"
                                                                    variant="outlined"
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="address_contact_last_name"
                                                                    label={"Site Contact Last Name"}
                                                                    value={this.state.haulbackAddress.address_contact_last_name}
                                                                    onChange={this.handleChange(null, null, { parent: 'haulbackAddress', child: 'address_contact_last_name' })}
                                                                    type="text"
                                                                    variant="outlined"
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={10}>
                                                        <Grid item xs={12} md={4}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="address_contact_email"
                                                                    label={"Site Contact Email"}
                                                                    value={this.state.haulbackAddress.address_contact_email}
                                                                    onChange={this.handleChange(null, null, { parent: 'haulbackAddress', child: 'address_contact_email' })}
                                                                    type="text"
                                                                    variant="outlined"
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="address_contact_phone"
                                                                    label={"Site Contact Phone"}
                                                                    value={this.state.haulbackAddress.address_contact_phone}
                                                                    onChange={this.handleChange(null, null, { parent: 'haulbackAddress', child: 'address_contact_phone' })}
                                                                    type="text"
                                                                    variant="outlined"
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </>}
                                                {/* <Grid container spacing={10}>
                                                    <Grid item xs={12} md={6}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="pick_up_location_id"
                                                                label={this.props.t('addressTranslations.pickupLocationIDOptional')}
                                                                value={this.state.pick_up_location_id}
                                                                onChange={this.handleChange("pick_up_location_id")}
                                                                type="text"
                                                                variant="outlined"
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="drop_off_location_id"
                                                                label={this.props.t('addressTranslations.dropoffLocationIDOptional')}
                                                                value={this.state.drop_off_location_id}
                                                                onChange={this.handleChange("drop_off_location_id")}
                                                                type="text"
                                                                variant="outlined"
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid> */}

                                            {/* </>) : null} */}
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={12}>
                                                    <SectionHeader title={this.props.t('materialTranslations.material')} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={6}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        startIcon={<WorldIcon />}
                                                        onClick={() => this.setStateIfMounted({ haulbackShowCustomMaterials: !this.state.haulbackShowCustomMaterials })}
                                                    >
                                                        {this.state.haulbackShowCustomMaterials ? this.props.t('materialTranslations.actions.addMaterial') : this.props.t('materialTranslations.actions.addCustomMaterial')}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={10}>
                                                {this.state.haulbackShowCustomMaterials ? <>
                                                    <Grid item xs={12} md={5}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel id="job_haulback_custom_material_list_name">{this.props.t('materialTranslations.customListName')}</InputLabel>
                                                            <Select
                                                                label={this.props.t('materialTranslations.customListName')}
                                                                labelId="job_haulback_custom_material_list_name"
                                                                value={this.state.haulbackCustomMaterialListName}
                                                                onChange={this.handleChange('haulbackCustomMaterialListName')}
                                                                inputProps={{
                                                                    name: "job_haulback_custom_material_list_name",
                                                                    id: "job_haulback_custom_material_list_name"
                                                                }}
                                                                fullWidth
                                                            >
                                                                {this.props.customMaterialListNames.map(listName => (
                                                                    <MenuItem key={listName} value={listName}>{listName}</MenuItem>
                                                                ))}
                                                            </Select>
                                                            {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                        </FormControl>
                                                    </Grid>
                                                    {this.state.haulbackCustomMaterialListName && <Grid item xs={12} md={5}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel id="job_haulback_custom_material">{this.props.t('materialTranslations.customMaterials')}</InputLabel>
                                                            <Select
                                                                label={this.props.t('materialTranslations.customMaterials')}
                                                                labelId="job_haulback_custom_material"
                                                                value={this.state.haulbackCustomMaterial}
                                                                onChange={this.handleChange('haulbackCustomMaterial')}
                                                                inputProps={{
                                                                    name: "job_haulback_custom_material",
                                                                    id: "job_haulback_custom_material"
                                                                }}
                                                                inputRef={this.haulbackMaterialInput}
                                                                fullWidth
                                                            >
                                                                {this.props.customMaterials[this.state.customMaterialListName].map(customMaterial => (
                                                                    <MenuItem key={customMaterial.id} value={customMaterial}>{customMaterial.material_number} {customMaterial.name}</MenuItem>
                                                                ))}
                                                            </Select>
                                                            {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                        </FormControl>
                                                    </Grid>}
                                                </> : <>
                                                    <Grid item xs={12} md={10}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel id="job_material">{this.props.t('materialTranslations.materials')}</InputLabel>
                                                            <Select
                                                                label={this.props.t('materialTranslations.materials')}
                                                                labelId="job_material"
                                                                value={this.state.haulbackMaterial}
                                                                onChange={this.handleChange('haulbackMaterial')}
                                                                inputProps={{
                                                                    name: "job_material",
                                                                    id: "job_material"
                                                                }}
                                                                inputRef={this.haulbackMaterialInput}
                                                                fullWidth
                                                            >
                                                                {this.props.materials.map(material => (
                                                                    <MenuItem key={material.id} value={material}>{material.name} {material.is_inspectable ? '- Inspectable' : ''}</MenuItem>
                                                                ))}
                                                            </Select>
                                                            {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                        </FormControl>
                                                    </Grid>
                                                </>}
                                                <Grid item xs={12} md={2}>
                                                    <Button
                                                        variant="contained"
                                                        type="button"
                                                        color="primary"
                                                        onClick={() => this.addMaterial(true)}
                                                        fullWidth
                                                    >
                                                        {this.props.t('actions.add')}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={10}>
                                                {this.state.selectedHaulbackMaterials.map(material => (
                                                    <Grid item xs={12} md={6} key={material.id}>
                                                        <Grid container spacing={10} direction="row" justify="space-between" alignItems="center">
                                                            <Grid item xs={12} md={9}>
                                                                <Grid container spacing={10}>
                                                                    <Grid item xs={12} md={6}>
                                                                        <Typography variant="subtitle2"><Bold>{material.name}</Bold></Typography>
                                                                        <Typography>{material.description}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={10}>
                                                                    <Grid item xs={12} md={7}>
                                                                        <FormControl fullWidth>
                                                                            <TextField
                                                                                id={`${material.id}_${material.name}_estimated_weight`}
                                                                                label={this.props.t('jobTranslations.estimatedWeight')}
                                                                                value={material.estimated_weight}
                                                                                onChange={this.handleMaterialWeightUpdate(material, true, true)}
                                                                                type="number"
                                                                                variant="outlined"
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={5}>
                                                                        <FormControl fullWidth variant="outlined">
                                                                            <InputLabel id={`${material.id}_${material.name}_weight_unit`}>{this.props.t('unitTranslations.weightUnit')}</InputLabel>
                                                                            <Select
                                                                                labelId={`${material.id}_${material.name}_weight_unit`}
                                                                                label={this.props.t('unitTranslations.weightUnit')}
                                                                                value={material.estimated_weight_units}
                                                                                onChange={this.handleMaterialWeightUpdate(material, false, true)}
                                                                                fullWidth
                                                                                inputProps={{
                                                                                    name: `${material.id}_${material.name}_weight_unit`,
                                                                                    id: `${material.id}_${material.name}_weight_unit`
                                                                                }}
                                                                            >
                                                                                {this.props.units.weight.map(unit => (
                                                                                    <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.weightUnits.${unit}`)}</MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={10}>
                                                                    <Grid item xs={12} md={7}>
                                                                        <FormControl fullWidth>
                                                                            <TextField
                                                                                id={`${material.id}_${material.name}_estimated_volume`}
                                                                                label={this.props.t('jobTranslations.estimatedVolume')}
                                                                                value={material.estimated_volume}
                                                                                onChange={this.handleMaterialVolumeUpdate(material, true, true)}
                                                                                type="number"
                                                                                variant="outlined"
                                                                            />
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={5}>
                                                                        <FormControl fullWidth variant="outlined">
                                                                            <InputLabel id={`${material.id}_${material.name}_volume_unit`}>{this.props.t('unitTranslations.volumeUnit')}</InputLabel>
                                                                            <Select
                                                                                labelId={`${material.id}_${material.name}_volume_unit`}
                                                                                label={this.props.t('unitTranslations.volumeUnit')}
                                                                                value={material.estimated_volume_units}
                                                                                onChange={this.handleMaterialVolumeUpdate(material, false, true)}
                                                                                fullWidth
                                                                                inputProps={{
                                                                                    name: `${material.id}_${material.name}_volume_unit`,
                                                                                    id: `${material.id}_${material.name}_volume_unit`
                                                                                }}
                                                                            >
                                                                                {this.props.units.volume.map(unit => (
                                                                                    <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.volumeUnits.${unit}`)}</MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} md={3}>
                                                                <Button
                                                                    variant="contained"
                                                                    type="button"
                                                                    color="default"
                                                                    onClick={() => this.removeMaterial(material, true)}
                                                                >
                                                                    <CloseIcon />
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            {this.state.selectedHaulbackMaterials.length > 1 && <Grid container spacing={10}>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.selectMaterialAtCheckin')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={Boolean(this.state.haulback_select_material_at_checkin)}
                                                                        onChange={this.handleChange("haulback_select_material_at_checkin", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>}
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={12}>
                                                    <SectionHeader title={this.props.t('deliveryTranslations.delivery')} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={(this.state.haulbackCheckoutType && this.state.haulbackCheckoutType.value == 'self-check') ? 4 : 6}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="haulback_checkin_type">{this.props.t('jobTranslations.checkinType')}</InputLabel>
                                                        <Select
                                                            label={this.props.t('jobTranslations.checkinType')}
                                                            labelId="haulback_checkin_type"
                                                            value={this.state.haulbackCheckinType}
                                                            onChange={this.handleChange("haulbackCheckinType")}
                                                            inputProps={{
                                                                name: "haulback_checkin_type",
                                                                id: "haulback_checkin_type"
                                                            }}
                                                            fullWidth
                                                            required
                                                            error={!this.state.haulbackCheckinType}
                                                        >
                                                            {this.state.checkinTypes.map(type => (
                                                                <MenuItem key={type.value} value={type}>{this.props.t(`jobTranslations.checkTypes.${type.value}`)}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={(this.state.haulbackCheckoutType && this.state.haulbackCheckoutType.value == 'self-check') ? 4 : 6}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="haulback_checkout_type">{this.props.t('jobTranslations.checkoutType')}</InputLabel>
                                                        <Select
                                                            label={this.props.t('jobTranslations.checkoutType')}
                                                            labelId="haulback_checkout_type"
                                                            value={this.state.haulbackCheckoutType}
                                                            onChange={this.handleChange('haulbackCheckoutType')}
                                                            inputProps={{
                                                                name: "haulback_checkout_type",
                                                                id: "haulback_checkout_type"
                                                            }}
                                                            fullWidth
                                                            required
                                                            error={!this.state.haulbackCheckoutType}
                                                        >
                                                            {this.state.checkoutTypes.map(type => (
                                                                <MenuItem key={type.value} value={type}>{this.props.t(`jobTranslations.checkTypes.${type.value}`)}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                    </FormControl>
                                                </Grid>
                                                {(this.state.haulbackCheckoutType && this.state.haulbackCheckoutType.value == 'self-check') && <Grid item xs={12} md={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.autoCheckout')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.haulback_auto_checkout}
                                                                        onChange={this.handleChange("haulback_auto_checkout", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>}
                                            </Grid>
                                            {((this.state.haulbackCheckinType && this.state.haulbackCheckinType.value == 'signature') || (this.state.haulbackCheckoutType && this.state.haulbackCheckoutType.value == 'signature')) ? <Grid container spacing={10}>
                                                {(this.state.haulbackCheckinType && this.state.haulbackCheckinType.value == 'signature') ? <Grid item xs={12} md={6}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.requireDriverPickupSignature')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.haulback_is_driver_checkin_signature_required}
                                                                        onChange={this.handleChange("haulback_is_driver_checkin_signature_required", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid> : null}
                                                {(this.state.haulbackCheckoutType && this.state.haulbackCheckoutType.value == 'signature') ? <Grid item xs={12} md={6}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.requireDriverDropoffSignature')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.haulback_is_driver_checkout_signature_required}
                                                                        onChange={this.handleChange("haulback_is_driver_checkout_signature_required", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid> : null}
                                            </Grid> : null}
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.geofence')}</FormLabel>
                                                        <RadioGroup
                                                            aria-label="destination_haulback_is_geo_fenced"
                                                            name="destination_haulback_is_geo_fenced"
                                                            value={this.state.haulback_is_geo_fenced}
                                                            onChange={this.handleChange('haulback_is_geo_fenced', 'radio-boolean')}
                                                            row
                                                        >
                                                            <FormControlLabel
                                                                value={true}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('yes')}
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio color="primary" required />}
                                                                label={this.props.t('no')}
                                                                labelPlacement="end"
                                                            />

                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                {this.state.haulback_is_geo_fenced && (
                                                    <Grid item xs={12} md={6}>
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel id="destination_haulback_geo_fence_distance_unit">{this.props.t('unitTranslations.distanceUnit')}</InputLabel>
                                                            <Select
                                                                label={this.props.t('unitTranslations.distanceUnit')}
                                                                labelId="destination_haulback_geo_fence_distance_unit"
                                                                value={this.state.haulback_geo_fence_distance_unit}
                                                                onChange={this.handleChange('haulback_geo_fence_distance_unit')}
                                                                inputProps={{
                                                                    name: "destination_haulback_geo_fence_distance_unit",
                                                                    id: "destination_haulback_geo_fence_distance_unit"
                                                                }}
                                                                fullWidth
                                                                required
                                                                error={!this.state.haulback_geo_fence_distance_unit}
                                                            >
                                                                {this.props.units.length && this.props.units.length.map(unit => (
                                                                    <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.distanceUnits.${unit}`)}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                )}
                                            </Grid>
                                            {this.state.haulback_is_geo_fenced && (
                                                <Grid container spacing={10}>
                                                    <Grid item xs={12} md={6}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="haulback_pickup_geofence_distance"
                                                                label={this.props.t('jobTranslations.pickGeofenceDistance')}
                                                                value={this.state.haulback_pick_up_geo_fence_distance}
                                                                onChange={this.handleChange('haulback_pick_up_geo_fence_distance')}
                                                                type="number"
                                                                variant="outlined"
                                                                inputProps={{
                                                                    min: 0.01,
                                                                    step: 0.01
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="haulback_dropoff_geofence_distance"
                                                                label={this.props.t('jobTranslations.dropGeofenceDistance')}
                                                                value={this.state.haulback_drop_off_geo_fence_distance}
                                                                onChange={this.handleChange('haulback_drop_off_geo_fence_distance')}
                                                                type="number"
                                                                variant="outlined"
                                                                inputProps={{
                                                                    min: 0.01,
                                                                    step: 0.01
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {(this.state.transaction_type_id == 2 && this.state.record_weight) ? <> 
                                                <Grid container spacing={10}>
                                                    <Grid item xs={12} md={4}>
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">{this.props.t('jobTranslations.truckWeighLocation')}</FormLabel>
                                                            <RadioGroup
                                                                aria-label="haulback_truck_weigh_location"
                                                                name="haulback_truck_weigh_location"
                                                                value={this.state.haulback_truck_weigh_location}
                                                                onChange={this.handleChange('haulback_truck_weigh_location')}
                                                                row
                                                            >
                                                                <FormControlLabel
                                                                    value={'P'}
                                                                    control={<Radio color="primary" required />}
                                                                    label="Pickup"
                                                                    labelPlacement="end"
                                                                />
                                                                <FormControlLabel
                                                                    value={'D'}
                                                                    control={<Radio color="primary" required />}
                                                                    label="Dropoff"
                                                                    labelPlacement="end"
                                                                />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                
                                                    {this.state.haulback_truck_weigh_location && <>
                                                        <Grid item xs={12} md={4}>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">{this.props.t('jobTranslations.tpScaleOnSite')}</FormLabel>
                                                                <RadioGroup
                                                                    aria-label="haulback_automated_scale"
                                                                    name="haulback_automated_scale"
                                                                    value={this.state.haulback_automated_scale}
                                                                    onChange={this.handleChange('haulback_automated_scale', 'radio-boolean')}
                                                                    row
                                                                >
                                                                    <FormControlLabel
                                                                        value={true}
                                                                        control={<Radio color="primary" required />}
                                                                        label={this.props.t('yes')}
                                                                        labelPlacement="end"
                                                                    />
                                                                    <FormControlLabel
                                                                        value={false}
                                                                        control={<Radio color="primary" required />}
                                                                        label={this.props.t('no')}
                                                                        labelPlacement="end"
                                                                    />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        {this.state.haulback_automated_scale && <Grid item xs={12} md={4}>
                                                            <FormControl fullWidth variant="outlined">
                                                                {this.state.haulback_truck_weigh_location == 'P' ? (<>
                                                                    <InputLabel id="job_haulback_pick_up_scale_id">{this.props.t('jobTranslations.pickupScale')}</InputLabel>
                                                                    <Select
                                                                        labelId="job_haulback_pick_up_scale_id"
                                                                        label={this.props.t('jobTranslations.pickupScale')}
                                                                        value={this.state.haulback_pick_up_scale_id}
                                                                        onChange={this.handleChange('haulback_pick_up_scale_id')}
                                                                        fullWidth
                                                                        required
                                                                        inputProps={{
                                                                            name: "job_haulback_pick_up_scale_id",
                                                                            id: "job_haulback_pick_up_scale_id"
                                                                        }}
                                                                        error={!this.state.haulback_pick_up_scale_id}
                                                                    >
                                                                        {this.state.haulbackScales.map(scale => (
                                                                            <MenuItem key={scale.id} value={scale.id}>{`${scale.name} - ${scale.hardware_specs.make} ${scale.hardware_specs.model}`}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                                </>) : (<>
                                                                    <InputLabel id="job_haulback_drop_off_scale_id">{this.props.t('jobTranslations.dropoffScale')}</InputLabel>
                                                                    <Select
                                                                        labelId="job_haulback_drop_off_scale_id"
                                                                        label={this.props.t('jobTranslations.dropoffScale')}
                                                                        value={this.state.haulback_drop_off_scale_id}
                                                                        onChange={this.handleChange('haulback_drop_off_scale_id')}
                                                                        fullWidth
                                                                        required
                                                                        inputProps={{
                                                                            name: "job_haulback_drop_off_scale_id",
                                                                            id: "job_haulback_drop_off_scale_id"
                                                                        }}
                                                                        error={!this.state.haulback_drop_off_scale_id}
                                                                    >
                                                                        {this.state.haulbackScales.map(scale => (
                                                                            <MenuItem key={scale.id} value={scale.id}>{`${scale.name} - ${scale.hardware_specs.make} ${scale.hardware_specs.model}`}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                                </>)}
                                                            </FormControl>
                                                        </Grid>}
                                                    </>}
                                                </Grid>
                                                {/* <Grid container spacing={10}>
                                                    <Grid item xs={12} md={6}>
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">{this.props.t('jobTranslations.weighmasterDoesntChange')}</FormLabel>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={this.state.haulback_weighmaster_doesnt_change}
                                                                            onChange={this.handleChange("haulback_weighmaster_doesnt_change", 'boolean')}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    {this.state.haulback_weighmaster_doesnt_change && <Grid item xs={12} md={6}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="haulback_constant_weighmaster_name"
                                                                label={this.props.t('deliveryTranslations.weighmasterName')}
                                                                value={this.state.haulback_constant_weighmaster_name}
                                                                onChange={this.handleChange("haulback_constant_weighmaster_name")}
                                                                type="text"
                                                                required
                                                                error={!this.state.haulback_constant_weighmaster_name}
                                                                variant="outlined"
                                                            />
                                                        </FormControl>
                                                    </Grid>}
                                                </Grid> */}
                                            </> : null}
                                            {/* {this.state.is_removal_job && <Grid container spacing={10}>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">{this.props.t('jobTranslations.trackDriverDestination')}</FormLabel>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={this.state.track_driver_destination}
                                                                        onChange={this.handleChange("track_driver_destination", 'boolean')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>} */}
                                            {/* {(this.state.transaction_type_id == 2) && (<>
                                                <Grid container spacing={10}>
                                                    <Grid item xs={12} md={4}>
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">{this.props.t('jobTranslations.autoBOL')}</FormLabel>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={this.state.auto_bol}
                                                                            onChange={this.handleChange("auto_bol", 'boolean')}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="bol_start"
                                                                label={this.props.t('jobTranslations.bolStart')}
                                                                value={this.state.bol_start}
                                                                onChange={this.handleChange("bol_start")}
                                                                type="number"
                                                                inputProps={{
                                                                    min: 1,
                                                                    step: 1
                                                                }}
                                                                // error={!this.state.bol_start}
                                                                variant="outlined"
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                id="po_number"
                                                                label={this.props.t('jobTranslations.poNumber')}
                                                                value={this.state.po_number}
                                                                onChange={this.handleChange("po_number")}
                                                                type="number"
                                                                inputProps={{
                                                                    min: 1,
                                                                    step: 1
                                                                }}
                                                                // error={!this.state.po_number}
                                                                variant="outlined"
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </>)} */}
                                        </>}
                                        {/* {Boolean(this.state.hasAdvancedSettings[4]) && <>
                                            <Grid container spacing={10}>
                                                {this.state.showAdvancedSettings[4] ? <Grid item xs>
                                                    <Button
                                                        fullWidth
                                                        onClick={() => this.setStateIfMounted({
                                                            showAdvancedSettings: {
                                                                ...this.state.showAdvancedSettings,
                                                                4: false
                                                            }
                                                        })}
                                                        type="button"
                                                    >
                                                        Hide Advanced Settings
                                                    </Button>
                                                </Grid> : <Grid item xs>
                                                    <Button
                                                        fullWidth
                                                        onClick={() => this.setStateIfMounted({
                                                            showAdvancedSettings: {
                                                                ...this.state.showAdvancedSettings,
                                                                4: true
                                                            }
                                                        })}
                                                        type="button"
                                                    >
                                                        Show Advanced Settings
                                                    </Button>
                                                </Grid>}
                                            </Grid>
                                        </>}
                                        {Boolean(this.state.showAdvancedSettings[4]) && <>
                                            <Grid container spacing={10}>
                                                <Grid item xs={12} md={6}>
                                                    advanced settings
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    asiofisdn
                                                </Grid>
                                            </Grid>
                                        </>} */}
                                        <Grid container spacing={10}>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" fullWidth type="button" onClick={() => this.prevPage()}>
                                                    {this.props.t('actions.previous')}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" fullWidth type="submit">
                                                    {this.props.t('actions.next')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>}
                                </TabPanel>
                                <TabPanel value={this.state.tabValue} index={this.summaryPage} style={{ display: (this.state.tabValue == this.summaryPage && !this.state.loading) ? "" : "none" }}>
                                    {this.state.tabValue == this.summaryPage && <form autoComplete="off" onSubmit={(event) => this.submitJob(event)}>
                                        <Grid container spacing={10}>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h4" style={{ textDecoration: "underline" }} mb={3}><Bold>{this.props.t('jobTranslations.jobDetails')}</Bold></Typography>
                                                {this.state.jobType == 'standard' && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.jobType')}:</Bold> {this.props.t('jobTranslations.jobTypes.standard')}</Typography>}
                                                {this.state.jobType == 'RMHMA' && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.jobType')}:</Bold> RMHMA</Typography>}
                                                {this.state.jobType == 'fourway' && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.jobType')}:</Bold> {this.props.t('jobTranslations.jobTypes.fourway')}</Typography>}
                                                {this.state.jobType == 'unknownDropoff' && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.jobType')}:</Bold> {this.props.t('jobTranslations.jobTypes.unknownDropoff')}</Typography>}
                                                {this.state.jobType == 'unknownPickup' && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.jobType')}:</Bold> {this.props.t('jobTranslations.jobTypes.unknownPickup')}</Typography>}
                                                {this.state.jobType == 'demolition' && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.jobType')}:</Bold> {this.props.t('jobTranslations.jobTypes.demolition')}</Typography>}
                                                <Typography color="textPrimary" mb={1}><Bold>{this.props.t('timeTranslations.startDate')}:</Bold> {this.state.start_date && this.props.helpers.getLongDate(this.state.start_date)[1]}</Typography>
                                                <Typography color="textPrimary" mb={1}><Bold>{this.props.t('timeTranslations.endDate')}:</Bold> {this.state.end_date && this.props.helpers.getLongDate(this.state.end_date)[1]}</Typography>
                                                <Typography color="textPrimary" mb={1}><Bold>{this.props.t('timeTranslations.openingTime')}:</Bold> {this.state.opening_time && this.props.helpers.getLongDate(this.state.opening_time)[2]}</Typography>
                                                <Typography color="textPrimary" mb={1}><Bold>{this.props.t('timeTranslations.closingTime')}:</Bold> {this.state.closing_time && this.props.helpers.getLongDate(this.state.closing_time)[2]}</Typography>
                                                <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.biddingEnds')}:</Bold> {this.state.bidding_ends && this.props.helpers.getLongDate(this.state.bidding_ends)[1]}</Typography>
                                                {this.state.bid_amount_status == 'prenegotiated_only' && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.bidType')}:</Bold> {this.props.t('jobTranslations.bid.options.prenegotiated_only')}</Typography>}
                                                <Typography color="textPrimary" mb={1}><Bold>{this.props.t('materialTranslations.materials')}:</Bold> {this.state.displayMaterials}</Typography>
                                                <Typography color="textPrimary" mb={1}><Bold>{this.props.t('vehicleTranslations.equipment')}:</Bold> {this.state.displayEquipment}</Typography>
                                                {this.state.estimated_loads && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.estimatedNumLoads')}:</Bold> {this.state.estimated_loads}</Typography>}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h4" style={{ textDecoration: "underline" }} mb={3}><Bold>{this.props.t('jobTranslations.routeDetails')}</Bold></Typography>
                                                <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.checkinType')}:</Bold> {this.props.t(`jobTranslations.checkTypes.${this.state.checkinType.value}`)}</Typography>
                                                <Typography color="textPrimary" mb={3}><Bold>{this.props.t('jobTranslations.checkoutType')}:</Bold> {this.props.t(`jobTranslations.checkTypes.${this.state.checkoutType.value}`)}</Typography>

                                                {this.state.bid_amount_status == 'open' && <>
                                                    <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.bidType')}:</Bold> {this.props.t('jobTranslations.bid.options.open')}</Typography>
                                                    <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.amount')}:</Bold> {this.props.t('jobTranslations.bid.options.open')}</Typography>
                                                </>}
                                                {this.state.bid_amount_status == 'fixed' && <>
                                                    <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.bidType')}:</Bold> {this.props.t('jobTranslations.bid.options.fixed')}</Typography>
                                                    <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.amount')}:</Bold> {this.state.currency && this.state.currency.symbol_prefix}{this.state.amount}</Typography>
                                                </>}
                                                {this.state.bid_amount_status == 'prenegotiated_only' && <>
                                                    <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.bidType')}:</Bold> {this.props.t('jobTranslations.bid.options.prenegotiated_only')}</Typography>
                                                    <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.amount')}:</Bold> {this.props.t('jobTranslations.bid.prenegotiated_only')}</Typography>
                                                </>}
                                                {this.state.transaction_type_id == 1 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-load')}</Typography>}
                                                {this.state.transaction_type_id == 2 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-weight')} ({this.props.t(`unitTranslations.weightUnits.${this.state.per_weight_job_units}`)})</Typography>}
                                                {this.state.transaction_type_id == 3 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-hour')}</Typography>}
                                                {this.state.transaction_type_id == 4 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-volume')} ({this.props.t(`unitTranslations.volumeUnits.${this.state.per_volume_job_units}`)})</Typography>}
                                                {(this.state.jobType == 'fourway') && (<>
                                                    <Divider my={2}/>
                                                    <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.materialSupplierPrice')}:</Bold> {this.state.currency && this.state.currency.symbol_prefix}{this.state.company_supplier_price}</Typography>
                                                    {this.state.company_supplier_transaction_type_id == 1 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-load')}</Typography>}
                                                    {this.state.company_supplier_transaction_type_id == 2 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-weight')} ({this.props.t(`unitTranslations.weightUnits.${this.state.company_supplier_per_weight_job_units}`)})</Typography>}
                                                    {this.state.company_supplier_transaction_type_id == 3 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-hour')}</Typography>}
                                                    {this.state.company_supplier_transaction_type_id == 4 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-volume')} ({this.props.t(`unitTranslations.volumeUnits.${this.state.company_supplier_per_volume_job_units}`)})</Typography>}
                                                </>)}
                                                {(this.state.jobType == 'demolition') && (<>
                                                    <Divider my={2}/>
                                                    <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.destinationPrice')}:</Bold> {this.state.currency && this.state.currency.symbol_prefix}{this.state.demolition_destination_price}</Typography>
                                                    {this.state.demolition_destination_transaction_type_id == 1 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-load')}</Typography>}
                                                    {this.state.demolition_destination_transaction_type_id == 2 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-weight')} ({this.props.t(`unitTranslations.weightUnits.${this.state.demolition_destination_per_weight_job_units}`)})</Typography>}
                                                    {this.state.demolition_destination_transaction_type_id == 3 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-hour')}</Typography>}
                                                    {this.state.demolition_destination_transaction_type_id == 4 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-volume')} ({this.props.t(`unitTranslations.volumeUnits.${this.state.demolition_destination_per_volume_job_units}`)})</Typography>}
                                                </>)}
                                                {(this.state.jobType == 'fourway' || this.state.jobType == 'demolition') && (<>
                                                    <Divider my={2}/>
                                                    <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.customerPrice')}:</Bold> {this.state.currency && this.state.currency.symbol_prefix}{this.state.company_customer_price}</Typography>
                                                    {this.state.company_customer_transaction_type_id == 1 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-load')}</Typography>}
                                                    {this.state.company_customer_transaction_type_id == 2 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-weight')} ({this.props.t(`unitTranslations.weightUnits.${this.state.company_customer_per_weight_job_units}`)})</Typography>}
                                                    {this.state.company_customer_transaction_type_id == 3 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-hour')}</Typography>}
                                                    {this.state.company_customer_transaction_type_id == 4 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-volume')} ({this.props.t(`unitTranslations.volumeUnits.${this.state.company_customer_per_volume_job_units}`)})</Typography>}
                                                </>)}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={10} my={2}>
                                            {this.state.jobType != 'unknownPickup' && <Grid item xs={12} md={6}>
                                                <Button variant="contained" color="primary" size="small">From:</Button>
                                                {this.renderLocation('pickup')}
                                            </Grid>}
                                            {this.state.jobType != 'unknownDropoff' && <Grid item xs={12} md={6}>
                                                <Button variant="contained" color="primary" size="small">To:</Button>
                                                {this.renderLocation('dropoff')}
                                            </Grid>}
                                        </Grid>
                                        {this.state.warnedBadAddressInput && <Grid container spacing={10}>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="estimated_meters"
                                                        label={this.props.t('jobTranslations.estimatedDistance')}
                                                        value={this.state.estimated_meters}
                                                        onChange={this.handleChange("estimated_meters")}
                                                        type="number"
                                                        required
                                                        error={!this.state.estimated_meters}
                                                        variant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="job_estimated_meters_units">{this.props.t('unitTranslations.distanceUnit')}</InputLabel>
                                                    <Select
                                                        labelId="job_estimated_meters_units"
                                                        label={this.props.t('unitTranslations.distanceUnit')}
                                                        value={this.state.estimated_meters_units}
                                                        onChange={this.handleChange('estimated_meters_units')}
                                                        fullWidth
                                                        required
                                                        inputProps={{
                                                            name: "job_estimated_meters_units",
                                                            id: "job_estimated_meters_units"
                                                        }}
                                                        error={!this.state.estimated_meters_units}
                                                    >
                                                        {this.props.units.length.map(unit => (
                                                            <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.distanceUnits.${unit}`)}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>}
                                        {Boolean((this.props.type === 'create' || this.props.type === 'duplicate') && this.state.specificHaulers && this.state.bid_amount_status === 'fixed' && this.state.amount) && <Grid container spacing={10}>
                                            {this.state.specificHaulers.map(hauler => (
                                                <Grid item xs={12} md={6} key={`${hauler.id}_${hauler.bidAsCompany.toString()}_${hauler.award.toString()}`}>
                                                    <Grid container spacing={10}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h4" style={{ textDecoration: "underline" }} mb={3}><Bold>Hauler Bid Details</Bold></Typography>
                                                            <Typography color="textPrimary" mb={1}><Bold>{this.props.t('companyTranslations.company')}:</Bold> {hauler.driver_company.name} {Boolean(hauler.identifier) && `- ${hauler.identifier}`}</Typography>
                                                            <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.amount')}:</Bold> {this.state.currency && this.state.currency.symbol_prefix}{this.state.amount}</Typography>
                                                            {this.state.transaction_type_id == 1 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-load')}</Typography>}
                                                            {this.state.transaction_type_id == 2 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-weight')} ({this.props.t(`unitTranslations.weightUnits.${this.state.per_weight_job_units}`)})</Typography>}
                                                            {this.state.transaction_type_id == 3 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-hour')}</Typography>}
                                                            {this.state.transaction_type_id == 4 && <Typography color="textPrimary" mb={1}><Bold>{this.props.t('jobTranslations.transactionType')}:</Bold> {this.props.t('jobTranslations.transactionTypes.per-volume')} ({this.props.t(`unitTranslations.volumeUnits.${this.state.per_volume_job_units}`)})</Typography>}
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">Bid as {hauler.driver_company.name}</FormLabel>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={hauler.bidAsCompany}
                                                                                onChange={event => {
                                                                                    const newHaulers = [...this.state.specificHaulers].map(newHauler => {
                                                                                        if (hauler.id === newHauler.id) {
                                                                                            newHauler.bidAsCompany = event.target.checked
                                                                                        }
                                                                                        return newHauler
                                                                                    })
                                                                                    console.log('new haulers', newHaulers, event)
                                                                                    this.setStateIfMounted({
                                                                                        specificHaulers: newHaulers
                                                                                    })
                                                                                }}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        {Boolean(hauler.allow_preferring_company_to_place_bid_on_behalf && hauler.bidAsCompany) && <Grid item xs={12} md={6}>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">Auto Award Bid</FormLabel>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={hauler.award}
                                                                                onChange={event => {
                                                                                    const newHaulers = [...this.state.specificHaulers].map(newHauler => {
                                                                                        if (hauler.id === newHauler.id) {
                                                                                            newHauler.award = event.target.checked
                                                                                        }
                                                                                        return newHauler
                                                                                    })
                                                                                    console.log('new haulers', newHaulers, event)
                                                                                    this.setStateIfMounted({
                                                                                        specificHaulers: newHaulers
                                                                                    })
                                                                                }}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>}

                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>}
                                        <Grid container spacing={10}>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" fullWidth type="button" onClick={() => this.prevPage()}>
                                                    {this.props.t('actions.previous')}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" fullWidth type="submit">
                                                    {this.props.t('actions.submit')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>}
                                </TabPanel>
                            </TabContext>
                            {this.state.openCustomMaterialModal && <CustomMaterialModal
                                setModalOpen={this.setCustomMaterialModal}
                                listName={this.state.customMaterialListName}
                                refreshMaterials={this.refreshMaterials}
                                enableListNameChange={true}
                            />}
                            {this.state.openQueryForMaterialsModal && <SearchMaterialsModal 
                                setModalOpen={this.setQueryForMaterialsModal}
                                onCustomMaterialsToAdd={(materials) => {
                                    this.addMaterial(false, materials)
                                }}
                            />}
                        </Paper>
                    </Grid>
                    <NotificationSnackbar />
                </Grid>
            </Modal>
        )
    }
}
const msp = state => ({
    helpers: state.helpers,
    token: state.session.user.token,
    userDefaults: state.session.user.settings,
    company: state.session.company,
    sites: state.entities.sites,
    scales: state.entities.scales,
    units: state.entities.units,
    currencies: state.entities.currencies,
    equipmentTypes: state.entities.equipment,
    supportedCountries: state.entities.supportedCountries,
    materials: state.entities.materials,
    customMaterials: state.entities.customMaterials,
    customMaterialListNames: state.entities.customMaterialListNames,
})
const mdp = dispatch => ({
    getCompanySites: (token, companyID) => dispatch(getCompanySites(token, companyID)),
    getValidUnits: (token) => dispatch(getValidUnits(token)),
    getCurrencies: (token) => dispatch(getCurrencies(token)),
    getEquipment: (token) => dispatch(getEquipment(token)),
    getSupportedCountries: () => dispatch(getSupportedCountries()),
    getMaterials: (token) => dispatch(getMaterials(token)),
    DEPRECATED_getCustomMaterials: (token, companyID) => dispatch(DEPRECATED_getCustomMaterials(token, companyID)),
    popAlert: (success, title, message) => dispatch(popAlert(success, title, message)),
    getPendingJobs: (token, company) => dispatch(getPendingJobs(token, company)),
    clearPaginatedPendingJobs: () => dispatch(clearPaginatedPendingJobs())
})
export default connect(msp, mdp)(withTranslation()(withRouter(CreateEditDuplicateJobModal)))