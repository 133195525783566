import { CompanyEntityTypeNames, DriverEntityTypeNames } from '../reducers/entities';
import * as JobAPI from '../util/jobAPI';

export const RECEIVE_JOB = "RECEIVE_JOB";
export const RECEIVE_CURRENT_JOBS = "RECEIVE_CURRENT_JOBS";
export const RECEIVE_CURRENT_JOBS_PAGE = "RECEIVE_CURRENT_JOBS_PAGE";
export const RECEIVE_NEARBY_JOBS = "RECEIVE_NEARBY_JOBS";
export const RECEIVE_NEARBY_JOBS_PAGE = "RECEIVE_NEARBY_JOBS_PAGE";
export const RECEIVE_PENDING_JOBS = "RECEIVE_PENDING_JOBS";
export const RECEIVE_PENDING_JOBS_PAGE = "RECEIVE_PENDING_JOBS_PAGE";
export const RECEIVE_PAST_JOBS = "RECEIVE_PAST_JOBS";
export const RECEIVE_PAST_JOBS_PAGE = "RECEIVE_PAST_JOBS_PAGE";
export const RECEIVE_IN_PROGRESS_JOBS = "RECEIVE_IN_PROGRESS_JOBS";
export const RECEIVE_IN_PROGRESS_JOBS_PAGE = "RECEIVE_IN_PROGRESS_JOBS_PAGE";
export const RECEIVE_EXTERNAL_JOBS = "RECEIVE_EXTERNAL_JOBS";
export const RECEIVE_EXTERNAL_JOBS_PAGE = "RECEIVE_EXTERNAL_JOBS_PAGE";
export const RECEIVE_BIDDED_JOBS = "RECEIVE_BIDDED_JOBS";
export const RECEIVE_BIDDED_JOBS_PAGE = "RECEIVE_BIDDED_JOBS_PAGE";
export const RECEIVE_PLANT_ORDERS_PAGE = "RECEIVE_PLANT_ORDERS_PAGE";
export const CLEAR_PAGINATED_CURRENT_JOBS = "CLEAR_PAGINATED_CURRENT_JOBS";
export const CLEAR_PAGINATED_PENDING_JOBS = "CLEAR_PAGINATED_PENDING_JOBS";
export const CLEAR_PAGINATED_BIDDED_JOBS = "CLEAR_PAGINATED_BIDDED_JOBS";
export const CLEAR_PAGINATED_IN_PROGRESS_JOBS = "CLEAR_PAGINATED_IN_PROGRESS_JOBS";
export const CLEAR_PAGINATED_NEARBY_JOBS = "CLEAR_PAGINATED_NEARBY_JOBS";
export const CLEAR_PAGINATED_PLANT_ORDERS = "CLEAR_PAGINATED_PLANT_ORDERS";

export const clearPaginatedCurrentJobs = () => ({
    type: CLEAR_PAGINATED_CURRENT_JOBS
})
export const clearPaginatedPendingJobs = () => ({
    type: CLEAR_PAGINATED_PENDING_JOBS
})
export const clearPaginatedBiddedJobs = () => ({
    type: CLEAR_PAGINATED_BIDDED_JOBS
})
export const clearPaginatedInProgressJobs = () => ({
    type: CLEAR_PAGINATED_IN_PROGRESS_JOBS
})
export const clearPaginatedNearbyJobs = () => ({
    type: CLEAR_PAGINATED_NEARBY_JOBS
})
export const clearPaginatedPlantOrders = () => ({
    type: CLEAR_PAGINATED_PLANT_ORDERS
})

export const receiveJob = (job) => ({
    type: RECEIVE_JOB,
    job
})
export const receiveCurrentJobs = (jobs) => ({
    type: RECEIVE_CURRENT_JOBS,
    jobs
})
export const receivePlantOrdersPage = (page, resData) => ({
    type: RECEIVE_PLANT_ORDERS_PAGE,
    page,
    pageCount: resData.total,
    jobs: resData.data
})
export const receiveCurrentJobsPage = (page, resData) => ({
    type: RECEIVE_CURRENT_JOBS_PAGE,
    page,
    pageCount: resData.total,
    jobs: resData.data
})
export const receiveNearbyJobs = (jobs) => ({
    type: RECEIVE_NEARBY_JOBS,
    jobs
})
export const receiveNearbyJobsPage = (page, resData) => ({
    type: RECEIVE_NEARBY_JOBS_PAGE,
    page,
    jobs: resData.data
})
export const receivePendingJobs = (jobs) => ({
    type: RECEIVE_PENDING_JOBS,
    jobs
})
export const receivePendingJobsPage = (page, resData) => ({
    type: RECEIVE_PENDING_JOBS_PAGE,
    page,
    pageCount: resData.total,
    jobs: resData.data
})
export const receivePastJobs = (jobs) => ({
    type: RECEIVE_PAST_JOBS,
    jobs
})
export const receivePastJobsPage = (page, resData) => ({
    type: RECEIVE_PAST_JOBS_PAGE,
    page,
    pageCount: resData.total,
    jobs: resData.data
})
export const receiveInProgressJobs = (jobs) => ({
    type: RECEIVE_IN_PROGRESS_JOBS,
    jobs
})
export const receiveInProgressJobsPage = (page, resData) => ({
    type: RECEIVE_IN_PROGRESS_JOBS_PAGE,
    page,
    pageCount: resData.total,
    jobs: resData.data
})
export const receiveExternalJobs = (jobs) => ({
    type: RECEIVE_EXTERNAL_JOBS,
    jobs
})
export const receiveBiddedJobs = (jobs) => ({
    type: RECEIVE_BIDDED_JOBS,
    jobs
})
export const receiveBiddedJobsPage = (page, resData) => ({
    type: RECEIVE_BIDDED_JOBS_PAGE,
    page,
    pageCount: resData.total,
    jobs: resData.data
})

export const getSpecificJob = (token, companyID, jobID) => dispatch => (
    JobAPI.getJob(token, companyID, jobID).then(res => {
        if (res.success) {
            dispatch(receiveJob(res.data))
        }
        return res
    })
)

export const getPlantOrdersPage = (token, companyID, page, options = {}) => dispatch => {
    const params = []
    if (options.filterOptions) {
        const { filterOptions } = options
        if (filterOptions.start_date_range_start) {
            params.push({
                key: 'start_date_range_start',
                value: filterOptions.start_date_range_start
            })
        }
        if (filterOptions.end_date_range_end) {
            params.push({
                key: 'end_date_range_end',
                value: filterOptions.end_date_range_end
            })
        }
        if (filterOptions.with_extended_attribute_values) {
            params.push({
                key: 'with_extended_attribute_values',
                value: filterOptions.with_extended_attribute_values ? 1 : 0
            })
        }
        if (filterOptions.siteID) {
            params.push({
                key: 'sites[]',
                value: filterOptions.siteID
            })
        }
        if (filterOptions.siteID) {
            params.push({
                key: 'sites[]',
                value: filterOptions.siteID
            })
        }
        if (filterOptions.extended_attribute_filters) {
            filterOptions.extended_attribute_filters.forEach((filter, index) => {
                params.push({
                    key: `extended_attribute_filters[${index}][extended_job_type]`,
                    value: filter.extended_job_type
                })
                params.push({
                    key: `extended_attribute_filters[${index}][extended_attribute_name]`,
                    value: filter.extended_attribute_name
                })
                params.push({
                    key: `extended_attribute_filters[${index}][range_start]`,
                    value: filter.range_start
                })
                params.push({
                    key: `extended_attribute_filters[${index}][range_end]`,
                    value: filter.range_end
                })
            })
        }
    }
    return JobAPI.getJobsCreatedByCompany(token, companyID, params).then(
        (jobsResponse) => {
            console.log('get plant orders page res from actions', jobsResponse)
            if (jobsResponse.success && dispatch) {
                jobsResponse.data.data = jobsResponse.data.data.filter(job => job.extended_attribute_values.length)
                dispatch(receivePlantOrdersPage(page, jobsResponse.data))
            }
            return jobsResponse
        }
    )
}

export const getCurrentJobsPage = (
        token, 
        company, 
        page, 
        options = { 
            forDispatch: false, 
            forDispatchCheck: false, 
        }, 
        filterOptions
    ) => dispatch => {
    return getCurrentJobsPageWithoutDispatch(token, company, page, options, dispatch, filterOptions)
}
export const getAllCurrentJobs = (token, company, options, filterOptions) => async dispatch => {
    let fetchedAll = false
    let page = 1
    while (!fetchedAll) {
        await getCurrentJobsPageWithoutDispatch(token, company, page, options, dispatch, filterOptions).then(res => {
            if (res.success) {
                dispatch(receiveCurrentJobsPage(page, res.data))
                if (res.data.next_page_url) {
                    page += 1
                } else {
                    fetchedAll = true
                }
            } else {
                fetchedAll = true
            }
        })
    }
}
export const getCurrentJobsPageWithoutDispatch = async (token, company, page, options = { forDispatch: false, forDispatchCheck: false }, dispatch, filterOptions) => {
    console.log('getting jobs with dispatch', Boolean(dispatch))
    console.log('get current jobs for company', company)
    const params = [
        { key: 'page', value: page },
        { key: 'is_completed', value: 0 },
    ];

    if (!options.forDispatch) {

    } else {
        // params.push({ key: 'has_dispatch_order', value: 1 })
    }
    if (filterOptions) {
        Object.keys(filterOptions).forEach(filterKey => {
            params.push({
                key: filterKey,
                value: filterOptions[filterKey]
            })
        })
    }
    if (company.title == 'Truck Driver') {

        params.push({ key: 'is_my_company_job', value: 1 })

        return JobAPI.getDriverJobs(token, company.id, params).then(
            (jobsResponse) => {
                if (jobsResponse.success && dispatch) {
                    dispatch(receiveCurrentJobsPage(page, jobsResponse.data))
                }
                return jobsResponse
            }
        )
    } else if (DriverEntityTypeNames.includes(company.entity_type.name)) {
        // params.push({ key: 'is_my_company_job', value: 0 })

        return JobAPI.getJobsBeingWorkedOnByMyCompany(token, company.id, params).then(
            (jobsResponse) => {
                if (jobsResponse.success && dispatch) {
                    dispatch(receiveCurrentJobsPage(page, jobsResponse.data))
                }
                return jobsResponse
            }
        )
    } else if (company.entity_type_id == 4) {

        console.log('fetching driver jobs')
        params.push({ key: 'is_my_company_job', value: 0 })

        return JobAPI.getDriverJobs(token, company.id, params).then(
            (jobsResponse) => {
                if (jobsResponse.success && dispatch) {
                    dispatch(receiveCurrentJobsPage(page, jobsResponse.data))
                }
                return jobsResponse
            }
        )
    } else {

        if (!options.forDispatch) {
            params.push({ key: 'is_current', value: 1 })
        }

        return JobAPI.getJobsCreatedByCompany(token, company.id, params).then(
            (jobsResponse) => {
                console.log('get curr job page res from actions', jobsResponse)
                if (jobsResponse.success && dispatch) {
                    dispatch(receiveCurrentJobsPage(page, jobsResponse.data))
                }
                return jobsResponse
            }
        )
    }
}
// export const getAllCurrentJobs = (token, company) => dispatch => {
//     if (company.title == 'Truck Driver' || company.entity_type_id == 4) {
//         return JobAPI.getAllCurrentJobs(token, company.id, true).then(
//             (jobsResponse) => {
//                 if (jobsResponse.success) {
//                     dispatch(receiveCurrentJobs(jobsResponse.data))
//                 }
//                 return jobsResponse
//             }
//         )
//     } else {
//         return JobAPI.getAssignedCurrentNotInProgressJobs(token, company.id, false).then(
//             (jobsResponse) => {
//                 if (jobsResponse.success) {
//                     dispatch(receiveCurrentJobs(jobsResponse.data))
//                 }
//                 return jobsResponse
//             }
//         )
//     }
// }

export const getNearbyJobsPage = (token, company, options = { relativeToCompany: false, page: 1 }) => dispatch => {
    return JobAPI.getNearbyJobs(token, company.id, options).then(
        (jobsResponse) => {
            if (jobsResponse.success) {
                dispatch(receiveNearbyJobsPage(options.page, jobsResponse.data))
            }
            return jobsResponse
        }
    )
}
export const getAllNearbyJobs = (token, company) => async dispatch => {
    let fetchedAll = false
    let page = 1
    while (!fetchedAll) {
        await getNearbyJobsPage(token, company, page).then(res => {
            if (res.success) {
                dispatch(receiveNearbyJobsPage(page, res.data))
                if (res.data.next_page_url) {
                    page += 1
                } else {
                    fetchedAll = true
                }
            } else {
                fetchedAll = true
            }
        })
    }
}
export const getPendingJobs = (token, company) => dispatch => {
    return JobAPI.getPendingJobs(token, company.id).then(
        (jobsResponse) => {
            if (jobsResponse.success) {
                dispatch(receivePendingJobs(jobsResponse.data))
            }
            return jobsResponse
        }
    )
}
export const getPendingJobsPage = (token, company, page, { filterOptions }) => dispatch => {
    const params = [
        { key: 'page', value: page },
        { key: 'is_completed', value: 0 },
        { key: 'is_current', value: 0 },
    ]
    if (filterOptions) {
        if (filterOptions.created_at_range_start) {
            params.push({
                key: 'created_at_range_start',
                value: filterOptions.created_at_range_start
            })
        }
        if (filterOptions.created_at_range_end) {
            params.push({
                key: 'created_at_range_end',
                value: filterOptions.created_at_range_end
            })
        }
        if (filterOptions.with_extended_attribute_values) {
            params.push({
                key: 'with_extended_attribute_values',
                value: filterOptions.with_extended_attribute_values ? 1 : 0
            })
        }
    }
    return JobAPI.getJobsCreatedByCompany(token, company.id, params).then(
        (jobsResponse) => {
            console.log('get pending job page res', jobsResponse)
            if (jobsResponse.success) {
                dispatch(receivePendingJobsPage(page, jobsResponse.data))
            }
            return jobsResponse
        }
    )
}
export const getAllPendingJobs = (token, company) => async dispatch => {
    let fetchedAll = false
    let page = 1
    while (!fetchedAll) {
        await getPendingJobsPage(token, company, page).then(res => {
            if (res.success) {
                dispatch(receivePendingJobsPage(page, res.data))
                if (res.data.next_page_url) {
                    page += 1
                } else {
                    fetchedAll = true
                }
            } else {
                fetchedAll = true
            }
        })
    }
}
export const getPastJobs = (token, company) => dispatch => {
    if (company.title != 'Truck Driver') {
        return JobAPI.getPastJobs(token, company.id).then(
            (jobsResponse) => {
                if (jobsResponse.success) {
                    dispatch(receivePastJobs(jobsResponse.data))
                }
                return jobsResponse
            }
        )
    } else {
        return JobAPI.getAssignedPastJobs(token, company.id).then(
            (jobsResponse) => {
                if (jobsResponse.success) {
                    dispatch(receivePastJobs(jobsResponse.data))
                }
                return jobsResponse
            }
        )
    }
}
export const getPastJobsPage = (token, company, page) => dispatch => {
    return getPastJobsPageWithoutDispatch(token, company, page, dispatch)
}
export const getPastJobsPageWithoutDispatch = (token, company, page, dispatch) => {
    const params = [
        { key: 'page', value: page },
        { key: 'is_completed', value: 1 },
    ]
    if (company.title == 'Truck Driver' || company.entity_type_id == 4) {
        return JobAPI.getDriverJobs(token, company.id, params).then(
            (jobsResponse) => {
                console.log('get past job page res', jobsResponse)
                if (jobsResponse.success && dispatch) {
                    dispatch(receivePastJobsPage(page, jobsResponse.data))
                }
                return jobsResponse
            }
        )
    } else {
        return JobAPI.getJobsCreatedByCompany(token, company.id, params).then(
            (jobsResponse) => {
                console.log('get past job page res', jobsResponse)
                if (jobsResponse.success && dispatch) {
                    dispatch(receivePastJobsPage(page, jobsResponse.data))
                }
                return jobsResponse
            }
        )
    }
}
export const getAllPastJobs = (token, company) => async dispatch => {
    let fetchedAll = false
    let page = 1
    while (!fetchedAll) {
        await getPastJobsPageWithoutDispatch(token, company, page).then(res => {
            if (res.success) {
                dispatch(receivePastJobsPage(page, res.data))
                if (res.data.next_page_url) {
                    page += 1
                } else {
                    fetchedAll = true
                }
            } else {
                fetchedAll = true
            }
        })
    }
}
export const getInProgressJobsPage = (token, company, page, options = {}) => dispatch => {
    return getInProgressJobsPageWithoutDispatch(token, company, page, options, dispatch)
}
export const getInProgressJobsPageWithoutDispatch = (token, company, page, options = {}, dispatch) => {
    const params = [
        { key: 'page', value: page },
        { key: 'has_in_progress_delivery', value: 1 },
    ]
    if (options['custom_material_lists[]']) {
        params.push({ key: 'custom_material_lists[]', value: options['custom_material_lists[]'] })
    }
    if (options['sites[]']) {
        params.push({ key: 'sites[]', value: options['sites[]'] })
    }
    if (company.title == 'Truck Driver' || company.entity_type_id == 4) {
        return JobAPI.getDriverJobs(token, company.id, params).then(
            (jobsResponse) => {
                console.log('get pending job page res', jobsResponse)
                if (jobsResponse.success && dispatch) {
                    dispatch(receiveInProgressJobsPage(page, jobsResponse.data))
                }
                return jobsResponse
            }
        )
    } else {
        return JobAPI.getJobsCreatedByCompany(token, company.id, params).then(
            (jobsResponse) => {
                console.log('get pending job page res', jobsResponse)
                if (jobsResponse.success && dispatch) {
                    dispatch(receiveInProgressJobsPage(page, jobsResponse.data))
                }
                return jobsResponse
            }
        )
    }
}
export const getAllInProgressJobs = (token, company) => async dispatch => {
    let fetchedAll = false
    let page = 1
    while (!fetchedAll) {
        await getInProgressJobsPageWithoutDispatch(token, company, page).then(res => {
            if (res.success) {
                dispatch(receiveInProgressJobsPage(page, res.data))
                if (res.data.next_page_url) {
                    page += 1
                } else {
                    fetchedAll = true
                }
            } else {
                fetchedAll = true
            }
        })
    }
}
// export const getAllInProgressJobs = (token, company, asCompany) => dispatch => {
//     if (company.title != 'Truck Driver') {
//         return JobAPI.getAllInProgressJobs(token, company.id).then(
//             (jobsResponse) => {
//                 if (jobsResponse.success) {
//                     dispatch(receiveInProgressJobs(jobsResponse.data))
//                 }
//                 return jobsResponse
//             }
//         )
//     } else {
//         return JobAPI.getAssignedInProgressJobs(token, company.id, asCompany).then(
//             (jobsResponse) => {
//                 if (jobsResponse.success) {
//                     dispatch(receiveInProgressJobs(jobsResponse.data))
//                 }
//                 return jobsResponse
//             }
//         )
//     }
// }
export const getExternalJobs = (token, includeCompleted) => dispatch => {
    return JobAPI.getExternalJobs(token, includeCompleted).then(
        (jobsResponse) => {
            if (jobsResponse.success) {
                dispatch(receiveExternalJobs(jobsResponse.data))
            }
            return jobsResponse
        }
    )
}
export const getBiddedJobs = (token, company) => dispatch => {
    return JobAPI.getBiddedJobs(token, company.id).then(
        (jobsResponse) => {
            if (jobsResponse.success) {
                dispatch(receiveBiddedJobs(jobsResponse.data))
            }
            return jobsResponse
        }
    )
}
export const getBiddedJobsPage = (token, company, page, options = { forDispatch: false }) => dispatch => {
    const params = [
        { key: 'page', value: page },
        { key: 'is_completed', value: 0 },
        { key: 'is_my_company_job', value: 0 },
    ]
    if (options.forDispatch) {
        params.push({ key: 'has_bid_with_status[]', value: 'awarded' })
    } else {
        params.push({ key: 'has_pending_bid', value: 1 })
    }

    return JobAPI.getJobsBeingWorkedOnByMyCompany(token, company.id, params).then(
        (jobsResponse) => {
            console.log('get pending job page res', jobsResponse)
            if (jobsResponse.success) {
                dispatch(receiveBiddedJobsPage(page, jobsResponse.data))
            }
            return jobsResponse
        }
    )
}


