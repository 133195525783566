import { Box, Button, Card, CardContent, CardHeader, Grid, List, ListItem, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { chunk } from "lodash";
import { DropzoneDialog } from "material-ui-dropzone";
import React, { FormEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useRootSelector } from "../../../..";
import { getEquipment, getSupportedCountries } from "../../../../redux/actions/adminActions";
import { popAlert } from "../../../../redux/actions/sessionActions";
import { getCompanyVehicles } from "../../../../redux/actions/vehicleActions";
import { EquipmentType } from "../../../../redux/reducers/entities";
import { Country } from "../../../../redux/reducers/entities/Location";
import { parseError } from "../../../../redux/reducers/helpersReducerr";
import { createVehicle, CreateVehicleData } from "../../../../redux/util/vehicleAPI_ts";
import { SpacedGrid } from "../../Util/SpacedComponents";
import UploadMultipleVehicleLegalDocs from "../subcomponents/UploadMultipleVehicleLegalDocs";

enum Column {
    NAME,
    EQUIPMENT_TYPE,
    VIN,
    MAKE,
    MODEL,
    YEAR,
    LICENSE_PLATE_COUNTRY,
    LICENSE_PLATE,
    FOREIGN_LICENSE_PLATE_COUNTRY,
    FOREIGN_LICENSE_PLATE,
    TRAILER_ID,
    SECONDARY_TRAILER_ID,
    FUEL_USAGE,
    TANK_SIZE,
    // TANK_UNITS, This is merged with the relevant data
    NUM_AXLES,
    TARE_WEIGHT,
    // TARE_WEIGHT_UNITS, This is merged with the relevant data
    WEIGHT_CAP,
    // WEIGHT_CAP_UNITS, This is merged with the relevant data
    VOLUME_CAP,
    // VOLUME_CAP_UNITS, This is merged with the relevant data
}

const csvTemplate = `Name,Equipment Type,VIN,Make,Model,Year,License Plate Country,License Plate,Foreign License Plate Country,Foreign License Plate,Trailer ID,Secondary Trailer ID,Fuel Usage (MPG),Tank Size + Units,Number of Axles,Tare Weight + Units,Weight Capacity + Units,Volume Capacity + Units
,,,,,,,,,,,,,,,,,`

interface Props {
    setModalOpen: (bool: boolean) => void;
    setLoading: (bool: boolean) => void;
}

const CreateVehicleUpload = ({
    setModalOpen,
    setLoading
}: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const token = useRootSelector(state => state.session.user.token!!)
    const companyId = useRootSelector(state => state.session.company.id!!)
    const equipment = useRootSelector<EquipmentType[]>(state => state.entities.equipment)
    const countries = useRootSelector<Country[]>(state => state.entities.supportedCountries)
    const weightUnits = useRootSelector<string[]>(state => state.entities.units.weight)
    const volumeUnits = useRootSelector<string[]>(state => state.entities.units.volume)

    const [justCreatedVehicles, setJustCreatedVehicles] = useState(false)
    const [newVehicleIds, setNewVehicleIds] = useState<number[]>([])

    // Make sure that the equipment types have been fetched
    // CreateVehicleManual gets rendered first and loads this data, so this is just here for redundancy
    useEffect(() => {
        if (!equipment?.length) {
            dispatch(getEquipment(token))
        }
        if (!countries?.length) {
            dispatch(getSupportedCountries(token))
        }
    }, [])

    // Chunk equipment by 6 and paginate
    const chunkedEquipment: Array<EquipmentType[]> = useMemo(() => chunk(equipment, 6), [equipment])

    // Handle pagination for equipment types
    const [page, setPage] = useState(1)


    const [openDropzoneDialog, setOpenDropzoneDialog] = useState(false)
    const [file, setFile] = useState<File>()

    const getRelevantEquipmentTypeId = (eqType: string): number => {
        const eq = equipment.find(eq => eq.name.toLowerCase() === eqType.trim().toLowerCase())
        return eq?.id || 0
    }

    const getRelevantCountryId = (countryCode: string): number => {
        const country = countries.find(ctry => ctry.code.toLowerCase() === countryCode.trim().toLowerCase())
        return country?.id || 0
    }

    const displayOptionError = (dataDescription: string, lineNumber: number) => {
        setLoading(false)
        dispatch(popAlert('error', t('error'), `The ${dataDescription} on line ${lineNumber} doesn't match any of the options. Please fix the data and resubmit the CSV.`))
    }
    const displayFormatError = (dataDescription: string, lineNumber: number) => {
        setLoading(false)
        dispatch(popAlert('error', t('error'), `The ${dataDescription} on line ${lineNumber} doesn't match the expected format. Please refer to the notes highlighted in red and resubmit the CSV.`))
    }
    const displayMeasurementError = (dataDescription: string, lineNumber: number) => {
        setLoading(false)
        dispatch(popAlert('error', t('error'), `The ${dataDescription} in line ${lineNumber} doesn't seem to be a number. Please enter a valid measurement and resubmit the CSV.`))
    }

    /**
     * Helper function to parse data given in <measurement> <unit> format.
     * 
     * Ensures that the data given follows the format, has a valid number for the measurement, and a valid unit.
     * 
     * @see {@link processCSV}
     * @param dataDescription Used to tell which data is erroneous, so the errors are more descriptive
     * @param data The raw data from the cell
     * @param unitOptions Options to validate the given unit against
     * @param lineNumber The line number in case the data is erronous, so the errors are more descriptive
     * @returns Tuple with valid data or empty array
     */
    const validateMeasurement = (dataDescription: string, data: string, unitOptions: string[], lineNumber: number): [] | [number, string] => {
        // Validate format
        const splitData = data?.split(" ").filter(Boolean) || []
        if (splitData.length !== 2) {
            displayFormatError(`${dataDescription} (${data})`, lineNumber)
            return []
        }
        const [rawMeasurement, rawUnit] = splitData.map(str => str.trim())

        // Measurement
        const measurement = Number.parseInt(rawMeasurement)
        if (!measurement) {
            displayMeasurementError(`${dataDescription} (${rawMeasurement})`, lineNumber)
            return []
        }

        // Unit
        const unit = rawUnit.toLowerCase()
        if (!unitOptions.includes(unit)) {
            displayOptionError(`${dataDescription} unit (${rawUnit})`, lineNumber)
            return []
        }
        return [measurement, unit]
    }

    /**
     * Handles processing the CSV data once the form has been submitted
     * 
     * @param e Event from form submission
     * @returns void
     */
    const processCSV = (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)

        if (!file) {
            setLoading(false)
            setOpenDropzoneDialog(true)
            dispatch(popAlert('error', t('error'), 'Please upload a CSV file'))
            return
        }
        const fileReader = new FileReader()
        fileReader.onloadend = (e: ProgressEvent<FileReader>) => {
            const fileData = e.target?.result?.toString()
            if (!fileData) {
                dispatch(popAlert('error', t('error'), `Unable to read file`))
                return;
            }

            // Treat \r as \n, split the data into rows, and remove empty rows
            const rows = fileData.replace(/\r/g, "\n").split('\n').filter(Boolean);

            const finalVehicles = []

            for (let i = 1; i < rows.length; i++) {
                const row = rows[i];
                const data = row.split(",").map((cell: string) => cell.trim())
                const lineNumber = i + 1

                // Validate that year, fuel usage, and number of axles are valid numbers

                const year = Number.parseInt(data[Column.YEAR])
                // Validate year only if its given
                if (data[Column.YEAR] && !year) {
                    displayMeasurementError(`vehicle year (${data[Column.YEAR]})`, lineNumber)
                    return
                }

                const fuelUsage = Number.parseInt(data[Column.FUEL_USAGE])
                if (!fuelUsage) {
                    displayMeasurementError(`fuel usage (${data[Column.FUEL_USAGE]})`, lineNumber)
                    return
                }

                const numAxles = Number.parseInt(data[Column.NUM_AXLES])
                if (!numAxles) {
                    displayMeasurementError(`number of axles (${data[Column.NUM_AXLES]})`, lineNumber)
                    return
                }

                // Equipment type and license country data are given as strings, but the API expects their ids
                // We need to validate that the given data is a valid option and get the relevant id

                const equipmentId = getRelevantEquipmentTypeId(data[Column.EQUIPMENT_TYPE])
                if (!equipmentId) {
                    displayOptionError(`equipment type (${data[Column.EQUIPMENT_TYPE]})`, lineNumber)
                    return
                }
                
                const mainCountryId = getRelevantCountryId(data[Column.LICENSE_PLATE_COUNTRY])
                if (!mainCountryId) {
                    displayOptionError(`license country code (${data[Column.LICENSE_PLATE_COUNTRY]})`, lineNumber)
                    return
                }

                const foreignCountryId = getRelevantCountryId(data[Column.FOREIGN_LICENSE_PLATE_COUNTRY])
                // Only validate the foreign country info if it's given
                if (data[Column.FOREIGN_LICENSE_PLATE_COUNTRY] && !foreignCountryId) {
                    displayOptionError(`foreign license country code (${data[Column.FOREIGN_LICENSE_PLATE_COUNTRY]})`, lineNumber)
                    return
                }

                // Tank size, tare weight, weight capacity, and volume capacity are given as "<measurement> <unit>"" in a single cell
                // We need to validate that the format is followed, the measurement is a number, and the unit is a valid option

                // Validate tank size data
                const tankSizeData = validateMeasurement("tank size", data[Column.TANK_SIZE], volumeUnits, lineNumber)
                if (!tankSizeData.length) {
                    // validateMeasurement handles displaying errors so we don't need to do that here
                    return;
                }

                // Validate tare weight format
                const tareWeightData = validateMeasurement("tare weight", data[Column.TARE_WEIGHT], weightUnits, lineNumber)
                if (!tareWeightData.length) {
                    // validateMeasurement handles displaying errors so we don't need to do that here
                    return;
                }

                // Validate weight capacity format
                const weightCapacityData = validateMeasurement("weight capacity", data[Column.WEIGHT_CAP], weightUnits, lineNumber)
                if (!weightCapacityData.length) {
                    // validateMeasurement handles displaying errors so we don't need to do that here
                    return;
                }

                // Validate volume capacity format
                const volumeCapacityData = validateMeasurement("volume capacity", data[Column.VOLUME_CAP], volumeUnits, lineNumber)
                if (!volumeCapacityData.length) {
                    // validateMeasurement handles displaying errors so we don't need to do that here
                    return;
                }

                const vehicle: CreateVehicleData = {
                    name: data[Column.NAME],
                    type_id: equipmentId,
                    license_plate_country_id: mainCountryId,
                    license_plate: data[Column.LICENSE_PLATE],
                    trailer_id_1: data[Column.TRAILER_ID],
                    trailer_id_2: data[Column.SECONDARY_TRAILER_ID],
                    fuel_usage: fuelUsage,
                    tank_size: tankSizeData[0],
                    tank_units: tankSizeData[1],
                    number_of_axles: numAxles,
                    truck_tare_weight: tareWeightData[0],
                    truck_tare_weight_units: tareWeightData[1],
                    weight_capacity: weightCapacityData[0],
                    weight_capacity_units: weightCapacityData[1],
                    volume_capacity: volumeCapacityData[0],
                    volume_capacity_units: volumeCapacityData[1],
                }
                if (data[Column.VIN]) {
                    vehicle.vin = data[Column.VIN]
                } else {
                    vehicle.make = data[Column.MAKE]
                    vehicle.model = data[Column.MODEL]
                    vehicle.year = year
                }

                if (foreignCountryId) {
                    vehicle.license_plate_2_country_id = foreignCountryId
                    vehicle.license_plate_2 = data[Column.FOREIGN_LICENSE_PLATE]
                }

                finalVehicles.push(vehicle)
            }
            submitVehicles(finalVehicles)
        }
        fileReader.readAsBinaryString(file)
    }

    const downloadTemplate = () => {
        const file = new File([csvTemplate], "VehicleUploadTemplate.csv", { type: "text/csv" });
        const objectURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.download = "VehicleUploadTemplate.csv";
        link.href = objectURL;
        link.click();
    }

    /**
     * Sends data to the `Create Company Vehicle` API
     * 
     * @param vehicles 
     * @returns void
     */
    const submitVehicles = async (vehicles: CreateVehicleData[]) => {
        
        const response = await createVehicle({
            token, 
            companyId,
            data: vehicles
        })

        if (!response.success) {
            const errorMsg = parseError(response)
            setLoading(false)
            dispatch(popAlert('error', t('error'), errorMsg))
            return
        }
        setNewVehicleIds(response.data.new_vehicle_ids)
        dispatch(popAlert('success', t('success'), "Uploaded vehicles successfully"))
        dispatch(getCompanyVehicles(token, companyId))
        // setModalOpen(false)
        setJustCreatedVehicles(true)
    }

    if (justCreatedVehicles) {
        return <UploadMultipleVehicleLegalDocs 
            newIds={newVehicleIds}
            setIsLoading={setLoading}
            setModalOpen={setModalOpen}
        />
    }

    return (<>
        <Grid container spacing={10}>
            <Grid item xs={12}>
                <Box style={{ backgroundColor: "#d9edf7", borderRadius: '8px' }} p={5} mb={1}>
                    <Typography style={{ fontWeight: 'bold', color: "#31708f" }}>
                        How to upload a new list of vehicles from a CSV file?
                    </Typography>
                    <Typography style={{ color: "#31708f" }}>
                        It's simple! Just download the CSV template and add each truck's information. When you're ready, come back here to upload the CSV and we'll do the rest of the work.
                    </Typography>
                </Box>
                <Box style={{ backgroundColor: "#FF7276", borderRadius: '8px' }} p={5} mb={1}>
                    <Typography>
                        Note: When specifying the type of vehicle or the country for a license plate, use the data from the lists below. They are case insensitive, but must be spelled as is.
                    </Typography>
                </Box>
                <Box style={{ backgroundColor: "#FF7276", borderRadius: '8px' }} p={5} mb={1}>
                    <Typography>
                        Note: When specifying the weight capacity, volume capacity, tare weight, or fuel tank size, format the data as: "&lt;size&gt; &lt;unit&gt;" (e.g., 20000 lb or 150 gal). You can get the relevant 
                        unit from one of the lists below. The units are also case insensitive.
                    </Typography>
                </Box>
            </Grid>
        </Grid>
        <SpacedGrid container spacing={5} my={3}>
            <Grid item xs={12} md={6}>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={downloadTemplate}
                    fullWidth
                >
                    Download Template
                </Button>
            </Grid>
            <Grid item xs={12} md={6}>
                <Button color="primary" variant={Boolean(file) ? "outlined" : "contained"} fullWidth onClick={() => setOpenDropzoneDialog(true)}>
                    {Boolean(file) ? "Change File" : "Upload"}
                </Button>
                <DropzoneDialog
                    open={openDropzoneDialog}
                    onSave={(files: File[]) => {
                        setFile(files[0])
                        setOpenDropzoneDialog(false)
                    }}
                    showPreviews={true}
                    maxFileSize={2000000}
                    onClose={() => setOpenDropzoneDialog(false)}
                    filesLimit={1}
                    acceptedFiles={['text/csv']}
                    dropzoneText={"Upload CSV"}
                />
            </Grid>
        </SpacedGrid>
        <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
                <Card variant="outlined" raised>
                    <CardHeader
                        title={"Weight Units"}
                    />
                    <CardContent>
                        <List dense>
                            {weightUnits.map(unit => (
                                <ListItem key={unit}>
                                    <Typography>• {unit}</Typography>
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card variant="outlined" raised>
                    <CardHeader
                        title={"Volume Units"}
                    />
                    <CardContent>
                        <List dense>
                            {volumeUnits.map(unit => (
                                <ListItem key={unit}>
                                    <Typography>• {unit}</Typography>
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card variant="outlined" raised>
                    <CardHeader
                        title={"Countries"}
                    />
                    <CardContent>
                        <List dense>
                            {countries.map(country => (
                                <ListItem key={country.id}>
                                    <Typography>• {country.code}</Typography>
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card variant="outlined" raised>
                    <CardHeader
                        title={"Equipment Types"}
                    />
                    <CardContent>
                        <List dense>
                            {chunkedEquipment[page - 1]?.map(eq => (
                                <ListItem key={eq.id}>
                                    <Typography>• {eq.name}</Typography>
                                </ListItem>
                            ))}
                            <Pagination
                                page={page}
                                count={chunkedEquipment.length}
                                onChange={(_, newPage) => setPage(newPage)}
                            />
                        </List>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <Grid container spacing={10}>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={processCSV} fullWidth>
                    {t('actions.save')}
                </Button>
            </Grid>
        </Grid>
    </>)
}

export default CreateVehicleUpload