import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    Tab,
    Tabs,
    TabContent,
    TabScrollButton,
    TabList,
    Paper as MuiPaper,
    AppBar,
    Toolbar,
    Typography as MuiTypography,
    Grid as MuiGrid,
    FormGroup,
    FormControl as MuiFormControl,
    FormControlLabel as MuiFormControlLabel,
    FormLabel,
    FormHelperText,
    Input,
    InputLabel,
    InputAdornment,
    Select,
    TextField,
    MenuItem,
    Button,
    Switch,
    Radio,
    RadioGroup,
    Divider,
    Box,
    Slider,
    Checkbox,
    Avatar,
    Dialog, 
    DialogTitle, 
    DialogContent,
    DialogActions,
    IconButton,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TableCell,
} from '@material-ui/core';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext'
import { connect } from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import { TPLoader } from '../../../components/Progress';
import { popAlert } from '../../../../redux/actions/sessionActions';
import { addCompaniesToJobWhitelist, removeCompanyFromJobWhitelist } from '../../../../redux/util/jobAPI';
import { getCurrencies } from '../../../../redux/actions/adminActions';
import { getCompanyDrivers } from '../../../../redux/util/companyAPI';
import { getAvailableVehicles, getCompanyVehicles } from '../../../../redux/util/vehicleAPI';
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { debounce } from 'lodash';
import NotificationSnackbar from '../../Util/Snackbar';

const Paper = styled(MuiPaper)(spacing);
const Typography = styled(MuiTypography)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const FormControlLabel = styled(MuiFormControlLabel)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Bold = styled.span`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: black;
`;

const GoogleMapReactWrapper = styled.div`
  height: 300px;
  width: 100%;
`;

function WhitelistGridComponent({ chunkedPreferredDrivers, isChecked, handleCheckChange, maxHeight, selectedCompanyIDs }) {
    return (
        <Grid container spacing={10} style={{ maxHeight: maxHeight || null, overflowY: 'scroll' }}>
            <Grid item xs={12}>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <TableBody>
                            {chunkedPreferredDrivers.map((chunk, index) => (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                >
                                    {chunk.map(({ identifier, driver_company, driver_company_id }) => (
                                        <React.Fragment key={driver_company_id}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isChecked(driver_company_id)}
                                                    onChange={handleCheckChange(driver_company_id)}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                {identifier && <Typography color="textSecondary">{identifier}</Typography>}
                                                <Typography>{driver_company.name}</Typography>
                                            </TableCell>
                                        </React.Fragment>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

function UpdateWhitelistModal({ token, helpers, company, preferredDrivers, whitelistedCompanyIDs = [], setModalOpen, jobID, refreshWhitelist, popAlert }) {
    const { t } = useTranslation()
    const [selectedCompanyIDs, setSelectedCompanyIDs] = useState(whitelistedCompanyIDs)
    const [companyFilter, setCompanyFilter] = useState('')
    const [filteredPreferredDrivers, setFilteredPreferredDrivers] = useState(helpers.chunk(preferredDrivers, 2))
    const [isLoading, setIsLoading] = useState(false)

    const isChecked = (companyID) => {
        return Boolean(selectedCompanyIDs.find(id => id == companyID))
    }

    const handleCheckChange = (companyID) => {
        return (event) => {
            if (!isChecked(companyID) && event.target.checked) {
                setSelectedCompanyIDs([...selectedCompanyIDs, companyID])
            } else {
                setSelectedCompanyIDs(selectedCompanyIDs.filter(id => id != companyID))
            }
        }
    }

    // Removes all companies that were originally in the whitelist but have been unchecked
    const removeCompanies = () => {
        return new Promise(async (res, rej) => {
            for (let i = 0; i < whitelistedCompanyIDs.length; i++) {
                const whitelistedCompanyID = whitelistedCompanyIDs[i];
                if (!selectedCompanyIDs.includes(whitelistedCompanyID)) {
                    await removeCompanyFromJobWhitelist(token, company.id, jobID, whitelistedCompanyID).then(res => {
                        console.log('remove from whitelist res', res)
                        if (res.success == false) {
                            const erroredPreferred = preferredDrivers.find(preferred => whitelistedCompanyID == preferred.driver_company_id)
                            console.log('errored preferred', erroredPreferred)
                            if (erroredPreferred) {
                                popAlert('error', t('error'), `${erroredPreferred.driver_company.name} - ${helpers.parseError(res.error)}`)
                            }
                        }
                    })
                }
            }
            res()
        })
    }

    // Removed unchecked companies then adds new companies
    const handleSubmit = async event => {
        setIsLoading(true)
        event.preventDefault()
        if (selectedCompanyIDs.length) {
            removeCompanies().then(() => {
                addCompaniesToJobWhitelist(token, company.id, jobID, selectedCompanyIDs).then(res => {
                    console.log("add companies to whitelist res", res)
                    if (res.success) {
                        popAlert('success', t('success'), t('jobTranslations.success.updateWhitelist'))
                        refreshWhitelist()
                        setModalOpen(false)
                    } else {
                        popAlert('error', t('error'), helpers.parseError(res))
                        setIsLoading(false)
                    }
                })
            })
        } else {
            removeCompanies().then(() => {
                refreshWhitelist()
                setModalOpen(false)
            })
        }
    }

    // Handles changes to the company filter
    useEffect(() => {
        if (companyFilter.length) {

            // Applies the filter to either the company name or the preference identifier
            const newFilteredDrivers = preferredDrivers.filter(preference => {
                return (
                    preference.driver_company.name.toLowerCase().includes(companyFilter.toLowerCase())
                    ||
                    preference.identifier.toLowerCase().includes(companyFilter.toLowerCase())
                )
            })

            setFilteredPreferredDrivers(helpers.chunk(newFilteredDrivers, 2))
        } else {
            setFilteredPreferredDrivers(helpers.chunk(preferredDrivers, 2))
        }
    }, [companyFilter])

    return (
        <Dialog
                open={true}
                aria-labelledby="form-dialog-title"
                fullWidth
                onBackdropClick={() => setModalOpen(false)}
                onEscapeKeyDown={() => setModalOpen(false)}
            >
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <DialogTitle disableTypography id="form-dialog-title">
                        <Box flexDirection="row" justifyContent="space-between" alignItems="center" >
                            <Typography variant="h6" display="inline">{t('jobTranslations.updateJobWhitelist')}</Typography>
                            <IconButton aria-label="close"
                                onClick={() => setModalOpen(false)}
                                style={{
                                    position: 'absolute',
                                    top: '0px',
                                    right: '0px'
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        {/* Reschedule */}
                    </DialogTitle>
                    <DialogContent>
                        {isLoading ? <TPLoader /> : <>
                            <Grid container spacing={10}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="companyFilter"
                                            label={t('jobTranslations.filterCompanies')}
                                            value={companyFilter}
                                            onChange={event => setCompanyFilter(event.target.value)}
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            
                            <WhitelistGrid
                                chunkedPreferredDrivers={filteredPreferredDrivers}
                                isChecked={isChecked}
                                handleCheckChange={handleCheckChange}
                            />

                        </>}
                    </DialogContent>
                    {!isLoading && <DialogActions>
                        <Button color="primary" type="button" onClick={() => setModalOpen(false)}>
                            {t('actions.cancel')}
                        </Button>
                        <Button color="primary" type="submit" variant="contained">
                            {t('actions.submit')}
                        </Button>
                    </DialogActions>}
                </form>
                <NotificationSnackbar />
            </Dialog>

    )
}
const msp = state => ({
    helpers: state.helpers,
    token: state.session.user.token,
    company: state.session.company,
})
const mdp = dispatch => ({
    popAlert: (success, title, message) => dispatch(popAlert(success, title, message)),
})
export const WhitelistGrid = connect(msp, mdp)(WhitelistGridComponent)
export default connect(msp, mdp)(UpdateWhitelistModal)