import { TPAPIBase } from '../../env';
import i18n from '../../i18n';
import { Paginate, PaginatedAPIOptions, TPResponse } from './util';
import { 
    CustomMaterial, CustomMaterialMap, CustomMaterialTag, MaterialCategorization, MaterialCategory, MaterialCategorizationValue, MaterialCategoryDataTypes, JobQuery, DistributiveOmit,
} from '../reducers/entities'
import { createDateFromUnix, formatParams, getDateTimeFromUnix, parseError } from '../reducers/helpersReducerr';

export const getAvailableTagsForCustomMaterials = (token: string): Promise<TPResponse<string[]>> => (
    fetch(`${TPAPIBase}/custom-materials/tags`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getTagsForCustomMaterial = (token: string, companyID: number, customMaterialID: number): Promise<TPResponse<CustomMaterialTag[]>> => (
    fetch(`${TPAPIBase}/company/${companyID}/custom-materials/${customMaterialID}/tags`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)

export const addTagToCustomMaterial = (token: string, companyID: number, customMaterialID: number, tag: string): Promise<TPResponse<CustomMaterialTag>> => (
    fetch(`${TPAPIBase}/company/${companyID}/custom-materials/${customMaterialID}/tags/${tag}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)

export const removeTagFromCustomMaterial = (token: string, companyID: number, customMaterialID: number, tag: string): Promise<TPResponse<CustomMaterialTag>> => (
    fetch(`${TPAPIBase}/company/${companyID}/custom-materials/${customMaterialID}/tags/${tag}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
interface GetCustomMaterialsOptions {
    listNames?: string[];
    customMaterialIDs?: number[];
    page?: number;
}
export const getCustomMaterials = (token: string, companyID: number, options: GetCustomMaterialsOptions = { listNames: [], customMaterialIDs: [], page: 1 }): Promise<TPResponse<Paginate<CustomMaterial[]>>> => {
    const formattedParams = [];
    if (options.listNames) options.listNames.forEach(listName => formattedParams.push(`list_names[]=${listName}`));
    if (options.customMaterialIDs) options.customMaterialIDs.forEach(materialID => formattedParams.push(`custom_material_ids[]=${materialID}`));
    if (options.page) formattedParams.push(`page=${options.page}`);
    
    return fetch(`${TPAPIBase}/company/${companyID}/custom-materials/direct?${formattedParams.join("&")}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}

export const getAllCustomMaterials = async (token: string, companyID: number, options: GetCustomMaterialsOptions = { listNames: [], customMaterialIDs: [] }): Promise<TPResponse<CustomMaterialMap>> => {
    const customMaterials: CustomMaterialMap = {};
    let page = 1;
    let fetchedAll = false;
    while (!fetchedAll) {
        const response = await getCustomMaterials(token, companyID, { ...options, page })
        if (response.success) {
            response.data.data.forEach(material => {
                if (customMaterials[material.list_name]) {
                    customMaterials[material.list_name].push(material)
                } else {
                    customMaterials[material.list_name] = [material]
                }
            })
        } else {
            fetchedAll = true;
        }
        if (response.data.next_page_url) {
            page += 1
        } else {
            fetchedAll = true;
        }
    }
    return {
        success: true,
        data: customMaterials,
        error: null
    }
}
export interface AddCustomMaterialPayload {
    list_name: string;
    material_number: string;
    name: string;
    description: string;
    is_inspectable: boolean;
    is_hazardous: boolean;
    handling_and_additional_info: string | null;
}
export const addCustomMaterial = (token: string, companyID: number, materialArray: AddCustomMaterialPayload[]): Promise<TPResponse<CustomMaterial[]>> => (
    fetch(`${TPAPIBase}/company/${companyID}/custom-materials`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: materialArray
        })
    }).then(response => response.json())
)


/**
 * Wrapper for `Update Custom Material API`
 */
export const updateCustomMaterial = async ({
    token, 
    companyId, 
    customMaterialId,
    payload,
}: {
    token: string,
    companyId: number,
    customMaterialId: number,
    payload: Partial<AddCustomMaterialPayload>
}): Promise<CustomMaterial> => {
    const response: TPResponse<CustomMaterial> = await fetch(`${TPAPIBase}/company/${companyId}/custom-materials/${customMaterialId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: payload
        })
    }).then(response => response.json())

    if (!response.success) throw new Error(parseError(response))

    return response.data
}

const getCategorizationMetadata = (categorization: MaterialCategorization) => {
    switch (categorization.category.data_type) {
        case MaterialCategoryDataTypes.DATE: 
        case MaterialCategoryDataTypes.DATETIME:
            categorization.dateValue = getDateTimeFromUnix(categorization.value);
            break;

        default: break;
    }
    return categorization
}
export const getAllAvailableCustomMaterialCategories = (token: string): Promise<TPResponse<MaterialCategory[]>> => (
    fetch(`${TPAPIBase}/custom-materials/categories`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getCategorizationsForCustomMaterial = (token: string, companyID: number, customMaterialID: number, options: PaginatedAPIOptions): Promise<TPResponse<Paginate<MaterialCategorization[]>>> => {
    const formattedParams = formatParams(options)
    return fetch(`${TPAPIBase}/company/${companyID}/custom-materials/${customMaterialID}/categorizations?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then((res: TPResponse<Paginate<MaterialCategorization[]>>) => {
        if (res.data.data) {
            res.data.data = res.data.data.map(getCategorizationMetadata)
        }
        return res
    })
}
export const getAllCategorizationsForCustomMaterial = async (token: string, companyID: number, customMaterialID: number): Promise<TPResponse<MaterialCategorization[]>> => {
    const categorizations: MaterialCategorization[] = [];
    let page = 1;
    let fetchedAll = false;
    while (!fetchedAll) {
        const response = await getCategorizationsForCustomMaterial(token, companyID, customMaterialID, { page })
        if (!response.success) {
            fetchedAll = true;
        }
        categorizations.push(...response.data.data)
        if (!response.data.next_page_url) {
            fetchedAll = true;
        }
        page += 1
    }
    return {
        success: true,
        data: categorizations,
        error: null
    }
}

export const addCategorizationsToCustomMaterial = (token: string, companyID: number, customMaterialID: number, data: MaterialCategorizationValue[]): Promise<TPResponse<CustomMaterial[]>> => (
    fetch(`${TPAPIBase}/company/${companyID}/custom-materials/${customMaterialID}/categorizations`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data
        })
    }).then(response => response.json())
)
export const removeCategorizationFromCustomMaterial = (token: string, companyID: number, customMaterialID: number, categorizationID: number): Promise<TPResponse<CustomMaterial[]>> => (
    fetch(`${TPAPIBase}/company/${companyID}/custom-materials/${customMaterialID}/categorizations/${categorizationID}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
    }).then(response => response.json())
)

export const getCustomMaterialsByQuery = (token: string, companyID: number, query: DistributiveOmit<JobQuery, "type">, options: PaginatedAPIOptions = {}): Promise<TPResponse<Paginate<CustomMaterial[]>>> => {
    const formattedParams = formatParams(options)
    return fetch(`${TPAPIBase}/company/${companyID}/materials/query?${formattedParams}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: {
                query
            }
        })
    }).then(response => response.json())
}