import { TPAPIBase } from '../../env'
import i18n from '../../i18n';
import { DeliveryApi, InspectionItem, InspectorType, JobInspectionItem, JobInspectorAssignment } from '../reducers/entities';
import { JobDoc } from '../reducers/entities/JobDocs';
import { formatParams, functions } from '../reducers/helpersReducerr';
import { BooleanParam, ConfirmationResponse, Paginate, PaginatedAPIOptions, TPResponse } from './util';

export interface CreateJobInspectorPayload {
    inspector_first_name: string;
    inspector_last_name: string;
    inspector_email: string;
    inspector_phone: string;
    inspector_phone_country_code: string;
    inspector_external_id: string;
    inspector_company_name: string;
    inspector_type: InspectorType;
    is_pickup_inspector: boolean;
    is_dropoff_inspector: boolean;
    notify_by_email: boolean;
}
export const createJobInspector = (token: string, companyID: number, jobID: number, data: CreateJobInspectorPayload): Promise<TPResponse<JobInspectorAssignment>> => (
    fetch(`${TPAPIBase}/company/${companyID}/jobs/${jobID}/inspectors`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const getJobInspectors = (token: string, companyID: number, jobID: number, page = 1): Promise<TPResponse<Paginate<JobInspectorAssignment[]>>> => (
    fetch(`${TPAPIBase}/company/${companyID}/jobs/${jobID}/inspectors?page=${page}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getAllJobInspectors = (token: string, companyID: number, jobID: number): Promise<TPResponse<JobInspectorAssignment[]>> => {
    return new Promise(async (res, rej) => {
        const items: JobInspectorAssignment[] = []
        let page = 1;
        let fetchedAll = false;
        while (!fetchedAll) {
            await getJobInspectors(token, companyID, jobID, page).then((res) => {
                if (res?.success && res.data.data) {
                    items.push(...res.data.data)
                    if (res.data.next_page_url) {
                        page += 1;
                    } else {
                        fetchedAll = true
                    }
                } else {
                    fetchedAll = true
                }
            }).catch(err => fetchedAll = true)
        }
        res({ success: true, data: items, error: null })
    })
}
export const updateJobInspector = (token: string, companyID: number, jobID: number, inspectorID: string, data: any) => (
    fetch(`${TPAPIBase}/company/${companyID}/jobs/${jobID}/inspectors/${inspectorID}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const removeJobInspector = (token: string, companyID: number, jobID: number, inspectorID: string): Promise<ConfirmationResponse> => (
    fetch(`${TPAPIBase}/company/${companyID}/jobs/${jobID}/inspectors/${inspectorID}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getInspectionItems = (token: string, page = 1): Promise<TPResponse<Paginate<InspectionItem[]>>> => (
    fetch(`${TPAPIBase}/inspection_items?page=${page}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getAllInspectionItems = (token: string): Promise<TPResponse<InspectionItem[]>> => {
    return new Promise(async (res, rej) => {
        const items: InspectionItem[] = []
        let page = 1;
        let fetchedAll = false;
        while (!fetchedAll) {
            await getInspectionItems(token, page).then((res) => {
                if (res.success && res.data.data) {
                    items.push(...res.data.data)
                    if (res.data.next_page_url) {
                        page += 1;
                    } else {
                        fetchedAll = true
                    }
                } else {
                    fetchedAll = true
                }
            }).catch(err => fetchedAll = true)
        }
        res({ success: true, data: items, error: null })
    })
}

export const getJobInspectionItems = (token: string, companyID: number, jobID: number, page = 1): Promise<TPResponse<Paginate<JobInspectionItem[]>>> => (
    fetch(`${TPAPIBase}/company/${companyID}/jobs/${jobID}/inspection_items?page=${page}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getAllJobInspectionItems = (token: string, companyID: number, jobID: number): Promise<TPResponse<JobInspectionItem[]>> => {
    return new Promise(async (res, rej) => {
        const items: any = []
        let page = 1;
        let fetchedAll = false;
        while (!fetchedAll) {
            await getJobInspectionItems(token, companyID, jobID, page).then((res) => {
                if (res.success && res.data.data) {
                    items.push(...res.data.data)
                    if (res.data.next_page_url) {
                        page += 1;
                    } else {
                        fetchedAll = true
                    }
                } else {
                    fetchedAll = true
                }
            }).catch(err => fetchedAll = true)
        }
        res({ success: true, data: items, error: null })
    })
}
export interface AddJobInspectionItemsPayload {
    job_inspection_items: {
        inspection_item_id: number;
        units?: string;
        notes: string;
        is_pickup_inspection: boolean;
        is_dropoff_inspection: boolean;
    }
}
export const addJobInspectionItems = (token: string, companyID: number, jobID: number, data: AddJobInspectionItemsPayload): Promise<ConfirmationResponse> => (
    fetch(`${TPAPIBase}/company/${companyID}/jobs/${jobID}/inspection_items`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const updateJobInspectionNotes = (token: string, companyID: number, jobID: number, itemID: number, data: any) => (
    fetch(`${TPAPIBase}/company/${companyID}/jobs/${jobID}/inspection_items/${itemID}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: data
        })
    }).then(response => response.json())
)
export const removeJobInspectionItem = (token: string, companyID: number, jobID: number, itemID: number) => (
    fetch(`${TPAPIBase}/company/${companyID}/jobs/${jobID}/inspection_items/${itemID}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)

export const findJobInspectorUserAndCompanyByEmail = (token: string, companyID: number, email: string) => (
    fetch(`${TPAPIBase}/company/${companyID}/inspectors/search?email=${encodeURIComponent(email)}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const getMyInspections = (inspectorToken: string, jobID: number, page = 1) => (
    fetch(`${TPAPIBase}/inspector/${inspectorToken}/job/${jobID}/inspections?page=${page}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
        }
    }).then(response => response.json())
)
export const getMyInspectionsWithValues = (inspectorToken: string, jobID: number, inspectionID: number, page = 1) => (
    fetch(`${TPAPIBase}/inspector/${inspectorToken}/job/${jobID}/inspections/${inspectionID}?page=${page}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
        }
    }).then(response => response.json())
)
export const getJobInspectionItemsAsInspector = (inspectorToken: string, jobID: number, page = 1): Promise<TPResponse<Paginate<JobInspectionItem[]>>> => (
    fetch(`${TPAPIBase}/inspector/${inspectorToken}/job/${jobID}/inspection_items?page=${page}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
        }
    }).then(response => response.json())
)
export const getAllJobInspectionItemsAsInspector = (inspectorToken: string, jobID: number): Promise<TPResponse<JobInspectionItem[]>> => {
    return new Promise(async (res, rej) => {
        const items: any = []
        let page = 1;
        let fetchedAll = false;
        while (!fetchedAll) {
            await getJobInspectionItemsAsInspector(inspectorToken, jobID, page).then((res) => {
                if (res.success && res.data.data) {
                    items.push(...res.data.data)
                    if (res.data.next_page_url) {
                        page += 1;
                    } else {
                        fetchedAll = true
                    }
                } else {
                    fetchedAll = true
                }
            }).catch(err => fetchedAll = true)
        }
        res({ success: true, data: items, error: null })
    })
}
export interface GetDeliveryFeedOptions {
    [key: string]: string | number | undefined;
    page?: number;
    _requires_company_pickup_inspection?: number;
    _requires_company_dropoff_inspection?: number;
    _requires_third_party_pickup_inspection?: number;
    _requires_third_party_dropoff_inspection?: number;
}
export const getDeliveryFeed = (inspectorToken: string, jobID: number, options: GetDeliveryFeedOptions): Promise<TPResponse<Paginate<DeliveryApi[]>>> => {
    const formattedParams = Object.keys(options).map((key: string) => `${key}=${options[key]}`).join("&")
    return fetch(`${TPAPIBase}/inspector/${inspectorToken}/job/${jobID}/deliveries?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
        },
    }).then(response => response.json()).then((res: TPResponse<Paginate<DeliveryApi[]>>) => {
        if (res.data?.data) {
            res.data.data = res.data.data.map(functions.getDeliveryMetadata)
        }

        return res
    })
}
export const createInspection = (inspectorToken: string, jobID: number, deliveryID: string, formData: FormData) => (
    fetch(`${TPAPIBase}/inspector/${inspectorToken}/job/${jobID}/deliveries/${deliveryID}/inspections`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
        },
        body: formData
    }).then(response => response.json())
)
export interface GetUserInspectorsOptions extends PaginatedAPIOptions {
    job_ids?: number[]
}
export const getUserInspectors = (token: string, companyID: number, options: GetUserInspectorsOptions = {}) => {
    const formattedParams = formatParams(options)
    console.log("GET USER INSPECTORS", formattedParams)
    return fetch(`${TPAPIBase}/company/${companyID}/user/inspectors?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        }
    }).then(response => response.json())
}
export const getInspectorTokens = getUserInspectors
export const getDeliveryDetails = (inspectorToken: string, jobID: number, deliveryID: number): Promise<TPResponse<DeliveryApi>> => (
    fetch(`${TPAPIBase}/inspector/${inspectorToken}/job/${jobID}/deliveries/${deliveryID}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
        }
    }).then(response => response.json()).then((res: TPResponse<DeliveryApi>) => {
        if (res.data) {
            res.data = functions.getDeliveryMetadata(res.data)
        }
        return res
    })
)
export const inspectorGetAdditionalJobDocuments = (inspectorToken: string, jobID: number, options: PaginatedAPIOptions = {}): Promise<TPResponse<Paginate<JobDoc[]>>> => {
    const formattedParams = formatParams(options)
    return fetch(`${TPAPIBase}/inspector/${inspectorToken}/job/${jobID}/documents?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
        }
    }).then(response => response.json())
}
export const inspectorGetAdditionalDeliveryDocuments = (inspectorToken: string, jobID: number, deliveryID: number, options: PaginatedAPIOptions = {}): Promise<TPResponse<Paginate<JobDoc[]>>> => {
    const formattedParams = formatParams(options)
    return fetch(`${TPAPIBase}/inspector/${inspectorToken}/job/${jobID}/deliveries/${deliveryID}/documents?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
        }
    }).then(response => response.json())
}
