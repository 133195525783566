import React from 'react';
import { withTranslation } from 'react-i18next'
import {
    Modal,
    Tab,
    Tabs,
    TabContent,
    TabScrollButton,
    TabList,
    Paper as MuiPaper,
    AppBar,
    Toolbar,
    Typography as MuiTypography,
    Grid as MuiGrid,
    FormGroup,
    FormControl as MuiFormControl,
    FormControlLabel,
    FormLabel,
    FormHelperText,
    Input,
    InputLabel,
    Select,
    TextField,
    MenuItem,
    Button,
    Switch,
    Radio,
    RadioGroup,
    Divider,
    InputAdornment,
    Box as MuiBox,
} from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone'
import { debounce } from 'lodash';
import {
    KeyboardDatePicker
} from "@material-ui/pickers";
import { connect } from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import { TPLoader } from '../../../components/Progress';
import GoogleMapReact from 'google-map-react'
import { GMapAPIKey } from '../../../../env'
import { popAlert } from '../../../../redux/actions/sessionActions';
import {
    getSupportedCountries,
    getEquipment,
    getValidUnits
} from '../../../../redux/actions/adminActions';
import { getCompanyVehicles } from '../../../../redux/actions/vehicleActions';
import { 
    createVehicle, 
    editVehicle, 
    createLegalDoc,
    getMakes,
    getModels,
    getYears
} from '../../../../redux/util/vehicleAPI';
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import NotificationSnackbar from '../../Util/Snackbar';
import { Autocomplete, TabContext } from "@material-ui/lab";
import TPModal from '../../Util/TPModal';
import CreateVehicleManual from '../components/CreateVehicleManual';
import CreateVehicleUpload from '../components/CreateVehicleUpload';
import TabPanel from '../../Util/TabPanel';

const Paper = styled(MuiPaper)(spacing);
const Typography = styled(MuiTypography)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Box = styled(MuiBox)(spacing);
const Bold = styled.span`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: black;
`;

const GoogleMapReactWrapper = styled.div`
  height: 300px;
  width: 100%;
`;
function SectionHeader({ title }) {
    return (<>
        <Typography variant="h2" mb={4}><Bold>{title}</Bold></Typography>
        <Divider />
    </>
    )
}

/**
 * Handles creating/editing a single vehicle, or creating many vehicles by CSV upload
 * 
 * @prop {Vehicle} vehicle
 * @prop {string} type - 'create' | 'edit'
 * 
 */
class CreateEditVehicleModal extends React.Component {
    state = {
        // Used for tabs,
        currentTab: "manual", // "manual" | "upload",
        loading: true
    }
    componentDidMount = () => {
        this.mounted = true;
    }
    componentWillUnmount = () => {
        this.mounted = false;
    }
    setStateIfMounted = (state, callback) => {
        if (this.mounted) {
            this.setState(state, callback)
        }
    }
    render = () => {
        let headerText = ""
        if (this.props.type === 'create') {
            headerText = this.props.t('vehicleTranslations.actions.createVehicle')
        }
        if (this.props.type === 'edit') {
            headerText = this.props.t('vehicleTranslations.actions.editVehicle')
        }
        return <TPModal
            setModalOpen={this.props.setModalOpen}
            headerOptions={{
                headerText
            }}
            bodyOptions={{
                noPadding: true
            }}
            sm={10} 
            md={7} 
            lg={6}
        >
            <TabContext value={this.state.currentTab}>
                {this.props.type === 'create' && <AppBar color="default" position="static">
                    <Toolbar>
                        <Tabs
                            variant="scrollable"
                            indicatorColor="primary"
                            scrollButtons="auto"
                            value={this.state.currentTab}
                            onChange={(event, tabValue) => this.setStateIfMounted({ currentTab: tabValue })}
                        >
                            <Tab label="Manual" value={"manual"} />
                            <Tab label="Upload" value={"upload"} />
                        </Tabs>
                    </Toolbar>
                </AppBar>}
                <TabPanel value={this.state.currentTab} index={"manual"} boxProps={{ p: 3 }} keepIn>
                    <CreateVehicleManual 
                        vehicle={this.props.vehicle}
                        type={this.props.type}
                        setModalOpen={this.props.setModalOpen}
                    />
                </TabPanel>
                <TabPanel value={this.state.currentTab} index={"upload"} boxProps={{ p: 3 }} keepIn>
                    <CreateVehicleUpload
                        setModalOpen={this.props.setModalOpen}
                        setLoading={bool => this.setStateIfMounted({ loading: bool })}
                    />
                </TabPanel>
            </TabContext>
        </TPModal>
    }
}
const msp = state => ({
    helpers: state.helpers,
    token: state.session.user.token,
    userID: state.session.user.id,
    defaults: state.session.user.settings,
    company: state.session.company,
    supportedCountries: state.entities.supportedCountries,
    units: state.entities.units,
    equipmentTypes: state.entities.equipment,
})
const mdp = dispatch => ({
    getSupportedCountries: () => dispatch(getSupportedCountries()),
    getCompanyVehicles: (token, companyID) => dispatch(getCompanyVehicles(token, companyID)),
    getEquipment: (token) => dispatch(getEquipment(token)),
    getValidUnits: (token) => dispatch(getValidUnits(token)),
    popAlert: (success, title, message) => dispatch(popAlert(success, title, message)),
})
export default connect(msp, mdp)(withTranslation()(CreateEditVehicleModal))