import React from "react";
import { connect } from 'react-redux';
import styled, { withTheme } from "styled-components";
import { withTranslation } from 'react-i18next';
import GoogleMapReact from "google-map-react";
import { GMapAPIKey } from '../../../../env';
import {
    CardActionArea,
    CardActions as MuiCardActions,
    CardContent as MuiCardContent,
    CardHeader,
    Card as MuiCard,
    CardMedia as MuiCardMedia,
    Grid,
    Divider as MuiDivider,
    Typography as MuiTypography,
    Button as MuiButton,
    Link,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Tooltip as MuiTooltip,
    IconButton as MuiIconButton,
    Box as MuiBox,
    Avatar as MuiAvatar,
    Paper,
    Chip as MuiChip,
} from "@material-ui/core";
import TPTruckIcon from '../../Util/TPTruckIcon';
import { spacing } from "@material-ui/system";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getTracksForDelivery, getReturnTracksForDelivery } from '../../../../redux/util/deliveryAPI';

import SkeletonCards from '../../../components/SkeletonCards';

const styles = {
    darkCard: {
        backgroundColor: "#F0F0F0",
    },
    acceptButton: {
        color: '#ffffff !important',
        backgroundColor: '#27c24c',
        borderColor: '#27c24c'
    },
    declineButton: {
        color: '#ffffff !important',
        backgroundColor: '#f05050',
        borderColor: '#f05050'
    },
    green: {
        color: '#27c24c !important'
    },
    red: {
        color: '#f05050 !important',
    },
    gray: {
        color: '#616161 !important',
    },
    tpo: {
        color: '#ff6600 !important',
    },
}

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Box = styled(MuiBox)(spacing);
const Divider = styled(MuiDivider)(spacing);
const GoogleMapReactWrapper = styled.div`
  height: 400px;
  width: 100%;
`;

/*
    props: 
    delivery: Delivery

*/

class DeliveryMap extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            options: {
                center: {
                    lat: 40.75027,
                    lng: -73.8542755
                },
                zoom: 14
            },
            loaded: false,
            fetchedTracks: false
        }
        this.markers = {};
        this.polylines = {};
    }
    componentDidMount = () => {
        this.mounted = true;
    }
    componentWillUnmount = () => {
        this.mounted = false;
        clearInterval(this.interval)
    }
    setStateIfMounted = (state, callback) => {
        if (this.mounted) {
            this.setState(state, callback)
        }
    }
    getMainTracks = async () => {
        const tracks = []
        let missingTracks = true;
        let page = 1;
        while (missingTracks) {
            await getTracksForDelivery(this.props.token, this.props.delivery.id, page).then(res => {
                if (res.success && res.data && res.data.data && res.data.data.length) {
                    tracks.push(...res.data.data)
                }
                if (res.data?.next_page_url) {
                    page += 1;
                } else {
                    missingTracks = false;
                }
            })
        }
        if (tracks.length) {
            this.createPath(tracks, false);
            if (!this.interval && this.props.delivery.in_progress) {
                this.interval = setInterval(() => this.getMainTracks(), 10000)
            }
        }
    }
    getReturnTracks = async () => {
        const tracks = []
        let missingTracks = true;
        let page = 1;
        while (missingTracks) {
            await getReturnTracksForDelivery(this.props.token, this.props.delivery.id, page).then(res => {
                if (res.success && res.data && res.data.data && res.data.data.length) {
                    tracks.push(...res.data.data)
                }
                if (res.data.next_page_url) {
                    page += 1;
                } else {
                    missingTracks = false;
                }
            })
        }
        if (tracks.length) {
            this.createPath(tracks, true);
        }
    }
    createPath = (tracks, forReturn) => {
        // convert all tracks to LatLng instances
        const deliveryCoords = [];
        tracks.forEach(track => {
            deliveryCoords.push(new this.maps.LatLng(track.latitude, track.longitude))
        })

        // create the google polyline
        const strokeColor = (forReturn ? 'tomato' : 'limegreen')
        const polylineKey = (forReturn ? 'return' : 'main')
        this.polylines[polylineKey] = new this.maps.Polyline({
            path: deliveryCoords,
            geodesic: true,
            strokeColor,
            strokeOpacity: 1.0,
            strokeWeight: 4
        })
        this.polylines[polylineKey].setMap(this.map)

        // add markers
        if (!forReturn) {
            //add start marker
            this.markers.start = new this.maps.Marker({
                map: this.map, 
                position: deliveryCoords[0],
                icon: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
            })
            
            //add end marker
            const lastPosition = deliveryCoords[deliveryCoords.length - 2];
            const newPosition = deliveryCoords[deliveryCoords.length - 1];

            if (!this.props.delivery.in_progress) {
                this.markers.end = new this.maps.Marker({
                    map: this.map,
                    position: newPosition,
                    icon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
                })
                this.getReturnTracks();
            // } else if (!this.hasReturnTracks) {
            //     this.updateTruckIcon(lastPosition, newPosition)
            // }
            } else {
                this.updateTruckIcon(lastPosition, newPosition)
            }
        } else {
            //add return marker
            const lastPosition = deliveryCoords[deliveryCoords.length - 2];
            const newPosition = deliveryCoords[deliveryCoords.length - 1];
            if (!this.props.delivery.in_progress) {
                this.markers.return = new this.maps.Marker({
                    map: this.map,
                    position: newPosition,
                    icon: 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png'
                })
            } else {
                this.updateTruckIcon(lastPosition, newPosition)
            }

        }
        this.adjustMapCenter();

    }
    updateTruckIcon = (lastPosition, newPosition) => {
        return
        if (this.markers.truck) {
            const heading = this.maps.geometry.spherical.computeHeading(lastPosition, newPosition)
            this.markers.truck.setIcon(this.icon.setRotation({ deg: heading }).getUrl());
            this.markers.truck.setPosition(newPosition)
        } else {
            // this.hasReturnTracks = true;
            this.icon = new TPTruckIcon({ is_returning: false }).setRotation({ deg: 0 });
            this.markers.truck = new this.maps.Marker({
                position: newPosition,
                map: this.map,
                anchor: new this.maps.Point(26, 26),
                icon: this.icon.getUrl()
            });
            this.map.setCenter(newPosition)
        }
    }
    getMapOptions = maps => {
        return {
            fullscreenControl: true,
            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.ROADMAP,
            scaleControl: true,
            scrollwheel: false,
            streetViewControl: true
        };
    };
    adjustMapCenter = () => {
        const bounds = new this.maps.LatLngBounds();
        Object.values(this.markers).forEach(marker => {
            bounds.extend(marker.position)
        })
        this.map.fitBounds(bounds);
        this.setStateIfMounted({ fetchedTracks: true })
    }
    drawMainRoute = (map, maps) => {
        const DirectionsService = new maps.DirectionsService();
        const DirectionsRenderer = new maps.DirectionsRenderer();
        DirectionsRenderer.setMap(map);
        DirectionsRenderer.setOptions({ suppressMarkers: true });

        const request = {
            origin: new maps.LatLng(this.props.job.routes[0].address_from.latitude, this.props.job.routes[0].address_from.longitude),
            destination: new maps.LatLng(this.props.job.routes[0].address_to.latitude, this.props.job.routes[0].address_to.longitude),
            travelMode: maps.TravelMode["DRIVING"]
        };
        DirectionsService.route(request, (response, status) => {
            if (status == maps.DirectionsStatus.OK) {
                DirectionsRenderer.setDirections(response);
                // //lets push the driving directions to our job object :)
                // $scope.job.directions = response;
                // $scope.job.direction_steps = response.routes[0].legs[0].steps;
                // $scope.job.direction_eta = response.routes[0].legs[0].duration;
                // $scope.job.direction_distance = response.routes[0].legs[0].distance;
            }
        });
        //Add Start of Route Marker
        this.markers.push(new maps.Marker({
            map: map,
            position: new maps.LatLng(this.props.job.routes[0].address_from.latitude, this.props.job.routes[0].address_from.longitude),
            icon: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
        }));
        //Add End of Route Marker
        this.markers.push(new maps.Marker({
            map: map,
            position: new maps.LatLng(this.props.job.routes[0].address_to.latitude, this.props.job.routes[0].address_to.longitude),
            icon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
        }));
        this.adjustMapCenter(map, maps)
    }

    handleApiLoaded = (map, maps) => {
        this.map = map;
        this.maps = maps;
        // this.drawMainRoute(map, maps)
        this.getMainTracks();
        // this.setStateIfMounted({ loaded: true })
    }

    render = () => {
        if (!this.props.expanded && !this.state.fetchedTracks) return <SkeletonCards.SkeletonMapCard />;

        return (
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card variant="outlined" raised >
                        <CardHeader title={this.props.t('deliveryTranslations.driverRoute')} />
                        <GoogleMapReactWrapper>
                            <GoogleMapReact
                                options={this.getMapOptions}
                                bootstrapURLKeys={{
                                    key: GMapAPIKey
                                }}
                                defaultCenter={this.state.options.center}
                                defaultZoom={this.state.options.zoom}
                                yesIWantToUseGoogleMapApiInternals
                                onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                            />
                        </GoogleMapReactWrapper>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

const DeliveryMapCard = withStyles(() => styles)(DeliveryMap)
const msp = state => ({
    token: state.session.user.token
})
const mdp = dispatch => ({
    
})
export default connect(msp, mdp)(withTranslation()(DeliveryMapCard))

