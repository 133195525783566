import React, { ReactElement } from "react";
import { Button, Checkbox, Collapse, Grid, Table, TableBody, TableCell, TableContainer, TableFooter, TableRow, Typography } from "@material-ui/core";
import { ETicketMap, ETicketParameterValueMap, ETicketValueMap } from "../../../../../redux/reducers/entities/ETicket";
import { useTranslation } from 'react-i18next';
import RenderETicketParameter from "./components/RenderETicketParameter";


interface CEDJobState {
    send_realtime_etickets: boolean,
    eticket_targets: ETicketValueMap,
    possibleTargets: ETicketMap
    possibleTargetNames: string[]
}
interface Props {
    state: CEDJobState,
    setState: Function,
}
const AddETicketTargets = ({
    state,
    setState,
}: Props): ReactElement => {
    const { t } = useTranslation()
    const toggleETicketTarget = (targetName: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            console.log(state.possibleTargets[targetName].parameterDependencies)
            const target: ETicketParameterValueMap = {}
            state.possibleTargets[targetName].parameterKeys.forEach(parameterKey => {
                target[parameterKey] = ""
            })
            setState({
                eticket_targets: {
                    [targetName]: target
                }
            })
        } else {
            const tempTargetObject = {
                ...state.eticket_targets
            }
            delete tempTargetObject[targetName]
            setState({ 
                eticket_targets: {
                    ...tempTargetObject
                } 
            })
        }
    }
    const handleChange = (targetName: string, parameterName: string) => (newValue: string | number) => {
        setState((prevState: any) => {
            const newState: CEDJobState = {
                eticket_targets: {
                    ...prevState.eticket_targets,
                    [targetName]: {
                        ...prevState.eticket_targets[targetName],
                        [parameterName]: newValue
                    }
                },
                send_realtime_etickets: state.send_realtime_etickets,
                possibleTargets: { ...state.possibleTargets },
                possibleTargetNames: state.possibleTargetNames,
            }

            // Check all requirements
            const checkedDependentParameterNames: string[] = []
            state.possibleTargets[targetName].parameterDependencies.forEach(dependency => {
                const [independentParameterName, independentParameterValue, dependentParameterName] = dependency;
                const dependencyConditionMet = newState.eticket_targets[targetName][independentParameterName] == independentParameterValue
                if (!checkedDependentParameterNames.includes(dependentParameterName)) {
                    if (dependencyConditionMet) {
                        checkedDependentParameterNames.push(dependentParameterName)
                    }
                    newState.possibleTargets[targetName].parameters[dependentParameterName].requiredByOther = dependencyConditionMet
                }
            })

            return newState
        })
    }
    return (
        <Grid container spacing={10}>
            <Grid item xs>
                <TableContainer>
                    <Table>
                        <TableBody>
                            {state.possibleTargetNames.map(targetName => {
                                const target = state.possibleTargets[targetName]
                                const targetAdded = state.eticket_targets.hasOwnProperty(targetName)
                                return <React.Fragment key={targetName}>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={targetAdded}
                                                onChange={toggleETicketTarget(targetName)}
                                                inputProps={{
                                                    'aria-label': targetName,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6">{targetName}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} padding={targetAdded ? "default" : "none"}>
                                            <Collapse in={targetAdded} timeout="auto" unmountOnExit>
                                                    <Grid container spacing={10}>
                                                        {target.parameterKeys.map(parameterName => (
                                                            <RenderETicketParameter 
                                                                key={`${targetName}-${parameterName}`}
                                                                targetName={targetName}
                                                                parameter={target.parameters[parameterName]}
                                                                value={state.eticket_targets[targetName]?.[parameterName]}
                                                                parameterName={parameterName}
                                                                setValue={handleChange(targetName, parameterName)}
                                                            />
                                                        ))}
                                                    </Grid>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}
export default AddETicketTargets