import { Box, Typography as MuiTypography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { spacing } from "@material-ui/system";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useRootSelector } from "../../../../..";
import { DeliveryApi } from "../../../../../redux/reducers/entities";
import { createDateFromUnix, getDateTime } from "../../../../../redux/reducers/helpersReducerr";

const Typography = styled(MuiTypography)(spacing)
const useStyles = makeStyles({
    green: {
        color: '#27c24c !important'
    },
    red: {
        color: '#f05050 !important',
    },
    gray: {
        color: '#616161 !important',
    },
    tpo: {
        color: '#ff6600 !important',
    },
})

interface Props {
    delivery: DeliveryApi
    type: "checkin" | "checkout" | "complete"
}
const DeliveryTimes = ({ delivery, type = "checkin" }: Props) => {
    const { t } = useTranslation()
    const viewLocalTimezone = useRootSelector(state => state.session.user.settings?.view_times_in_local_timezone)
    const classes = useStyles()

    const [formattedDate, setFormattedDate] = useState({
        title: "",
        value: "",
        className: classes.green,
    })
    const [duration, setDuration] = useState({
        title: "",
        value: "",
        className: classes.green,
    })

    useEffect(() => {
        const dateOptions = { timezone: viewLocalTimezone ? undefined : delivery.job.job_location_timezone }
        if (type === "checkin") {
            setFormattedDate({
                title: t('deliveryTranslations.checkin'),
                value: delivery.checkin ? getDateTime(delivery.checkinDate!, dateOptions) : t('na'),
                className: classes.green,
            })
            setDuration({
                title: t("deliveryTranslations.loadDuration"),
                value: delivery.loadDuration || t('na'),
                className: classes.green,
            })
            return
        } 
        if (type === "checkout") {
            setFormattedDate({
                title: t('deliveryTranslations.checkout'),
                value: delivery.checkout ? getDateTime(delivery.checkoutDate!, dateOptions) : t('na'),
                className: classes.red,
            })
            setDuration({
                title: t("deliveryTranslations.unloadDuration"),
                value: delivery.unloadDuration || t('na'),
                className: classes.red,
            })
        }
        if (type === "complete") {
            let formattedCompletedAt = t('na')
            if (delivery.completed_at) {
                const completedAtDate = createDateFromUnix(delivery.completed_at, { keepLocal: false, isTimestampDateOnly: false })
                formattedCompletedAt = getDateTime(completedAtDate, dateOptions)
            }

            setFormattedDate({
                title: "Complete",
                value: formattedCompletedAt,
                className: classes.tpo,
            })
            setDuration({
                title: "Total Duration",
                value: delivery.formattedTotalDuration || t('na'),
                className: classes.tpo,
            })
        }
    }, [])

    return <>
        <Box mb={2}>
            <i className={`fas fa-circle fa-xs ${formattedDate.className}`}></i> 
            <Typography color="textSecondary" display="inline" ml={2}>
                {formattedDate.title}: 
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>
                {formattedDate.value}
            </Typography>
        </Box>
        <Box mb={2}>
            <i className={`fas fa-circle fa-xs ${duration.className}`}></i> 
            <Typography color="textSecondary" display="inline" ml={2}>
                {duration.title}: 
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>
                {duration.value}
            </Typography>
        </Box>
    </>
}

export default DeliveryTimes