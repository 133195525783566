import { TPAPIBase } from '../../env';
import { functions } from '../reducers/helpersReducer';
import i18n from '../../i18n';
import { createDateFromUnix } from '../reducers/helpersReducerr';

const formatSiteHours = (time) => {
    const date = new Date().setHours(...time.split(":").map(str => parseInt(str)));
    return functions.timeFormatter(date);
}

export const addSiteMetadata = site => {
    site.formattedOpenTime = formatSiteHours(site.opening_time)
    site.formattedCloseTime = formatSiteHours(site.closing_time)
    site.startDateObject = createDateFromUnix(site.start_date, { isTimestampDateOnly: true })
    site.endDateObject = createDateFromUnix(site.end_date, { isTimestampDateOnly: true })
    const startTimeParts = functions.getTimeParts(site.opening_time)
    const endTimeParts = functions.getTimeParts(site.closing_time)
    site.startDateObject.setHours(startTimeParts.hour)
    site.startDateObject.setMinutes(startTimeParts.min)
    site.endDateObject.setHours(endTimeParts.hour)
    site.endDateObject.setMinutes(endTimeParts.min)
    return site
}

export const getCompanySites = (token, companyID) => (
    fetch(`${TPAPIBase}/company/${companyID}/sites`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then(res => {
        const tempRes = {...res};
      
        if (res.data && res.data.length) {
            tempRes.data = res.data.map(site => {
                return addSiteMetadata(site)
            })
        }
        return tempRes;
    })
)
export const getSite = (token, siteID) => (
    fetch(`${TPAPIBase}/site/${siteID}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const deleteSite = (token, siteID) => (
    fetch(`${TPAPIBase}/site/${siteID}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: {
                delete: true
            }
        })
    }).then(response => response.json())
)
export const getSiteTypes = (token) => (
    fetch(`${TPAPIBase}/site_types`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
)
export const createSite = (token, formData) => (
    fetch(`${TPAPIBase}/site`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: formData
    }).then(response => response.json())
)
export const editSite = (token, siteID, formData) => (
    fetch(`${TPAPIBase}/site/${siteID}/update`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        },
        body: formData
    }).then(response => response.json())
)
// export const getUsersCompanies = (token) => (
//     fetch(`${TPAPIBase}/users/authenticate`, {
//         method: 'get',
//         headers: {
//             Accept: 'application/json',
// 'Accept-Language': i18n.language,
//             'Content-Type': 'application/json',
//             'x-auth-header': token
//         },
//         body: JSON.stringify({
//             data: user
//         })
//     }).then((response) => response.json())
// )
