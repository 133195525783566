import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    Modal,
    Tab,
    Tabs,
    TabContent,
    TabScrollButton,
    TabList,
    Paper as MuiPaper,
    AppBar,
    Toolbar,
    Typography as MuiTypography,
    Grid as MuiGrid,
    FormGroup,
    FormControl as MuiFormControl,
    FormControlLabel,
    FormLabel,
    FormHelperText,
    Input,
    InputLabel,
    Select,
    TextField,
    MenuItem,
    Button,
    Switch,
    Radio,
    RadioGroup,
    Divider,
    InputAdornment,
    Box as MuiBox,
} from '@material-ui/core';
import { connect } from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import WorldIcon from '@material-ui/icons/Language';
import { TPLoader } from '../../../components/Progress';
import GoogleMapReact from 'google-map-react'
import { GMapAPIKey } from '../../../../env'
import { popAlert } from '../../../../redux/actions/sessionActions';
import { getValidUnits, getSupportedCountries } from '../../../../redux/actions/adminActions';
import { getCompanyScales } from '../../../../redux/actions/scaleActions';
import { getScaleHardwareSpecs, getScaleCameraSpecs, createScale, editScale } from '../../../../redux/util/scaleAPI';
import { spacing, border } from "@material-ui/system";
import styled from "styled-components";
import { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import { getPhoneData, CustomPhoneNumberInput } from '../../Util/PhoneInput';
import NotificationSnackbar from '../../Util/Snackbar';
import './styles.css';
import TpTextfield from '../../Util/TPTextfield';
import { ScaleApi } from '../../../../redux/reducers/entities/Scale';

const Paper = styled(MuiPaper)(spacing);
const Typography = styled(MuiTypography)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const Grid = styled(MuiGrid)`
    ${spacing};
    ${border}
`;
const Box = styled(MuiBox)(spacing);
const Bold = styled.span`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: black;
`;

const GoogleMapReactWrapper = styled.div`
  height: 300px;
  width: 100%;
`;
function SectionHeader({ title }) {
    return (<>
        <Typography variant="h2" mb={4}><Bold>{title}</Bold></Typography>
        <Divider />
    </>
    )
}
class CreateEditScale extends React.Component {
    nullState = {
        scale_hardware_specs_id: "",
        name: "",
        weighmaster_required: false,
        wifi_name: "",
        wifi_password: "",
        ssid: "",
        port: "",
        support_email: "",
        support_phone: "",
        companyCountryCode: "1",
        
        country_id: "",
        address_line_1: "",
        city: "",
        province: "",
        postal_code: "",
        latitude: "",
        longitude: "",
    
        scale_division: "",
        weight_units: "",

        front_camera_spec_id: "",
        front_camera_url: "",

        back_camera_spec_id: "",
        back_camera_url: "",

        overhead_camera_spec_id: "",
        overhead_camera_url: "",

        hardwareSpecs: [],
        cameraSpecs: [],
        bypass_address_verification: false,
        warnedBadAddressInput: false,

        loading: true,
        displayCoordinates: false,
        hasWifi: false,
        hasFrontCamera: false,
        hasBackCamera: false,
        hasOverheadCamera: false,
    }
    constructor(props) {
        super(props);
        this.today = new Date();
        this.state = { ...this.nullState }
        this.addressInput = React.createRef();
    }

    prefillState = ({ hardwareSpecs }) => {
        if (this.props.scale) {
            console.log("site", this.props.scale, hardwareSpecs)
            /** @type {ScaleApi} */
            const scale = this.props.scale
            const prefilledState = { ...this.nullState }
            Object.keys(scale).forEach(scaleKey => {
                if (prefilledState.hasOwnProperty(scaleKey) && scale[scaleKey]) {
                    prefilledState[scaleKey] = scale[scaleKey];
                }
            })

            // Scale properties that can't be set programatically
            const companyCountryCode = this.props.company?.phone_country_code || '1'
            const override = {
                latitude: scale.location.coordinates[1],
                longitude: scale.location.coordinates[0],
                weight_units: scale.weight_units.toLowerCase(),
                companyCountryCode,
                support_phone: formatPhoneNumber(`+${companyCountryCode}${scale.support_phone}`)
            }
            if (!(prefilledState.address_line_1 && prefilledState.city && prefilledState.province)) {
                override.displayCoordinates = true;
            }
            if (prefilledState.wifi_name) {
                override.hasWifi = true;
            }
            if (prefilledState.front_camera_url) {
                override.hasFrontCamera = true;
                override.front_camera_url = scale.decrypted_front_camera_url
            }
            if (prefilledState.back_camera_url) {
                override.hasBackCamera = true;
                override.back_camera_url = scale.decrypted_back_camera_url
            }
            if (prefilledState.overhead_camera_url) {
                override.hasOverheadCamera = true;
                override.overhead_camera_url = scale.decrypted_overhead_camera_url
            }
            return {
                ...prefilledState,
                ...override,
            }
        } else {
            return { ...this.nullState }
        }
    }
    componentDidMount = () => {
        this.mounted = true;
        console.log('scale this', this)
    }
    getInitialData = () => {
        Promise.all([
            getScaleHardwareSpecs(this.props.token),
            getScaleCameraSpecs(this.props.token),
            this.props.getValidUnits(this.props.token),
            this.props.getSupportedCountries()
        ]).then(resArray => {
            console.log('scale res arr', resArray)
            const newState = {
                hardwareSpecs: [],
                cameraSpecs: [],
            }
            if (resArray[0].data) {
                newState.hardwareSpecs = resArray[0].data
            }
            if (resArray[1].data) {
                newState.cameraSpecs = resArray[1].data
            }
            if (this.props.scale && this.props.type == 'edit') {
                console.log('prefilling state')
                const prefilledState = this.prefillState(newState)
                const finalState = {
                    ...prefilledState,
                    ...newState,
                    loading: false
                }
                console.log('final state', finalState)
                this.setStateIfMounted(finalState)
            } else {
                this.setStateIfMounted({ ...newState, loading: false })
            }
        })
    }
    componentWillUnmount = () => {
        this.mounted = false;
    }
    componentDidUpdate = (prevProps, prevState) => {

        if (prevState.displayCoordinates != this.state.displayCoordinates) {
            this.connectGoogleAutocomplete()
        }
        if (
            (prevState.latitude != this.state.latitude || prevState.longitude != this.state.longitude)
            && (this.state.latitude && this.state.longitude)
        ) {
            this.updateMarkerPosition()
            if (this.state.displayCoordinates) {
                this.geocodeNewPosition({ lat: parseFloat(this.state.latitude), lng: parseFloat(this.state.longitude) })
            }
        }
        if (prevState.country_id != this.state.country_id && this.state.country_id) {
            this.updateAutocompleteRestriction();
        }
    }
    setStateIfMounted = (state, callback) => {
        if (this.mounted) {
            this.setState(state, callback)
        }
    }
    handleChange = (field, type) => {
        if (type) {
            if (type == 'boolean') {
                return event => this.setStateIfMounted({ [field]: event.target.checked })
            } else if (type == 'number') {
                return event => this.setStateIfMounted({ [field]: parseFloat(event.target.value) || "" })
            } else if (type == 'date') {
                return date => this.setStateIfMounted({ [field]: date })
            } else if (type == 'time') {
                return time => this.setStateIfMounted({ [field]: time })
            } else if (type == 'radio-boolean') {
                return event => this.setStateIfMounted({ [field]: event.target.value == 'true' ? true : false })
            }
        }
        return event => this.setStateIfMounted({ [field]: event.target.value });
    }
    handleRatingChange = newRating => {
        this.setStateIfMounted({
            internet_quality: newRating
        })
    }
    handleFileChange = files => {
        this.setStateIfMounted({
            file: files[0]
        })
    }
    handleApiLoaded = (map, maps) => {
        this.map = map;
        this.maps = maps;
        this.connectGoogleAutocomplete()
        this.getInitialData()
    }
    getMapOptions = maps => {
        return {
            fullscreenControl: true,
            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.ROADMAP,
            scaleControl: true,
            scrollwheel: false,
            streetViewControl: true
        };
    };
    updateAutocompleteRestriction = () => {
        const selectedCountry = this.props.supportedCountries.find(country => country.id == this.state.country_id)
        if (selectedCountry && this.addressAutocomplete) {
            this.addressAutocomplete.setComponentRestrictions({
                country: selectedCountry.code
            })
        }
    }
    connectGoogleAutocomplete = () => {
        if (this.addressInput.current && this.maps && this.maps.places) {
            this.addressAutocomplete = new this.maps.places.Autocomplete(this.addressInput.current)
            this.autoCompleteListener = this.addressAutocomplete.addListener('place_changed', () => {
                const selection = this.addressAutocomplete.getPlace()
                if (selection) {
                    this.handleAddressSelect(selection.address_components, selection.geometry.location)
                }
            })

            const possibleContainers = document.getElementsByClassName('pac-container');
            if (possibleContainers[0]) {
                this.googleAutocompleteWidget = possibleContainers[0];
                this.googleAutocompleteWidget.style.zIndex = "1301";
            }
        }
    }
    handleAddressSelect = (addressComponents, coordinates) => {
        const parsedAddress = this.props.helpers.parseGoogleAddressComponents(addressComponents, true)
        this.setStateIfMounted({
            ...parsedAddress,
            latitude: coordinates.lat(),
            longitude: coordinates.lng(),
        })
    }
    handleMapClick = ({ x, y, lat, lng, event }) => {
        const newPosition = new this.maps.LatLng(lat, lng)
        if (this.map && this.maps) {
            this.geocodeNewPosition(newPosition, true)
        }
    }
    geocodeNewPosition = (latLng, resetCoords) => {
        console.log("geocoding new position")
        if (!this.maps) return

        // const geocoder = new this.maps.Geocoder();
        // geocoder.geocode({ latLng }, (results, status) => {
        //     if (results.length) {
        //         const result = results[0];
        //         const parsedAddress = this.props.helpers.parseGoogleAddressComponents(result.address_components, true)
        //         const newState = {
        //             ...parsedAddress
        //         }
        //         if (resetCoords) {
        //             newState.latitude = result.geometry.location.lat()
        //             newState.longitude = result.geometry.location.lng()
        //         }
        //         this.setStateIfMounted({ ...newState })
        //     }
        // })
    }
    updateMarkerPosition = () => {
        if (this.map && this.maps) {
            const newPosition = new this.maps.LatLng(this.state.latitude, this.state.longitude)
            if (!this.marker) {
                this.marker = new this.maps.Marker({
                    position: newPosition,
                    map: this.map,
                    animation: this.maps.Animation.DROP
                })
            } else {
                this.marker.setPosition(newPosition);
            }
            this.map.setCenter(newPosition);
        }

    }

    organizeSitePostData = async () => {
        const postData = {
            scale_hardware_specs_id: "",
            name: "",
            weighmaster_required: false,
            wifi_name: "",
            wifi_password: "",
            ssid: "",
            port: "",
            support_email: "",
            support_phone: "",
            latitude: "",
            longitude: "",
            scale_division: "",
            weight_units: "",
            front_camera_spec_id: "",
            front_camera_url: "",
            back_camera_spec_id: "",
            back_camera_url: "",
            overhead_camera_spec_id: "",
            overhead_camera_url: "",
            bypass_address_verification: false
        }

        Object.keys(postData).forEach(key => {
            if (this.state.hasOwnProperty(key)) {
                if (key == 'weight_units') {
                    postData[key] = this.state[key].toUpperCase();
                } else if (Boolean(this.state[key])) {
                    postData[key] = this.state[key] 
                } else {
                    delete postData[key]
                }
            }
        })

        // If the wifi, front cam, or back cam is turned off, nullify the relevant values
        if (!this.state.hasWifi) {
            postData.wifi_name = ""
            postData.wifi_password = ""
        }
        if (!this.state.hasFrontCamera) {
            postData.front_camera_spec_id = null
            postData.front_camera_url = null
        }
        if (!this.state.hasBackCamera) {
            postData.back_camera_spec_id = null
            postData.back_camera_url = null
        }
        if (!this.state.hasOverheadCamera) {
            postData.overhead_camera_spec_id = null
            postData.overhead_camera_url = null
        }

        const postType = this.props.type;
        if (postType == 'create') {
            createScale(this.props.token, this.props.company.id, postData).then(res => {
                if (res.success) {
                    this.props.popAlert('success', this.props.t('success'), this.props.t('scaleTranslations.success.createScale'))
                    this.props.getCompanyScales(this.props.token, this.props.company.id)
                    this.props.setModalOpen(false)
                } else {
                    this.setStateIfMounted({
                        loading: false,
                    }, () => this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res.error)))
                }
            })
        } else if (postType == 'edit') {
            editScale(this.props.token, this.props.company.id, this.props.scale.id, postData).then(res => {
                if (res.success) {
                    this.props.popAlert('success', this.props.t('success'), this.props.t('scaleTranslations.success.editScale'))
                    this.props.getCompanyScales(this.props.token, this.props.company.id)
                    this.props.setModalOpen(false)
                } else {
                    this.setStateIfMounted({
                        loading: false,
                    }, () => this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res.error)))
                }
            })
        }

    }
    verifyAddress = async () => {
        if (this.state.warnedBadAddressInput) {
            this.organizeSitePostData();
        }
        if (!this.state.displayCoordinates && this.maps && (this.state.address_line_1 && this.state.city && this.state.province)) {
            const geocoder = new this.maps.Geocoder();
            await geocoder.geocode({ address: `${this.state.address_line_1} ${this.state.city} ${this.state.province} ${this.state.postal_code}` }, (results, status) => {
                if (status == this.maps.GeocoderStatus.OK && results.length) {
                    console.log(results[0])
                    this.setStateIfMounted({
                        latitude: results[0].geometry.location.lat(),
                        longitude: results[0].geometry.location.lng(),
                    }, () => this.organizeSitePostData())
                } else {
                    this.setStateIfMounted({
                        warnedBadAddressInput: true,
                        loading: false,
                        bypass_address_verification: true,
                    }, () => {
                        this.props.popAlert('warning', this.props.t('badAddress'), this.props.t('scaleTranslations.errors.badAddress'))
                    })
                    return false;
                }
            })
        } else {
            this.organizeSitePostData();
        }
    }
    getDisplayType = (isLoader) => {
        if (isLoader) {
            return this.state.loading ? "" : "none";
        } else {
            return this.state.loading ? "none" : "";
        }
    }
    submitScale = event => {
        event.preventDefault();
        console.log("submitting!")
        this.setStateIfMounted({
            loading: true,
        }, () => this.verifyAddress())
    }
    render = () => {
        return (
            <Modal open={true} style={{ overflowY: 'scroll' }}>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} sm={8} md={7} lg={5}>
                        <Paper m={6}>
                            <Box borderBottom={1} borderColor="grey.500">
                                <Grid container spacing={5} style={{ width: '100%', margin: 0, backgroundColor: "rgba(0,0,0,0.05)" }} direction="row" justify="space-between" alignItems="center">
                                    <Grid item>
                                        {this.props.type == 'create' && <Typography variant="h3">{this.props.t('scaleTranslations.actions.createScale')}</Typography>}
                                        {this.props.type == 'edit' && <Typography variant="h3">{this.props.t('scaleTranslations.actions.editScale')}</Typography>}
                                    </Grid>
                                    <Grid item>
                                        <CloseIcon onClick={() => this.props.setModalOpen(false)} />
                                    </Grid>
                                </Grid>
                            </Box>
                            
                            {/* <Box style={{ display: this.getDisplayType(true) }}>
                                <TPLoader />
                            </Box> */}

                            <Box p={5}>
                                <form autoComplete="off" onSubmit={(event) => this.submitScale(event)}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="scale_hardware_specs_id">{this.props.t('scaleTranslations.scaleHardware')}</InputLabel>
                                                <Select
                                                    labelId="scale_hardware_specs_id"
                                                    label={this.props.t('scaleTranslations.scaleHardware')}
                                                    value={this.state.scale_hardware_specs_id}
                                                    onChange={this.handleChange('scale_hardware_specs_id')}
                                                    fullWidth
                                                    required
                                                    inputProps={{
                                                        name: "scale_hardware_specs_id",
                                                        id: "scale_hardware_specs_id"
                                                    }}
                                                    error={!this.state.scale_hardware_specs_id}
                                                >
                                                    {this.state.hardwareSpecs.map(spec => (
                                                        <MenuItem key={spec.id} value={spec.id}>{spec.make} {spec.model}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="name"
                                                    label={this.props.t('scaleTranslations.scaleName')}
                                                    value={this.state.name}
                                                    onChange={this.handleChange("name")}
                                                    type="text"
                                                    required
                                                    error={!this.state.name}
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="ssid"
                                                    label={this.props.t('scaleTranslations.scaleSSID')}
                                                    value={this.state.ssid}
                                                    onChange={this.handleChange("ssid")}
                                                    type="text"
                                                    required
                                                    error={!this.state.ssid}
                                                    variant="outlined"
                                                    placeholder="192.1.1.16"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TpTextfield 
                                                label={this.props.t('scaleTranslations.scalePort')}
                                                onChange={this.handleChange("port")}
                                                value={this.state.port}
                                                textfieldProps={{
                                                    type: "number",
                                                    inputProps: {
                                                        min: 0,
                                                        step: 1,
                                                    },
                                                    placeholder: "8080"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} md={4}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">{this.props.t('scaleTranslations.wifiRequired')}</FormLabel>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.hasWifi}
                                                                onChange={this.handleChange("hasWifi", 'boolean')}
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                        {this.state.hasWifi && <>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="wifi_name"
                                                        label={this.props.t('scaleTranslations.wifiName')}
                                                        value={this.state.wifi_name}
                                                        onChange={this.handleChange("wifi_name")}
                                                        type="text"
                                                        required
                                                        error={!this.state.wifi_name}
                                                        variant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="wifi_password"
                                                        label={this.props.t('scaleTranslations.wifiPassword')}
                                                        value={this.state.wifi_password}
                                                        onChange={this.handleChange("wifi_password")}
                                                        type="text"
                                                        error={!this.state.wifi_password}
                                                        variant="outlined"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>}
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">{this.props.t('scaleTranslations.frontCamera')}</FormLabel>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.hasFrontCamera}
                                                                onChange={this.handleChange("hasFrontCamera", 'boolean')}
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {this.state.hasFrontCamera && <>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="front_camera_spec_id">{this.props.t('scaleTranslations.frontCameraHardware')}</InputLabel>
                                                    <Select
                                                        labelId="front_camera_spec_id"
                                                        label={this.props.t('scaleTranslations.frontCameraHardware')}
                                                        value={this.state.front_camera_spec_id}
                                                        onChange={this.handleChange('front_camera_spec_id')}
                                                        fullWidth
                                                        required
                                                        inputProps={{
                                                            name: "front_camera_spec_id",
                                                            id: "front_camera_spec_id"
                                                        }}
                                                        error={!this.state.front_camera_spec_id}
                                                    >
                                                        {this.state.cameraSpecs.map(spec => (
                                                            <MenuItem key={spec.id} value={spec.id}>{spec.make} {spec.model}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <TpTextfield 
                                                    label="Front Camera Image Snapshot URL"
                                                    onChange={this.handleChange("front_camera_url")}
                                                    value={this.state.front_camera_url}
                                                    required
                                                    textfieldProps={{
                                                        type: "url"
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>}
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">{this.props.t('scaleTranslations.backCamera')}</FormLabel>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.hasBackCamera}
                                                                onChange={this.handleChange("hasBackCamera", 'boolean')}
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {this.state.hasBackCamera && <>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="back_camera_spec_id">{this.props.t('scaleTranslations.backCameraHardware')}</InputLabel>
                                                    <Select
                                                        labelId="back_camera_spec_id"
                                                        label={this.props.t('scaleTranslations.backCameraHardware')}
                                                        value={this.state.back_camera_spec_id}
                                                        onChange={this.handleChange('back_camera_spec_id')}
                                                        fullWidth
                                                        required
                                                        inputProps={{
                                                            name: "back_camera_spec_id",
                                                            id: "back_camera_spec_id"
                                                        }}
                                                        error={!this.state.back_camera_spec_id}
                                                    >
                                                        {this.state.cameraSpecs.map(spec => (
                                                            <MenuItem key={spec.id} value={spec.id}>{spec.make} {spec.model}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <TpTextfield 
                                                    label="Back Camera Image Snapshot URL"
                                                    onChange={this.handleChange("back_camera_url")}
                                                    value={this.state.back_camera_url}
                                                    required
                                                    textfieldProps={{
                                                        type: "url"
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>}
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Overhead Camera</FormLabel>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.hasOverheadCamera}
                                                                onChange={this.handleChange("hasOverheadCamera", 'boolean')}
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {this.state.hasOverheadCamera && <>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="overhead_camera_spec_id">Overhead Camera Hardware</InputLabel>
                                                    <Select
                                                        labelId="overhead_camera_spec_id"
                                                        label="Overhead Camera Hardware"
                                                        value={this.state.overhead_camera_spec_id}
                                                        onChange={this.handleChange('overhead_camera_spec_id')}
                                                        fullWidth
                                                        required
                                                        inputProps={{
                                                            name: "overhead_camera_spec_id",
                                                            id: "overhead_camera_spec_id"
                                                        }}
                                                        error={!this.state.back_camera_spec_id}
                                                    >
                                                        {this.state.cameraSpecs.map(spec => (
                                                            <MenuItem key={spec.id} value={spec.id}>{spec.make} {spec.model}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <TpTextfield 
                                                    label="Overhead Camera Image Snapshot URL"
                                                    onChange={this.handleChange("overhead_camera_url")}
                                                    value={this.state.overhead_camera_url}
                                                    required
                                                    textfieldProps={{
                                                        type: "url"
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>}

                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <GoogleMapReactWrapper>
                                                <GoogleMapReact
                                                    options={this.getMapOptions}
                                                    bootstrapURLKeys={{
                                                        key: GMapAPIKey
                                                    }}
                                                    defaultCenter={{ lat: 40.75027, lng: -73.8542755 }}
                                                    defaultZoom={16}
                                                    yesIWantToUseGoogleMapApiInternals
                                                    onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                                                    onClick={this.handleMapClick}
                                                />
                                            </GoogleMapReactWrapper>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} md={6}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                startIcon={<WorldIcon />}
                                                onClick={() => this.setStateIfMounted({ displayCoordinates: !this.state.displayCoordinates })}
                                            >
                                                {this.state.displayCoordinates ? this.props.t('addressTranslations.useAddress') : this.props.t('addressTranslations.useCoordinates')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {!this.state.displayCoordinates ? <>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="job_country">{this.props.t('addressTranslations.country')}</InputLabel>
                                                    <Select
                                                        labelId="job_country"
                                                        label={this.props.t('addressTranslations.country')}
                                                        value={this.state.country_id}
                                                        onChange={this.handleChange('country_id')}
                                                        fullWidth
                                                        inputProps={{
                                                            name: "job_country",
                                                            id: "job_country"
                                                        }}
                                                        required
                                                        error={!this.state.country_id}
                                                    >
                                                        {this.props.supportedCountries.map(country => (
                                                            <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_address_line_1"
                                                        label={this.props.t('addressTranslations.addressLine1')}
                                                        value={this.state.address_line_1}
                                                        onChange={this.handleChange('address_line_1')}
                                                        type="text"
                                                        inputRef={this.addressInput}
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.address_line_1}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_city"
                                                        label={this.props.t('addressTranslations.city')}
                                                        value={this.state.city}
                                                        onChange={this.handleChange('city')}
                                                        type="text"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.city}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_state"
                                                        label={this.props.t('addressTranslations.province')}
                                                        value={this.state.province}
                                                        onChange={this.handleChange('province')}
                                                        type="text"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.province}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_postal_code"
                                                        label={this.props.t('addressTranslations.postalCode')}
                                                        value={this.state.postal_code}
                                                        onChange={this.handleChange('postal_code')}
                                                        type="text"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.postal_code}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </> : <>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_latitude"
                                                        label={this.props.t('addressTranslations.latitude')}
                                                        value={this.state.latitude}
                                                        onChange={this.handleChange('latitude')}
                                                        type="number"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.latitude}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_longitude"
                                                        label={this.props.t('addressTranslations.longitude')}
                                                        value={this.state.longitude}
                                                        onChange={this.handleChange('longitude')}
                                                        type="number"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.longitude}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </>}

                                    <Grid container spacing={5}>
                                        <Grid item xs={12} md={4}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">{this.props.t('scaleTranslations.weighmasterRequired')}</FormLabel>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.weighmaster_required}
                                                                onChange={this.handleChange("weighmaster_required", 'boolean')}
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="support_email"
                                                    label={this.props.t('scaleTranslations.supportEmail')}
                                                    value={this.state.support_email}
                                                    onChange={this.handleChange("support_email")}
                                                    type="email"
                                                    error={!this.state.support_email}
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="support_phone"
                                                    label={this.props.t('scaleTranslations.supportPhone')}
                                                    value={this.state.support_phone}
                                                    onChange={e => {
                                                        const data = parsePhoneNumber(`+${this.state.companyCountryCode}${e.target.value}`)
                                                        if (data) {
                                                            this.setStateIfMounted({
                                                                support_phone: formatPhoneNumber(`+${data.countryCallingCode}${data.nationalNumber}`)
                                                            })
                                                        } else {
                                                            this.setStateIfMounted({
                                                                support_phone: e.target.value
                                                            })
                                                        }
                                                    }}
                                                    // onChange={this.handleChange("support_phone")}
                                                    type="text"
                                                    error={!this.state.support_phone}
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} md={6}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">{this.props.t('scaleTranslations.scaleDivision')}</FormLabel>
                                                <RadioGroup
                                                    aria-label="scale_division"
                                                    name="scale_division"
                                                    value={this.state.scale_division}
                                                    onChange={this.handleChange('scale_division', 'number')}
                                                    row
                                                >
                                                    <FormControlLabel
                                                        value={10}
                                                        control={<Radio color="primary" required />}
                                                        label="10 lb"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value={20}
                                                        control={<Radio color="primary" required />}
                                                        label="20 lb"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value={50}
                                                        control={<Radio color="primary" required />}
                                                        label="50 lb"
                                                        labelPlacement="end"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="weight_units">{this.props.t('unitTranslations.weightUnit')}</InputLabel>
                                                <Select
                                                    labelId="weight_units"
                                                    label={this.props.t('unitTranslations.weightUnit')}
                                                    value={this.state.weight_units}
                                                    onChange={this.handleChange('weight_units')}
                                                    fullWidth
                                                    required
                                                    inputProps={{
                                                        name: "weight_units",
                                                        id: "weight_units"
                                                    }}
                                                    error={!this.state.weight_units}
                                                >
                                                    {this.props.units.weight.map(unit => (
                                                        <MenuItem key={unit} value={unit}>{this.props.t(`unitTranslations.weightUnits.${unit}`)}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="primary" fullWidth type="submit">
                                                {this.props.t('actions.submit')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Paper>
                    </Grid>
                    <NotificationSnackbar />
                </Grid>
            </Modal>
        )
    }
}
const msp = state => ({
    helpers: state.helpers,
    token: state.session.user.token,
    userID: state.session.user.id,
    company: state.session.company,
    units: state.entities.units,
    supportedCountries: state.entities.supportedCountries,
})
const mdp = dispatch => ({
    getValidUnits: token => dispatch(getValidUnits(token)),
    getSupportedCountries: () => dispatch(getSupportedCountries()),
    getCompanyScales: (token, company) => dispatch(getCompanyScales(token, company)),
    popAlert: (success, title, message) => dispatch(popAlert(success, title, message)),
})
export default connect(msp, mdp)(withTranslation()(CreateEditScale))