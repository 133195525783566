import React from "react";
import PhoneInput, { parsePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import TextField from '@material-ui/core/TextField';
import 'react-phone-number-input/style.css';
import './styles.css';
const CustomPhoneNumberInput = React.forwardRef(function (props, ref) {
    const { onChange, defaultValue, ...other } = props;
    return (
        <PhoneInput
            {...other}
            onChange={onChange}
            inputRef={ref}
            defaultCountry="US"
            international={false}
            initialValueFormat="national"
        />
    );
});

/* EXAMPLE USAGE
    <FormControl fullWidth>
        <TextField
            id="phone_number"
            label={this.props.t('companyTranslations.companyPhone')}
            value={this.state.phone_number}
            onChange={text => this.setStateIfMounted({ phone_number: text })}
            placeholder="(123) 456-7890"
            type="tel"
            required
            error={!this.state.phone_number}
            variant="outlined"
            InputProps={{
                inputComponent: CustomPhoneNumberInput
            }}
            InputLabelProps={{ shrink: true }}
        />
    </FormControl>
*/

const CustomTextfield = React.forwardRef(function (props, ref) {
    const { onChange, ...other } = props;
    return (
        <TextField
            {...other}
            onChange={onChange}
            inputRef={ref}
        />
    );
});
const CustomOutlinedTextfield = React.forwardRef(function (props, ref) {
    const { onChange, ...other } = props;
    return (
        <TextField
            {...other}
            onChange={onChange}
            inputRef={ref}
            variant="outlined"
        />
    );
});
const getPhoneData = (text) => {
    let isValid = false
    if (isValidPhoneNumber(text)) {
        isValid = true
    }
    const { countryCallingCode, nationalNumber } = parsePhoneNumber(text) || { countryCallingCode: null, nationalNumber: null }

    if (countryCallingCode && nationalNumber) {
        return {
            isValid,
            countryCode: countryCallingCode,
            phone: nationalNumber
        }
    } else {
        return {
            isValid,
            countryCode: '',
            phone: ''
        }
    }

}
export {
    CustomPhoneNumberInput,
    CustomTextfield,
    CustomOutlinedTextfield,
    getPhoneData,
}