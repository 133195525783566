import { TPAPIBase } from '../../env'
import i18n from '../../i18n';
import { Frequency } from '../reducers/entities';
import { Invoice } from '../reducers/entities/Payments';
import { formatParams, parseError, toMysqlFormat } from '../reducers/helpersReducerr';
import { TPResponse } from './util';

export const getSpecificInvoice = (token: string, invoiceID: number, asCustomer: boolean) => {
    return fetch(`${TPAPIBase}/invoice/${invoiceID}?as_customer=${asCustomer ? 1 : 0}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        }
    }).then(response => response.json())
}
interface GetInvoicesParam {
    key: string;
    value: any
}
// TODO update the Get __ Invoices to use this structure
export interface GetInvoicesOptions {
    token: string;
    company: number;
    period: Frequency | "custom";
    group_by_currency: 0 | 1;
    start?: number;
    end?: number;
    date_filter_field?: "created_at" | "due_date";
    is_overdue?: 0 | 1;
}
interface GetInvoicesResponse {
    currency_groups: {
        [currencyCode: string]: {
            invoices: Invoice[],
            summary: { 
                currency: string; 
                total_amount: number; 
                total_load: number; 
            }
        }
    };
    current_payment_method: {
        address_city: string | null;
        address_country: string | null;
        address_line1: string | null;
        address_line1_check: string | null;
        address_line2: string | null;
        address_state: string | null;
        address_zip: string | null;
        address_zip_check: string | null;
        brand: string;
        country: string;
        customer: string;
        cvc_check: string;
        dynamic_last4: any | null;
        exp_month: number;
        exp_year: number;
        fingerprint: string;
        funding: string;
        id: string;
        last4: string;
        metadata: any[]
        name: any | null;
        object: "card";
        tokenization_method: any | null;
    };
    invoice_parameters: {
        [key: string]: any;
    };
    summary: { total_load: number };
}
export const getPayableInvoices = async ({ token, ...params }: GetInvoicesOptions): Promise<GetInvoicesResponse> => {
    const formattedParams = formatParams(params)
    const response = await fetch(`${TPAPIBase}/invoice/payable?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        }
    }).then(response => response.json())

    if (!response.success) throw new Error(parseError(response))

    return response.data
}
export const getReceivableInvoices = async ({ token, ...params }: GetInvoicesOptions): Promise<GetInvoicesResponse> => {
    const formattedParams = formatParams(params)
    const response = await fetch(`${TPAPIBase}/invoice/receivable?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        }
    }).then(response => response.json())

    if (!response.success) throw new Error(parseError(response))

    return response.data
}
export const getDeliveriesForInvoice = (token: string, invoiceID: number, page = 1) => {
    return fetch(`${TPAPIBase}/invoice/${invoiceID}/deliveries?page=${page}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'x-auth-token': token,
        }
    }).then(response => response.json())
}
export const payInvoice = (token: string, companyID: number, invoiceID: number) => (
    fetch(`${TPAPIBase}/invoice/${invoiceID}/pay`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: {
                company_id: companyID
            }
        })
    }).then(response => response.json())
)
export const markAsPaid = (token: string, companyID: number, invoiceID: number) => (
    fetch(`${TPAPIBase}/company/${companyID}/invoices/${invoiceID}/is_paid`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: {
                paid_on: toMysqlFormat(new Date())
            }
        })
    }).then(response => response.json())
)
export const signOffOnInvoice = (token: string, companyID: number, invoiceID: number) => (
    fetch(`${TPAPIBase}/company/${companyID}/invoice/${invoiceID}/sign_off`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: {

            }
        })
    }).then(response => response.json())
)