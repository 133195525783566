/**
 * Environment Configurations
 */

let TPAPIBase = "https://api.truckpay.com/api/v1";
let GMapAPIKey = "AIzaSyCXmBWfOOU49oHCpS2Bb07RMhsW6hRV0eQ";
let PUSHER_KEY = '';
let PLAID_KEY = '';
let PLAID_ENV = '';
let StripeAPIKey = "";
let BYPASS_TIN_CHECK = false;

switch (process.env.REACT_APP_ENV || process.env.NODE_ENV) {
    case 'production':
        TPAPIBase = "https://api.truckpay.com/api/v1";
        PLAID_KEY = '447a62113d3204528b60f71905e516';
        PLAID_ENV = 'production';
        StripeAPIKey = "pk_live_oWrq0SfK5YQI6Mn7R5X3OykS";
        PUSHER_KEY = '605e46b808553452930a';
        console.log = function () { };
        console.info = function () { };
        console.warn = function () { };
        break;

    case 'test':
        TPAPIBase = "https://testapi.truckpay.com/api/v1"; //the api testing server
        PLAID_KEY = '447a62113d3204528b60f71905e516';
        PLAID_ENV = 'sandbox';
        StripeAPIKey = "pk_test_BvNdBGoRprkUiZV3pY4XC4Eh";
        PUSHER_KEY = 'fd71556b036504ce1756';
        BYPASS_TIN_CHECK = true;
        break;

    case 'development':
    case 'dev':
        TPAPIBase = "https://devapi.truckpay.com/api/v1"; //the api dev server //"https://1998ed2b.ngrok.io/api/v1"
        PLAID_KEY = '447a62113d3204528b60f71905e516';
        PLAID_ENV = 'sandbox';
        StripeAPIKey = "pk_test_BvNdBGoRprkUiZV3pY4XC4Eh";
        PUSHER_KEY = '997811c99407505e7e4f';
        BYPASS_TIN_CHECK = true;
        break;
}

export {
    TPAPIBase,
    StripeAPIKey,
    GMapAPIKey,
    PLAID_ENV,
    PLAID_KEY,
    PUSHER_KEY,
    BYPASS_TIN_CHECK
}
