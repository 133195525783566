import { HardwareSpecsApi } from "./HardwareSpecs";
import { LocationApi } from "./Location"
import CryptoJS from "crypto-js";

/**
 * Decrypts a camera url
 */
const decryptCameraUrl = (encryptedUrl: string, scaleId: number): string | undefined => {
    try {
        const decryptedData = atob(encryptedUrl)
        const decryptedJson: { iv: string, data: string } = JSON.parse(decryptedData)
        const decryptedBodyData = CryptoJS.AES.decrypt(
            decryptedJson.data,
            CryptoJS.enc.Base64.parse("SQxIoX8bxYzGCm/xbycnDcy+dEA8XaN7xknBFqwq1ZU="),
            { iv: CryptoJS.enc.Base64.parse(decryptedJson.iv) }
        )
        return decryptedBodyData.toString(CryptoJS.enc.Utf8)
    } catch (error: any) {
        console.log("error decrypting", error, scaleId)
        return undefined
    }
}

export class ScaleApi {
    public id!: number;
    public scale_hardware_specs_id!: number;
    public string_format!: "CONDEC-Continuous";
    public company_id!: number;
    public name!: string;
    public location!: LocationApi;
    public country_id!: number;
    public geo_fence!: null;
    public distance_units!: "ft";
    public location_code!: string;
    public weighmaster_required!: boolean;
    public wifi_name!: string;
    public wifi_password!: string;
    public ssid!: string;
    public port!: string;
    public front_camera_spec_id!: number | null;
    public back_camera_spec_id!: number | null;
    public overhead_camera_spec_id!: number | null;
    public front_camera_url!: string | null;
    public back_camera_url!: string | null;
    public overhead_camera_url!: string | null;
    public decrypted_front_camera_url?: string | null;
    public decrypted_back_camera_url?: string | null;
    public decrypted_overhead_camera_url?: string | null;
    public support_phone!: string;
    public support_email!: string;
    public scale_division!: number;
    public weight_units!: "LB";
    public created_by!: number;
    public modified_by!: number;
    public created_at!: string;
    public updated_at!: string;
    public num_web_weight_streams_to_log!: number;
    public num_ios_weight_streams_to_log!: number;
    public num_android_weight_streams_to_log!: number;
    public hardware_specs!: HardwareSpecsApi;
    public front_camera!: null;
    public back_camera!: null;

    public static toApi(api: ScaleApi): ScaleApi {
        return {
            ...api,
            decrypted_front_camera_url: api.front_camera_url && decryptCameraUrl(api.front_camera_url, api.id),
            decrypted_back_camera_url: api.back_camera_url && decryptCameraUrl(api.back_camera_url, api.id),
            decrypted_overhead_camera_url: api.overhead_camera_url && decryptCameraUrl(api.overhead_camera_url, api.id),
        }
    }
    
}