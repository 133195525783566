export const SET_THEME = 'SET_THEME';
export const SET_TEXT_SIZE = 'SET_TEXT_SIZE';
export const SET_STYLE_OPTIONS = 'SET_STYLE_OPTIONS';

export function setTheme(value) {
  return {
    type: SET_THEME,
    payload: value
  }
}
export function setTextSize(value) {
  return {
    type: SET_THEME,
    payload: value
  }
}
export function decreaseTextSize(currentValue) {
  return {
    type: SET_TEXT_SIZE,
    payload: (currentValue > 1) ? currentValue - 1 : currentValue
  }
}
export function increaseTextSize(currentValue) {
  return {
    type: SET_TEXT_SIZE,
    payload: (currentValue < 5) ? currentValue + 1 : currentValue
  }
}
export function setStyleOptions(value) {
  return {
    type: SET_STYLE_OPTIONS,
    payload: value
  }
}
