import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { 
    Chip as MuiChip,
    CircularProgress,
    Typography 
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton'
import { useTranslation } from 'react-i18next';
import { Clear, Done } from '@material-ui/icons';
import { getETicketUploadReadinessStatus } from '../../../../redux/util/eticketAPI';
import ViewETicketUploadReadinessModal from '../modals/ModalViewETicketUploadReadiness';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props: any) => props.ready && props.theme.palette.green.main};
  background: ${(props: any) => props.unready && props.theme.palette.error.main};
  color: ${(props: any) => (props.ready || props.unready) && props.theme.palette.primary.contrastText};
`

interface Props {
    targetName: string;
    targetID: number;
    deliveryID: number;
}

const ETicketUploadStatusChip = ({ targetName, deliveryID, targetID }: Props) => {
    const [status, setStatus] = useState("loading")
    const token = useSelector((state: any) => state.session.user.token)
    const [openStatusDetailsModal, setOpenStatusDetailsModal] = useState(false)

    const getETicketUploadStatus = async () => {
        // const status = await getETicketUploadReadinessStatus(token, deliveryID, targetName)
        const response = await getETicketUploadReadinessStatus(token, deliveryID, targetName, { detailed: true })

        if (!response.success) {
            setStatus('unready')
            return;
        }

        let finishedAllRequirements = true
        Object.keys(response.data.requirements).forEach(requirement => {
            if (finishedAllRequirements && !response.data.requirements[requirement]) {
                finishedAllRequirements = false
            }
        })
        setStatus(finishedAllRequirements ? "ready" : "unready")
        
    }
    useEffect(() => {
        getETicketUploadStatus()
    }, [])
    const getChipIcon = () => {
        switch(status) {
            case "ready":
                return <Done color="primary" />
            case "unready":
                return <Clear color="primary"/>
            default:
                return <CircularProgress size="small" color="primary"/>
        }
    }
    const getBackgroundColor = () => {
        switch(status) {
            case "ready":
                return "green"
            case "unready":
                return "red"
            default:
                return undefined
        }
    }
    return <>
        <Chip 
            onClick={() => {
                setOpenStatusDetailsModal(true)
                getETicketUploadStatus()
            }}
            label={targetName}
            onDelete={() => {}}
            deleteIcon={getChipIcon()}
            style={{ backgroundColor: getBackgroundColor(), color: "white" }}
        />
        {openStatusDetailsModal && <ViewETicketUploadReadinessModal 
            targetName={targetName}
            targetID={targetID}
            deliveryID={deliveryID} 
            setModalOpen={setOpenStatusDetailsModal}
        />}
    </>
}
export default ETicketUploadStatusChip