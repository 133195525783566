import { AppBar, Box, Button, Grid, IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tabs, Toolbar, Typography } from "@material-ui/core";
import { Clear, Close, Done, Refresh } from "@material-ui/icons";
import { TabContext } from "@material-ui/lab";
import React, { MouseEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux"
import { popAlert } from "../../../../redux/actions/sessionActions";
import { ETicketUploadReceipt } from "../../../../redux/reducers/entities/ETicket";
import { getETicketUploadReadinessStatus, getETicketUploadReceipts, runETicketTargetIfReady } from "../../../../redux/util/eticketAPI"
import { TPLoader } from "../../../components/Progress";
import TPModal from "../../Util/TPModal"
import TabPanel from "../../Util/TabPanel"

interface Props {
    deliveryID: number;
    targetName: string;
    targetID: number;
    setModalOpen: (bool: boolean) => void
}
interface ETicketRequirementDetail {
    name: string;
    passed: boolean;
}
const ViewETicketUploadReadinessModal = ({
    deliveryID,
    targetName,
    setModalOpen,
    targetID
}: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const helpers = useSelector((state: any) => state.helpers)
    const token = useSelector((state: any) => state.session.user.token)

    const [tabValue, setTabValue] = useState("0")
    const [loading, setLoading] = useState(true)

    const [requirements, setRequirements] = useState<ETicketRequirementDetail[]>([])
    const [readyToUpload, setReadyToUpload] = useState(false)
    const [alreadyUploaded, setAlreadyUploaded] = useState(false)
    const [uploadReceipts, setUploadReceipts] = useState<{ [key: number]: ETicketUploadReceipt[] }>({
        0: []
    })
    const [pagination, setPagination] = useState({
        count: 0,
        page: 0,
    })

    const onChangePage = (event: any, page: number) => {
        setPagination({ ...pagination, page })
    }

    useEffect(() => {
        getETicketUploadReadinessStatus(token, deliveryID, targetName, { detailed: true }).then(res => {
            console.log('status res', res)
            if (res.success) {
                const reqs: ETicketRequirementDetail[] = []
                reqs.push({ name: "Uploaded", passed: res.data.is_already_uploaded })
                setAlreadyUploaded(res.data.is_already_uploaded)
                let finishedAllRequirements = true
                Object.keys(res.data.requirements).forEach(requirement => {
                    if (finishedAllRequirements && !res.data.requirements[requirement]) {
                        finishedAllRequirements = false
                    }
                    reqs.push({ name: requirement, passed: res.data.requirements[requirement] })
                })
                setReadyToUpload(finishedAllRequirements)
                setRequirements(reqs)
            }
            setLoading(false)
        })
    }, [])

    const uploadTicket = () => {
        setLoading(true)
        runETicketTargetIfReady(token, deliveryID, targetID).then(res => {
            if (res?.success) {
                dispatch(popAlert('success', t("success"), "Uploaded ticket"))
            } else {
                dispatch(popAlert('error', t("error"), helpers.parseError(res)))
            }
            setLoading(false)
        })
    }

    const getReceipts = () => {
        setLoading(true)
        getETicketUploadReceipts(token, deliveryID, targetName, { page: pagination.page + 1 }).then(res => {
            if (res.success) {
                setUploadReceipts({
                    ...uploadReceipts,
                    [pagination.page]: res.data.data
                })
                setPagination({
                    ...pagination,
                    count: res.data.total
                })
            }
            setLoading(false)
        })
    }
    useEffect(() => {
        getReceipts()
    }, [pagination.page])

    return <TPModal
        headerOptions={{
            headerText: targetName
        }}
        setModalOpen={setModalOpen}
        bodyOptions={{
            noPadding: true
        }}
        loading={loading}
    >
        <TabContext value={tabValue}>
            <AppBar color="default" position="static">
                <Toolbar>
                    <Tabs
                        variant="scrollable"
                        indicatorColor="primary"
                        scrollButtons="auto"
                        value={tabValue}
                        onChange={(event, tabValue) => setTabValue(tabValue)}
                    >
                        <Tab label={`Requirements`} value={"0"} />
                        <Tab label={"Upload Receipts"} value={"1"}/>
                    </Tabs>
                </Toolbar>
            </AppBar>
            <TabPanel value={tabValue} index={"0"} boxProps={{ p: 3 }}>
                {requirements.map(requirement => <Grid container spacing={10} key={requirement.name} alignItems="center">
                    <Grid item>
                        {requirement.passed ? <Done color="secondary" /> : <Clear color="error" />} 
                    </Grid>
                    <Grid item xs>
                        <Typography>{requirement.name}</Typography>
                    </Grid>
                </Grid>)}
                {(readyToUpload && !alreadyUploaded) && <Grid container spacing={10}>
                    <Grid item xs>
                        <Button color="primary" variant="contained" onClick={() => uploadTicket()}>
                            Upload {targetName} E Ticket
                        </Button>
                    </Grid>
                </Grid>}
            </TabPanel>
            <TabPanel value={tabValue} index={"1"} boxProps={{ p: 3 }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography>Success</Typography></TableCell>
                                <TableCell><Typography>Response</Typography></TableCell>
                                <TableCell padding="checkbox">
                                    <IconButton size="small" onClick={getReceipts}>
                                        <Refresh />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {uploadReceipts[pagination.page]?.map(receipt => <TableRow key={receipt.id}>
                                <TableCell>{receipt.success ? t('success') : t('error')}</TableCell>
                                <TableCell>{receipt.response}</TableCell>
                                <TableCell />
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    rowsPerPage={10}
                    rowsPerPageOptions={[10]}
                    count={pagination.count}
                    page={pagination.page}
                    onPageChange={onChangePage}
                />
                
            </TabPanel>
        </TabContext>
    </TPModal>
}

export default ViewETicketUploadReadinessModal