import i18n from '../../i18n';
import React, { useRef, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { GMapAPIKey } from '../../env';
import { 
    CompanyPermission, 
    LengthUnits, 
    LengthUnit,
    Job, 
    TransactionTypes,
    Company,
    Bid,
    DeliveryApi,
    VolumeUnits,
    WeightUnits,
    JobQuery,
    DistributiveOmit,
    JobQueryTypes,
    instanceOf,
    MaterialCategorizationQuery
} from './entities';
import { SimpleAddress } from './entities/Location';
import { formatCategorizationValue } from '../../pages/dashboards/Materials/modals/ModalEditMaterialCategorizations';

const FULL_TIMESTAMP_REGEX = /(?<year>[\d]+)-(?<month>[\d]+)-(?<day>[\d]+) (?<hour>[\d]+):(?<min>[\d]+):(?<sec>[\d]+)/
const DATE_TIMESTAMP_REGEX = /(?<year>[\d]+)-(?<month>[\d]+)-(?<day>[\d]+)/
interface DateFunctionOptions {
    timezone?: string;
    noTimezone?: boolean;
    keepLocal?: boolean;
    noDecimals?: boolean;
    forCapacities?: boolean;
    timeOnly?: boolean;
    includeSeconds?: boolean;
    dateOnly?: boolean;
    toUTCValues?: boolean;
    isTimestampDateOnly?: boolean;
}

interface RegexMatch {
    [key: string]: string;
}
interface DateMatchGroups {
    year: string;
    month: string;
    day: string;
    hour?: string;
    min?: string;
    sec?: string;
    error?: boolean;
}
interface TimeParts {
    hour: string;
    min: string;
    sec: string;
}

interface TPAPIComplexError {
    [key: string]: string | string[];
}
interface TPAPIReponse {
    success: boolean;
    data?: any;
    message?: string;
    error: string | TPAPIComplexError
}

const getTimestampDetails = function (datetimeString: string, options: DateFunctionOptions = { isTimestampDateOnly: false } ): DateMatchGroups | RegexMatch {
    const nullMatch: DateMatchGroups = {
        year: "0",
        month: "0",
        day: "0",
        hour: "0",
        min: "0",
        sec: "0",
        error: true,
    }
    if (!datetimeString) {
        return nullMatch
    }
    if (options.isTimestampDateOnly && DATE_TIMESTAMP_REGEX.test(datetimeString)) {
        return datetimeString.match(DATE_TIMESTAMP_REGEX)!.groups!
    } else if (FULL_TIMESTAMP_REGEX.test(datetimeString)) {
        return datetimeString.match(FULL_TIMESTAMP_REGEX)!.groups!
    }
    return nullMatch
}
export const createDateFromUnix = function (unixTimestamp: string, options: DateFunctionOptions = { keepLocal: true, isTimestampDateOnly: false }): Date {
    if (!unixTimestamp) return new Date();
    let {
        year = "0", month = "0", day = "0",
        hour = "0", min = "0", sec = "0",
        error = false
    } = getTimestampDetails(unixTimestamp, { isTimestampDateOnly: options.isTimestampDateOnly })
    if (error) {
        console.warn("ERROR CREATING DATE")
        return new Date()
    } 

    if (options.isTimestampDateOnly) {        
        if (options.keepLocal) {
            return new Date(parseInt(year), (parseInt(month) - 1), parseInt(day));
        } 
        return new Date(Date.UTC(parseInt(year), (parseInt(month) - 1), parseInt(day)));
    } 
    
    if (options.keepLocal) {
        return new Date(parseInt(year), (parseInt(month) - 1), parseInt(day), parseInt(hour), parseInt(min), parseInt(sec));
    } 
    return new Date(Date.UTC(parseInt(year), (parseInt(month) - 1), parseInt(day), parseInt(hour), parseInt(min), parseInt(sec)));
}
export const getFormattedDateFromDateString = function(dateString: string): string {
    const date = createDateFromDateString(dateString)
    return getFormattedLongDate(date)
}
const createDateFromDateString = function(dateString: string): Date {
    const { year, month, day } = getDateParts(dateString)
    return new Date(parseInt(year), (parseInt(month) - 1), parseInt(day))
}
const getDateParts = function (dateString: string): { year: string, month: string, day: string} {
    const [year, month, day] = dateString.split('-');
    return {year, month, day}
}
export const getDateTimeFromUnix = function (unixTimestamp: string, options: DateFunctionOptions = { timezone: undefined, noTimezone: false, keepLocal: false }): string {
    if (!unixTimestamp) return '';
    const date: Date = createDateFromUnix(unixTimestamp, { keepLocal: options.keepLocal });
    const dateOptions: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }
    if (!options.noTimezone) {
        dateOptions.timeZoneName =  'short'
        dateOptions.timeZone = options.timezone
    }
    return date.toLocaleString(i18n.language, dateOptions)
}
export const getDateTime = function (date: Date, options: DateFunctionOptions = {}): string {
    if (!date) return '';
    return date.toLocaleString(i18n.language, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short', timeZone: options.timezone || undefined })
}
export const getFormattedLongDate = function(date: Date): string {
    if (!date) return '';
    return date.toLocaleDateString(i18n.language, { 
        month: 'long', 
        day: 'numeric', 
        year: 'numeric' 
    });
}
const timeFormatter = function (dateVal: Date | any, options: DateFunctionOptions = { timezone: undefined, noTimezone: false }): string {
    if (!dateVal) return '';
    const date: Date = new Date(dateVal)
    const timeOptions: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
    }
    if (!options.noTimezone) {
        timeOptions.timeZoneName = 'short'
        timeOptions.timeZone = options.timezone
    };
    return date.toLocaleTimeString(i18n.language, timeOptions)
}
export const getFormattedDateFromUnix = function (unixTimestamp: string, options: DateFunctionOptions = { keepLocal: false }): string {
    if (!unixTimestamp) return '';
    const date = createDateFromUnix(unixTimestamp, options);
    return getFormattedLongDate(date)
}
export const getFormattedTimeFromUnix = function (unixTimestamp: string, options: DateFunctionOptions = { keepLocal: false, timezone: undefined }): string {
    if (!unixTimestamp) return '';
    const date = createDateFromUnix(unixTimestamp, options);
    return timeFormatter(date, options)
}
const getLongDate = function (date: Date, options: DateFunctionOptions = { timezone: undefined }): string[] {
    if (!date) return ['', '', ''];

    return [
        date.toLocaleDateString(i18n.language, { weekday: 'long' }),
        date.toLocaleDateString(i18n.language, { month: 'long', day: 'numeric', year: 'numeric' }),
        date.toLocaleTimeString(i18n.language, { hour: 'numeric', minute: 'numeric', timeZoneName: 'short', timeZone: options.timezone })
    ]
}
const getTimeZoneOffset = function (timeZone: string): number {
    const hoursHere = new Date().getHours();
    const hoursThere = new Date(new Date().toLocaleString('en-US', { timeZone: timeZone })).getHours();
    return hoursThere - hoursHere;
}
const getLocalMomentFromTimeStringWithTimezone = function(timeString: string, timezone: string): Moment {
    /*
        create date from timestring
        get timezone offset
        apply timezone offset to created date
        return date

        e.g.
        "2021-08-02 19:00:00" which is in "America/New_York"
    */
    const offset = getTimeZoneOffset(timezone);
    return moment(timeString).subtract(offset, 'hours')
}
const getDateFromTime = function(timeString: string): Date {
    if (!timeString) return new Date()
    const date = new Date();
    const timeParts: string[] = timeString.split(':')
    const [
        hour,
        minute,
        second,
    ]: number[] = timeParts.map((str: string) => parseInt(str))
    date.setHours(hour, minute, second);
    return date;
}
const getFormattedLongDateFromUnix = function (unixTimestamp: string): string[] {
    if (!unixTimestamp) return ['', '', ''];
    const date = createDateFromUnix(unixTimestamp);
    return getLongDate(date)
}
const getTimeParts = function (timeString: string): TimeParts {
    if (!timeString) return { hour: '', min: '', sec: '' }
    const time = timeString.split(':');
    const hour = time[0];
    const min = time[1];
    const sec = time[2];
    return { hour, min, sec }
}
export const getFormattedTimeFromTimeParts = function (timeString: string, options: DateFunctionOptions = {}): string {
    const timeParts = getTimeParts(timeString)
    if (!timeParts.hour || !timeParts.min || !timeParts.sec) return "";
    const date = new Date()
    date.setHours(parseInt(timeParts.hour))
    date.setMinutes(parseInt(timeParts.min))
    date.setSeconds(parseInt(timeParts.sec))
    return timeFormatter(date, options)
}
export const parseError = function (response: TPAPIReponse): string {
    if (!response) return 'Unknown error. Please contact a TruckPay representative.';
    console.log("GOT AN ERROR", response);
    let errString = '';
    if (response.message) {
        errString = response.message;
    } else if (typeof response == 'string') {
        errString = response;
    } else if (typeof response.error == 'string') {
        errString = response.error;
    } else if (response.data?.error && typeof response.data.error == 'string') {
        errString = response.data.error;
    } else if (response.data?.error && Array.isArray(response.data.error)) {
        console.log("this?!?!?!")
        errString = response.data.error.join(' ')
    } else {
        console.log("dynamic hanlding", response);
        const errors: string[] = [];
        Object.values(response.error).forEach(errVal => {
            if (Array.isArray(errVal)) {
                console.log("found array in error values", errVal)
                errVal.forEach(err => errors.push(err))
            } else {
                if (typeof errVal === 'string') {
                    errors.push(errVal)
                } else {
                    try {
                        Object.entries(errVal).forEach(entryArr => {
                            const key: any = entryArr[0];
                            const val: any = entryArr[1];
                            errors.push(val)
                        })
                    } catch (e) {
                        errors.push(errVal)
                    }
                }
            }
        })
        errString = errors.join(' ')
    }
    return errString;
}
export const chunk = function (arr: any[], size: number): any[] {
    if (!arr) return [];
    let newArr = [];
    for (let i = 0; i < arr.length; i += size) {
        newArr.push(arr.slice(i, i + size));
    }
    return newArr;
}
export const can = function (arr: CompanyPermission[], perm: string): boolean {
    if (arr) {
        return !!arr.find(p => p.name === perm);
    } else {
        return false;
    }
}
const capitalize = function(s: string): string {
    if (typeof s !== 'string') return '';
    const stringParts = s.split('_')
    return stringParts.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
}
export interface FormatNumberOptions {
    noDecimals?: boolean;
    forCapacities?: boolean;
    keepDecimals?: boolean;
}
export const formatNumber = function(initialNum: number | string, options: FormatNumberOptions = { noDecimals: false, forCapacities: false, keepDecimals: false }): string {
    if (initialNum === null || initialNum === undefined) return ''
    let finalNum = initialNum;
    if (typeof initialNum !== 'number') finalNum = parseFloat(initialNum)
    if (options.noDecimals) {
        return finalNum.toLocaleString(i18n.language)
    } else if (options.forCapacities) {
        return finalNum.toLocaleString(i18n.language, { maximumFractionDigits: 2 })
    } else if (options.keepDecimals) {
        return finalNum.toLocaleString(i18n.language)
    }
    return finalNum.toLocaleString(i18n.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}
const convertUnits = function (value: number, initialUnit: LengthUnit, newUnit: LengthUnit): number {
    switch (initialUnit) {
        case LengthUnits.METERS: {
            if (newUnit == LengthUnits.MILES) return value * 0.000621371;
            else if (newUnit == LengthUnits.KM) return value * 0.001;
            return value;
        }
        case LengthUnits.MILES: {
            if (newUnit == LengthUnits.METERS) return value * 1609.34;
            else if (newUnit == LengthUnits.KM) return value * 1.60934;
            return value;
        }
        case LengthUnits.KM: {
            if (newUnit == LengthUnits.METERS) return value * 1000;
            else if (newUnit == LengthUnits.MILES) return value * 0.621371;
            return value;
        } 
        default: {
            return value;
        }
    }
}
export const convertVolume = function (value: number, initialUnit: VolumeUnits, finalUnit: VolumeUnits): number {
    switch (initialUnit) {
        case VolumeUnits.CUBIC_METER: {
            if (finalUnit === VolumeUnits.CUBIC_YARD) return value * 1.308
            if (finalUnit === VolumeUnits.GAL) return value * 264.2
            if (finalUnit === VolumeUnits.LITER) return value * 1000
            if (finalUnit === VolumeUnits.FLUID_OUNCE) return value * 33810
            return value;
        }
        case VolumeUnits.CUBIC_YARD: {
            if (finalUnit === VolumeUnits.CUBIC_METER) return value / 1.308
            if (finalUnit === VolumeUnits.GAL) return value * 202
            if (finalUnit === VolumeUnits.LITER) return value * 764.6
            if (finalUnit === VolumeUnits.FLUID_OUNCE) return value * 25850
            return value;
        }
        case VolumeUnits.GAL: {
            if (finalUnit === VolumeUnits.CUBIC_METER) return value / 264.2
            if (finalUnit === VolumeUnits.CUBIC_YARD) return value / 202
            if (finalUnit === VolumeUnits.LITER) return value * 3.785
            if (finalUnit === VolumeUnits.FLUID_OUNCE) return value * 128
            return value;
        }
        case VolumeUnits.LITER: {
            if (finalUnit === VolumeUnits.CUBIC_METER) return value / 1000
            if (finalUnit === VolumeUnits.CUBIC_YARD) return value / 764.6
            if (finalUnit === VolumeUnits.GAL) return value / 3.785
            if (finalUnit === VolumeUnits.FLUID_OUNCE) return value * 33.814
            return value;
        }
        case VolumeUnits.FLUID_OUNCE: {
            if (finalUnit === VolumeUnits.CUBIC_METER) return value / 33810
            if (finalUnit === VolumeUnits.CUBIC_YARD) return value / 25850
            if (finalUnit === VolumeUnits.GAL) return value / 128
            if (finalUnit === VolumeUnits.LITER) return value / 33.814
            return value;
        }
        default: {
            return value
        }
    }
}
export const convertWeight = function(value: number, initialUnit: WeightUnits, finalUnit: WeightUnits): number {
    switch (initialUnit) {
        case WeightUnits.LB: 
            if (finalUnit === WeightUnits.KG) return value / 2.205
            if (finalUnit === WeightUnits.TON) return value / 2000
            if (finalUnit === WeightUnits.MT) return value / 2205
            return value;
        case WeightUnits.KG:
            if (finalUnit === WeightUnits.LB) return value * 2.205
            if (finalUnit === WeightUnits.TON) return value / 907.2
            if (finalUnit === WeightUnits.MT) return value / 1000
            return value;
        case WeightUnits.TON:
            if (finalUnit === WeightUnits.LB) return value * 2000
            if (finalUnit === WeightUnits.KG) return value * 907.2
            if (finalUnit === WeightUnits.MT) return value / 1.102
            return value;
        case WeightUnits.MT:
            if (finalUnit === WeightUnits.LB) return value / 2.205
            if (finalUnit === WeightUnits.KG) return value * 1000
            if (finalUnit === WeightUnits.TON) return value * 1.102
            return value;
        default: 
            return value
    }
}
export const getInitials = function(stringWithSpaces: string): string {
    if (!stringWithSpaces) return '';
    return stringWithSpaces.split(" ").map(str => str[0]).join("")
}
const convertTransactionTypeIDToName = function (transactionTypeID: number, job: Job, options: any = { forSupplier: false, forCustomer: false, forDestination: false }): string  {
    if (transactionTypeID != null) {
        let transactionTypeName = '';
        switch (transactionTypeID) {
            case 1:
                transactionTypeName = 'Load';
                break;
            case 2:
                transactionTypeName = 'Weight';
                if (options.forSupplier) {
                    transactionTypeName = job.company_supplier_per_weight_job_units;
                } else if (options.forDestination) {
                    transactionTypeName = job.demolition_destination_per_weight_job_units;
                } else if (options.forCustomer) {
                    transactionTypeName = job.company_customer_per_weight_job_units;
                } else {
                    transactionTypeName = job.per_weight_job_units;
                }
                break;
            case 3:
                transactionTypeName = 'Hour';
                break;
            case 4: 
                transactionTypeName = 'Volume';
                if (options.forSupplier) {
                    transactionTypeName = job.company_supplier_per_volume_job_units;
                } else if (options.forDestination) {
                    transactionTypeName = job.demolition_destination_per_volume_job_units;
                } else if (options.forCustomer) {
                    transactionTypeName = job.company_customer_per_weight_job_units;
                } else {
                    transactionTypeName = job.per_volume_job_units;
                }
                break;
        }
        return transactionTypeName;
    }
    return "";
}
export const formatPhoneNumber = function (number: string): string {
    if (number && number.length == 10) {
        if (number.match(/^\d{3}\d{3}\d{4}$/)) {
            return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`
        }
    } else if (number && number.length == 11) {
        if (number.match(/^\d{1}\d{3}\d{3}\d{4}$/)) {
            return `+${number.slice(0, 1)} (${number.slice(1, 4)}) ${number.slice(4, 7)}-${number.slice(7, 11)}`
        }
    }
    return number
}
const makeDate = function (dateObject: Date, timeString: string): string {
    if (typeof (timeString) !== 'undefined') {
        return toMysqlFormat(dateObject) + ' ' + timeString + ':00';
    } 
    return toMysqlFormat(dateObject);
}
export const toMysqlFormat = function (date: Date, options: DateFunctionOptions = { timeOnly: false, includeSeconds: false, dateOnly: false, toUTCValues: false }): string {
    let hour: string | number = options.toUTCValues ? date.getUTCHours() : date.getHours();
    let min: string | number = options.toUTCValues ? date.getUTCMinutes() : date.getMinutes();
    let sec: string | number = options.toUTCValues ? date.getUTCSeconds() : date.getSeconds();
    //Add the zero...
    if (hour < 10) {
        hour = '0' + hour;
    }
    if (min < 10) {
        min = '0' + min;
    }
    if (sec < 10) {
        sec = '0' + sec;
    }
    let finalTime = hour + ':' + min;
    if (options.includeSeconds) {
        finalTime += `:${sec}`
    }

    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    if (options.timeOnly) {
        return finalTime;
    } else if (options.dateOnly) {
        return year + '-' + month + '-' + day;
    }
    return `${year}-${month}-${day} ${hour}:${min}:${sec}`
}
export const makeUnixTime = function(date: Date): number {
    return Math.trunc(date.getTime() / 1000);
}
const convertSecondsToTime = function (seconds: number): Date {
    const fraction = seconds / (60 * 60);
    const hour = Math.floor(fraction);
    const min = (seconds % 3600) / 60;
    const sec = (seconds % 3600) % 60;
    const finalTime = new Date(1970, 0, 1, hour, min, sec);
    return finalTime;
}
export const parseGoogleAddressComponents = function (addressComponents: any, useLongName: boolean): SimpleAddress {
    let streetNumber = '';
    let unitedKingdomState = '';
    let unitedKingdomCity = '';
    let addressData: SimpleAddress = {
        address_line_1: '',
        city: '',
        province: '',
        postal_code: '',
        country: ''
    };
    if (!addressComponents) return addressData;
    addressComponents.forEach((component: any) => {
        switch (component.types[0]) {
            case 'street_number':
                streetNumber = useLongName ? component.long_name : component.short_name;
                break;

            case 'route':
                if (streetNumber) {
                    addressData.address_line_1 = streetNumber + ' ' + (useLongName ? component.long_name : component.short_name);
                } else {
                    addressData.address_line_1 = useLongName ? component.long_name : component.short_name;
                }
                break;

            case 'locality': 
            case 'sublocality_level_1':
            case 'sublocality': 
            case 'neighborhood':
                addressData.city = useLongName ? component.long_name : component.short_name;
                break;
            
            case 'administrative_area_level_1':
                addressData.province = useLongName ? component.long_name : component.short_name;
                break;

            case 'postal_code':
            case 'postal_code_prefix':
                addressData.postal_code = useLongName ? component.long_name : component.short_name;
                break;

            case 'postal_town':
                unitedKingdomState = useLongName ? component.long_name : component.short_name;
                break;

            case 'country':
                addressData.country = useLongName ? component.long_name : component.short_name;
                break;
        }
    });
    return addressData;
}
const getTransactionTypeDisplay = function(job: Job): string {
    switch (job.transaction_type.name) {
        case TransactionTypes.PER_HOUR: {
            return i18n.t(`jobTranslations.transactionTypes.perHour`)
        }
        case TransactionTypes.PER_LOAD: {
            return i18n.t(`jobTranslations.transactionTypes.perLoad`);
        }
        case TransactionTypes.PER_WEIGHT: {
            const string = job.per_weight_job_units ? `/ ${job.per_weight_job_units}` : i18n.t(`jobTranslations.transactionTypes.perWeight`)
            return string;
        }
        case TransactionTypes.PER_VOLUME: {
            const string = job.per_volume_job_units ? `/ ${job.per_volume_job_units}` : i18n.t(`jobTranslations.transactionTypes.perVolume`)
            return string;
        }
    }
    return ""
}
export const getPricingDisplay = function(job: Job, company: any, rate: any) {
    if (!job.transaction_type) return '';
    if (job.bid_amount_status == 'open') {
        switch (job.transaction_type.name) {
            case TransactionTypes.PER_HOUR: {
                return i18n.t(`jobTranslations.transactionTypes.per-hour`)
            }
            case TransactionTypes.PER_LOAD: {
                return i18n.t(`jobTranslations.transactionTypes.per-load`);
            }
            case TransactionTypes.PER_WEIGHT: {
                return `${i18n.t(`jobTranslations.transactionTypes.per-weight`)} (${i18n.t(`unitTranslations.weightUnits.${job.per_weight_job_units}`)})`;
            }
            case TransactionTypes.PER_VOLUME: {
                return `${i18n.t(`jobTranslations.transactionTypes.per-volume`)} (${i18n.t(`unitTranslations.volumeUnits.${job.per_volume_job_units}`)})`;
            }
            default: {
                return i18n.t(`jobTranslations.bid.open`)
            }
        }
    } else if (job.bid_amount_status == 'prenegotiated_only' && rate) {
        return `${job.currency_object && job.currency_object.symbol_prefix}${formatNumber(rate.rate / 100)}${job.currency_object.symbol_suffix || ' ' + job.currency_object.code} ${getTransactionTypeDisplay(job)}`
    } else if (job.bid_amount_status == 'prenegotiated_only') {
        return i18n.t(`jobTranslations.bid.prenegotiated_only`)
    } else if ((job.bid_amount_status == 'fixed') && job.company_id == company.id) {
        return `${job.currency_object && job.currency_object.symbol_prefix}${formatNumber(job.amount / 100)}${job.currency_object.symbol_suffix || ' ' + job.currency_object.code} ${getTransactionTypeDisplay(job)}`
    } else if ((job.bid_amount_status == 'fixed') && job.company_id != company.id) {
        return `${job.currency_object && job.currency_object.symbol_prefix}${formatNumber((job.reduced_amount || job.amount) / 100)}${job.currency_object.symbol_suffix || ' ' + job.currency_object.code} ${getTransactionTypeDisplay(job)}`
    } else {
        return ''
    }
}
const getBidPricingDisplay = function(job: Job, bid: Bid, company: Company) {
    return `${job.currency_object && job.currency_object.symbol_prefix}${formatNumber((bid.reduced_amount || bid.amount) / 100)}${job.currency_object.symbol_suffix || ' ' + job.currency_object.code} ${getTransactionTypeDisplay(job)}`

}
const mergeDateAndTime = function (date: Date, time: Date) {
    const tempDate = new Date(date);
    const hour = time.getHours();
    const min = time.getMinutes();
    tempDate.setHours(hour);
    tempDate.setMinutes(min);
    return tempDate;
}

const setupHandleChange = function(context: any) {
    return (field: string, type: string, singleNestedOptions: any) => {
        if (!singleNestedOptions) {
            if (type) {
                if (type == 'boolean') {
                    return (event: React.ChangeEvent<HTMLInputElement>) => context.setStateIfMounted({ [field]: event.target.checked })
                } else if (type == 'number') {
                    return (event: React.ChangeEvent<HTMLInputElement>) => context.setStateIfMounted({ [field]: parseFloat(event.target.value) || "" })
                } else if (type == 'date') {
                    return (date: Date) => context.setStateIfMounted({ [field]: date })
                } else if (type == 'time') {
                    return (time: Date) => context.setStateIfMounted({ [field]: time })
                } else if (type == 'radio-boolean') {
                    return (event: React.ChangeEvent<HTMLInputElement>) => context.setStateIfMounted({ [field]: event.target.value == 'true' ? true : false })
                }
                return (event: React.ChangeEvent<HTMLInputElement>) => context.setStateIfMounted({ [field]: event.target.value });
            }
            return (event: React.ChangeEvent<HTMLInputElement>) => context.setStateIfMounted({ [field]: event.target.value });
        } else if (singleNestedOptions) {
            if (type) {
                if (type == 'boolean') {
                    return (event: React.ChangeEvent<HTMLInputElement>) => context.setStateIfMounted({
                        ...context.state,
                        [singleNestedOptions.parent]: {
                            ...context.state[singleNestedOptions.parent],
                            [singleNestedOptions.child]: event.target.checked
                        }
                    })
                } else if (type == 'number') {
                    return (event: React.ChangeEvent<HTMLInputElement>) => context.setStateIfMounted({
                        ...context.state,
                        [singleNestedOptions.parent]: {
                            ...context.state[singleNestedOptions.parent],
                            [singleNestedOptions.child]: parseFloat(event.target.value) || ""
                        }
                    })
                } else if (type == 'date') {
                    return (date: Date) => {
                        context.setStateIfMounted({
                            ...context.state,
                            [singleNestedOptions.parent]: {
                                ...context.state[singleNestedOptions.parent],
                                [singleNestedOptions.child]: date
                            }
                        })
                    }
                } else if (type == 'time') {
                    return (time: Date) => {
                        context.setStateIfMounted({
                            ...context.state,
                            [singleNestedOptions.parent]: {
                                ...context.state[singleNestedOptions.parent],
                                [singleNestedOptions.child]: time
                            }
                        })
                    }
                }
            }
            return (event: React.ChangeEvent<HTMLInputElement>) => context.setStateIfMounted({
                ...context.state,
                [singleNestedOptions.parent]: {
                    ...context.state[singleNestedOptions.parent],
                    [singleNestedOptions.child]: event.target.value
                }
            });
        }
    }
}
//     usePrevious: function(value: any) {
//         const ref = useRef();
//         useEffect(() => {
//             ref.current = value;
//         });
//         return ref.current;
//     },
const wait = function(sec: number): Promise<void> {
    return new Promise((resolve, reject) => {
        setTimeout((): void => {
            resolve()
        }, sec * 1000);
    })
}
interface DriverETAParams {
    origin: {
        latitude: number;
        longitude: number;
    };
    destination: {
        latitude: number;
        longitude: number;
    };
    departureTime?: any;
}
const getDriverETA = function ({ origin, destination, departureTime }: DriverETAParams): Promise<any> {
    if (!origin?.latitude || !origin?.longitude || !destination?.latitude || !destination?.longitude) {
        return new Promise((resolve, rej) => {
            resolve({ status: "ERROR", results: []})
        })
    }
    
    return new Promise((resolve, rej) => {
        if ((window as any).google?.maps) {
            const maps = (window as any).google.maps
            const distanceMatrix = new maps.DistanceMatrixService()
            distanceMatrix.getDistanceMatrix({
                origins: [new maps.LatLng(origin.latitude, origin.longitude)],
                destinations: [new maps.LatLng(destination.latitude, destination.longitude)],
                travelMode: "DRIVING"
            }, (results: any, status: any) => {
                resolve({ status, results })
            })
        }
    })
}

export enum DeliveryStatuses {
    COMPLETED = "completed",
    LOADING = "loading",
    AWAITING_WEIGHMENT = "awaitingWeighment",
    DRIVING = "driving",
    UNLOADING = "unloading",
    VOIDED = "voided",
    CANCELED = "canceled"
}

export type DeliveryStatus = typeof DeliveryStatuses[keyof typeof DeliveryStatuses];

export const getDetailedDeliveryStatus = function (delivery: DeliveryApi): DeliveryStatus {
    if (delivery.is_voided) return DeliveryStatuses.VOIDED;
    if (delivery.is_canceled) return DeliveryStatuses.VOIDED;
    if (delivery.checkout) return DeliveryStatuses.COMPLETED;
    if (delivery.route.pick_up_scale_id) {
        if (delivery.checkin && !delivery.load_time_duration) return DeliveryStatuses.LOADING;
        if (delivery.load_time_duration && !delivery.gross_pounds) return DeliveryStatuses.AWAITING_WEIGHMENT;
        if (delivery.load_time_duration && !delivery.checkout) return DeliveryStatuses.DRIVING;
        if (delivery.checkout && !delivery.unload_time_duration) return DeliveryStatuses.UNLOADING;
    } else if (delivery.route.drop_off_scale_id) {
        if (delivery.checkin && !delivery.load_time_duration) return DeliveryStatuses.LOADING;
        if (delivery.load_time_duration && !delivery.gross_pounds) return DeliveryStatuses.AWAITING_WEIGHMENT;
        if (delivery.load_time_duration && !delivery.checkout) return DeliveryStatuses.DRIVING;
        if (delivery.checkout && !delivery.unload_time_duration) return DeliveryStatuses.UNLOADING;
    } else {
        if (delivery.checkin && !delivery.load_time_duration && !delivery.checkout) return DeliveryStatuses.LOADING;
        if (delivery.checkin && delivery.load_time_duration && !delivery.checkout) return DeliveryStatuses.DRIVING;
        if (delivery.checkout && !delivery.unload_time_duration) return DeliveryStatuses.UNLOADING;
    }
    return DeliveryStatuses.COMPLETED;
}
export const getDeliveryMetadata = function (delivery: DeliveryApi) {
    delivery.displayMaterials = delivery.materials.map(mat => mat.name ? mat.name : mat.custom_material_name).join(", ");
    if (delivery.load_time_duration) {
        const loadDate = convertSecondsToTime(delivery.load_time_duration)
        delivery.loadDuration = `${loadDate.getHours()}h ${loadDate.getMinutes()}m ${loadDate.getSeconds()}s`;
    }
    if (delivery.unload_time_duration) {
        const unloadDate = convertSecondsToTime(delivery.unload_time_duration)
        delivery.unloadDuration = `${unloadDate.getHours()}h ${unloadDate.getMinutes()}m ${unloadDate.getSeconds()}s`;
    }
    if (delivery.total_duration) {
        const durationDate = convertSecondsToTime(delivery.total_duration)
        delivery.formattedTotalDuration = `${durationDate.getHours()}h ${durationDate.getMinutes()}m`;
    } else if (delivery.checkin && delivery.checkout) {
        const millisecondDiff: number = (new Date(delivery.checkout).getTime()  - new Date(delivery.checkin).getTime())
        const durationDate = convertSecondsToTime(millisecondDiff / 1000)
        delivery.formattedTotalDuration = `${durationDate.getHours()}h ${durationDate.getMinutes()}m`;
    } else {
        delivery.formattedTotalDuration = null
    }

    delivery.detailedStatus = getDetailedDeliveryStatus(delivery);

    if (delivery.original_gross_weight || delivery.original_net_weight) {
        delivery.isReweighment = true;
    }
    delivery.checkinDate = delivery.checkin ? createDateFromUnix(delivery.checkin, { keepLocal: true }) : null
    delivery.checkoutDate = delivery.checkout ? createDateFromUnix(delivery.checkout, { keepLocal: true }) : null

    delivery.hasImagesToShow = Boolean(
        delivery.load_picture_url || 
        delivery.drop_picture_url ||
        delivery.checkin_signature_url || 
        delivery.checkout_signature_url ||
        delivery.driver_checkin_signature_url || 
        delivery.driver_checkout_signature_url ||
        delivery.scale_weight_image
    )
    return delivery
}

/**
 * Formats a snake case string into normal text
 * 
 * @param str 
 * Snake case string
 * 
 * @returns 
 * Normal reading convention string
 */
const formatToReadableString = function(str: string): string {
    return str.replace(/_/g, " ").toUpperCase()
}

export const isNotNullOrUndefined = (val: any) => {
    return val !== null && val !== undefined
}

export const isNotEmptyOrBlank = (val: any) => {
    if (typeof val !== 'string') return true
    return !!val?.length
}

const getFinalValue = (value: string | number): string => {
    if (typeof value === "boolean") {
        return value ? "1" : "0"
    } else {
        return value.toString()
    }
}

/**
 * Formats query params.\
 * Handles both a single value and lists of values for API use.\
 * Removes null/undefined values\
 * Removes empty string values
 * 
 * __Formats__
 * - key=value
 * - key[]=value&key=otherValue
 * 
 * @returns string
 */
export const formatParams = (options: { [key: string]: any | any[] }): string => {
    const params: string[] = []

    const isValidValue = (val: any) => {
        return isNotNullOrUndefined(val) && isNotEmptyOrBlank(val)
    }

    Object.keys(options).forEach(key => {
        if (Array.isArray(options[key])) {
            options[key].forEach((el: string | number | any, elIndex: number) => {
                if (typeof el === 'object') {
                    Object.keys(el).forEach((elKey: string) => {
                        if (!isValidValue(el[elKey])) return
                        params.push(`${key}[${elIndex}][${elKey}]=${getFinalValue(el[elKey])}`)
                    })
                } else if (isValidValue(el)){
                    params.push(`${key}[]=${getFinalValue(el)}`)
                }
            })
        } else if (isValidValue(options[key])) {
            params.push(`${key}=${getFinalValue(options[key])}`)               
        }
    })
    return params.join("&")
}
export const functions =  {
    getDateTimeFromUnix,
    getDateTime,
    getFormattedDateFromUnix,
    getFormattedTimeFromUnix,
    timeFormatter,
    getLongDate,
    getFormattedLongDate,
    getTimeZoneOffset,
    getLocalMomentFromTimeStringWithTimezone,
    getDateFromTime,
    getFormattedLongDateFromUnix,
    getTimestampDetails,
    createDateFromUnix,
    getTimeParts,
    parseError,
    chunk,
    can,
    capitalize,
    formatNumber,
    convertUnits,
    getInitials,
    convertTransactionTypeIDToName,
    formatPhoneNumber,
    makeDate,
    toMysqlFormat,
    makeUnixTime,
    convertSecondsToTime,
    parseGoogleAddressComponents,
    getTransactionTypeDisplay,
    getPricingDisplay,
    getBidPricingDisplay,
    mergeDateAndTime,
    setupHandleChange,
    wait,
    getDriverETA,
    getDetailedDeliveryStatus,
    getDeliveryMetadata,
    formatToReadableString,
    getFormattedDateFromDateString,
    createDateFromDateString,
    getDateParts,
}
export default (state = functions, action: any) => state;
