import { TPAPIBase } from "../../env";
import i18n from "../../i18n";
import { BidAmountStatuses, DistributiveOmit, FullJobMaterial, Job, JobQuery, } from "../reducers/entities";
import { formatParams, getFormattedDateFromDateString } from "../reducers/helpersReducerr";
import { BooleanParam, Paginate, PaginatedAPIOptions, TPResponse } from "./util";

// TODO finish transfering all apis

export const getJobMetadata = (job: Job): Job => {
    job.displayStartDate = getFormattedDateFromDateString(job.start_date)
    job.displayEndDate = getFormattedDateFromDateString(job.end_date)
    if (job.estimated_volume) {
        job.estimated_volume = parseFloat(job.estimated_volume.toFixed(2))
    }
    if (job.estimated_remaining_volume) {
        job.estimated_remaining_volume = parseFloat(job.estimated_remaining_volume.toFixed(2))
    }
    if (job.estimated_amount) {
        job.estimated_amount = parseFloat(job.estimated_amount.toFixed(2))
    }
    if (job.estimated_remaining_weight) {
        job.estimated_remaining_weight = parseFloat(job.estimated_remaining_weight.toFixed(2))
    }
    return job;
}
const handleNewGetJobAPIReturn = (res: TPResponse<Paginate<Job[]> | number[]>) => {
    if (res.success && !Array.isArray(res.data) && res.data.data) {
        res.data.data = res.data.data.map(getJobMetadata)
    }
    return res;
}

interface SharedGetJobOptions {
    is_four_way_job?: BooleanParam;
    is_demolition_job?: BooleanParam;
    is_removal_job?: BooleanParam;
    is_completed?: BooleanParam;
    is_current?: BooleanParam;
    has_delivery?: BooleanParam;
    has_in_progress_delivery?: BooleanParam;
    has_pending_bid?: BooleanParam;
    has_awarded_bid?: BooleanParam;
    has_active_bid?: BooleanParam;
    has_dispatch_order?: BooleanParam;

    custom_material_lists?: string[];
    custom_materials?: number[];

    sites?: number[];
    pickup_location_ids?: string[];
    dropoff_location_ids?: string[];

    created_at_range_start?: string;
    created_at_range_end?: string;
    start_date_range_start?: string;
    start_date_range_end?: string;
    end_date_range_start?: string;
    end_date_range_end?: string;

    with_extended_attribute_values?: BooleanParam;

    extended_attribute_filters?: {
        extended_job_type?: string;
        extended_attribute_name?: string;
        range_start?: string;
        range_end?: string;
    }[];

    has_bid_with_status?: string[];

    sort_by?: "job_number" | "start_date" | "end_date" | "created_at" | "opening_time" | "closing_time" | "bidding_ends";
    sort_order?: "asc" | "desc";
    extended_attribute_sort_by?: string;
    extended_attribute_sort_order?: "asc" | "desc";

    return_only_ids?: BooleanParam;

    filter_and_sort_by_next_dispatch_start_time_after?: string;
} 

export interface GetJobsCreatedByCompanyOptions extends PaginatedAPIOptions, SharedGetJobOptions {
    additional_selections?: string[];
}

export const getJobsCreatedByCompany = (token: string, companyID: number, options: GetJobsCreatedByCompanyOptions = {}): Promise<TPResponse<Paginate<Job[]> | number []>> => {
    const formattedParams = formatParams(options)
    return fetch(`${TPAPIBase}/company/${companyID}/jobs/created?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then(handleNewGetJobAPIReturn)
}
export interface GetJobsBeingWorkedOnByMyCompanyOptions extends PaginatedAPIOptions, SharedGetJobOptions {
    is_my_company_job?: BooleanParam;
}
export const getJobsBeingWorkedOnByMyCompany = (token: string, companyID: number, options: GetJobsBeingWorkedOnByMyCompanyOptions = {}): Promise<TPResponse<Paginate<Job[]> | number []>> => {
    const formattedParams = formatParams(options)
    return fetch(`${TPAPIBase}/company/${companyID}/jobs/working?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then(handleNewGetJobAPIReturn)
}
interface GetAllJobsBeingWorkedOnByMyCompanyOptions {
    token: string;
    companyId: number;
    options: GetJobsBeingWorkedOnByMyCompanyOptions
}
export const getAllJobsBeingWorkedOnByMyCompany = async ({
    token,
    companyId,
    options
}: GetAllJobsBeingWorkedOnByMyCompanyOptions): Promise<TPResponse<Job[]>> => {
    const jobs: Job[] = []
    let page = 0
    let doneFetching = false

    while (!doneFetching) {
        const response = await getJobsBeingWorkedOnByMyCompany(token, companyId, options)
        if (!response.success) {
            doneFetching = true
            return {
                ...response,
                success: false,
                data: jobs,
            }
        }

        // Gotta do this because I had no idea what I was doing when I set up the return type
        // The response format is paginated jobs as long as it's not an array (api can return just the job ids)
        if (!Array.isArray(response.data)) {
            jobs.push(...response.data.data)
            if (response.data.next_page_url) {
                page += 1
            } else {
                doneFetching = true
            }
        }
        break;
    }

    return {
        success: true,
        error: null,
        data: jobs
    }
}

interface GetDriverJobsOptions extends PaginatedAPIOptions, SharedGetJobOptions {
    is_my_company_job?: BooleanParam;
    is_assigned_to_me?: BooleanParam;
}
export const getDriverJobs = (token: string, companyID: number, options: GetDriverJobsOptions = {}): Promise<TPResponse<Paginate<Job[]> | number []>> => {
    const formattedParams = formatParams(options)
    return fetch(`${TPAPIBase}/company/${companyID}/jobs/driving?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json()).then(handleNewGetJobAPIReturn)
}


export const getCompanyOwnedJobsByQuery = (token: string, companyID: number, query: DistributiveOmit<JobQuery, "type">, options: PaginatedAPIOptions = {}): Promise<TPResponse<Paginate<Job[]>>> => {
    const formattedParams = formatParams(options)
    return fetch(`${TPAPIBase}/company/${companyID}/jobs/query?${formattedParams}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        },
        body: JSON.stringify({
            data: {
                query
            }
        })
    }).then(response => response.json()).then((res: TPResponse<Paginate<Job[]>>) => {
        const tempRes = { ...res };
        if (res.success && res.data && res.data.data.length) {
            tempRes.data.data = res.data.data.map(getJobMetadata)
        }
        return tempRes;
    })
}

export const getJobMaterials = (token: string, companyID: number, jobID: number, options: PaginatedAPIOptions = {}): Promise<TPResponse<Paginate<FullJobMaterial[]>>> => {
    const formattedParams = formatParams(options)
    return fetch(`${TPAPIBase}/company/${companyID}/job/${jobID}/materials?${formattedParams}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
            'x-auth-token': token,
        }
    }).then(response => response.json())
}
export const getAllJobMaterials = async (token: string, companyID: number, jobID: number): Promise<TPResponse<FullJobMaterial[]>> => {
    const materials = []
    let page = 1
    let fetchedAll = false
    while (!fetchedAll) {
        const response = await getJobMaterials(token, companyID, jobID)
        if (!response.success) {
            fetchedAll = true
            continue
        }
        materials.push(...response.data.data)
        if (!response.data.next_page_url) fetchedAll = true
        page += 1
    }
    return {
        success: true,
        error: null,
        data: materials
    }
}