import * as AdminAPI from '../util/adminAPI';
import i18n from '../../i18n';

import { popAlert } from './sessionActions'
export const RECEIVE_VALID_UNITS = "RECEIVE_VALID_UNITS";
export const RECEIVE_CURRENCIES = "RECEIVE_CURRENCIES";
export const RECEIVE_EQUIPMENT = "RECEIVE_EQUIPMENT";
export const RECEIVE_SUPPORTED_COUNTRIES = "RECEIVE_SUPPORTED_COUNTRIES";
export const RECEIVE_MATERIALS = "RECEIVE_MATERIALS";

export const receiveValidUnits = (units) => ({
    type: RECEIVE_VALID_UNITS,
    units
})
export const receiveCurrencies = (currencies) => ({
    type: RECEIVE_CURRENCIES,
    currencies
})
export const receiveEquipment = (equipment) => ({
    type: RECEIVE_EQUIPMENT,
    equipment
})
export const receiveSupportedCountries = (countries) => ({
    type: RECEIVE_SUPPORTED_COUNTRIES,
    countries
})
export const receiveMaterials = (materials) => ({
    type: RECEIVE_MATERIALS,
    materials
})

export const getValidUnits = (token) => dispatch => {
    return AdminAPI.getValidUnits(token).then(
        (res) => {
            if (res.length && res.weight && res.volume) {
                dispatch(receiveValidUnits(res))
            }
            return res
        },
        (errors) => {
            dispatch(popAlert('error', i18n.t(`error`), errors.responseJSON))
        }
    )
}
export const getCurrencies = (token) => dispatch => {
    return AdminAPI.getCurrencies(token).then(
        (res) => {
            if (res.data) {
                dispatch(receiveCurrencies(res.data))
            }
            return res
        },
        (errors) => {
            dispatch(popAlert('error', i18n.t(`error`), errors.responseJSON))
        }
    )
}
export const getEquipment = (token) => dispatch => {
    return AdminAPI.getEquipment(token).then(
        (res) => {
            if (res.data) {
                dispatch(receiveEquipment(res.data))
            }
            return res
        },
        (errors) => {
            dispatch(popAlert('error', i18n.t(`error`), errors.responseJSON))
        }
    )
}
export const getSupportedCountries = (token) => dispatch => {
    return AdminAPI.getSupportedCountries(token).then(
        (res) => {
            if (res.data) {
                dispatch(receiveSupportedCountries(res.data))
            }
            return res
        },
        (errors) => {
            dispatch(popAlert('error', i18n.t(`error`), errors.responseJSON))
        }
    )
}
export const getMaterials = (token) => dispatch => {
    return AdminAPI.getMaterials(token).then(
        (res) => {
            if (res.data) {
                dispatch(receiveMaterials(res.data))
            }
            return res
        },
        (errors) => {
            dispatch(popAlert('error', i18n.t(`error`), errors.responseJSON))
        }
    )
}
