import React, { useEffect } from 'react';
import {
    Snackbar,
    Typography,
} from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import { connect, useDispatch, useSelector } from 'react-redux';
import { unpopAlert } from '../../../redux/actions/sessionActions';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}


const NotificationSnackbar = () => {
    const alerts = useSelector(state => state.session.alerts)
    const dispatch = useDispatch()
    const closeAlert = () => dispatch(unpopAlert);

    // if (!alerts.alert) return <></>;

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            open={alerts.alert}
            message={alerts.alertMessage}
        >
            <Alert
                severity={alerts.alertType}
                onClose={closeAlert}
            >
                <Typography variant="h6">
                    {alerts.alertTitle}
                </Typography>
                <Typography>
                    {alerts.alertMessage}
                </Typography>
            </Alert>
        </Snackbar>
    )

}

export default NotificationSnackbar