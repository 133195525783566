import React, { useEffect } from 'react';
import styled from "styled-components";
import { connect, useDispatch, useSelector } from 'react-redux';
import { withTranslation, useTranslation } from 'react-i18next';
import {
    Box,
    Paper as MuiPaper,
    TableCell,
    TableRow,
    Typography,
    Button,
    Checkbox
} from "@material-ui/core";
import { getSpecificSite } from '../../../../redux/actions/siteActions';
import CarIcon from '@material-ui/icons/DirectionsCar';
import { spacing } from "@material-ui/system";
import { Link, useHistory } from 'react-router-dom';
import { getPricingDisplay } from '../../../../redux/reducers/helpersReducerr';

const Bold = styled.span`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
`;
const BoldTypography = styled(Typography)`
    font-weight: ${props => props.theme.typography.fontWeightBold}
`;
const Orange = styled.span`
  color: ${props => props.theme.palette.primary.main};
`;

function getEquipmentDisplay(job, t) {
    return job.equipment.length > 1 ? t('vehicleTranslations.variedEquipment') : `${job.equipment[0].name}(s)`;
}

function getEmptyJobListMessage(jobType, t) {
    switch(jobType) {
        case "currentJobs": {
            return "No current jobs";
        }
        case "nearbyJobs": {
            return "No nearby jobs";
        }
        case "pendingJobs": {
            return "No pending jobs";
        }
        case "pastJobs": {
            return "No past jobs";
        }
        case "inProgressJobs": {
            return "No in progress jobs";
        }
        case "externalJobs": {
            return "No external jobs";
        }
        default: {
            return "No jobs";
        }
    }
}
export function EmptyJobListRow({ }) {
    const { t } = useTranslation();
    return (
        <TableRow
            hover
            tabIndex={-1}
        >
            <TableCell align="left" colSpan={4}>
                <Typography variant="h5">
                    <Bold>
                        {t('jobTranslations.noJobs')}
                    </Bold>
                </Typography>
            </TableCell>
        </TableRow>
    )
}

export function MainJobDetailsCell({ job, company, forExport, onClick = () => {} }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const token = useSelector(state => state.session.user.token)
    const site = useSelector(state => state.entities.sitesMap[job.site_id])

    // useEffect(() => {
    //     if (!site) {
    //         dispatch(getSpecificSite(token, job.site_id))
    //     }
    // }, [])

    return (
        <TableCell align="left" onClick={onClick}>
            <Typography variant="h5">
                <Bold>
                    {job.name}
                </Bold>
            </Typography>
            {site && <Typography>
                {site.site_name}
            </Typography>}
            <Typography>
                {job.equipment && (<><Orange>{getEquipmentDisplay(job, t)}</Orange> | </>)}{!forExport && `(${job.quantity - job.remaining_spots} of ${job.quantity})`} | {`${job.displayStartDate} - ${job.displayEndDate}`}
            </Typography>
            {(job.company_id == company.id && !forExport) ? <>
                {job.open_bids == 1 && (
                    <BoldTypography variant="subtitle2">
                        <Orange>{t('jobTranslations.singleBidMessage')}</Orange>
                    </BoldTypography>
                )}
                {job.open_bids > 1 && (
                    <BoldTypography variant="subtitle2">
                        <Orange>{t('jobTranslations.multiBidMessage1')} {job.open_bids} {t('jobTranslations.multiBidMessage2')}</Orange>
                    </BoldTypography>
                )}
            </> : null}

        </TableCell>
    )
}


function JobListRow({ job, forExport = false, isSelected = false, handleSelect = () => {} }) {
    const { t } = useTranslation();
    const history = useHistory()
    const company = useSelector(state => state.session.company)
    return (
        <TableRow
            hover
            tabIndex={-1}
            role={forExport ? 'checkbox' : undefined}
        >
            {forExport && <TableCell align="left" padding="checkbox">
                <Checkbox
                    checked={isSelected}
                    // inputProps={{ 'aria-labelledby': labelId }}
                    onClick={() => handleSelect(job)}
                />
            </TableCell>}
            <MainJobDetailsCell job={job} company={company}/>
            <TableCell align="center">
                <Typography>
                    {job.privacy_status != 'private' && getPricingDisplay(job, company)}
                </Typography>
            </TableCell>
            {!forExport && <TableCell align="right">
                <Link to={`/jobs/details/${job.id}`} 
                // target="_blank" rel="noopener noreferrer" 
                style={{ textDecoration: "none" }}>
                    <Button 
                        startIcon={<CarIcon />}
                        variant="contained"
                        color="primary"
                    >
                        {t('jobTranslations.viewJob')}
                    </Button>
                </Link>
            </TableCell>}
        </TableRow>
    )
}
const msp = state => ({
    helpers: state.helpers
})
const mdp = dispatch => ({
    
})
export default connect(msp, mdp)(JobListRow)