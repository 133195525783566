import { Dispatch } from "redux"
import { CustomMaterial, CustomMaterialMap } from "../reducers/entities";
import * as MaterialAPI from '../util/materialAPI'

export const RECEIVE_MATERIAL_TAGS = "RECEIVE_MATERIAL_TAGS"
export const RECEIVE_CUSTOM_MATERIALS = "RECEIVE_CUSTOM_MATERIALS";
export const UPSERT_CUSTOM_MATERIAL = "UPSERT_CUSTOM_MATERIAL";

export const receiveMaterialTags = (tags: string[]) => ({
    type: RECEIVE_MATERIAL_TAGS,
    tags
})
export const receiveCustomMaterials = (customMaterials: CustomMaterialMap) => ({
    type: RECEIVE_CUSTOM_MATERIALS,
    customMaterials
})
export const upsertCustomMaterial = (customMaterial: CustomMaterial) => ({
    type: UPSERT_CUSTOM_MATERIAL,
    customMaterial
})

export const getAvailableTagsForCustomMaterials = (token: string) => (dispatch: Dispatch) => {
    return MaterialAPI.getAvailableTagsForCustomMaterials(token).then(res => {
        if (res.success) {
            dispatch(receiveMaterialTags(res.data))
        }
    })
}
export const getAllCustomMaterials = (token: string, companyID: number) => (dispatch: Dispatch) => {
    return MaterialAPI.getAllCustomMaterials(token, companyID).then(res => {
        if (res.success) {
            dispatch(receiveCustomMaterials(res.data))
        }
    })
}