import React from 'react';
import {
    Modal,
    Tab,
    Tabs,
    TabContent,
    TabScrollButton,
    TabList,
    Paper as MuiPaper,
    AppBar,
    Toolbar,
    Typography as MuiTypography,
    Grid as MuiGrid,
    FormGroup,
    FormControl as MuiFormControl,
    FormControlLabel,
    FormLabel,
    FormHelperText,
    Input,
    InputLabel,
    Select,
    TextField,
    MenuItem,
    Button,
    Switch,
    Radio,
    RadioGroup,
    Divider,
    InputAdornment,
    Box as MuiBox,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next'
import StarRatings from 'react-star-ratings';
import { DropzoneArea } from 'material-ui-dropzone'
import { connect } from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import WorldIcon from '@material-ui/icons/Language';
import { TPLoader } from '../../../components/Progress';
import {
    KeyboardDatePicker,
    KeyboardTimePicker
} from "@material-ui/pickers";
import GoogleMapReact from 'google-map-react'
import { GMapAPIKey } from '../../../../env'
import { popAlert } from '../../../../redux/actions/sessionActions';
import { getCompanySites } from '../../../../redux/actions/siteActions';
import {
    getSupportedCountries,
} from '../../../../redux/actions/adminActions';
import { getSiteTypes, createSite, editSite, getSite, addSiteMetadata } from '../../../../redux/util/siteAPI';
import { spacing } from "@material-ui/system";
import NotificationSnackbar from '../../Util/Snackbar';
import styled from "styled-components";
import { CustomTextfield, CustomPhoneNumberInput, getPhoneData } from '../../Util/PhoneInput';
import { TPResponse } from '../../../../redux/util/util';
import { Site } from '../../../../redux/reducers/entities';
import './styles.css';

const Paper = styled(MuiPaper)(spacing);
const Typography = styled(MuiTypography)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Box = styled(MuiBox)(spacing);
const Bold = styled.span`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: black;
`;

const GoogleMapReactWrapper = styled.div`
  height: 300px;
  width: 100%;
`;
function SectionHeader({ title }) {
    return (<>
        <Typography variant="h2" mb={4}><Bold>{title}</Bold></Typography>
        <Divider />
    </>
    )
}
class CreateEditSiteModal extends React.Component {
    nullState = {
        site_name: "",
        location_id: "",
        start_date: new Date(),
        end_date: new Date(),
        opening_time: new Date(),
        closing_time: new Date(),
        internet_quality: 5,
        electricity: true,
        address_line_1: "",
        city: "",
        province: "",
        postal_code: "",
        latitude: "",
        longitude: "",
        country_id: "",
        company_id: "",
        site_employees: [],
        type: "",
        siteTypes: [],
        is_public: false,
        loading: true,
        displayCoordinates: false,
        file: "",
        warnedBadAddressInput: false,
        bypass_address_verification: false,

        emergency_phone: '',
        epa_id: '',

        addContactInfo: false,

        address_company_name: "",
        address_contact_email: "",
        address_contact_first_name: "",
        address_contact_last_name: "",
        address_contact_phone: "",
        address_contact_phone_country_code: "",

        has_automated_batching: true,
        canHaveAutomatedBatching: false,

        sameAddressForMailing: true,

        mailing_address: {
            address_company_name: "",
            address_contact_email: "",
            address_contact_first_name: "",
            address_contact_last_name: "",
            address_contact_phone: "",
            address_contact_phone_country_code: "",
            country_id: "",
            address_line_1: "",
            city: "",
            province: "",
            postal_code: "",
            latitude: "",
            longitude: ""
        }
    }
    constructor(props) {
        super(props);
        this.today = new Date();
        this.state = { 
            ...this.nullState,
            address_company_name: props.company.name
        }
        this.addressInput = React.createRef();
        this.mailingAddressInput = React.createRef();
    }

    prefillState = async ({ siteTypes }) => {
        if (this.props.site) {
            let site;

            // Fetch site 
            try {
                /** @type {TPResponse<Site>} */
                const response = await getSite(this.props.token, this.props.site.id)
                if (!response.success) throw new Error("Couldn't get site details")
                site = addSiteMetadata(response.data)
            } catch (e) {
                return;
            }
            console.log("site", site)

            const prefilledState = { ...this.nullState }
            Object.keys(site).forEach(siteKey => {
                if (prefilledState.hasOwnProperty(siteKey) && site[siteKey]) {
                    prefilledState[siteKey] = site[siteKey];
                }
            })
            if (site.address) {
                Object.keys(site.address).forEach(siteAddressKey => {
                    if (prefilledState.hasOwnProperty(siteAddressKey) && site.address[siteAddressKey]) {
                        prefilledState[siteAddressKey] = site.address[siteAddressKey];
                    }
                })
            }
            if (site.mailing_address) {
                Object.keys(site.mailing_address).forEach(siteMailingAddressKey => {
                    if (prefilledState.hasOwnProperty(siteMailingAddressKey) && site.mailing_address[siteMailingAddressKey]) {
                        prefilledState.mailing_address[siteMailingAddressKey] = site.mailing_address[siteMailingAddressKey];
                    }
                })
            }
            // Site properties that can't be set programatically
            const override = {
                siteTypes: siteTypes,
                type: siteTypes.find(type => type.name == site.type),
                start_date: site.startDateObject,
                opening_time: site.startDateObject,
                end_date: site.endDateObject,
                closing_time: site.endDateObject,
                emergency_phone: `+${site.emergency_phone_country_code}${site.emergency_phone}`,
                address_contact_phone: `+${site.address.address_contact_phone_country_code}${site.address.address_contact_phone}`,
            }
            if (this.props.supportedCountries.find(country => country.id == site.address.country_id)) {
                override.country_id = this.props.supportedCountries.find(country => country.id == site.address.country_id).id;
            }
            if (!(prefilledState.address_line_1 && prefilledState.city && prefilledState.province)) {
                override.displayCoordinates = true;
            }
            if (site.mailing_address) {
                // if (this.props.supportedCountries.find(country => country.id == site.mailing_address.country_id)) {
                //     override.mailing_address.country_id = this.props.supportedCountries.find(country => country.id == site.address.country_id).id;
                // }
                override.sameAddressForMailing = prefilledState.address_line_1 == site.mailing_address.address_line_1 &&
                    prefilledState.city == site.mailing_address.city &&
                    prefilledState.province == site.mailing_address.province &&
                    prefilledState.postal_code == site.mailing_address.postal_code
                
            }

            const finalState = {
                ...prefilledState,
                ...override,
                loading: false,
            }
            console.log("final", finalState)
            this.setStateIfMounted(finalState)
        } else {
            this.setStateIfMounted({
                ...this.nullState,
                loading: false
            })
        }
    }
    componentDidMount = () => {
        this.mounted = true;
    }
    getInitialData = () => {
        Promise.all([
            getSiteTypes(this.props.token),
            this.props.getSupportedCountries(),
        ]).then(resArray => {
            const newState = {
                siteTypes: [],
            }
            if (resArray[0].data) {
                newState.siteTypes = resArray[0].data
                console.log(resArray)
            } 
            if (this.props.site && this.props.type == 'edit') {
                this.prefillState(newState)
            } else {
                this.setStateIfMounted({...newState, loading: false})
            }
        })
    }
    componentWillUnmount = () => {
        this.mounted = false;
    }
    componentDidUpdate = (prevProps, prevState) => {

        if (prevState.displayCoordinates != this.state.displayCoordinates && this.state.displayCoordinates) {
            this.connectGoogleAutocomplete()
        }
        if (prevState.sameAddressForMailing != this.state.sameAddressForMailing && !this.state.sameAddressForMailing) {
            this.connectGoogleAutocomplete()
        }
        if (
            (prevState.latitude != this.state.latitude || prevState.longitude != this.state.longitude)
            && (this.state.latitude && this.state.longitude)
        ) {
            this.updateMarkerPosition()
            if (this.state.displayCoordinates) {
                this.geocodeNewPosition({ lat: parseFloat(this.state.latitude), lng: parseFloat(this.state.longitude) })
            }
        }
        if (prevState.country_id != this.state.country_id && this.state.country_id) {
            this.updateAutocompleteRestriction();
        }
        if (prevState.mailing_address.country_id != this.state.mailing_address.country_id && this.state.mailing_address.country_id) {
            this.updateAutocompleteRestriction();
        }
        if (prevState.type != this.state.type && this.state.type) {
            this.setStateIfMounted({ canHaveAutomatedBatching: ['asphalt-plant', 'grain-silo', 'ready-mix-plant'].includes(this.state.type.name) })
        }
    }
    setStateIfMounted = (state, callback) => {
        if (this.mounted) {
            this.setState(state, callback)
        }
    }
    handleChange = this.props.helpers.setupHandleChange(this)
    handleRatingChange = newRating => {
        this.setStateIfMounted({
            internet_quality: newRating
        })
    }
    handleFileChange = files => {
        this.setStateIfMounted({
            file: files[0]
        })
    }
    handleApiLoaded = (map, maps) => {
        this.map = map;
        this.maps = maps;
        this.connectGoogleAutocomplete()
        this.getInitialData()
    }
    getMapOptions = maps => {
        return {
            fullscreenControl: true,
            mapTypeControl: true,
            mapTypeId: maps.MapTypeId.ROADMAP,
            scaleControl: true,
            scrollwheel: false,
            streetViewControl: true
        };
    };
    updateAutocompleteRestriction = () => {
        const selectedCountry = this.props.supportedCountries.find(country => country.id == this.state.country_id)
        if (selectedCountry && this.addressAutocomplete) {
            this.addressAutocomplete.setComponentRestrictions({
                country: selectedCountry.code
            })
        }
        const selectedMailingCountry = this.props.supportedCountries.find(country => country.id == this.state.mailing_address.country_id)
        if (selectedMailingCountry && this.mailingAddressAutocomplete) {
            this.mailingAddressAutocomplete.setComponentRestrictions({
                country: selectedMailingCountry.code
            })
        }
    }
    connectGoogleAutocomplete = () => {
        if (this.addressInput.current && this.maps && this.maps.places) {
            if (!this.addressAutocomplete) {
                this.addressAutocomplete = new this.maps.places.Autocomplete(this.addressInput.current)
                this.autoCompleteListener = this.addressAutocomplete.addListener('place_changed', () => {
                    const selection = this.addressAutocomplete.getPlace()
                    if (selection) {
                        this.handleAddressSelect(selection.address_components, selection.geometry.location)
                    }
                })

                const possibleContainers = document.getElementsByClassName('pac-container');
                if (possibleContainers[0]) {
                    this.googleAutocompleteWidget = possibleContainers[0];
                    this.googleAutocompleteWidget.style.zIndex = "1301";
                }

            }
        }
        if (this.mailingAddressInput.current && this.maps && this.maps.places) {
            if (!this.mailingAddressAutocomplete) {
                this.mailingAddressAutocomplete = new this.maps.places.Autocomplete(this.mailingAddressInput.current)
                this.mailingAutoCompleteListener = this.mailingAddressAutocomplete.addListener('place_changed', () => {
                    const selection = this.mailingAddressAutocomplete.getPlace()
                    if (selection) {
                        this.handleAddressSelect(selection.address_components, selection.geometry.location, true)
                    }
                })

                const possibleContainers = document.getElementsByClassName('pac-container');
                if (possibleContainers[0]) {
                    possibleContainers[0].style.zIndex = "1301";
                }
                if (possibleContainers[1]) {
                    possibleContainers[1].style.zIndex = "1301";
                }
            }
        }
    }
    handleAddressSelect = (addressComponents, coordinates, forMailing) => {
        const parsedAddress = this.props.helpers.parseGoogleAddressComponents(addressComponents, true)
        if (forMailing) {
            this.setStateIfMounted({
                mailing_address: {
                    ...this.state.mailing_address,
                    ...parsedAddress,
                    latitude: coordinates.lat(),
                    longitude: coordinates.lng(),
                }
            })
        } else {
            this.setStateIfMounted({
                ...parsedAddress,
                latitude: coordinates.lat(),
                longitude: coordinates.lng(),
            })
        }
    }
    handleMapClick = ({ x, y, lat, lng, event }) => {
        const newPosition = new this.maps.LatLng(lat, lng)
        if (this.map && this.maps) {
            this.geocodeNewPosition(newPosition, true)
        }
    }
    geocodeNewPosition = (latLng, resetCoords) => {
        console.log("geocoding new position")
        const geocoder = new this.maps.Geocoder();
        geocoder.geocode({ latLng }, (results, status) => {
            if (results.length) {
                const result = results[0];
                const parsedAddress = this.props.helpers.parseGoogleAddressComponents(result.address_components, true)
                const newState = {
                    ...parsedAddress
                }
                if (resetCoords) {
                    newState.latitude = result.geometry.location.lat()
                    newState.longitude = result.geometry.location.lng()
                }
                this.setStateIfMounted({ ...newState })
            }
        })
    }
    updateMarkerPosition = () => {
        if (this.map && this.maps) {
            const newPosition = new this.maps.LatLng(this.state.latitude, this.state.longitude)
            if (!this.marker) {
                this.marker = new this.maps.Marker({
                    position: newPosition,
                    map: this.map,
                    animation: this.maps.Animation.DROP
                })
            } else {
                this.marker.setPosition(newPosition);
            }
            this.map.setCenter(newPosition);
        }

    }
    
    organizeSitePostData = async () => {
        const postData = {
            site_name: this.state.site_name,
            location_id: this.state.location_id,
            internet_quality: this.state.internet_quality,
            address_line_1: this.state.address_line_1,
            city: this.state.city,
            province: this.state.province,
            postal_code: this.state.postal_code,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            country_id: this.state.country_id,
            company_id: this.props.company.id,
            type: this.state.type.name,
            is_public: this.state.is_public ? 1 : 0,
            start_date: this.props.helpers.toMysqlFormat(this.state.start_date, { dateOnly: true }),
            end_date: this.props.helpers.toMysqlFormat(this.state.end_date, { dateOnly: true }),
            opening_time: this.props.helpers.toMysqlFormat(this.state.opening_time, { timeOnly: true }),
            closing_time: this.props.helpers.toMysqlFormat(this.state.closing_time, { timeOnly: true }),
            electricity: this.state.electricity ? 1 : 0,
            bypass_address_verification: this.state.bypass_address_verification ? 1 : 0,
            has_automated_batching: this.state.has_automated_batching ? 1 : 0,
            address_company_name: this.state.address_company_name,
            address_contact_phone: this.state.address_contact_phone,
        }
        if (this.state.canHaveAutomatedBatching) {
            postData.has_automated_batching = this.state.has_automated_batching ? 1 : 0
        }
        if (this.state.emergency_phone) {
            const phoneData = getPhoneData(this.state.emergency_phone)
            postData.emergency_phone = phoneData.phone
            postData.emergency_phone_country_code = phoneData.countryCode
        }
        if (this.state.address_contact_phone) {
            const phoneData = getPhoneData(this.state.address_contact_phone)
            postData.address_contact_phone = phoneData.phone
            postData.address_contact_phone_country_code = phoneData.countryCode
        }
        if (this.state.epa_id) {
            postData.epa_id = this.state.epa_id
        }
        if (this.state.sameAddressForMailing) {
            postData.mailing_address = {
                country_id: this.state.country_id,
                address_line_1: this.state.address_line_1,
                city: this.state.city,
                province: this.state.province,
                postal_code: this.state.postal_code,
                latitude: this.state.latitude,
                longitude: this.state.longitude,
            }
        } else {
            postData.mailing_address = {
                country_id: this.state.mailing_address.country_id,
                address_line_1: this.state.mailing_address.address_line_1,
                city: this.state.mailing_address.city,
                province: this.state.mailing_address.province,
                postal_code: this.state.mailing_address.postal_code,
                latitude: this.state.mailing_address.latitude,
                longitude: this.state.mailing_address.longitude,
            }
        }
        const formData = new FormData();
        formData.append('data[site_employees][0]', this.props.userID)
        Object.keys(postData).forEach(key => {
            if (postData[key] !== "" && postData[key] !== null) {
                if (typeof postData[key] == 'object') {
                    Object.keys(postData[key]).forEach(subkey => {
                        formData.append(`data[${key}][${subkey}]`, postData[key][subkey])
                    })
                } else {
                    formData.append(`data[${key}]`, postData[key])
                }
            }
        })
        if (this.state.file) {
            formData.append('data[image]', this.state.file)
        }
        const postType = this.props.type;
        if (postType == 'create') {
            createSite(this.props.token, formData).then(res => {
                if (res.success) {
                    this.props.popAlert('success', this.props.t('success'), this.props.t('siteTranslations.success.createSite'))
                    this.props.getCompanySites(this.props.token, this.props.company.id)
                    this.props.setModalOpen(false)
                } else {
                    this.setStateIfMounted({
                        loading: false,
                    }, () => this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res.error)))
                }
            })
        } else if (postType == 'edit') {
            editSite(this.props.token, this.props.site.id, formData).then(res => {
                if (res.success) {
                    this.props.popAlert('success', this.props.t('success'), this.props.t('siteTranslations.success.editSite'))
                    this.props.getCompanySites(this.props.token, this.props.company.id)
                    this.props.setModalOpen(false)
                } else {
                    this.setStateIfMounted({
                        loading: false,
                    }, () => this.props.popAlert('error', this.props.t('error'), this.props.helpers.parseError(res.error)))
                }
            })
        }

    }
    verifyAddress = async () => {
        if (this.state.warnedBadAddressInput) {
            this.organizeSitePostData();
        }
        if (!this.state.displayCoordinates && this.maps && (this.state.address_line_1 && this.state.city && this.state.province)) {
            const geocoder = new this.maps.Geocoder();
            await geocoder.geocode({ address: `${this.state.address_line_1} ${this.state.city} ${this.state.province} ${this.state.postal_code}` }, (results, status) => {
                if (status == this.maps.GeocoderStatus.OK && results.length) {
                    this.organizeSitePostData();
                } else {
                    this.setStateIfMounted({
                        warnedBadAddressInput: true,
                        loading: false,
                        bypass_address_validation: true,
                    }, () => {
                        this.props.popAlert('warning', this.props.t('badAddress'), this.props.t('siteTranslations.errors.badAddress'))
                    })
                    return false;
                }
            })
        } else {
            this.organizeSitePostData();
        }
    }
    getDisplayType = (isLoader) => {
        if (isLoader) {
            return this.state.loading ? "" : "none";
        } else {
            return this.state.loading ? "none" : "";
        }
    }
    submitSite = event => {
        event.preventDefault();
        console.log("submitting!")
        this.setStateIfMounted({
            loading: true,
        }, () => this.verifyAddress())
    }
    render = () => {
        return (
            <Modal open={true} style={{ overflowY: 'scroll' }}>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} sm={8} md={7} lg={5}>
                        <Paper m={6}>
                            <Box borderBottom={1} borderColor="grey.500">
                                <Grid container spacing={5} style={{ width: '100%', margin: 0, backgroundColor: "rgba(0,0,0,0.05)" }} direction="row" justify="space-between" alignItems="center">
                                    <Grid item>
                                        {this.props.type == 'create' && <Typography variant="h3">{this.props.t('siteTranslations.actions.createSite')}</Typography>}
                                        {this.props.type == 'edit' && <Typography variant="h3">{this.props.t('siteTranslations.actions.editSite')}</Typography>}
                                    </Grid>
                                    <Grid item>
                                        <CloseIcon onClick={() => this.props.setModalOpen(false)} />
                                    </Grid>
                                </Grid>
                            </Box>
                            {/* <Box style={{ display: this.getDisplayType(true) }}>
                                <TPLoader />
                            </Box> */}

                            <Box p={5}>
                                <form autoComplete="off" onSubmit={(event) => this.submitSite(event)}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="location_id"
                                                    label={"Location ID"}
                                                    value={this.state.location_id}
                                                    onChange={this.handleChange("location_id")}
                                                    type="text"
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="site_name"
                                                    label={this.props.t('siteTranslations.siteName')}
                                                    value={this.state.site_name}
                                                    onChange={this.handleChange("site_name")}
                                                    type="text"
                                                    required
                                                    error={!this.state.site_name}
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="address_company_name"
                                                    label={"Site Company Name"}
                                                    value={this.state.address_company_name}
                                                    onChange={this.handleChange("address_company_name")}
                                                    type="text"
                                                    required
                                                    error={!this.state.address_company_name}
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="epa_id"
                                                    label={"EPA ID (Optional)"}
                                                    value={this.state.epa_id}
                                                    onChange={this.handleChange("epa_id")}
                                                    type="text"
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="emergency_phone"
                                                    label={"Emergency Phone"}
                                                    value={this.state.emergency_phone}
                                                    onChange={text => this.setStateIfMounted({ emergency_phone: text })}
                                                    placeholder="(123) 456-7890"
                                                    type="tel"
                                                    variant="outlined"
                                                    InputProps={{
                                                        inputComponent: CustomPhoneNumberInput
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="address_contact_phone"
                                                    label={"Site Contact Phone"}
                                                    value={this.state.address_contact_phone}
                                                    onChange={text => this.setStateIfMounted({ address_contact_phone: text })}
                                                    placeholder="(123) 456-7890"
                                                    type="tel"
                                                    variant="outlined"
                                                    InputProps={{
                                                        inputComponent: CustomPhoneNumberInput
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <GoogleMapReactWrapper>
                                                <GoogleMapReact
                                                    options={this.getMapOptions}
                                                    bootstrapURLKeys={{
                                                        key: GMapAPIKey
                                                    }}
                                                    defaultCenter={{ lat: 40.75027, lng: -73.8542755 }}
                                                    defaultZoom={16}
                                                    yesIWantToUseGoogleMapApiInternals
                                                    onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                                                    onClick={this.handleMapClick}
                                                />
                                            </GoogleMapReactWrapper>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} md={6}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                startIcon={<WorldIcon />}
                                                onClick={() => this.setStateIfMounted({ displayCoordinates: !this.state.displayCoordinates })}
                                            >
                                                {this.state.displayCoordinates ? this.props.t('addressTranslations.useAddress') : this.props.t('addressTranslations.useCoordinates')}
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    {!this.state.displayCoordinates ? <>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="job_country">{this.props.t('addressTranslations.country')}</InputLabel>
                                                    <Select
                                                        labelId="job_country"
                                                        label={this.props.t('addressTranslations.country')}
                                                        value={this.state.country_id}
                                                        onChange={this.handleChange('country_id')}
                                                        fullWidth
                                                        inputProps={{
                                                            name: "job_country",
                                                            id: "job_country"
                                                        }}
                                                        required
                                                        error={!this.state.country_id}
                                                    >
                                                        {this.props.supportedCountries.map(country => (
                                                            <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_address_line_1"
                                                        label={this.props.t('addressTranslations.addressLine1')}
                                                        value={this.state.address_line_1}
                                                        onChange={this.handleChange('address_line_1')}
                                                        type="text"
                                                        inputRef={this.addressInput}
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.address_line_1}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_city"
                                                        label={this.props.t('addressTranslations.city')}
                                                        value={this.state.city}
                                                        onChange={this.handleChange('city')}
                                                        type="text"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.city}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_state"
                                                        label={this.props.t('addressTranslations.province')}
                                                        value={this.state.province}
                                                        onChange={this.handleChange('province')}
                                                        type="text"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.province}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_postal_code"
                                                        label={this.props.t('addressTranslations.postalCode')}
                                                        value={this.state.postal_code}
                                                        onChange={this.handleChange('postal_code')}
                                                        type="text"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.postal_code}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </> : <>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_latitude"
                                                        label={this.props.t('addressTranslations.latitude')}
                                                        value={this.state.latitude}
                                                        onChange={this.handleChange('latitude')}
                                                        type="number"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.latitude}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="job_longitude"
                                                        label={this.props.t('addressTranslations.longitude')}
                                                        value={this.state.longitude}
                                                        onChange={this.handleChange('longitude')}
                                                        type="number"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.longitude}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </>} 
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Same Mailing Address</FormLabel>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.sameAddressForMailing}
                                                                onChange={this.handleChange("sameAddressForMailing", 'boolean')}
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {!this.state.sameAddressForMailing && <>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="mailing_country_id">{this.props.t('addressTranslations.country')}</InputLabel>
                                                    <Select
                                                        labelId="mailing_country_id"
                                                        label={this.props.t('addressTranslations.country')}
                                                        value={this.state.mailing_address.country_id}
                                                        onChange={this.handleChange(null, null, { parent: 'mailing_address', child: 'country_id'})}
                                                        fullWidth
                                                        inputProps={{
                                                            name: "mailing_country_id",
                                                            id: "mailing_country_id"
                                                        }}
                                                        required
                                                        error={!this.state.mailing_address.country_id}
                                                    >
                                                        {this.props.supportedCountries.map(country => (
                                                            <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {/* <FormHelperText>Select your which of your preferred Material Suppliers will be involved with the job.</FormHelperText> */}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="mailing_address_line_1"
                                                        label={this.props.t('addressTranslations.addressLine1')}
                                                        value={this.state.mailing_address.address_line_1}
                                                        onChange={this.handleChange(null, null, { parent: 'mailing_address', child: 'address_line_1'})}
                                                        type="text"
                                                        inputRef={this.mailingAddressInput}
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.mailing_address.address_line_1}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="mailing_city"
                                                        label={this.props.t('addressTranslations.city')}
                                                        value={this.state.mailing_address.city}
                                                        onChange={this.handleChange(null, null, { parent: 'mailing_address', child: 'city'})}
                                                        type="text"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.mailing_address.city}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="mailing_province"
                                                        label={this.props.t('addressTranslations.province')}
                                                        value={this.state.mailing_address.province}
                                                        onChange={this.handleChange(null, null, { parent: 'mailing_address', child: 'province'})}
                                                        type="text"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.mailing_address.province}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="mailing_postal_code"
                                                        label={this.props.t('addressTranslations.postalCode')}
                                                        value={this.state.mailing_address.postal_code}
                                                        onChange={this.handleChange(null, null, { parent: 'mailing_address', child: 'postal_code'})}
                                                        type="text"
                                                        required
                                                        variant="outlined"
                                                        error={!this.state.mailing_address.postal_code}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </>}
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <KeyboardDatePicker
                                                    label={this.props.t('timeTranslations.startDate')}
                                                    value={this.state.start_date}
                                                    onChange={this.handleChange('start_date', 'date')}
                                                    format="MM/dd/yyyy"
                                                    min={this.today}
                                                    inputVariant="outlined"
                                                    fullWidth
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <KeyboardDatePicker
                                                    label={this.props.t('timeTranslations.endDate')}
                                                    value={this.state.end_date}
                                                    onChange={this.handleChange('end_date', 'date')}
                                                    format="MM/dd/yyyy"
                                                    min={this.today}
                                                    inputVariant="outlined"
                                                    fullWidth
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} md={6}>
                                            <KeyboardTimePicker
                                                label={this.props.t('timeTranslations.openingTime')}
                                                value={this.state.opening_time}
                                                onChange={this.handleChange('opening_time', 'date')}
                                                inputVariant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <KeyboardTimePicker
                                                label={this.props.t('timeTranslations.closingTime')}
                                                value={this.state.closing_time}
                                                onChange={this.handleChange('closing_time', 'date')}
                                                inputVariant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="site_type">{this.props.t('siteTranslations.siteType')}</InputLabel>
                                                <Select
                                                    labelId="site_type"
                                                    label={this.props.t('siteTranslations.siteType')}
                                                    value={this.state.type}
                                                    onChange={this.handleChange('type')}
                                                    fullWidth
                                                    required
                                                    inputProps={{
                                                        name: "site_type",
                                                        id: "site_type"
                                                    }}
                                                    error={!this.state.type}
                                                >
                                                    {this.state.siteTypes.map(type => (
                                                        <MenuItem key={type.name} value={type}>{type.display_name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">{this.props.t('siteTranslations.publicSite')}</FormLabel>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.is_public}
                                                                onChange={this.handleChange("is_public", 'boolean')}
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} md={6}>
                                            <FormControl component="fieldset">
                                                <FormGroup>
                                                    <FormLabel component="legend">{this.props.t('siteTranslations.internetQuality')}</FormLabel>
                                                    <FormControl>
                                                        <StarRatings
                                                                rating={this.state.internet_quality}
                                                                numberOfStars={5}
                                                                starRatedColor="#FF6600"
                                                                starDimension="15px"
                                                                starSpacing="1px"
                                                                changeRating={this.handleRatingChange}
                                                            />
                                                    </FormControl>
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">{this.props.t('siteTranslations.electricity')}</FormLabel>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.electricity}
                                                                onChange={this.handleChange("electricity", 'boolean')}
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {Boolean(this.state.type && this.state.canHaveAutomatedBatching) && <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Automated Batching</FormLabel>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.has_automated_batching}
                                                                onChange={this.handleChange("has_automated_batching", 'boolean')}
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>}
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} md={(this.props.site && this.props.site.image_url) ? 6 : 12}>
                                            <DropzoneArea 
                                                showFileNamesInPreview={true} 
                                                showFileNames={true} 
                                                filesLimit={1}
                                                onChange={this.handleFileChange}
                                                acceptedFiles={['image/*']}
                                                dropzoneText={this.props.t('siteTranslations.actions.uploadSitePhotoOptional')}
                                            />
                                        </Grid>
                                        {(this.props.site && this.props.site.image_url) && <Grid item xs={12} md={6}>
                                            <img src={this.props.site.image_url} style={{ objectFit: "contain", width: "100%", height: "100%"}}/>
                                        </Grid>}
                                    </Grid>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="primary" fullWidth type="submit" disabled={this.state.loading}>
                                                {this.props.t('actions.submit')}
                                            </Button>
                                        </Grid>
                                    </Grid>          
                                </form>
                            </Box>  
                        </Paper>
                    </Grid>
                    <NotificationSnackbar />
                </Grid>
            </Modal>
        )
    }
}
const msp = state => ({
    helpers: state.helpers,
    token: state.session.user.token,
    userID: state.session.user.id,
    company: state.session.company,
    supportedCountries: state.entities.supportedCountries,
})
const mdp = dispatch => ({
    getSupportedCountries: () => dispatch(getSupportedCountries()),
    getCompanySites: (token, companyID) => dispatch(getCompanySites(token, companyID)),
    popAlert: (success, title, message) => dispatch(popAlert(success, title, message)),
})
export default connect(msp, mdp)(withTranslation()(CreateEditSiteModal))