import * as CompanyAPI from '../util/companyAPI';
import i18n from '../../i18n';

import { popAlert } from './sessionActions'
export const RECEIVE_COMPANIES = "RECEIVE_COMPANIES";
export const RECEIVE_CUSTOM_MATERIALS = "RECEIVE_CUSTOM_MATERIALS";

export const receiveCompanies = (companies) => ({
    type: RECEIVE_COMPANIES,
    companies
})
export const receiveCustomMaterials = (customMaterials) => ({
    type: RECEIVE_CUSTOM_MATERIALS,
    customMaterials
})

export const getUsersCompanies = (token) => dispatch => (
    CompanyAPI.getUsersCompanies(token).then(
        (companyResponse) => {
            if (companyResponse.success) {
                dispatch(receiveCompanies(companyResponse.data))
            }
            return companyResponse
        },
        (errors) => {
            dispatch(popAlert('error', i18n.t(`error`), errors.responseJSON))
        }
    )
)
export const DEPRECATED_getCustomMaterials = (token, companyID) => dispatch => (
    CompanyAPI.DEPRECATED_getCustomMaterials(token, companyID).then(
        (res) => {
            if (res.data) {
                dispatch(receiveCustomMaterials(res.data))
            }
            return res
        },
        (errors) => {
            dispatch(popAlert('error', i18n.t(`error`), errors.responseJSON))
        }
    )
)

