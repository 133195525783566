import { Divider as MuiDivider, Grid, Link, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DeliveryApi, InspectionItemDataTypes, InspectionValue, InspectorTypes } from "../../../../../redux/reducers/entities";
import { getDateTimeFromUnix } from "../../../../../redux/reducers/helpersReducerr";

const Divider = styled(MuiDivider)(spacing);

interface Props {
    delivery: DeliveryApi;
}

const DeliveryInspections = ({
    delivery
}: Props) => {
    const { t } = useTranslation();

    if (!delivery?.inspections?.length) {
        return <>
            <Grid container spacing={10}>
                <Grid item>
                    <Typography>No Inspections Found</Typography>
                </Grid>
            </Grid>
        </>
    }

    return <Grid container spacing={10}>
        {delivery.inspections.map(inspection => <Grid item key={inspection.id} xs={12}>
            <Grid container spacing={10}>
                <Grid item xs={12}>
                    <Typography variant="h4" align="center">{t(`companyTranslations.${inspection.inspection_type}`)} - {t(`addressTranslations.${inspection.inspection_location}`)}</Typography>
                    <Typography style={{ fontWeight: "bold" }}>Notes:</Typography>
                    <Typography>{inspection.notes}</Typography>
                </Grid>
            </Grid>
            <Divider my={3} />
            {inspection.values.map(inspectionValue => <React.Fragment key={inspectionValue.id}>
                <RenderInspectionValue inspectionValue={inspectionValue} />
                <Divider my={2} />
            </React.Fragment>)}
        </Grid>)}
    </Grid>
}

export default DeliveryInspections

const RenderInspectionValue = ({ inspectionValue }: { inspectionValue: InspectionValue }) => {
    const { t } = useTranslation()
    const jobInspectionItem = inspectionValue.job_inspection_item
    const inspectionItem = jobInspectionItem.inspection_item

    let displayError = <></>
    let displayLine1 = <Typography variant="h6">{inspectionItem.name}: {inspectionValue.value} {inspectionValue.units ? t(`unitTranslations.${inspectionValue.units}`) : null}</Typography>
    let displayLine2 = <Typography variant="body2">{inspectionItem.description}</Typography>
    let displayLine3 = <Typography variant="subtitle2" color="textSecondary">Notes: </Typography>
    let displayLine4 = <Typography variant="body1">{jobInspectionItem.notes || t('na')}</Typography>
    

    switch (inspectionValue.job_inspection_item.inspection_item.data_type) {
        case InspectionItemDataTypes.STRING:
        case InspectionItemDataTypes.INTEGER:
        case InspectionItemDataTypes.DECIMAL:
            // these all use the default display
            break;

        case InspectionItemDataTypes.DATETIME:
            displayLine1 = <Typography variant="h6">{inspectionItem.name}: {getDateTimeFromUnix(inspectionValue.value)}</Typography>
            break
        case InspectionItemDataTypes.BOOLEAN: 
            displayLine1 = <Typography variant="h6">{inspectionItem.name}: {inspectionValue.value ? t('valid') : t('invalid')}</Typography>
            break
        case InspectionItemDataTypes.IMAGE:
            displayLine1 = <Typography variant="h6">{inspectionItem.name}: <Link href={inspectionValue.value} target="_blank">View Image</Link></Typography>
            break;
        case "document":
            displayLine1 = <Typography variant="h6">{inspectionItem.name}: <Link href={inspectionValue.value} target="_blank">View Doc</Link></Typography>
            break;
        case "signature": 
            displayLine1 = <Typography variant="h6">{inspectionItem.name}: <Link href={inspectionValue.value} target="_blank">View Signature</Link></Typography>
            break;
        default: 
            displayError = <Typography color="error">Unknown Error.</Typography>
            break;
    }
    return (<Grid container spacing={5}>
        <Grid item xs={12}>
            {displayError}
            {displayLine1}
            {displayLine2}
            {displayLine3}
            {displayLine4}
        </Grid>
    </Grid>)

}
