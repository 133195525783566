import { AppBar, Button, Card, CardContent, CardHeader, Grid, Tab, Tabs, Toolbar, Typography } from "@material-ui/core";
import { TabContext } from "@material-ui/lab";
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useRootSelector } from "../../../..";
import { popAlert, showAlert } from "../../../../redux/actions/sessionActions";
import { Company, DeliveryApi, JobInspectorAssignment, Permissions } from "../../../../redux/reducers/entities";
import { can, parseError } from "../../../../redux/reducers/helpersReducerr";
import { getSpecificCompany } from "../../../../redux/util/companyAPI";
import { cancelDelivery, exportDeliveries, getDeliveryDetails, voidOrUnvoidDelivery } from "../../../../redux/util/deliveryAPI";
import { getDeliveryDetails as InspectorGetDeliveryDetails } from "../../../../redux/util/inspectorAPI";
import CreateInspectionModal from "../../Inspector/modals/ModalCreateInspection";
import AdditionalJobDocsCard from "../../JobDocs/components/AdditionalJobDocsCard";
import CreateEditMixBatchModal from "../../Materials/modals/ModalCreateEditMixBatch";
import TabPanel from "../../Util/TabPanel";
import TPModal from "../../Util/TPModal"
import DeliveryTrackingMap from "../components/DeliveryTracking";
import DeliveryDetails from "./components/DeliveryDetails";
import DeliveryInspections from "./components/DeliveryInspections";
import DeliveryLocations from "./components/DeliveryLocations";
import { isNil } from "lodash";
import { getETicketTargetsForJob } from "../../../../redux/util/eticketAPI";
import { JobETicketTarget } from "../../../../redux/reducers/entities/ETicket";
import ETicketUploadStatusChip from "../subcomponents/ETicketUploadStatusChip";
import ExportDeliveriesModal from "../../Export/modals/ModalExportDeliveries";

enum TabValues {
    General,
    Tracking,
    Inspections,
    Docs,
}
interface Props {
    setModalOpen: (bool: boolean) => void;
    deliveryID: number;
    refreshData?: () => void;
    asInspector?: boolean;
    inspectorAssignment?: JobInspectorAssignment;
    inspectionType?: "pickup" | "dropoff",
    visibleTabs?: { 
        general: boolean;
        tracking: boolean;
        inspections: boolean;
        docs: boolean;
    };
}

/**
 * __OVERVIEW__
 * 
 * Handles viewing a delivery.
 * The data is separated into 4 tabs:
 * - General
 * - Tracking
 * - Inspections
 * - Docs
 * 
 * __INDEPENDENT PARAMS__
 * - setModalOpen 
 *   - Handles closing the modal.
 * 
 * - refreshData?
 *   - Listener function that will get called when data should update. Currently, refreshData will be 
 *     invoked when a delivery is voided or unvoided, or when an inspection is created.
 * 
 * 
 * __LOGIC PARAMS__
 * - asInspector?
 *   - Flag to tell the modal to attemp to view things as an inspector
 * 
 * - inspectorAssignment?
 *   - If passed with `asInspector`, then `Inspector Get Delivery Details` fetches delivery data.
 * 
 * - deliveryID
 *   - If inspector info isn't passed, then `Get Delivery Details` fetches delivery data.
 * 
 * - inspectionType?
 *   - Used to determine what kind of inspection should be created
 * 
 * @todo e ticket targets
 * @todo move invoices here
 * @todo perm lock creating batch material
 * 
 */
const ViewDeliveryModal = ({
    setModalOpen,
    deliveryID,
    asInspector = false,
    inspectorAssignment,
    inspectionType = "pickup",
    refreshData,
    visibleTabs = {
        general: true,
        tracking: true,
        inspections: true,
        docs: true,
    }
}: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const token = useRootSelector(state => state.session.user.token!)
    const company = useRootSelector(state => state.session.company)
    const companyID = useRootSelector(state => state.session.company.id!)
    const [loading, setLoading] = useState(true)

    const [shouldCreateBatch, setShouldCreateBatch] = useState(false)
    const [openCreateBatchModal, setOpenCreateBatchModal] = useState(false)


    const getInitialTabValue = (): string => {
        if (visibleTabs.general) return TabValues.General.toString()
        if (visibleTabs.tracking) return TabValues.Tracking.toString()
        if (visibleTabs.inspections) return TabValues.Inspections.toString()
        if (visibleTabs.docs) return TabValues.Docs.toString()
        return TabValues.General.toString()
    }

    const [tabValue, setTabValue] = useState(getInitialTabValue())
    const [openCreateInspectionModal, setCreateInspectionModal] = useState(false)
    const [openExportDeliveryModal, setExportDeliveryModal] = useState(false)

    const [jobOwnerCompany, setJobOwnerCompany] = useState<Company>()
    const [delivery, setDelivery] = useState<DeliveryApi>()
    const [eTicketTargets, setETicketTargets] = useState<JobETicketTarget[]>([])


    /**
     * A user can void a delivery if
     * - they belong to either job owner or driver company
     * - they have permission to void deliveries
     * - delivery is not in progress
     * - delivery is not yet been voided
     */
    const canVoidThisDelivery = useMemo(() => {
        if (delivery && company) {
            const isARelevantParty = (
                delivery.job_owner_company_id === company.id ||
                delivery.driver_company.id === company.id
            )
            return (
                isARelevantParty
                && can(company.perms!, "void-delivery")
                && !delivery.in_progress
                && !delivery.is_voided
            )
        }
        return false
    }, [delivery, company])


    /**
     * A user can cancel a delivery if
     * - they are part of the job owner company
     * - have permission to cancel deliveries
     * - delivery has not yet been canceled
     * - delivery is in progress
     */
    const canCancelThisDelivery = useMemo(() => {
        if (delivery && company) {
            return (
                delivery.job_owner_company_id === company.id
                && can(company.perms!, Permissions.CANCEL_DELIVERY)
                && !delivery.is_canceled
                && delivery.in_progress
            )
        }
        return false
    }, [delivery, company])

    /**
     * Gets any e ticket targets
     */
    const getETicketTargets = async (delivery: DeliveryApi) => {
        if (!company?.perms?.length || !can(company.perms, "create-job")) return

        try {
            const jobETicketTargets = await getETicketTargetsForJob(token, delivery.job_id)
            if (!jobETicketTargets.success) return;
            setETicketTargets(jobETicketTargets.data)
        } catch (error: any) {
            console.log("e ticket error", error)
        }
    }

    /**
     * Gets the delivery details
     */
    const getDelivery = async () => {
        let fullDeliveryResponse;
        if (asInspector && inspectorAssignment) {
            fullDeliveryResponse = await InspectorGetDeliveryDetails(inspectorAssignment.token, inspectorAssignment.job_id, deliveryID)
        } else {
            fullDeliveryResponse = await getDeliveryDetails(token, companyID, deliveryID)
        }
        if (!fullDeliveryResponse.success) return;

        const tempDelivery = fullDeliveryResponse.data
        console.log('delivery', tempDelivery)

        const belongsToCompany = tempDelivery.company_id === companyID
        const isInProgress = tempDelivery.in_progress
        const hasInspectableMaterial = !!tempDelivery.materials.find(mat => mat.is_inspectable && !isNil(mat.material_mix_design_id))
        const noBatchYet = !tempDelivery.mix_batch
        
        setShouldCreateBatch(belongsToCompany && isInProgress && hasInspectableMaterial && noBatchYet)

        setDelivery(tempDelivery)
        getETicketTargets(tempDelivery)
        setLoading(false)
    }


    /**
     * Gets the job owner company
     */
    const getJobOwnerCompany = async () => {
        const response = await getSpecificCompany(token, companyID)
        if (!response.success || !response.data) return;
        console.log("got job owner company");
        
        setJobOwnerCompany(response.data)
    }


    /**
     * On mount, get the delivery details and the job owner company details
     */
    useEffect(() => {
        getDelivery()
        getJobOwnerCompany()
    }, [])

    /**
     * Voids the current delivery
     */
    const voidDelivery = async () => {
        setLoading(true)
        const response = await voidOrUnvoidDelivery(token, companyID, delivery!.id, 1)
        if (!response.success) {
            const errorMessage = parseError(response)
            dispatch(popAlert('error', t("error"), errorMessage))
            setLoading(false)
            return;
        }
        dispatch(popAlert('success', t("success"), "Voided delivery"))
        refreshData?.()
        setDelivery({ ...delivery!, is_voided: true })
        setLoading(false)
    }

    /**
     * Unvoids the current delivery
     */
    const unvoidDelivery = async () => {
        setLoading(true)
        const response = await voidOrUnvoidDelivery(token, companyID, delivery!.id, 0)
        if (!response.success) {
            const errorMessage = parseError(response)
            dispatch(popAlert('error', t("error"), errorMessage))
            setLoading(false)
            return;
        }
        dispatch(popAlert('success', t("success"), "Unvoided delivery"))
        refreshData?.()
        setDelivery({ ...delivery!, is_voided: false })
        setLoading(false)
    }

    /**
     * Handles canceling a delivery
     */
    const cancel = async () => {
        setLoading(true)
        try {
            await cancelDelivery({ 
                token, 
                companyId: companyID, 
                jobId: delivery!.job_id,
                deliveryId: delivery!.id
            })
            setDelivery(curVal => ({ ...curVal!, is_canceled: true }))
            dispatch(showAlert("success", t("success"), "Cancelled delivery!"))
        } catch (e: any) {
            dispatch(showAlert("error", t("error"), parseError(e)))
        } finally {
            setLoading(false)
        }
    }

    return <TPModal
        loading={loading}
        setModalOpen={setModalOpen}
        headerOptions={{
            headerText: `Ticket #${delivery?.ticket_number || ""}${delivery?.is_voided ? " - Voided" : ""}`
        }}
        bodyOptions={{
            noPadding: true
        }}
        xs={12}
        sm={8}
        md={6}
        lg={6}
    >
        <TabContext value={tabValue}>
            <AppBar color="default" position="static">
                <Toolbar>
                    <Tabs
                        variant="scrollable"
                        indicatorColor="primary"
                        scrollButtons="auto"
                        value={tabValue}
                        onChange={(event, tabValue) => setTabValue(tabValue)}
                    >
                        {visibleTabs.general && <Tab label="Details" value={TabValues.General.toString()} />}
                        {visibleTabs.tracking && <Tab label="Route" value={TabValues.Tracking.toString()} />}
                        {visibleTabs.inspections && <Tab label="Inspections" value={TabValues.Inspections.toString()} />}
                        {visibleTabs.docs && <Tab label="Job Docs" value={TabValues.Docs.toString()} />}
                    </Tabs>
                </Toolbar>
            </AppBar>
            <TabPanel value={tabValue} index={TabValues.General.toString()} boxProps={{ p: 3 }} keepIn>
                {!!delivery && <>                
                    <Grid container spacing={5}>
                        <Grid item>
                            <Button 
                                color="primary" 
                                variant="outlined" 
                                onClick={() => setExportDeliveryModal(true)}
                            >
                                Export Delivery
                            </Button>
                        </Grid>
                        {asInspector ? <>
                            {(delivery.inspections && !delivery.inspections.some(inspection => inspection.inspection_location === inspectionType)) && <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => setCreateInspectionModal(true)}
                                >
                                    Create Inspection
                                </Button>
                            </Grid>}
                            {openCreateInspectionModal && <CreateInspectionModal 
                                setModalOpen={setCreateInspectionModal}
                                delivery={delivery}
                                specificToken={inspectorAssignment!.token}
                                specificJobID={inspectorAssignment!.job_id}
                                inspectionType={inspectionType} 
                                refreshDeliveries={() => {
                                    // setLoading(true)
                                    // getDelivery()
                                    refreshData?.()
                                }}
                            />}
                        </> : <>
                            {canVoidThisDelivery && <>
                                <Grid item>
                                    <Button 
                                        variant="contained" 
                                        color="primary"
                                        onClick={() => {
                                            if (delivery.is_voided) {
                                                unvoidDelivery()
                                            } else {
                                                voidDelivery()
                                            }
                                        }}
                                        fullWidth
                                    >
                                        {delivery.is_voided ? "Unvoid Delivery" : "Void Delivery"}
                                    </Button>
                                </Grid>
                            </>}
                            {canCancelThisDelivery && <>
                                <Grid item>
                                    <Button 
                                        variant="contained" 
                                        color="primary"
                                        onClick={() => cancel()}
                                        fullWidth
                                    >
                                        Cancel Delivery
                                    </Button>
                                </Grid>
                            </>}
                        </>}
                        <Grid item />
                    </Grid>
                    {eTicketTargets.length > 0 && <Grid container spacing={10}>
                        {eTicketTargets.map(target => (<Grid item key={target.target}>
                            <ETicketUploadStatusChip 
                                targetName={target.target} 
                                targetID={target.id} 
                                deliveryID={delivery.id} 
                            />
                        </Grid>))}
                    </Grid>}
                    <DeliveryDetails 
                        delivery={delivery} 
                        jobOwnerCompany={jobOwnerCompany} 
                        shouldCreateBatch={shouldCreateBatch} 
                        setShouldCreateBatch={setShouldCreateBatch} 
                    />
                </>}
            </TabPanel>
            <TabPanel value={tabValue} index={TabValues.Tracking.toString()} boxProps={{ p: 3 }} keepIn>
                {!!delivery && <>
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                            <DeliveryLocations delivery={delivery} type="pickup" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DeliveryLocations delivery={delivery} type="dropoff" />
                        </Grid>
                    </Grid>
                    <Grid container spacing={10}>
                        <Grid item xs>
                            <Card variant="outlined" raised>
                                <CardHeader title={"Route"}/>
                                <CardContent>
                                    <DeliveryTrackingMap delivery={delivery} />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </>}
            </TabPanel>
            <TabPanel value={tabValue} index={TabValues.Docs.toString()} boxProps={{ p: 3 }} keepIn>
                {!!delivery && <AdditionalJobDocsCard delivery={delivery} />}
            </TabPanel>
            <TabPanel value={tabValue} index={TabValues.Inspections.toString()} boxProps={{ p: 3 }} keepIn>
                {!!delivery && <DeliveryInspections delivery={delivery} />}
            </TabPanel>
        </TabContext>
        {openExportDeliveryModal && <ExportDeliveriesModal 
            setModalOpen={(isOpen) => setExportDeliveryModal(isOpen)}
            deliveryIds={[deliveryID]}
        />}
    </TPModal>
}

export default ViewDeliveryModal