import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import languageEN from './locales/en/translation.json';
import languageES from './locales/es/translation.json';
import languageFR from './locales/fr/translation.json';

const initLang = window.localStorage.getItem("TPLang");

export const defaultNS = 'translations'
export const resources = {
    en: { translations: languageEN },
    es: { translations: languageES },
    fr: { translations: languageFR },
} as const;

i18n.use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        /* default language when load the website in browser */
        lng: initLang || "en",
        /* When react i18next not finding any language to as default in borwser */
        fallbackLng: "en",
        /* debugger For Development environment */
        debug: true,
        ns: [defaultNS],
        defaultNS,
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindI18nStore: 'added removed',
            nsMode: 'default'
        }
    })

export default i18n;