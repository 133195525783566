import variants from './variants';
const overrides = themeName => {

  let overridesObject = {
    MuiCardHeader: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px"
      },
      root: {
        backgroundColor: '#F0F0F0'
      }
    },
    MuiPickersDay: {
      day: {
        fontWeight: "300"
      }
    },
    MuiPickersYear: {
      root: {
        height: "64px"
      }
    },
    MuiPickersCalendar: {
      transitionContainer: {
        marginTop: "6px"
      }
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent"
        }
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px"
      }
    },
    MuiPickersClock: {
      container: {
        margin: `32px 0 4px`
      }
    },
    MuiPickersClockNumber: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px"
      }
    },
    MuiPickerDTHeader: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400
        }
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400
        }
      }
    },
    MuiPickersTimePicker: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: "3.75rem",
          fontWeight: 300
        }
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400
        }
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: "#F0F0F0"
      }
    },
    MuiTab: {
      root: {
        '&$selected': {
          backgroundColor: 'white',
          borderRadius: '5px 5px 0px 0px'
        }
      }
    },
    MuiChip: {
      root: {
        colorPrimary: variants.standardVariant.palette.green.main,
        colorSecondary: variants.standardVariant.palette.error,
      }
    },
    MuiPagination: {
      ul: {
        justifyContent: "center"
      }
    }
  }

  if (themeName == 'high_contrast') {
    overridesObject = {
      ...overridesObject,
      MuiPaper: {
        root: {
          backgroundColor: "#000",
        },
        outlined: {
          borderColor: variants.highContrast.card.borderColor
        }
      },
      MuiDialog: {
        paper: {
          border: `1px solid ${variants.highContrast.card.borderColor}`
        },
      },
      MuiOutlinedInput: {
        root: {
          "$disabled": {
            borderColor: "#FFF"
          }
        },
        notchedOutline: {
          borderColor: "#FFF"
        },
        // disabled: {
        //   borderColor: "#FFF"
        // }
      },
      // MuiTableBody: {
      //   root: {
      //     backgroundColor: ""
      //   }
      // },
      MuiDivider: {
        root: {
          backgroundColor: "#FFF"
        }
      },
      MuiCard: {
        outlined: {
          borderColor: "white"
        }
      },
      MuiCardHeader: {
        root: {
          backgroundColor: variants.highContrast.card.header.background,
          borderBottom: `1px solid ${variants.highContrast.card.borderColor}`
        }
      },
      MuiCardContent: {
        root: {
          backgroundColor: variants.highContrast.card.body.background
        }
      },
      MuiAppBar: {
        colorDefault: {
          color: variants.highContrast.palette.text.primary,
          backgroundColor: "#000"
        }
      },
      MuiMenu: {
        paper: {
          border: `1px solid ${variants.highContrast.card.borderColor}`
        }
      },
      MuiTableBody: {
        root: {
          backgroundColor: variants.highContrast.pseudo.hover.background
        }
      },
      MuiTableRow: {
        root: {
          backgroundColor: variants.highContrast.card.body.background
        }
      },
      MuiButton: {
        root: {
          border: `1px solid ${variants.highContrast.palette.primary.main}`
        }
      },
      MuiTab: {
        root: {
          '&$selected': {
            backgroundColor: 'rgba(255,255,255,0.3)',
            borderRadius: '5px 5px 0px 0px'
          }
        }
      },
    }  
  } else {

  }

  return overridesObject
};

export default overrides;
