import { Box } from "@material-ui/core";
import React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
  boxProps?: any;
  keepIn?: boolean;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, boxProps, keepIn, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {Boolean(value === index || keepIn) && (
          <Box {...boxProps} height={(value !== index && keepIn) ? 0 : undefined}>
            {children}
          </Box>
        )}
      </div>
    );
}
export default TabPanel