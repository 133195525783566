import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import { CssBaseline, Paper } from "@material-ui/core";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

/**
 * Mainly used for the TOS/PP pages
 */
export const AuthLayoutWrapper = styled(Paper)`
padding: ${props => props.theme.spacing(6)}px;

${props => props.theme.breakpoints.up("md")} {
  padding: ${props => props.theme.spacing(10)}px;
}

max-height: 100vh;
overflow-y: scroll;
`;
function Auth({ children }) {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
}

export default Auth;
